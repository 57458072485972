import {View,Text, TextInput} from "react-native"
import {Colors} from "../../../styles"
import {FoldButton} from "../../CommonComponents/FoldButton/FoldButton";
import {COMMON_ACTION_CODES} from "../../../constants/ActionConst";
import React, {useEffect, useState} from "react";
import {UpdateSignatureInfoPopover} from "./UpdateSignatureInfoPopover";
import JoditRichEditor from "../JoditRichEditor/JoditRichEditor";
import {ADD_SIGNATURE_EDITOR_OPTIONS} from "./PreserenceConst";
import './PreferenceSetting.css'
import {IAddSignatureInputView} from "./PreferencesInterface";
import {useIntl} from "react-intl";
import {TestIdentifiers, testID} from "../../../testUtils";

export const AddSignatureInputView = (props: IAddSignatureInputView) => {
  const [stateData, setStateData] = useState({
    id: '',
    tempId: '',
    title: '',
    bodyText: '',
    showSignatureUpdateInfo: false,
  })
  const intl = useIntl();
  const editorRef = React.useRef<any>(null);

  const onUpdatePopoverChange = () => {
    setStateData((prev) => {
      return {
        ...prev,
        showSignatureUpdateInfo: !prev?.showSignatureUpdateInfo
      }
    })
  }

  const onActionPerformed = (actionCode: string, actionData?: any) => {
    switch(actionCode) {
      case COMMON_ACTION_CODES.CLOSE_POPUP:
        onUpdatePopoverChange();
        return;
      case COMMON_ACTION_CODES.SAVE:
        const tempData = {
          id: stateData?.id,
          tempId: stateData?.tempId,
          title: stateData?.title,
          bodyText: editorRef?.current?.value,
          isChecked: actionData?.isChecked,
        }
        props?.onActionPerformed(COMMON_ACTION_CODES.SAVE, tempData);
        onUpdatePopoverChange();
        return;
      default:
        return;
    }
  }

  const isDisabled = () => {
    if (!stateData?.title?.length || (!editorRef?.current && !editorRef?.current?.value)) {
      return true
    }
    return false;
  }

  useEffect(() => {
    setStateData((prev) => {
      return {
        ...prev,
        id: props?.selectedSignature?.id,
        tempId: props?.selectedSignature?.tempId,
        title: props?.selectedSignature?.title,
        bodyText: props?.selectedSignature?.bodyText,
      }
    })
  },[])
  return <>
    <View>
      <Text style={{
        color: Colors.FoldPixel.GRAY200,
        fontSize: 14,
        lineHeight: 16.8
      }}>
        {intl.formatMessage({id: 'title'})}
      </Text>
      <TextInput
        style={{
          borderWidth: 0.5,
          borderColor: Colors.FoldPixel.GRAY200,
          borderRadius: 6,
          height: 32,
          width: '100%',
          paddingLeft: 10,
          marginTop: 1
        }}
        {...testID(`${TestIdentifiers.emailSignature}_Name_Input`)}
        onChangeText={(text: string) => {
          setStateData((prev) => {
            return {
              ...prev,
              title: text
            }
          })
        }}
        placeholder="Enter Title"
        placeholderTextColor={Colors.FoldPixel.GRAY200}
        value={stateData?.title}
      />
      <View style={{
        marginTop: 8,
        borderWidth: 0.5,
        borderColor: Colors.FoldPixel.GRAY200,
        borderRadius: 6,
        padding: 1,
      }}>
        <JoditRichEditor
          setRef={(ref) => {
            editorRef.current = ref;
          }}
          buttons={ADD_SIGNATURE_EDITOR_OPTIONS}
          className={'add-signature-JoditRichEditor'}
          content={stateData?.bodyText}
          {...testID(`${TestIdentifiers.emailSignature}_Value_Input`)}
        />
      </View>
      <View style={{
        flexDirection: 'row',
        marginTop: 6,
      }}>
        <FoldButton
          customProps={{
            btnText: props?.selectedSignature?.id ? 'Discard' : 'Delete',
          }}
          nativeProps={{
            onPress() {
              const tempData = {
                id: stateData?.id,
                tempId: stateData?.tempId,
                title: stateData?.title,
                bodyText: editorRef?.current?.value,
              }
              props?.onActionPerformed(COMMON_ACTION_CODES.DISCARD, tempData)
            },
            backgroundColor: Colors.Custom.White,
            _text: {
              fontSize: 14,
              fontWeight: 500,
              lineHeight: 16.8,
              color: Colors.FoldPixel.GRAY300
            },
            paddingX: 2,
            borderWidth: 0.5,
            borderColor: Colors.FoldPixel.GRAY200
          }}
        />
        {stateData?.showSignatureUpdateInfo && <UpdateSignatureInfoPopover
          onActionPerformed={onActionPerformed}
        />}
        <FoldButton
          customProps={{
            btnText: 'Save',
          }}
          nativeProps={{
            onPress() {
              const tempData = {
                id: stateData?.id,
                tempId: stateData?.tempId,
                title: stateData?.title,
                bodyText: editorRef?.current?.value,
              }
              if (props?.selectedSignature?.id && !props?.userSignatureInfoChecked) {
                setStateData((prev) => {
                  return {
                    ...prev,
                    showSignatureUpdateInfo: true
                  }
                })
              } else {
                props?.onActionPerformed(COMMON_ACTION_CODES.SAVE, tempData)
              }
            },
            // isLoading: stateData?.isSubmitting,
            isDisabled: isDisabled(),
            backgroundColor: isDisabled() ? Colors.FoldPixel.GRAY50 : Colors.Custom.Primary300,
            _text: {
              fontSize: 14,
              fontWeight: 500,
              lineHeight: 16.8,
              color: isDisabled() ? Colors.FoldPixel.GRAY150 : Colors.Custom.White,
            },
            paddingX: 2,
            marginLeft: 2,
            borderWidth: 0.5,
            borderColor: isDisabled() ? Colors.FoldPixel.GRAY200 : Colors.Custom.Primary300,
          }}
        />
      </View>
    </View>
  </>
}