import {Select} from 'antd';
import {FormControl} from 'native-base';
import React, {useMemo} from 'react';
import {View, ViewStyle} from 'react-native';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import {getEHRDisplayNameByCode} from '../../../../../../utils/commonUtils';
import {IFormLocation} from '../AddOrUpdateForm';
import {testID} from '../../../../../../testUtils';

interface IFormEHRAndLocationSelect {
  selectedEHRCode: string;
  selectedLocationId: IFormLocation[];
  enabledFields: {
    ehr: boolean;
    locations: boolean;
  };
  onChange: (locationIdList: IFormLocation[], ehrCode: string) => void;
  containerStyles?: ViewStyle;
  ehrContainerStyles?: ViewStyle;
  locationContainerStyles?: ViewStyle;
  isAllFieldDisabled?: boolean;
  ehrList: string[];
  locationList: {
    locationUUID: string;
    practiceLocationName: string;
  }[];
  loading?: {
    ehr: boolean;
    location: boolean;
  };
  showErrors?: boolean;
}

const FormEHRAndLocationSelect = (props: IFormEHRAndLocationSelect) => {
  const {selectedEHRCode, selectedLocationId, ehrList, locationList} = props;
  const handleEHRCodeChange = (value: any, option: any | any[]) => {
    if (value) {
      props.onChange(selectedLocationId, value);
    }
  };

  const handleLocationSelect = (value: string[], option: (any | any)[]) => {
    props.onChange(
      value.map((locationId: string) => ({locationId})),
      selectedEHRCode
    );
  };

  if (Object.keys(props.enabledFields).length === 0) {
    return <></>;
  }

  const commonStyles: React.CSSProperties = useMemo(
    () => ({
      height: 40,
    }),
    []
  );

  console.log(selectedEHRCode, props.isAllFieldDisabled);

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'flex-start',
        ...props?.containerStyles,
      }}
    >
      {
        <FormControl
          style={{
            maxWidth: 150,
            marginRight: 10,
            ...props?.ehrContainerStyles,
          }}
          isInvalid={props.showErrors && selectedEHRCode?.length === 0}
        >
          <FormControl.Label isRequired>
            <DisplayText textLocalId="Select EHR" />
          </FormControl.Label>
          <Select
            {...testID('SelectEHR')}
            maxTagCount={'responsive'}
            style={{
              ...commonStyles,
            }}
            onChange={handleEHRCodeChange}
            placeholder="Select EHR"
            value={
              !!selectedEHRCode
                ? getEHRDisplayNameByCode(selectedEHRCode)
                : undefined
            }
            disabled={props.isAllFieldDisabled}
          >
            {ehrList?.map((ehr) => (
              <Select.Option
                key={ehr}
                value={ehr}
                {...testID(`SelectEHR-${ehr}`)}
              >
                {getEHRDisplayNameByCode(ehr)}
              </Select.Option>
            ))}
          </Select>
          {props.showErrors && selectedEHRCode?.length === 0 && (
            <FormControl.ErrorMessage>
              Please select EHR
            </FormControl.ErrorMessage>
          )}
        </FormControl>
      }
      <FormControl
        style={{
          maxWidth: 300,
          ...props?.locationContainerStyles,
        }}
        isInvalid={props.showErrors && selectedLocationId?.length === 0}
      >
        <FormControl.Label isRequired>
          <DisplayText textLocalId="Select Location" />
        </FormControl.Label>
        <Select
          allowClear
          maxTagCount={'responsive'}
          style={{
            ...commonStyles,
          }}
          {...testID('SelectLocation')}
          mode="multiple"
          placeholder="Select locations"
          onChange={handleLocationSelect}
          disabled={!selectedEHRCode}
          value={
            !!selectedLocationId?.length
              ? selectedLocationId?.map((location) => location.locationId)
              : undefined
          }
        >
          {locationList.map((location) => (
            <Select.Option
              {...testID(`SelectLocation-${location.practiceLocationName}`)}
              key={location.locationUUID}
              value={location.locationUUID}
            >
              {location.practiceLocationName}
            </Select.Option>
          ))}
        </Select>
        {props.showErrors && selectedLocationId?.length === 0 && (
          <FormControl.ErrorMessage>
            Please select location
          </FormControl.ErrorMessage>
        )}
      </FormControl>
    </View>
  );
};

export default FormEHRAndLocationSelect;
