import { HStack, Text, VStack, View } from 'native-base';
import { IFormCommonData, IFormComponentProps } from '../CustomWrapper/CustomWrapper';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { IFormValidationOutput } from '../CustomWrapper/interfaces';
import { IRatingColor, IRatingState } from './interfaces';
import { Rate } from 'antd';
import { Colors } from '../../../../../../styles';
import { DEFAULT_RATING_SCALE, NPS_INDEX_COLORS, NPS_TILE_HEIGHT, NPS_TILE_SPACE, NPS_TILE_WIDTH, NPS_TOTAL_SCALE, RatingType, getIconElement, getIconElementName } from './Helper';
import { useContainerDimensions } from '../../../../../CustomHooks/ContainerDimensionHook';
import { CommonDataContext } from '../../../../../../context/CommonDataContext';

const Rating = (props: IFormComponentProps) => {
  const { component, disabled } = props;
  const leftKeyValue = component.leftKey?.trim();
  const rightKeyValue = component.rightKey?.trim();
  const componentRef = useRef();
  const { width } = useContainerDimensions(componentRef);
  const isMobileScreen = width <= 480;
  const fillColor = component.fillColor || Colors.Custom.mainPrimaryPurple;
  const npsUIConfig = {
    width: isMobileScreen ? 22 : NPS_TILE_WIDTH,
    space: NPS_TILE_SPACE,
    height: isMobileScreen ? 32 : NPS_TILE_HEIGHT,
    fontSize: isMobileScreen ? 12 : 14
  };
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const isPreviewMode = contextData.isPreviewMode || false;
  const isAllowToShare = props?.component?.allowToShare &&  props?.component?.allowToShare === false ? false : true;
  const [componentState, setComponentState] = useState<IRatingState>({
    rating: component.selectedValue,
    hoveredItem: undefined,
    showErrors: false,
  });

  const setValue = useCallback((value: any) => {
    try {
      if (!isNaN(value)) {
        const parsedValue = parseFloat(value);
        setComponentState((prev) => {
          return {
            ...prev,
            rating: isNPSRating() ? parsedValue + 1 : value,
          };
        });
      }
    } catch {}
  }, [component.ratingType]);

  const validateData = (currentData: string): IFormValidationOutput => {
    setComponentState((prev) => ({...prev, showErrors: true}));
    if (props.component?.validate?.required) {
      return { isValid: !!currentData, message: !currentData ? `${component.label} is required` : '' };
    }
    return {isValid: true, message: ''};
  };

  props.setValueRef.current = setValue;
  props.validateRef.current = validateData;

  useEffect(() => {
    let rating = componentState?.rating;
    if (rating !== undefined && isNPSRating()) {
      rating -= 1;
    }
    props.onChange(rating);
  }, [componentState.rating]);

  const renderItem = (data: any) => {
    const displayValue = (componentState.hoveredItem !== undefined ? componentState.hoveredItem : componentState.rating) || 0;
    const shouldHighlight = data.index < displayValue;
    // NPS Rating
    if (isNPSRating()) {
      return renderNPSRating(data.index, shouldHighlight);
    }
    // Normal Rating
    return renderNormalRating(data.index, shouldHighlight);
  }

  const renderNormalRating = (index: number, shouldHighlight: boolean) => {
    const colors: IRatingColor = {
      filledIcon: fillColor,
      unfilledIcon: disabled ? Colors.Custom.Gray300 : Colors.Custom.Gray500,
    }
    const iconElem = getIconElement(component.ratingType, shouldHighlight, colors);
    return (
      <VStack alignItems={'center'} key={index} space={2}>
        {iconElem}
        {component?.showRatingValue && (
          <Text fontWeight={400} color={Colors.Custom.Gray400}>
            {index + 1}
          </Text>
        )}
      </VStack>
    );
  }

  const renderNPSRating = (index: number, shouldHighlight: boolean) => {
    const highlightColor = component.usePlainColor ? fillColor : NPS_INDEX_COLORS[index];
    return (
      <View
        key={index}
        borderWidth={1}
        borderRadius={4}
        borderColor={shouldHighlight ? highlightColor : (disabled ? Colors.Custom.Gray300 : Colors.Custom.Gray400)}
        alignItems={'center'}
        justifyContent={'center'}
        backgroundColor={shouldHighlight ? highlightColor : Colors.Custom.FontColorWhite}
        style={{
          width: npsUIConfig.width,
          height: npsUIConfig.height,
        }}
      >
        <Text
          textAlign={'center'}
          fontWeight={400}
          fontSize={npsUIConfig.fontSize}
          color={shouldHighlight ? Colors.Custom.FontColorWhite : (disabled ? Colors.Custom.Gray400 : Colors.Custom.Gray900)}>
          {index}
        </Text>
      </View>
    );
  }

  const isNPSRating = () => {
    return component.ratingType === RatingType.nps;
  }

  const getRatingScale = (): number => {
    return isNPSRating() ? NPS_TOTAL_SCALE : (component?.ratingScale || DEFAULT_RATING_SCALE);
  }

  const getNPSSubtitleWidth = (): number => {
    return (npsUIConfig.width * NPS_TOTAL_SCALE) + (npsUIConfig.space * (NPS_TOTAL_SCALE - 1));
  }

if (isPreviewMode) {
  return (
    <div className={` ${isAllowToShare ? '' : 'disallow-to-share'}`}>
    <Rate
      key={`${component?.id}_${component?.ratingType}`}
      character={renderItem}
      disabled={true}
      value={componentState?.rating}
      count={getRatingScale()}
    />
    </div>
  );
}

  return (
    <VStack marginY={2} space={1} ref={componentRef}>
      <Rate
        key={`${component?.id}_${component?.ratingType}`}
        allowClear
        character={renderItem}
        onHoverChange={(value) => {
          setComponentState((prev) => ({...prev, hoveredItem: value}));
        }}
        disabled={disabled}
        value={componentState?.rating}
        count={getRatingScale()}
        onChange={(value) => {
          setComponentState((prev) => ({...prev, rating: value}));
        }}
      />
      {(isNPSRating() && (leftKeyValue || rightKeyValue)) && (
        <HStack width={getNPSSubtitleWidth()}>
          {leftKeyValue && (<Text flex={1} fontWeight={400} color={Colors.Custom.Gray400}>{leftKeyValue}</Text>)}
          {leftKeyValue && rightKeyValue && (<View flex={0.25} />)}
          {rightKeyValue && (<Text flex={1} fontWeight={400} color={Colors.Custom.Gray400} textAlign={'end'}>{rightKeyValue}</Text>)}
        </HStack>
      )}
    </VStack>
  );
}

export default Rating;
