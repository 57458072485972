import React, {useContext, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {CommonDataContext} from '../../../../../../../../../context/CommonDataContext';
import {isVirtualLocationDisabled} from '../../../../../../../../../utils/commonUtils';
import {useAppointmentDataContext} from '../../../contexts/AppointmentDataContext';
import {ISlot} from '../../../../../BookAppointment/Interfaces';
import {
  getUpdatedErrorMessages,
  isVirtualAppointmentType,
} from '../../../AppointmentBookingHelper';
import Stack from '../../../../../../../LayoutComponents/Stack';
import {Skeleton} from 'antd';
import {AppointmentAvailabilityCode} from '../../../../../../../../RightSideContainer/AccountSettings/AppointmentTypes/constants';
import DayWiseSlots from './DayWiseSlots';
import {Skeleton as NativeSkeleton, Spinner, View} from 'native-base';
import {isWeb} from '../../../../../../../../../utils/platformCheckUtils';
import useEHRCapabilities from '../../../../../../../../../screens/BusinessStudio/useEHRCapabilities';

const SlotView = (props: {isByDate?: boolean}) => {
  const {appointmentBookingState} = useAppointmentDataContext();
  const intl = useIntl();
  const contextData = useContext(CommonDataContext);
  const userSettings = contextData.userSettings;
  const ehrCapabilities = useEHRCapabilities({locationId: appointmentBookingState?.selectedAccountLocationId});
  const disAllowVirtualLocation = isVirtualLocationDisabled(
    userSettings,
    ehrCapabilities
  );
  const disallowToScheduleForOtherLocation =
    userSettings['disallow_to_schedule_for_other_location']?.value === 'True' ||
    false;
  const isVirtualVisit = isVirtualAppointmentType(
    appointmentBookingState.selectedAppointmentType,
    appointmentBookingState.selectedLocationType
  );
  const isVirtualLocation =
    isVirtualVisit &&
    !disAllowVirtualLocation &&
    !disallowToScheduleForOtherLocation;

  const dummyArray = useMemo(() => Array.from({length: 15}, (v, k) => k), []);

  if (appointmentBookingState.slotLoading) {
    if (appointmentBookingState.isSpecificTimeView) {
      return isWeb() ? (
        <Skeleton.Button active size="small" style={{width: 80, margin: 4}} />
      ) : (
        <View h={200} justifyContent={'center'} alignItems={'center'} w='100%'>
        <Spinner size={'lg'} />
      </View>
      );
    }
    return (
      <Stack
        direction="row"
        style={{
          width: '100%',
          flexWrap: 'wrap',
        }}
      >
        {isWeb() ? (
          <>
            {dummyArray.map((item, index) => (
              <Skeleton.Button
                active
                size="small"
                style={{width: 80, margin: 4}}
              />
            ))}
          </>
        ) : (
          <View h={200} justifyContent={'center'} alignItems={'center'} w='100%'>
            <Spinner size={'lg'} />
          </View>
        )}
        {}
      </Stack>
    );
  }
  return (
    <Stack
      key={appointmentBookingState.selectSlotByView}
      direction="row"
      style={{
        width: '100%',
        flexWrap: 'wrap',
      }}
    >
      <DayWiseSlots />
    </Stack>
  );
};

export default React.memo(SlotView);
