import React from 'react';
import {Path,Svg} from 'react-native-svg';

const TriagedStatusSvg = (props: any) => {
  return (
    <Svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <Path
        d="M10.1464 10.8536C10.3417 11.0488 10.6583 11.0488 10.8536 10.8536C11.0488 10.6583 11.0488 10.3417 10.8536 10.1464L10.1464 10.8536ZM4.62759 5.50003C4.44572 5.29225 4.12983 5.27124 3.92205 5.45312C3.71426 5.635 3.69325 5.95088 3.87513 6.15867L4.62759 5.50003ZM5.04033 6.73072L4.6641 7.06003L5.04033 6.73072ZM5.775 6.75L6.13344 7.0986V7.0986L5.775 6.75ZM8.0898 5.08704C8.28233 4.88908 8.27792 4.57253 8.07996 4.38C7.88201 4.18748 7.56545 4.19188 7.37293 4.38984L8.0898 5.08704ZM9.55 5.775C9.55 7.85987 7.85987 9.55 5.775 9.55V10.55C8.41216 10.55 10.55 8.41216 10.55 5.775H9.55ZM5.775 9.55C3.69013 9.55 2 7.85987 2 5.775H1C1 8.41216 3.13784 10.55 5.775 10.55V9.55ZM2 5.775C2 3.69013 3.69013 2 5.775 2V1C3.13784 1 1 3.13784 1 5.775H2ZM5.775 2C7.85987 2 9.55 3.69013 9.55 5.775H10.55C10.55 3.13784 8.41216 1 5.775 1V2ZM8.57145 9.27855L10.1464 10.8536L10.8536 10.1464L9.27855 8.57145L8.57145 9.27855ZM3.87513 6.15867L4.6641 7.06003L5.41656 6.4014L4.62759 5.50003L3.87513 6.15867ZM6.13344 7.0986L8.0898 5.08704L7.37293 4.38984L5.41656 6.4014L6.13344 7.0986ZM4.6641 7.06003C5.04868 7.49941 5.72633 7.51719 6.13344 7.0986L5.41656 6.4014H5.41656L4.6641 7.06003Z"
        fill="#12B76A"
      />
    </Svg>
  );
};

export default TriagedStatusSvg;
