export const XXL_CONFIG = {
  xxlLight: {
    fontSize: 30,
    fontWeight: 300,
    fontFamily: 'Manrope-Regular',
  },
  xxlNormal: {
    fontSize: 30,
    fontFamily: 'Manrope-Regular',
  },
  xxlMedium: {
    fontSize: 30,
    fontFamily: 'Manrope-Medium',
  },
  xxlSemibold: {
    fontSize: 30,
    fontFamily: 'Manrope-SemiBold',
  },
  xxlBold: {
    fontSize: 30,
    fontFamily: 'Manrope-Bold',
  },
};

export const XSL_CONFIG = {
  xslLight: {
    fontSize: 24,
    fontWeight: 300,
    fontFamily: 'Manrope-Regular',
  },
  xslNormal: {
    fontSize: 24,
    fontFamily: 'Manrope-Regular',
  },
  xslMedium: {
    fontSize: 24,
    fontFamily: 'Manrope-Medium',
  },
  xslSemibold: {
    fontSize: 24,
    fontFamily: 'Manrope-SemiBold',
  },
  xslBold: {
    fontSize: 24,
    fontFamily: 'Manrope-Bold',
  },
};
export const XL_CONFIG = {
  xlLight: {
    fontSize: 20,
    fontWeight: 300,
    fontFamily: 'Manrope-Regular',
  },
  xlNormal: {
    fontSize: 20,
    fontFamily: 'Manrope-Regular',
  },
  xlMedium: {
    fontSize: 20,
    fontFamily: 'Manrope-Medium',
  },
  xlSemibold: {
    fontSize: 20,
    fontFamily: 'Manrope-SemiBold',
  },
  xlBold: {
    fontSize: 20,
    fontFamily: 'Manrope-Bold',
    fontWeight: 600,
  },
  xlBoldNew: {
    fontSize: 20,
    fontFamily: 'Manrope-Bold',
    fontWeight: 700,
  },
};

export const LG_CONFIG = {
  lgLight: {
    fontSize: 18,
    fontWeight: 300,
    fontFamily: 'Manrope-Regular',
  },
  lgNormal: {
    fontSize: 18,
    fontFamily: 'Manrope-Regular',
  },
  lgMedium: {
    fontSize: 18,
    fontFamily: 'Manrope-Medium',
  },
  lgSemibold: {
    fontSize: 18,
    fontFamily: 'Manrope-SemiBold',
  },
  lgBold: {
    fontSize: 18,
    fontFamily: 'Manrope-Bold',
    fontWeight: 600,
  },
};

export const MD_CONFIG = {
  mdLight: {
    fontSize: 16,
    fontWeight: 300,
    fontFamily: 'Manrope-Regular',
  },
  mdNormal: {
    fontSize: 16,
    fontFamily: 'Manrope-Regular',
    fontWeight: 400,
  },
  mdMedium: {
    fontSize: 16,
    fontFamily: 'Manrope-Medium',
    fontWeight: 400,
  },
  mdSemibold: {
    fontSize: 16,
    fontFamily: 'Manrope-SemiBold',
    fontWeight: 500,
  },
  mdBold: {
    fontSize: 16,
    fontFamily: 'Manrope-Bold',
    fontWeight: 600,
  },
};

export const SM_CONFIG = {
  smLight: {
    fontSize: 14,
    fontWeight: 300,
    fontFamily: 'Manrope-Regular',
  },
  smNormal: {
    fontSize: 14,
    fontFamily: 'Manrope-Regular',
  },
  smRegular: {
    fontSize: 14,
    fontFamily: 'Manrope-Regular',
    fontWeight: 400,
  },
  smMedium: {
    fontSize: 14,
    fontFamily: 'Manrope-Medium',
    fontWeight: 400,
  },
  smSemibold: {
    fontSize: 14,
    fontFamily: 'Manrope-SemiBold',
    fontWeight: 500,
  },
  smBold: {
    fontSize: 14,
    fontFamily: 'Manrope-Bold',
    fontWeight: 600,
  },
};

export const XS_CONFIG = {
  xsLight: {
    fontSize: 12,
    fontWeight: 300,
    fontFamily: 'Manrope-Regular',
  },
  xsNormal: {
    fontSize: 12,
    fontFamily: 'Manrope-Regular',
  },
  xsMedium: {
    fontSize: 12,
    fontFamily: 'Manrope-Medium',
  },
  xsSemibold: {
    fontSize: 12,
    fontFamily: 'Manrope-SemiBold',
  },
  xsBold: {
    fontSize: 12,
    fontFamily: 'Manrope-Bold',
  },
};

export const EXS_CONFIG = {
  exsLight: {
    fontSize: 10,
    fontWeight: 300,
    fontFamily: 'Manrope-Regular',
  },
  exsNormal: {
    fontSize: 10,
    fontFamily: 'Manrope-Regular',
  },
  exsMedium: {
    fontSize: 10,
    fontFamily: 'Manrope-Medium',
  },
  exsSemibold: {
    fontSize: 10,
    fontFamily: 'Manrope-SemiBold',
  },
  exsBold: {
    fontSize: 10,
    fontFamily: 'Manrope-Bold',
  },
};
