import React from 'react';
import {ICommonSvgProps} from './interfaces';

const RedirectSvg = (props: ICommonSvgProps) => {
  const {customStrokeColor} = props;
  return (
    <svg
      width={props?.width || '12'}
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={customStrokeColor ? customStrokeColor : '#8C5AE2'}
        d="M6.14645 5.14645C5.95118 5.34171 5.95118 5.65829 6.14645 5.85355C6.34171 6.04882 6.65829 6.04882 6.85355 5.85355L6.14645 5.14645ZM11 1H11.5C11.5 0.723858 11.2761 0.5 11 0.5V1ZM8.32812 0.5C8.05198 0.5 7.82812 0.723858 7.82812 1C7.82812 1.27614 8.05198 1.5 8.32812 1.5V0.5ZM10.5 3.67188C10.5 3.94802 10.7239 4.17188 11 4.17188C11.2761 4.17188 11.5 3.94802 11.5 3.67188H10.5ZM11.5 6C11.5 5.72386 11.2761 5.5 11 5.5C10.7239 5.5 10.5 5.72386 10.5 6H11.5ZM6 1.5C6.27614 1.5 6.5 1.27614 6.5 1C6.5 0.723858 6.27614 0.5 6 0.5V1.5ZM6.85355 5.85355L11.3536 1.35355L10.6464 0.646447L6.14645 5.14645L6.85355 5.85355ZM11 0.5H8.32812V1.5H11V0.5ZM10.5 1V3.67188H11.5V1H10.5ZM6 10.5C4.80735 10.5 3.96317 10.4989 3.32344 10.4129C2.6981 10.3289 2.34352 10.1719 2.08579 9.91421L1.37868 10.6213C1.85318 11.0958 2.45397 11.305 3.1902 11.404C3.91204 11.5011 4.83562 11.5 6 11.5V10.5ZM0.5 6C0.5 7.16438 0.498938 8.08796 0.595988 8.8098C0.694971 9.54603 0.904179 10.1468 1.37868 10.6213L2.08579 9.91421C1.82805 9.65648 1.67115 9.3019 1.58707 8.67656C1.50106 8.03683 1.5 7.19265 1.5 6H0.5ZM10.5 6C10.5 7.19265 10.4989 8.03683 10.4129 8.67656C10.3289 9.3019 10.1719 9.65648 9.91421 9.91421L10.6213 10.6213C11.0958 10.1468 11.305 9.54603 11.404 8.8098C11.5011 8.08796 11.5 7.16438 11.5 6H10.5ZM6 11.5C7.16438 11.5 8.08796 11.5011 8.8098 11.404C9.54603 11.305 10.1468 11.0958 10.6213 10.6213L9.91421 9.91421C9.65648 10.1719 9.3019 10.3289 8.67656 10.4129C8.03683 10.4989 7.19265 10.5 6 10.5V11.5ZM6 0.5C4.83562 0.5 3.91204 0.498938 3.1902 0.595988C2.45397 0.694971 1.85318 0.904179 1.37868 1.37868L2.08579 2.08579C2.34352 1.82805 2.6981 1.67115 3.32344 1.58707C3.96317 1.50106 4.80735 1.5 6 1.5V0.5ZM1.5 6C1.5 4.80735 1.50106 3.96317 1.58707 3.32344C1.67115 2.6981 1.82805 2.34352 2.08579 2.08579L1.37868 1.37868C0.904179 1.85318 0.694971 2.45397 0.595988 3.1902C0.498938 3.91204 0.5 4.83562 0.5 6H1.5Z"
      />
    </svg>
  );
};

export default RedirectSvg;
