import {FormControl, HStack, TextArea, VStack} from 'native-base';
import React from 'react';
import { ISearchFieldProps } from '../../../../../../Interfaces/CommonInterfaces';
import { Colors } from '../../../../../../styles';
import { TestIdentifiers } from '../../../../../../testUtils';
import { testID } from '../../../../../../testUtils/Utils';
import PageBreakWrapper from '../../../../../PublicPages/AppointmentBookingWidget/PageBreakWrapper';
import { DisplayText } from '../../../../DisplayText/DisplayText';

const EventName = (props: ISearchFieldProps) => {
  const {
    value,
    isShowError,
    isDisabled,
    isRequired,
    customLabel,
    customPlaceHolder,
    customStyles,
    numberOfLines,
    errorMessage,
    showLabel,
    onChange,
  } = props;
  return (
    <PageBreakWrapper>
    <VStack>
      <FormControl
        isRequired={isRequired}
        isDisabled={isDisabled}
      >
        <FormControl.Label marginLeft={0}>
          {showLabel && (
            <HStack space={1} alignItems={'center'}>
              <DisplayText
                size={'smMedium'}
                extraStyles={{
                  color: Colors.Custom.Gray500,
                  fontWeight: '400',
                  fontSize: 14,
                }}
                textLocalId={customLabel || 'eventName' || ''}
              />
            </HStack>
          )}
        </FormControl.Label>
        <TextArea
          isDisabled={isDisabled}
          isRequired={isRequired}
          placeholder={customPlaceHolder || 'Enter Event Name'}
          rounded="lg"
          borderWidth={0.5}
          backgroundColor={customStyles?.backgroundColor || '#fff'}
          borderRadius={customStyles?.borderRadius || 4}
          borderColor={customStyles?.borderColor || Colors.FoldPixel.GRAY250}
          numberOfLines={numberOfLines || 3}
          totalLines={numberOfLines || 3}
          fontSize={customStyles?.fontSize || 12}
          _focus={{
            borderColor: Colors.Custom.BorderColor,
            style: {
              borderColor: Colors.Custom.BorderColor,
            },
          }}
          fontWeight={300}
          value={value}
          onChangeText={(eventName) => {
            onChange({code: undefined, displayName: eventName});
          }}
          {...testID(TestIdentifiers.eventName)}
        />
        {!!errorMessage && (
          <FormControl.ErrorMessage
            _text={{
              fontSize: 'xs',
              color: 'error.500',
              fontWeight: '500',
            }}
          >
            {errorMessage}
          </FormControl.ErrorMessage>
        )}
      </FormControl>
    </VStack>
    </PageBreakWrapper>
  );
};

export default EventName;
