export enum VisitType {
    OP = 'OP visits',
    ER = 'ER visits',
    Speciality = 'Specialty visits',
    IP = 'IP visits',
    homeVisit = 'Home Healthcare',
    others = 'Others'
}

export enum VisitCodes {
    OP = 'OP',
    IP = 'IP',
    ER = 'ER',
    Speciality = 'Speciality',
    homeVisit = 'Home Healthcare',
    others = 'Others'
}

export enum EncounterStatus {
  planned = 'Planned',
  arrived = 'Arrived',
  triaged = 'Triaged',
  inProgress = 'In Progress',
  onleave = 'On Leave',
  finished = 'Finished',
  cancelled = 'Cancelled',
  enteredInError = 'Entered in Error',
  unknown = 'Unknown',
}

export const visitTypesWithCodes = [
    {
        visitType: "OP visits",
        visitCode: "OP",
    },
    {
        visitType: "IP visits",
        visitCode: "IP",
    },
    {
        visitType: "ER visits",
        visitCode: "ER",
    },
    {
        visitType: "Specialty visits",
        visitCode: "Speciality",
    },
    {
        visitType: "Home Visit",
        visitCode: "Home Visit",
    }
];