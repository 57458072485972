import {useLazyQuery} from '@apollo/client';
import {Drawer, Select as AntSelect} from 'antd';
import {
  Checkbox,
  FormControl,
  Input,
  Select,
  useMediaQuery,
  useToast,
  View,
  VStack,
} from 'native-base';
import {useContext, useState} from 'react';
import {
  ACCOUNTS_AND_ROLES_ACTION_CONST,
  BUTTON_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
  MLOV_CATEGORY,
} from '../../../../../constants';
import {FHIR_RESOURCE} from '../../../../../constants/FhirConstant';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {IMlov} from '../../../../../Interfaces';
import {getPractitionersData} from '../../../../../services/CommonService/AidBoxService';
import BaseService from '../../../../../services/CommonService/BaseService';
import EmployerQueries from '../../../../../services/Employer/EmployerQueries';
import UserPracticeLocationQueries from '../../../../../services/Location/UserPracticeLocationQueries';
import {Colors} from '../../../../../styles';
import { getEHRName, getResourceAbilities } from '../../../../../utils/capabilityUtils';
import {
  getAccountId,
  getAccountUUID,
  getUserUUID,
  isValidEmail,
} from '../../../../../utils/commonUtils';
import {showToast, ToastType} from '../../../../../utils/commonViewUtils';
import {getMlovIdFromCodeAndCategory, getMlovListByCategory, getMlovListFromCategory} from '../../../../../utils/mlovUtils';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {
  ehrCodeDisplay,
  InviteUserDrawerProps,
  PractitionerData,
  SingleEmployer,
  UserData,
} from './interfaces';
import {styles} from './InviteUserDrawerStyles';
import {
  getErrorMessage,
  getFinalPractitionerData,
  getFormattedPractitionerData,
  getPostRequestBody,
  inviteUser,
  validateForm,
} from './InviteUserDrawerUtils';
import './inviteUserDrawer.css';
import { getEhrList } from '../../../../../services/CommonService/IntegrationService';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import SkeletonLoader from '../AddEditUser/components/SkeletonLoader';
import { useIntl } from 'react-intl';
import AssociatedAccountView from '../AssociatedAccountView/AssociatedAccountView';
import {IAccountRole, ILocationWiseRoles} from '../AssociatedAccountView/interface';
import {GLOBAL_ROLES} from '../../../../../constants/MlovConst';
import { Text } from 'react-native';
import { ModalActionSubTitle } from '../../../../common/ModalActionTitle/ModalActionSubTitle';
import { ModalActionAntSelect } from '../../../../common/ModalActionCommonComponent/ModalActionAntSelect';

const {Label, ErrorMessage} = FormControl;

const InviteUserDrawer = (props: InviteUserDrawerProps) => {
  const {isOpen, onClose} = props;

  const [errors, setErrors] = useState<any>({});
  const accountId = getAccountId();
  const accountUuid = getAccountUUID()
  const [userData, setUserData] = useState<UserData>({
    firstName: '',
    lastName: '',
    role: '',
    email: '',
    externalUserId: '',
    employerId: '',
    employerName: '',
    id: -1,
    edit: false,
    roleName: '',
    associatedAccounts: [
      {
        id: 1,
        accountUuid: '',
        accountId: 0,
        externalUserId: '',
        rolesToAdd: [] as string[],
        rolesToDelete: [] as string[],
        locationGroupId: '',
      },
    ] as IAccountRole[]
  });
  const [stateData, setStateData] = useState({
    usersData: [] as UserData[],
    isGloabalAdminSelected: true,
    selectedGlobalRoles: [] as string[]
  });
  const [userRoles, setUserRoles] = useState([] as IMlov[]);
  const [globalUserRoles, setGlobalUserRoles] = useState([] as IMlov[]);
  const [loading, setLoading] = useState(false);
  const [practitionerData, setPractitionerData] = useState<PractitionerData[]>(
    []
  );
  const [employerList, setEmployerList] = useState<SingleEmployer[]>([]);

  const toast = useToast();
  const commonData = useContext(CommonDataContext);
  const tenantId = getAccountUUID();
  const userId = getUserUUID();
  const intl = useIntl();


  const practitionerAbilities = getResourceAbilities(
    FHIR_RESOURCE.PRACTITIONER,
    '',
    ''
  );
  const showPractitionerDropdown = practitionerAbilities?.isEnabled || false;
  const [ehrList, setEhrList] = useState<ehrCodeDisplay[]>([]);



  const baseService = BaseService.getSharedInstance().axios;

  const [getAccountUserExternalUserId] = useLazyQuery(
    UserPracticeLocationQueries.GetAccountUserExternalUserId
  );

  const [getUserEmail] = useLazyQuery(
    UserPracticeLocationQueries.GET_USER_EMAIL
  );

  const [getEmployers] = useLazyQuery(EmployerQueries.employersSearch, {
    fetchPolicy: 'no-cache',
    variables: {
      accountUuid: tenantId,
      query: '%%',
    },
  });


  const handleInviteUser = (body: any) => {
    inviteUser({baseService: baseService, body: body})
      .then((response) => {
        const duplicateEmailExist = response.data?.duplicateUserEmailList
          ?.length
          ? response.data?.duplicateUserEmailList
          : [];
        if (props.onCallBackAction && duplicateEmailExist.length) {
          props.onCallBackAction?.(
            COMMON_ACTION_CODES.DUPLICATE,
            duplicateEmailExist
          );
        }
        if (!(duplicateEmailExist && duplicateEmailExist.length > 0)) {
          showToast(toast, intl.formatMessage({id: 'userInvited'}), ToastType.success);
        }
        onClose(true);
      })
      .catch((error) => {
        showToast(toast, getErrorMessage(error), ToastType.error);
        setLoading(false);
        onClose(true);
      });
  };

  const handleSubmit = (data: any) => {
    setErrors({});
    handleInviteUser(data);
  };

  const getInitialDetail = () => {
    const userRoles =
      (getMlovListFromCategory(commonData.MLOV, MLOV_CATEGORY.USER_ROLES) || [])
        ?.sort((a, b) => a?.value.localeCompare(b?.value));
    setUserRoles((prev) => [
      ...userRoles,
      {
        categoryId: '',
        code: '',
        id: '',
        value: '',
      },
    ]);
    try {
      setLoading(true);
      const promiseList = [
        getEmployers(),
        ...(showPractitionerDropdown
          ? [
              getEhrList(),
              getPractitionersData(),
              getAccountUserExternalUserId({
                variables: { accountId: accountId },
              }),
            ]
          : []),
      ];
      Promise.all(promiseList)
        .then((response) => {
          setEmployerList(response?.[0]?.data?.employers || []);
          if (showPractitionerDropdown) {
            if (response?.[1]?.data?.expansion?.contains?.length > 0) {
              setEhrList(response?.[1]?.data?.expansion?.contains);
            }
            const data = getFormattedPractitionerData(response[2].data);
            const accountUsers = response[3];
            const list = getFinalPractitionerData(data, accountUsers);
            setPractitionerData(list);
          }
          setLoading(false);
        })
        .catch((error) => {
          setErrors({
            ...errors,
            practitioner: "Error in fetching practitioners list",
          });
          setPractitionerData([]);
          setEhrList([]);
          setEmployerList([]);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };


  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const drawerWidth = isIPadMiniScreen || isIPadScreen ? '60vw' : '38vw';

  const checkForDuplicateEmail = async (email: string) => {
    const isEmailExist = stateData?.usersData.some((userData) => {
      return userData?.email === email;
    });
    const isUserEmailExist = await getUserEmail({
      variables: {
        email: email,
      },
    });

    setErrors({
      ...errors,
      duplicateEmail:
        isEmailExist || isUserEmailExist?.data?.users.length
          ? 'Email already exist'
          : '',
      invalidEmail: !isValidEmail(email) ? 'Please enter a valid email' : '',
    });
    if (
      isEmailExist ||
      isUserEmailExist?.data?.users.length ||
      !isValidEmail(email)
    ) {
      return true;
    }
    return false;
  };

  const getInviteUserField = (userData?: any) => {
    return (
      <View mb={2}>
        <ModalActionSubTitle subTitle={'Basic Info'} />
        <FormControl
          style={styles.formElement}
          isRequired
          isInvalid={errors.firstName}
        >
          <Label style={styles.labelText}>
            <DisplayText textLocalId="firstName" />
          </Label>
          <Input
          _focus={{
            borderColor: Colors.Custom.Gray200
          }}
            value={
              userData?.id === -1
                ? userData?.firstName
                : stateData?.usersData[userData?.id]?.firstName
            }
            onChangeText={(firstName) => {
              if (userData?.id === -1) {
                setUserData((prev) => {
                  return {
                    ...prev,
                    firstName: firstName,
                  };
                });
              } else {
                const updatedUserData = stateData?.usersData.map((user) => {
                  if (user.id === userData?.id) {
                    return {...user, firstName: firstName};
                  } else {
                    return user;
                  }
                });
                setStateData((prev) => {
                  return {
                    ...prev,
                    usersData: updatedUserData,
                  };
                });
              }
            }}
          />
          {errors.firstName && (
            <ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {errors.firstName}
            </ErrorMessage>
          )}
        </FormControl>

        <FormControl
          style={styles.formElement}
          isRequired
          isInvalid={errors.lastName}
        >
          <Label style={styles.labelText}>
            <DisplayText textLocalId="lastName" />
          </Label>
          <Input
          _focus={{
            borderColor: Colors.Custom.Gray200
          }}
            value={
              userData?.id === -1
                ? userData?.lastName
                : stateData?.usersData[userData?.id]?.lastName
            }
            onChangeText={(lastName) => {
              if (userData?.id === -1) {
                setUserData((prev) => {
                  return {
                    ...prev,
                    lastName: lastName,
                  };
                });
              } else {
                const updatedUserData = stateData?.usersData.map((user) => {
                  if (user.id === userData?.id) {
                    return {...user, lastName: lastName};
                  } else {
                    return user;
                  }
                });
                setStateData((prev) => {
                  return {
                    ...prev,
                    usersData: updatedUserData,
                  };
                });
              }
            }}
          />
          {errors.lastName && (
            <ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {errors.lastName}
            </ErrorMessage>
          )}
        </FormControl>

        <FormControl
          style={styles.formElement}
          isRequired
          isInvalid={
            errors.email || errors.duplicateEmail || errors.invalidEmail
          }
        >
          <Label style={styles.labelText}>
            <DisplayText textLocalId="email" />
          </Label>
          <Input
          _focus={{
            borderColor: Colors.Custom.Gray200
          }}
            value={
              userData?.id === -1
                ? userData?.email
                : stateData?.usersData[userData?.id]?.email
            }
            onBlur={() => {
              checkForDuplicateEmail(userData?.email);
            }}
            onChangeText={(email) => {
              if (userData?.id === -1) {
                setUserData((prev) => {
                  return {
                    ...prev,
                    email: email,
                  };
                });
              } else {
                const updatedUserData = stateData?.usersData.map((user) => {
                  if (user.id === userData?.id) {
                    return {...user, email: email};
                  } else {
                    return user;
                  }
                });
                setStateData((prev) => {
                  return {
                    ...prev,
                    usersData: updatedUserData,
                  };
                });
              }
            }}
          />
          {(errors.duplicateEmail || errors.email || errors.invalidEmail) && (
            <ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {errors.duplicateEmail || errors.email || errors.invalidEmail}
            </ErrorMessage>
          )}
        </FormControl>

        <AssociatedAccountView
          userData={userData}
          isDisabled={false}
          errors={errors}
          selectedGlobalRoles={userData.selectedGlobalRoles}
          associatedAccounts={userData.associatedAccounts}
          onActionPerformed={(code: string,data: any)=> {
            if (code === ACCOUNTS_AND_ROLES_ACTION_CONST.LOCATION_AND_ROLE) {
              setUserData((prev)=> {
                return {
                  ...prev,
                  associatedAccounts: data
                }
              })
            } else if (code === ACCOUNTS_AND_ROLES_ACTION_CONST.GLOBAL_USER_ROLES) {
              setUserData((prev)=> {
                return {
                  ...prev,
                  selectedGlobalRoles: data
                }
              })
              setStateData(prev=> {
                return {
                  ...prev,
                  selectedGlobalRoles: data
                }
              })
            }
          }}
        />
      </View>
    );
  };
  const getUpdatedUserData = (userData: UserData) => {
    let globalRoles = {}
    const {selectedGlobalRoles} = userData
    const locationWiseRoles: ILocationWiseRoles[]  = []
    userData?.associatedAccounts?.forEach((account) => {
      if (account.rolesToAdd?.length) {
        locationWiseRoles.push({
          accountLocationId: account.locationUuid || '',
          externalUserId: account.externalUserId || '',
          accountRoleIdsToAdd: account.rolesToAdd || [],
          locationGroupId: account.locationGroupId || ''
        })
      }
    });
    if (selectedGlobalRoles && selectedGlobalRoles?.length > 0) {
       globalRoles = {
        accountRoleIdsToAdd: selectedGlobalRoles
      }
    }
    userData.associatedAccounts = [
      {
        accountUuid: accountUuid,
        accountId: accountId || -1 ,
        locationWiseRoles: locationWiseRoles,
        globalRoles: globalRoles
      }
    ]
    return userData
  }

//Removed Bulk Invite User Info

  return (
    <Drawer
      visible={isOpen}
      onClose={onClose}
      width={drawerWidth}
      title={
        <>
          <ModalActionTitle
            title={'inviteUser'}
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'cancel',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  onClose();
                },
              },
              {
                show: true,
                id: 2,
                btnText: 'sendInvitation',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                isLoading: loading,

                onClick: async () => {
                  setLoading(true);
                  const isEmailDuplicate = await checkForDuplicateEmail(
                    userData?.email
                  );
                  const {isValid, errors, isEmpty, updatedUseData} = validateForm(
                    userData,
                    isEmailDuplicate,
                    {}
                  );
                  if (!isValid) {
                    setUserData(prev=> {
                      return {...updatedUseData}
                    })
                  }
                  if (stateData?.usersData?.length && isValid) {
                    const body = getPostRequestBody([
                      userData,
                      ...stateData?.usersData,
                    ]);
                    handleSubmit(body);
                  } else if (stateData?.usersData?.length === 0 && isValid) {
                    const updatedUserData: UserData = getUpdatedUserData(userData)
                    const body = getPostRequestBody([updatedUserData]);
                    handleSubmit(body);
                  } else if (stateData?.usersData?.length && isEmpty) {
                    const body = getPostRequestBody(stateData?.usersData);
                    handleSubmit(body);
                  } else {
                    setErrors(errors);
                    setLoading(false);
                  }
                },
              },
            ]}
          />
        </>
      }
    >
      <VStack style={[styles.container]}>
      { loading ? ( <SkeletonLoader /> ) :
        (
          <>
            <View style={{
              borderWidth: 0,
              borderStyle: 'solid',
              borderColor: Colors.FoldPixel.GRAY150,
              borderRadius: 12,
              }}>{getInviteUserField(userData)}</View>
              {/* Removed bulk invite option */}
          </>
        )}
      </VStack>
    </Drawer>
  );
};

export default InviteUserDrawer;
