import {USER_ROLE_CODES} from '../../../../constants/MlovConst';
import {getMlovValueFromId} from '../../../../utils/mlovUtils';
import {formatRoleArray} from '../../RightContainer/CareTeamView/CareTeamUtils';
import {
  IPatientCareJourneyResponse,
  ICareTeam,
  ICareTeamMember,
  CareTeamData,
} from './interfaces';
import {v4 as uuidv4} from 'uuid';

const isPcp = (role: string, userId: string, pcpId: string) =>
  role === USER_ROLE_CODES.PHYSICIAN && userId === pcpId;
export const formatCareTeamResponse = (
  journeys: IPatientCareJourneyResponse[],
  memberList: any[],
  roleList: any[]
) => {
  const careTeamList: ICareTeam[] = [];
  journeys.forEach((journey: IPatientCareJourneyResponse) => {
    if (journey?.patientCareJourneyTeam?.length) {
      const careTeamMember: ICareTeam = {
        journeyId: journey.id,
        journeyName: journey?.title || journey?.careJourney?.title,
        createdOn: journey.createdOn,
        patientCareJourneyTeam: getCareTeamMemberList(
          journey,
          memberList,
          roleList
        ),
      };
      careTeamList.push(careTeamMember);
   }
  });
  return careTeamList;
};

const getCareTeamMemberList = (
  journey: IPatientCareJourneyResponse,
  memberList: any[],
  roleList: any[]
) => {
  const teamMemberList: any[] = [];
  if (journey?.patientCareJourneyTeam?.length) {
    (journey.patientCareJourneyTeam || []).forEach((member: any) => {
      if (member.userId && member.roleId) {
        teamMemberList.push({
          userId: member.userId,
          role: getMlovValueFromId(roleList, member.roleId),
          name: getCareTeamMemberName(memberList, member),
        });
      }
    });
  }
  return teamMemberList;
};

const getCareTeamMemberName = (careTeamList: any[], careTeamMember: any) => {
  return careTeamList.find((teamMember: any) => {
    return teamMember.uuid === careTeamMember.userId;
  })?.name;
};

export const getFormatedPrimaryCareTeamData = (
  data: CareTeamData,
  pcpId: string
): ICareTeam => {
  const userCareTeam: ICareTeam = {
    journeyId: uuidv4(),
    journeyName: 'Primary Care Team',
    createdOn: '',
    patientCareJourneyTeam: data.members.map((member: ICareTeamMember) => {
      return {
        userId: member.uuid,
        name: member.name,
        role: formatRoleArray(member.userRoles || [])
          .map((role) => {
            const code = role?.customRoleCode || role?.userRole?.code;
            const value = role?.roleName || role?.userRole?.value
            return isPcp(code, member.uuid, pcpId)
            ? `${value}(PCP)`
            : value
          })
          .join(', '),
      };
    }),
  };
  return userCareTeam;
};
