import {Select, Spin} from 'antd';
import {debounce} from 'lodash';
import React, {useEffect, useState} from 'react';
import {ISearchFieldProps} from '../../../Interfaces';
import BaseService from '../../../services/CommonService/BaseService';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {Colors} from '../../../styles';
import {HStack, Icon, Text, VStack} from 'native-base';
import { ICodeableParam } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/Conditions/interfaces';
import { getCodeSystem } from './PAMISearchHelper';
interface IPAMIItem {
  text: string;
  coding: {
    display: string;
    system: string;
    code: string;
  }[];
}

export enum PAMISearchType {
  problem = 'Problem',
  immunization = 'Immunization',
  allergy = 'Allergy',
  medication = 'Medicine',
  procedure = 'Procedure',
  allergyReactions = 'AllergyReaction',
  diagnosis = 'Diagnosis',
  radiology = 'Radiology',
  lab = 'Lab',
  PastMedicalHistory='Past Medical History'
}

interface IPAMISearchFieldProps extends ISearchFieldProps {
  searchType: PAMISearchType;
  placeholder?: string;
  disabled?: boolean;
  addNewOptionEnabled?: boolean;
  clearOnSelection?: boolean;
  additionalHeaders?: {[index: string]: any};
  skipAPICall?: boolean;
  enablePaginationOnScroll?: boolean;
  showAdditionalDetails?: boolean;
  isInvalid?: boolean;
  locationId?: string | undefined;
}

interface IPAMISearchState {
  loading: boolean;
  searchValue: string;
  pamiData: IPAMIItem[];
  originalData: IPAMIItem[];
  selectedValue?: ICodeableParam;
  offset: number;
  pageSize: number;
  dataFinished: boolean;
  locationId?: string | undefined
}

const PAMISearch = (props: IPAMISearchFieldProps) => {
  const defaultOffset = 0;
  const defaultPageSize = 25;
  const addNewPrefix = "Add "
  const {value, isShowError, searchType, isInvalid, onChange, locationId} = props;

  const [componentState, setComponentState] = useState<IPAMISearchState>({
    loading: false,
    searchValue: '',
    pamiData: [],
    originalData: [],
    selectedValue: undefined,
    offset: defaultOffset,
    pageSize: defaultPageSize,
    dataFinished: false,
    locationId: locationId,
  });

  useEffect(() => {
    if (searchType === PAMISearchType.allergyReactions) {
      searchPAMI('', true);
    } else if (value?.text && componentState.pamiData.length === 0) {
      const item = {
        text: value.text,
        coding: value.coding,
      };
      setComponentState((prev) => ({
        ...prev,
        pamiData: [item],
        originalData: [],
        selectedValue: item.text
      }));
    }
  }, []);

  const searchPAMI = (searchString: string, forceTrigger?: boolean) => {
    const offset = defaultOffset;
    const pageSize = defaultPageSize;
    setComponentState((prev) => ({...prev, searchValue: searchString, offset: offset, pageSize: pageSize, dataFinished: false}));
    if (searchType === PAMISearchType.allergyReactions && componentState.originalData.length && !forceTrigger) {
      const list = componentState.originalData.filter((value) => {
        return value.text.toLowerCase().includes(searchString.toLowerCase());
      });
      if (props.addNewOptionEnabled) {
        list.push({
          text: searchString.trim(),
          coding: [],
        });
      }
      setComponentState((prev) => ({...prev, pamiData: list}));
      return;
    }
    setComponentState((prev) => ({...prev, pamiData: [], originalData: []}));
    if (props.skipAPICall) {
      const list: IPAMIItem[] = [];
      if (props.addNewOptionEnabled) {
        list.push({
          text: searchString.trim(),
          coding: [],
        });
      }
      setComponentState((prev) => ({...prev, pamiData: list, originalData: list}));
    } else if (searchString.length > 1 || forceTrigger) {
      callAPI(searchString, offset, pageSize);
    }
  };

  const callAPI = (searchString: string, offset: number, pageSize: number) => {
    setComponentState((prev) => ({...prev, loading: true}));
    const {axios} = BaseService.getSharedInstance();
    let path = `/integration/api/codes?limit=${pageSize}&offset=${offset}&searchType=${searchType}&searchString=${searchString}`;
    if (searchType === PAMISearchType.allergyReactions) {
      path += '&dataField=reactions';
    }
    if (componentState.locationId) {
      path += `&location=${componentState.locationId}`;
    }
    axios
      .get(
        path,
        {
          headers: props.additionalHeaders,
        }
      )
      .then((result) => {
        let list: IPAMIItem[] = [];
        if (result?.data?.codeable?.length) {
          list = result.data.codeable;
        }
        setComponentState((prev) => {
          let finalList = prev.pamiData;
          const totalCount = list.length;
          if (props.addNewOptionEnabled) {
            finalList.splice(finalList.length - 1, 1);
          }
          finalList = offset !== 0 ? [...finalList, ...list] : list;
          if (props.addNewOptionEnabled) {
            finalList.push({
              text: searchString.trim(),
              coding: [],
            });
          }
          return {
            ...prev,
            loading: false,
            dataFinished: totalCount === 0 || totalCount < prev.pageSize,
            offset: offset,
            pamiData: finalList,
            originalData: finalList
          }
        });
      })
      .catch((error) => {

        setComponentState((prev) => ({...prev, loading: false}));
      });
  }

  const getDataFromId = (id: string) => {
    const matchedData = componentState.pamiData.filter((pami) => {
      return pami.text === id;
    });
    if (matchedData.length > 0) {
      return {
        text: matchedData[0].text,
        coding: matchedData[0].coding,
      };
    }
  };

  const onOptionScroll = (event: any) => {
    const target = event.target
    if (
        props.enablePaginationOnScroll &&
        !componentState.loading &&
        !componentState.dataFinished &&
        target.scrollTop + target.offsetHeight === target.scrollHeight
      ) {
      const offset = componentState.offset + componentState.pageSize;
      setComponentState((prev) => ({...prev, loading: true}));
      target.scrollTo(0, target.scrollHeight);
      callAPI(componentState.searchValue, offset, componentState.pageSize);
    }
  };

  const checkCodingMissingInData = () => {
    return componentState.pamiData.length === 1 && componentState.pamiData.some((pami) => !pami.coding.length)
  }

  const getSelectedValue = () => {
    if (props?.clearOnSelection) {
      return componentState.selectedValue
        ? {
            label: <Text>{componentState.selectedValue}</Text>,
            value: componentState.selectedValue,
            key: componentState.selectedValue,
          }
        : undefined;
    } else {
      return value ? value.text : undefined;
    }
  }

  return (
    <Select
      size="large"
      showSearch
      allowClear
      suffixIcon={
        !componentState.searchValue && !props.skipAPICall ? (
          <Icon
            color={Colors.Custom.Gray500}
            as={AntIcon}
            name={'search1'}
            size="4"
          />
        ) : null
      }
      status={isInvalid ? 'error' : undefined}
      filterOption={false}
      disabled={props.disabled}
      value={getSelectedValue()}
      onSearch={debounce(searchPAMI, 500)}
      onChange={(value: any[], data: any) => {
        if (data?.value) {
          setComponentState((prev) => ({...prev, searchValue: data.value}));
          const selectedValue = getDataFromId(data.value);
          onChange(selectedValue);
          if (props.clearOnSelection) {
            setComponentState((prev) => ({...prev, selectedValue: data.value}));
            setTimeout(() => {
              setComponentState((prev) => ({...prev, selectedValue: undefined}));
            }, 500);
          }
        } else {
          onChange(undefined);
          setComponentState((prev) => ({...prev, searchValue: ''}));
        }
      }}
      placeholder={props.placeholder || 'Search'}
      notFoundContent={
        (componentState.loading ? (
          <Spin size="small" />
        ) : (componentState.searchValue) ? (
          'No Result Found'
        ) : null)
      }
      style={{height: '40px'}}
      className={isShowError && !value ? 'pami-search field-error' : 'pami-search'}
      onPopupScroll={onOptionScroll}
      optionLabelProp={checkCodingMissingInData() ? "label" : undefined}
    >
      {componentState.pamiData.map((pami, index) => {
        return (
          <Select.Option key={`${pami.text}_${index}`} value={pami.text} label={pami.text}>
            {!props.showAdditionalDetails && (
              <Text>
                {props.addNewOptionEnabled && !pami.coding.length ? `${addNewPrefix}${pami.text}` : `${pami.text}`}
              </Text>
            )}
            {props.showAdditionalDetails && (
              <VStack>
                <Text>
                  {props.addNewOptionEnabled && !pami.coding.length ? `${addNewPrefix}${pami.text}` : `${pami.text}`}
                </Text>
                {pami.coding.map((codeData) => {
                  return (
                    <HStack key={codeData.code} space={2} alignItems="baseline">
                      <Text fontSize="2xs" color={Colors.Custom.Gray500}>
                        {getCodeSystem(codeData.system)}
                      </Text>
                      <Text fontSize="xs">
                        {codeData.code}
                      </Text>
                    </HStack>
                  );
                })}
              </VStack>
            )}
          </Select.Option>
        );
      })}
      {componentState.pamiData.length && componentState.loading && (
        <Select.Option key={`${props.searchType}_loading`} disabled>
          <Spin size="small" />
        </Select.Option>
      )}
    </Select>
  );
};

export default PAMISearch;
