import { Text } from 'react-native'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { FormContext, IFormCommonData, IFormComponentProps } from '../CustomWrapper/CustomWrapper'
import { IFormValidationOutput } from '../CustomWrapper/interfaces'
import { CommonDataContext } from '../../../../../../context/CommonDataContext'
import CustomTextAreaField, { CustomTextInputMode } from '../TextAreaComponent/CustomTextAreaField'

const TextFieldComponent = (props: IFormComponentProps) => {
  const { component, disabled } = props;
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const isPreviewMode = contextData.isPreviewMode || false;
  const isAllowToShare = props?.component?.allowToShare &&  props?.component?.allowToShare === false ? false : true;
  const [componentState, setComponentState] = useState({ value: props.component?.selectedValue, showErrors: false});
  const isPatientForm = contextData.formContext === FormContext.patientForm;

  const setValue = useCallback((value: any) => {
    if (value) {
      setComponentState((prev) => {
        return {
          ...prev,
          value,
        };
      });
    }
  }, []);

  const validateData = (currentData: string): IFormValidationOutput => {
    setComponentState((prev) => ({...prev, showErrors: true}));
    if (component?.validate?.required) {
      return { isValid: !!currentData, message: !currentData ? `${component.label} is required` : '' };
    }
    return {isValid: true, message: ''};
  };

  props.setValueRef.current = setValue;
  props.validateRef.current = validateData;

  useEffect(() => {
    props.onChange(componentState.value);
  }, [componentState.value]);

  if (isPreviewMode) {
    const displayValue = componentState.value ?? '-';
    return (
      <div className={` ${isAllowToShare ? '' : 'disallow-to-share'}`}>
        <Text style={{fontSize: 16, fontWeight: '400'}}>{displayValue}</Text>
      </div>
    );
  }

  return (
    <CustomTextAreaField
      inputMode={CustomTextInputMode.textfield}
      value={componentState.value}
      prefix={component.prefix}
      suffix={component.suffix}
      macroTemplatesEnabled={component.macroTemplatesEnabled && !isPatientForm}
      disabled={disabled}
      placeholder={component.placeholder}
      showError={componentState.showErrors && !componentState.value}
      onValueChange={(text) => {
        setComponentState((prev) => {
          return {
            ...prev,
            value: text,
          };
        });
      }}
    />
  )
}

export default TextFieldComponent
