import React, { useContext } from 'react';
import {HStack, Text} from 'native-base';
import {Switch, Tooltip} from 'antd';
import Feather from 'react-native-vector-icons/Feather';
import {Colors} from '../../../../../styles';
import { CustomToggleButton } from '../../../../common/CustomToggleButton/CustomToggleButton';
import { CommonDataContext } from '../../../../../context/CommonDataContext';

interface IShowToPatientSwitchProps {
  value: boolean;
  onChange: (value: boolean) => void;
  loading?: boolean;
  message: string;
  tooltipMessage?: string;
}

const ShowToPatientSwitch = (props: IShowToPatientSwitchProps) => {
  const context = useContext(CommonDataContext);
  const isSidecarContext = context.sidecarContext?.isSidecar;
  return (
    <HStack alignItems={'center'} space={1} flexWrap={'wrap'}>
      {isSidecarContext ? (
        <CustomToggleButton
          size="small"
          value={props.value}
          onChange={props.onChange}
        />
      ) : (
        <Switch
          size="small"
          style={{
            backgroundColor: props.value
              ? Colors.Custom.mainPrimaryPurple
              : Colors.Custom.silverGray,
            width: 10,
          }}
          loading={props.loading}
          checked={props.value}
          onChange={props.onChange}
        />
      )}
      <Text marginLeft={isSidecarContext ? 1 : 0} color={Colors.Custom.Gray500}>{props?.message}</Text>
      {!!props?.tooltipMessage && (
        <Tooltip title={props?.tooltipMessage} placement="top">
          <Feather
            style={{
              marginTop: 2,
            }}
            size={14}
            name="info"
            color={Colors.Custom.Gray500}
          />
        </Tooltip>
      )}
    </HStack>
  );
};

export default ShowToPatientSwitch;
