import { Text, FlatList, Pressable, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import { IRule, IContactRuleListViewProps, IContactRuleListViewState, IContactRule } from '../interfaces'
import { getContactRules } from '../../../../../ContentManagement/CampaignManagement/GroupDetails/PopulationGroupApi';
import { Colors } from '../../../../../../../styles';
import Stack from '../../../../../../common/LayoutComponents/Stack';
import RuleSvg from '../../../../../../common/Svg/RuleSvg';
import AddOrUpdateContactRuleDrawer from './AddOrUpdateContactRuleDrawer';
import ContactRuleTemplateSearchModal from './ContactRuleTemplateSearchModal';
import { CONTACT_RULE_ACTION } from './ContactRulesConstants';
import { transformRuleToContactRule } from './ContactRulesHelper';
import TrashBin2Icon from '../../../../../../../assets/Icons/TrashBin2Icon';
import { Tooltip } from 'antd';
import Feather from 'react-native-vector-icons/Feather';

const ContactRuleListView = (props: IContactRuleListViewProps) => {
  // Constants
  const { ruleType, defaultRules, metadata, onChange } = props;

  // States
  const [componentState, setComponentState] = useState<IContactRuleListViewState>({
    rules: defaultRules || [],
    loading: false,
    selectedAction: undefined,
    showWorkflowAudit: false,
    workflowMasterId: '',
    workflowId: '',
  });

  // APIs
  const getRules = async () => {
    try {
      if (!metadata.contactId) {
        return;
      }
      setComponentState((prev) => ({ ...prev, loading: true }));
      const response = await getContactRules({
        contactUuids: [metadata.contactId],
        // not using category filter for now
        // ...(ruleType && { category: ruleType.ruleEntityType }),
      });
      const rules = response?.data?.data || [];
      setComponentState((prev) => ({ ...prev, loading: false, rules: rules }))
    } catch (error) {
      setComponentState((prev) => ({ ...prev, loading: false }));
    }
  }


  // Life cycle methods
  useEffect(() => {
    if (metadata.fetchRules) {
      getRules();
    }
  }, []);

  useEffect(() => {
    onChange?.(componentState.rules);
  }, [componentState.rules]);


  // Other methods
  const getFilteredData = () => {
    return componentState.rules.filter(item => !item.isDeleted);
  }

  const onDelete = (item: IContactRule, index: number) => {
    setComponentState((prev) => {
      const rules = prev.rules;
      if (index > -1) {
        // In case of template mode directly delete item from list else set isDeleted false
        if (metadata.isTemplateContext) {
          rules.splice(index, 1)
        } else {
          rules[index].rule.isDeleted = true;
          rules[index].isDeleted = true;
        }
      }
      return {
        ...prev,
        rules: [...rules],
      };
    });
  }

  const renderRuleRow = (data: { item: IContactRule, index: number }) => {
    const rule = data.item?.rule;
    const color = metadata.allowedOperations.update ? Colors.Custom.Primary300 : Colors.Custom.Gray400;
    const fontSize = 14;
    return (
      <Stack
        direction='row'
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Pressable
            style={{ paddingVertical: 4 }}
            disabled={!metadata.allowedOperations.update}
            onPress={() => {
              setComponentState((prev) => ({
                ...prev,
                selectedAction: {
                  action: CONTACT_RULE_ACTION.UPDATE,
                  selectedRule: data.item.rule,
                  selectedRuleIndex: data.index
                }
              }));
            }}
          >
            <Stack direction="row" space={12} style={{alignItems: 'center', flex: 1}}>
              <View>
                <RuleSvg size={16} customStrokeColor={color} />
              </View>
              <Text style={{color, fontSize }}>
                {rule?.name}
              </Text>
              {metadata.allowedOperations.delete && (
                <Pressable
                  style={{ marginTop: 1 }}
                  onPress={() => {
                    onDelete(data.item, data.index);
                  }}
                >
                  <TrashBin2Icon size={22} color={color} />
                </Pressable>
              )}
            </Stack>
        </Pressable>
        {!metadata.allowedOperations.update ? (
          <Pressable
            onPress={async () => {
              props?.onOpenWorkflowAuditView?.({
                workflowMasterId: data.item.contactRuleWorkflow?.[0]?.workflowMasterId,
              })
            }}
          >
            <Tooltip
              title={'Show Automation'}
            >
              <Feather
                name="info"
                style={{
                  fontSize: 16,
                  color: Colors.Custom.Gray400,
                  padding: 0,
                  marginLeft: 4,
                }}
              />
            </Tooltip>
          </Pressable>
        ) : (
          <></>
        )}
      </Stack>
    );
  }

  return (
    <Stack direction='column' space={12}>
      <FlatList
        data={getFilteredData()}
        renderItem={renderRuleRow}
        keyExtractor={(item) => `contact_rule_${item?.id}`}
      />
      {metadata.allowedOperations.create && (
        <Pressable
          onPress={() => {
            setComponentState((prev) => ({ ...prev, selectedAction: { action: CONTACT_RULE_ACTION.SEARCH, selectedRule: undefined }}));
          }}
        >
          <Text style={{color: Colors.Custom.Primary300}}>
            {'+ Add rule alert'}
          </Text>
        </Pressable>
      )}
      {[CONTACT_RULE_ACTION.ADD, CONTACT_RULE_ACTION.ADD_WITH_TEMPLATE, CONTACT_RULE_ACTION.SEARCH].includes(componentState.selectedAction?.action || '') && (
        <ContactRuleTemplateSearchModal
          open={true}
          onCancel={() => {
            setComponentState((prev) => ({ ...prev, selectedAction: undefined }));
          }}
          onAddNew={(searchText) => {
            setComponentState((prev) => ({ ...prev, selectedAction: { action: CONTACT_RULE_ACTION.ADD, selectedRule: { name: searchText, rule: {}, displayJson: {}}}}));
          }}
          onTemplateSelect={(rule: IRule) => {
            setComponentState((prev) => ({ ...prev, selectedAction: { action: CONTACT_RULE_ACTION.ADD_WITH_TEMPLATE, selectedRule: rule }}));
          }}
        />
      )}
      {([CONTACT_RULE_ACTION.ADD, CONTACT_RULE_ACTION.ADD_WITH_TEMPLATE, CONTACT_RULE_ACTION.UPDATE].includes(componentState.selectedAction?.action || '')) && (
        <AddOrUpdateContactRuleDrawer
          rule={componentState.selectedAction?.selectedRule}
          ruleType={ruleType}
          isUpdate={componentState.selectedAction?.action === CONTACT_RULE_ACTION.UPDATE}
          createNewTemplate={
            metadata.isTemplateContext ||
            componentState.selectedAction?.action === CONTACT_RULE_ACTION.ADD ||
            (componentState.selectedAction?.action == CONTACT_RULE_ACTION.ADD_WITH_TEMPLATE && componentState.selectedAction?.selectedRule?.isTemplate === false)
          }
          additionalHeaders={metadata.additionalHeaders}
          onSave={(rule: IRule) => {
            setComponentState((prev) => {
              const rules = prev.rules;
              const index = componentState.selectedAction?.selectedRuleIndex;
              if (index !== undefined && index > -1) {
                rules[index].rule = rule;
                rules[index].contactRuleWorkflow = rule.reference?.workflows || [];
              } else {
                rules.push(transformRuleToContactRule(rule, {
                  contactId: metadata.contactId,
                  entityId: metadata.entityId,
                  entityTypeId: metadata.entityTypeId
                }));
              }
              return {
                ...prev,
                rules: [...rules],
                selectedAction: undefined
              };
            });
          }}
          onCancel={() => {
            setComponentState((prev) => ({ ...prev, selectedAction: undefined }));
          }}
         />
      )}
    </Stack>
  )
}

export default ContactRuleListView
