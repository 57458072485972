import React from 'react';
import {ICommonSvgProps} from './interfaces';

const PreferencesEmailSettingsSvg = (props: ICommonSvgProps) => {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.99996 4.66666L5.79904 6.16589C7.32957 7.44134 8.09484 8.07906 8.99996 8.07906C9.90508 8.07906 10.6703 7.44134 12.2009 6.16589L14 4.66666M7.33329 14.6667H10.6666C13.8093 14.6667 15.3807 14.6667 16.357 13.6904C17.3333 12.714 17.3333 11.1427 17.3333 7.99999C17.3333 4.8573 17.3333 3.28595 16.357 2.30964C15.3807 1.33333 13.8093 1.33333 10.6666 1.33333H7.33329C4.1906 1.33333 2.61925 1.33333 1.64294 2.30964C0.666626 3.28595 0.666626 4.8573 0.666626 7.99999C0.666626 11.1427 0.666626 12.714 1.64294 13.6904C2.61925 14.6667 4.1906 14.6667 7.33329 14.6667Z" stroke="#6F7A90" stroke-linecap="round"/>
    </svg>
  );
};

export default PreferencesEmailSettingsSvg;