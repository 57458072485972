import { useLazyQuery } from '@apollo/client';
import { Drawer, notification, Tooltip } from 'antd';
import { Divider, HStack, Text, useToast, View } from 'native-base';
import { useContext, useEffect, useRef, useState } from 'react';
import { Pressable } from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { useNavigate } from 'react-router-dom';
import {
  CONTACT_NOTES_VIEW,
  getInActiveContactError,
  PERSON_TYPES,
  PREFERRED_CHANNEL_CODE,
  RIGHT_SIDE_CONTAINER_CODE
} from '../../../constants';
import {
  COMMON_ACTION_CODES,
  CONVERSATION_ACTION_CODES,
  PATIENT_QUICK_PROFILE_PARENT_CODES
} from '../../../constants/ActionConst';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import { FHIR_RESOURCE } from '../../../constants/FhirConstant';
import { USER_ROLE_CODES } from '../../../constants/MlovConst';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { getPatientDetailsFromHint } from '../../../services/CommonService/AidBoxService';
import BaseService from '../../../services/CommonService/BaseService';
import { GetUsersTaskAccess, GetUsersTaskPoolAccess } from '../../../services/TaskPool/TaskPoolQueries';
import { GET_USER_FOR_SCHEDULE_ACCESS } from '../../../services/User/UserQueries';
import { Colors } from '../../../styles';
import { checkAbilityAccess, checkAccountConfigAbilityAccess, getAccountConfigResourceAbilities, getOrgAbilites, getPatientEHRUrl, getResourceAbilities } from '../../../utils/capabilityUtils';
import {
  canCommunicateWithInactivePatient,
  filterWorkflowUser,
  getAccountId,
  getAccountUUID, getCaslAbility, getLocationGroupIdFromLocationId, getUserData,
  getUserFullName,
  getUserId,
  getUserUUID,
  isActiveContact, isConsentRequiredAndGiven, isEmployerRole,
  isPhysicianRole
} from '../../../utils/commonUtils';
import { EventBus } from '../../../utils/EventBus';
import { getMlovId } from '../../../utils/mlovUtils';
import { getToolTipMessage } from '../../../utils/PersonActionsUtils';
import { PERSON_ACTION_CODES } from '../../PersonOmniView/PersonHeaderBar/PersonAction/PersonActionPopover/ActionConst';
import { PersonActionPopover } from '../../PersonOmniView/PersonHeaderBar/PersonAction/PersonActionPopover/PersonActionPopover';
import { getFormDataFromLeadData } from '../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import { AutomationWorkflowEmployee } from '../../RightSideContainer/MembersManagement/AutomationWorkflowEmployee';
import CreateSmsConversationDrawer from '../../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/CreateSmsConversationDrawer/CreateSmsConversationDrawer';
import {patientNotLoggedInError} from '../../RightSideContainer/TeamInbox/Conversations/ConversationContainer/ConversationConst';
import { IContact, IConversationData, INewConversationResponse } from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import { SMS_INBOX_ACTION_CODES } from '../../RightSideContainer/TeamInbox/Integrations/IntegrationCreate/SmsInboxCreate/SmsInboxConst';
import {
  MAIN_MENU_CODES,
  USER_ROLE_SIDE_BAR_CODES_PERMISSION_WISE
} from '../../SideMenuBar/SideBarConst';
import {AddNoteView} from '../AddNoteView';
import AddOrUpdateTask from '../AddTask/AddOrUpdateTask';
import { AppointmentType } from '../CalendarWidget/BookingWorkflows/AppointmentTypeSelection/AppointmentTypeEnum';
import BookingWorkflow from '../CalendarWidget/BookingWorkflows/BookingWorkflow';
import { ParticipantType } from '../CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import { IAllUserPoolSelect } from '../CareDashboard/CareDashboardWidget/UserAutoComplete';
import InstantChat from '../ChatDrawer/InstantChat';
import ContactSendForm from '../ContactSendForm/ContactSendForm';
import EmailDrawerCommon from '../EmailPopupView/EmailDrawerCommon';
import MeetingView from '../MeetingView/MeetingView';
import OutboundCallSmsView from '../OutboundCallSmsView/OutboundCallSmsView';
import { PrintContactClinicalProfileSectionSelector } from '../PrintContactClinicalProfileDrawer/PrintContactClinicalProfileSectionSelector';
import {VideoCallSvg} from '../Svg';
import EmailSvg from '../Svg/EmailSvg';
import PatientChatSvg from '../Svg/PatientChatSvg';
import AppointmentActionSvg from '../Svg/PersonActionSvgIcons/AppointmentActionSvg';
import CallActionSvg from '../Svg/PersonActionSvgIcons/CallActionSvg';
import ChatActionSvg from '../Svg/PersonActionSvgIcons/ChatActionSvg';
import CreateNoteAction from '../Svg/PersonActionSvgIcons/CreateNoteAction';
import EmailActionSvg from '../Svg/PersonActionSvgIcons/EmailActionSvg';
import SmsActionSvg from '../Svg/PersonActionSvgIcons/SmsActionSvg';
import StartPreferredicon from '../Svg/PersonActionSvgIcons/StartPreferredIcon';
import VideoActionSvg from '../Svg/PersonActionSvgIcons/VideoActionSvg';
import ViewEhrActionBtn from '../Svg/PersonActionSvgIcons/ViewEhrActionBtn';
import ViewProfileActionSvg from '../Svg/PersonActionSvgIcons/ViewProfileActionSvg';
import { styles } from './personActionStyles';
import ResetPasswordModal from './ResetPasswordModal';
import { showToast, ToastType } from '../../../utils/commonViewUtils';
import EditActionBtnSvg from '../Svg/PersonActionSvgIcons/EditActionBtnSvg';
import { withMiniContactViewHOC } from '../../MiniContactViewHOC';
import {isAccountConfigEnabled} from '../../../utils/configUtils';
import {CONFIG_CODES} from '../../../constants/AccountConfigConst';
import { EmailDrawerCommonV2 } from '../EmailDrawerCommonV2';
import InstantChatView from '../ChatDrawer/InstantChatView';
import { SendProviderRequestFormDrawer } from '../../RightSideContainer/Contacts/Tpa/SendProviderRequestFormDrawer';
import CareProgramConsent from '../CareProgramConsent/CareProgramConsent';
import ConversationsQueries from '../../../services/Conversations/ConversationsQueries';
import { EcmOutreach } from '../EcmOutreach/EcmOutreach';
import SMSActionAvatar from '../Svg/PersonActionSvgIcons/SMSActionAvatar';
import MailActionSvg from '../Svg/ConversationAllSvg/MailActionSvg';
import VideoMeetActionSvg from '../Svg/ConversationAllSvg/VideoMeetActionSvg';
import ChatRoundActionSvg from '../Svg/PersonActionSvgIcons/ChatRoundActionSvg';
import PhoneActionSvg from '../Svg/PersonActionSvgIcons/PhoneActionSvg';
import {isAffiliatePatient} from '../../RightSideContainer/Contacts/ContactsUtils';
import {useIntl} from 'react-intl';
import {useContactLocationUuid} from '../MemebersView/customHook/useContactLocationUuid';
import {USER_ACCESS_PERMISSION} from '../../RightSideContainer/UserAccess/UserAccessPermission';
import FoldPermitCan from '../../CommonComponents/FoldPermitCan/FoldPermitCan';

interface PersonActionsProps {
  shouldShowEmail?: boolean;
  handleEmail?: any;
  email?: any;
  showVideoCall: boolean;
  handleCall?: any;
  phoneNumber?: any;
  showSms?: boolean;
  handleSMS?: any;
  showAppointment?: boolean;
  showNotes?: boolean;
  contactInfo?: IContact | undefined;
  showPhoneCall?: boolean;
  marginTop?: number;
  extra?: any;
  handleEdit?: any;
  parentCode: string;
  showShowInEhr?: boolean;
  showCharts?:boolean;
  showViewProfile?:boolean;
  hideVideoCallingButton?:boolean;
  onPersonActionPerformed?: (actionCode: string, rawData?: any) => void;
  showEditProfileOnPersonAction?: boolean;
  onEditDetails?: any;
  navigateOrOpenContactIdDrawer?:(contactId?:string, additionalData?:any) => void;
  isEmployerDrillDown?: boolean;
  shouldCheckConsent?: boolean;
  isSideCarBannerView?: boolean;
}

const PersonActionsComponent = (props: PersonActionsProps) => {
  const {
    shouldShowEmail,
    handleEmail,
    email,
    showVideoCall,
    handleCall,
    phoneNumber,
    showSms,
    handleSMS,
    showAppointment,
    showNotes,
    contactInfo,
    marginTop,
    extra,
    handleEdit,
    showPhoneCall,
    parentCode,
    showShowInEhr,
    showCharts,
    showViewProfile,
    hideVideoCallingButton,
    showEditProfileOnPersonAction,
    shouldCheckConsent,
    isSideCarBannerView
  } = props;
  const  personActionRef:any = useRef()
  const isSizeLess = personActionRef?.current?.clientWidth < 450 ? true : false
  const navigate = useNavigate();
  const userUUID = getUserUUID();
  const userId = getUserId();
  const userData = getUserData()
  const userFullName = getUserFullName();
  const baseService = BaseService.getSharedInstance().axios;
  const accountUUID = getAccountUUID()
  const accountId = getAccountId()
  const toast = useToast();
  const isLoggedIn = contactInfo?.additionalAttributes?.loggedInTime
  const isPhysician = isPhysicianRole()
  const [selectedView, setSelectedView] = useState<string>('');
  const isPatientMessagingEnabled = isAccountConfigEnabled(CONFIG_CODES.PATIENT_MESSAGING_ENABLED);
  const showOnlyInternalChatInSidecar = isAccountConfigEnabled(CONFIG_CODES.SHOW_ONLY_INTERNAL_CHAT_IN_SIDECAR);

  const [stateData, setStateData] = useState({
    visible: false,
    preferredChannel: '',
    openDrawerCode: '',
    selectedConversation: {} as IConversationData,
  });


  const ehrPatientId = contactInfo?.patient?.patientId || '';
  const [isOpen, setIsOpen] = useState(false);
  const [hintPatientId, setHintPatientId] = useState('');
  const commonData = useContext(CommonDataContext);
  const isSidecarContext = commonData.sidecarContext?.isSidecar;
  const isPatientSidecarContext = commonData.sidecarContext?.isPatient;
  const contactActiveStatus = isActiveContact(contactInfo);
  const formattedContactData = getFormDataFromLeadData(
    contactInfo || {},
    commonData
  );
  const mlovData = useContext(CommonDataContext);
  const intl = useIntl();
  const accountUuid = getAccountUUID();
  const [accountUsers, setAccountUsers] = useState<any[]>([]);
  const [userTaskPoolAccess, setUserTaskPoolAccess] = useState(false);
  const [userPoolSelect, setAllUserPoolSelect] = useState<IAllUserPoolSelect[]>([]);
  const [locationId, setLocationId] = useState(contactInfo?.contactPracticeLocations?.[0]?.accountLocation?.uuid)
  const loggedInUserId = getUserUUID();
  const accessUserTypeId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'UserPoolType',
    'access_user_task'
  );

  const canUpdatePatient = checkAccountConfigAbilityAccess(FHIR_RESOURCE.PATIENT, 'canUpdate')?.isEnabled;

  const consentCheck =
    isConsentRequiredAndGiven(contactInfo) || !(shouldCheckConsent ?? true);

  const isAffiliatedPatient = isAffiliatePatient(
    props.contactInfo?.additionalAttributes
  );
  const accessUserTaskPoolTypeId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'UserPoolType',
    'task_user_pool'
  );
  const [getAccountUsers] = useLazyQuery(GET_USER_FOR_SCHEDULE_ACCESS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      const accountUsers: any[] = [];
      if (data && data?.users && data?.users.length) {
        data.users = filterWorkflowUser(data.users, loggedInUserId);
        (data.users || []).forEach((user: any) => {
          accountUsers.push({
            userName: user.name,
            userId: user.uuid,
            email: user.email || '',
          });
        });
      }
      setAccountUsers(accountUsers);
      getUserTaskPoolAccess();
    },
    onError: (error: any) => {

    },
  });

  const [getAllTaskAccessUserTaskPools] = useLazyQuery(GetUsersTaskAccess, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const [ getUserTaskPoolAccess, getUserTaskPoolAccessAPI ] = useLazyQuery(GetUsersTaskPoolAccess, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    //skip: accountUserQueryLoading,
    variables: {
      // userId: accountUsers.map(
      //   (accountUserItem: any) => accountUserItem.userId
      // ),
      userId: [loggedInUserId],
      userPoolTypeId: accessUserTypeId,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      // fetching all account users amd filtering login user data
      const filterUserPoolAccessData: any = data.userPoolAccesses?.filter(
        (dataItem: any) => dataItem.userId === loggedInUserId
      );

      if (filterUserPoolAccessData && filterUserPoolAccessData.length) {
        // logged In user have the other pool user access
        const userPoolAccessUsersData =
          filterUserPoolAccessData[0]?.user_pool?.userPoolUsers;
        const loggedInUser = accountUsers.filter(
          (accountItem: any) => accountItem.userId === loggedInUserId
        );
        if (loggedInUser && loggedInUser.length) {
          userPoolAccessUsersData.unshift(loggedInUser[0]);
        }

        // flag to manage dropdown whether there are multiple users to handle or not
        if (userPoolAccessUsersData && userPoolAccessUsersData.length > 1) {
          setUserTaskPoolAccess(true);
        } else {
          setUserTaskPoolAccess(false);
        }
        //userDataPostProcessing(userPoolAccessUsersData);
        fetchAllTaskPoolAccessUserPool(userPoolAccessUsersData);
      } else {
        // logged In user do not have the other pool user access
        setUserTaskPoolAccess(false);
        fetchAllTaskPoolAccessUserPool([
          {
            id: '',
            userId: loggedInUserId,
            isDeleted: false,
          },
        ]);
      }
    },
    onError: (error: any) => {

    },
  });

  const fetchAllTaskPoolAccessUserPool = async (users: any[]) => {
    try {
      const userIds = users.map((userItem: any) => userItem?.userId);
      const taskPoolData = await getAllTaskAccessUserTaskPools({
        variables: {
          userId: userIds,
          userPoolTypeId: accessUserTaskPoolTypeId,
        },
      });
      const newAllUserPool: IAllUserPoolSelect[] = [];
      taskPoolData.data?.userPoolUsers?.map((userPoolItem: any) => {
        const userPoolId = userPoolItem?.userPoolId;
        const userPoolName = userPoolItem?.userPool?.name;
        const userPoolUsers = userPoolItem?.userPool?.userPoolUsers;

        const findIndex = newAllUserPool.findIndex(
          (userPoolItem: IAllUserPoolSelect) =>
            userPoolName === userPoolItem.userPoolName
        );
        if (findIndex < 0) {
          newAllUserPool.push({
            userPoolId,
            userPoolName,
            userPoolUserIds: userPoolUsers.map(
              (userPoolUserItem: any) => userPoolUserItem.userId
            ),
          });
        }
      });
      setAllUserPoolSelect(newAllUserPool)

    } catch (error) {
    } finally {
    }
  };

  const [GetConversationByConversationId] =
    useLazyQuery<INewConversationResponse>(
      ConversationsQueries.GetConversationByConversationId,
      {
        fetchPolicy: 'no-cache',
      }
    );

  const handleOnCreateNewEmailConversation = async (conversationId: string) => {
    if (!conversationId) {
      return;
    }
    try {
      const response = await GetConversationByConversationId({
        variables: {
          conversationId: Number(conversationId),
          loginUserIntId: userId,
          loginUserUuid: userUUID,
        },
      });
      const conversations = response.data?.conversations || [];
      if (conversations.length > 0) {
        const selectedConversationData = conversations[0];
        setStateData((prev) => {
          return {
            ...prev,
            selectedConversation: selectedConversationData,
          };
        });
        setSelectedView(RIGHT_SIDE_CONTAINER_CODE.SHOW_CONVERSATION_DRAWER)
      }
    } catch (error) {
      setStateData((prev) => {
        return {
          ...prev,
          selectedConversationData: {},
        };
      });
    }
  };

  useEffect(() => {
    getAccountUsers({
      variables: {
        accountUUID: accountUuid,
        roleCode: USER_ROLE_CODES.EMPLOYER
      },
    });
  }, []);
  const openInHint =
    getAccountConfigResourceAbilities(FHIR_RESOURCE.HINT).openInHint || false;
  const handleActions = (actionCode: string, rowData?: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.CLOSE_MODAL:
        setStateData((prev) => {
          return {
            ...prev,
            visible: false,
          };
        });
        break;
      case COMMON_ACTION_CODES.CALL:
        const eventBus = EventBus.getEventBusInstance();
        eventBus.broadcastEvent('CLOUD_CALL', {
          contactData: contactInfo ?? {fromNumber: phoneNumber},
          fromNumber: rowData.fromNumber,
          toNumber: phoneNumber,
        });
        setStateData((prev) => {
          return {
            ...prev,
            visible: false,
          };
        });
        break;
      case PERSON_ACTION_CODES.SCHEDULE_APPOINTMENT:
        setSelectedView(COMMON_ACTION_CODES.ADD)
      break;
      case PERSON_ACTION_CODES.SEND_FORMS:
        setSelectedView(RIGHT_SIDE_CONTAINER_CODE.CONTACT_SEND_FORM)
      break
      case PERSON_ACTION_CODES.SEND_PROVIDER_REQUEST_FORM:
        setSelectedView(RIGHT_SIDE_CONTAINER_CODE.SHOW_SEND_PROVIDER_REQUEST_FORM_DRAWER)
      break;
      case PERSON_ACTION_CODES.PRINT_CLINICAL_PROFILE:
        setSelectedView(RIGHT_SIDE_CONTAINER_CODE.PRINT_CLINICAL_PROFILE);
      break;
      case PERSON_ACTION_CODES.CREATE_TASK:
        setSelectedView(RIGHT_SIDE_CONTAINER_CODE.CREATE_TASK)
      break;
      case PERSON_ACTION_CODES.CREATE_AUTOMATION:
        setSelectedView(RIGHT_SIDE_CONTAINER_CODE.AUTOMATION_VIEW)
      break;
      case PERSON_ACTION_CODES.RESET_PASSWORD:
        props.onPersonActionPerformed?.(actionCode, contactInfo)
        setSelectedView(RIGHT_SIDE_CONTAINER_CODE.CONTACT_RESET_PASSWORD);
      break;
      case PERSON_ACTION_CODES.EDIT:
        props.onPersonActionPerformed?.(actionCode, contactInfo)
       break
      case PERSON_ACTION_CODES.ADD_NOTE:
        setSelectedView(CONTACT_NOTES_VIEW.ADD_NOTE);
      break;
      case PERSON_ACTION_CODES.CHAT:
        setSelectedView(
          CONVERSATION_ACTION_CODES.CHAT_WITH_PATIENT
        );
      break;
      case PERSON_ACTION_CODES.CREATE_MEETING:
        if (contactActiveStatus) {
          if (consentCheck) {
            if (email || phoneNumber) {
              setSelectedView(
                RIGHT_SIDE_CONTAINER_CODE.CREATE_MEETING_VIEW
              );
              handleCall?.();
            } else {
              handleEdit?.();
            }
          } else {
            handleEdit?.(COMMON_ACTION_CODES.CONSENT_WARNING);
          }
        } else {
          const message = getInActiveContactError(contactInfo);
          notification.error({
            message,
          });
        }
      break;
      case PERSON_ACTION_CODES.CARE_PROGRAM_CONSENT_AND_ENROLL:
        setSelectedView(RIGHT_SIDE_CONTAINER_CODE.SHOW_CARE_PROGRAM_CONSENT_AND_ENROLL_DRAWER)
      break;
      default:
      props.onPersonActionPerformed?.(actionCode, contactInfo)
      break;
      }
  };
  const getPreferredChannel = () => {
    return props?.contactInfo?.contactPreferredCommunicationChannel?.length
      ? props?.contactInfo?.contactPreferredCommunicationChannel?.[0]
          ?.preferredCommunicationModeMlov?.code
      : '';
  };
  const isPatientLoggedIn = contactInfo?.additionalAttributes?.loggedInTime ?  true : false

  const shouldShowChat = () => {
    if (isSidecarContext && showOnlyInternalChatInSidecar) {
      return false;
    }
    if (
      USER_ROLE_SIDE_BAR_CODES_PERMISSION_WISE.USER.includes(
        MAIN_MENU_CODES.INBOX
      )
    ) {
      return true;
    } else {
      return false;
    }
  };
  const sendResetPasswordInstructions = async (contactData: any) => {
    const url = `/crm-nest/auth/resetContactPassword`;
    const resetPasswordData = {
      accountUuid: accountUUID,
      contactUuid: contactData?.uuid,
    };
    const headers = {
      accountId: accountId,
    };
    await baseService
      .post(url, resetPasswordData, {headers: headers})
      .then((response) => {
        if (contactData?.email) {
          showToast(
            toast,
            'Password instruction successfully sent on patient email',
            ToastType.success
          );
        } else if (contactData?.phoneNumber) {
          showToast(
            toast,
            'Password instruction successfully sent on patient phone number',
            ToastType.success
          );
        }
      })
      .catch((error) => {
        showToast(
          toast,
          'Something went wrong',
          ToastType.error
        );

      });
  };
  const locationGroupId =
    locationId && getLocationGroupIdFromLocationId(locationId);
  const openInEhrUrl = getPatientEHRUrl(ehrPatientId, '', locationGroupId);
  // Always show View In EHR button if openInEhrUrl is available
  // const showViewInEHRBtn = showShowInEhr && isPhysician;
  // Copied below existing condition from PersonActionPopover.tsx
  const showViewInEHRBtn = !isSidecarContext && (!!openInEhrUrl || window?.location?.host === 'demo.sandbox.foldhealth.io');
  const openInEHR = () => {
    window.open(openInEhrUrl, '_blank');
  };
  const handleEHRBtnClick = () => {
    // Here do not check window?.location?.host === 'demo.sandbox.foldhealth.io' as if condition opens link in new URL and else condition shows popover specifically added for demo.sandbox
    if (!!openInEhrUrl) {
      openInEHR();
    } else {
      props.onPersonActionPerformed?.(
        PERSON_ACTION_CODES.OPEN_IN_EHR
      );
    }
    setIsOpen(!isOpen);
  };

  const {fetchContactLocationUuid} = useContactLocationUuid(contactInfo?.id, true);
  const getContactLocationId = async (contactId: number) => {
    const response = await fetchContactLocationUuid(contactId);
    setLocationId(response);
  };

  useEffect(() => {
    if (contactInfo?.id && openInHint) {
      if(!getContactLocationId(contactInfo?.id)) {
        getContactLocationId(contactInfo?.id);
      } else {
      getPatientDetailsFromHint(contactInfo?.id, locationId)
        .then((response) => {
          const id = response.data?.id;
          setHintPatientId(id);
        })
        .catch((error) => {

        });
    }
    }
  }, [locationId]);

  const getActionDividerLine = () => {
    return (
      <Divider
        orientation={'vertical'}
        style={{
          height: 18,
          width: 1,
          backgroundColor: Colors.Custom.Gray200,
        }}
      />
    );
  };

  return (
    <View
      style={{
        maxWidth: '100%',
      }}
    >
    <HStack ref={personActionRef} justifyContent={'space-between'} style={{width: '100%'}}>
      <HStack
        justifyContent={'flex-start'}
        alignItems={'center'}
        space="3"
        marginTop={marginTop ? marginTop : 0}
      >
        {(showEditProfileOnPersonAction && !showViewInEHRBtn) && (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Tooltip title={isAffiliatedPatient ? intl.formatMessage({id: 'affiliatePatientWarningMsg'}) :  'Edit'}>
              <Pressable
                onPress={() => {
                  props?.onEditDetails?.();
                }}
                style={{
                  height: 36,
                  alignItems: 'center',
                  paddingLeft: 4,
                  paddingRight: 12,
                }}
                disabled={isAffiliatedPatient}
              >
                <View style={{width: 20, height: 20}}>
                  <EditActionBtnSvg />
                </View>
                <Text fontSize={12} fontWeight={500} color={'#667085'}>
                  Edit
                </Text>
              </Pressable>
            </Tooltip>
            {getActionDividerLine()}
          </View>
        )}
        {showViewInEHRBtn && (
          <View style={{flexDirection: 'row', alignItems:'center'}}>
            <Tooltip title={'View in EHR'}>
              <Pressable
                onPress={() => {
                  handleEHRBtnClick()
                }}
                style={{height: 36, alignItems: 'center', paddingLeft: 4, paddingRight: 12}}
              >
                <View style={{width: 20, height: 20}}>
                  <ViewEhrActionBtn isActive={true} />
                </View>
                <Text fontSize={12} fontWeight={500} color={'#667085'}>
                  View in EHR
                </Text>
              </Pressable>
            </Tooltip>
            {getActionDividerLine()}
          </View>
        )}
         {/* just tome hide TEMP show profile view action icon ?? ABHAY */}
        {showViewProfile && !isEmployerRole() && false && (
          <View style={{flexDirection: 'row', alignItems:'center'}}>
            <Tooltip title="View Profile">
              <Pressable
                onPress={() => {
                  if (
                    contactInfo?.contactType?.contactType?.code == 'CUSTOMER' ||
                    contactInfo?.contactType?.contactType?.code == 'PATIENT'
                  ) {
                    props?.navigateOrOpenContactIdDrawer?.(contactInfo?.id?.toString());
                  }
                }}
                style={{height: 36, alignItems: 'center', paddingLeft: 4, paddingRight: 12}}
              >
                <View style={{width: 20, height: 20}}>
                  <ViewProfileActionSvg isActive={true} />
                </View>
                <Text fontSize={12} fontWeight={500} color={'#667085'}>
                  View Profile
                </Text>
              </Pressable>
            </Tooltip>
            {getActionDividerLine()}
          </View>
        )}
        {parentCode !== PATIENT_QUICK_PROFILE_PARENT_CODES.CONTACT_OMNI_VIEW &&
          parentCode !== PATIENT_QUICK_PROFILE_PARENT_CODES.MESSAGING &&
          contactInfo?.patient?.id &&
          contactInfo?.uuid  && !(showCharts == false)&& (
          <View style={{flexDirection: 'row', alignItems:'center'}}>
            <Tooltip title="Create Charts">
              <Pressable
                onPress={() => {
                  if (
                    contactInfo?.contactType?.contactType?.code == 'CUSTOMER' ||
                    contactInfo?.contactType?.contactType?.code == 'PATIENT'
                  ) {
                    props?.navigateOrOpenContactIdDrawer?.(contactInfo?.id?.toString(),{
                      state: {actionType:'CREATE_CHART'},
                    } );
                  }
                }}
                style={{height: 36, alignItems: 'center', paddingLeft: 4, paddingRight: 12}}
              >
                <View style={{width: 20, height: 20}}>
                  <CreateNoteAction isActive={true} />
                </View>
                <Text fontSize={12} fontWeight={500} color={'#667085'}>
                  Charts
                </Text>
              </Pressable>
            </Tooltip>
            {getActionDividerLine()}
          </View>
        )}
        <FoldPermitCan
          resource={MAIN_MENU_CODES.INBOX}
          action={USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code}
          component={showSms ? (
            <View style={{flexDirection: 'row', alignItems:'center'}}>
              <Tooltip
                title={
                  !canCommunicateWithInactivePatient(contactInfo)
                    ? getInActiveContactError(contactInfo)
                    : getToolTipMessage(
                        'SMS',
                        phoneNumber,
                        email,
                        props.contactInfo as IContact
                      )
                }
              >
                <Pressable
                  onPress={() => {
                    if (canCommunicateWithInactivePatient(contactInfo)) {
                      if (consentCheck) {
                        if (phoneNumber) {
                          setSelectedView(
                            RIGHT_SIDE_CONTAINER_CODE.CONTACT_SMS_DRAWER
                          );
                          handleSMS?.();
                        } else {
                          handleEdit?.();
                        }
                      } else {
                        handleEdit?.(COMMON_ACTION_CODES.CONSENT_WARNING);
                      }
                    } else {
                      const message = getInActiveContactError(contactInfo);
                      notification.error({
                        message,
                      });
                    }
                  }}
                  style={{height: 36, alignItems: 'center', paddingLeft: 4, paddingRight: isSideCarBannerView ? 0 : 12}}
                >
                {isSideCarBannerView ?
                    <View justifyContent={'center'} alignItems={'center'}>
                      <SMSActionAvatar width={24} height={24} isActive={phoneNumber ? true : false}/>
                    </View>
                   : (
                    <View>
                      <View style={{width: 20, height: 20}}>
                      <SmsActionSvg
                          isActive={phoneNumber ? true : false}
                          customStrokeColor={
                            phoneNumber
                              ? Colors.Custom.foldIconColor
                              : Colors.Custom.GrayDisable
                          }
                        />
                        {getPreferredChannel() ===
                          PREFERRED_CHANNEL_CODE.SMS && (
                          <View style={[styles.preferredIcon]}>
                            <StartPreferredicon />
                          </View>
                        )}
                      </View>
                        <Text
                          fontSize={12}
                          fontWeight={500}
                          color={phoneNumber ? '#667085' : '#D0D5DD'}
                        >
                          SMS
                        </Text>
                      </View>
                    )}
                </Pressable>
              </Tooltip>
              {!isSideCarBannerView && getActionDividerLine()}
            </View>
          ) : <></>}
          />
          <FoldPermitCan
          resource={MAIN_MENU_CODES.INBOX}
          action={USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code}
          component={shouldShowEmail ? (
            <View style={{flexDirection: 'row', alignItems:'center'}}>
              <Tooltip
                title={
                  !canCommunicateWithInactivePatient(contactInfo)
                    ? getInActiveContactError(contactInfo)
                    : getToolTipMessage(
                        'EMAIL',
                        phoneNumber,
                        email,
                        props.contactInfo as IContact
                      )
                }
              >
                <Pressable
                  onPress={() => {
                    if (canCommunicateWithInactivePatient(contactInfo)) {
                      if (consentCheck) {
                        if (email) {
                          setSelectedView(
                            RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP
                          );
                          handleEmail?.();
                        } else {
                          handleEdit?.();
                        }
                      } else {
                        handleEdit?.(COMMON_ACTION_CODES.CONSENT_WARNING);
                      }
                    } else {
                      const message = getInActiveContactError(contactInfo);
                      notification.error({
                        message,
                      });
                    }
                  }}
                  style={{height: 36, alignItems: 'center', paddingLeft: 4, paddingRight: isSideCarBannerView ? 0 : 12}}
                >
                {isSideCarBannerView ?
                (<View justifyContent={'center'} alignItems={'center'}>
                  <MailActionSvg width={24} height={24} isActive={email ? true : false}/>
                </View>)
                : (
                  <View>
                  <View>
                    <View style={{width: 20, height: 20}}>
                      <EmailActionSvg isActive={email ? true : false} />
                    </View>
                    {getPreferredChannel() === PREFERRED_CHANNEL_CODE.EMAIL && (
                      <View style={[styles.preferredIcon]}>
                        <StartPreferredicon />
                      </View>
                    )}
                  </View>
                  <Text
                    fontSize={12}
                    fontWeight={500}
                    color={email ? '#667085' : '#D0D5DD'}
                  >
                    Email
                  </Text>
                </View>
              )
              }
            </Pressable>
          </Tooltip>
          {!isSideCarBannerView && getActionDividerLine()}
        </View>
          ) : <></>}
          />
          <FoldPermitCan
          resource={MAIN_MENU_CODES.INBOX}
          action={USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code}
          component={(parentCode !== PATIENT_QUICK_PROFILE_PARENT_CODES.MESSAGING &&
            contactInfo?.patient?.id &&
              contactInfo?.uuid &&
                isLoggedIn && isPatientMessagingEnabled && shouldShowChat()) ? (
                  <View style={{flexDirection: 'row', alignItems:'center'}}>
                    <Tooltip
                      title={
                        isPatientLoggedIn ?
                        !contactActiveStatus
                          ? getInActiveContactError(contactInfo)
                          : 'Chat with patient' : patientNotLoggedInError
                      }
                    >
                        <Pressable
                          onPress={() => {
                            if (contactActiveStatus && isPatientLoggedIn) {
                              setSelectedView(
                                CONVERSATION_ACTION_CODES.CHAT_WITH_PATIENT
                              );
                            }
                          }}
                          style={{
                            height: 36,
                            alignItems: 'center',
                            paddingLeft: 4, paddingRight: isSideCarBannerView ? 0 : 12
                          }}
                        >
                        {isSideCarBannerView ? (
                          <View justifyContent={'center'} alignItems={'center'}>
                            <ChatRoundActionSvg
                              width={24}
                              height={24}
                              isActive={isPatientLoggedIn}
                            />
                          </View>
                        ) : (
                          <View>
                            <View
                              justifyContent={'center'}
                              alignItems={'center'}
                              style={{width: 20, height: 20}}
                            >
                              <ChatActionSvg
                                isActive={isPatientLoggedIn}
                                customStrokeColor={
                                  isSideCarBannerView
                                    ? Colors.FoldPixel.GRAY300
                                    : Colors.Custom.foldIconColor
                                }
                              />
                            </View>
                            <Text
                              fontSize={12}
                              fontWeight={500}
                              color={
                                isPatientLoggedIn
                                  ? Colors.Custom.Gray500
                                  : Colors.Custom.Gray300
                              }
                            >
                              Chat
                            </Text>
                          </View>
                        )}
                        </Pressable>
                    </Tooltip>
                    {!isSideCarBannerView && getActionDividerLine()}
                  </View>
          ) : <></>}
          />
        {showVideoCall && (
          <FoldPermitCan
          resource={MAIN_MENU_CODES.CALL_LOGS}
          action={USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code}
          component={<View style={{flexDirection: 'row', alignItems:'center'}}>
          <Tooltip
            title={
              !contactActiveStatus
                ? getInActiveContactError(contactInfo)
                : getToolTipMessage(
                    'VCALL',
                    phoneNumber,
                    email,
                    props.contactInfo as IContact
                  )
            }
          >
            <Pressable
              onPress={() => {
                if (contactActiveStatus) {
                  if (consentCheck) {
                    if (email || phoneNumber) {
                      setSelectedView(
                        RIGHT_SIDE_CONTAINER_CODE.CREATE_MEETING_VIEW
                      );
                      handleCall?.();
                    } else {
                      handleEdit?.();
                    }
                  } else {
                    handleEdit?.(COMMON_ACTION_CODES.CONSENT_WARNING);
                  }
                } else {
                  const message = getInActiveContactError(contactInfo);
                  notification.error({
                    message,
                  });
                }
                // if (props.onActionPerformed) {
                //   props.onActionPerformed(PERSON_ACTION_CODES.CALL, {});
                // }
              }}
              style={{alignItems: 'center', height: 36, paddingLeft: 4, paddingRight: 12}}
            >
               {isSideCarBannerView ? (
                  <View justifyContent={'center'} alignItems={'center'}>
                    <VideoMeetActionSvg width={24} height={24} isActive={(email || phoneNumber) ? true : false}/>
                  </View>
                ) : (
                  <View>
                  <View style={{width: 20, height: 20}}>
                    <VideoActionSvg
                      isActive={(email || phoneNumber) ? true : false}
                    />
                  </View>
                  <Text
                    fontSize={12}
                    fontWeight={500}
                    color={(email || phoneNumber) ? '#667085' : '#D0D5DD'}
                  >
                    Meet
                  </Text>
                  </View>
                )}
                </Pressable>
              </Tooltip>
              {!isSideCarBannerView && getActionDividerLine()}
            </View>}
          />
        )}
        {showAppointment && !isPhysician && !isSizeLess && (
          <View style={{flexDirection: 'row', alignItems:'center'}}>
            <Tooltip
              title={
                !contactActiveStatus
                  ? getInActiveContactError(contactInfo)
                  : 'Schedule Appointment'
              }
            >
              <Pressable
                onPress={() => {
                  const currentStatus = isActiveContact(contactInfo);
                  if (!currentStatus) {
                    const message = getInActiveContactError(contactInfo);
                    notification.error({
                      message,
                    });
                    return;
                  }
                  setSelectedView(COMMON_ACTION_CODES.ADD);
                }}
                style={{height: 36, alignItems: 'center', paddingLeft: 4, paddingRight: 12}}
              >
                <View style={{width: 20, height: 20}}>
                  <AppointmentActionSvg isActive={true} />
                </View>
                <Text fontSize={12} fontWeight={500} color={'#667085'}>
                  Appointment
                </Text>
              </Pressable>
            </Tooltip>
            {getActionDividerLine()}
          </View>
        )}
        {showNotes && !isSizeLess && (
          <View style={{flexDirection: 'row', alignItems:'center'}}>
            <Tooltip title="Create Notes">
              <Pressable
                onPress={() => {
                  setSelectedView(CONTACT_NOTES_VIEW.ADD_NOTE);
                }}
                style={{height: 36, alignItems: 'center', paddingLeft: 4, paddingRight: 12}}
              >
                <View style={{width: 20, height: 20}}>
                  <CreateNoteAction isActive={true} />
                </View>
                <Text fontSize={12} fontWeight={500} color={'#667085'}>
                  Notes
                </Text>
              </Pressable>
            </Tooltip>
            {getActionDividerLine()}
          </View>
        )}
        {showPhoneCall && (
          <FoldPermitCan
          resource={MAIN_MENU_CODES.CALL_LOGS}
          action={USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code}
          component={<View style={{flexDirection: 'row', alignItems:'center'}}>
          <OutboundCallSmsView
            showOnlyCallNumbers={true}
            isHideSms={true}
            visible={stateData.visible}
            selectedContactData={contactInfo}
            content={
              <Tooltip
                title={
                  !contactActiveStatus
                    ? getInActiveContactError(contactInfo)
                    : getToolTipMessage(
                        'CALL',
                        phoneNumber,
                        email,
                        props.contactInfo as IContact
                      )
                }
              >
                <Pressable
                  onPress={() => {
                    if (contactActiveStatus) {
                       if (consentCheck) {
                        if (phoneNumber) {
                          setStateData((prev) => {
                            return {
                              ...prev,
                              visible: true,
                            };
                          });
                        } else {
                          handleEdit?.();
                        }
                      } else {
                        handleEdit?.(COMMON_ACTION_CODES.CONSENT_WARNING);
                      }
                    } else {
                      const message = getInActiveContactError(contactInfo);
                      notification.error({
                        message,
                      });
                    }
                  }}
                  style={{
                    height: 36,
                    alignItems: 'center',
                    paddingLeft: 4, paddingRight: isSideCarBannerView ? 0 : 12,
                  }}
                >
                   {isSideCarBannerView ? (
                    <View justifyContent={'center'} alignItems={'center'}>
                      <PhoneActionSvg
                        width={24}
                        height={24}
                        isActive={phoneNumber ? true : false}
                      />
                    </View>
                   ) : (
                     <View>
                       <View>
                         <View style={{width: isSideCarBannerView ? 18 : 20, height: isSideCarBannerView ? 18 : 20, marginTop: isSideCarBannerView ? 2 : 0}} justifyContent={'center'} alignItems={'center'}>
                           <CallActionSvg isActive={phoneNumber ? true : false}  customStrokeColor={
                             phoneNumber
                               ? isSideCarBannerView
                                 ? Colors.FoldPixel.GRAY300
                                 : Colors.Custom.foldIconColor
                               : Colors.Custom.Gray300
                               } />
                         </View>
                         {getPreferredChannel() ===
                           PREFERRED_CHANNEL_CODE.VOICE && (
                           <View style={[styles.preferredIcon]}>
                             <StartPreferredicon />
                           </View>
                         )}
                       </View>
                       <Text
                         fontSize={12}
                         fontWeight={500}
                         color={phoneNumber ? '#667085' : '#D0D5DD'}
                       >
                         Call
                       </Text>
                     </View>
                   )}
                </Pressable>
              </Tooltip>
            }
            handleAction={(actionCode: string, rawData?: any) => {
              handleActions(actionCode, rawData);
            }}
          />
          {isSidecarContext ? <></> :  getActionDividerLine()}
        </View>}
          />
        )}


        {selectedView === RIGHT_SIDE_CONTAINER_CODE.CREATE_MEETING_VIEW && (
          <MeetingView
            personData={formattedContactData}
            onPersonActionPerformed={(actionCode: any) => {
              if (actionCode == COMMON_ACTION_CODES.CANCEL) {
                setSelectedView('');
              }
            }}
          />
        )}

        {selectedView === RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP && (
          <EmailDrawerCommonV2
            isOpen={
              selectedView === RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP
            }
            onClose={() => {
              setSelectedView('');
            }}
            onEmailSent={({msgData}) => {
              setSelectedView('');
              handleOnCreateNewEmailConversation(msgData?.conversationId);
            }}
            contactIds={contactInfo?.id ? [contactInfo?.id] : []}
          />
        )}
        {selectedView === RIGHT_SIDE_CONTAINER_CODE.CONTACT_SMS_DRAWER && (
          <CreateSmsConversationDrawer
            isDrawerVisible={true}
            selectedInbox={{} as any}
            onCreateSmsConversationActionPerformed={(
              actionCode: string,
              actionData: any
            ) => {
              // setViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_LIST_VIEW);
              if (actionCode == SMS_INBOX_ACTION_CODES.DRAWER_CLOSE && !actionData) {
                setSelectedView('');
              } else {
                setSelectedView(RIGHT_SIDE_CONTAINER_CODE.SHOW_CONVERSATION_DRAWER)
                setStateData((prev)=>{
                  return {
                    ...prev,
                    selectedConversation: actionData?.selectedInboxConversationData?.conversations?.[0],
                  }
                })
              }
            }}
            selectedPatient={contactInfo}
          />
        )}

        {selectedView === COMMON_ACTION_CODES.ADD && (
          <BookingWorkflow
            user={{
              name: contactInfo?.name || '',
              uuid: contactInfo?.uuid || '',
            }}
            isOpen={true}
            appointmentType={AppointmentType.bookAppointment}
            defaultParticipants={[
              {
                label: contactInfo?.name || '',
                key: contactInfo?.uuid || '',
                value: contactInfo?.uuid || '',
                type: ParticipantType.patient,
              },
            ]}
            onClose={() => {
              setSelectedView('');
            }}
            onCancel={() => {
              setSelectedView('');
            }}
          />
        )}
        {selectedView === CONTACT_NOTES_VIEW.ADD_NOTE && (
          <AddNoteView
            selectedData={null}
            contactId={contactInfo?.id}
            onFormActionPerformed={(actionCode: any) => {
              setSelectedView('');
            }}
          />
        )}
        {selectedView === CONVERSATION_ACTION_CODES.CHAT_WITH_PATIENT &&
          contactInfo?.uuid &&
          contactInfo?.patient?.id && (
            <InstantChat
              contactData={contactInfo}
              contactUuid={contactInfo?.uuid}
              isDrawerVisible={
                selectedView === CONVERSATION_ACTION_CODES.CHAT_WITH_PATIENT
              }
              onActionPerformed={(actionCode) => {
                if (actionCode === COMMON_ACTION_CODES.CLOSE_MODAL) {
                  setSelectedView('');
                }
              }}
            />
          )}

        {selectedView === RIGHT_SIDE_CONTAINER_CODE.SHOW_CONVERSATION_DRAWER && (
          <InstantChatView
            selectedConversation={stateData.selectedConversation}
            contactData={{} as any}
            isLoading={false}
            isDrawerVisible={true}
            isInstantChatView={true}
            instantChatFromNotifications={true}
            onActionPerformed={(actionCode: any, actionData: any) => {
              if (actionCode === COMMON_ACTION_CODES.CLOSE_MODAL) {
                setSelectedView('');
              } else if (actionCode === CONVERSATION_ACTION_CODES.UPDATE_TO_CONTACT) {
                setStateData((prev) => {
                  return {
                    ...prev,
                    selectedConversation: actionData,
                  };
                });
              }
            }}
        />
        )}
        {
          selectedView === RIGHT_SIDE_CONTAINER_CODE.CONTACT_SEND_FORM && (
            <ContactSendForm
              isVisible={
                selectedView === RIGHT_SIDE_CONTAINER_CODE.CONTACT_SEND_FORM
              }
              assignmentData={{
                patientId: formattedContactData.patientUuid || '',
                contactId: formattedContactData.contactUUID || '',
                assignedById: userUUID,
                patientEmail: formattedContactData.email || '',
                patientFirstName: formattedContactData.firstName || '',
                patientContactUUID: formattedContactData.contactUUID || '',
                contactTypeCode: formattedContactData?.contactType?.code || '',
              }}
              onActionComplete={() => {
                setSelectedView('');
              }}
              onClose={() => {
                setSelectedView('');
              }}
            />
          )
        }
        {selectedView ===
          RIGHT_SIDE_CONTAINER_CODE.SHOW_SEND_PROVIDER_REQUEST_FORM_DRAWER && (
          <SendProviderRequestFormDrawer
            isVisible={
              selectedView === RIGHT_SIDE_CONTAINER_CODE.SHOW_SEND_PROVIDER_REQUEST_FORM_DRAWER
            }
            assignmentData={{
              contactId: formattedContactData.contactUUID || '',
              contactEmail: formattedContactData.email || ''
            }}
            onActionComplete={() => {
              setSelectedView('');
            }}
            onClose={() => {
              setSelectedView('');
            }}
          />
        )}
    { selectedView === RIGHT_SIDE_CONTAINER_CODE.CREATE_TASK && (
        <AddOrUpdateTask
          saveBtnText={'Save'}
          successMessage={'taskAddedMsg'}
          assignee={{
            value: formattedContactData?.contactUUID,
            label: formattedContactData?.name,
            key: formattedContactData?.contactUUID,
            type: ParticipantType.patient,
            contactData: formattedContactData?.contactData,
            data: formattedContactData?.contactData,
          } as any}
          isVisible={selectedView === RIGHT_SIDE_CONTAINER_CODE.CREATE_TASK}
          onComplete={() => {
            setSelectedView('');
          }}
          onCancel={() => {
            setSelectedView('');
          }}
        />
    )}
        {
          selectedView === RIGHT_SIDE_CONTAINER_CODE.PRINT_CLINICAL_PROFILE &&
          <PrintContactClinicalProfileSectionSelector
            formattedContactData={formattedContactData}
            isOpen={selectedView === RIGHT_SIDE_CONTAINER_CODE.PRINT_CLINICAL_PROFILE}
            onClose={() => {
              setSelectedView('');
            }}
          />
        }

        {selectedView === RIGHT_SIDE_CONTAINER_CODE.AUTOMATION_VIEW && (
          <Drawer
            width={700}
            visible={true}
            closeIcon={null}
            closable
            onClose={() => {
              setSelectedView('');
            }}
            title={<></>}
            maskClosable={true}
          >
            <AutomationWorkflowEmployee
              type="patient"
              initData={{selectedItems: [contactInfo]}}
              onModalClose={() => {
                setSelectedView('');
              }}
            />
          </Drawer>
        )}
                {selectedView ===
        RIGHT_SIDE_CONTAINER_CODE.CONTACT_RESET_PASSWORD && (
          <ResetPasswordModal
          isOpen={true}
          onClose={() => {
            setSelectedView('')
          }}
          onConfirm={() => {
            sendResetPasswordInstructions(contactInfo);
            setSelectedView('')
          }}
          />
        // <OverrideModal
          // isOpen={true}
          // onClose={() => {
          //   setSelectedView('')
          // }}
          // onConfirm={() => {
          //   sendResetPasswordInstructions(contactInfo);
          //   setSelectedView('')
          // }}
        //   textContent={{
        //     headerText: 'Confirmation',
        //     message: 'Do you want to send reset password instructions?',
        //   }}
        // />
      )}
      </HStack>
      { parentCode === PATIENT_QUICK_PROFILE_PARENT_CODES.CALL_LOG ? (
        props?.extra ?  props?.extra : <></>
      ) : (
        <View>
          <PersonActionPopover
          isSizeLess={isSizeLess}
          skipActions={showViewInEHRBtn ? [] : [PERSON_ACTION_CODES.EDIT]}
          hideVideoCallingButton={hideVideoCallingButton}
            personData={contactInfo as IContact}
            personType={contactInfo?.contactType?.contactType?.code as any}
            onActionPerformed={(code, data)=> {
              if (
                contactInfo?.contactType?.contactType?.code ===
                  PERSON_TYPES.CUSTOMER &&
                code === COMMON_ACTION_CODES.EDIT &&
                !canUpdatePatient
              ) {
                return;
              }
              handleActions(code, data)
            }}
            parentCode={parentCode}
            showChats={shouldShowChat()}
            hintPatientData={{
              openInHint: openInHint,
              hintPatientId: hintPatientId,
              showVideoCall: showVideoCall
            }}
            locationId={locationId}
            isEmployerDrillDown={props?.isEmployerDrillDown}
          />
        </View>
      )}
      {
          selectedView ===
          RIGHT_SIDE_CONTAINER_CODE.SHOW_CARE_PROGRAM_CONSENT_AND_ENROLL_DRAWER && (
          <CareProgramConsent
            isVisible={
              selectedView === RIGHT_SIDE_CONTAINER_CODE.SHOW_CARE_PROGRAM_CONSENT_AND_ENROLL_DRAWER
            }
            locationId={locationId}
            contactId={contactInfo?.uuid || ''}
            contactCareProgramId={''}
            onClose={() => {
              setSelectedView('');
            }}
          />
        )}
        {selectedView ===
          RIGHT_SIDE_CONTAINER_CODE.ECM_OUTREACH_VIEW && (
          <EcmOutreach
            isVisible={
              selectedView === RIGHT_SIDE_CONTAINER_CODE.ECM_OUTREACH_VIEW
            }
            contactId={contactInfo?.uuid || ''}
            contactCareProgramId={''}
            onClose={() => {
              setSelectedView('');
            }}
          />
        )}
    </HStack>
    </View>
  );
};


export const PersonActions = withMiniContactViewHOC(PersonActionsComponent)
