import React from 'react';
import {Path, Svg} from 'react-native-svg';
import {Colors} from '../../styles';

function TagHorizontal() {
  return (
    <Svg width="16" height="16" fill="none" viewBox="0 0 16 16">
      <Path
        stroke={Colors.FoldPixel.GRAY300}
        strokeLinecap="round"
        d="M5 5.33v6.003M8.905 14H7.147c-2.583 0-3.875 0-4.678-.83-.802-.83-.802-2.165-.802-4.837 0-2.671 0-4.007.802-4.836.803-.83 2.095-.83 4.678-.83h1.758c1.485 0 2.228 0 2.848.355.62.355 1.013 1.006 1.8 2.308l.454.75c.662 1.096.993 1.644.993 2.253 0 .61-.331 1.157-.993 2.253l-.454.75c-.787 1.303-1.18 1.954-1.8 2.309-.62.355-1.363.355-2.848.355z"
      ></Path>
    </Svg>
  );
}

export default TagHorizontal;
