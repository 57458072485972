import React from 'react';
import {Path, Svg} from 'react-native-svg';
import {Colors} from '../../../styles/Colors';
import {ICommonSvgProps} from './interfaces';

const RuleSvg = (props: ICommonSvgProps) => {
  const strokeColor = props.customStrokeColor || Colors.FoldPixel.PRIMARY300;
  const size = props.size || 16;
  return (
    <Svg width={size} height={size} viewBox="0 0 16 16" fill="none">
      <Path
        d="M6.58331 2.00409C6.02081 2.00415 4.36009 1.97647 3.95831 3.87486C3.55219 5.7938 2.02974 7.56759 1.33331 7.99967M1.33331 7.99967C2.02974 8.43174 3.55219 10.2059 3.95831 12.1249C4.36009 14.0233 6.02081 13.9998 6.58331 13.9997M1.33331 7.99967H6.58331M9.58331 7.99985H14.0833M9.58331 2H14.0833M9.58331 14H14.0833"
        stroke={strokeColor}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Svg>
  );
};

export default RuleSvg;
