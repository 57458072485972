import {Spacer, Text} from 'native-base';
import React,{useCallback, useMemo} from 'react';
import Stack from '../../../../../LayoutComponents/Stack';
import TextTabs from '../../../../../TextTabs/TextTabs';
import {useAppointmentDataContext} from '../contexts/AppointmentDataContext';
import SelectSlotByDate from './components/SelectSlotByDate/SelectSlotByDate';
import SelectSlotByProvider from './components/SelectSlotByProvider/SelectSlotByProvider';
import TimeZoneSelectV2 from './components/TimeZoneSelectV2/TimeZoneSelectV2';
import {ITimezone} from '../../../../../../../services/Location/interfaces';
import {Segmented} from 'antd';
import {ClinicalSectionFilterTabs} from '../../../../../../PatientOmniView/Sections/ClinicalSection/ClinicalSectionFilterTabs/ClinicalSectionFilterTabs';
import {isWeb} from '../../../../../../../utils/platformCheckUtils';
import {IClinicalSectionFilterTab} from '../../../../../../PatientOmniView/Sections/ClinicalSection/ClinicalSectionFilterTabs/interfaces';
import {testID, TestIdentifiers} from '../../../../../../../testUtils';
import {SelectSlotsBy} from '../AppointmentBookingEnums';


interface SelectSlotByProps {
  timeZoneView: JSX.Element;
}

const TabMap = {
  [SelectSlotsBy.Provider]: SelectSlotByProvider,
  [SelectSlotsBy.Date]: SelectSlotByDate,
}

const SelectSlotBy = (props: SelectSlotByProps) => {
  const {appointmentBookingState, handleChangeAppointmentbookingState} = useAppointmentDataContext();
  const {selectSlotByView} = appointmentBookingState;
  const tabList = useMemo(
    () => Object.values(SelectSlotsBy).map((item) => ({code: item, title: item})),
    []
  );

  const handleTabChange = useCallback((code: string) => {
    handleChangeAppointmentbookingState(({
      selectSlotByView: code as SelectSlotsBy,
      selectedPrimaryUserId: undefined,
      selectedSecondaryUserIds: [],
      selectedSlot: undefined,
    }));
  }, []);

  const SelectSlotByComponent = useMemo(() => TabMap[selectSlotByView], [selectSlotByView]);

  const tabsList:IClinicalSectionFilterTab[] = useMemo(() => Object.values(SelectSlotsBy).map((item) => ({code: item, label: item})), [selectSlotByView]);


  const titleProps = isWeb()
    ? {fontWeight: 500, fontSize: 16}
    : {
        marginTop: 1,
        mb: 4,
        ml: 2,
        fontSize: 22,
        fontWeight: '700',
        ...testID(TestIdentifiers.appointmentInfoLabel),
      };

  return (
    <Stack direction="column" space={4}>
      <Stack direction="row" space={2}>
        <Text {...titleProps}>
          Show Available Slots
        </Text>
        <Spacer />
        {props?.timeZoneView}
      </Stack>
      {isWeb() ? (
        <Segmented
          className="custom-segmented"
          width={200}
          size="small"
          options={Object.keys(SelectSlotsBy)}
          onResize={{}}
          value={selectSlotByView || SelectSlotsBy.Provider}
          onResizeCapture={{}}
          onChange={(code) => handleTabChange(code as string)}
        />
      ) : (
        <ClinicalSectionFilterTabs
          tabs={tabsList}
          selectedTabCode={selectSlotByView}
          onTabClick={(tab: string) => {
            handleTabChange(tab);
          }}
        />
      )}

      <SelectSlotByComponent />
    </Stack>
  );
};

export default SelectSlotBy;
