import {useLazyQuery} from '@apollo/client';
import {DatePicker} from 'antd';
import {
  FormControl,
  Input,
  Select,
  Stack,
  useMediaQuery,
  View,
  VStack,
  Text,
  FlatList,
  HStack,
  Spinner,
} from 'native-base';
import Feather from 'react-native-vector-icons/Feather';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {DATE_FORMATS, DISPLAY_DATE_FORMAT} from '../../../../../../../constants';
import {CARESTUDIO_PROXY_TO_CRM_CONTEXT} from '../../../../../../../constants/Configs';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';
import {IMlov} from '../../../../../../../Interfaces';
import {MlovQueries} from '../../../../../../../services';
import {getPatientDetailsWithHeaders} from '../../../../../../../services/CommonService/AidBoxService';
import ContactsQueries from '../../../../../../../services/Contacts/ContactsQueries';
import { EHR_CAPABILITY_CONST } from '../../../../../../../utils/capabilityUtils';
import {
  getFormattedDate,
  getMomentObjectWithDateStringAndFormat,
  isFutureDate,
} from '../../../../../../../utils/DateUtils';
import {DisplayText} from '../../../../../../common/DisplayText/DisplayText';
import { CustomComponentKey, getFieldDisplayName, isRequiredField } from '../../CustomComponentUtils';
import { useContainerDimensions } from '../../../../../../CustomHooks/ContainerDimensionHook';
import {FormError, componentKeys} from '../../CustomWrapper/CustomComponentHelper';
import {
  FormContext,
  IFormCommonData,
  IFormComponentProps,
} from '../../CustomWrapper/CustomWrapper';
import FormComponentError from '../../CustomWrapper/FormComponentError/FormComponentError';
import FormComponentLoader from '../../CustomWrapper/FormComponentLoader/FormComponentLoader';
import { cloneDeep } from 'lodash';
import DetailPreview, { ICustomComponentPreviewData } from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailPreview/DetailPreview';
import {v4 as uuidv4} from 'uuid';
import { Colors } from '../../../../../../../styles';
import { IFormValidationOutput } from '../../CustomWrapper/interfaces';
import AllowOverrideMessage from '../../AllowOverrideMessage/AllowOverrideMessage';
import ContactPracticeToUpdateInfoMessage from '../../ContactPracticeToUpdateInfoMessage/ContactPracticeToUpdateInfoMessage';
import CustomButton from '../../CustomWrapper/CustomButton/CustomButton';
import { hideGenderFieldsForPrintPreview } from '../../../../../../../utils/commonUtils';
import './AddOrUpdatePatientDemographics.css';
export interface IFHIRPatient {
  resourceType?: string;
  id?: string;
  name: {use?: string; family?: string; given: string[]; text?: string}[];
  birthDate?: string;
  gender?: string;
  extension?: {url: string; valueCode: string}[];
  reference: {
    contactUuid?: string;
    genderId?: string;
  };
}

export interface IPatientDemographicsComponentValue {
  firstName?: string;
  lastName?: string;
  birthDate?: string;
  genderCode?: string;
  genderId?: string;
  birthSex?: string;
}

interface IAddOrUpdatePatientDemographicsState {
  showErrors: boolean;
  loading: boolean;
  genderList: IMlov[];
  formError?: FormError;
  nonBlockingFormError?: FormError;
}

export enum PatientField {
  firstName ,
  lastName ,
  birthDate ,
  gender ,
  birthSex,
}

const AddOrUpdatePatientDemographics = (props: IFormComponentProps) => {
  const intl = useIntl();
  const componentRef = useRef();
  const { width } = useContainerDimensions(componentRef);
  const [isMobileScreen] = [width <= 480];
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const isPreviewMode = contextData.isPreviewMode || false;
  const isAllowToShare = props?.component?.allowToShare &&  props?.component?.allowToShare === false ? false : true;
  const isPatientForm = contextData.formContext === FormContext.patientForm;
  const isPatientNote = contextData.formContext === FormContext.patientNotes;
  const hideGenderFields = hideGenderFieldsForPrintPreview(contextData.userSettings);
  const [componentValue, setComponentValue] =
    useState<IPatientDemographicsComponentValue>(
      props.component?.selectedValue || {}
    );
  const [importFromChartLoading, setImportFromChartLoading] = useState<boolean>(false);
  const [componentState, setComponentState] =
    useState<IAddOrUpdatePatientDemographicsState>({
      showErrors: false,
      loading: false,
      genderList: [],
      formError: contextData.formError,
    });

  const [getContactDetails] = useLazyQuery(
    ContactsQueries.GET_CONTACT_DEMOGRAPHICS_BY_CONTACT_UUID,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_PROXY_TO_CRM_CONTEXT,
        headers: contextData.headers,
      },
    }
  );

  const [getMlovList] = useLazyQuery(MlovQueries.GET_MLOVS_BY_CATEGORY, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_PROXY_TO_CRM_CONTEXT,
      headers: contextData.headers,
    },
  });

  const validateData = (currentData: IPatientDemographicsComponentValue): IFormValidationOutput => {
    // If no patient id is not found means it's lead and for lead, the section is not applicable. So validation should always return true
    if (componentState.formError === FormError.noPatientIdFound) {
      return {isValid: true, message: ''};
    }
    setComponentState((prev) => ({...prev, showErrors: true}));
    let isValid = true;
    isValid &&= !isInvalid(PatientField.firstName, currentData.firstName, true);
    isValid &&= !isInvalid(PatientField.lastName, currentData.lastName, true);
    isValid &&= !isInvalid(PatientField.gender, currentData.genderCode, true);
    isValid &&= !isInvalid(PatientField.birthSex, currentData.birthSex, true);
    isValid &&= !isInvalid(PatientField.birthDate, currentData.birthDate, true);
    return { isValid: isValid, message: !isValid ? `${props.component.label}: Please fill all the mandatory fields` : '' };
  };
  const isDisabled = () => !props.component?.allowToEdit || false;

  const isRequired = (field: PatientField) => {
    const keyAllowedOperations = contextData.capabilities?.abilities?.keyAllowedOperations;
    return isRequiredField(CustomComponentKey.PATIENT_DEMOGRAPHICS, field, keyAllowedOperations) && !isDisabled();
  };

  const canShow = (field: PatientField) => {
    if (props.component?.enabledFields) {
      return props.component?.enabledFields[field];
    }
    const keyAllowedOperations = contextData.capabilities?.abilities?.keyAllowedOperations;
    switch (field) {
      case PatientField.birthSex: return !keyAllowedOperations?.birthSex?.isHidden;
      case PatientField.gender: return !keyAllowedOperations?.gender?.isHidden;
    }
    return true;
  };

  const getLabel = (field: PatientField): string => {
    const keyAllowedOperations = contextData.capabilities?.abilities?.keyAllowedOperations;
    return getFieldDisplayName(CustomComponentKey.PATIENT_DEMOGRAPHICS, field, keyAllowedOperations) || '';
  };

  const isInvalid = (
    field: PatientField,
    value?: string,
    showErrors?: boolean
  ) => {
    const canShowErrors = showErrors || componentState.showErrors;
    return !value && isRequired(field) && canShowErrors;
  };

  const getBirthSexArray = (): {code: string; display: string}[] => {
    return (
      contextData.capabilities?.abilities?.keyAllowedOperations?.birthSex
        ?.possibleValues || []
    );
  };

  const getExistingData = (contactId: string, skipLoader?: boolean) => {
    if (!skipLoader){
      setComponentState((prev) => ({...prev, loading: true}));
    }
    getContactDetails({
      variables: {
        contactId,
      },
    })
      .then((response) => {
        if (response?.data?.contacts?.length) {
          const detail = response.data.contacts[0];
          const newData = {
            firstName: detail.person?.firstName,
            lastName: detail.person?.lastName,
            birthDate: detail.person?.birthDate,
            genderCode: detail.person?.gender?.code,
            genderId: detail.person?.gender?.id,
          };
          setComponentValue({
            ...newData,
          });

          contextData?.updateFormPrefilledByFoldProgress?.(componentKeys.PATIENT_DEMOGRAPHICS, newData);
        }
        setComponentState((prev) => ({
          ...prev,
          loading: false,
          formError: !response?.data?.contacts
            ? FormError.existingDataAPIFail
            : prev.formError,
        }));
        setImportFromChartLoading(false);
      })
      .catch((error) => {

        setComponentState((prev) => ({
          ...prev,
          loading: false,
          formError: FormError.existingDataAPIFail,
        }));
        setImportFromChartLoading(false);
      });
  };

  const getFirstName = (patientDetail: any) => {
    if (patientDetail?.name?.length && patientDetail.name[0].given?.length) {
      return patientDetail.name[0].given[0];
    }
    return '';
  };

  const getLastName = (patientDetail: any) => {
    if (patientDetail?.name?.length) {
      return patientDetail.name[0].family;
    }
    return '';
  };

  const getSexAtBirth = (patientDetail: any) => {
    if (patientDetail?.extension?.length) {
      const birthSex = patientDetail.extension.find((extension: any) => {
        return extension?.url?.includes('us-core-birthsex');
      });
      return birthSex?.valueCode || birthSex?.valueString || '';
    }
    return '';
  };

  const getGenderId = (genderCode?: string) => {
    if (genderCode) {
      const genderData = componentState.genderList.find((gender: IMlov) => {
        return gender.code === genderCode;
      });
      if (genderData?.id) {
        return genderData.id;
      }
    }
    return '';
  };

  const getPatientDetails = (patientId: string, contactId?: string, skipLoader?: boolean) => {
    if (!skipLoader) {
      setComponentState((prev) => ({...prev, loading: true}));
    }
    let dataPrefilledByFold = {};
    getPatientDetailsWithHeaders(
      patientId,
      !contextData.hasLoggedInContext,
      contextData.headers,
      (response) => {
        if (response?.data?.id) {
          const newData = {
            firstName: getFirstName(response.data),
            lastName: getLastName(response.data),
            birthDate: response.data.birthDate,
            birthSex: getSexAtBirth(response.data),
            ...(!contactId && {genderCode: response.data?.gender}),
            ...(!contactId && {genderId: getGenderId(response.data?.gender)}),
          };
          setComponentValue((prev) => ({
            ...prev,
            ...newData,
          }));
          dataPrefilledByFold = {
            ...dataPrefilledByFold,
            ...newData,
          };
          contextData?.updateFormPrefilledByFoldProgress?.(componentKeys.PATIENT_DEMOGRAPHICS, dataPrefilledByFold);
        }
        setComponentState((prev) => ({
          ...prev,
          loading: false,
          formError: !response?.data?.id
            ? FormError.existingDataAPIFail
            : prev.formError,
        }));
        setImportFromChartLoading(false);
      },
      (error) => {

        setComponentState((prev) => ({
          ...prev,
          loading: false,
          formError: FormError.existingDataAPIFail,
        }));
        setImportFromChartLoading(false);
      },
      contextData.locationId,
    );
    if (contactId) {
      getContactDetails({
        variables: {
          contactId,
        },
      })
        .then((response) => {
          if (response?.data?.contacts?.length) {
            const detail = response.data.contacts[0];
            const newData = {
              genderCode: detail.person?.gender?.code,
              genderId: detail.person?.gender?.id,
            };
            setComponentValue((prev) => ({
              ...prev,
              ...newData,
            }));
            dataPrefilledByFold = {
              ...dataPrefilledByFold,
              ...newData,
            };
            contextData?.updateFormPrefilledByFoldProgress?.(componentKeys.PATIENT_DEMOGRAPHICS, dataPrefilledByFold);
          }
          setComponentState((prev) => ({
            ...prev,
            loading: false,
            nonBlockingFormError: !response?.data?.contacts
              ? FormError.existingDataAPIFail
              : undefined,
          }));
          setImportFromChartLoading(false);
        })
        .catch((error) => {
          setImportFromChartLoading(false);
          setComponentState((prev) => ({
            ...prev,
            loading: false,
            nonBlockingFormError: FormError.existingDataAPIFail,
          }));
        });
    }
  };

  const fetchMetaData = () => {
    getMlovList({
      variables: {
        categories: ['PersonGender'],
      },
    })
      .then((response) => {
        if (response?.data?.mlovs?.length) {
          setComponentState((prev) => ({
            ...prev,
            genderList: response.data.mlovs,
          }));
        }
        setComponentState((prev) => ({
          ...prev,
          formError: !response?.data?.mlovs
            ? FormError.configurationDataAPIFail
            : prev.formError,
        }));
      })
      .catch(() => {
        setComponentState((prev) => ({
          ...prev,
          formError: FormError.configurationDataAPIFail,
        }));
      });
  };

  const showImportButton = isPatientNote && !isPreviewMode && !isDisabled();

  const fetchImportData = () => {
    const patientId = contextData?.patientId as string;
    const contactId = contextData?.contactId as string;
    if (isPatientNote && !isPreviewMode) {
      setImportFromChartLoading(true);
      if (patientId) {
        getPatientDetails(patientId, contactId, true);
      } else if (contactId) {
        getExistingData(contactId, true);
      }
    }
  };

  useEffect(() => {
    const patientId = contextData?.patientId;
    const contactId = contextData?.contactId;
    fetchMetaData();
    // if fields are not editable the latest data should be prefilled
    // Removing !isPatientNote check here, as we need to prefill the notes data always
    if (/*!isPatientNote && */(isDisabled() || !props.component.selectedValue)) {
      if (patientId) {
        getPatientDetails(patientId, contactId);
      } else if (contactId) {
        getExistingData(contactId);
      }
    }
  }, []);

  useEffect(() => {
    props.onChange(cloneDeep(componentValue));
  }, [componentValue]);

  props.validateRef.current = validateData;

  const getPreviewData = (): {
    id: string;
    key: string;
    value: string;
  }[] => {
    const previewData: {
      id: string;
      key: string;
      value: string;
    }[] = [];
    const data = ['firstName', 'lastName', 'birthDate'];
    if (canShow(PatientField.birthSex) && !hideGenderFields) {
      data.push('birthSex');
    }
    if (canShow(PatientField.gender) && !hideGenderFields) {
      data.push('genderCode');
    }
    data.forEach((item: string) => {
      let value = componentValue[
        item as keyof IPatientDemographicsComponentValue
      ] as any;
      let fieldTitle = '';
      switch (item) {
        case 'genderCode':
          fieldTitle = 'Gender';
          const selectedGender = componentState.genderList.find(
            (gender: IMlov) => {
              return gender.code == componentValue.genderCode;
            }
          );
          value = selectedGender?.value;
          break;
        case 'birthSex':
          fieldTitle = 'Sex at Birth';
          value = getBirthSexArray().find(value => value.code === componentValue.birthSex)?.display
          break;
        case 'birthDate':
          fieldTitle = 'Date of Birth';
          value = componentValue.birthDate
            ? getFormattedDate(componentValue.birthDate, DISPLAY_DATE_FORMAT)
            : '';
          break;
        default:
          fieldTitle = intl.formatMessage({id: item});
      }
      const previewItem = {
        key: `${fieldTitle}`,
        value: value,
        id: uuidv4(),
      };
      if (value) previewData.push(previewItem);
    });
    return previewData;
  };

  useEffect(() => {
    const isDataAvailable = !!componentValue.firstName || !!componentValue.lastName || !!componentValue.birthDate || !!componentValue.genderCode || !!componentValue.genderId || !!componentValue.birthSex;
    contextData?.updateLoadingStatus?.(componentKeys.PATIENT_DEMOGRAPHICS, componentState.loading, isDataAvailable);
  }, [componentState.loading]);


  if (isPreviewMode) {
    const data =  getPreviewData();
    const showVertical = data.length > 3;
    return (
      <>
        {!componentState.loading && !componentState.formError && (
          <>
            {data.length > 0 && (
              <div className={`${isAllowToShare ? 'page-break' : 'disallow-to-share'}`}>
              <DetailPreview titleLocalId={props.component.label}>
                <View style={{ flex: 1, alignItems: 'flex-start', flexDirection: showVertical ? 'column' : 'row' }}>
                  {data.map((item) => {
                    return (
                      <div key={item.id} className={showVertical ? undefined : 'patient-demographic-preview-item'} style={showVertical ? {flex: 1, width: '100%'} : {}}>
                        <Stack key={item.id} flex={1} direction={showVertical ? 'row' : 'column'}>
                          <Text flex={3} style={{ fontWeight: '600' }}>
                            <div className="note-preview-label">{item.key}</div>
                          </Text>
                          <Text flex={7}>
                            {item.value}
                          </Text>
                        </Stack>
                      </div>
                    );
                  })}
                </View>
              </DetailPreview>
              </div>
            )}
          </>
        )}
      </>
    );
  }
  return (
    <VStack ref={componentRef} >
      {isPatientForm && !props.component?.allowToEdit && <ContactPracticeToUpdateInfoMessage />}
      {componentState.loading && <FormComponentLoader />}
      {!componentState.loading && componentState.nonBlockingFormError && (
        <FormComponentError error={componentState.nonBlockingFormError} />
      )}
      {!componentState.loading && componentState.formError && (
        <FormComponentError error={componentState.formError} />
      )}
      <HStack alignItems={'center'} mb={2} mt={1}>
        <Text fontSize={16} fontWeight="bold" flex={1}>
          {props.component.label}
        </Text>
        {showImportButton && (
          <CustomButton
            styles={{alignSelf: 'flex-end'}}
            title="Import from Chart"
            isDisabled={importFromChartLoading}
            leftIcon={
              importFromChartLoading ? (
                <Spinner mr={1.5} />
              ) : (
                <Feather
                  name="download"
                  size={20}
                  color={Colors.Custom.mainPrimaryPurple}
                />
              )
            }
            onPress={fetchImportData}
          />
        )}
      </HStack>
      {!componentState.loading && !componentState.formError && (
        <VStack space={4} flex={1}>
          <div className='page-break'>
          <Stack
            space={4}
            direction={isMobileScreen ? 'column' : 'row'}
            flex={1}
          >
            <FormControl
              isInvalid={isInvalid(
                PatientField.firstName,
                componentValue.firstName
              )}
              flex={1}
            >
              <FormControl.Label
                isRequired={isRequired(PatientField.firstName)}
              >
                <DisplayText textLocalId="firstName" />
              </FormControl.Label>
              <Input
               isDisabled={isDisabled()}
              _focus={{borderColor: '#D0D5DD'}}
                value={componentValue.firstName}
                isReadOnly={props.disabled}
                onChangeText={(text: string) => {
                  setComponentValue((prev) => ({...prev, firstName: text}));
                }}
              />
            </FormControl>
            <FormControl
              isInvalid={isInvalid(
                PatientField.lastName,
                componentValue.lastName
              )}
              isDisabled={isDisabled()}
              flex={1}
            >
              <FormControl.Label isRequired={isRequired(PatientField.lastName)}>
                <DisplayText textLocalId="lastName" />
              </FormControl.Label>
              <Input
              _focus={{borderColor: '#D0D5DD'}}
                value={componentValue.lastName}
                isReadOnly={props.disabled}
                onChangeText={(text: string) => {
                  setComponentValue((prev) => ({...prev, lastName: text}));
                }}
              />
            </FormControl>
          </Stack>
          </div>
          <div className='page-break'>
          <Stack
            space={4}
            direction={isMobileScreen ? 'column' : 'row'}
            flex={1}
          >
            <FormControl
              isInvalid={isInvalid(
                PatientField.birthDate,
                componentValue.birthDate
              )}
              isDisabled={isDisabled()}
              flex={1}
            >
              <FormControl.Label
                isRequired={isRequired(PatientField.birthDate)}
              >
                <DisplayText textLocalId="birthDate" />
              </FormControl.Label>
              <DatePicker
                disabled={props.disabled || isDisabled()}
                placeholder={intl.formatMessage({id: 'birthDate'})}
                format={DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT}
                disabledDate={(current: any) => {
                  return current && isFutureDate(current);
                }}
                value={
                  componentValue.birthDate
                    ? getMomentObjectWithDateStringAndFormat(
                        componentValue.birthDate,
                        DATE_FORMATS.DISPLAY_DATE_FORMAT
                      )
                    : undefined
                }
                style={{height: '36px'}}
                className={
                  isInvalid(PatientField.birthDate, componentValue.birthDate)
                    ? 'field-error'
                    : ''
                }
                onChange={(value) => {
                  setComponentValue((prev) => ({
                    ...prev,
                    birthDate: value?.format(DATE_FORMATS.DISPLAY_DATE_FORMAT),
                  }));
                }}
              />
            </FormControl>
            {canShow(PatientField.birthSex) && (
              <FormControl
                isRequired={isRequired(PatientField.birthSex)}
                isInvalid={isInvalid(
                  PatientField.birthSex,
                  componentValue.birthSex
                )}
                flex={1}
                isDisabled={isDisabled()}
              >
                <FormControl.Label>
                  <Text size={'smMedium'}>{getLabel(PatientField.birthSex)}</Text>
                </FormControl.Label>
                <Select
                  accessibilityLabel={getLabel(PatientField.birthSex)}
                  placeholder={`Select ${getLabel(PatientField.birthSex)}`}
                  selectedValue={componentValue?.birthSex || ''}
                  isDisabled={props.disabled}
                  height={36}
                  onValueChange={(itemValue) => {
                    setComponentValue((prev) => ({
                      ...prev,
                      birthSex: itemValue,
                    }));
                  }}
                >
                  {getBirthSexArray().map((singleGender) => {
                    return (
                      <Select.Item
                        key={singleGender.code}
                        label={singleGender.display}
                        value={singleGender.code}
                      />
                    );
                  })}
                </Select>
              </FormControl>
            )}
          </Stack>
          </div>
          <div className='page-break'>
          <Stack
            space={4}
            direction={isMobileScreen ? 'column' : 'row'}
            flex={1}
          >
            {canShow(PatientField.gender) && (
              <FormControl
                isRequired={isRequired(PatientField.gender)}
                isInvalid={isInvalid(
                  PatientField.gender,
                  componentValue.genderCode
                )}
                flex={1}
                isDisabled={isDisabled()}
              >
                <FormControl.Label>
                  <Text size={'smMedium'}>{getLabel(PatientField.gender)}</Text>
                </FormControl.Label>
                <Select
                  selectedValue={componentValue.genderCode}
                  accessibilityLabel={getLabel(PatientField.gender)}
                  placeholder={`Select ${getLabel(PatientField.gender)}`}
                  height={36}
                  isDisabled={props.disabled}
                  onValueChange={(itemValue) => {
                    const selectedGender = componentState.genderList.find(
                      (gender: any) => {
                        return gender.id == itemValue;
                      }
                    );
                    setComponentValue((prev) => ({
                      ...prev,
                      genderCode: itemValue,
                      genderId: selectedGender?.id,
                    }));
                  }}
                >
                  {componentState.genderList.map((singleGenderObj) => {
                    return (
                      <Select.Item
                        key={singleGenderObj.id}
                        label={singleGenderObj.value}
                        value={singleGenderObj.code}
                      />
                    );
                  })}
                </Select>
              </FormControl>
            )}
            <View flex={1} />
          </Stack>
          {contextData.isBuilderMode && !props.component?.allowToEdit && <AllowOverrideMessage />}
          </div>
        </VStack>
      )}
    </VStack>
  );
};

export default AddOrUpdatePatientDemographics;
