import React from 'react';
import {Path, Svg} from 'react-native-svg';
import { ICommonSvgProps } from './interfaces';

const SelectDownArrowSvg = (props: ICommonSvgProps) => {
  return (
    <Svg
      width={props.size || "6"}
      height={props.size || "7"}
      viewBox="0 0 6 7"
      fill="none"
    >
      <Path
        d="M3 4.5L5 2.5H1L3 4.5Z"
        fill={props.customStrokeColor || "#8C5AE2"}
        stroke={props.customStrokeColor ||"#8C5AE2"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default SelectDownArrowSvg;
