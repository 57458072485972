import { View, Text } from 'react-native'
import React, { useContext } from 'react'
import { IContactCareProgram } from './interface'
import { CommonDataContext } from '../../../context/CommonDataContext';
import { getMlovListFromCategory } from '../../../utils/mlovUtils';
import { MLOV_CATEGORY, RIGHT_SIDE_CONTAINER_CODE } from '../../../constants';
import { CARE_PROGRAM_TYPE_CODES } from '../../../constants/MlovConst';
import Stack from '../LayoutComponents/Stack';
import { Colors } from '../../../styles';
import { CareProgramStepWiseView } from '../MemebersView/CareProgramStepWiseView';
import { IContactCareProgramResponse } from '../../../services/ContactCareProgram/interface';
import { CARE_PROGRAM_STEP_TYPE, PROGRAM_STEPS_FILTER_CODE } from '../MemebersView/constant';
import { ICondition } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/Conditions/interfaces';
import { chronicCondtionsView } from '../../RightSideContainer/Contacts/Leads/LeadView/LeadTableView/Helper/LeadListCommonViews';
import {useCareProgramDeclinedInfo} from '../MemebersView/customHook/useCareProgramDeclinedInfo';
import {Pressable} from 'native-base';
import Feather from 'react-native-vector-icons/Feather';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {useIntl} from 'react-intl';


interface ICareProgramCardProps {
  careProgramData: IContactCareProgramResponse;
  careProgram: IContactCareProgram;
  chronicConditions: ICondition[];
  onActionPerform?: (
    actionCode: string,
    selectedCareProgram: IContactCareProgram,
    stepId?: string
  ) => void;
  contactId?: number;
}

const CareProgramCard = (props: ICareProgramCardProps) => {
  const { careProgram, onActionPerform, chronicConditions, contactId } = props;
  const intl = useIntl();
  const contextData = useContext(CommonDataContext);
  const careProgramTypesList = getMlovListFromCategory(contextData.CARE_STUDIO_MLOV, MLOV_CATEGORY.CARE_PROGRAM_TYPES) || [];
  const {isDeclined, declinedAtStep} = useCareProgramDeclinedInfo(props?.careProgramData);  
  // Other methods
  const getCardTopTitle = () => {
    return 'Care Program Eligibility';
  }

  const getCardMainTitle = () => {
    const careProgramTypeCode = careProgramTypesList.find((item) => item.id === careProgram.payerCareProgram.careProgramTypeId)?.code;
    switch (careProgramTypeCode) {
      case CARE_PROGRAM_TYPE_CODES.CCM: return 'Chronic Care Management (CCM)';
      case CARE_PROGRAM_TYPE_CODES.TCM: return 'Transitional Care Management (TCM)';
      case CARE_PROGRAM_TYPE_CODES.ECM: return 'Enhanced Care Management (ECM)';
      default: return 'Care Program';
    }
  }

  const getCardSubTitle = () => {
    return chronicCondtionsView({
      conditionNametextStyles: {color: Colors.Custom.Gray500},
      monthTextStyles: {color: Colors.Custom.Gray900},
      chronicConditions: chronicConditions,
      careProgramEnrollmentDate: props?.careProgramData?.enrollmentLog?.enrollmentDateTime,
    });
  };

  const getCardSteps = () => {
    if (!props?.careProgramData?.id) {
      return (<></>);
    }
    return (
      <CareProgramStepWiseView
        contactId={contactId}
        contactCareProgram={props?.careProgramData}
        onActionPerformed={(code: string, extraData?: any) => {
          if (onActionPerform && typeof onActionPerform === 'function') {
            onActionPerform(code, careProgram, extraData?.stepId);
          }
        }}
        config={{hideCareProgramName: true}}
        filterCode={PROGRAM_STEPS_FILTER_CODE.ALL}
      />)
  }

  const handleDeclinedStepClick = () => { 
    const stepCode = declinedAtStep?.careProgramStepType.code;
    let actionCode = '';
    switch (stepCode) {
      case CARE_PROGRAM_STEP_TYPE.CONSENT:
        actionCode = COMMON_ACTION_CODES.CARE_PROGRAM_CONSENT_AND_ENROLL;
        break;
      case CARE_PROGRAM_STEP_TYPE.ECM_OUTREACH:
      case CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_2DAY:
      case CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_14DAY:
        actionCode = COMMON_ACTION_CODES.ECM_OUTREACH_VIEW;
        break;
      default:
        break;
    }

    if (
      onActionPerform &&
      typeof onActionPerform === 'function' &&
      actionCode.length > 0
    ) {
      onActionPerform(actionCode, careProgram, declinedAtStep?.id);
    }
  }

  const renderDeclinedView = () => {
    return (
      <Pressable
        _hover={{
          backgroundColor: Colors.Custom.Red100,
        }}
        padding={1}
        alignSelf={'flex-start'}
        borderRadius={4}
        ml={-1}
        onPress={handleDeclinedStepClick}
      >
        <Text
          style={{
            color: Colors.FoldPixel.STATUS_ERROR,
          }}
        >
          <Feather name="slash" size={14} style={{marginRight: 2}} />
          {intl.formatMessage({id: 'patientDeclined'})}
        </Text>
      </Pressable>
    );
  }

  return (
    <Stack
      direction="row"
      style={{
        alignItems: 'center',
        borderRadius: 8,
        borderWidth: 0.5,
        borderColor: isDeclined ? Colors.FoldPixel.GRAY150 : Colors.Custom.Primary300,
        backgroundColor: isDeclined ? Colors.Custom.White : Colors.Custom.insuranceBgColor,
        overflow: 'hidden',
        marginBottom: 4,
      }}
    >
      <View
        style={{
          height: '80%',
          width: 4,
          backgroundColor: isDeclined ? Colors.FoldPixel.GRAY200 : Colors.Custom.Primary300,
          overflow: 'hidden',
          borderTopEndRadius: 8,
          borderBottomEndRadius: 8,
        }}
      />
      <Stack direction="column" space={4} style={{padding: 12, flex: 1}}>
        {!isDeclined && (
          <Text
            style={{
              fontSize: 12,
              fontWeight: '400',
              color: Colors.Custom.Gray400,
            }}
          >
            {getCardTopTitle()}
          </Text>
        )}
        <Text
          style={{
            fontSize: 14,
            fontWeight: '400',
            color: Colors.Custom.Gray700,
          }}
        >
          {getCardMainTitle()}
        </Text>
        {getCardSubTitle()}
        {isDeclined ? renderDeclinedView() : getCardSteps()}
      </Stack>
    </Stack>
  );
}

export default CareProgramCard
