import React, { useContext, useEffect, useState } from 'react'
import { Tooltip, notification } from 'antd';
import { HStack, Text, VStack} from 'native-base';
import { PatientInfoBanner } from '../../../../../PatientInfoBanner/PatientInfoBanner';
import { getContactAddressDetails, getGenderTextByGenderCode, getPatientName, getSecondaryValue, isActiveContact, isString } from '../../../../../../utils/commonUtils';
import { DATE_FORMATS, getInActiveContactError, GROUP_MEMBER_TYPE, PERSON_TYPES, RIGHT_SIDE_CONTAINER_CODE } from '../../../../../../constants';
import RedirectSvg from '../../../../../../assets/Icons/RedirectSvg';
import { PersonActions } from '../../../../PersonActions/PersonActions';
import { getAgeValue, getDateStrFromFormat } from '../../../../../../utils/DateUtils';
import { getContactTypeValue } from '../../../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingUtils';
import { useNavigate,useLocation } from 'react-router-dom';
import { AddOrUpdateLead } from '../../../../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateLead';
import { withMiniContactViewHOC } from '../../../../../MiniContactViewHOC';
import { getEhrConfig } from '../../../../../../utils/capabilityUtils';
import { getCurrentPatientDeceasedData} from '../../../../../../services/CommonService/AidBoxService';
import { Colors } from '../../../../../../styles';
import { IContact } from '../../../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import { useIntl } from 'react-intl';
import { CommonDataContext } from '../../../../../../context/CommonDataContext';

const AppointmentContactBanner = (props: {
  contactData: any;
  parentCode: string;
  showCommunicationDetails?: boolean;
  navigateOrOpenContactIdDrawer?: (contactId: string, additionalData?: any) => void;
  openContactInWindowOrOpenContactIdDrawer?:(contactId: string) => void;
}) => {
  const { contactData, showCommunicationDetails } = props;
  const navigate = useNavigate();
  const [stateData, setStateData] = useState({
    actionCode: '',
    rawData: {} as any,
    deceasedDate: ''
  })
  const commonData = useContext(CommonDataContext);
  const isSidecarContext = !!commonData?.sidecarContext?.isSidecar;
  const location = useLocation();
  const intl = useIntl();
  const getSubtitleText = (contactData: any):JSX.Element => {
    const genderValue = getGenderTextByGenderCode(contactData);
    const ageValue = getAgeValue(contactData?.person?.birthDate, stateData.deceasedDate);
    const address = getContactAddressDetails(contactData);

    return (
      <>
        <VStack>
          <HStack>
            <Text>
              {contactData?.contactType?.contactType &&
                getContactTypeValue(contactData.contactType.contactType)}
            </Text>
            <Tooltip
              title={ageValue}
              placement="top"
              color={Colors.Custom.Gray700}
            >
              <Text>
                {contactData?.person?.birthDate &&
                  ` | ${getDateStrFromFormat(
                    contactData?.person?.birthDate,
                    DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
                  )}`}
              </Text>
            </Tooltip>
            <Text>{genderValue && ` | ${genderValue}`}</Text>
          </HStack>
          {(showCommunicationDetails) &&
          <VStack>
            <Text>{address && `${address}`}</Text>
            <Text>{contactData.email && `${contactData.email}`}</Text>
            <Text>{contactData.phoneNumber && contactData.phoneNumber}</Text>
          </VStack>
          }
        </VStack>
      </>
    );
  };

  const onContactClicked = (contactId: any, contactType?: string) => {
    if (!contactData) {
      return;
    }
    const currentStatus = isActiveContact(contactData)
    if (!currentStatus) {
      const message = getInActiveContactError(contactData)
      notification.error({
        message,
      });
      return
    }
    if (contactType == 'CUSTOMER') {
      props?.navigateOrOpenContactIdDrawer?.(contactId)
    } else if (contactType == 'LEAD') {
      navigate(`/members/prospect/${contactId}`);
    }
  };
  const ehrConfig = getEhrConfig(
    contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid,
    ''
  );

  const getPatientDeceasedData = async (contactData: IContact) => {
    const deceasedDatePatient = await getCurrentPatientDeceasedData(contactData);
    setStateData((prev) => {
      return {
        ...prev,
        deceasedDate: deceasedDatePatient,
      };
    });
  };
  useEffect(() => {
    ehrConfig.isAthena && getPatientDeceasedData(contactData);
  }, [contactData?.patient?.patientId]);

  return (
    <>
      <VStack space={4}>
        <HStack>
          <PatientInfoBanner
            isLetterAvatarShow={true}
            name={isString(contactData.name)
              ? contactData.name
              : getPatientName(contactData)}
            contactActiveStatus={contactData?.isActive}
            bannerType={'bigBanner'}
            isHorizontal={true}
            isSelected={false}
            headerString={
              isString(contactData.name)
                ? contactData.name
                : getPatientName(contactData)
            }
            genderCode={
              contactData?.person?.gender?.code ||
              contactData?.person?.sexAtBirth?.code
            }
            userId={contactData?.uuid}
            userType={
              contactData?.patient?.patientUuid
                ? GROUP_MEMBER_TYPE.PATIENT
                : GROUP_MEMBER_TYPE.CONTACT
            }
            deceasedDate ={stateData.deceasedDate}
            subtitleElement={getSubtitleText(contactData) || '-'}
            hideConversationTypeImage={true}
            loggedInTime={contactData?.additionalAttributes?.loggedInTime}
            contactData={contactData}
            viewStylePropsForBigBannerSubHeaderView={{ ...isSidecarContext && { justifyContent: 'center' } }} 
            showDetailProfile={!isSidecarContext}
            onShowDetails={() => {
              const pathURL=`/members/patient/${contactData.id}`;
              // Check if the user is already on the patient profile page
              if (location.pathname === pathURL) {
                notification.info({
                  message: intl.formatMessage({id: 'messageForTaskPage'}),
                  duration: 2
                });
              }
              const currentStatus = isActiveContact(contactData);
              if (!currentStatus) {
                const message = getInActiveContactError(contactData);
                notification.error({
                  message,
                });
                return;
              }
              if (props?.contactData?.contactType?.contactType?.code == PERSON_TYPES.CUSTOMER) {
                props?.navigateOrOpenContactIdDrawer?.(contactData.id, {
                  state: { contactData: contactData },
                });
              } else {
                navigate(`/members/prospect/${contactData.id}`, {
                  state: { contactData: contactData },
                });
              }
            }}
            onPatientProfileIconClick={() => {
              const currentStatus = isActiveContact(contactData)
              if (!currentStatus) {
                const message = getInActiveContactError(contactData)
                notification.error({
                  message,
                });
                return
              }
              if (props?.contactData?.contactType?.contactType?.code == PERSON_TYPES.CUSTOMER || contactData?.contactType?.contactType?.code === PERSON_TYPES.CUSTOMER) {
                props?.openContactInWindowOrOpenContactIdDrawer?.(contactData.id)
              } else {
                window.open(
                  `/#/members/prospect/${contactData.id}`,
                  '_blank'
                );
              }
            }}
          />
          {/* <View style={{width: 36, height: 36}}>
            <Tooltip label="View Details">
              <Pressable
                onPress={() => {
                  onContactClicked(
                    contactData.id,
                    contactData.contactType?.contactType?.code
                  );
                }}
              >
                <RedirectSvg />
              </Pressable>
            </Tooltip>
          </View> */}
        </HStack>
        {/* Person actions removed from for appointment and task */}
        {/* <PersonActions
          showPhoneCall={true}
          shouldShowEmail={true}
          showVideoCall={true}
          showAppointment={false}
          showNotes={true}
          contactInfo={contactData}
          email={
            contactData?.email ||
            getSecondaryValue(contactData?.personContact, 'email') ||
            ''
          }
          phoneNumber={
            contactData?.phoneNumber ||
            getSecondaryValue(contactData?.personContact, 'phone') ||
            ''
          }
          parentCode={props.parentCode}
          onPersonActionPerformed={(code, data) => {
            if (code === PERSON_ACTION_CODES.EDIT) {
              setStateData((prev) => {
                return {
                  ...prev,
                  actionCode: RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW,
                  rawData: data,
                };
              });
            }
          }}
        /> */}
      </VStack>
      {stateData.actionCode === RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW && (
        <AddOrUpdateLead
          singleLeadData={stateData.rawData}
          onFormCompleteAction={(actionCode: string) => {
            setStateData({} as any);
          }}
          personType={stateData.rawData?.contactType?.contactType?.code}
          personTypeUuid={stateData.rawData?.contactType?.contactType?.id}
        />
      )}
    </>
  );
}

export default withMiniContactViewHOC(AppointmentContactBanner);
