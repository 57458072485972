import {HStack, Text} from 'native-base';
import React, { useContext } from 'react';
import {useIntl} from 'react-intl';
import Feather from 'react-native-vector-icons/Feather';
import { Colors } from '../../../../../styles';
import InfoIconSvg from '../../../../common/Svg/InfoIconSvg';

function WriteInfoBackBanner(props: {
  message?: string;
}) {
  const intl = useIntl();
  return (
    <HStack
      backgroundColor={Colors.Custom.infoBannerBgColor}
      rounded={'lg'}
      px={3}
      py={2}
      mb={2}
      alignItems={'center'}
    >
      <InfoIconSvg size={34}/>
      <Text ml={2} color={Colors.FoldPixel.GRAY400} fontWeight={200}>{intl.formatMessage({id: props.message || 'writeBackInfoMessage'})}</Text>
    </HStack>
  );
}

export default React.memo(WriteInfoBackBanner);
