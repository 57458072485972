import {Spin} from 'antd';
import {debounce} from 'lodash';
import {HStack, Stack, Text, useToast} from 'native-base';
import React, {useEffect, useState} from 'react';
import Feather from 'react-native-vector-icons/Feather';
import { ISearchFieldProps } from '../../../../../../../Interfaces';
import { ToastType, showToast } from '../../../../../../../utils/commonViewUtils';
import { ModalActionAntSelect } from '../../../../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import { Colors } from '../../../../../../../styles';
import { getEnabledWorkflowList } from '../../../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { getAccountUUID } from '../../../../../../../utils/commonUtils';
import { IAutomation } from '../interfaces';
import CollapsePanelWithSwitch from '../../../../../../TaskCard/CollapsePanelWithSwitch';
import ReadOnlyAutomationViewDrawer from './ReadOnlyAutomationViewDrawer';
import { FlowType } from '../../../../../../../context/WorkflowContext';
import { useIntl } from 'react-intl';

interface IAutomationSearch extends ISearchFieldProps {
  infoMessage?: string;
  additionalHeaders?: {[index: string]: any};
}

const AutomationSearch = (props: IAutomationSearch) => {
  const intl = useIntl();
  const {value, isShowError, onChange, infoMessage, additionalHeaders } = props;
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState<IAutomation[]>([]);
  const [readOnlyAutomationViewState, setReadOnlyAutomationViewState] = useState<{
    isReadOnlyAutomationViewOpen: boolean;
    flowType:string,
    selectedWorkflowId: any;
    workflowName: string;
  }>({
    isReadOnlyAutomationViewOpen: false,
    flowType:'',
    selectedWorkflowId: '',
    workflowName: ''
  });
  const toast = useToast();
  const accountId = additionalHeaders?.['account-uuid'] || getAccountUUID();

  useEffect(() => {
    if (value?.workflowId && listData.length === 0) {
      setListData([
        {
          ...value,
        },
      ]);
    } else {
      onSearch('');
    }
  }, []);

  const onSearch = async (searchString: string) => {
    setListData([]);
    setLoading(true);
    try {
      const response = await getEnabledWorkflowList({
        accountUUID: accountId,
        limit: 10,
        offset: 0,
        name: searchString,
        notIncludeFlowTypes: [FlowType.careJourney, FlowType.ivr],
      });
      const automation = response?.data?.data?.workflows || [];
      const updatedAutomation: IAutomation[] = automation.map((item: IAutomation) => {
        return {
          ...item,
          workflowId: item.id,
        }
      })
      setLoading(false);
      if (updatedAutomation?.length) {
        setListData(updatedAutomation);
      } else {
        setListData([]);
      }
    } catch(error) {
      showToast(toast, 'Something went wrong. Please try again later', ToastType.error);
      setLoading(false);
    }
  };

  const getDataFromId = (id: any) => {
    const matchedData = listData.filter((item) => {
      return item.workflowId === id;
    });
    if (matchedData.length > 0) {
      return {
        ...matchedData[0],
      };
    }
  };

  return (
    <>
      {value?.workflowId ? (
        <Stack direction="column">
          <CollapsePanelWithSwitch
            key={value.id}
            data={value}
            index={value.id}
            isDetailsPage={false}
            showAutomation={(show: any, workflowMasterId: any, flowType: string, workflowName: string) => {
              setReadOnlyAutomationViewState({
                isReadOnlyAutomationViewOpen: show,
                flowType,
                selectedWorkflowId: workflowMasterId,
                workflowName
              })
            }}
            onAutomationClear={() => {
              onChange(undefined);
              onSearch('');
            }}
            forGoalRuleAlert={true}
          />
          <Text style={{ color: Colors.Custom.Gray400, fontSize: 12, marginTop: 5 }}>
            <Feather
              name="info"
              style={{
                padding: 0,
                marginRight: 2,
              }}
            />
            {intl.formatMessage({ id: 'automationExecutesIfMatchingRulesFulfilled' })}
          </Text>
        </Stack>
      ) : (
        !readOnlyAutomationViewState.isReadOnlyAutomationViewOpen ? (
          <ModalActionAntSelect
            showSearch={true}
            allowClear={true}
            filterOption={false}
            value={value ? value.workflowId : undefined}
            onSearch={debounce(onSearch, 500)}
            onChange={(value: any[], data: any) => {
              if (data && data.value) {
                onChange(getDataFromId(data.value));
              } else {
                onChange(undefined);
              }
            }}
            placeholder= {'Type to Search Automation'}
            loading={loading}
            notFoundContent={(loading) && <Spin size="small" />}
            extraStyle={{flex: 1}}
            data={listData}
            optionProps={{key: 'workflowId', value: 'workflowId', label: 'name'}}
            errors={isShowError}
            renderCustomOption={(option: any) => {
              return (
                <CollapsePanelWithSwitch
                  key={option.id}
                  data={option}
                  index={option.id}
                  isDetailsPage={false}
                  showAutomation={(show: any, workflowMasterId: any, flowType: string, workflowName: string) => {
                    setReadOnlyAutomationViewState({
                      isReadOnlyAutomationViewOpen: show,
                      flowType,
                      selectedWorkflowId: workflowMasterId,
                      workflowName
                    })
                  }}
                  forGoalRuleAlert={true}
                />
              );
            }}
          />
        ) : (
          <></>
        )
      )}
      {!!infoMessage && (
        <HStack alignItems={'flex-start'} space={2} my={1}>
          <Feather name="info" size={16} color={Colors.Custom.Gray500} style={{marginTop: 2}} />
          <Text fontSize={12} color={Colors.Custom.Gray500} width="full">
            {infoMessage}
          </Text>
        </HStack>
      )}
      {readOnlyAutomationViewState.isReadOnlyAutomationViewOpen &&
        <ReadOnlyAutomationViewDrawer
          title={readOnlyAutomationViewState.workflowName}
          open={readOnlyAutomationViewState.isReadOnlyAutomationViewOpen}
          flowType={readOnlyAutomationViewState.flowType}
          workflowDetails={{
            workflowMasterId: readOnlyAutomationViewState.selectedWorkflowId,
            workflowConfig: null,
          }}
          onClose={() => {
            setReadOnlyAutomationViewState((prev) => ({
              ...prev,
              selectedWorkflowId: '',
              isReadOnlyAutomationViewOpen: false,
              workflowName: ''
            }));
          }}
        />
      }
    </>
  );
};

export default AutomationSearch;
