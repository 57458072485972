import React from 'react';
import Feather from 'react-native-vector-icons/Feather';
import {Colors} from '../../../../styles';
import {getBookingErrorTextMessage} from '../../../common/CalendarWidget/BookingWorkflows/BookAppointment/BookAppointmentHelper';
import {IBookingWidgetData} from '../AppointmentBookingWidget';
import {useIntl} from 'react-intl';
import Stack from '../../../common/LayoutComponents/Stack';
import {Text} from 'react-native';
const BookingDataMsg = (props: {bookingData?: IBookingWidgetData}) => {
  const {bookingData} = props;
  const intl = useIntl();
  return (
    <Stack
      direction='row'
      style={{
        backgroundColor: Colors.Custom.infoBannerBgColor,
        paddingHorizontal: 1,
        borderRadius: 4,
      }}
    >
      <Feather name="info" size={20} color={Colors.Custom.infoColor} />
      <Text style={{
        marginLeft: 2,
        fontWeight: "200",
      }}>
        {getBookingErrorTextMessage(bookingData?.appointmentType, intl)}
      </Text>
    </Stack>
  );
};

export default React.memo(BookingDataMsg);
