import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  HStack,
  View,
  VStack,
  Text,
  useMediaQuery,
  useToast,
  Box,
  Pressable,
  Stack
} from 'native-base';
import { Dimensions } from 'react-native';
import { Collapse, Popover } from 'antd';
import Feather from 'react-native-vector-icons/Feather';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { useIntl } from 'react-intl';
import { CommonDataContext } from '../../../../../../../context/CommonDataContext';
import {
  FormContext,
  FormViewType,
  IFormCommonData,
  IFormComponentProps,
} from '../../CustomWrapper/CustomWrapper';
import { v4 as uuidv4 } from 'uuid';
import { Colors } from '../../../../../../../styles';
import SectionLoadingIndicator from '../../CustomWrapper/SectionLoadingIndicator/SectionLoadingIndicator';
import { Drawer } from 'antd';
import { DATE_FORMATS, IPAD_MINI_WIDTH, IPAD_WIDTH, SMALL_WINDOW_1700 } from '../../../../../../../constants';
import { cloneDeep } from 'lodash';
import { DetailViewCollapse } from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailViewCollapse';
import { CUSTOM_COMPONENT_STYLE } from '../../constants';
import NoInterventionSvg from '../../../../../../common/Svg/CarePlanSvgs/NoInterventionSvg';
import InterventionRow from '../../../../../../common/Intervention/InterventionRow';
import SideMenu from '../../../../../Workflow/FlowComponent/StateNodes/SideMenu';
import { getNodeMetadata } from '../../../../../Journeys/AddOrUpdateJourney/JourneyMetadataService';
import { FlowType } from '../../../../../../../context/WorkflowContext';
import { ToastType, showToast } from '../../../../../../../utils/commonViewUtils';
import { IUserInputField } from '../../../../../Workflow/FlowComponent/StateNodes/FlowNodeInterface';
import { IInterventionType, IIntervention, IInterventionComponent, IInterventionList } from '../interfaces';
import { IFormValidationOutput } from '../../CustomWrapper/interfaces';
import { FormError } from '../../CustomWrapper/CustomComponentHelper';
import { TagComponent } from '../../../../../../common/TagComponent';
import { useMutation } from '@apollo/client';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../../../constants/Configs';
import CarePlanInterventionQueries from '../../../../../../../services/Visit/CarePlanInterventionQueries';
import { InterventionLinkTask } from '../../../../../../common/Intervention/InterventionLinkTask';
import { getDueDateInDays, getInterventionIcon, getPriorityIcon, mapValues, reverseGetMetadata } from './AddOrUpdateInterventionHelper';
import { EntityType, InterventionActions, InterventionStatus, InterventionTypeValues, TaskStatus, TaskType, interventionType, INTERVENTION_IMMEDIATE_CODE } from './InterventionConstants';
import { addTimeToDate, getDateDifference, getDateObjectFromAPIFormatToMyFormat, getMomentObj } from '../../../../../../../utils/DateUtils';

const AddOrUpdateIntervention = (props: IFormComponentProps) => {
  const { Panel } = Collapse;
  const intl = useIntl();
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const isSidecarContext = !!contextData.sidecarContext?.isSidecar;
  const isPatientNote = contextData.formContext === FormContext.patientNotes;
  const componentRef = useRef();
  const carePlanDetailsStartDateTime = props?.options?.carePlanDetailsStartDateTime;

  const toast = useToast();

  const initialDuration = {
    unit: 'day',
    value: 1,
  }
  const [componentState, setComponentState] =
    useState<IInterventionComponent>({
      loading: false,
      formError: contextData.formError,
      collapsed: contextData.defaultCollapse,
      showSavingIndicator: false,
      editModalVisible: false,
      editModalCollapse: false,
      selectedItemToMap: undefined,
      activePanels: [],
    });
    const initialState =  {
      title: '',
      interventionTitle: '',
      trigger: initialDuration,
      type: ''
    }
  const [interventionDetails, setInterventionDetails] = useState<IIntervention>();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [interventionTitle, setInterventionTitle] = useState<string>('');
  const [selectedIntervention, setSelectedIntervention] = useState<string | undefined>('');
  const [interventionTypeNodes, setInterventionTypeNodes] = useState<any[]>();
  const [userInputFieldList, setUserInputFieldList] = useState<any[]>();
  const [selectedInterventionTypeNodes, setSelectedInterventionTypeNode] = useState<any>();
  const [selectedDuration, setSelectedDuration] = useState<any>(initialDuration);

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    { maxWidth: IPAD_WIDTH },
    { maxWidth: IPAD_MINI_WIDTH },
  ]);
  const screenDimensions = Dimensions.get('window');
  const smallWindow = screenDimensions?.width < SMALL_WINDOW_1700
  const [showInterventionRowView, setShowInterventionRowView] = useState<boolean>(false);
  const [disableIntervention, setDisableIntervention] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(true);
  const [metdataError, setMetdataError] = useState<boolean>(false);

  const [componentValue, setComponentValue] = useState<IInterventionList>(
    props.component?.selectedValue ||
    props.component.defaultComponentValue
    || { interventions: [] }
  );
  const [durationInfoMsg, setDurationInfoMsg] = useState<string>("")
  const isWidgetView = props.options.componentView === FormViewType.widget;

  const onInterventionTypeChange = (interventionEntityType: string) => {
    const typeIndex = interventionType.findIndex((item) => item.code === interventionEntityType || item.value === interventionEntityType)
    const newSelectedIntervention = interventionType[typeIndex]?.type
    setSelectedIntervention(interventionType[typeIndex].value)
    if (newSelectedIntervention) {
      let selectedInterventionTypeData: any
      if (interventionTypeNodes) {
        selectedInterventionTypeData = interventionTypeNodes.find(obj => obj[newSelectedIntervention]);
      }
      setSelectedInterventionTypeNode(selectedInterventionTypeData[newSelectedIntervention])
    }
    setIsVisible(true)
  }

  const onInterventionTitleChange = (title: string) => {
    setInterventionTitle(title);
    setInterventionDetails(prev => {
      if (prev?.metadata && prev.metadata.hasOwnProperty('title')) {
        return {
          ...prev,
          metadata: {
            ...prev.metadata,
            title: title
          }
        };
      } else {
        return prev;
      }
    });
  }

  const showErrorToast = () => {
    showToast(toast, intl.formatMessage({ id: 'apiErrorMsg' }), ToastType.error);
  };

  const onDurationChange = (duration: any) => {
    const newDuration = {
      ...selectedDuration,
      ...duration
    }
    setSelectedDuration(newDuration)
    setDurationInfoMsg(getDurationInfoMessage(newDuration));
  }

  useEffect(() => {
    getNodeMetaData()
  }, [])

  const getNodeMetaData = () => {
    getNodeMetadata(
      FlowType.careJourney,
      (response) => {
        const nodeMap = response?.nodeMasterData?.nodeMap;
        const interventionNodes = getInterventionNodesList(interventionType, nodeMap);
        setInterventionTypeNodes(interventionNodes)
      },
      () => {
        showErrorToast();
      }
    );
  };

  const getInterventionNodesList = (taskType: IInterventionType[], allTaskType: any) => {
    const tasktypeData: any = [];
    taskType.forEach(task => {
      if (allTaskType.hasOwnProperty(task.type)) {
        const taskObject: any = {};
        taskObject[task.type] = allTaskType[task.type];
        const filteredUserInputFieldList = taskObject[task.type].userInputFieldList.filter((item: any) => task.userInputFieldListKeys.includes(item.key));
        taskObject[task.type].userInputFieldList = []
        taskObject[task.type].userInputFieldList.push(...filteredUserInputFieldList)
        tasktypeData.push(taskObject);
      }
    });
    return tasktypeData;
  }

  const addNewItem = (item: IIntervention) => {
    setComponentValue((prev) => {
      prev.interventions.splice(0, 0, item);
      return {
        ...prev,
      }
    });
  };

  useEffect(() => {
    const value = cloneDeep(componentValue);
    if (contextData.isBuilderMode) {
      props.component.defaultComponentValue = value;
    }
    props.onChange(value);
  }, [componentValue]);

  const onRowCancel = () => {
    setInterventionTitle('')
    setSelectedDuration(initialDuration)
    setDurationInfoMsg(getDurationInfoMessage(initialDuration));
    setSelectedIntervention('')
    setShowInterventionRowView(false)
    setShowPopup(true)
  }

  const onTaskEdit = (type: string) => {
    setIsVisible(true)
    getNodeMetaData()
    const typeIndex = interventionType.findIndex(
      (item) => item.code === type || item.value === type
    )
    const newSelectedIntervention = interventionType[typeIndex]?.type
    let selectedInterventionTypeData
    if (newSelectedIntervention) {
      if (interventionTypeNodes) {
        selectedInterventionTypeData = interventionTypeNodes.find(obj => obj[newSelectedIntervention]);
      }
      setSelectedInterventionTypeNode(selectedInterventionTypeData[newSelectedIntervention])
      const reverseMetadata = reverseGetMetadata(interventionDetails?.metadata)
      const newNode = selectedInterventionTypeData[newSelectedIntervention]
      const mappedValues = mapValues(reverseMetadata, newNode.userInputFieldList);
      newNode.userInputFieldList = []
      newNode.userInputFieldList = mappedValues
      setSelectedInterventionTypeNode(newNode)
    }
    setIsVisible(true)
  }

  const callPostInterventionApi = async (intervention: IIntervention) => {
    const response = await addOrUpdateTemplate({
      variables: {
        params: intervention
      }
    });
  }

  const onEditIntervention = (
    recurrence?:
      | {
          frequency: any;
          duration: any;
          displayValue?: string;
        }
      | undefined
  ) => {
    // when builder mode on create new intervention every add or update
    const intervention: IIntervention = {
      id: contextData.isBuilderMode ? uuidv4() : interventionDetails?.id,
      title: interventionTitle,
      type: interventionDetails?.type,
      isDeleted: false,
      trigger: getDurationFromPublishedDate(selectedDuration),
      metadata: interventionDetails?.metadata
    }
    if (recurrence) {
      intervention.frequency = recurrence.frequency
      intervention.duration = recurrence.duration
      intervention.metadata.recurrenceDisplay = recurrence.displayValue
    } else {
      intervention.metadata.recurrenceDisplay = ''
      intervention.frequency = undefined
      intervention.duration = undefined
    }
    if (interventionDetails?.status) {
      intervention.status = interventionDetails?.status
    }
    setComponentValue((prev) => {
      const index = prev.interventions.findIndex(
        (item) => item.id === interventionDetails?.id
      );
      prev.interventions.splice(index, 1);
      prev.interventions.splice(index, 0, intervention)
      return {
        ...prev,
      }
    });
    callPostInterventionApi(intervention);
    setInterventionDetails(intervention);
    setIsEdit(false);
    setShowPopup(true);
  }

  const onCreateNewIntervention = (
    recurrence?:
      | {
          frequency: any;
          duration: any;
          displayValue?: string;
        }
      | undefined
  ) => {
    const type = getInterventionEntityType()
    const intervention: IIntervention = {
      id: uuidv4(),
      title: interventionTitle,
      type: type,
      isDeleted: false,
      trigger: getDurationFromPublishedDate(selectedDuration),
      metadata: {
        ...getInterventionMetadata(userInputFieldList),
        recurrenceDisplay: recurrence?.displayValue || '',
      },
      frequency: recurrence?.frequency,
      duration: recurrence?.duration,
    };

    if (!userInputFieldList?.length) {
      setMetdataError(true)
      return
    }
    const finalInterventionObj = {...intervention, ...recurrence};
    setInterventionDetails(finalInterventionObj);
    if (intervention) {
      callPostInterventionApi(intervention);
      addNewItem({...intervention, ...recurrence});
    }
    setShowPopup(true);
  }

  const isCarePlanPublished = (): boolean => {
    return !!(interventionDetails?.startDateTime || carePlanDetailsStartDateTime);
  }

  const getDurationFromPublishedDate = (duration: any): { unit: string, value: number } => {
    const currentDateObj = getMomentObj(new Date());
    const publishedDateObj = getMomentObj(interventionDetails?.startDateTime || carePlanDetailsStartDateTime);
    const newExecuteAfterTime = Math.ceil(getDateDifference(publishedDateObj, currentDateObj, duration?.unit, true));
    return { unit: duration?.unit, value: newExecuteAfterTime + parseInt(duration?.value) };
  }

  const getDurationFromCurrentDate = (duration: any) => {
    if (isCarePlanPublished()) { // this intervention is part of published care-plan
      const currentDateObj = getMomentObj(new Date());
      const publishedDateObj = getMomentObj(interventionDetails?.startDateTime || carePlanDetailsStartDateTime).toDate();
      const executeDateObj1 = getMomentObj(addTimeToDate(publishedDateObj, duration.value, duration.unit));
      const newExecuteAfterTime = Math.floor(getDateDifference(currentDateObj, executeDateObj1, duration.unit, true));
      return {
        unit: duration.unit,
        value: newExecuteAfterTime <= 0 ? 1 : newExecuteAfterTime,
      };
    }
    // care-plan is not published
    return duration;
  }

  const getDurationInfoMessage = (duration: any) => {
    const isCarePlanPublishedFlag = isCarePlanPublished();
    let newDuration;
    if (isCarePlanPublishedFlag) {
      newDuration = getDurationFromPublishedDate(duration);
    } else {
      newDuration = duration;
    }
    return(isCarePlanPublishedFlag ? (
      `Will execute in ${duration.value} ${duration.unit}${duration.value > 1 ? 's' : ''} (${getMomentObj(addTimeToDate(carePlanDetailsStartDateTime, newDuration.value, newDuration.unit)).format('DD-MM-YYYY')})`
    ) : (
      `Will execute ${newDuration.value} ${newDuration.unit}${newDuration.value > 1 ? 's' : ''}  after Care Plan is started`
    ))
  }

  const getInterventionRowView = (fullWidth?: boolean): JSX.Element => {
    return (
      <View
        flex={1}
        style={{ width: '100%' }}
      >
        <InterventionRow
          recurrence={{
            frequency: interventionDetails?.frequency,
            duration: interventionDetails?.duration,
            displayValue: interventionDetails?.metadata?.recurrenceDisplay
          }}
          metdataError={metdataError}
          onInterventionTitleChange={onInterventionTitleChange}
          onInterventionTypeChange={onInterventionTypeChange}
          onDurationChange={onDurationChange}
          selectedIntervention={selectedIntervention}
          interventionTitle={interventionTitle}
          selectedDuration={selectedDuration}
          onCancel={onRowCancel}
          disableIntervention={disableIntervention}
          onTaskEdit={onTaskEdit}
          onSaveIntervention={(recurrence) => {
            if (isEdit) {
              onEditIntervention(recurrence)
            } else {
              onCreateNewIntervention(recurrence);
            }
            setShowInterventionRowView(false)
            setDisableIntervention(false)
            setDisableIntervention(false)
          }
          }
          durationInfoMessage={durationInfoMsg}
        />
      </View>
    );
  };

  const renderInterventionItems = (taskList: any[], headerText: string) => {
    if (!taskList?.length) {
      return (<></>);
    }
    return (
      <>
        <Text>{headerText}</Text>
        {taskList?.map((item: IIntervention) => {
          if (!item.isDeleted) {
            return (
              showInterventionRowView && isEdit && interventionDetails?.id === item.id ? getInterventionRowView(true) :
              <>
                <HStack justifyContent={'space-between'}>
                  <HStack alignSelf={'flex-start'} justifyItems={'flex-start'} width={'85%'}>
                    <Box >
                      {item.type && getInterventionIcon(item.type)}
                    </Box>
                    <VStack justifyContent={'center'} width={'90%'}>
                      <Text>{item.title}</Text>
                      <HStack alignItems={'center'} space={2} marginTop={1}>
                        <Text>
                          {item.metadata?.priority &&
                            getPriorityIcon(item.metadata?.priority)}
                          {item.metadata?.recurrenceDisplay && !!item.metadata?.priority && (<Text> • </Text>)}
                          {!!item.metadata?.recurrenceDisplay && (
                            <Text color={Colors.Custom.Gray400} fontSize={12}>
                              {item.metadata?.recurrenceDisplay}
                            </Text>
                          )}
                          {isCarePlanPublished() && (
                            <>
                              {item.startDateTime && (
                                <Text
                                  color={Colors.Custom.Gray400}
                                  fontSize={12}
                                  paddingLeft={1}
                                  paddingRight={1}
                                >
                                  • {'  '}
                                  {getDateObjectFromAPIFormatToMyFormat(
                                    item.startDateTime,
                                    DATE_FORMATS.CALL_LOG_DATE_FORMAT
                                  )}
                                  {item.status === InterventionStatus.TO_DO &&
                                    '   •'}
                                </Text>
                              )}
                              {item.startDateTime &&
                                item.status === InterventionStatus.TO_DO && (
                                  <Text
                                    color={
                                      Colors.Custom.AppointmentStatusPending
                                    }
                                    fontSize={12}
                                  >
                                    {getDueDateInDays(item.startDateTime, item)}
                                  </Text>
                                )}
                            </>
                          )}
                        </Text>
                      </HStack>
                    </VStack>
                  </HStack>
                  <HStack alignItems={'center'}>
                    {item.status && (
                      <TagComponent
                        tagColor={
                          item.status === InterventionStatus.TO_DO
                            ? Colors.Custom.PendingTaskBackgroundColor
                            : Colors.Custom.CompletedTaskBackgroundColor
                        }
                        tagTextColor={
                          item.status === InterventionStatus.TO_DO
                            ? Colors.Custom.PendingTaskFontColor
                            : Colors.Custom.CompletedTaskFontColor
                        }
                        tagText={
                          item.status === InterventionStatus.TO_DO ||
                          item.status === InterventionStatus.IN_PROGRESS
                            ? TaskStatus.Pending
                            : item.status === InterventionStatus.FAILED
                            ? TaskStatus.Pending
                            : TaskStatus.Completed
                        }
                      />
                    )}
                    {!isWidgetView &&
                      ![
                        InterventionStatus.COMPLETED,
                        InterventionStatus.IN_PROGRESS,
                      ].includes(item?.status as InterventionStatus) && (
                            <Stack direction="row" space={4}>
                              <Pressable
                                disabled={!showPopup}
                                onPress={() => {
                                  onActionPerformed(
                                    InterventionActions.EDIT,
                                    item
                                  );
                                }}
                              >
                                <Feather
                                  name="edit-2"
                                  color={
                                    !showPopup
                                      ? Colors.Custom.Gray300
                                      : Colors.Custom.Gray500
                                  }
                                  size={18}
                                />
                              </Pressable>
                              <View
                                style={{
                                  width: 1,
                                  height: 20,
                                  backgroundColor: Colors.Custom.Gray300,
                                  marginRight: -10,
                                  marginLeft: -10,
                                }}
                              />
                              <Pressable
                                disabled={!showPopup}
                                onPress={() => {
                                  onActionPerformed(
                                    InterventionActions.DELETE,
                                    item
                                  );
                                }}
                              >
                                <Feather
                                  name="trash-2"
                                  color={
                                    !showPopup
                                      ? Colors.Custom.Gray300
                                      : Colors.Custom.Gray500
                                  }
                                  size={18}
                                />
                              </Pressable>
                            </Stack>
                      )}
                  </HStack>
                </HStack>
                {item.status === InterventionStatus.COMPLETED && (
                  <>
                    <InterventionLinkTask
                      interventionId={item.carePlanInterventionId || ''}
                      patientId={`${contextData.patientId}`}
                      accountLocationUuid={`${contextData.locationId}`}
                    />
                  </>
                )}
              </>
            );
          }
        })
        }
      </>
    )
  }

  const getInterventionDetailsView = (): JSX.Element => {
    const notProviderTaskList = componentValue.interventions.filter((item: IIntervention) => {
      return item?.type && item.type !== TaskType.ProviderTask && !item?.isDeleted
    });
    const providerTaskList = componentValue.interventions.filter((item: IIntervention) => {
      return item?.type && item.type === TaskType.ProviderTask && !item?.isDeleted
    });
    return (
      <View
        flex={1}
        style={{ width: '100%', padding: 15 }}
      >
        {notProviderTaskList.length > 0 && renderInterventionItems(notProviderTaskList, 'For Patient')}
        {providerTaskList.length > 0 && renderInterventionItems(providerTaskList, 'For Internal Users')}
      </View>
    );
  };

  const onActionPerformed = (action: InterventionActions, intervention: IIntervention) => {
    switch (action) {
      case InterventionActions.EDIT:
        setShowPopup(false)
        updateIntervention(intervention);
        break;
      case InterventionActions.DELETE:
        deleteIntervention(intervention);
        break;
    }
  };

  const validateData = (currentData: IInterventionList): IFormValidationOutput => {
    // If no patient id is not found means it's lead and for lead, the section is not applicable. So validation should always return true
    if (componentState.formError === FormError.noPatientIdFound) {
      return { isValid: true, message: '' };
    }
    setComponentState((prev) => ({ ...prev, showErrors: true }));
    const isValid = true;
    return { isValid: isValid, message: !isValid ? `${props.component.label}: Please fill all the mandatory fields` : '' };
  };

  props.validateRef.current = validateData;

  const [addOrUpdateTemplate] = useMutation(CarePlanInterventionQueries.ADD_OR_UPDATE_INTERVENTION, {
    fetchPolicy: 'no-cache',
    context: {
      Headers: contextData.headers,
      service: CARESTUDIO_APOLLO_CONTEXT,
    }
  })

  const updateIntervention = async (intervention: IIntervention) => {
    setIsEdit(true)
    setDisableIntervention(true)
    setShowInterventionRowView(true)
    setInterventionTitle(intervention.title)
    setSelectedIntervention(intervention?.type)
    setSelectedDuration(getDurationFromCurrentDate(intervention.trigger))
    setDurationInfoMsg(getDurationInfoMessage(getDurationFromCurrentDate(intervention.trigger)))
    setInterventionDetails(intervention)
  }

  const deleteIntervention = (intervention: any) => {
    const index = componentValue.interventions.findIndex(
      (item) => item.id === intervention.id
    );
    if (index !== -1) {
      if (contextData.isBuilderMode) {
        setComponentValue(prev => ({
          ...prev,
          interventions: prev.interventions.filter(item => item.id !== intervention.id)
        }));
      } else {
        if (componentValue.interventions[index]?.id) {
          componentValue.interventions[index].isDeleted = true
        }
        setComponentValue((prev) => ({
          ...prev,
          interventions: [...componentValue.interventions],
        }));
      }
    }
    if (componentValue.interventions?.length === 0) {
      setShowInterventionRowView(false)
    }
    setInterventionDetails(initialState)
  };

  const getDetailView = (): JSX.Element => {
    return (
      <VStack alignItems={'center'}>
        {componentValue.interventions.length === 0 && !showInterventionRowView ? <NoInterventionSvg titleId="noIntervention" /> :
          <HStack space={2} alignItems="center" w={'100%'}>
            {componentState.showSavingIndicator && <SectionLoadingIndicator />}
            {showInterventionRowView && !isEdit && getInterventionRowView(true)}
          </HStack>}
      </VStack>
    );
  };

  const getInterventionDetails = (): JSX.Element => {
    return (
      <VStack paddingBottom={2} alignItems={'center'}>
        <HStack space={2} alignItems="center" w={'100%'}>
          {getInterventionDetailsView()}
        </HStack>
      </VStack>
    );
  };

  const showInterventionRow = () => {
    if (!isWidgetView) {
      setDisableIntervention(false)
      setShowInterventionRowView(true)
      setInterventionTitle('')
      setSelectedDuration(initialDuration)
      setSelectedIntervention('')
    }
    setIsEdit(false)
    setInterventionDetails(initialState)
  }

  const getInterventionEntityType = () => {
    const typeIndex = interventionType.findIndex((item) => item.value === selectedIntervention)
    switch (interventionType[typeIndex]?.type) {
      case InterventionTypeValues.CareJourneyAddTaskForContact: return EntityType.MemberTask
      case InterventionTypeValues.SendForms: return EntityType.Form
      case InterventionTypeValues.CareJourneyContentV3: return EntityType.EducationContent
      case InterventionTypeValues.CareJourneyVital: return EntityType.MeasureVital
      case InterventionTypeValues.CareJourneyAddTask: return EntityType.ProviderTask
      case InterventionTypeValues.SendAppointmentBookingLink: return EntityType.AppointmentLink
    }
  }

  const getInterventionMetadata = (selectedInterventionTypeNodes: any) => {
    const result: { [key: string]: string } = {};
    selectedInterventionTypeNodes.forEach((item: any) => {
      result[item.key] = item.value;
    });
    return result
  }

  const onSave = (data: any) => {
    const newUserInputFieldList: IUserInputField[] =
      data.userInputFieldMap.out.userInputFieldList || [];
    const titleObj = newUserInputFieldList.find((item: any) => ['patientFacingTitle', 'title'].includes(item.key));
    let title = titleObj?.value;
    if (!title) {
      const educationContent = newUserInputFieldList?.find(
        (item) => item.key === EntityType.EducationContent
      );
      if (educationContent) {
        title = educationContent?.value?.title || '';
      }
    }
    setInterventionTitle(title || interventionTitle)
    setUserInputFieldList(newUserInputFieldList)
    setIsVisible(false)
  };

  return (
    <View ref={componentRef}>
      <div className='page-break'>
        <DetailViewCollapse
          collapsible='disabled'
          hideArrow
          btnList={!isWidgetView ? [
            {
              icon: <View style={{ flexDirection: 'row' }}>
                <AntIcon
                  name="plus"
                  style={{ color: Colors.Custom.foldIconColor, borderWidth: 0, marginRight: contextData.isBuilderMode ? 5 : 0 }}
                  size={20}
                />
                {(!contextData.isBuilderMode && contextData.componentView === 'detail' && !componentValue.interventions?.[0]?.startDateTime) && <View style={{
                  width: 1,
                  height: 20,
                  marginLeft: 7,
                  marginRight: -2,
                  backgroundColor: Colors.Custom.Gray300,
                  marginHorizontal: 2
                }} />}
              </View>
              ,
              onIconBtnClick: showInterventionRow,
              tooltipLabel: 'Add',
              placement: 'top right',
              isCustomButton: false,
            },
          ] : []}
          textLocalId={props.component.label}
          toggled={!componentState.collapsed}
          headerStyle={{ marginTop: 3 }}
          extraTextStyles={
            isPatientNote ? { fontWeight: 'bold', fontSize: 16 } : {}
          }
          extraPanelStyles={
            isPatientNote
              ? { borderColor: '#D0D5DD', ...CUSTOM_COMPONENT_STYLE }
              : {}
          }
          onToggled={() => {
            setComponentState((prev) => ({
              ...prev,
              collapsed: !prev.collapsed,
            }));
          }}
        >
          <VStack>
            {!componentState.loading &&
              !componentState.formError &&
              getDetailView()}
            {componentValue.interventions?.length > 0 && getInterventionDetails()}
          </VStack>
        </DetailViewCollapse>
      </div>
      <Drawer
        destroyOnClose
        placement="right"
        open={isVisible}
        closable
        width={
         isSidecarContext ? '100%' : (isIPadScreen || isIPadMiniScreen ? '70%' : smallWindow ? '60%' : '50%')
        }
        headerStyle={{ display: 'none' }}
      >
        <SideMenu
          userInputFieldMap={{
            out: {
              userInputFieldList: selectedInterventionTypeNodes?.userInputFieldList,
            },
          }}
          nodeType={selectedInterventionTypeNodes || ''}
          isOpen={true}
          title={selectedInterventionTypeNodes && selectedInterventionTypeNodes.displayName || ''}
          onClose={() => { setIsVisible(false) }}
          onSave={onSave}
        />
      </Drawer>
    </View>
  );
};

export default AddOrUpdateIntervention;
