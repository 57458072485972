import React from 'react';
import {ICommonSvgProps} from './interfaces';

const PreferencesNoEmailSignatureSvg = (props: ICommonSvgProps) => {
  return (
    <svg width="102" height="102" viewBox="0 0 102 102" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="51" cy="51.0001" r="50" stroke="url(#paint0_radial_45_2098)" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="1.46 1.46"/>
      <circle cx="50.9997" cy="51" r="42.6667" stroke="url(#paint1_radial_45_2098)" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="1.46 1.46"/>
      <path d="M15.8535 51.1016C15.8535 31.7716 31.5236 16.1016 50.8535 16.1016C70.1835 16.1016 85.8536 31.7716 85.8536 51.1016C85.8536 70.4316 70.1835 86.1016 50.8535 86.1016C31.5236 86.1016 15.8535 70.4316 15.8535 51.1016Z" fill="url(#paint2_linear_45_2098)"/>
      <path d="M33.4991 68.75L37.1535 53.6104C38.4823 48.1055 39.1467 45.3531 41.0685 43.5057C42.9903 41.6583 45.7668 41.103 51.3197 39.9924L53.581 39.5401L62.7091 48.6682L62.2568 50.9294C61.1462 56.4824 60.5909 59.2589 58.7435 61.1807C56.8961 63.1025 54.1436 63.7669 48.6388 65.0956L33.4991 68.75ZM33.4991 68.75L47.3483 54.9009M67.2731 42.2785L59.9706 34.9761C59.8252 34.8306 59.7525 34.7579 59.6881 34.6994C58.2955 33.4336 56.1689 33.4336 54.7763 34.6994C54.7119 34.7579 54.6392 34.8306 54.4938 34.9761C54.3484 35.1215 54.2756 35.1942 54.2171 35.2586C52.9513 36.6512 52.9513 38.7778 54.2171 40.1704C54.2756 40.2348 54.3483 40.3075 54.4938 40.4529L61.7962 47.7554C61.9417 47.9008 62.0144 47.9735 62.0787 48.032C63.4714 49.2979 65.598 49.2979 66.9906 48.032C67.055 47.9735 67.1277 47.9008 67.2731 47.7554C67.4185 47.61 67.4912 47.5373 67.5497 47.4729C68.8156 46.0803 68.8156 43.9537 67.5497 42.561C67.4912 42.4967 67.4185 42.424 67.2731 42.2785ZM49.9297 48.6682C51.9462 48.6682 53.581 50.3029 53.581 52.3195C53.581 54.336 51.9462 55.9707 49.9297 55.9707C47.9132 55.9707 46.2785 54.336 46.2785 52.3195C46.2785 50.3029 47.9132 48.6682 49.9297 48.6682Z" stroke="#A7AFBE" stroke-width="1.5" stroke-linecap="round"/>
      <defs>
        <radialGradient id="paint0_radial_45_2098" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(51.0001 73.6082) rotate(-90) scale(72.5485)">
        <stop stop-color="#E4E7EC"/>
        <stop offset="1" stop-color="#E4E7EC" stop-opacity="0.25"/>
        </radialGradient>
        <radialGradient id="paint1_radial_45_2098" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(50.9998 70.2923) rotate(-90.639) scale(66.9587)">
        <stop stop-color="#E4E7EC"/>
        <stop offset="1" stop-color="#E4E7EC" stop-opacity="0.35"/>
        </radialGradient>
        <linearGradient id="paint2_linear_45_2098" x1="25.9993" y1="24" x2="73.9993" y2="84.75" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F9FAFC"/>
        <stop offset="1" stop-color="#F1F2F3"/>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PreferencesNoEmailSignatureSvg;