import React, {useState} from 'react';
import {useToggle} from 'react-use';
import ReasonForVisitFreeText from '../../../../../../PublicPages/AppointmentBookingWidget/ReasonForVisitFreeText';
import {useAppointmentDataContext} from '../contexts/AppointmentDataContext';
import {useIntl} from 'react-intl';
import {debounce} from 'lodash';
import {FoldButton} from '../../../../../../CommonComponents/FoldButton/FoldButton';
import {BUTTON_TYPE} from '../../../../../../../constants';
import {Colors} from '../../../../../../../styles';
import {Text} from 'native-base';

const HiddenView = (props: {
  title: string;
  children: JSX.Element;
  defaultOpen?: boolean;
  icon?: JSX.Element;
}) => {
  const {title, children, defaultOpen = false} = props;

  const [open, setOpen] = useState(defaultOpen);
  const intl = useIntl();

  if (!open) {
    return (
      <FoldButton
        nativeProps={{
          variant: BUTTON_TYPE.PRIMARY,
          style: {
            width: 'max-content',
            borderWidth: 0,
            paddingLeft: 4,
            // paddingRight: 4,
            minHeight: 'max-content',
          },
          _hover: {
            backgroundColor: Colors.FoldPixel.PRIMARY_20,
          },
          onPress: () => {
            setOpen((prev) => !prev);
          },
          leftIcon: props.icon,
        }}
        customProps={{
          btnText: (
            <Text
              cursor={'pointer'}
              size="smMedium"
              fontWeight={'400'}
              fontSize={14}
              color={Colors.Custom.mainPrimaryPurple}
            >
              {title}
            </Text>
          ),
          withRightBorder: false,
        }}
      ></FoldButton>
    );
  }

  return children;
};

export default React.memo(HiddenView);
