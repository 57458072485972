import {gql} from '@apollo/client';

export const GET_PERMISSION_BY_ENTITY = gql`
  query GetPermission($entityCode: String) {
    userPermissions(
      where: {entityCode: {_eq: $entityCode}, isDeleted: {_eq: false}}
    ) {
      id
      accountUUID
      entityCode
      userRoleCode
      permissionCode
      actionCode
      createdAt
    }
  }
`;

export const CREATE_NEW_PERMISSION = gql`
  mutation create(
    $permissionCode: String
    $userRoleCode: String
    $accountUUID: uuid!
    $entityCode: String
    $actionCode: String
    $actionId: uuid
    $accountRoleId: uuid
  ) {
    createUserPermission(
      object: {
        actionCode: $actionCode
        permissionCode: $permissionCode
        userRoleCode: $userRoleCode
        accountUUID: $accountUUID
        entityCode: $entityCode
        actionId: $actionId
        accountRoleId: $accountRoleId
      }
    ) {
      accountUUID
      entityCode
      userRoleCode
      permissionCode
      accountRoleId
      id
    }
  }
`;

export const UPDATE_PERMISSION = gql`
  mutation updatePermission($id: uuid, $permissionCode: String, $actionId: uuid!, $accountRoleId: uuid!) {
    updateUserPermissions(where: {id: {_eq: $id}}, _set: {permissionCode: $permissionCode, actionId: $actionId, accountRoleId: $accountRoleId}) {
      returning {
        id
      }
    }
  }
`;

export const GET_PERMISSION_BY_ENTITY_CODE_AND_ACTION_CODE = gql`
  query getPermissionByEntityCodeAndActionCode(
    $entityCode: String
    $userRoleCode: String
    $actionCode: String
    $accountRoleId: uuid!
  ) {
    userPermissions(
      where: {
        userRoleCode: {_eq: $userRoleCode}
        actionCode: {_eq: $actionCode}
        entityCode: {_eq: $entityCode}
        accountRoleId:{_eq: $accountRoleId}
        isDeleted: {_eq: false}
      }
    ) {
      actionCode
      entityCode
      id
      userRoleCode
      permissionCode
      createdAt
    }
  }
`;

export const createUserRolePermissions = gql`
  mutation createUserRolePermissions(
    $objects: [user_permissions_insert_input!]!
  ) {
    createUserPermissions(objects: $objects) {
      returning {
      id
    }
    }
  }
`;

export const createAccountRole = gql`
  mutation createAccountRole(
    $customRoleCode: String
    $roleName: String
    $roleId: uuid
  ) {
    createAccountRole(
      object: {
        customRoleCode: $customRoleCode
        roleName: $roleName
        roleId: $roleId
      }
    ) {
      id
    }
  }
`;

export const getAccountRoles = gql`
query getAccountRole($category: [String!]) {
    accountRoles(where: {isDeleted: {_eq: false}, userRole: {category: {category: {_in: $category}}}}) {
    accountUuid
    customRoleCode
    id
    isDeleted
    roleId
    roleName
    userRole {
      id
      code
      value
    }
  }
}
`

export default {
  GET_PERMISSION_BY_ENTITY,
  CREATE_NEW_PERMISSION,
  UPDATE_PERMISSION,
  GET_PERMISSION_BY_ENTITY_CODE_AND_ACTION_CODE,
  createUserRolePermissions,
  createAccountRole,
  getAccountRoles
};
