import { useLazyQuery } from "@apollo/client";
import { AutoComplete, Drawer, Spin } from "antd";
import { debounce } from "lodash";
import { View, Text, VStack, HStack, FormControl, Spinner, CheckIcon, Select as NativeSelect, Skeleton, Input, IInputProps, Pressable, Image} from "native-base";
import { Select } from 'antd';
import { useContext, useEffect, useRef, useState } from "react";
import {useIntl} from "react-intl";
import { Dimensions, TextInputSelectionChangeEventData, TouchableOpacity } from "react-native";
import { BUTTON_TYPE, PATIENT_CONSENT_ERROR, PHONE_NUMBER_MASK } from "../../../../constants";
import { COMMON_ACTION_CODES } from "../../../../constants/ActionConst";
import {CLOUD_TELEPHONY_APOLLO_CONTEXT} from "../../../../constants/Configs";
import { CommonDataContext } from "../../../../context/CommonDataContext";
import { CloudTelephonyQueries, LeadQueries, UserQueries } from "../../../../services";
import { Colors } from "../../../../styles/Colors";
import { getAccountId, getAccountUUID, getAllowedUserAccountLocationUuids, getAllowedUserPracticeLocationUuids, getUserPracticeLocations, getUserUUID, isContactConsentGiven, isContactConsentRequired, numericStringMask } from "../../../../utils/commonUtils";
import { getContactTypeId, getMlovId } from "../../../../utils/mlovUtils";
import { DisplayText } from "../../../common/DisplayText/DisplayText";
import MemberInfoListItem from "../../../common/MemberInfoListItem/MemberInfoListItem";
import {ModalActionSelect} from "../../../common/ModalActionCommonComponent/ModalActionSelect";
import { ModalActionTitle } from "../../../common/ModalActionTitle/ModalActionTitle";
import {IUsersResponse} from "../../Contacts/TeamMembers/interfaces";
import { IContact } from "../../TeamInbox/Conversations/interfaces";
import { SMS_INBOX_ACTION_CODES } from "../../TeamInbox/Integrations/IntegrationCreate/SmsInboxCreate/SmsInboxConst";
import {getVirtualNumberFormattedList} from "../CloudTelephonyUtils";
import {IVirtualPhoneNumberResp} from "../interfaces";
import { styles } from './SelectPatientCallDrawerStyle'
import { FoldButton } from "../../../CommonComponents/FoldButton/FoldButton";
import VoiceCall from "../../../common/Svg/GlobalActionSvg/VoiceCall";
import Dialpad from "./Dialpad";
import { insertAtIndex } from "../../ContentManagement/PushNotifications/PushNotificationsUtils";
import UserSvg from "../../../common/Svg/UserSvg";
import DialpadSvg from "../../../common/Svg/DialpadSvg";
import BaseService from "../../../../services/CommonService/BaseService";
import {EventBus} from "../../../../utils/EventBus";
import DialpadDeleteSvg from "../../../common/Svg/DialpadDeleteSvg";
import USFlagSVG from "../../../common/Svg/FlagIcon/USFlagSVG";
import { IVirtualNumber } from "../../../common/OutboundCallSmsView/interface";
import {getCallExtensionList} from "../../CallConfiguration/AddCallExtensionService";
import {IExtensionList} from "../../CallConfiguration/interface";
import { CONFIG_CODES } from "../../../../constants/AccountConfigConst";
import { isAccountConfigEnabled } from "../../../../utils/configUtils";
import UserPracticeLocationQueries from "../../../../services/Location/UserPracticeLocationQueries";
import { MAIN_MENU_CODES } from "../../../SideMenuBar/SideBarConst";
import { USER_ACCESS_PERMISSION } from "../../UserAccess/UserAccessPermission";
const { Option: AutoCompleteOption } = AutoComplete;

export const CreateAndDialNewNumber = async (
  newPhoneNumber: string,
  accountUUID:  string,
  source?: string,
)=>{
  const baseService = BaseService.getSharedInstance().axios;
  const body = {
    phoneNumber: newPhoneNumber,
    accountUuid: accountUUID
  };
  return await baseService.post('crm-nest/account/contact_create', {
    ...body,
  });
};

export const formatPhoneNumber = (phoneNumber: string) => {
  const match = phoneNumber.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
  if (match) {
    return !match[2]
      ? match[1]
      : `(${match[1]}) ${match[2]}${match[3] ? `-${match[3]}` : ''}`;
  }
  return phoneNumber;
};

const SelectPatientCallDrawer = (props: any) => {
    const {
        isDrawerVisible,
        selectedRecord,
        onCreateCallConversationActionPerformed,
        handleCloudCall,
    } = props;
    const { width } = Dimensions.get('window');
    const accountId = getAccountId();
    const accountUUID = getAccountUUID();
    const userUuid = getUserUUID();
    const allowedUserAccountLocationUuids = getAllowedUserAccountLocationUuids(
      USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
      MAIN_MENU_CODES.CALL
    );
    const allowedUserPracticeLocationUuids = getAllowedUserPracticeLocationUuids(
      USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
      MAIN_MENU_CODES.CALL
    );
    const mlovData = useContext(CommonDataContext);
    // const contactTypeUuid = getContactTypeId('CUSTOMER');
  const contactTypeUuid = getContactTypeId('CUSTOMER');
    const cloudMlovData = mlovData.CLOUD_TELEPHONY_MLOV;
    const CommunicationType = cloudMlovData?.CommunicationType;
    const assigneesTypeList = cloudMlovData['VirtualNumberAssignee'] || [];
    const intl = useIntl();
    const isConsentRequired = isContactConsentRequired();
    const isSideCarContext = mlovData.sidecarContext?.isSidecar;
    const finalWidth = isSideCarContext ? '100%' : width / 3;
    const isCommunicationLocationHandlingEnabled = isAccountConfigEnabled(
      CONFIG_CODES.ENABLE_COMMUNICATION_LOCATION_HANDLING
    );
    const [
      CreateEmailConversationDrawerState,
      setCreateEmailConversationDrawerState,
    ] = useState({
      isPatientDataLoading: false,
      selectedPatient: {} as IContact,
      patientLists: [] as IContact[],
      errors: {} as any,
      isConsentGiven: true,
    });

    const [GET_NUMBER_CALL_SMS] = useLazyQuery(
      CloudTelephonyQueries.GET_SINGLE_NUMBER_BY_VIRTUAL_NUMBER,{
        fetchPolicy: 'no-cache',
      }
    );

    const [numberValue, setNumberValue] = useState('');
    const [phoneNumberValue, setPhoneNumberValue] = useState({
      countryCode: '+1',
      phoneNumber: '',
    });
    const [cursorPosition, setCursorPosition] = useState(0);
    const [isDialpadOn, setIsDialpadOn] = useState<boolean>(false);
    const handleNumberChange = (text: string) => {
      const numericValue = text.replace(/[^0-9]/g, '');
      if (numericValue?.length <= 10) {
        setNumberValue(numericValue);
      }
    };

    const handleChangeDialpad = (text: string, index: number) => {
      const numericValue = text.replace(/[^0-9]/g, '');
      if (numberValue?.length < 10) {
        setNumberValue((prev) => {
          return insertAtIndex(prev, index, numericValue);
        });
        setCursorPosition(cursorPosition + 1);
      }
    };

    const [stateData, setStateData] = useState({
      numberList: [] as any,
      loading: false,
      selectedNumber: selectedRecord || {},
      pageNo: 1,
      limit: 10,
      extensionList: [] as IExtensionList[],
    });

    const fetchCallExtensionList = async (searchString?: string) => {
      setStateData((prev)=> {
        return {
          ...prev,
        }
      })
      const params = {
        pageNo: stateData?.pageNo,
        pageSize: stateData?.limit,
        searchString: searchString,
      }
      try {
        const response = await getCallExtensionList(params);
        if (response?.data?.data?.length) {
          const callExtensions = response?.data?.data;
          const filterActiveExtension = callExtensions?.filter((extension: any) => {
            return extension?.isActive
          })
          setStateData((prev)=> {
            return {
              ...prev,
              extensionList: filterActiveExtension,
              loading: false,
              total: response?.data?.count,
            }
          })
        } else {
          setStateData((prev)=> {
            return {
              ...prev,
              loading: false,
              extensionList: []
            }
          })
        }
      } catch (error: any) {
        setStateData((prev)=> {
          return {
            ...prev,
            loading: false
          }
        })
      }
    }

    const getVirtualNumbersList = async (phoneNumer: string) => {
      return await GET_NUMBER_CALL_SMS({
        context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
        variables: {
          accountUuid: accountUUID,
          virtualPhoneNumber: phoneNumer,
        },
      }).catch((error) => {});
    }

    const createNewEntry = async () => {
      setPhoneNumberValue((prev) => {
        return {
          ...prev,
          phoneNumber: prev.countryCode + numberValue,
        };
      });
      const numberToCreate = phoneNumberValue.countryCode + numberValue;
      const responseData = await getVirtualNumbersList(numberToCreate);
      const numberAssigneeList: IVirtualNumber[] =
        responseData?.data?.virtualPhoneNumberAssignees;
      if (numberAssigneeList?.length) {
        const userNumberAssigneeData = numberAssigneeList?.[0];
        const contactData = {
          phoneNumber: numberValue,
          name: userNumberAssigneeData?.assignee,
        } as any;
        if (handleCloudCall) {
          handleCloudCall(
            COMMON_ACTION_CODES.CLOUD_CALL,
            contactData,
            selectedRecord?.phoneNumber?.phoneNumber ||
            selectedRecord?.rowData?.virtualPhoneNumber ||
            stateData?.selectedNumber?.phoneNumber?.phoneNumber,
          );
        }
        closeModal();
      } else if (numberValue?.length >= 3 && numberValue?.length <= 6) {
        const extensionData = stateData?.extensionList?.find((extension) => {
          return extension?.extensionNumber === numberValue;
        })
        const contactData = {
          phoneNumber: numberValue,
          name: extensionData?.extensionName,
        }
        if (handleCloudCall) {
          handleCloudCall(
            COMMON_ACTION_CODES.CLOUD_CALL,
            contactData,
            selectedRecord?.phoneNumber?.phoneNumber ||
            selectedRecord?.rowData?.virtualPhoneNumber ||
            stateData?.selectedNumber?.phoneNumber?.phoneNumber,
            {
              isExtension: (numberValue?.length >= 3 && numberValue?.length <= 6),
              extensionNumber: numberValue
            }
          );
        }
        closeModal();
      } else {
        const newData = await CreateAndDialNewNumber(numberToCreate, accountUUID, 'Call')
          .then((resp) => {
            const contactData: IContact =
              resp?.data?.data?.createContactPerson?.contact ||
              resp?.data?.contact[0];
            contactData.phoneNumber =
              resp?.data?.data?.createContactPerson?.contact.phoneNumber ||
              resp?.data?.contact[0].phone_number;
            if (contactData) {
              if (handleCloudCall) {
                handleCloudCall(
                  COMMON_ACTION_CODES.CLOUD_CALL,
                  contactData,
                  selectedRecord?.phoneNumber?.phoneNumber ||
                  selectedRecord?.rowData?.virtualPhoneNumber ||
                  stateData?.selectedNumber?.phoneNumber?.phoneNumber,
                );
              }
              closeModal();
            }
          })
          .catch((err) => {});
      }
    };

    const [getPatientList] = useLazyQuery(
        LeadQueries.GetContactsForSMSInboxCreate,
        {
            fetchPolicy: 'no-cache',
        }
    );


    const [getPatientListByLocationUuids] = useLazyQuery(
      LeadQueries.GetContactsForGroupByLocationUuids,
      {
        fetchPolicy: 'no-cache',
      }
    );

    const [getContact] = useLazyQuery(LeadQueries.GetContact, {
        fetchPolicy: 'no-cache',
      });

    const [GET_VIRTUAL_PHONE_NUMBER] = useLazyQuery<IVirtualPhoneNumberResp>(
      CloudTelephonyQueries.GET_VIRTUAL_PHONE_NUMBER,
      {
        fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      }
    );
    
    const [GET_USER_PRACTICE_LOCATION_BASED_VIRTUAL_PHONE_NUMBERS] = useLazyQuery<IVirtualPhoneNumberResp>(
      CloudTelephonyQueries.GET_USER_PRACTICE_LOCATION_BASED_VIRTUAL_PHONE_NUMBERS,
      {
        fetchPolicy: 'no-cache', //FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      }
    );
    
    const [GetUsersByUserPracticeLocations] = useLazyQuery(
      UserPracticeLocationQueries.GetUsersByUserPracticeLocations, {
        fetchPolicy: 'no-cache',
      }
    );

    const [GET_ALL_DEFAULT_NUMBER_FOR_ACCOUNT] = useLazyQuery(
      CloudTelephonyQueries.GET_ALL_DEFAULT_NUMBER_FOR_ACCOUNT,
      {
        fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      }
    );
    const [getUsersData] = useLazyQuery<IUsersResponse>(UserQueries.GET_USERS, {
      variables: {
        searchString: '%%',
        accountId: getAccountId(),
      },
    });
    const closeModal = () => {
        onCreateCallConversationActionPerformed(
            SMS_INBOX_ACTION_CODES.DRAWER_CLOSE
        );
    };

    const setEmptyPatientList = () => {
        setCreateEmailConversationDrawerState((prev) => {
            return {
                ...prev,
                patientLists: [],
                isPatientDataLoading: false,
            };
        });
    }

    const getPatientLists = async (searchPatient: string): Promise<any[]> => {
        if (searchPatient.length) {
            setCreateEmailConversationDrawerState((prev) => {
                return {
                    ...prev,
                    patientLists: [],
                    selectedPatient: {} as IContact,
                    loadingPatientData: true,
                    errors: {
                        ...prev.errors,
                        patient: '',
                    },
                };
            });
            let patientData = [] as IContact[];
            if (isCommunicationLocationHandlingEnabled) {
              const responseData = await getPatientListByLocationUuids({
                variables: {
                  accountId: accountId,
                  typeId: contactTypeUuid,
                  isDeleted: false,
                  searchString: `%${searchPatient}%`,
                  locationUuids: allowedUserPracticeLocationUuids,
                },
              });
              if (responseData?.data?.contacts?.length) {
                patientData = responseData?.data?.contacts || [];
              }
            } else {
              const responseData = await getPatientList({
                variables: {
                  accountId: accountId,
                  typeId: contactTypeUuid,
                  isDeleted: false,
                  searchString: `%${searchPatient}%`,
                },
              });
              if (responseData?.data?.contacts?.length) {
                patientData = responseData?.data?.contacts || [];
              }
            }
            setCreateEmailConversationDrawerState((prev) => {
              return {
                ...prev,
                patientLists: patientData || [],
                isPatientDataLoading: false,
              };
            });
        } else {
            setEmptyPatientList();
        }

        return [];
    };

    const getPatientListOptElem = () => {
        return CreateEmailConversationDrawerState?.patientLists?.map((patient) => {
            return (
                <AutoCompleteOption
                    key={patient.id}
                    patientData={patient}
                    value={patient.name}
                >
                     <MemberInfoListItem
                        showDateOfBirth={true}
                        contactData={patient}
                        isPatientSearchCard
                      />
                </AutoCompleteOption>
            );
        });
    };

    const handleOnCallAction = () => {
        getContact({
            variables: {
                id: CreateEmailConversationDrawerState?.selectedPatient?.id
            }
        }).then((resp) => {
            if (resp?.data) {
                if (handleCloudCall) {
                    handleCloudCall(
                      COMMON_ACTION_CODES.CLOUD_CALL,
                      resp?.data?.contact,
                      selectedRecord?.phoneNumber?.phoneNumber ||
                        selectedRecord?.rowData?.virtualPhoneNumber ||
                        stateData?.selectedNumber?.phoneNumber?.phoneNumber
                    );
                  }
                closeModal();
            }
        })
    }

    const handleDeletePress = () => {
      if (numberValue.length > 0) {
        setNumberValue(numberValue.slice(0, -1));
      }
    };

    const handleDeleteKeyPress = () => {
      if (inputRef?.current && !inputRef?.current?.contains?.(event?.target)) {
        const cleanNumber = inputRef?.current?.value.replace(/\D/g, '');
        setNumberValue(cleanNumber.slice(0, -1));
      }
    };

    const checkCallButtonDisabled = () => {
      if (stateData?.selectedNumber?.id) {
        if (
          !isDialpadOn
            ? CreateEmailConversationDrawerState?.selectedPatient.id &&
              CreateEmailConversationDrawerState.isConsentGiven
            : ((numberValue?.length >= 3 && numberValue?.length <=6) || numberValue?.length === 10)
        ) {
          return false;
        }
        return true;
      }
      return true;
    };

    const getNumbersList = async () => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: true,
        };
      });
      let responses:any;
      if (isCommunicationLocationHandlingEnabled) {
        let usersWithMatchingLocations:string[] = [];
        const userPracticeLocationsResp = await GetUsersByUserPracticeLocations({
          variables: {
            locationUuids: allowedUserAccountLocationUuids,
            accountUuid: accountUUID
          },
        });

        
        if (userPracticeLocationsResp?.data?.userPracticeLocations) {
          usersWithMatchingLocations =
          userPracticeLocationsResp?.data?.userPracticeLocations?.map(
            (value: {uuid: string; userUuid: string}) => {
              return value?.userUuid;
            }
          );
        }
        usersWithMatchingLocations.push(...allowedUserAccountLocationUuids);

        responses = await GET_USER_PRACTICE_LOCATION_BASED_VIRTUAL_PHONE_NUMBERS({
          context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
          variables: {
            accountUuid: accountUUID,
            assigneeIds: usersWithMatchingLocations,
            searchString: '%%',
          },
        });
      } else {
        responses = await GET_VIRTUAL_PHONE_NUMBER({
          context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
          variables: {
            accountUuid: accountUUID,
            searchString: '%%',
          },
        });
      }
      const userDataList = await getUsersData();
      const allDefaultForAccount = await GET_ALL_DEFAULT_NUMBER_FOR_ACCOUNT({
        context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
        variables: {
          accountUuid: accountUUID,
          tenantId: accountUUID
        },
      });
      const virtualNumberList = getVirtualNumberFormattedList(
        responses?.data?.virtualPhoneNumberAssignees || [],
        assigneesTypeList,
        userDataList.data?.users || [],
        props.ivrFlowList,
        allDefaultForAccount?.data?.numberDefaultCommunications || [],
        CommunicationType
      );

      const formattedData = virtualNumberList;
      const selectedForCall = formattedData.find((item:any)=> {
        return  item.isDefaultCalling
      })
      setStateData((prev) => {
        return {
          ...prev,
          numberList: formattedData || [],
          loading: false,
          selectedNumber: selectedForCall || {}
        };
      });
    };
    const inputRef: any = useRef(null)
    useEffect(() => {
      if (!props.selectedRecord) {
        getNumbersList();
      }
      fetchCallExtensionList();
      document.addEventListener('keydown', (e) => {
        if (e.key === 'Backspace') {
          handleDeleteKeyPress();
        }
      });
      return () => {
        document.removeEventListener('keydown', handleDeleteKeyPress);
      };
    }, []);

    const checkForConsent = async (contactData: any) => {
      if (!isConsentRequired) {
        setCreateEmailConversationDrawerState((prev) => ({
          ...prev,
          isConsentGiven: true,
        }));
        return;
      }
      const isGiven = isContactConsentGiven(contactData);
      setCreateEmailConversationDrawerState((prev) => ({
        ...prev,
        isConsentGiven: isGiven,
      }));
    };

    return (
      <View>
        <Drawer
          visible={isDrawerVisible}
          width={finalWidth}
          onClose={() => closeModal()}
          mask={isSideCarContext ? false : true}
          title={
            <>
              <ModalActionTitle title={'voiceCall'} titleColor={''}
               buttonList={[
                {
                  ...(isSideCarContext && {
                    show: true,
                    id: 1,
                    btnText: 'back',
                    textColor: Colors.Custom.mainSecondaryBrown,
                    variant: BUTTON_TYPE.SECONDARY,
                    isTransBtn: false,
                    onClick: () => {
                      closeModal()
                    },
                  }),
                },
               ]}
              />
            </>
          }
        >
          <View>
            {stateData.loading ? (
              <Skeleton.Text />
            ) : (
              <>
                <FormControl isRequired>
                  <FormControl.Label style={styles.formLabel}>
                    <DisplayText
                      size={'smMedium'}
                      extraStyles={{color: Colors.Custom.Gray500}}
                      textLocalId={'selectNumber'}
                    />
                  </FormControl.Label>
                  {!props.selectedRecord ? (
                    <NativeSelect
                      placeholder="Please select number for Call"
                      selectedValue={stateData?.selectedNumber?.id}
                      onValueChange={(itemValue) => {
                        const selected = stateData?.numberList?.find(
                          (item: any) => {
                            return item.id === itemValue;
                          }
                        );
                        setStateData((prev) => {
                          return {
                            ...prev,
                            selectedNumber: selected,
                          };
                        });
                      }}
                    >
                      {stateData.numberList.map((number: any, index: any) => {
                        return (
                          <NativeSelect.Item
                            key={index}
                            label={`${number.phoneNumber?.name} (${number.phoneNumber?.phoneNumber})`}
                            value={number?.id}
                          />
                        );
                      })}
                    </NativeSelect>
                  ) : (
                    <VStack>
                      <Text size={'bold'} fontWeight={600}>
                        From Number
                      </Text>
                      <Text size={'bold'}>
                        {selectedRecord?.phoneNumber?.phoneNumber ||
                          selectedRecord?.rowData?.virtualPhoneNumber}
                      </Text>
                    </VStack>
                  )}
                </FormControl>
              </>
            )}

            {!isDialpadOn ? (
              <>
                <HStack pt={5}>
                  <FormControl
                    isInvalid={
                      CreateEmailConversationDrawerState?.errors?.patient
                    }
                  >
                    <FormControl.Label style={styles.formLabel} isRequired>
                      <DisplayText
                        size={'smMedium'}
                        extraStyles={{color: Colors.Custom.Gray500}}
                        textLocalId={'channelPatient'}
                      />
                    </FormControl.Label>
                    {CreateEmailConversationDrawerState.isPatientDataLoading && (
                      <Spinner zIndex={1000} position={'absolute'} left={16} />
                    )}

                    <AutoComplete
                      size="large"
                      style={{fontSize: 12}}
                      clearIcon={false}
                      placeholder="Search Patients"
                      onSearch={debounce(getPatientLists, 300)}
                      //disabled={selectedPatient?.id ? true : false}
                      value={
                        CreateEmailConversationDrawerState.selectedPatient?.name
                      }
                      onSelect={(data: any, options: any) => {
                        setCreateEmailConversationDrawerState((prev) => {
                          return {
                            ...prev,
                            selectedPatient: options?.patientData,
                          };
                        });
                        checkForConsent(options?.patientData);
                      }}
                      notFoundContent={
                        CreateEmailConversationDrawerState.isPatientDataLoading && (
                          <Spin size="small" />
                        )
                      }
                    >
                      {getPatientListOptElem()}
                    </AutoComplete>

                    <FormControl.ErrorMessage
                      _text={{
                        fontSize: 'xs',
                        color: 'error.500',
                        fontWeight: 500,
                      }}
                    >
                      {CreateEmailConversationDrawerState.errors.patient}
                    </FormControl.ErrorMessage>
                  </FormControl>
                </HStack>
                {!CreateEmailConversationDrawerState?.isConsentGiven && (
                  <HStack pl={1}>
                    <Text
                      fontSize={12}
                      color={Colors.Custom.MissedCallIconColor}
                    >
                      {`Please give consent for "${CreateEmailConversationDrawerState?.selectedPatient?.name}" ${PATIENT_CONSENT_ERROR}`}
                    </Text>
                  </HStack>
                )}
                <HStack pt={5} alignSelf={'center'}>
                  <TouchableOpacity
                    onPress={() => setIsDialpadOn(true)}
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <View paddingRight={1}>
                      <DialpadSvg
                        customStrokeColor={Colors.Custom.mainPrimaryPurple}
                        width={18}
                        height={18}
                      />
                    </View>
                    <Text color={'#825AC7'}>Or dial a number</Text>
                  </TouchableOpacity>
                </HStack>
              </>
            ) : (
              <>
                <HStack pt={5}>
                  <Select
                    style={{
                      height: 39,
                      fontSize: 14,
                      lineHeight: 21,
                      fontWeight: 500,
                      width: 110,
                      alignSelf: 'center',
                      alignContent: 'center',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    value={phoneNumberValue.countryCode}
                    onChange={(itemValue) => {
                      setPhoneNumberValue((prev) => {
                        return {
                          ...prev,
                          countryCode: itemValue,
                        };
                      });
                    }}
                  >
                    <Select.Option key={'+1'} value={'+1'}>
                      <HStack>
                        <HStack marginTop={1} paddingRight={1}>
                          <USFlagSVG />
                        </HStack>
                        <HStack> {'+1'}</HStack>
                      </HStack>
                    </Select.Option>
                  </Select>
                  <Input
                    marginLeft={2}
                    ref={inputRef}
                    keyboardType="numeric"
                    width={'77.5%'}
                    fontSize={14}
                    lineHeight={21}
                    fontWeight={500}
                    borderRadius={6}
                    textAlign={'center'}
                    value={formatPhoneNumber(numberValue)}
                    placeholder="Enter number here"
                    onSelectionChange={(e) => {
                      setCursorPosition(e.nativeEvent.selection.start);
                    }}
                    onChangeText={handleNumberChange}
                    rightElement={
                      <TouchableOpacity
                        style={{paddingRight: 5}}
                        onPress={handleDeletePress}
                      >
                        {numberValue.length > 0 && <DialpadDeleteSvg />}
                      </TouchableOpacity>
                    }
                  />
                </HStack>
                <Dialpad
                  onPress={(number: any) =>
                    handleChangeDialpad(number, cursorPosition)
                  }
                />
              </>
            )}

            <HStack pt={5}>
              <FoldButton
                customProps={{
                  btnText: 'Call',
                }}
                nativeProps={{
                  variant: BUTTON_TYPE.PRIMARY,
                  width: '100%',
                  isDisabled: checkCallButtonDisabled(),
                  onPress() {
                    {
                      !isDialpadOn ? handleOnCallAction() : createNewEntry();
                    }
                  },
                  leftIcon: (
                    <VoiceCall
                      customStrokeColor={
                        !checkCallButtonDisabled()
                          ? Colors.Custom.mainPrimaryPurple
                          : Colors.Custom.Gray300
                      }
                    />
                  ),
                }}
              />
            </HStack>
            {isDialpadOn && (
              <HStack pt={5} style={{alignSelf: 'center'}}>
                <TouchableOpacity
                  onPress={() => setIsDialpadOn(false)}
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <View paddingRight={1}>
                    <UserSvg
                      customStrokeColor={Colors.Custom.mainPrimaryPurple}
                      width={18}
                      height={18}
                    />
                  </View>
                  <Text color={'#825AC7'}>Choose a member</Text>
                </TouchableOpacity>
              </HStack>
            )}
          </View>
        </Drawer>
      </View>
    );
};

export default SelectPatientCallDrawer;
