import {View, Text} from 'react-native';
import React, {useContext} from 'react';
import {HStack, Spacer, VStack} from 'native-base';
import {Colors} from '../../../../../styles';
import {CarePlanScreenContext} from '../interfaces';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import Stack from '../../../../common/LayoutComponents/Stack';
import {CommonDataContext} from '../../../../../context/CommonDataContext';

const Header = (props: {
  headerAction: React.ReactNode;
  context: CarePlanScreenContext;
  title: string;
  titleFontSize?: number | string;
  subTitle?: string | JSX.Element;
  subTitleStyle?: any
  prefixAction?: React.ReactNode;
}) => {
  const {headerAction, context, title, subTitle, subTitleStyle} = props;
  const contextData = useContext(CommonDataContext);
  const isSidecar = contextData?.sidecarContext?.isSidecar;
  return (
    <VStack>
      <VStack
        w="100%"
        px={5}
        paddingY={2}
        backgroundColor={isSidecar ? undefined : Colors.Custom.White}
        borderBottomWidth={1}
        borderColor={Colors.Custom.BorderColor}
      >
        <HStack alignItems="center">
          {props.prefixAction}
          <Stack direction="column" style={{
            marginLeft: !!props?.prefixAction ? 10 : undefined
          }}>
            <DisplayText
              textLocalId={title || ''}
              size={'xlBold'}
              extraStyles={{
                fontSize: props?.titleFontSize || 14
              }} 
            />
            {subTitle &&
              (typeof subTitle === 'string' ? (
                <DisplayText
                  extraStyles={{
                    color: Colors.Custom.Gray500,
                    ...subTitleStyle,
                  }}
                  textLocalId={subTitle || ''}
                />
              ) : (
                subTitle
              ))}
          </Stack>
          <Spacer />
          <HStack space={1} alignItems="center">
            {headerAction}
          </HStack>
        </HStack>
      </VStack>
    </VStack>
  );
};

export default Header;
