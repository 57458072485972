import React from 'react';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {Colors} from '../../../../../styles';
import Stack from '../../../../common/LayoutComponents/Stack';
import SelectCarePlanTemplateSvg from '../../../../common/Svg/CarePlanSvgs/SelectCarePlanTemplateSvg';

export const SelectCarePlanTemplateFallback = (props: {title: string; extraView?: JSX.Element}) => {
  return (
    <Stack
      direction="column"
      style={{flex: 1, justifyContent: 'center', alignItems: 'center', marginHorizontal: 24}}
    >
      <SelectCarePlanTemplateSvg />
      <DisplayText
        size="lgNormal"
        textLocalId={props.title}
        extraStyles={{
          color: Colors.Custom.Gray500,
          textAlign: 'center',
          maxWidth: 300,
          fontSize: 16,
          marginTop: 30
        }}
      />
    </Stack>
  );
};
