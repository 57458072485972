import React, {useContext, useEffect, useRef, useState} from 'react';
// import 'antd/dist/antd.css';
import {AntdConfig, BasicConfig} from '@react-awesome-query-builder/antd';
import {useLazyQuery} from '@apollo/client';
import {MLOV_CATEGORY} from '../../../../constants';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {FormsQueries, ScheduleEventQueries, ZipCodeQueries} from '../../../../services';
import EmployerQueries from '../../../../services/Employer/EmployerQueries';
import LabelsQueries from '../../../../services/Labels/LabelsQueries';
import {
  FOLD_MEMBERSHIP_STATUS_LIST,
  getAccountConfigResourceAbilities,
  getBirthSexLabel,
  getEhrConfig,
  getGenderIdentityLabel,
  getPossibleValuesByOperationName,
  getResourceAbilities,
  getSexAtBirthTransformValues,
  getVitalListFromCapability,
} from '../../../../utils/capabilityUtils';
import {
  getAccountId,
  getAccountUUID,
  getBooleanFeatureFlag,
  getEHRCapability,
  getPatientName,
  isLeadGroupsPage,
  isWearableRuleEnabled,
} from '../../../../utils/commonUtils';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../../utils/mlovUtils';
import {ILabel} from '../../../common/FilterView/interfaces';
import {
  IZipCodeData,
  IZipCodeVar,
} from '../../../common/ZipcodeMultiSelect/interfaces';
import QueryBuilder from './QueryBuilder/QueryBuilder';
import {
  getAppointmentTypesForRuleEngine,
  getCodesWithHeaders,
  getCustomEHRAttributes,
  getPractitionersData,
} from '../../../../services/CommonService/AidBoxService';
import {IState, IStateVar} from '../../../common/State/interfaces';
import {CodeSearchType} from '../../../common/CodeSearch/CodeSearchConstants';
import {getRuleEngineCodes} from '../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {getVitalListForRuleEngine} from './utils';
import {FHIR_RESOURCE, KEY_ALLOWED_OPERATIONS} from '../../../../constants/FhirConstant';
import {getCodeGroups} from '../../Contacts/CodeGroups/CodeGroupApi';
import {CodeGroupCategory, SUPPORTED_WEARABLES} from './constants';
import {mapGenderDisplayCode} from '../../Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import {CapabilityResource} from '../../Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import {getLeadSourceList} from './QueryBuilder/QueryBuilderHelper';
import ContactsQueries from '../../../../services/Contacts/ContactsQueries';
import {CONTACT_TYPE_CODES} from '../../TeamInbox/Conversations/ConversationContainer/ConversationConst';
import { getAccountConfigValue } from '../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../constants/AccountConfigConst';
import {getLocationsBySearchString} from '../../../../services/Team/TeamQueries';
import useGetBatchedAccountUsers from '../../../CustomHooks/useGetBatchedAccountUsers';
import UserQueries, { GET_ACTIVE_AND_INACTIVE_USERS_FOR_CALENDAR, GET_ACCOUNT_ACTIVE_AND_INACTIVE_USER_COUNT } from '../../../../services/User/UserQueries';
import { EHRWiseLocations, IUser } from '../../../../Interfaces';
import { MLOV_CODES } from '../../../../constants/MlovConst';
import { formatCustomEhrFieldsList, getCustomEHRAttributesFromPatientData } from '../../Contacts/Leads/LeadView/AddOrUpdateLead/components/CustomEHRAttributes/helper';
import { IEhrFieldList, IEhrFieldOption, IEhrFieldType } from '../../Contacts/Leads/LeadView/AddOrUpdateLead/components/CustomEHRAttributes/interface';
import { Spinner } from 'native-base';
import FeatureFlags from '../../../../constants/FeatureFlags.enums';

const InitialConfig = AntdConfig;

const config: BasicConfig = {
  ...InitialConfig,
  fields: {},
};

config.conjunctions.AND.label = 'All';
config.conjunctions.OR.label = 'Any';
config.settings.showNot = false;
config.settings.setOpOnChangeField = ['none'];
config.settings.showErrorMessage = true;

interface IQueryField {
  id: string;
  value: string;
}
interface IQueryBuilderProps {
  jsonTree: any;
  handleJSONTreeStateChange: (displayJSONTree: any) => void;
  isPatientGroup?: boolean;
  selectedAccountLocations?: EHRWiseLocations[];
}

const RuleEngineInputField1 = (props: IQueryBuilderProps) => {
  const context = useContext(CommonDataContext);
  const {handleJSONTreeStateChange, jsonTree, isPatientGroup, selectedAccountLocations} = props;
  const accountLocationRef = useRef(selectedAccountLocations);
  const commonData = useContext(CommonDataContext);
  const isMultiTenancyEnabled = getBooleanFeatureFlag(commonData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const accountId = getAccountId();
  const ehrConfig = getEhrConfig('', '');
  const isAthena = ehrConfig.isAthena;
  const hintCapability = getAccountConfigResourceAbilities(FHIR_RESOURCE.HINT);
  const openInHint = hintCapability?.openInHint || false;
  const accountUuid = getAccountUUID();
  const isWearableFactEnabled = isWearableRuleEnabled(commonData.userSettings);
  let careTeamListUsers = [] as IUser[];
  const {
    loading: batchedUserLoading,
    error: batchedUserError,
    userList,
    getData,
  } = useGetBatchedAccountUsers({
    doNotFetchOnMount: true,
    usersQueryName: GET_ACTIVE_AND_INACTIVE_USERS_FOR_CALENDAR,
    usersCountQueryName: GET_ACCOUNT_ACTIVE_AND_INACTIVE_USER_COUNT
  });
  const [loadingEHRAttributes, setLoadingEHRAttributes] = useState<boolean>(false);

  // vitals
  const vitalList = getVitalListFromCapability('', '', true).filter(
    (item) => !item.isHidden
  );
  const vitals = getVitalListForRuleEngine(vitalList);

  // Sex at birth
  const genderAtBirthLabel = getBirthSexLabel('', '');
  const genderAtBirthArray = getPossibleValuesByOperationName(
    KEY_ALLOWED_OPERATIONS.BIRTH_SEX,
    '',
    ''
  );
  // Gender Identity
  const genderIdentityLabel = getGenderIdentityLabel('', '', true);
  const patientCapability = getResourceAbilities(
    CapabilityResource.patient,
    '',
    '',
    true,
  );
  const isGenderIdentityHidden =
    patientCapability?.keyAllowedOperations?.gender?.isHidden || false;
  let personGender = getMlovListFromCategory(
    context.MLOV,
    MLOV_CATEGORY.PERSON_GENDER
  );
  const genderTransformValues = getSexAtBirthTransformValues(
    KEY_ALLOWED_OPERATIONS.GENDER,
    '',
    '',
    true,
  );
  personGender = mapGenderDisplayCode(personGender, genderTransformValues);

  const [getUsers] = useLazyQuery( UserQueries.GET_USERS_BY_RESOURCE_CODES, {
    onError: (error) => {
      console.log(error);
    },
  });

  // Employer
  const [searchEmployer] = useLazyQuery(EmployerQueries.employersSearch, {
    fetchPolicy: 'no-cache',
  });

  // Contact Type
  const [getContactTypes] = useLazyQuery(
    ContactsQueries.GetAllCustomContactTypes,
    {
      fetchPolicy: 'no-cache',
    }
  );

  // Zipcode
  const [getZipCodes] = useLazyQuery<{zipcodes: IZipCodeData[]}, IZipCodeVar>(
    ZipCodeQueries.getZipCodeBySearchString
  );

  // Tags
  const labelTypeList = commonData.MLOV[MLOV_CATEGORY.LABEL_TYPE];
  const labelTypeId = getMlovIdFromCode(labelTypeList, 'CONTACT');
  const [getLabelDataList] = useLazyQuery<{labels: ILabel[]}>(
    LabelsQueries.GetAllLabelsByFilter
  );

  const isOnLeadGroup = isLeadGroupsPage() && !isPatientGroup;
  const ruleEngineType = isOnLeadGroup ? '' : CONTACT_TYPE_CODES.PATIENT

  // Patient state
  const [getStates] = useLazyQuery<{states: IState[]}, IStateVar>(
    ZipCodeQueries.getStatesBySearchString
  );

  // Forms
  const [getForms] = useLazyQuery(FormsQueries.GET_ALL_FORMS_BY_LOCATION);

  // Rad order
  let radCodes: {code: string; description: string}[] = [];
  const orderData = getAccountConfigValue(CONFIG_CODES.ALLOWED_IMAGING_CPT_DATA);
  if (orderData) {
    radCodes = JSON.parse(orderData);
  }

  // locations
  const [getLocations] = useLazyQuery(getLocationsBySearchString)
  const handleCareTeamSearch = async (search: string) => {
    const values: any = [];
    let users: IUser[] = careTeamListUsers;

    if (!users?.length) {
      const response = await getUsers({
        variables: {
          params: {
            accountLocationIds: accountLocationRef.current?.map((location) => location.uuid),
            orderBy: { name: 'asc' }
          }
        },
      });
      users = response?.data?.searchUsers?.users || [];
      careTeamListUsers = users;
    }

    users?.forEach((user: IUser) => {
      if (!search || user?.name?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase())) {
        values.push({
          title: user?.accountUsers?.[0]?.isActive === false ? user.name + '(Inactive)' : user.name,
          value: user.uuid,
        });
      }
    });

    return {
      values: values,
      hasMore: true,
    };
  }

  //Family History
  const capabilities = getEHRCapability(context.ehrCapabilities, [CapabilityResource.familyHistory]);
  const relationshipList = capabilities?.abilities?.keyAllowedOperations?.relationship?.possibleValues || [];

  const deleteConfigFields = (key: string) => {
    if (config.fields[key]) {
      delete config.fields[key];
    }
  }

  const uniquePossibleValues = (possibleValues: any[]) => {
    const values: any = [];
    const seenCodes = new Set();
    possibleValues?.forEach((option) => {
      if (!seenCodes.has(option.code)) {
        values.push({
          title: option.display,
          value: option.code,
        });
        seenCodes.add(option.code);
      }
    });
    return values;
  };


  const mapEHRFieldsToSubfields = (fieldList: IEhrFieldList[]) => {
    const subfields: any = {};
    fieldList.forEach((field: IEhrFieldList, index: number) => {
      let fieldConfig:any = {
        label: field.label,
        type: field.type,
        valueSources: ['value'],
      };
      switch (field.type) {
        case IEhrFieldType.TEXT:
          fieldConfig = {
            ...fieldConfig,
            operators: [
              'equal',
              'not_equal',
              'like',
              'not_like',
            ],
          };
          break;
        case IEhrFieldType.CHOICE:
          fieldConfig = {
            ...fieldConfig,
            type: 'multiselect',
            operators: [
              'multiselect_equals',
              'multiselect_not_equals',
              'multiselect_contains',
              'multiselect_not_contains'
            ],
            fieldSettings: {
              listValues: uniquePossibleValues(field.possibleValues),
            },
          };
          break;
        case IEhrFieldType.DATE:
          fieldConfig = {
            ...fieldConfig,
            operators: [
              'greater',
              'greater_or_equal',
              'less',
              'less_or_equal',
              'between',
            ],
          };
          break;
        case IEhrFieldType.NUMBER:
          fieldConfig = {
            ...fieldConfig,
            operators: [
              'equal',
              'not_equal',
              'greater',
              'greater_or_equal',
              'less',
              'less_or_equal',
              'between',
            ],
          };
          break;
        default:
          break;
      }

      subfields[field.key] = fieldConfig;
    });
    return subfields;
  };

  const loadEHRAttributes = async () => {
    const ehrAttributeFieldResponseList = getCustomEHRAttributesFromPatientData([]);
    setLoadingEHRAttributes(true);
    getCustomEHRAttributes(
      (res: any) => {
        const formmattedList = formatCustomEhrFieldsList(
          res.data?.expansion?.contains,
          ehrAttributeFieldResponseList
        );
        const subfields = mapEHRFieldsToSubfields(formmattedList);
        if (!isOnLeadGroup) {
          config.fields.customEHRAttributes = {
            label: 'Custom EHR Attributes',
            type: '!group',
            conjunctions: ['AND'],
            showNot: false,
            subfields: subfields
          };
        }
        else {
          deleteConfigFields('customEHRAttributes');
        }
        setLoadingEHRAttributes(false);
      },
      (err: any) => {
        setLoadingEHRAttributes(false);
      }
    );
  };

  useEffect(() => {
    if (isAthena) {
      loadEHRAttributes();
    }
  }, []);

  useEffect(() => {
    accountLocationRef.current = selectedAccountLocations;
  }, [selectedAccountLocations]);

  // Employer Field
  if (!isOnLeadGroup) {
    config.fields.employerId = {
      label: 'Employer',
      type: 'multiselect',
      valueSources: ['value'],
      fieldSettings: {
        useAsyncSearch: true,
        allowCustomValues: false,
        asyncFetch: async (search: any, offset: any) => {
          const employerList = await searchEmployer({
            variables: {
              accountUuid: accountUuid,
              query: `%${search || ''}%`,
            },
          });
          const values: any = [];
          employerList?.data?.employers?.forEach((employer: any) => {
            values.push({
              title: employer.name,
              value: employer.id,
            });
          });
          return {
            values: values,
            hasMore: true,
          };
        },
      },
    };
  } else {
    deleteConfigFields('employerId');
  }
  // Sex at birth Field
  if (!isOnLeadGroup) {
    config.fields.sexAtBirth = {
      label: genderAtBirthLabel || 'Gender',
      type: 'select',
      valueSources: ['value'],
      operators: ['select_equals', 'select_not_equals'],
      fieldSettings: {
        listValues: genderAtBirthArray.map((item) => {
          return {
            value: item.code,
            title: item.display,
          };
        }),
      },
    };
  } else {
    deleteConfigFields('sexAtBirth');
  }
  if(isOnLeadGroup){
    config.fields.contactTypeId = {
      label: 'Contact Type',
      type: 'multiselect',
      operators: ['multiselect_contains'],
      valueSources: ['value'],
      fieldSettings: {
        useAsyncSearch: true,
        allowCustomValues: false,
        asyncFetch: async (search: any, offset: any) => {
          const contactTypeList = await getContactTypes({
            variables: {
              accountUuid: accountUuid,
            },
          });
          const values: any = [];
          contactTypeList?.data?.customContactTypes?.forEach(
            (customContactType: any) => {
              values.push({
                title: customContactType.value,
                value: customContactType.id,
              });
            }
          );
          return {
            values: values,
            hasMore: true,
          };
        },
      },
    };
  } else {
    deleteConfigFields('contactTypeId');
  }
  // Patient gender identity
  if (!isGenderIdentityHidden) {
    config.fields.genderIdentity = {
      label: genderIdentityLabel,
      type: 'select',
      valueSources: ['value'],
      operators: ['select_equals', 'select_not_equals'],
      fieldSettings: {
        listValues: personGender.map((item) => {
          return {
            value: item.code,
            title: item.value,
          };
        }),
      },
    };
  } else {
    deleteConfigFields('genderIdentity');
  }
  // Zip Code Field
  config.fields.hasZipInGivenList = {
    label: 'Zip Code',
    type: 'multiselect',
    operators: ['multiselect_contains', 'multiselect_not_contains'],
    valueSources: ['value'],
    fieldSettings: {
      useAsyncSearch: true,
      allowCustomValues: false,
      asyncFetch: async (search: any, offset: any) => {
        const zipcodes = await getZipCodes({
          variables: {
            searchString: `${search || ''}%`,
            limit: 50,
          },
        });
        const values: any = [];
        zipcodes?.data?.zipcodes?.forEach((zipcode: any) => {
          values.push({
            title: zipcode.code,
            value: zipcode.code,
          });
        });
        return {
          values: values,
          hasMore: true,
        };
      },
    },
  };
  // Patient Age Field
  config.fields.age = {
    label: 'Age',
    operators: [
      'greater_or_equal',
      'less_or_equal',
      'less',
      'greater',
      'between',
    ],
    type: 'number',
    valueSources: ['value'],
    fieldSettings: {
      min: 0,
    },
    mainWidgetProps: {
      valuePlaceholder: 'Enter Age',
      valueLabels: ['From', 'To'],
      customProps: {
        addonAfter: 'years',
      },
    },
  };
  // Patient Tag Field
  config.fields.hasTags = {
    label: 'Tag',
    type: 'multiselect',
    operators: ['multiselect_contains', 'multiselect_not_contains', 'multiselect_equals', 'multiselect_not_equals'],
    valueSources: ['value'],
    fieldSettings: {
      useAsyncSearch: true,
      allowCustomValues: false,
      asyncFetch: async (search: any, offset: any) => {
        const labelList = await getLabelDataList({
          variables: {
            labelTypeId: labelTypeId,
            search: search ? `%${search || ''}%` : '%%',
            accountId: accountId,
          },
        });
        const values: any = [];
        labelList?.data?.labels?.forEach((label: any) => {
          values.push({
            title: label.title,
            value: label.title,
          });
        });
        return {
          values: values,
          hasMore: true,
        };
      },
    },
  };

  // care team field
  if (!isOnLeadGroup) {
    config.fields.hasCareTeam = {
      label: 'Care Team',
      type: 'multiselect',
      operators: ['multiselect_contains', 'multiselect_not_contains', 'multiselect_equals', 'multiselect_not_equals'],
      valueSources: ['value'],
      fieldSettings: {
        useAsyncSearch: true,
        allowCustomValues: false,
        asyncFetch: async (search: any, offset: any) => {
          const result = await handleCareTeamSearch(search);
          return result;
        }
      },
    };
  }

  if (!isMultiTenancyEnabled) {
    config.fields.hasLocation = {
      label: 'Practice Location',
      type: 'multiselect',
      operators: ['multiselect_contains', 'multiselect_not_contains'],
      valueSources: ['value'],
      fieldSettings: {
        useAsyncSearch: true,
        allowCustomValues: false,
        asyncFetch: async (search: any, offset: any) => {
          const locationList = await getLocations({
            variables: {
              searchString: `%${search || ''}%`,
            },
            fetchPolicy: 'no-cache',
          });
          const values: any = [];
          locationList?.data?.accountLocations?.forEach((location: any) => {
            values.push({
              title: location?.practiceLocation?.name,
              value: location?.practiceLocation?.uuid
            });
          });
          return {
            values: values,
            hasMore: true
          }
        },
      },
    };
  }

  if (!ehrConfig.isFold && !isOnLeadGroup) {
    config.fields.practitioner = {
      label: 'Practitioner',
      type: 'multiselect',
      valueSources: ['value'],
      operators: ['multiselect_contains', 'multiselect_not_contains'],
      mainWidgetProps: {
        customProps: {
          filterOption: (input: string, option: any) => {
            return (option?.children || '')
              .toLowerCase()
              .includes(input.toLowerCase());
          },
        },
      },
      fieldSettings: {
        useAsyncSearch: false,
        allowCustomValues: false,
        asyncFetch: async (search: any, offset: any) => {
          const locationGroupIds = accountLocationRef?.current?.map(location => location.locationGroupId)?.filter((locationGroupId): locationGroupId is string => !!locationGroupId);
          const practitioner = await getPractitionersData(undefined, undefined, locationGroupIds);
          const values: any = [];
          practitioner?.data?.entry?.forEach((item: any) => {
            const name = getPatientName(item?.resource);
            values.push({
              title: name,
              value: item?.resource?.id,
            });
          });
          return {
            values: values,
            hasMore: true,
          };
        },
      },
    };
  } else {
    deleteConfigFields('practitioner');
  }

  if(isOnLeadGroup){
    config.fields.source = {
      label: 'Source',
      type: 'multiselect',
      valueSources: ['value'],
      fieldSettings: {
        listValues: getLeadSourceList(ruleEngineType),
      },
    };
  } else {
    deleteConfigFields('source');
  }

  if (!isOnLeadGroup) {
    config.fields.state = {
      label: 'State',
      type: 'multiselect',
      valueSources: ['value'],
      operators: ['multiselect_contains', 'multiselect_not_contains'],
      fieldSettings: {
        useAsyncSearch: true,
        allowCustomValues: false,
        asyncFetch: async (search: any, offset: any) => {
          const practitioner = await getStates({
            variables: {
              searchString: `%${search || ''}%`,
              limit: 50,
            },
            fetchPolicy: 'no-cache',
          });
          const values: any = [];
          practitioner?.data?.states?.forEach((item) => {
            values.push({
              title: item.name,
              value: item.abbr,
            });
          });
          return {
            values: values,
            hasMore: true,
          };
        },
      },
    };
  } else {
    deleteConfigFields('state');
  }



  if (!isOnLeadGroup) {
    config.fields.form = {
      label: 'Form',
      type: '!group',
      conjunctions: ['AND'],
      showNot: false,
      subfields: {
        name: {
          type: 'select',
          label: 'Form Name',
          operators: ['select_equals'],
          valueSources: ['value'],
          mainWidgetProps: {
            valuePlaceholder: 'Search Form',
          },
          fieldSettings: {
            useAsyncSearch: true,
            allowCustomValues: false,
            asyncFetch: async (search: any, offset: any) => {
              const response = await getForms({
                variables: {
                  searchString: `%${search || ''}%`,
                  formLocationIds: accountLocationRef?.current?.map(location => location.uuid),
                  limit: 20,
                  offset: 0,
                },
                fetchPolicy: 'no-cache',
                context: {
                  service: CARESTUDIO_APOLLO_CONTEXT,
                },
              });
              const values: any = [];
              response?.data?.forms?.forEach((item: any) => {
                values.push({
                  title: item.name,
                  value: item.id,
                });
              });
              return {
                values: values,
                hasMore: true,
              };
            },
          },
        },
        submitted: {
          type: 'number',
          label: 'Submitted',
          operators: ['greater'],
          valueSources: ['value'],
          mainWidgetProps: {
            customProps: {
              addonAfter: 'days',
            },
          },
        },
        notSubmitted: {
          type: 'number',
          label: 'Not Submitted',
          valueSources: ['value'],
          operators: ['greater'],
          mainWidgetProps: {
            customProps: {
              addonAfter: 'days',
            },
          },
        },
        score: {
          type: 'number',
          label: 'Form Score',
          operators: [
            'equal',
            'greater',
            'greater_or_equal',
            'less',
            'less_or_equal',
            'between',
          ],
          valueSources: ['value'],
        },
      },
    };
  } else {
    deleteConfigFields('form');
  }

  if (!isOnLeadGroup) {
    config.fields.hasAppointment = {
      label: 'Appointment',
      type: '!group',
      conjunctions: ['AND'],
      showNot: false,
      subfields: {
        scheduledInPast: {
          type: 'number',
          label: 'Scheduled in past',
          operators: ['greater', 'between'],
          valueSources: ['value'],
          mainWidgetProps: {
            customProps: {
              addonAfter: 'days',
            },
          },
        },
        notScheduledInPast: {
          type: 'number',
          label: 'Not scheduled in past',
          operators: ['greater', 'between'],
          valueSources: ['value'],
          mainWidgetProps: {
            customProps: {
              addonAfter: 'days',
            },
          },
        },
        scheduledInFuture: {
          type: 'number',
          label: 'Scheduled in future',
          operators: ['less', 'between'],
          valueSources: ['value'],
          mainWidgetProps: {
            customProps: {
              addonAfter: 'days',
            },
          },
        },
        notScheduledInFuture: {
          type: 'number',
          label: 'Not scheduled in future',
          operators: ['less', 'between'],
          valueSources: ['value'],
          mainWidgetProps: {
            customProps: {
              addonAfter: 'days',
            },
          },
        },
        appointmentType: {
          type: 'multiselect',
          label: 'Appointment type',
          operators: ['multiselect_contains'],
          valueSources: ['value'],
          mainWidgetProps: {
            valuePlaceholder: 'Search appointment type',
          },
          fieldSettings: {
            useAsyncSearch: true,
            allowCustomValues: false,
            asyncFetch: async (search: any, offset: any) => {
              const appointmentTypesList = await getAppointmentTypesForRuleEngine(accountLocationRef?.current?.map(location => location.uuid));
              const values: any = [];
              const seenCodes = new Set();
              appointmentTypesList?.data?.forEach((appointmentType: any) => {
                if (
                  (!search ||
                    appointmentType?.display
                      ?.toLocaleLowerCase()
                      ?.includes(search?.toLocaleLowerCase())) &&
                  !seenCodes.has(appointmentType.code)
                ) {
                  values.push({
                    title: appointmentType?.display,
                    value: appointmentType.code,
                  });
                  seenCodes.add(appointmentType.code);
                }
              });
              return {
                values: values,
                hasMore: true,
              };
            },
          },
        },
        appointmentStatus: {
          type: 'multiselect',
          label: 'Appointment Status',
          operators: ['multiselect_contains'],
          valueSources: ['value'],
          mainWidgetProps: {
            valuePlaceholder: 'Search appointment status',
          },
          fieldSettings: {
            useAsyncSearch: true,
            allowCustomValues: false,
            asyncFetch: async (search: any, offset: any) => {
              const appointmentStatusList = await getRuleEngineCodes(CodeSearchType.appointmentStatus);
              const values: any = [];
              const seenCodes = new Set();
              appointmentStatusList?.data?.expansion?.contains.forEach((status: any) => {
                if (
                  (!search ||
                    status?.display
                      ?.toLocaleLowerCase()
                      ?.includes(search?.toLocaleLowerCase())) &&
                  !seenCodes.has(status.code)
                ) {
                  values.push({
                    title: status?.display,
                    value: status.code,
                  });
                  seenCodes.add(status.code);
                }
              });
              return {
                values: values,
                hasMore: true,
              };
            },
          },
        }
      },
    };
  } else {
    deleteConfigFields('hasAppointment');
  }

  if (!isOnLeadGroup) {
    config.fields.hasLastVitalValue = {
      label: 'Vital',
      type: '!group',
      conjunctions: ['AND'],
      showNot: false,
      subfields: {
        name: {
          type: 'select',
          label: 'Vital name',
          operators: ['select_equals'],
          valueSources: ['value'],
          mainWidgetProps: {
            valuePlaceholder: 'Select Vital',
          },
          fieldSettings: {
            listValues: vitals.map((vital) => ({
              value: vital.loinc,
              title: vital.unit
                ? `${vital.foldDisplay} (${vital.unit})`
                : vital.foldDisplay,
            })),
          },
        },
        value: {
          type: 'number',
          label: 'Last value',
          operators: [
            'equal',
            'greater',
            'greater_or_equal',
            'less',
            'less_or_equal',
          ],
          valueSources: ['value'],
        },
      },
    };
  } else {
    deleteConfigFields('hasLastVitalValue');
  }

  if (!isOnLeadGroup) {
    config.fields.hasCondition = {
      label: 'Problem',
      type: '!group',
      conjunctions: ['AND'],
      showNot: false,
      subfields: {
        name: {
          type: 'multiselect',
          label: 'Problem Code Group',
          operators: ['multiselect_equals', 'multiselect_not_equals'],
          valueSources: ['value'],
          mainWidgetProps: {
            valuePlaceholder: 'Search code group',
          },
          fieldSettings: {
            useAsyncSearch: true,
            allowCustomValues: false,
            asyncFetch: async (search: any, offset: any) => {
              const response = await getCodeGroups({
                searchString: search || '',
                limit: 10,
                offset: 0,
                category: CodeGroupCategory.problem,
              });
              const values: any = [];
              response?.data?.codeGroups?.forEach((item: any) => {
                values.push({
                  title: item.name,
                  value: item.name,
                });
              });
              return {
                values: values,
                hasMore: true,
              };
            },
          },
        },
        onset: {
          type: 'number',
          label: 'Onset',
          defaultOperator: 'greater',
          operators: [
            'equal',
            'not_equal',
            'greater',
            'greater_or_equal',
          ],
          valueSources: ['value'],
          mainWidgetProps: {
            customProps: {
              addonAfter: 'days',
            },
          },
        },
        status: {
          type: 'select',
          label: 'Status',
          valueSources: ['value'],
          operators: [
            'select_equals',
          ],
          mainWidgetProps: {
            customProps: {
              filterOption: (input: string, option: any) => {
                return (option?.children || '')
                  .toLowerCase()
                  .includes(input.toLowerCase());
              },
              showSearch: false,
            },
          },
          fieldSettings: {
            useAsyncSearch: false,
            allowCustomValues: false,
            asyncFetch: async (search: any, offset: any) => {
              const codes = await getCodesWithHeaders(
                CodeSearchType.conditionStatus,
                false,
                {},
                undefined,
                accountLocationRef?.current?.map(location => location.uuid),
              );
              const values: any = [];
              codes?.data?.expansion?.contains?.forEach((item: any) => {
                values.push({
                  title: item.display,
                  value: item.code,
                });
              });
              return {
                values: values,
                hasMore: true,
              };
            },
          },
        }
      },
    };
  } else {
    deleteConfigFields('hasCondition');
  }

  if (!isOnLeadGroup) {
    config.fields.hasFamilyHistory = {
      label: 'Family History',
      type: '!group',
      conjunctions: ['AND'],
      showNot: false,
      subfields: {
        relationship: {
          type: 'multiselect',
          label: 'Family Member',
          operators: ['multiselect_equals'],
          valueSources: ['value'],
          mainWidgetProps: {
            valuePlaceholder: 'Search family member',
          },
          fieldSettings: {
            listValues: [
              { title: 'Any', value: 'any' },
              ...uniquePossibleValues(relationshipList)
            ],
          },
        },
        condition: {
          type: 'multiselect',
          label: 'Condition',
          operators: [
            'multiselect_equals',
            'multiselect_not_equals',
          ],
          valueSources: ['value'],
          mainWidgetProps: {
            valuePlaceholder: 'Search condition',
          },
          fieldSettings: {
            useAsyncSearch: true,
            allowCustomValues: false,
            asyncFetch: async (search: any, offset: any) => {
              const response = await getCodeGroups({
                searchString: search || '',
                limit: 10,
                offset: 0,
                category: CodeGroupCategory.problem,
              });
              const values: any = [];
              response?.data?.codeGroups?.forEach((item: any) => {
                values.push({
                  title: item.name,
                  value: item.name,
                });
              });
              return {
                values: values,
                hasMore: true,
              };
            },
          },
        },
        onsetAge: {
          type: 'number',
          label: 'Onset Age',
          operators: [
            'greater_or_equal',
            'less_or_equal',
            'less',
            'greater',
            'between',
          ],
          valueSources: ['value'],
          fieldSettings: {
            min: 0,
          },
          mainWidgetProps: {
            valuePlaceholder: 'Enter Age',
            valueLabels: ['From', 'To'],
            customProps: {
              addonAfter: 'years',
            },
          },
        }
      },
    };
  } else {
    deleteConfigFields('hasFamilyHistory');
  }

  if (!isOnLeadGroup) {
    config.fields.hasDiagnosis = {
      label: 'Diagnosis',
      type: '!group',
      conjunctions: ['AND'],
      showNot: false,
      subfields: {
        name: {
          type: 'multiselect',
          label: 'Diagnosis Code Group',
          operators: ['multiselect_equals', 'multiselect_not_equals'],
          valueSources: ['value'],
          mainWidgetProps: {
            valuePlaceholder: 'Search code group',
          },
          fieldSettings: {
            useAsyncSearch: true,
            allowCustomValues: false,
            asyncFetch: async (search: any, offset: any) => {
              const response = await getCodeGroups({
                searchString: search || '',
                limit: 10,
                offset: 0,
                category: CodeGroupCategory.diagnosis,
              });
              const values: any = [];
              response?.data?.codeGroups?.forEach((item: any) => {
                values.push({
                  title: item.name,
                  value: item.name,
                });
              });
              return {
                values: values,
                hasMore: true,
              };
            },
          },
        },
        onset: {
          type: 'number',
          label: 'Onset',
          defaultOperator: 'greater',
          operators: [
            'equal',
            'not_equal',
            'greater',
            'greater_or_equal',
          ],
          valueSources: ['value'],
          mainWidgetProps: {
            customProps: {
              addonAfter: 'days',
            },
          },
        },
      },
    };
  } else {
    deleteConfigFields('hasDiagnosis');
  }

  if (!isOnLeadGroup) {
    config.fields.onMedication = {
      label: 'Medication Orders',
      type: '!group',
      conjunctions: ['AND'],
      showNot: false,
      subfields: {
        name: {
          type: 'multiselect',
          label: 'Medication Code Group',
          operators: ['multiselect_equals', 'multiselect_not_equals'],
          valueSources: ['value'],
          mainWidgetProps: {
            valuePlaceholder: 'Search code group',
          },
          fieldSettings: {
            useAsyncSearch: true,
            allowCustomValues: false,
            asyncFetch: async (search: any, offset: any) => {
              const response = await getCodeGroups({
                searchString: search || '',
                limit: 10,
                offset: 0,
                category: CodeGroupCategory.medication,
              });
              const values: any = [];
              response?.data?.codeGroups?.forEach((item: any) => {
                values.push({
                  title: item.name,
                  value: item.name,
                });
              });
              return {
                values: values,
                hasMore: true,
              };
            },
          },
        },
        onset: {
          type: 'number',
          label: 'Onset',
          defaultOperator: 'greater',
          operators: [
            'equal',
            'not_equal',
            'greater',
            'greater_or_equal',
          ],
          valueSources: ['value'],
          mainWidgetProps: {
            customProps: {
              addonAfter: 'days',
            },
          },
        },
      },
    };
  } else {
    deleteConfigFields('onMedication');
  }

  if (!isOnLeadGroup) {
    config.fields.hasLabResult = {
      label: 'Lab Result',
      type: '!group',
      conjunctions: ['AND'],
      showNot: false,
      subfields: {
        name: {
          type: 'select',
          label: 'Lab Analyte',
          operators: ['select_equals'],
          valueSources: ['value'],
          mainWidgetProps: {
            valuePlaceholder: 'Search analyte',
          },
          fieldSettings: {
            useAsyncSearch: true,
            allowCustomValues: false,
            asyncFetch: async (search: any, offset: any) => {
              const locationIds = accountLocationRef?.current?.map(location => location.uuid);
              const codes = await getCodesWithHeaders(
                CodeSearchType.analyte,
                false,
                {},
                search || '',
                locationIds,
              );
              const values: any = [];
              codes?.data?.expansion?.contains?.forEach((item: any) => {
                values.push({
                  title: item.display,
                  value: item.code,
                });
              });
              return {
                values: values,
                hasMore: true,
              };
            },
          },
        },
        value: {
          type: 'number',
          label: 'Last value',
          operators: [
            'equal',
            'greater',
            'greater_or_equal',
            'less',
            'less_or_equal',
          ],
          valueSources: ['value'],
        },
        // --- kept for future reference ---
        // onset: {
        //   type: 'number',
        //   label: 'Onset',
        //   defaultOperator: 'greater',
        //   operators: [
        //     'equal',
        //     'not_equal',
        //     'greater',
        //     'greater_or_equal',
        //   ],
        //   valueSources: ['value'],
        //   mainWidgetProps: {
        //     customProps: {
        //       addonAfter: 'days',
        //     },
        //   },
        // },
      },
    };
  } else {
    deleteConfigFields('hasLabResult');
  }

  if (!isOnLeadGroup) {
    config.fields.hasProcedure = {
      label: 'Procedure',
      type: '!group',
      conjunctions: ['AND'],
      showNot: false,
      subfields: {
        name: {
          type: 'multiselect',
          label: 'Procedure Code Group',
          operators: ['multiselect_equals', 'multiselect_not_equals'],
          valueSources: ['value'],
          mainWidgetProps: {
            valuePlaceholder: 'Search code group',
          },
          fieldSettings: {
            useAsyncSearch: true,
            allowCustomValues: false,
            asyncFetch: async (search: any, offset: any) => {
              const response = await getCodeGroups({
                searchString: search || '',
                limit: 10,
                offset: 0,
                category: CodeGroupCategory.procedure,
              });
              const values: any = [];
              response?.data?.codeGroups?.forEach((item: any) => {
                values.push({
                  title: item.name,
                  value: item.name,
                });
              });
              return {
                values: values,
                hasMore: true,
              };
            },
          },
        },
        onset: {
          type: 'number',
          label: 'Performed',
          defaultOperator: 'greater',
          operators: [
            'equal',
            'not_equal',
            'greater',
            'greater_or_equal',
          ],
          valueSources: ['value'],
          mainWidgetProps: {
            customProps: {
              addonAfter: 'days',
            },
          },
        },
      },
    };
  } else {
    deleteConfigFields('hasProcedure');
  }

  if (!isOnLeadGroup) {
    config.fields.engagementOfCare = {
      label: 'Patient Engagement',
      type: '!group',
      conjunctions: ['AND'],
      showNot: false,
      subfields: {
        engagedInPast: {
          type: 'number',
          label: 'Patient engaged in last',
          operators: ['greater'],
          valueSources: ['value'],
          mainWidgetProps: {
            customProps: {
              addonAfter: 'days',
            },
          },
        },
        notEngagedInPast: {
          type: 'number',
          label: 'Patient not engaged in last',
          operators: ['greater'],
          valueSources: ['value'],
          mainWidgetProps: {
            customProps: {
              addonAfter: 'days',
            },
          },
        },
      },
    };
  } else {
    deleteConfigFields('engagementOfCare');
  }

  if (!isOnLeadGroup) {
    config.fields.hasImmunization = {
      label: 'Immunization',
      type: '!group',
      conjunctions: ['AND'],
      showNot: false,
      subfields: {
        name: {
          type: 'multiselect',
          label: 'Immunization Code Group',
          operators: ['multiselect_equals', 'multiselect_not_equals'],
          valueSources: ['value'],
          mainWidgetProps: {
            valuePlaceholder: 'Search code group',
          },
          fieldSettings: {
            useAsyncSearch: true,
            allowCustomValues: false,
            asyncFetch: async (search: any, offset: any) => {
              const response = await getCodeGroups({
                searchString: search || '',
                limit: 10,
                offset: 0,
                category: CodeGroupCategory.immunization,
              });
              const values: any = [];
              response?.data?.codeGroups?.forEach((item: any) => {
                values.push({
                  title: item.name,
                  value: item.name,
                });
              });
              return {
                values: values,
                hasMore: true,
              };
            },
          },
        },
        onset: {
          type: 'number',
          label: 'Administered',
          defaultOperator: 'greater',
          operators: [
            'equal',
            'not_equal',
            'greater',
            'greater_or_equal',
          ],
          valueSources: ['value'],
          mainWidgetProps: {
            customProps: {
              addonAfter: 'days',
            },
          },
        },
      },
    };
  } else {
    deleteConfigFields('hasImmunization');
  }
  if (!isOnLeadGroup) {
    config.fields.hasMedicationStatement = {
      label: 'Historical Medications',
      type: '!group',
      conjunctions: ['AND'],
      showNot: false,
      subfields: {
        name: {
          type: 'multiselect',
          label: 'Medication Code Group',
          operators: ['multiselect_equals', 'multiselect_not_equals'],
          valueSources: ['value'],
          mainWidgetProps: {
            valuePlaceholder: 'Search code group',
          },
          fieldSettings: {
            useAsyncSearch: true,
            allowCustomValues: false,
            asyncFetch: async (search: any, offset: any) => {
              const response = await getCodeGroups({
                searchString: search || '',
                limit: 10,
                offset: 0,
                category: CodeGroupCategory.medication,
              });
              const values: any = [];
              response?.data?.codeGroups?.forEach((item: any) => {
                values.push({
                  title: item.name,
                  value: item.name,
                });
              });
              return {
                values: values,
                hasMore: true,
              };
            },
          },
        },
        onset: {
          type: 'number',
          label: 'Onset',
          defaultOperator: 'greater',
          operators: [
            'equal',
            'not_equal',
            'greater',
            'greater_or_equal',
          ],
          valueSources: ['value'],
          mainWidgetProps: {
            customProps: {
              addonAfter: 'days',
            },
          },
        },
        status: {
          type: 'select',
          label: 'Status',
          valueSources: ['value'],
          operators: [
            'select_equals',
          ],
          mainWidgetProps: {
            customProps: {
              filterOption: (input: string, option: any) => {
                return (option?.children || '')
                  .toLowerCase()
                  .includes(input.toLowerCase());
              },
              showSearch: false,
            },
          },
          fieldSettings: {
            useAsyncSearch: false,
            allowCustomValues: false,
            asyncFetch: async (search: any, offset: any) => {
              const codes = await getCodesWithHeaders(
                CodeSearchType.medicationStatementStatus,
                false,
                {},
                '',
                accountLocationRef?.current?.map(location => location.uuid),
              );
              const values: any = [];
              codes?.data?.expansion?.contains?.forEach((item: any) => {
                values.push({
                  title: item.display,
                  value: item.code,
                });
              });
              return {
                values: values,
                hasMore: true,
              };
            },
          },
        }
      },
    };
  } else {
    deleteConfigFields('hasMedicationStatement');
  }
  if (!isOnLeadGroup) {
    config.fields.hasLabResult = {
      label: 'Lab Result',
      type: '!group',
      conjunctions: ['AND'],
      showNot: false,
      subfields: {
        name: {
          type: 'select',
          label: 'Lab Analyte',
          operators: ['select_equals'],
          valueSources: ['value'],
          mainWidgetProps: {
            valuePlaceholder: 'Search analyte',
          },
          fieldSettings: {
            useAsyncSearch: true,
            allowCustomValues: false,
            asyncFetch: async (search: any, offset: any) => {
              const codes = await getCodesWithHeaders(
                CodeSearchType.analyte,
                false,
                {},
                search || ''
              );
              const values: any = [];
              codes?.data?.expansion?.contains?.forEach((item: any) => {
                values.push({
                  title: item.display,
                  value: item.code,
                });
              });
              return {
                values: values,
                hasMore: true,
              };
            },
          },
        },
        value: {
          type: 'number',
          label: 'Last value',
          operators: [
            'equal',
            'greater',
            'greater_or_equal',
            'less',
            'less_or_equal',
          ],
          valueSources: ['value'],
        },
        // --- kept for future reference ---
        // onset: {
        //   type: 'number',
        //   label: 'Onset',
        //   defaultOperator: 'greater',
        //   operators: [
        //     'equal',
        //     'not_equal',
        //     'greater',
        //     'greater_or_equal',
        //   ],
        //   valueSources: ['value'],
        //   mainWidgetProps: {
        //     customProps: {
        //       addonAfter: 'days',
        //     },
        //   },
        // },
      },
    };
  } else {
    deleteConfigFields('hasLabResult');
  }
  if (!isOnLeadGroup && isAthena) {
    config.fields.hasImagingResult = {
      label: 'Imaging Result',
      type: '!group',
      conjunctions: ['AND'],
      showNot: false,
      subfields: {
        name: {
          type: 'multiselect',
          label: 'Results',
          operators: ['multiselect_equals', 'multiselect_not_equals'],
          valueSources: ['value'],
          mainWidgetProps: {
            valuePlaceholder: 'Search results (min 2 characters)',
          },
          fieldSettings: {
            useAsyncSearch: true,
            allowCustomValues: false,
            asyncFetch: async (search: any, offset: any) => {
              if (!search || search.length < 2) {
                return { values: [], hasMore: false };
              }
              const codes = await getCodesWithHeaders(
                CodeSearchType.imagingResult,
                false,
                {},
                search || '',
                undefined,
                { limit: 25, offset: 0 },
              );
              const values = uniquePossibleValues(codes?.data?.expansion?.contains || []);
              return {
                values: values,
                hasMore: true,
              };
            },
          },
        },
        onset: {
          type: 'number',
          label: 'Performed',
          defaultOperator: 'greater',
          operators: [
            'equal',
            'not_equal',
            'greater',
            'greater_or_equal',
          ],
          valueSources: ['value'],
          mainWidgetProps: {
            customProps: {
              addonAfter: 'days',
            },
          },
        },
        status: {
          type: 'multiselect',
          label: 'Status',
          valueSources: ['value'],
          operators: [
            'multiselect_equals',
          ],
          mainWidgetProps: {
            customProps: {
              filterOption: (input: string, option: any) => {
                return (option?.children || '')
                  .toLowerCase()
                  .includes(input.toLowerCase());
              },
              showSearch: false,
            },
          },
          fieldSettings: {
            useAsyncSearch: false,
            allowCustomValues: false,
            asyncFetch: async (search: any, offset: any) => {
              const codes = await getCodesWithHeaders(
                CodeSearchType.serviceRequestStatus,
                false,
                {},
                '',
              );
              const values: any = [];
              codes?.data?.expansion?.contains?.forEach((item: any) => {
                values.push({
                  title: item.display,
                  value: item.code,
                });
              });
              return {
                values: values,
                hasMore: true,
              };
            },
          },
        },
        matchCount: {
          type: 'number',
          label: 'For total number of record(s)',
          operators: [
            'equal',
            'greater',
            'less',
            'between',
          ],
          valueSources: ['value'],
          fieldSettings: {
            min: 0,
          },
        },
      },
    };
  } else {
    deleteConfigFields('hasImagingResult');
  }
  if (!isOnLeadGroup && isAthena) {
    config.fields.hasLabOrder = {
      label: 'Lab Order',
      type: '!group',
      conjunctions: ['AND'],
      showNot: false,
      subfields: {
        name: {
          type: 'multiselect',
          label: 'Orders',
          operators: ['multiselect_equals', 'multiselect_not_equals'],
          valueSources: ['value'],
          mainWidgetProps: {
            valuePlaceholder: 'Search orders (min 2 characters)',
          },
          fieldSettings: {
            useAsyncSearch: true,
            allowCustomValues: false,
            asyncFetch: async (search: any, offset: any) => {
              if (!search || search.length < 2) {
                return { values: [], hasMore: false };
              }
              const codes = await getCodesWithHeaders(
                CodeSearchType.labOrder,
                false,
                {},
                search || '',
                undefined,
                { limit: 25, offset: 0 },
              );
              const values: any = [];
              codes?.data?.expansion?.contains?.forEach((item: any) => {
                values.push({
                  title: item.display,
                  value: item.code,
                });
              });
              return {
                values: values,
                hasMore: true,
              };
            },
          },
        },
        onset: {
          type: 'number',
          label: 'Performed',
          defaultOperator: 'greater',
          operators: [
            'equal',
            'not_equal',
            'greater',
            'greater_or_equal',
          ],
          valueSources: ['value'],
          mainWidgetProps: {
            customProps: {
              addonAfter: 'days',
            },
          },
        },
        status: {
          type: 'multiselect',
          label: 'Status',
          valueSources: ['value'],
          operators: [
            'multiselect_equals',
          ],
          mainWidgetProps: {
            customProps: {
              filterOption: (input: string, option: any) => {
                return (option?.children || '')
                  .toLowerCase()
                  .includes(input.toLowerCase());
              },
              showSearch: false,
            },
          },
          fieldSettings: {
            useAsyncSearch: false,
            allowCustomValues: false,
            asyncFetch: async (search: any, offset: any) => {
              const codes = await getCodesWithHeaders(
                CodeSearchType.serviceRequestStatus,
                false,
                {},
                '',
              );
              const values: any = [];
              codes?.data?.expansion?.contains?.forEach((item: any) => {
                values.push({
                  title: item.display,
                  value: item.code,
                });
              });
              return {
                values: values,
                hasMore: true,
              };
            },
          },
        },
        matchCount: {
          type: 'number',
          label: 'For total number of record(s)',
          operators: [
            'equal',
            'greater',
            'less',
            'between',
          ],
          valueSources: ['value'],
          fieldSettings: {
            min: 0,
          },
        },
      },
    };
  } else {
    deleteConfigFields('hasLabOrder');
  }
  if (!isOnLeadGroup) {
    config.fields.hasRADOrder = {
      label: 'Imaging Order',
      type: '!group',
      conjunctions: ['AND'],
      showNot: false,
      subfields: {
        name: {
          type: 'multiselect',
          label: 'Orders',
          operators: ['multiselect_equals', 'multiselect_not_equals'],
          valueSources: ['value'],
          mainWidgetProps: {
            valuePlaceholder: isAthena ? 'Search orders (min 2 characters)' : 'Search orders',
          },
          ...(isAthena ? {
            fieldSettings: {
              useAsyncSearch: true,
              allowCustomValues: false,
              asyncFetch: async (search: any, offset: any) => {
                if (!search || search.length < 2) {
                  return { values: [], hasMore: false };
                }
                const codes = await getCodesWithHeaders(
                  CodeSearchType.imagingOrder,
                  false,
                  {},
                  search || '',
                  undefined,
                  { limit: 25, offset: 0 },
                );
                const values: any = [];
                codes?.data?.expansion?.contains?.forEach((item: any) => {
                  values.push({
                    title: item.display,
                    value: item.code,
                  });
                });
                return {
                  values: values,
                  hasMore: true,
                };
              },
            }
           } : {
            fieldSettings: {
              listValues: radCodes.map((item) => {
                return {
                  value: item.code,
                  title: item.description,
                };
              }),
            },
          }),
        },
        onset: {
          type: 'number',
          label: 'Performed',
          defaultOperator: 'greater',
          operators: [
            'equal',
            'not_equal',
            'greater',
            'greater_or_equal',
          ],
          valueSources: ['value'],
          mainWidgetProps: {
            customProps: {
              addonAfter: 'days',
            },
          },
        },
        ...(isAthena && {
          status: {
            type: 'multiselect',
            label: 'Status',
            valueSources: ['value'],
            operators: [
              'multiselect_equals',
            ],
            mainWidgetProps: {
              customProps: {
                filterOption: (input: string, option: any) => {
                  return (option?.children || '')
                    .toLowerCase()
                    .includes(input.toLowerCase());
                },
                showSearch: false,
              },
            },
            fieldSettings: {
              useAsyncSearch: false,
              allowCustomValues: false,
              asyncFetch: async (search: any, offset: any) => {
                const codes = await getCodesWithHeaders(
                  CodeSearchType.serviceRequestStatus,
                  false,
                  {},
                  '',
                );
                const values: any = [];
                codes?.data?.expansion?.contains?.forEach((item: any) => {
                  values.push({
                    title: item.display,
                    value: item.code,
                  });
                });
                return {
                  values: values,
                  hasMore: true,
                };
              },
            },
          },
          matchCount: {
            type: 'number',
            label: 'For total number of record(s)',
            operators: [
              'equal',
              'greater',
              'less',
              'between',
            ],
            valueSources: ['value'],
            fieldSettings: {
              min: 0,
            },
          },
        }),
      },
    };
  } else {
    deleteConfigFields('hasRADOrder');
  }
  if (!isOnLeadGroup && isAthena) {
    config.fields.hasReferralOrder = {
      label: 'Referral Order',
      type: '!group',
      conjunctions: ['AND'],
      showNot: false,
      subfields: {
        name: {
          type: 'multiselect',
          label: 'Orders',
          operators: ['multiselect_equals', 'multiselect_not_equals'],
          valueSources: ['value'],
          mainWidgetProps: {
            valuePlaceholder: 'Search orders (min 2 characters)',
          },
          fieldSettings: {
            useAsyncSearch: true,
            allowCustomValues: false,
            asyncFetch: async (search: any, offset: any) => {
              if (!search || search.length < 2) {
                return { values: [], hasMore: false };
              }
              const codes = await getCodesWithHeaders(
                CodeSearchType.referralOrder,
                false,
                {},
                search || '',
                undefined,
                { limit: 25, offset: 0 },
              );
              const values: any = [];
              codes?.data?.expansion?.contains?.forEach((item: any) => {
                values.push({
                  title: item.display,
                  value: item.code,
                });
              });
              return {
                values: values,
                hasMore: true,
              };
            },
          },
        },
        onset: {
          type: 'number',
          label: 'Performed',
          defaultOperator: 'greater',
          operators: [
            'equal',
            'not_equal',
            'greater',
            'greater_or_equal',
          ],
          valueSources: ['value'],
          mainWidgetProps: {
            customProps: {
              addonAfter: 'days',
            },
          },
        },
        status: {
          type: 'multiselect',
          label: 'Status',
          valueSources: ['value'],
          operators: [
            'multiselect_equals',
          ],
          mainWidgetProps: {
            customProps: {
              filterOption: (input: string, option: any) => {
                return (option?.children || '')
                  .toLowerCase()
                  .includes(input.toLowerCase());
              },
              showSearch: false,
            },
          },
          fieldSettings: {
            useAsyncSearch: false,
            allowCustomValues: false,
            asyncFetch: async (search: any, offset: any) => {
              const codes = await getCodesWithHeaders(
                CodeSearchType.serviceRequestStatus,
                false,
                {},
                '',
              );
              const values: any = [];
              codes?.data?.expansion?.contains?.forEach((item: any) => {
                values.push({
                  title: item.display,
                  value: item.code,
                });
              });
              return {
                values: values,
                hasMore: true,
              };
            },
          },
        },
        matchCount: {
          type: 'number',
          label: 'For total number of record(s)',
          operators: [
            'equal',
            'greater',
            'less',
            'between',
          ],
          valueSources: ['value'],
          fieldSettings: {
            min: 0,
          },
        },
      },
    };
  } else {
    deleteConfigFields('hasLabOrder');
  }
  if (!isOnLeadGroup && openInHint) {
    config.fields.hasPastMembershipStatus = {
      label: 'Past Hint Membership Status',
      type: '!group',
      conjunctions: ['AND'],
      showNot: false,
      subfields: {
        active: {
          type: 'number',
          label: 'Active',
          operators: ['between'],
          valueSources: ['value'],
          mainWidgetProps: {
            customProps: {
              addonAfter: 'days',
            },
          },
        },
        inactive: {
          type: 'number',
          label: 'Inactive',
          operators: ['between'],
          valueSources: ['value'],
          mainWidgetProps: {
            customProps: {
              addonAfter: 'days',
            },
          },
        },
      },
    };
  } else {
    deleteConfigFields('hasPastMembershipStatus');
  }

  if (!isOnLeadGroup) {
    config.fields.hasFoldMembershipStatus = {
      label: 'Fold Membership Status',
      type: 'select',
      valueSources: ['value'],
      operators: ['select_equals'],
      fieldSettings: {
        listValues: FOLD_MEMBERSHIP_STATUS_LIST.map((item) => {
          return {
            value: item.code,
            title: item.display,
          };
        }),
      },
    };
  } else {
    deleteConfigFields('hasFoldMembershipStatus');
  }

  if (!isOnLeadGroup && isWearableFactEnabled) {
    config.fields.wearable = {
      label: 'Home Monitoring',
      type: '!group',
      conjunctions: ['AND'],
      showNot: false,
      subfields: {
        name: {
          type: 'select',
          label: 'Biomarker/Activity',
          operators: ['select_equals'],
          valueSources: ['value'],
          mainWidgetProps: {
            valuePlaceholder: 'Select biomarker/activity',
          },
          fieldSettings: {
            listValues: SUPPORTED_WEARABLES.map((vital) => ({
              value: vital.loinc,
              title: vital.unit
                ? `${vital.label} (${vital.unit})`
                : vital.label,
            })),
          },
        },
        value: {
          type: 'number',
          label: 'For every value',
          operators: [
            'equal',
            'greater',
            'greater_or_equal',
            'less',
            'less_or_equal',
          ],
          valueSources: ['value'],
        },
        averageValue: {
          type: 'number',
          label: 'Daily average value',
          operators: [
            'equal',
            'greater',
            'greater_or_equal',
            'less',
            'less_or_equal',
          ],
          valueSources: ['value'],
        },
        increasedByValue: {
          type: 'number',
          label: 'Increased by value',
          operators: [
            'equal',
            'greater',
            'greater_or_equal',
            'less',
            'less_or_equal',
          ],
          valueSources: ['value'],
        },
        increasedByPercentageValue: {
          type: 'number',
          label: 'Increased by percentage',
          operators: [
            'equal',
            'greater',
            'greater_or_equal',
            'less',
            'less_or_equal',
          ],
          valueSources: ['value'],
          mainWidgetProps: {
            customProps: {
              addonAfter: '%',
            },
          },
        },
        decreasedByValue: {
          type: 'number',
          label: 'Decreased by value',
          operators: [
            'equal',
            'greater',
            'greater_or_equal',
            'less',
            'less_or_equal',
          ],
          valueSources: ['value'],
        },
        decreasedByPercentageValue: {
          type: 'number',
          label: 'Decreased by percentage',
          operators: [
            'equal',
            'greater',
            'greater_or_equal',
            'less',
            'less_or_equal',
          ],
          valueSources: ['value'],
          mainWidgetProps: {
            customProps: {
              addonAfter: '%',
            },
          },
        },
        deviatedByValue: {
          type: 'number',
          label: 'Deviated by value',
          operators: [
            'equal',
            'greater',
            'greater_or_equal',
            'less',
            'less_or_equal',
          ],
          valueSources: ['value'],
        },
        deviatedByPercentageValue: {
          type: 'number',
          label: 'Deviated by percentage',
          operators: [
            'equal',
            'greater',
            'greater_or_equal',
            'less',
            'less_or_equal',
          ],
          valueSources: ['value'],
          mainWidgetProps: {
            customProps: {
              addonAfter: '%',
            },
          },
        },
        lastConsecutiveDays: {
          type: 'number',
          label: 'In the past consecutive day(s)',
          operators: ['equal'],
          valueSources: ['value'],
          mainWidgetProps: {
            customProps: {
              addonAfter: 'days',
            },
          },
        },
        lastConsecutiveWeeks: {
          type: 'number',
          label: 'Over the Course of Week(s)',
          operators: ['equal'],
          valueSources: ['value'],
          mainWidgetProps: {
            customProps: {
              addonAfter: 'weeks',
            },
          },
        },
        lastRecording: {
          type: 'number',
          label: 'For latest recording(s)',
          operators: ['equal'],
          valueSources: ['value'],
        },
        betweenTimeRange: {
          type: 'time',
          label: 'Time of day',
          operators: ['between'],
          valueSources: ['value'],
          mainWidgetProps: {
            customProps: {
              format: 'HH:mm',
              minuteStep: 15,
            },
          },
        },
        numberOfRecordings: {
          type: 'number',
          label: 'For total no of recording(s)',
          operators: [
            'equal',
            'greater',
            'less',
            'between',
          ],
          valueSources: ['value'],
          fieldSettings: {
            min: 0,
          },
        },
        anyValue: {
          type: 'number',
          label: 'Any value recorded',
          operators: [
            'equal',
            'greater',
            'greater_or_equal',
            'less',
            'less_or_equal',
            'between',
          ],
          valueSources: ['value'],
        },
        duration: {
          type: 'number',
          label: 'During the last days(s)',
          operators: ['equal'],
          valueSources: ['value'],
          fieldSettings: {
            min: 0,
          },
          mainWidgetProps: {
            customProps: {
              addonAfter: 'days',
            },
          },
        },
      },
    };
  } else {
    deleteConfigFields('wearable');
  }


  return (
    loadingEHRAttributes ? (
      <Spinner />
    ) : (
      <QueryBuilder
        handleJSONTreeStateChange={handleJSONTreeStateChange}
        config={config}
        jsonTree={jsonTree}
      />
    )
  );
};

export const RuleEngineInputField = React.memo(RuleEngineInputField1);
