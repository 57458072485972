import { useLazyQuery } from '@apollo/client';
import { Auth } from 'aws-amplify';
import { HStack, Pressable, Text, useMediaQuery, View, VStack } from 'native-base';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Dimensions } from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import { useNavigate } from 'react-router-dom';
import {
  BUTTON_TYPE,
  GROUP_MEMBER_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
  PARENT_CODE
} from '../../../constants';
import { COMMON_ACTION_CODES } from '../../../constants/ActionConst';
import { getEnvVariable } from '../../../constants/BaseUrlConst';
import { MLOV_CATEGORY, USER_PREFERENCE_CODE, USER_ROLE_CODES } from '../../../constants/MlovConst';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { ILoginUserData } from '../../../Interfaces';
import { getCurrentSubdomain } from '../../../screens/MainScreen/MainScreenHelper';
import { LeadQueries, UserQueries } from '../../../services';
import BaseService from '../../../services/CommonService/BaseService';
import { getProfileImage } from '../../../services/ProfileImage/ProfileImage';
import { Colors } from '../../../styles';
import { createAxiosInstance } from '../../../utils/APIUtils';
import {
  getAccountId,
  getAccountName,
  getCaslAbility,
  getCurrentUserRole,
  getCurrentUserRoleCodes,
  getUserId,
  getUserName,
  getUserUUID,
  isEmployerRole,
  isEmptyArray,
  isLoggedInUserGlobalAdmin,
  isLoggedInUserWorkFlowOrCustomerSuccess,
  isLoginUserBusinessOwner,
  isMasterAccount
} from '../../../utils/commonUtils';
import LocalStorage from '../../../utils/LocalStorage';
import { getContactTypeId, getMlovIdFromCode, getMlovListFromCategory } from '../../../utils/mlovUtils';
import { GlobalWarningsContext } from '../../BodyContainer/GlobalWarningContext/GlobalWarningsContext';
import { SearchBar } from '../../BodyContainer/SearchBar';
import { FoldButton } from '../../CommonComponents/FoldButton/FoldButton';
import { useOnlineStatus } from '../../CustomHooks/useOnlineStatus';
import { withMiniContactViewHOC } from '../../MiniContactViewHOC';
import { AddOrUpdateLead } from '../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateLead';
import AddEditUser from '../../RightSideContainer/Contacts/TeamMembers/AddEditUser/AddEditUser';
import { IUsersResponse } from '../../RightSideContainer/Contacts/TeamMembers/interfaces';
import AppointmentBooking from '../CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBooking';
import { DisplayCardAvatar } from '../DisplayCard/DisplayCardAvatar';
import { EnvIndicator } from '../EnvIndicator';
import FoldUnityAi from '../FoldUnityAi/FoldUnityAi';
import GlobalActions from '../GlobalActions/GlobalActions';
import { Notifications } from '../Notifications';
import NotificationSettings from '../Notifications/NotificationSettings';
import PatientSearchAndSelect from '../PatientSearch/PatientSearchAndSelect';
import ProfileViewModal from '../ProfileView/ProfileViewModal';
import UnityAiIcon from '../Svg/UnityAiIcon';
import { LockScreenWarning } from './LockScreenWarning';
import {EventBus} from '../../../utils/EventBus';
import {SUPPORTED_EVENT_CODE} from '../../../constants/WebSocketConst';
import {openOutlookLoginWindow} from '../../RightSideContainer/TeamInbox/Integrations/IntegrationCreate/EmailInboxCreate/EmailInboxUtils';
import {IEmailInboxTokenStatusChangedData} from '../../RightSideContainer/TeamInbox/Integrations/IntegrationCreate/EmailInboxCreate/interfaces';
import {emailReLogin, getExpiredEmailInbox} from '../EmailDrawerCommonV2/EmailInboxApi';
import {ToastType} from '../../../utils/commonViewUtils';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../constants/Configs';
import {EMAIL_INBOX_USER_VIEW} from '../PreferencesSetting/PreserenceConst';
import {IMainTopbarViewState} from './MainTopbarInterface';
import {isAccountConfigEnabled} from '../../../utils/configUtils';
import {CONFIG_CODES} from '../../../constants/AccountConfigConst';
import {PreferencesSettingDrawer} from '../PreferencesSetting/PreferencesSettingDrawer';
import {useToast} from '../../Toast/ToastProvider';
import {ICustomToast} from '../../RightSideContainer/Contacts/CustomField/interface';
import {CUSTOM_FIELD_TOAST_ERROR_DURATION} from '../../RightSideContainer/Contacts/CustomField/CustomFieldConst';

import {USER_ACCESS_PERMISSION} from '../../RightSideContainer/UserAccess/UserAccessPermission';
import {MAIN_MENU_CODES} from '../../SideMenuBar/SideBarConst';
import FoldPermitCan from '../../CommonComponents/FoldPermitCan/FoldPermitCan';
const MainTopBarView = (props: {
  leftBackView?: JSX.Element;
  patientContextView?: JSX.Element;
  navigateOrOpenContactIdDrawer?:(contactId:string) => void;
}) => {
  const intl = useIntl();
  const isOnline = useOnlineStatus();
  const {leftBackView, patientContextView} = props;
  const commonData = useContext(CommonDataContext);
  const userData = commonData.userData || ({} as ILoginUserData);
  const userId = getUserId();
  const accountName = getAccountName();
  const currentUserRoles = getCurrentUserRoleCodes();
  const currentUserRole = getCurrentUserRole();
  const isMasterAccountFlag = isMasterAccount();
  const toast = useToast();
  const isBusinessOwner = isLoginUserBusinessOwner() || isLoggedInUserGlobalAdmin() || isLoggedInUserWorkFlowOrCustomerSuccess()
  const personType = 'CUSTOMER';
  const personTypeId = getContactTypeId(personType);
  const { width } = Dimensions.get('window');
  const isEmployer = isEmployerRole();
  const userName = getUserName()
  const [openAddPatientModal, setOpenAddPatientModal] = useState(false);
  const [selectedContactName, setContactName] = useState('');
  const [searchList, setSearchList] = useState<any>({
    isLoading: false,
    searchListData: [],
    foundData: true,
  });
  const [stateData, setStateData] = useState<IMainTopbarViewState>({
    selectedViewCode: '',
    showPreferencesDrawer: false,
    selectedEmailInboxUserView: EMAIL_INBOX_USER_VIEW[1],
    preferenceData: {},
    preferenceDataId: undefined,
  });
  const userUuid = getUserUUID();
  const userPreferenceList = getMlovListFromCategory(
    commonData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.USER_PREFERENCE_TYPE
  );
  const userPreferenceId = getMlovIdFromCode(
    userPreferenceList,
    USER_PREFERENCE_CODE.USER_CONVERSATION_PREFERENCE
  );

  const [userEditData, setUserEditData] = useState({
    showUserEdit: false,
    userInfo: [] as any,
    id: userData.uuid,
    name: userData.name,
    profileImage: userData?.avatar_url?.includes('404')
      ? ''
      : userData.avatar_url,
    email: userData.email,
    showProfileModal: false,
  });
  const [notificationDrawerState, setNotificationDrawerState] = useState({
    showNotificationDrawer: false,
  });
  const axios = BaseService.getSharedInstance().axios
  const [showOfflineWarning, setShowOfflineWarning] = useState(false);
  const [showPopover, setShowPopover] = useState(true);
  const navigate = useNavigate();
  const [getContacts] = useLazyQuery<{contacts: any[]}, any>(
    LeadQueries.SearchContacts2,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [getUserPreference] = useLazyQuery(UserQueries.GET_USER_PREFERENCE, {
    fetchPolicy: 'no-cache',
    variables: {
      userId: userUuid,
      userPreferencesTypeId: userPreferenceId,
    },
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
    onCompleted:(data) => {
      setStateData((prev) => {
        return {
          ...prev,
          userPreferenceLoading: false,
        }
      })
      if (data?.userPreferences?.length > 0 && data?.userPreferences?.[0]?.preferencesJson) {
       const preferenceData = JSON.parse(data?.userPreferences?.[0]?.preferencesJson);
       const selectedEmailInboxUserView = {
        code: preferenceData?.userPreference?.emailInboxUserView,
        title: '',
       }
       setStateData(prev => {
        return {
          ...prev,
          preferenceData: preferenceData,
          preferenceDataId: data?.userPreferences?.[0]?.id,
          selectedEmailInboxUserView: selectedEmailInboxUserView?.code ? selectedEmailInboxUserView : prev?.selectedEmailInboxUserView,
        }
      })
      }
    }
  });

  const ssoSignOut = () => {
    Auth.signOut({global: true});
  };

  const csSignOut = (cognitoUrl: string) => {
   const axiosInstance = createAxiosInstance({
      baseURL: `${cognitoUrl}`,
      headers: {
        'Content-type': 'application/json',
      },
    });
    return axiosInstance.get(`${cognitoUrl}`)
  };

  const logout = () => {
    return axios.post('crm-nest/logout');
  }

  const userSignOut = (): void => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent('CLOUD_CALL_DESTROY', {});
    logout().then((res) => {
      const ssoConfig =
        commonData.accountConfigData?.SSO_CONFIG?.accountConfigurations;
        const isCsUser = currentUserRoles.includes('CUSTOMER_SUCCESS');
      if (ssoConfig && ssoConfig.length > 0) {
        const ssoValue = ssoConfig[0].value;
        const ssoValueData = JSON.parse(ssoValue);
        const redirectSignOutUrl = window.location.host.includes('localhost')
          ? 'http://localhost:3000'
          : ssoValueData.Auth.oauth.redirectSignOut;
        const domain = ssoValueData.Auth.oauth.domain;
        const clientId = ssoValueData.Auth.userPoolWebClientId;
        const providerId = JSON.parse(ssoValue).provider;
        const ssoCognitoLogoutUrl = `https://${domain}/logout?response_type=code&client_id=${clientId}&redirect_uri=${redirectSignOutUrl}&logout_uri=${redirectSignOutUrl}&scope=openid+profile+email&identity_provider=${providerId}`;
        window.location.href = ssoCognitoLogoutUrl;
        ssoSignOut();
        LocalStorage.removeItem('loginDone');
        LocalStorage.removeItem('isWorkflowLogin');
        LocalStorage.removeItem('consent_Obj');
        LocalStorage.removeItem('user');
        //window.location.reload();
      } else if(isCsUser){
          const ssoValue = commonData.accountConfigData?.WORKFLOW_SSO_CONFIG?.defaultValue;
          const ssoValueData = JSON.parse(ssoValue!);
          const domain = ssoValueData.Auth.oauth.domain;
          const clientId = ssoValueData.Auth.userPoolWebClientId;
          const providerId = ssoValueData.provider;

          const redirectSignOutUrl = window.location.host.includes('localhost')
          ? 'http://localhost:3000/cs'
          : `https://${getCurrentSubdomain()}/cs`;


          const ssoCognitoLogoutUrl = `https://${domain}/logout?response_type=code&client_id=${clientId}&identity_provider=${providerId}&redirect_uri=${redirectSignOutUrl}&logout_uri=${redirectSignOutUrl}`;
          const ssoAzureLogoutUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${encodeURIComponent(ssoCognitoLogoutUrl)}`
          window.location.href = ssoAzureLogoutUrl;

          LocalStorage.removeItem('loginDone');
          LocalStorage.removeItem('isWorkflowLogin');
          LocalStorage.removeItem('consent_Obj');
          LocalStorage.removeItem('user');

      } else {
        navigate('/login', {state: {logout: true}});
      }
    });
  };

  const [getUsersData] = useLazyQuery<IUsersResponse>(
    UserQueries.GET_USERS_BY_ID,
    {
      variables: {
        accountId: getAccountId(),
        userUuid: userData.uuid,
      },
    }
  );

  const showToast = (toastData: ICustomToast) => {
    toast({
      toastType: toastData?.toastType,
      message: toastData?.message,
      duration: toastData?.duration || CUSTOM_FIELD_TOAST_ERROR_DURATION,
      closeAllPrevToast: toastData?.closeAllPrevToast || false,
    })
  }

  const fetchUserData = async () => {
    const data = await getUsersData();
    setUserEditData({...userEditData, userInfo: data?.data?.users[0]});
  };

  const [isShowFoldUnityAi, setIsShowFoldUnityAi] = useState(false)
  const onActionPerformed = (actionCode: string, actionData?: any) => {
    switch (actionCode) {
      case 'PROFILE':
        setUserEditData({
          ...userEditData,
          showUserEdit: true,
          showProfileModal: false,
        });
        break;
      case 'NOTIFICATION':
        setNotificationDrawerState({...notificationDrawerState, showNotificationDrawer: true})
        setUserEditData({
          ...userEditData,
          showProfileModal:false,
        })
        break;
      case COMMON_ACTION_CODES.PREFERENCES:
        setStateData((prev) => {
          return {
            ...prev,
            showPreferencesDrawer: true
          }
        })
        setUserEditData((prev) => {
          return {
            ...prev,
            showProfileModal: false
          }
        })
        break;
      case 'LOGOUT':
        userSignOut();
      LocalStorage.removeItem('assignOtherConversationLastFilterSelected');
      LocalStorage.removeItem('associatedAccounts');
        break;
      case 'CLOSE':
        setNotificationDrawerState({...notificationDrawerState, showNotificationDrawer: false})
        setUserEditData({...userEditData, showProfileModal: false});
        break;
      case 'CLOSE':
        setNotificationDrawerState({...notificationDrawerState, showNotificationDrawer: false})
        setUserEditData({...userEditData, showProfileModal: false});
        break;
      case COMMON_ACTION_CODES.ADD_SCHEDULE:
        setStateData((prev) => {
          return {
            ...prev,
            selectedViewCode: COMMON_ACTION_CODES.ADD_SCHEDULE,
          };
        });
        break;
      case COMMON_ACTION_CODES.COMPLETE:
        showToast({
          toastType: ToastType.success,
          message: intl.formatMessage({id: 'profileUpdateSuccess'}),
          duration: CUSTOM_FIELD_TOAST_ERROR_DURATION,
          closeAllPrevToast: true,
        })
        onCompleteAddEditDrawer(actionData);
        return;
      default:
        setStateData((prev) => {
          return {
            ...prev,
            selectedViewCode: '',
          };
        });
        break;
    }
  };
  function searchCon(contact: string) {
    setSearchList({...searchList, isLoading: true});
    getContacts({
      variables: {
        searchString: '%' + contact + '%',
        limit: 10,
        // contactType: 'customer',
      },
    })
      .then((contactRespData) => {
        const contacts = contactRespData?.data?.contacts;
        if (contacts && !contacts.length)
          setSearchList({
            ...searchList,
            searchListData: contacts,
            foundData: false,
            isLoading: false,
          });
        else
          setSearchList({
            ...searchList,
            searchListData: contacts,
            foundData: true,
            isLoading: false,
          });
      })
      .catch((err) => {
        setSearchList({...searchList, isLoading: false});
      });
  }
  const onCompleteAddEditDrawer = (data: any) => {
    setProfileImageAndName();
    setUserEditData({...userEditData, showUserEdit: false});
  };
  const onCloseAddEditDrawer = () => {
    setUserEditData({...userEditData, showUserEdit: false});
  };
  const onCloseNotificationDrawer =() => {
    setNotificationDrawerState({...notificationDrawerState, showNotificationDrawer: false})
  }
  const onClosePreferencesDrawer =() => {
    setStateData((prev) => {
      return {
        ...prev,
        showPreferencesDrawer: false,
      }
    })
  }
  const [searchPopover, setSearchPopover] = useState(false);
  const handleSearchPopover = () => {
    setSearchPopover(!searchPopover);
  };
  const setProfileImageAndName = async () => {
    const userRes: any = await getProfileImage(userData.name, userData.email);
    if (userRes?.data?.avatar_url) {
      setUserEditData((prev) => {
        return {
          ...prev,
          profileImage: userRes?.data?.avatar_url.includes('404')
            ? ''
            : userRes?.data?.avatar_url,
          name: userRes?.data?.available_name,
        };
      });
    }
  };

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const isSmallScreen = isIPadMiniScreen || isIPadScreen;

  const ContactSearch = useMemo(() => {
    return (
      <SearchBar
        showPopover={showPopover}
        setShowPopover={setShowPopover}
        foundData={searchList.foundData}
        isLoading={searchList.isLoading}
        setSearchList={setSearchList}
        placeholderText={'Search member'}
        searchList={searchList.searchListData}
        borderRadius={5}
        inputWidth={isSmallScreen ? 150 : 300}
        searchBoxStyle={{
          marginHorizontal: 0,
          paddingHorizontal: 0,
        }}
        onChange={(e: {target: {value: string}}) => {
          if (e?.target?.value?.length >= 3) {
            searchCon(e.target.value);
            setShowPopover(true);
          } else {
            setSearchList({...searchList, searchListData: []});
          }
        }}
      />
    );
  }, [searchList, showPopover]);


  const showNotificationIcon = !currentUserRoles.includes('EMPLOYER');

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    if(isOnline){
      setTimeout(() => {
        setShowOfflineWarning(false)
      }, 2000)
    } else {
      setShowOfflineWarning(true)
    }
  },[isOnline])

  useEffect(() => {
    getUserPreference();
  },[userEditData?.showProfileModal])

  return (
    <VStack>
      {showOfflineWarning && (
        <HStack
          backgroundColor={isOnline ? Colors.Custom.SuccessColor : Colors.Custom.ErrorColor}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Feather name={isOnline ? 'globe' : 'alert-triangle'} color={Colors.Custom.Gray25} />
          <Text ml={2} color={Colors.Custom.Gray25}>
            {intl.formatMessage({
              id: isOnline ? 'backOnlineMessage' : 'offlineMessage',
            })}
          </Text>
        </HStack>
      )}


      {!getEnvVariable() && isBusinessOwner && (
        <GlobalWarningsContext.Consumer>
          {(context) => {
            return (
              <>
                {context?.globalWarningData.isShow && (
                  <HStack
                    backgroundColor={Colors.Custom.orangeMessageTopBar}
                    justifyContent={'center'}
                    alignItems={'flex-start'}
                    flex={1}
                  >
                    <View
                      ml={2}
                      justifyContent={'center'}
                      alignItems={'center'}
                      justifySelf={'center'}
                      alignSelf={'center'}
                    >
                      <Feather name={'globe'} color={'#000000'} />
                    </View>
                    <Text
                      width={width - 100}
                      ml={2}
                      flexDirection={'row'}
                      flexWrap={'wrap'}
                      flexShrink={1}
                      flex={1}
                      color={'#000000'}
                    >
                      {context.globalWarningData.warningMessage}
                    </Text>
                  </HStack>
                )}
              </>
            );
          }}
        </GlobalWarningsContext.Consumer>
      )}
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
          paddingLeft: 20,
          paddingRight: 12,
          paddingTop: 16,
          paddingBottom: 16,
          height: 60,
          backgroundColor: '#fff',
          justifyContent: 'space-between',
          borderBottomWidth: 1,
          borderColor: Colors.Custom.Gray200,
        }}
      >
        <HStack flex={1} alignItems={'center'}>
          <HStack flex={0.5}>
            <View
              nativeID="account_name"
              style={{flexDirection: 'row', alignItems: 'center'}}
            >
              {leftBackView ? leftBackView : <></>}
              <Text style={{fontSize: 24, fontWeight: '800', color: '#000'}}>
                {width > 840 ? accountName : accountName.split(" ").map((n)=>n[0]).join("")}
              </Text>
              <View style={{marginLeft: 10}}>
                  {width > 840 && <EnvIndicator />}
              </View>
              {patientContextView}
            </View>
          </HStack>
          <HStack
            flex={0.5}
            justifyContent={'flex-end'}
            alignItems={'center'}
            space={2}
          >
            <LockScreenWarning/>
            {!isMasterAccountFlag && <>
              {!isEmployer && (
                <FoldPermitCan 
                resource={MAIN_MENU_CODES.CONSUMER}
                action={USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code}
                component={<View
                  width={'50%'}
                  borderRadius={10}
                  borderWidth={1}
                  borderColor={Colors.Custom.Gray200}
                >
                  <PatientSearchAndSelect
                    border={false}
                    allowAddNew={true}
                    offSuffixIcon={true}
                    addNewLabel="Add New Patient"
                    isProspect={false}
                    placeholder="Search Patient"
                    value={selectedContactName}
                    disableUserSearch={true}
                    disableLeadsSearch={true}
                    onChange={(participants: any) => {
                      const contactId =
                        participants?.label?.props?.contactData?.id;
                      setContactName(
                        participants?.label?.props?.contactData?.name
                      );
                      if (contactId) {
                        props?.navigateOrOpenContactIdDrawer?.(contactId);
                      }
                    }}
                    onRightLabelClick={() => setOpenAddPatientModal(true)}
                  />
                </View>}
                />
            )}
            <View
          style={{
            width: 1,
            height: 20,
            backgroundColor: Colors.Custom.Gray200,
            marginHorizontal: 8,
          }}
        ></View>
            { !isEmployer && getEnvVariable() ?
              <View>
            <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.PRIMARY,
                  onPress: () => {
                    setIsShowFoldUnityAi(true)
                  },
                  leftIcon: (
                    <UnityAiIcon customColor={Colors.Custom.mainPrimaryPurple} />
                  ),
                }}
                customProps={{
                  btnText: intl.formatMessage({id: 'tryUnityAI'}),
                }}
              ></FoldButton>
            </View> : <></> }

           { isEmployer ? <></>: <View
            >
              <Notifications key={1} />
            </View> }
            {/* <View
            >
              <CustomSwitch isSettingIcon={true} />
            </View> */}
            <View
          style={{
            width: 1,
            height: 20,
            backgroundColor: Colors.Custom.Gray200,
            marginHorizontal: 8,
          }}
        ></View>
            {!isEmployerRole() &&
            <GlobalActions isBtn={true} />
            }
         <View
          style={{
            width: 1,
            height: 20,
            backgroundColor: Colors.Custom.Gray200,
            marginHorizontal: 8,
          }}
        ></View>
        </> }
            <Pressable
              onPress={() =>
                setUserEditData({...userEditData, showProfileModal: true})
              }
            >
              <DisplayCardAvatar
                avatarStyle={{
                  avatarSize: '12',
                }}
                isLetterAvatarShow
                userData={{
                  userId: userId,
                  userType: GROUP_MEMBER_TYPE.USER,
                  imgSrc: userEditData?.profileImage,
                  roles: currentUserRoles,
                  userName: userName
                }}
              />
            </Pressable>
          </HStack>
        </HStack>
      </View>
      {userEditData.showProfileModal && (
        <ProfileViewModal
          userEditData={userEditData}
          onActionPerformed={onActionPerformed}
        />
      )}
      {userEditData.showUserEdit && (
        <AddEditUser
          roleIsDisable
          onClose={(actionCode, data) => {
            if (actionCode === 'COMPLETE') {
              onCompleteAddEditDrawer(data);
              return;
            }
            onCloseAddEditDrawer();
          }}
          user={{
            id: userData.id,
            email: userEditData.email,
            name: userEditData.name,
            avatar_url: userEditData.profileImage,
            userRoles: userEditData?.userInfo?.userRoles,
            uuid: userData.uuid,
          }}
          parentCode={PARENT_CODE.SIDE_MENU_PANEL}
        />
      )}
      {stateData.selectedViewCode === COMMON_ACTION_CODES.ADD_SCHEDULE && (
        <AppointmentBooking
          isVisible={true}
          onComplete={() => {
            onActionPerformed('');
          }}
          onCancel={() => {
            onActionPerformed('');
          }}
        />
      )}

      {openAddPatientModal && (
        <AddOrUpdateLead
          shouldInvokeCallback={true}
          onFormCompleteAction={(actionCode: string, responseData: any) => {
            setOpenAddPatientModal(false);
            if (responseData?.reference?.contactId) {
              props?.navigateOrOpenContactIdDrawer?.(responseData.reference.contactId);
            }
          }}
          personType={personType}
          personTypeUuid={personTypeId}
        />
      )}
      {notificationDrawerState.showNotificationDrawer &&(
        <NotificationSettings
        user={{
          id: userData.id,
          email: userEditData.email,
          name: userEditData.name,
          avatar_url: userEditData.profileImage,
          userRoles: userEditData?.userInfo?.userRoles,
          uuid: userData.uuid,
        }}
        onClose={() => {
          onCloseNotificationDrawer();
        }}
        />
      )}
      {stateData?.showPreferencesDrawer && (
        <PreferencesSettingDrawer
         selectedEmailInboxUserView={stateData?.selectedEmailInboxUserView}
         preferenceData={stateData?.preferenceData}
         preferenceDataId={stateData?.preferenceDataId}
         userPreferenceId={userPreferenceId}
         onClose={() => {
          onClosePreferencesDrawer();
         }}
         onActionPerformed={onActionPerformed}
         userEditData={userEditData}
        />
      )}

      <FoldUnityAi key={isShowFoldUnityAi+''} isDrawer={true} isShowFoldUnityAi={isShowFoldUnityAi} setIsShowFoldUnityAi={setIsShowFoldUnityAi}></FoldUnityAi>
    </VStack>
  );
};

export default withMiniContactViewHOC(MainTopBarView);
