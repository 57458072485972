export enum CodeSearchType {
  problem = 'Problem',
  immunization = 'Immunization',
  allergy = 'Allergy',
  medication = 'Medicine',
  procedure = 'Procedure',
  allergyReactions = 'AllergyReaction',
  diagnosis = 'Diagnosis',
  race = 'Race',
  ethnicity = 'Ethnicity',
  emergencyRelationType = 'EmergencyRelationType',
  memberStatus = 'MemberStatus',
  conditionStatus = 'ConditionStatus',
  analyte = 'Analyte',
  medicationStatementStatus = 'MedicationStatementStatus',
  appointmentStatus = 'AppointmentStatus',
  imagingOrder = 'ImagingOrder',
  labOrder = 'LabOrder',
  imagingResult = 'ImagingResult',
  serviceRequestStatus = 'ServiceRequestStatus',
  diagnosticReportStatus = 'DiagnosticReportStatus',
  referralOrder = 'ReferralOrder',
}
