import { Drawer } from "antd"
import { Skeleton, VStack, Text } from "native-base";
import { useEffect, useState } from "react";
import { Dimensions } from "react-native";
import { BUTTON_TYPE } from "../../../../constants/StringConst";
import { getHieDataReportByUrl } from "../../../../services/CommonService/AidBoxService";
import { downloadReport } from "../../../../services/CommonService/OrderService";
import { Colors } from "../../../../styles/Colors";
import { getEhrConfig } from "../../../../utils/capabilityUtils";
import { MIME_TYPES } from "../../../common/DocumentViewer/DocumentViewerHelper";
import Iframe from "../../../common/Iframe/Iframe";
import { ModalActionTitle } from "../../../common/ModalActionTitle/ModalActionTitle";
import { isValidHttpUrl } from "../../../RightSideContainer/Contacts/TeamMembers/EditAccount/EditAccountUtils";
import { SOURCE_TYPES } from "../../../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/ContactDetailsTabs/ActivityTimeLine/FeedConst";
import { getBase64 } from "../OtherDetails/PatientDocuments/UploadPatientDocument";
import { SUPPORTED_IMAGE_URLS } from "./RecentReport/ReportUtils";
import CommonService from "../../../../services/CommonService/CommonService";
import { isAccountConfigEnabled } from "../../../../utils/configUtils";
import { CONFIG_CODES } from "../../../../constants/AccountConfigConst";


export const MediaReportView = (props: any) => {
  const {height} = Dimensions.get('window');
  const finalHeight = height - 120;
  const [mediaReportDetail, setMediaReportDetail] =
    useState<any>({
      source: props.source,
      reportSource: props.reportSource,
      showReportPdf: props.showReportPdf,
      reportData: '',
      loading: false,
      base64Data: ''
  });
  const ehrConfig = getEhrConfig(props?.personData.accountLocationUuid, '');
  const [ showSummary, setShowSummary ] = useState<boolean>(false);
  const [ summary, setSummary ] = useState<string>('')
  const isDocumentSummaryEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_DOCUMENT_SUMMARY_ENABLED);


  const getSummary = async (documentResourceId: string, source: string) => {
    const commonService = CommonService.getCommonServiceInstance();
    const llmflowService = commonService.llmService;

    const maxRetries = 10;

    for (let attempt = 1; attempt<=maxRetries; attempt++) {

      try {
        const response = await llmflowService.post(
          `/ocr/resource/summary`, {
            documentResourceId: documentResourceId,
            source: source,
            patientId: props?.patientId
          }
        );
        const isComplete = response?.data?.isComplete;
        const summary = response.data.summaryString;
        if (isComplete) {
          return summary;
        } else if (attempt < maxRetries) {
          throw new Error('Processing not complete');
        } else {
          return 'Error in fetching the summary';
        }

      } catch (error){
        console.log(`checkDocumentProcessStatus: API call to llm failing /ocr/resource/status`);
        if (attempt < maxRetries) {
          await new Promise(resolve => setTimeout(resolve, 30000));
        } else {

          return 'Error in fetching the summary';
        }
      }
    }
  }

  const generateSummary = async (hardRefresh: string) => {
    setShowSummary(true);
    const summary = await getSummary(props?.reportId, mediaReportDetail.base64Data)
    setSummary(summary);
    return;

  }
  // const generateDocumentSummary
  const getReport = async () => {
    if (props?.hieDataReportUrl) {
      const reportUrl = await getHieDataReportByUrl(props?.hieDataReportUrl);
      setMediaReportDetail((prev: any) => {
        return {
          ...prev,
          source: reportUrl.data,
          loading: false
        };
      });
      return;
    }
    downloadReport(mediaReportDetail.source, mediaReportDetail.reportSource, false, (async response => {
      const file: any = {};
      file.blob = response.data;
      file.blobUrl = URL.createObjectURL(file.blob);
      file.preview = await getBase64(response.data);


      setMediaReportDetail((prev: any) => {
        return {
          ...prev,
          reportData: file.blobUrl,
          loading: false,
          base64Data: file.preview,
        };
      });
    }), (error) => {
      setMediaReportDetail((prev: any) => {
        return {
          ...prev,
          loading: false
        };
      });
    }, props?.reportId, ehrConfig, props?.personData.accountLocationUuid)
  }

  const  getResourceTypeWiseView = (): JSX.Element => {
    // If isFetchMediaFromIntegration is true then directly return iframe element with reportData as source
    if (props?.reportSource === SOURCE_TYPES.HIE) {
      if (props?.htmlDataString && props?.isHtmlFormattedData) {
        return (<Iframe srcDoc={props.htmlDataString} height={finalHeight} width="100%" customStyle={{border: 0 }}></Iframe>)
      } else {
        return (<Iframe source={mediaReportDetail.source} height={finalHeight} width="100%" customStyle={{ border: 0 }}></Iframe>)
      }
    }
    if (props?.isFetchMediaFromIntegration) {
      return (<Iframe source={mediaReportDetail.reportData} height={finalHeight} width="100%" customStyle={{border: 0 }}></Iframe>)
    }
    if (!props?.sourceType || (props?.sourceType && [MIME_TYPES.DOC, MIME_TYPES.DOCX, MIME_TYPES.ODT, MIME_TYPES.PDF].includes(props.sourceType))) {
      return (
        <Iframe source={mediaReportDetail.source} height={finalHeight} width="100%" customStyle={{ border: 0 }}></Iframe>
      );
    } else if (mediaReportDetail.source && isValidHttpUrl(mediaReportDetail.source)) {
      return (<img src={mediaReportDetail.source} style={{maxHeight: finalHeight}} />);
    } else if (props?.sourceType && SUPPORTED_IMAGE_URLS.includes(props?.sourceType)) {
      return (<img src={`data:image/jpeg;base64,${mediaReportDetail.source}`} style={{maxHeight: finalHeight}} />);
    } else {
      return (<></>);
    }
  }

  useEffect(() => {
    if (props?.isFetchMediaFromIntegration || props?.hieDataReportUrl) {
      setMediaReportDetail((prev: any) => {
        return {
          ...prev,
          loading: true
        };
      });
      getReport();
    }
  }, [])

  return (
    !showSummary ?
    <Drawer
      visible={mediaReportDetail.showReportPdf}
      width={props?.isImaging ? '50%': '70%'}
      title={
      <ModalActionTitle
        title={props?.drawerTitle || 'Report'}
        titleColor={''}
        buttonList={[
          {
            show: isDocumentSummaryEnabled,
            id: 1,
            btnText: 'Generate Summary',
            textColor: Colors.Custom.mainSecondaryBrown,
            variant: BUTTON_TYPE.SECONDARY,
            isTransBtn: false,
            onClick: () => {
              generateSummary('false');
            },
          },
          {
            show: true,
            id: 2,
            btnText: 'back',
            textColor: Colors.Custom.mainSecondaryBrown,
            variant: BUTTON_TYPE.SECONDARY,
            isTransBtn: false,
            onClick: () => {
              props.onClose();
            },
          },
        ]}
      />}
      onClose={() => {
        if (typeof props.onClose === 'function') {
          props?.onClose();
        }
      }}>
        {mediaReportDetail.loading && (
          <VStack>
            <Skeleton.Text my={3} />
            <Skeleton />
          </VStack>
        )}
        {!mediaReportDetail?.loading && (getResourceTypeWiseView())}
    </Drawer>:
    <Drawer
      visible={mediaReportDetail.showReportPdf}
      width={props?.isImaging ? '50%': '70%'}
      title={
      <ModalActionTitle
        title={'Summary'}
        titleColor={''}
        buttonList={[
          {
            show: false,
            id: 1,
            btnText: 'Regenerate Summary',
            textColor: Colors.Custom.mainSecondaryBrown,
            variant: BUTTON_TYPE.SECONDARY,
            isTransBtn: false,
            onClick: () => {
              setSummary('')
              generateSummary('true')
            },
          },
          {
            show: true,
            id: 2,
            btnText: 'back',
            textColor: Colors.Custom.mainSecondaryBrown,
            variant: BUTTON_TYPE.SECONDARY,
            isTransBtn: false,
            onClick: () => {
              setShowSummary(false);
            },
          },
        ]}
      />}
      onClose={() => {
        if (typeof props.onClose === 'function') {
          props?.onClose();
        }
      }
    }>
      {summary==='' && (
        <VStack>
          <Skeleton.Text my={3} />
          <Skeleton />
        </VStack>
      )}
      {summary!=='' && (<Text> {summary}</Text>)}
  </Drawer>
  )
}
