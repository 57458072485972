import { View } from 'native-base'
import React, { useEffect, useState } from 'react'
import { ModalActionAntSelect } from '../ModalActionCommonComponent/ModalActionAntSelect';
import { debounce } from 'lodash';
import BaseService from '../../../services/CommonService/BaseService';
import { CodeSearchType } from './CodeSearchConstants';
import { ISearchFieldProps } from '../../../Interfaces';
import { Colors } from '../../../styles';
import { Spin } from 'antd';

export interface ICodeSearchProps extends ISearchFieldProps {
  label: string;
  searchType: CodeSearchType;
  pageSize?: number
  placeholder?: string;
  isRequired?: boolean;
  allowClear?: boolean;
  isDisabled?: boolean;
  isInvalid?: boolean;
  addNewOptionEnabled?: boolean;
  errorMessage?: string;
  additionalHeaders?: {[index: string] : any};
  locationId?: string;
}

export interface ICodeSearchState {
  pageSize: number;
  offset: number;
  loading: boolean;
  searchList: any[];
  originalList: any[];
  searchString: string;
}

const RaceSearch = (props: ICodeSearchProps) => {
  const { value, label, isDisabled, allowClear, pageSize, searchType, addNewOptionEnabled, placeholder, isRequired, isInvalid, errorMessage, allowUserMultiSelect, locationId} = props;
  const MIN_CHAR_FOR_SEARCH = 2;

  const [componentState, setComponentState] = useState<ICodeSearchState>({
    offset: 0,
    pageSize: pageSize || 10,
    loading: false,
    searchList: [],
    originalList: [],
    searchString: ''
  });

  useEffect(() => {
    onSearch('', true);
  }, []);

  const onSearch = (searchString: string, dirty?: boolean) => {
    setComponentState((prev) => ({...prev, searchList: [], originalList: [], searchString: searchString}));
    if (searchString.length < MIN_CHAR_FOR_SEARCH && !dirty) return;
    setComponentState((prev) => ({...prev, loading: true}));
    const {axios} = BaseService.getSharedInstance();
    let path = `/integration/api/codes?limit=${pageSize}&offset=${componentState.offset}&searchType=${searchType}&searchString=${searchString}`;
    if (locationId) {
      path += `&location=${locationId}`;
    }
    axios
      .get(
        path,
        {
          headers: props.additionalHeaders,
        }
      )
      .then((result) => {
        let list: any[] = [];
        if (result?.data?.codeable?.length) {
          list = result.data.codeable;
        } else if (result?.data?.expansion?.contains?.length) {
          list = result.data.expansion.contains;
        }
        setComponentState((prev) => {
          const finalList = list;
          if (addNewOptionEnabled) {
            finalList.push({
              text: searchString.trim(),
              coding: [],
            });
          }
          return {
            ...prev,
            loading: false,
            searchList: finalList,
            originalList: finalList
          }
        });
      })
      .catch((error) => {

        setComponentState((prev) => ({...prev, loading: false}));
      });
  }

  const getDataFromId = (data: any) => {
    if (!data) {
      return;
    }
    const selectedList = Array.isArray(data) ? data : [data];
    const matchedData = selectedList.map((item) => {
      return {
        code: item.key,
        display: item.value,
      }
    });
    return matchedData;
  }

  const getOptionMessage = () => {
    return componentState.searchString.length > MIN_CHAR_FOR_SEARCH ? 'No data found' : `Type at least ${MIN_CHAR_FOR_SEARCH} characters for search`;
  }

  return (
    <View>
      <ModalActionAntSelect
        mode={allowUserMultiSelect ? 'multiple' : undefined}
        disabled={isDisabled}
        label={label}
        style={{width:'100%'}}
        allowClear={allowClear !== undefined ? allowClear : true}
        showSearch={true}
        leftMargin={'0'}
        onSearch={debounce(onSearch, 500)}
        labelInValue={true}
        filterOption={false}
        value={value}
        notFoundContent={componentState.loading ? <Spin size="small" /> : getOptionMessage()}
        isRequired={isRequired}
        isInvalid={isInvalid}
        errors={isInvalid}
        errorText={errorMessage}
        placeholder={placeholder}
        onChange={(value: any[], data: any) => {
          if (data?.value || data?.length) {
            props.onChange(getDataFromId(data));
          } else {
            props.onChange(undefined);
          }
        }}
        data={componentState.searchList}
        optionProps={{key: 'code', value: 'display', label: 'display'}}
        extraStyle={{flex: 1}}
        customStyle={{
          flex: 1,
          fontSize: 15,
          fontWeight: 400,
          labelBottomMargin: 0,
          borderColor: Colors.Custom.Gray300,
        }}
      />
    </View>
  )
}

export default RaceSearch
