import {MAIN_MENU_CODES} from "../../../../../../SideMenuBar/SideBarConst";
import {USER_ACCESS_PERMISSION} from "../../../../../UserAccess/UserAccessPermission";
import {FEED_RESOURCE_TYPE} from "./FeedConst";
import {IFeedData} from "./interface";

export const groupByFeedDataByTimeStamp = (feedDataList: IFeedData[]) => {
  return feedDataList?.reduce((singleData: any, singleFeedDataObj) => {
    const date = singleFeedDataObj.time_stamp;
    const key = new Date(date).toLocaleDateString('en-US', {
      month: 'long',
      year: 'numeric',
    });
    if (!singleData[key]) {
      singleData[key] = [];
    }
    singleData[key].push(singleFeedDataObj);
    return singleData;
  }, {});
}

export const getResourceByDisplayCode = (display_text: string)=> {
  if(display_text === FEED_RESOURCE_TYPE.MESSAGE) {
    return {
      actionEvent: USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
      resourceCode: MAIN_MENU_CODES.INBOX,
    }
  } else if(display_text === FEED_RESOURCE_TYPE.TASK) {
    return {
      actionEvent: USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
      resourceCode: MAIN_MENU_CODES.TASKS,
    }
  } else if(display_text === FEED_RESOURCE_TYPE.APPOINTMENT) {
    return {
      actionEvent: USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
      resourceCode: MAIN_MENU_CODES.CALENDAR,
    }
  } else if([FEED_RESOURCE_TYPE.VIDEO_CALL, FEED_RESOURCE_TYPE.CALL].includes(display_text)) {
    return {
      actionEvent: USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
      resourceCode: MAIN_MENU_CODES.CALL_LOGS,
    }
  } else if(display_text === FEED_RESOURCE_TYPE.FORM_RESPONSE) {
    return {
      actionEvent: USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
      resourceCode: MAIN_MENU_CODES.FORMS,
    }
  }
}
