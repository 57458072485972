import {useLazyQuery} from '@apollo/client';
import {Popover, notification} from 'antd';
import moment from 'moment';
import {FlatList, Pressable, Skeleton, Text} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {Platform, SafeAreaView, View} from 'react-native';
import {
  COMMON_ACTION_CODES,
  CONVERSATION_ACTION_CODES,
  GROUP_ACTION_CODES,
} from '../../../../constants/ActionConst';
import {GROUP_TYPE_CODES, MLOV_CATEGORY} from '../../../../constants/MlovConst';
import {
  BUTTON_TYPE,
  CONSENT_ERROR,
  DATE_FORMATS,
  GROUP_MEMBER_TYPE,
  MODULE_PAGINATION_COUNT,
  PARENT_CODE,
} from '../../../../constants/StringConst';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import GroupQueriesV2 from '../../../../services/Conversations/V2/GroupQueriesV2';
import {Colors} from '../../../../styles/Colors';
import {getAccountId, getUserId, getUserUUID, isConsentRequiredAndGiven} from '../../../../utils/commonUtils';
import {getMessageDisplayTimeAgoFullDate, isTodayDate, isYesterdayDate} from '../../../../utils/DateUtils';
import {
  getMlovCodeIdObj,
  getMlovListFromCategory,
} from '../../../../utils/mlovUtils';
import {isWeb} from '../../../../utils/platformCheckUtils';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';
import CallLogEntry from '../../../RightSideContainer/TeamInbox/CallLogs/CallLogEntry';
import CreateSmsConversationDrawer from '../../../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/CreateSmsConversationDrawer/CreateSmsConversationDrawer';
import {
  CHANNEL_TYPE_CODE,
  CONVERSATION_TAB_CODES,
  PATIENT_PROFILE_COMMUNICATION_TAB_CODES,
} from '../../../RightSideContainer/TeamInbox/Conversations/ConversationConst';
import {TimeType} from '../../../RightSideContainer/TeamInbox/Conversations/ConversationContainer/ConversationConst';
import {renderConversationFooter} from '../../../RightSideContainer/TeamInbox/Conversations/ConversationContainer/ConversationList/ConversationListComponents';
import {ConversationListItem} from '../../../RightSideContainer/TeamInbox/Conversations/ConversationContainer/ConversationList/ConversationListItem';
import CreateAllGroupPopup from '../../../RightSideContainer/TeamInbox/Conversations/ConversationContainer/CreateGroupPopup/CreateAllGroupPopup';
import {
  IContact,
  IConversationContactTimelineResponse,
  IConversationData,
  IConversationMessageGroups,
} from '../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import CreateChannelView from '../../CreateChannelView/CreateChannelView';
import {DisplayText} from '../../DisplayText/DisplayText';
import NoDataFound from '../../NoDataFound/NoDataFound';
import {IGroupListByMember} from '../interface';
import {EventBus} from '../../../../utils/EventBus';
import {isAccountConfigEnabled} from '../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../constants/AccountConfigConst';
import InstantChat from '../../ChatDrawer/InstantChat';
import {EmailDrawerCommonV2} from '../../EmailDrawerCommonV2';
import Stack from '../../LayoutComponents/Stack';
import {NoChatFound} from '../../../SideCar/assets/images/NoChatFound';
import {CALL_ONLY_MESSAGE_TYPE_NUM} from '../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingCustomCard/CustomCardConst';
import { CONVERSATION_LOCAL_EVENT_CODES } from '../../../../constants/WebSocketConst';
import GroupQueries from '../../../../services/Conversations/GroupQueries';
import ConversationsQueries from '../../../../services/Conversations/ConversationsQueries';
import {ISipNumberList, IUserList} from '../../../RightSideContainer/TeamInbox/CallLogs/interface';
import {getSipPhoneCredentialList} from '../../../../services/CloudTelephony/CloudTelephonyQueries';

const GroupListByMemberV2 = (props: IGroupListByMember) => {
  const {groupMemberData, onGroupListActionPerformed, onSelect, parentCode, selectedConversation, updateCommunicationList} = props;
  const userId = getUserId();
  const userUuidId = getUserUUID();
  const accountId = getAccountId();
  const commonData = useContext(CommonDataContext);
  const groupTypeList = getMlovListFromCategory(
    commonData.MLOV,
    MLOV_CATEGORY.GroupType
  );
  const contactData = props?.contactData || ({} as any);
  const consentCheck = isConsentRequiredAndGiven(contactData);
  const groupTypeCodeIdObj = getMlovCodeIdObj(groupTypeList);
  const isPatientMessagingEnabled = isAccountConfigEnabled(CONFIG_CODES.PATIENT_MESSAGING_ENABLED);
  const [conversationChannelTabState, setConversationChannelTabState] =
    useState<any>({
      isLoading: false,
      visibleDrawerCode: '',
      filterModal: false as boolean,
      openCount: 0,
      displayCreateChatPopup: false,
      isNewChatDrawerVisible: false,
      groupMemberContactData: {} as IContact,
    });
  const [groupListByMemberState, setGroupListByMemberState] = useState({
    isLoading: true,
    isFetchMore: false,
    showFetchMore: true,
    selectedGroup: {} as IConversationData,
    conversationAndCallList: [] as IConversationMessageGroups[],
    page: 1 as number,
    monthlyConv: {} as {[index: string]: IConversationMessageGroups[]},
  });
  const [stateData, setStateData] = useState({
    userList: [] as IUserList[],
    sipNumberList: [] as ISipNumberList[],
    userAndSipNumberLoading: false,
  })
  const isSidecarContext = commonData.sidecarContext?.isSidecar;
  const [getConversationsAndCallDataFromConversationMessageGroup] =
    useLazyQuery<IConversationContactTimelineResponse>(GroupQueriesV2.GetAllConversationWithCallMessages, {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    });

  const hideNewConversationButton =
    !isPatientMessagingEnabled &&
      !contactData.email?.length &&
      !contactData.phoneNumber?.length
      ? true
      : false;

  const [GetSelectedConversationData] = useLazyQuery(
    GroupQueries.GetSelectedConversationData,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const getGroupList = (offset: number) => {
    const variableQueryObj = {
      limit: MODULE_PAGINATION_COUNT.PATIENT_QUICK_VIEW_CONVERSATION_PAGE_SIZE,
      offset: offset || 0,
      loginUserId: userId,
      loginUserUuid: userUuidId,
      whereCondition: {
        accountId: {
          _eq: accountId,
        },
        _or: [
          {
            inboxId: {
              _neq: -1,
            },
            contactInbox: {
              contact: {
                uuid: {
                  _eq: groupMemberData?.groupUserId,
                },
              },
            },
          },
        ],
      }
    }
    if (isPatientMessagingEnabled) {
      variableQueryObj.whereCondition._or.push({
        groupConversation: {
          groupMembers: {
            groupUserId: {
              _eq: groupMemberData?.groupUserId,
            },
            groupUserType: {
              code: {
                _eq: GROUP_MEMBER_TYPE.PATIENT,
              },
            },
          },
          groupType: {
            _or: [
              {
                id: {
                  _eq: groupTypeCodeIdObj[GROUP_TYPE_CODES.PRIVATE],
                },
              },
            ],
          },
        },
      } as any)
    }
    return getConversationsAndCallDataFromConversationMessageGroup({
      variables: {...variableQueryObj}
    })
  };

  const getSingleGroupConversation = async (conversationUuid: string) => {
    setGroupListByMemberState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    const groupResp = await getConversationsAndCallDataFromConversationMessageGroup({
      variables: {
        limit: 1,
        offset: 0,
        loginUserId: userId,
        loginUserUuid: userUuidId,
        whereCondition: {
          accountId: {
            _eq: accountId,
          },
          uuid: {
            _eq: conversationUuid,
          },
        },
      },
    }).catch(() => {
      emptyRespHandler();
    });
    const conversationAndCallList = groupResp?.data?.conversationMessageGroups || [];
    if (conversationAndCallList?.length) {
      const finalConversationAndCallList: IConversationMessageGroups[] = getUpdatedConversationAndCallDataList(conversationAndCallList);
      setGroupListByMemberState((prev) => {
        return {
          ...prev,
          isLoading: false,
          conversationAndCallList: [...finalConversationAndCallList],
        };
      });
      if (finalConversationAndCallList[0]?.messageConversation?.id) {
        if (parentCode === PARENT_CODE.PATIENT_INFO_LIST_VIEW) {
          onGroupListActionPerformed?.(COMMON_ACTION_CODES.ITEM_CLICKED, {
            selectedConversation: finalConversationAndCallList[0]?.messageConversation,
          });
        } else if (parentCode === PARENT_CODE.MESSAGING_DETAIL_PANEL) {
          onSelect && onSelect(finalConversationAndCallList[0]?.messageConversation);
        }
      }
    }
  };

  const emptyRespHandler = () => {
    setGroupListByMemberState((prev) => {
      return {
        ...prev,
        isLoading: false,
        conversationAndCallList: [],
      };
    });
  };

  const updateFetchMore = (messageConversationDataList: IConversationMessageGroups[]) => {
    if ((messageConversationDataList?.length === 0) || messageConversationDataList?.length < MODULE_PAGINATION_COUNT.PATIENT_QUICK_VIEW_CONVERSATION_PAGE_SIZE) {
      setGroupListByMemberState((prev) => {
        return {
          ...prev,
          isFetchMore: false,
          showFetchMore: false,
        };
      });
    } else {
      setGroupListByMemberState((prev) => {
        return {
          ...prev,
          isFetchMore: false,
        };
      });
    }
  };

  const loadMoreConversations = async (): Promise<void> => {
    setGroupListByMemberState((prev) => {
      return {
        ...prev,
        isFetchMore: true,
      };
    });
    const moreMessagesRes = await getGroupList(groupListByMemberState?.conversationAndCallList?.length);
    const conversationAndCallList = moreMessagesRes?.data?.conversationMessageGroups || [];
    if (conversationAndCallList?.length) {
      const finalConversationAndCallList: IConversationMessageGroups[] = getUpdatedConversationAndCallDataList(conversationAndCallList);
      setGroupListByMemberState((prev) => {
        return {
          ...prev,
          isLoading: false,
          page: groupListByMemberState.page + 1,
          conversationAndCallList: [...groupListByMemberState.conversationAndCallList, ...finalConversationAndCallList],
        };
      });
    }
    updateFetchMore(conversationAndCallList);
  };

  useEffect(() => {
    getConversationAndCallData();
  }, [groupMemberData.id, parentCode, contactData?.id, updateCommunicationList]);

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.removeEventListener(updateSelectedConversationData);
    eventBus.addEventListener(
      CONVERSATION_ACTION_CODES.MESSAGE_READ_FROM_NOTIFICATION,
      onMessageReadFromNotification
    );
    eventBus.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAl_UPDATE_SELECTED_CONVERSATION,
      updateSelectedConversationData
    );

    return () => {
      eventBus.removeEventListener(onMessageReadFromNotification);
      eventBus.removeEventListener(updateSelectedConversationData);
    };
  }, []);

  useEffect(() => {
    const monthlyConv: {[index: string]: IConversationMessageGroups[]} = {};
    groupListByMemberState.conversationAndCallList?.forEach?.((value: IConversationMessageGroups, index: number) => {
      const lastActivityDate = getLastActivityAtDate(value);
      const month = moment(lastActivityDate).format(
        DATE_FORMATS.PATIENT_PROFILE_COMMUNICATION_TAB_DATE_FORMAT
      );
      if (isTodayDate(lastActivityDate)) {
        if (monthlyConv[PATIENT_PROFILE_COMMUNICATION_TAB_CODES.TODAY]) {
          monthlyConv[PATIENT_PROFILE_COMMUNICATION_TAB_CODES.TODAY]?.push(
            value
          );
        } else {
          monthlyConv[PATIENT_PROFILE_COMMUNICATION_TAB_CODES.TODAY] = [value];
        }
      } else if (isYesterdayDate(lastActivityDate)) {
        if (monthlyConv[PATIENT_PROFILE_COMMUNICATION_TAB_CODES.YESTERDAY]) {
          monthlyConv[PATIENT_PROFILE_COMMUNICATION_TAB_CODES.YESTERDAY]?.push(
            value
          );
        } else {
          monthlyConv[PATIENT_PROFILE_COMMUNICATION_TAB_CODES.YESTERDAY] = [
            value,
          ];
        }
      } else if (
        month === PATIENT_PROFILE_COMMUNICATION_TAB_CODES.JAN_1970 &&
        value?.messageConversation?.workflowCommunicationLogResponseId
      ) {
        if (monthlyConv[PATIENT_PROFILE_COMMUNICATION_TAB_CODES.AUTOMATED]) {
          monthlyConv[PATIENT_PROFILE_COMMUNICATION_TAB_CODES.AUTOMATED]?.push(
            value
          );
        } else {
          monthlyConv[PATIENT_PROFILE_COMMUNICATION_TAB_CODES.AUTOMATED] = [
            value,
          ];
        }
      } else if (month === PATIENT_PROFILE_COMMUNICATION_TAB_CODES.JAN_1970) {
        if (monthlyConv[PATIENT_PROFILE_COMMUNICATION_TAB_CODES.OLD]) {
          monthlyConv[PATIENT_PROFILE_COMMUNICATION_TAB_CODES.OLD]?.push(value);
        } else {
          monthlyConv[PATIENT_PROFILE_COMMUNICATION_TAB_CODES.OLD] = [value];
        }
      } else if (monthlyConv[month]) {
        monthlyConv[month]?.push(value);
      } else {
        monthlyConv[month] = [value];
      }
    });
    setGroupListByMemberState((prev) => {
      return {
        ...prev,
        monthlyConv: monthlyConv,
      };
    });
  }, [groupListByMemberState.conversationAndCallList]);

  const onMessageReadFromNotification = (actionData: any) => {
    let selectedGroupUuid = '';
    let groupList = [] as IConversationMessageGroups[];
    setGroupListByMemberState((prev) => {
      selectedGroupUuid = prev.selectedGroup?.uuid || '';
      groupList = prev.conversationAndCallList || [];
      if (
        selectedGroupUuid === actionData?.conversation
      ) {
        const conversationIndex = groupList.findIndex(
          (singleConversationOrCallData) =>
            singleConversationOrCallData?.messageConversation?.uuid === actionData?.conversation
        );
        const conversationData = groupList[conversationIndex].messageConversation;
        if (conversationIndex !== -1 && conversationData?.id) {
          conversationData.unreadMsgCount = 0;
          conversationData.unreadMessages = [];
          groupList[conversationIndex].messageConversation = conversationData;
          setGroupListByMemberState((prev) => {
            return {
              ...prev,
              conversationAndCallList: [],
            };
          });

        }
      }
      return {
        ...prev,
      }
    });
  };

  const updateSelectedConversationData = async (actionData: any) => {
    try {
      let groupList = [] as IConversationMessageGroups[];
      setGroupListByMemberState((prev) => {
        groupList = prev.conversationAndCallList || [];
        return {
          ...prev,
          isLoading: true,
        };
      });
      const updatedConversationList = [...groupList];
      const conversationIndex = groupList.findIndex(
        (conversation: any) =>
          conversation?.messageConversation?.uuid === actionData?.conversation
      );
      const updatedConversation = {
        ...updatedConversationList[conversationIndex],
      };
      const finalUpdatedConversation =
        await handleUpdateSelectedConversationData(
          updatedConversation,
          actionData?.conversation
        );
      updatedConversationList[conversationIndex] = finalUpdatedConversation;
      setGroupListByMemberState((prev) => {
        return {
          ...prev,
          isLoading: false,
          conversationAndCallList: updatedConversationList,
        };
      });
    } catch (e) {
      setGroupListByMemberState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    }
  };

  const handleUpdateSelectedConversationData = async (
    updatedConversation: any,
    conversationUuid: string
  ) => {
    const updatedConversationData = await GetSelectedConversationData({
      variables: {
        conversationUuid: conversationUuid,
      },
    });
    if (updatedConversationData?.data?.conversations?.length) {
      updatedConversation.messageConversation.assigneeId =
        updatedConversationData?.data?.conversations?.[0]?.assigneeId;
      updatedConversation.messageConversation.status =
        updatedConversationData?.data?.conversations?.[0]?.status;
      updatedConversation.messageConversation.assignedUser =
        updatedConversationData?.data?.conversations?.[0]?.assignedUser;
      updatedConversation.messageConversation.conversationMessages =
        updatedConversationData?.data?.conversations?.[0]?.conversationMessages;
      updatedConversation.messageConversation.updatedAt =
        updatedConversationData?.data?.conversations?.[0]?.updatedAt;
      updatedConversation.messageConversation.createdAt =
        updatedConversationData?.data?.conversations?.[0]?.createdAt;
      if (updatedConversation?.messageConversation?.groupConversation?.id) {
        updatedConversation.messageConversation.groupConversation.name =
          updatedConversationData?.data?.conversations?.[0]?.groupConversation?.name;
        updatedConversation.messageConversation.groupConversation.groupMembers_aggregate =
          updatedConversationData?.data?.conversations?.[0]?.groupConversation?.groupMembers?.length;
        updatedConversation.messageConversation.groupConversation.groupMembers =
          updatedConversationData?.data?.conversations?.[0]?.groupConversation?.groupMembers;
      }
    }
    updatedConversation.messageConversation.unreadMessages = [];
    updatedConversation.messageConversation.unreadMsgCount = 0;
    return updatedConversation;
  };

  const getLastActivityAtDate = (conversationOrCallData: IConversationMessageGroups) => {
    const messageCreatedAt = conversationOrCallData?.message?.createdAt || new Date().toISOString();
    return messageCreatedAt;
  }
  const getUpdatedConversationAndCallDataList = (conversationAndCallList: IConversationMessageGroups[]) => {
    const finalConversationAndCallList: IConversationMessageGroups[] = [];
    conversationAndCallList?.forEach((singleData) =>{
      if (singleData?.messageConversation?.id) {
        const conversationData = singleData?.messageConversation;
        if (singleData?.message?.id) {
          conversationData.conversationMessages = [singleData?.message];
        }
      } else if (singleData?.message?.id  && singleData?.message?.messageType && CALL_ONLY_MESSAGE_TYPE_NUM.includes(singleData?.message?.messageType))  {
        try {
          if (singleData.message?.contentAttributes && typeof singleData?.message?.contentAttributes === 'object' && Object.keys(singleData?.message?.contentAttributes)?.length) {
            singleData.message.callLogData = singleData.message?.contentAttributes;
          } else if (singleData.message?.content) {
            singleData.message.callLogData = JSON.parse(singleData.message?.content);
          }
        } catch (error) {
        }
      }
      finalConversationAndCallList.push(singleData);
    });
    return finalConversationAndCallList;
  }
  const getConversationAndCallData = () => {
    setGroupListByMemberState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });


    getGroupList(0).then((responses) => {
      const conversationAndCallList = responses?.data?.conversationMessageGroups || [];
      if (conversationAndCallList?.length) {
        const finalConversationAndCallList: IConversationMessageGroups[] = getUpdatedConversationAndCallDataList(conversationAndCallList);
        if (props.onGroupConversationListLoad) {
          props.onGroupConversationListLoad(conversationAndCallList || []);
        }
        if (conversationAndCallList?.length) {
          setGroupListByMemberState((prev) => {
            return {
              ...prev,
              isLoading: false,
              page: groupListByMemberState.page + 1,
              conversationAndCallList: [...finalConversationAndCallList],
            };
          });
        } else {
          setGroupListByMemberState((prev) => {
            return {
              ...prev,
              isLoading: false,
            };
          });
        }
      } else {
        setGroupListByMemberState((prev) => {
          return {
            ...prev,
            isLoading: false,
          };
        });
      }
    }).catch(() => {
      setGroupListByMemberState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    });
  }



  const onCreateGroupPopupActionPerformed = (
    actionCode: string,
    actionData?: any
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.CLOSE_POPUP: {
        setConversationChannelTabState((prev: any) => {
          return {
            ...prev,
            displayCreateChatPopup: false,
          };
        });

        break;
      }
      case CONVERSATION_ACTION_CODES.ON_PATIENT_SELECTION: {
        setConversationChannelTabState((prev: any) => {
          return {
            ...prev,
            displayCreateChatPopup: false,
          };
        });
        if (actionData) {
          getSingleGroupConversation(actionData?.conversationUuid);
        }
        break;
      }
      case CONVERSATION_ACTION_CODES.START_GROUP_CHAT:
        setConversationChannelTabState((prev: any) => {
          return {
            ...prev,
            displayCreateChatPopup: false,
            isCreateChannelViewDisplay: true,
            visibleDrawerCode: CONVERSATION_ACTION_CODES.START_GROUP_CHAT,
          };
        });
        break;
      case CONVERSATION_ACTION_CODES.START_SMS:
        setConversationChannelTabState((prev: any) => {
          return {
            ...prev,
            displayCreateChatPopup: false,
            visibleDrawerCode: CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS,
          };
        });
        break;
      case CONVERSATION_ACTION_CODES.START_EMAIL:
        if (consentCheck) {
          setConversationChannelTabState((prev: any) => {
            return {
              ...prev,
              displayCreateChatPopup: false,
              visibleDrawerCode: CHANNEL_TYPE_CODE.CHANNEL_EMAIL,
            };
          });
        } else {
          onActionPerformed(COMMON_ACTION_CODES.CONSENT_WARNING)
        }

        break;
      case CONVERSATION_ACTION_CODES.CHAT_WITH_INTERNAL_USERS:
        setConversationChannelTabState((prev: any) => {
          return {
            ...prev,
            displayCreateChatPopup: false,
            visibleDrawerCode: CONVERSATION_ACTION_CODES.START_GROUP_CHAT,
          };
        });
        break;
    }
  };

  const isCreateConversationDrawerVisible = (channelCode: string) => {
    return channelCode === conversationChannelTabState.visibleDrawerCode;
  };

  const onCreateSmsConversationActionPerformed = (
    actionCode: string,
    actionData?: any
  ) => {
    switch (actionCode) {
      case CONVERSATION_ACTION_CODES.DRAWER_CLOSE:
      case COMMON_ACTION_CODES.RESET:
        setConversationChannelTabState((prev: any) => {
          return {
            ...prev,
            isCreateChannelViewDisplay: false,
            visibleDrawerCode: '',
          };
        });
        getConversationAndCallData();

        break;
      case GROUP_ACTION_CODES.GROUP_CREATE:
        {
          setConversationChannelTabState((prev: any) => {
            return {
              ...prev,
              isCreateChannelViewDisplay: false,
              visibleDrawerCode: '',
            };
          });
        }
        break;
      case COMMON_ACTION_CODES.RESET: {
        setConversationChannelTabState((prev: any) => {
          return {
            ...prev,
            displayCreateChatPopup: false,
            visibleDrawerCode: ''
          };
        });

        break;
      }
    }
  };

  const getCreateChannelTabCode = () => {
    if (
      conversationChannelTabState.selectedTabCode ===
      CHANNEL_TYPE_CODE.CHANNEL_INTERNAL
    ) {
      return CONVERSATION_TAB_CODES.INTERNAL;
    } else {
      return CONVERSATION_TAB_CODES.PRIVATE;
    }
  };

  const onCreateChannelActionPerformed = (
    actionCode: string,
    actionData?: any
  ) => {
    switch (actionCode) {
      case CONVERSATION_ACTION_CODES.DRAWER_CLOSE:
        setConversationChannelTabState((prev: any) => {
          return {
            ...prev,
            isCreateChannelViewDisplay: false,
            visibleDrawerCode: '',
          };
        });
        break;
      case GROUP_ACTION_CODES.GROUP_CREATE:
        setConversationChannelTabState((prev: any) => {
          return {
            ...prev,
            isCreateChannelViewDisplay: false,
            visibleDrawerCode: '',
          };
        });
        break;
    }
  };

  const showCreateGroupPopup = (e: any) => {
    setConversationChannelTabState((prev: any) => {
      return {
        ...prev,
        displayCreateChatPopup: e,
        openCount: prev.openCount + 1,
      };
    });
  };

  const getNewConversationActionCodes = () => {
    const conversationActionCodeList = [];
    if (contactData?.patient?.id || contactData?.patient?.uuid) {
      conversationActionCodeList.push(
        CONVERSATION_ACTION_CODES.CHAT_WITH_PATIENT
      );
      conversationActionCodeList.push(
        CONVERSATION_ACTION_CODES.START_GROUP_CHAT
      );
    }

    if (contactData?.email) {
      conversationActionCodeList.push(CONVERSATION_ACTION_CODES.START_EMAIL);
    }

    if (contactData?.phoneNumber) {
      conversationActionCodeList.push(CONVERSATION_ACTION_CODES.START_SMS);
    }
    return conversationActionCodeList;
  };

  const onActionPerformed = (actionCode: string, actionData?: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.CONSENT_WARNING:
        const message = CONSENT_ERROR;
        notification.error({
          message,
        });
        break;
    }
  };

  const getCallLogItem = (item: IConversationMessageGroups) => {
    if (Platform.OS === 'web') {
      return (
        <CallLogEntry
          callLog={item?.message?.callLogData || {}}
          displayTime={getMessageDisplayTimeAgoFullDate(item?.message?.callLogData?.dateTime)}
          isOpenOutgoingCall={true}
          contactData={contactData}
          onActionPerformed={onActionPerformed}
          userList={stateData?.userList}
          sipNumberList={stateData?.sipNumberList}
        />)
    } else {
      return (
        <Pressable
          onPress={() => {
            onGroupListActionPerformed?.(
              COMMON_ACTION_CODES.ITEM_CLICKED,
              {
                callDetails: item?.message?.callLogData,
              }
            );
          }}
        >
          <CallLogEntry
            callLog={item?.message?.callLogData || {}}
            displayTime={getMessageDisplayTimeAgoFullDate(item?.message?.callLogData?.dateTime)}
            isOpenOutgoingCall={false}
            contactData={contactData}
            userList={stateData?.userList}
            sipNumberList={stateData?.sipNumberList}
          />
        </Pressable>
      )
    }

  }

  return (
    <View>
      <SafeAreaView style={{height: '80%', marginBottom: 40}}>
        {groupListByMemberState?.isLoading ? (
          <Skeleton.Text lines={6} />
        ) :
          groupListByMemberState?.conversationAndCallList?.length > 0 ? (
            Object.keys(groupListByMemberState.monthlyConv)?.map((key: string, index: number) => {
              return (
                <View key={`${key}_${index}`}>
                  <Text
                    style={{
                      marginBottom: 8,
                      marginTop: 16,
                      marginLeft: isSidecarContext ? 16 : 0
                    }}
                    size="mdBold"
                    fontSize={16}
                    color={'#000000'}
                  >
                    {key}
                  </Text>
                  <FlatList
                    data={groupListByMemberState.monthlyConv[key]}
                    renderItem={({item, index}: {
                      item: IConversationMessageGroups;
                      index: number;
                    }) => {
                      const itemElem = (
                        <>
                          {item.messageConversation?.id ?
                            <Pressable
                              onPress={() => {
                                if (
                                  parentCode === PARENT_CODE.PATIENT_INFO_LIST_VIEW
                                ) {
                                  onGroupListActionPerformed?.(
                                    COMMON_ACTION_CODES.ITEM_CLICKED,
                                    {
                                      selectedConversation: item.messageConversation,
                                    }
                                  );
                                  setGroupListByMemberState((prev) => {
                                    return {
                                      ...prev,
                                      selectedGroup: item?.messageConversation || {} as any,
                                    };
                                  });
                                } else if (
                                  parentCode === PARENT_CODE.MESSAGING_DETAIL_PANEL
                                ) {
                                  if (item?.messageConversation?.id) {
                                    setGroupListByMemberState((prev) => {
                                      return {
                                        ...prev,
                                        selectedGroup: item?.messageConversation || {} as any,
                                      };
                                    });
                                    onSelect && onSelect(item?.messageConversation);
                                  }
                                }
                              }}
                              backgroundColor={"#ffffff"}
                              style={{
                                borderRadius: selectedConversation?.id === item.messageConversation?.id ? 0 : 8,
                                marginTop: 2,
                                backgroundColor: selectedConversation?.id === item.messageConversation?.id ? Colors.Custom.Gray100 : "#ffffff"
                              }}
                              key={item.messageConversation?.uuid + '_' + index}
                            >
                              <ConversationListItem
                                index={index}
                                singleConversationData={item.messageConversation || {}}
                                searchString={''}
                                selectedConversation={
                                  groupListByMemberState.selectedGroup
                                }
                                parentCode={parentCode}
                                displayTimeType={TimeType.timeAgoFullDate}
                              />
                            </Pressable>
                            :
                            <View
                              style={{
                                overflow: 'hidden',
                                marginTop: 2,
                                borderRadius: 16,
                                borderWidth: 0,
                                borderColor: Colors.Custom.Gray200,
                                backgroundColor: '#ffffff'
                              }}
                              key={item?.message?.uuid + '_' + index}
                            >
                              {getCallLogItem(item)}
                            </View>
                          }
                        </>
                      );
                      return itemElem;
                    }}
                    keyExtractor={(item: IConversationMessageGroups) => {
                      return 'ConversationList_' + item?.message?.uuid + + '_' + new Date();
                    }}
                  />
                </View>
              );
            })
          ) : (
            <>
              {isWeb() ? (
                <View
                  style={[
                    {
                      backgroundColor: '#ffffff',
                      borderRadius: 8,
                      // @ts-expect-error: Let's ignore a compile error like this unreachable code
                      boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.06)`,
                      paddingHorizontal: 16,
                      paddingVertical: 16,
                      ...(isSidecarContext && {
                        height: 600,
                        justifyContent: 'center',
                      })
                    },
                  ]}
                >
                  <View style={{marginBottom: 30}}>
                    {isSidecarContext ? (
                      <Stack
                        direction="column"
                        style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: 600,
                        }}
                      >
                        <NoChatFound />
                        <DisplayText
                          textLocalId={'noChatFound'}
                          extraStyles={{
                            color: Colors.Custom.Gray400,
                            fontSize: 16,
                            width: '70%',
                            textAlign: 'center',
                          }}
                        />
                      </Stack>
                    ) : (
                      <NoDataFound displayString="noChatFound" />
                    )}
                  </View>
                  {!hideNewConversationButton && !isSidecarContext && (
                    <Popover
                      style={{
                        width: '200px'
                      }}
                      className={'create-chat'}
                      onVisibleChange={showCreateGroupPopup}
                      trigger="click"
                      visible={conversationChannelTabState.displayCreateChatPopup}
                      content={
                        <CreateAllGroupPopup
                          selectedTabCode={CONVERSATION_TAB_CODES.PATIENTS}
                          openCount={conversationChannelTabState.openCount}
                          onCreateGroupPopupActionPerformed={
                            onCreateGroupPopupActionPerformed
                          }
                          displayTabCodes={getNewConversationActionCodes()}
                          contactData={contactData}
                        />
                      }
                    >
                      <FoldButton
                        nativeProps={{
                          variant: BUTTON_TYPE.PRIMARY,
                          onPress: () => {
                            setConversationChannelTabState((prev: any) => {
                              return {
                                ...prev,
                                displayCreateChatPopup: true,
                              };
                            });
                          },
                        }}
                        customProps={{
                          btnText: (
                            <DisplayText
                              textLocalId={'newConversations'}
                              size={'smBold'}
                              extraStyles={{
                                color: Colors.Custom.mainPrimaryPurple,
                              }}
                            />
                          ),
                          withRightBorder: false,
                        }}
                      ></FoldButton>
                    </Popover>
                  )}
                  {!hideNewConversationButton && contactData?.additionalAttributes?.loggedInTime && isSidecarContext && (
                    <FoldButton
                      nativeProps={{
                        variant: BUTTON_TYPE.PRIMARY,
                        onPress: () => {
                          setConversationChannelTabState((prev: any) => {
                            return {
                              ...prev,
                              isNewChatDrawerVisible: true,
                            };
                          });
                        },
                        isDisabled: contactData?.additionalAttributes?.loggedInTime ? false : true
                      }}
                      customProps={{
                        btnText: (
                          <DisplayText
                            textLocalId={'newConversations'}
                            size={'smBold'}
                            extraStyles={{
                              color: Colors.Custom.mainPrimaryPurple,
                            }}
                          />
                        ),
                        withRightBorder: false,
                      }}
                    ></FoldButton>
                  )}
                  {conversationChannelTabState.isNewChatDrawerVisible && (
                    <InstantChat
                      contactData={contactData}
                      contactUuid={contactData?.uuid}
                      isDrawerVisible={
                        conversationChannelTabState.isNewChatDrawerVisible
                      }
                      onActionPerformed={(actionCode) => {
                        if (actionCode === COMMON_ACTION_CODES.CLOSE_MODAL) {
                          setConversationChannelTabState((prev: any) => {
                            return {
                              ...prev,
                              isNewChatDrawerVisible: false,
                            };
                          });
                          getConversationAndCallData();
                        }
                      }}
                    />
                  )}

                  <CreateSmsConversationDrawer
                    isDrawerVisible={isCreateConversationDrawerVisible(
                      CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS
                    )}
                    selectedInbox={conversationChannelTabState.selectedInbox}
                    onCreateSmsConversationActionPerformed={
                      onCreateSmsConversationActionPerformed
                    }
                    selectedPatient={contactData}
                  />
                  {isCreateConversationDrawerVisible(
                    CHANNEL_TYPE_CODE.CHANNEL_EMAIL
                  ) && (
                      <EmailDrawerCommonV2
                        contactIds={[contactData.id]}
                        isOpen={isCreateConversationDrawerVisible(
                          CHANNEL_TYPE_CODE.CHANNEL_EMAIL
                        )}
                        onClose={() => {
                          onCreateSmsConversationActionPerformed(
                            COMMON_ACTION_CODES.RESET,
                            {}
                          );
                        }}
                        onEmailSent={() => {
                          onCreateSmsConversationActionPerformed(
                            COMMON_ACTION_CODES.RESET,
                            {}
                          );
                        }}
                      />
                    )}
                  <CreateChannelView
                    isDrawerVisible={isCreateConversationDrawerVisible(
                      CONVERSATION_ACTION_CODES.START_GROUP_CHAT
                    )}
                    onCreateChannelActionPerformed={
                      onCreateChannelActionPerformed
                    }
                    selectedTabCode={getCreateChannelTabCode()}
                    contactData={contactData}
                  />
                </View>
              ) : (
                <View style={{marginBottom: 30}}>
                  <NoDataFound displayString="noConversations" />
                </View>
              )}
            </>
          )}
        {groupListByMemberState?.conversationAndCallList?.length > 0 && (
          renderConversationFooter(
            groupListByMemberState.showFetchMore,
            groupListByMemberState.isFetchMore,
            groupListByMemberState.conversationAndCallList?.length,
            loadMoreConversations,
            false
          )
        )}
      </SafeAreaView>
    </View>
  );
};

export default GroupListByMemberV2;
