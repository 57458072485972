import {IFrequencyObjectState} from './InterventionRecurrence';

export function isFrequencyValueValid(frequency: any) {
  return frequency && frequency > 0;
}

export function isPeriodValueValid(period: any) {
  return period && period > 0;
}

export function isPeriodUnitValueValid(periodUnit: any) {
  return !!periodUnit;
}

export function isFormValid(frequencyObject: IFrequencyObjectState) {
  const isFrequencyCodeValid = frequencyObject.code.isValid;
  const isFrequencyValid = frequencyObject.frequency.isValid;
  const isPeriodValid = frequencyObject.period.isValid;
  const isPeriodUnitValid = frequencyObject.periodUnit.isValid;

  return isFrequencyValid && isPeriodValid && isPeriodUnitValid;
}
