import React from 'react';
import {Pressable, View} from 'native-base';
import FeedDataCollapse from '../FeedCollapse/FeedDataCollapse';
import {IFeedData} from '../interface';
import {Colors} from '../../../../../../../../styles';
import {FEED_CLICKABLE_RESOURCE_TYPE_LIST} from '../FeedConst';
import {getResourceByDisplayCode} from '../ActivityUtils';
import {getCaslAbility} from '../../../../../../../../utils/commonUtils';

import FoldPermitCan from '../../../../../../../CommonComponents/FoldPermitCan/FoldPermitCan';



const SingleTimelineCard = (props: {
  singleFeedData: IFeedData,
  firstEntryID: string,
  onHeaderClick: (actionCode: string, singleFeedData: IFeedData) => void;
}) => {
  
  const {singleFeedData,firstEntryID, onHeaderClick} = props;
  const getResourceByDisplayType  = getResourceByDisplayCode(singleFeedData.display_type)
  return singleFeedData ? (
    <View>
      {singleFeedData?.id && (
        <View>
          {getResourceByDisplayType?.resourceCode && <FoldPermitCan
            key={singleFeedData.patient_id}
            action={getResourceByDisplayType?.actionEvent}
            resource={getResourceByDisplayType?.resourceCode}
            component={<FeedDataCollapse
              singleFeedData={singleFeedData}
              firstEntryID={firstEntryID}
              onHeaderClick={(actionCode, singleFeedData) => {
                onHeaderClick(actionCode, singleFeedData);
              }}
            />} />}
          {
            !getResourceByDisplayType?.resourceCode && <FeedDataCollapse
            singleFeedData={singleFeedData}
            firstEntryID={firstEntryID}
            onHeaderClick={(actionCode, singleFeedData) => {
              onHeaderClick(actionCode, singleFeedData);
            }}
          />
          }
        </View>
      )}
    </View>
  ) : (
    <></>
  );
};

export default React.memo(SingleTimelineCard);
