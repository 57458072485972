import {CampaignSegmentTableContext, ICampaignErrors, ICampaignSegmentFormState} from '../interfaces';

export enum FilterType {
  NONE = '',
  SELECT_BY_PATIENT = 'selectPatient',
  SELECT_BY_OTHER = 'selectOther',
}

export enum FrequencyType {
  NONE = '',
  FREQUENCY_3H = '3h',
  FREQUENCY_6H = '6h',
  FREQUENCY_12H = '12h',
  FREQUENCY_1D = '1d',
  FREQUENCY_1W = '1w',
  FREQUENCY_1M = '1M',
}

export interface ICampaignSegmentFormProps {
  isReadOnly?: boolean;
  isAddGroupMemberOnly?: boolean;
  initData?: ICampaignSegmentFormState;
  campaignDetailsError?: ICampaignErrors;
  onChange?: (value: ICampaignSegmentFormState) => void;
  onModalClose?: any;
  onSubmitForm?: (campaignSegmentFormData: ICampaignSegmentFormState, callback?: any, initialLocationIdsList?: string[]) => void;
  editing?: boolean;
  context?: CampaignSegmentTableContext;
  showLeadAndPatient?: boolean;
  isPatientGroup?: boolean;
  locationOptionList?:any;
  onColumnViewChange?: (columns: number) => void;
}
