import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {FormError, componentKeys} from '../../CustomWrapper/CustomComponentHelper';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';
import {IFormCommonData} from '../../CustomWrapper/CustomWrapper';
import {FormControl, Icon, Input, Spinner, Text, VStack} from 'native-base';
import FormComponentLoader from '../../CustomWrapper/FormComponentLoader/FormComponentLoader';
import {useContainerDimensions} from '../../../../../../CustomHooks/ContainerDimensionHook';
import FormComponentError from '../../CustomWrapper/FormComponentError/FormComponentError';
import {CustomComponentKey, isRequiredField} from '../../CustomComponentUtils';
import {DisplayText} from '../../../../../../common/DisplayText/DisplayText';
import {DatePicker} from 'antd';
import {DATE_FORMATS} from '../../../../../../../constants';
import {useIntl} from 'react-intl';
import {v4 as uuidv4} from 'uuid';
import {
  getMomentObjectWithDateStringAndFormat,
  isFutureDate,
} from '../../../../../../../utils/DateUtils';
import {IFormValidationOutput} from '../../CustomWrapper/interfaces';
import {cloneDeep, debounce} from 'lodash';
import {useLazyQuery} from '@apollo/client';
import {
  CARESTUDIO_APOLLO_CONTEXT,
} from '../../../../../../../constants/Configs';
import ContactsQueries from '../../../../../../../services/Contacts/ContactsQueries';
import Stack from '../../../../../../common/LayoutComponents/Stack';
import {Colors} from '../../../../../../../styles';
import DetailPreview from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailPreview/DetailPreview';
import {FlatList} from 'react-native';
import MaterialIcon from 'react-native-vector-icons/MaterialIcons';
import { WIDGET_TYPES } from '../../../../FormsConstants';

export interface IFormComponentProps {
  validateRef: any;
  disabled: boolean;
  component: any;
  options: any;
  setValueRef?: any;
  onChange: (componentValue: any, flag?: any) => void;
}

export enum PatientField {
  externalId,
  birthDate,
}

interface IAddPatientIdentifierState {
  showErrors: boolean;
  loading: boolean;
  formError?: FormError;
  nonBlockingFormError?: FormError;
  isApiLoading?: boolean;
  isValidPatient?: boolean;
  isPatientNotFound?: boolean;
}

export interface IPatientIdentifierComponentValue {
  externalId?: string;
  birthDate?: string;
}

const AddPatientIdentifier = (props: IFormComponentProps) => {
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const componentRef = useRef();
  const {width} = useContainerDimensions(componentRef);
  const isPreviewMode = contextData.isPreviewMode || false;
  const isAllowToShare = props?.component?.allowToShare &&  props?.component?.allowToShare === false ? false : true;
  const isBuilderPreviewMode = props.options?.isBuilderPreviewMode;
  const isPrintForm = props.options?.isPrintForm;

  const [isMobileScreen] = [width <= 480];
  const intl = useIntl();

  const [componentValue, setComponentValue] =
    useState<IPatientIdentifierComponentValue>(
      props.component?.selectedValue || {}
    );

  const [componentState, setComponentState] =
    useState<IAddPatientIdentifierState>({
      showErrors: false,
      loading: false,
      formError: contextData.formError,
      isApiLoading: false,
      isPatientNotFound: false,
    });

  const [getContactByCustomAttribute] = useLazyQuery(
    ContactsQueries.GET_CONTACT_BY_CUSTOM_ATTRIBUTE,
    {
      fetchPolicy: 'no-cache',
      context: {
        headers: props?.options?.proxyHeaders,
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
    }
  );

  const isRequired = (field: PatientField) => {
    const keyAllowedOperations =
      contextData.capabilities?.abilities?.keyAllowedOperations;
    return isRequiredField(
      CustomComponentKey.PATIENT_IDENTIFIER,
      field,
      keyAllowedOperations
    );
  };

  const isInvalid = (
    field: PatientField,
    value?: string,
    showErrors?: boolean
  ) => {
    const canShowErrors = showErrors || componentState.showErrors;
    return !value && isRequired(field) && canShowErrors;
  };

  const validateData = (
    currentData: IPatientIdentifierComponentValue
  ): IFormValidationOutput => {
    setComponentState((prev) => ({...prev, showErrors: true, isPatientNotFound: false}));

    const isValidInput = isValidData();

    if (!isValidInput) {
      return {
        isValid: false,
        message: `${props.component.label}: Please fill all the mandatory fields`,
      };
    }

    const isValidPatient =
      !componentState?.isApiLoading && componentState?.isValidPatient;

    return {
      isValid: isValidPatient || false,
      message: `${props.component.label}: Please enter valid patient details.`,
    };
  };

  const isValidData = () => {
    let isValid = true;
    isValid &&= !isInvalid(
      PatientField.externalId,
      componentValue.externalId,
      true
    );
    isValid &&= !isInvalid(
      PatientField.birthDate,
      componentValue.birthDate,
      true
    );
    return isValid;
  };

  const validatePatientInfo = async (externalId: string, birthDate: string) => {
    const isValid = externalId && birthDate;

    if (!isValid || contextData?.isBuilderMode || isPreviewMode || isBuilderPreviewMode) {
      return;
    }

    setComponentState((prev) => ({
      ...prev,
      isApiLoading: true,
      isValidPatient: false,
    }));

    getContactByCustomAttribute({
      variables: {
        params: {
          entityType: 'CONTACT',
          value: externalId,
          birthDate: birthDate
            ? birthDate
            : '',
          key: 'external_id',
          ...(props?.options?.tpaCareAdvocateId && {tpaCareAdvocateId: props?.options?.tpaCareAdvocateId})
        },
      },
    })
      .then((data) => {
        if (data?.data?.getContactByCustomAttribute?.contactId) {
          setComponentValue((prev) => ({
            ...prev,
            contactId: data?.data?.getContactByCustomAttribute?.contactId,
          }));
          setComponentState((prev) => ({
            ...prev,
            isValidPatient: true,
            isApiLoading: false,
            isPatientNotFound: false,
          }));
        } else {
          setComponentState((prev) => ({
            ...prev,
            isValidPatient: false,
            isApiLoading: false,
            showErrors: true,
            isPatientNotFound: true,
          }));
        }
      })
      .catch((error) => {

        setComponentState((prev) => ({
          ...prev,
          isValidPatient: false,
          isApiLoading: false,
        }));
      });
  };

  props.validateRef.current = validateData;

  useEffect(() => {
    props.onChange(cloneDeep(componentValue));
  }, [componentValue]);

  useEffect(() => {
    if (componentValue?.externalId && componentValue?.birthDate) {
      debouncedValidatePatientInfo(componentValue?.externalId, componentValue?.birthDate);
    }
  }, [componentValue?.externalId, componentValue?.birthDate]);


  const debouncedValidatePatientInfo = useCallback(
    debounce((externalId: string, birthDate: string) => {
      validatePatientInfo(externalId, birthDate);
    }, 500),
    []
  );

  const getPreviewData = (): {
    id: string;
    key: string;
    value: string;
  }[] => {
    const previewData: {
      id: string;
      key: string;
      value: string;
    }[] = [];
    const data = ['externalId', 'birthDate'];
    data.forEach((item: string) => {
      const value = componentValue[
        item as keyof IPatientIdentifierComponentValue
      ] as any;
      const fieldTitle = intl.formatMessage({id: item});
      const previewItem = {
        key: `${fieldTitle}`,
        value: value,
        id: uuidv4(),
      };
      if (value) previewData.push(previewItem);
    });
    return previewData;
  };

  if (isPreviewMode) {
    const data = getPreviewData();
    return (
      <>
        {!componentState.loading && !componentState.formError && (
          <>
            {data.length > 0 && (
              <div className={` ${isAllowToShare ? 'page-break' : 'disallow-to-share'}`}>
                <DetailPreview titleLocalId={props.component.label}>
                  <FlatList
                    data={data}
                    keyExtractor={(item) => item.id}
                    renderItem={({item}) => (
                      <Stack
                        key={item.id}
                        direction="column"
                        style={{marginTop: 20}}
                      >
                        <Text flex={3} color={Colors.Custom.Gray700}>{item.key}</Text>
                        <Text flex={7} color={Colors.Custom.Gray700}>{item.value}</Text>
                      </Stack>
                    )}
                  />
                </DetailPreview>
              </div>
            )}
          </>
        )}
      </>
    );
  }


  if (
    !isPreviewMode &&
    !contextData.isBuilderMode &&
    !isBuilderPreviewMode &&
    !isPrintForm &&
    props.options?.widgetType !== WIDGET_TYPES.TPA_CARE_ADVOCATE
  ) {
    return <FormComponentError error={FormError.configurationDataAPIFail} />;
  }

  return (
    <VStack ref={componentRef}>
      {componentState.loading && <FormComponentLoader />}
      {!componentState.loading && componentState.nonBlockingFormError && (
        <FormComponentError error={componentState.nonBlockingFormError} />
      )}
      {!componentState.loading && (
        <VStack space={4} flex={1}>
          {!contextData?.isBuilderMode &&
            !isPreviewMode && (
              <DisplayText
                textLocalId="patientIdentifierMessage"
                extraStyles={{color: Colors.Custom.Gray500, fontSize: 14}}
              />
          )}
          <div className="page-break">
            <FormControl
              isInvalid={isInvalid(
                PatientField.externalId,
                componentValue.externalId
              )}
              flex={1}
            >
              <FormControl.Label
                isRequired={isRequired(PatientField.externalId)}
              >
                <DisplayText textLocalId="externalId" />
              </FormControl.Label>
              <Input
                _focus={{borderColor: '#D0D5DD'}}
                value={componentValue.externalId}
                isReadOnly={props.disabled}
                onChangeText={(text: string) => {
                  setComponentValue((prev) => ({...prev, externalId: text}));
                }}
              />
            </FormControl>
          </div>
          <div className="page-break">
            <FormControl
              isInvalid={isInvalid(
                PatientField.birthDate,
                componentValue.birthDate
              )}
              flex={1}
            >
              <FormControl.Label
                isRequired={isRequired(PatientField.birthDate)}
              >
                <DisplayText textLocalId="birthDate" />
              </FormControl.Label>
              <DatePicker
                disabled={props.disabled}
                placeholder={intl.formatMessage({id: 'birthDate'})}
                format={DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT}
                disabledDate={(current: any) => {
                  return current && isFutureDate(current);
                }}
                value={
                  componentValue.birthDate
                    ? getMomentObjectWithDateStringAndFormat(
                        componentValue.birthDate,
                        DATE_FORMATS.DISPLAY_DATE_FORMAT
                      )
                    : undefined
                }
                style={{height: '36px'}}
                className={
                  isInvalid(PatientField.birthDate, componentValue.birthDate)
                    ? 'field-error'
                    : ''
                }
                onChange={(value) => {
                  setComponentValue((prev) => ({
                    ...prev,
                    birthDate: value?.format(DATE_FORMATS.DISPLAY_DATE_FORMAT),
                  }));
                }}
              />
            </FormControl>
          </div>
          {componentState.isApiLoading && (
            <Stack direction="row">
              <Spinner />
              <DisplayText
                extraStyles={{marginLeft: 12}}
                textLocalId="identifyingThePatientMessage"
              />
            </Stack>
          )}
          {!componentState?.isApiLoading && componentState?.isValidPatient && (
            <Stack direction="row" style={{alignItems: 'center'}}>
              <Icon
                as={MaterialIcon}
                name="check-circle"
                size="4"
                marginRight={1}
                color={Colors.Custom.SuccessColor}
              />
              <DisplayText
                extraStyles={{color: Colors.Custom.SuccessColor}}
                textLocalId="patientIdentified"
              />
            </Stack>
          )}
          {!componentState.isApiLoading &&
            componentState.isPatientNotFound &&
            !componentState?.isValidPatient && (
              <Stack direction="row">
                <DisplayText
                  extraStyles={{color: Colors.Custom.Danger700}}
                  textLocalId="patientNotFoundError"
                />
              </Stack>
            )}
        </VStack>
      )}
    </VStack>
  );
};

export default AddPatientIdentifier;
