import React, { useContext } from 'react';
import {Pressable, Text, View} from 'react-native';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import Stack from '../../../../common/LayoutComponents/Stack';
import {CINICAL_SECTIONS_FILTER_TABS} from '../ClinicalSectionConstants';
import {styles} from './ClinicalSectionFilterTabsStyles';
import Feather from 'react-native-vector-icons/Feather';
import {Colors} from '../../../../../styles';
import {IClinicalSectionFilterTab} from './interfaces';
import {isWeb} from '../../../../../utils/platformCheckUtils';
import { useIntl } from 'react-intl';
import { Badge, Skeleton } from 'native-base';
import { Tooltip } from 'antd';
import { CommonDataContext } from '../../../../../context/CommonDataContext';

interface IClinicalSectionFilterTabsProps {
  onTabClick: (codde: string) => void;
  selectedTabCode?: string;
  tabs: IClinicalSectionFilterTab[];
  useEvenlyDistributedTabs?: boolean;
  useNewDesign?: boolean;
}

export const ClinicalSectionFilterTabs = (
  props: IClinicalSectionFilterTabsProps
) => {
  const intl = useIntl();
  const mlovData = useContext(CommonDataContext);
  const isSideCarContext = !!mlovData.sidecarContext?.isSidecar;

  const {useNewDesign} = props;
  const renderTab = (code: string, label?: string, icon?: JSX.Element | undefined, showIconToRight?: boolean, tabContainsCount?: boolean, tabItemCountLoading?: boolean, tabItemCount?: number, countTooltip?: string) => {
    return (
      <Pressable
        onPress={() => {
          props.onTabClick(code);
        }}
      >
        <Stack
          direction={showIconToRight ? 'row' : 'column'}
          style={
            code === props.selectedTabCode
              ? useNewDesign
                ? styles.selectedTabNewDesign
                : styles.selectedTab
              : styles.tab
          }
        >
          {label &&
            (isWeb() ? (
              <Stack direction={'row'}>
                <DisplayText
                  textLocalId={label}
                  extraStyles={{
                    color:
                      code === props.selectedTabCode
                        ? Colors.FoldPixel.GRAY400
                        : Colors.FoldPixel.GRAY300,
                    ...(!isSideCarContext ? {fontSize: 16} : {})   
                  }}
                />
                {tabContainsCount &&
                  (tabItemCountLoading ? (
                    <Skeleton.Text
                      alignContent={'center'}
                      width={3}
                      justifyContent={'center'}
                      alignItems={'center'}
                      lines={0.5}
                      startColor={Colors.Custom.Gray400}
                      style={{
                        marginLeft: 4,
                      }}
                      // marginLeft={2}
                    ></Skeleton.Text>
                  ) : (
                    !!tabItemCount &&
                    tabItemCount > 0 && (
                      <Tooltip title={countTooltip}>
                        <Badge
                          style={{
                            paddingHorizontal: 4,
                            paddingVertical: 2,
                          }}
                          fontSize={10}
                          fontWeight={500}
                          _text={{
                            color: Colors.Custom.Gray700,
                          }}
                          borderWidth={0.5}
                          borderColor={Colors.FoldPixel.GRAY100}
                          alignSelf="center"
                          rounded="md"
                          justifyContent={'center'}
                          ml={1}
                        >
                          {tabItemCount}
                        </Badge>
                      </Tooltip>
                    )
                  ))}
              </Stack>
            ) : (
              <>
                <Text style={{fontSize: 16, color: Colors.FoldPixel.GRAY300}}>
                  {intl.formatMessage({id: label})}
                </Text>
                {tabContainsCount &&
                  (tabItemCountLoading ? (
                    <Skeleton.Text
                      alignContent={'center'}
                      width={3}
                      justifyContent={'center'}
                      alignItems={'center'}
                      lines={0.5}
                    ></Skeleton.Text>
                  ) : (
                    !!tabItemCount &&
                    tabItemCount > 0 && (
                      <Badge
                        fontSize={10}
                        fontWeight={500}
                        _text={{
                          color: Colors.Custom.Gray700,
                        }}
                        borderWidth={1}
                        borderColor={Colors.Custom.Gray400}
                        alignSelf="center"
                        rounded="md"
                        justifyContent={'center'}
                        paddingX={0}
                        paddingY={0}
                        ml={1}
                      >
                        {tabItemCount}
                      </Badge>
                    )
                  ))}
              </>
            ))}
          {icon}
        </Stack>
      </Pressable>
    );
  };

  return (
    <Stack
      direction="row"
      style={{
        backgroundColor: useNewDesign ? undefined : Colors.Custom.Gray100,
        padding: 2,
        borderRadius: 4,
        alignItems: useNewDesign ? 'center' : undefined,
      }}
    >
      {props.tabs.map((filterTab) => {
        return (
          <View
            key={`tabs_${filterTab.code}`}
            style={{
              flex: isWeb()
                ? props.useEvenlyDistributedTabs
                  ? 1
                  : undefined
                : 1,
            }}
          >
            {renderTab(
              filterTab.code,
              filterTab.label,
              filterTab.icon,
              filterTab.showIconToRight,
              filterTab?.tabContainsCount,
              filterTab?.tabCountLoading,
              filterTab?.tabCount,
              filterTab?.countTooltip,
            )}
          </View>
        );
      })}
    </Stack>
  );
};
