import {Drawer} from 'antd';
import {VStack, Text, HStack, View, Divider, Pressable, Icon, Spacer, Skeleton} from 'native-base';
import {BUTTON_TYPE, DATE_FORMATS, GROUP_MEMBER_TYPE} from '../../../../constants/StringConst';
import {Colors} from '../../../../styles/Colors';
import {
  getDateStrFromFormat,
  isCurrentDateInFutureComparedToOther,
} from '../../../../utils/DateUtils';
import {ITask} from '../../CareDashboard/CareDashboardInterfaces';
import {DisplayText} from '../../DisplayText/DisplayText';
import {ModalActionTitle} from '../../ModalActionTitle/ModalActionTitle';
import WorkFlowListByEntityEvents from '../../CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/WorkFlowListByEntityEvents';
import {
  CUSTOM_AUTOMATION_URL,
  ENTITY_EVENTS_TITLE_SUBTITLE,
  ENTITY_EVENTS_TYPE,
} from '../../../../constants/EntityAndEventsConst';
import {getPatientEHRUrl} from '../../../../utils/capabilityUtils';
import {IUserSearch} from '../CareDashboardWidget/UserAutoComplete';
import React, {useContext, useEffect, useState} from 'react';
import {EntityType} from '../../../TaskCard/TaskEnum';
import {BottomViewAction, isTaskWithType} from '../../../TaskCard/TaskCardHelper';
import FeatherIcon from 'react-native-vector-icons/Feather';
import MemberInfoListItem from '../../MemberInfoListItem/MemberInfoListItem';
import { updateContactWorkflow } from '../../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateQueryUtils';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { TaskQueries } from '../../../../services';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../constants/Configs';
import { USER_ACCESS_PERMISSION } from '../../../RightSideContainer/UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../../SideMenuBar/SideBarConst';
import { getCaslAbility, getUserUUID } from '../../../../utils/commonUtils';
import AppointmentContactBanner from '../../CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentContactBanner';
import { PATIENT_QUICK_PROFILE_PARENT_CODES } from '../../../../constants/ActionConst';
import ContactsQueries from '../../../../services/Contacts/ContactsQueries';
import { AppointmentPatientBannerLoading } from '../../CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentPatientBannerLoading';
import { isAllowMarkAsComplete, isAllowToEditTask } from '../CareDashboardTable/CareDashboardTableHelper';
import { IAddOrUpdateTaskState, ISubTasks, ISubTasksRes } from '../../AddTask/interfaces';
import { TASK_ACTIONS, getCompletedTaskStatusId } from '../CareDashboardUtils/CareDashboardUtils';
import { getMlovListFromCategory, getMlovObjectFromCode } from '../../../../utils/mlovUtils';
import { MLOV_CATEGORY } from '../../../../constants';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import AddSubTasks from '../../AddTask/AddEditTaskView/AddSubTasks';
import AddOrUpdateCommentForTask from '../../AddTask/AddEditComment/AddOrUpdateCommentForTask';
import ArrowDownSvg from '../../Svg/ArrowDownSvg';
import ArrowRightSvg from '../../Svg/ArrowRightSvg';
import AttachmentsItemList from '../../AddTask/AddEditTaskView/AttachmentsItemList';
import { manageAttachmentsListData } from '../../AddTask/AddTaskUtils';
import { LabelDataListView } from '../CareDashboardWidget/LabelDataListView';
import {TaskDrawerAction} from '../../AddTask/AddEditTaskView/TaskDrawerAction';
import { TaskViewTabsCode, TASK_VIEW_TABS } from '../../AddTask/AddEditComment/TaskCommentsHelper';
import { testID, TestIdentifiers } from '../../../../testUtils';
import useTaskActionManager from '../CustomHook/useTaskActionManager';
import { EventBus } from '../../../../utils/EventBus';
import { TASK_STATUS } from '../../../../constants/MlovConst';
import { TASK_EVENTS } from '../CareDashboardConstants';

import FoldPermitCan from '../../../CommonComponents/FoldPermitCan/FoldPermitCan';

export const OrderTaskDetails = (props: {
  onCancel: () => void;
  isVisible: boolean;
  assignee: IUserSearch | undefined;
  task?: ITask;
  // onBottomViewAction: (
  //   task: ITask,
  //   action: BottomViewAction,
  //   data: any,
  // ) => void;
  onAssigneeClick?: () => void;
  fetchAllTypeTask?:(selectedAssigneeUserId?: string) => void
}) => {
  const { task } = props;
  const eventBus = EventBus.getEventBusInstance();

  const { handleTaskActions } = useTaskActionManager({});
  const [contactDetails, setContactDetails] = useState<{
    details: any;
    loading: boolean;
    subTasks?: ISubTasks[];
  }>({
    details: undefined,
    loading: false,
    subTasks: [] as ISubTasks[],
  });
  const [expandSubtask, setExpandSubtask] = useState(true);
  const [orderNote, setOrderNote] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState(TASK_VIEW_TABS?.find((tab) => tab?.code === TaskViewTabsCode?.ACTIVITY))
  const openInEhrUrl = getPatientEHRUrl(
    props?.task?.contact?.patient?.patientId,
    props?.task?.taskLocations?.[0].locationId,
    ''
  );
  const [loading, setLoading] = useState(false)
  const currentUserId = getUserUUID();
  const mlovData = useContext(CommonDataContext);
  const taskStatusMlov =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_STATUS
    ) || [];
  const completedStatusId = getCompletedTaskStatusId(taskStatusMlov);
  const completedStatusMlov = getMlovObjectFromCode(TASK_STATUS.COMPLETED, taskStatusMlov);

  const [getContactDetails, contactDetailAPIQuery] = useLazyQuery(
    ContactsQueries.GET_CONTACT_BY_UUID,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [ getSubTaskByIds ] = useLazyQuery(TaskQueries.GET_SUB_TASK_BY_ID, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });
   // if task not completed any one can mark it as complete
  const canMarkAsComplete = isAllowMarkAsComplete(task);


  const getJourneyDates = (careJourney: {
    startDateTime: string;
    endDateTime: string;
  }) => {
    let date = '';
    if (careJourney.startDateTime && careJourney.endDateTime) {
      date = getDateStrFromFormat(
        careJourney.startDateTime,
        DATE_FORMATS.DISPLAY_DATE_FORMAT,
      );

      if (
        isCurrentDateInFutureComparedToOther(
          new Date(),
          careJourney.endDateTime,
        )
      ) {
        date +=
          ' - ' +
          getDateStrFromFormat(
            careJourney.endDateTime,
            DATE_FORMATS.DISPLAY_DATE_FORMAT,
          );
      } else {
        date += ' - Present';
      }
    }
    return date;
  };

  const getJourneyDetail = () => {
    const title = props.task?.patientCareJourney?.title || props.task?.patientCareJourney?.careJourney?.title;
    if (props?.task?.patientCareJourney) {
      const journeyDates = getJourneyDates(props.task.patientCareJourney);
      return `${title} (${journeyDates})`;
    }
    return title;
  };

  const getOrderType = () => {
    let orderType = '';
    if (props?.task?.referenceData?.entityType) {
      switch (props.task.referenceData.entityType) {
        case EntityType.MED:
          orderType = 'Medication';
          break;
        case EntityType.LAB_TEST:
          orderType = 'Lab Test';
          break;
        case EntityType.IMMUNIZATION:
          orderType = 'Immunization';
          break;
      }
    }
    return orderType;
  };

  const getOrderItemLabel = () => {
    let orderItemLabel = '';
    if (props?.task?.referenceData?.entityType) {
      switch (props.task.referenceData.entityType) {
        case EntityType.MED:
          orderItemLabel = 'drugName';
          break;
        case EntityType.LAB_TEST:
          orderItemLabel = 'labTestName';
          break;
        case EntityType.IMMUNIZATION:
          orderItemLabel = 'vaccineName';
          break;
      }
    }
    return orderItemLabel;
  };

  const getOrderItemValue = () => {
    let orderItemValue = '';
    if (props?.task?.referenceData?.entityType) {
      switch (props.task.referenceData.entityType) {
        case EntityType.MED:
          orderItemValue = props?.task?.referenceData?.entity?.name || props?.task?.referenceData?.entity?.drugName;
          break;
        case EntityType.LAB_TEST:
        case EntityType.IMMUNIZATION:
          orderItemValue = props?.task?.referenceData?.entity?.name;
          break;
      }
    }
    return orderItemValue;
  };

  const openInEHR = () => {
    window.open(openInEhrUrl, '_blank');
  };

  const [updateTaskWorkflow] = useMutation(TaskQueries.UPDATE_TASK_WORKFLOW, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    onError: (error) => {

    },
  });

  const UpdateWorkFlow = (referenceData: any, workflowEventList: any) => {
    const data = updateContactWorkflow(workflowEventList);
    updateTaskWorkflow({
      variables: {
        params: {
          id: props.task?.id,
          data: {
            referenceData: {
              ...(referenceData || {}),
              ...data
            },
          },
        },
      },
    });
  };


  const orderNoteQuery = useQuery(
    TaskQueries.GET_ORDER_TASK_NOTES,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      variables: {
        taskId: props.task?.id,
      },
      onCompleted: (data: any) => {
        if (data?.rte_subject?.length) {
          setOrderNote(data?.rte_subject?.[0]?.content);
        }
      },
      onError(error) {
          setOrderNote('');
      },
    }
  )

  useEffect(() => {
    if (props.task && props.task.contactId) {
      setContactDetails((prev) => ({
        ...prev,
        loading: true,
        details: undefined
      }));
      getContactDetails({
        variables: {
          contactId: props.task.contactId,
        },
      })
      .then((response) => {
        if (
          response?.data?.contacts?.length > 0 &&
          response.data.contacts[0].person
        ) {
          const contactDetail = response.data.contacts[0];
          setContactDetails((prev) => ({
            ...prev,
            loading: false,
            details: contactDetail,
          }));
        }
      })
      .catch((error) => {

        setContactDetails((prev) => ({
          ...prev,
          loading: false,
          details: undefined
        }));
      });
    }
  }, [props?.task?.contactId]);

  useEffect(()=> {
    if (props.task?.id && props.task?.subTasks?.length) {
      getSubTask(props.task);
    }
  }, [props?.task?.id])

  const getSubTask = async (task: ITask) => {
    const subTasksId = task.subTasks?.map((item) => item.id.toString());
    setContactDetails((prev) => ({...prev, loading: true}));
    const response = await getSubTaskByIds({
      variables: {
        ids: subTasksId,
      },
    });
    if (response?.data?.getTasks?.tasks?.length) {
      const subTaskRes = response.data.getTasks.tasks;
      const updatedItems = subTaskRes.map((item: ISubTasksRes) => ({
        ...item,
        isChecked: item.statusId === completedStatusId ? true : false,
        assignee: {
         value: item?.assigneeUser?.uuid,
         label: item?.assigneeUser?.name,
         key: item?.assigneeUser?.uuid,
         details: item?.assigneeUser,
        },
      }));
      setContactDetails((prev) => {
        return {
          ...prev,
          subTasks: updatedItems,
        };
      });
    }
    setContactDetails((prev) => ({...prev, loading: false}));
  };

  

  const isVitalTask = isTaskWithType(props?.task || {} as ITask, EntityType.VITAL);
  const isFormTask = isTaskWithType(props?.task || {} as ITask, EntityType.FORM);

  const isVitalOrFormTask = ()=> {
    return isVitalTask || isFormTask
  }

  const onMarkAsComplete = async () => {
    if (!props.task) {
      return;
    }
    const task = props.task;
    setLoading(true);
    await handleTaskActions(task, BottomViewAction.markAsComplete, {});
    eventBus.broadcastEvent(TASK_EVENTS.TASK_UPDATED, {
       task: {
        ...task,
        statusId: completedStatusId,
        isCompleted: true,
        status: {
          id: completedStatusMlov?.id || '',
          code: completedStatusMlov?.code || '',
          value: completedStatusMlov?.value || '',
        }
       }
    });
    setLoading(false);
    props?.fetchAllTypeTask?.();
  }

  return (
    <Drawer
      destroyOnClose
      placement="right"
      onClose={() => {
        props.onCancel();
      }}
      visible={props.isVisible}
      closable={false}
      width={'40%'}
      title={
        <ModalActionTitle
          title={'orderTaskDetail'}
          titleColor={''}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'close',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                props.onCancel();
              },
            },
            {
              show: openInEhrUrl?.length ? true : false,
              id: 2,
              btnText: 'openInEhr',
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              onClick: () => {
                openInEHR();
              },
            },
            {
              show:
                !canMarkAsComplete ||
                openInEhrUrl?.length ||
                props.task?.isCompleted || isVitalOrFormTask()
                  ? false
                  : true,
              id: 3,
              btnText: 'MarkAsCompleted',
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              isLoading: loading,
              onClick: onMarkAsComplete,
            },
          ]}
        />
      }
    >
      <View position={'absolute'} top={'60px'}>
        <TaskDrawerAction
          actions ={TASK_ACTIONS}
          task={task || {} as ITask}
          onComplete={()=>{
            props?.fetchAllTypeTask?.();
          }}
        />
      </View>
      <>
        {orderNoteQuery?.loading && (
          <VStack space={4}>
            <Skeleton />
            <Skeleton.Text />
            <Divider />
            <Skeleton />
            <Skeleton.Text />
          </VStack>
        )}
        {!orderNoteQuery?.loading && (
          <>
            <VStack flex={1} mx={0} space={4}>
              <VStack>
                {contactDetails.loading && <AppointmentPatientBannerLoading />}
                {!contactDetails.loading &&
                  (contactDetails.details?.name ||
                    props?.task?.contact?.name) && (
                    <AppointmentContactBanner
                      contactData={
                        contactDetails.details || props.task?.contact
                      }
                      parentCode={
                        PATIENT_QUICK_PROFILE_PARENT_CODES.TASK_DETAIL
                      }
                    />
                  )}
              </VStack>
              <Divider />

              <View>
                <HStack space={1} flex={1}>
                  <DisplayText
                    size={'mdSemibold'}
                    extraStyles={{
                      fontWeight: 600,
                      fontSize: 16,
                      flex: 0.2,
                    }}
                    textLocalId={'taskTitle'}
                  />
                  <Text
                    fontWeight={400}
                    fontSize={14}
                    flex={0.8}
                    color={Colors.Custom.Gray700}
                    overflow= 'hidden'
                  >
                    {props?.task?.title}
                  </Text>
                </HStack>
                <HStack space={1} flex={1}>
                  <DisplayText
                    size={'mdSemibold'}
                    extraStyles={{
                      fontWeight: 600,
                      fontSize: 16,
                      flex: 0.2,
                    }}
                    textLocalId={'taskDescription'}
                  />
                  <Text
                    fontWeight={400}
                    fontSize={14}
                    flex={0.8}
                    color={Colors.Custom.Gray700}
                    overflow= 'hidden'
                  >
                    {props?.task?.description}
                  </Text>
                </HStack>
                <HStack space={1} flex={1}>
                  <DisplayText
                    size={'mdSemibold'}
                    extraStyles={{
                      fontWeight: 600,
                      fontSize: 16,
                      flex: 0.2,
                    }}
                    textLocalId={'orderType'}
                  />
                  <Text
                    fontWeight={400}
                    fontSize={14}
                    flex={0.8}
                    color={Colors.Custom.Gray700}
                  >
                    {getOrderType()}
                  </Text>
                </HStack>
                <HStack space={1} flex={1}>
                  <DisplayText
                    size={'mdSemibold'}
                    extraStyles={{
                      fontWeight: 600,
                      fontSize: 16,
                      flex: 0.2,
                    }}
                    textLocalId={getOrderItemLabel()}
                  />
                  <Text
                    flex={0.8}
                    fontWeight={400}
                    fontSize={14}
                    color={Colors.Custom.Gray700}
                  >
                    {getOrderItemValue()}
                  </Text>
                </HStack>
                <HStack space={1} flex={1}>
                  <DisplayText
                    size={'mdSemibold'}
                    extraStyles={{
                      fontWeight: 600,
                      fontSize: 16,
                      flex: 0.2,
                    }}
                    textLocalId={'orderNote'}
                  />
                  <Text
                    flex={0.8}
                    fontWeight={400}
                    fontSize={14}
                    color={Colors.Custom.Gray700}
                  >
                    {orderNote}
                  </Text>
                </HStack>
              </View>

              <VStack>
                <DisplayText
                  size={'mdSemibold'}
                  extraStyles={{
                    fontWeight: 900,
                    fontSize: 18,
                    flex: 0.2,
                  }}
                  textLocalId={'additionalDetail'}
                />
                <HStack space={1} flex={1}>
                  <DisplayText
                    size={'mdSemibold'}
                    extraStyles={{
                      flex: 0.2,
                      fontWeight: 600,
                      fontSize: 16,
                      color: 'gray.700',
                    }}
                    textLocalId={'Priority'}
                  />
                  <Text
                    flex={0.8}
                    fontWeight={400}
                    fontSize={14}
                    color="gray.700"
                  >
                    {props?.task?.priority?.value}
                  </Text>
                </HStack>
                <HStack space={1} flex={1}>
                  <DisplayText
                    size={'mdSemibold'}
                    extraStyles={{
                      fontWeight: 600,
                      fontSize: 16,
                      flex: 0.2,
                    }}
                    textLocalId={'assignee'}
                  />
                  <Text
                    flex={0.8}
                    fontWeight={400}
                    fontSize={14}
                    color={Colors.Custom.Gray700}
                  >
                    {props?.assignee?.label}
                  </Text>
                </HStack>
                <HStack space={1} flex={1}>
                  <DisplayText
                    size={'mdSemibold'}
                    extraStyles={{
                      fontWeight: 600,
                      fontSize: 16,
                      flex: 0.2,
                    }}
                    textLocalId={'journeyDetail'}
                  />
                  <Text
                    flex={0.8}
                    fontWeight={400}
                    fontSize={14}
                    color={Colors.Custom.Gray700}
                    overflow= 'hidden'
                  >
                    {getJourneyDetail()}
                  </Text>
                </HStack>
              </VStack>

              {(task?.labels || [])?.length > 0 && (
                <VStack flex={1}>
                  <DisplayText
                    size={'mdSemibold'}
                    extraStyles={{
                      fontWeight: 600,
                      fontSize: 16,
                      flex: 0.2,
                    }}
                    textLocalId={'labels'}
                  />
                  <View width={800} flex={1}>
                    <LabelDataListView
                      key={task?.id}
                      hideAddMoreButton={true}
                      tagList={task?.labels || []}
                      style={{width: 800}}
                    />
                  </View>
                </VStack>
              )}

              {(contactDetails?.subTasks || [])?.length > 0 && (
                <VStack>
                  <HStack alignItems={'center'} ml={1}>
                    <DisplayText
                      size={'mdSemibold'}
                      extraStyles={{
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                      textLocalId={'subTasks'}
                    />
                    <Pressable
                      ml={2}
                      onPress={() => {
                        setExpandSubtask(!expandSubtask);
                      }}
                    >
                      <View size={18}>
                        {expandSubtask ? (
                          <ArrowDownSvg customStrokeColor="black"></ArrowDownSvg>
                        ) : (
                          <ArrowRightSvg customStrokeColor="black"></ArrowRightSvg>
                        )}
                      </View>
                    </Pressable>
                    <Text
                      fontWeight={600}
                      fontSize={14}
                      color={Colors.Custom.Gray500}
                      ml={5}
                    >
                      {`${
                        contactDetails?.subTasks?.filter(
                          (item) => item.isChecked
                        )?.length
                      }/${contactDetails?.subTasks?.length}`}
                    </Text>
                  </HStack>
                  {expandSubtask && (
                    <>
                      <VStack>
                        {contactDetails.subTasks?.map((data, index) => {
                          return (
                            <>
                              <AddSubTasks subTaskItem={data} isDisable />
                            </>
                          );
                        })}
                      </VStack>
                    </>
                  )}
                </VStack>
              )}
              {(props?.task?.attachments?.length || 0) > 0 && (
              <VStack>
                <DisplayText
                  extraStyles={{
                    color: 'black',
                    fontWeight: 600,
                    fontSize: 16,
                  }}
                  textLocalId={'attachments'}
                />
                <AttachmentsItemList
                  value={{} as IAddOrUpdateTaskState}
                  fileList={manageAttachmentsListData(props?.task?.attachments || [])}
                  isViewOnly
                />
                </VStack>
              )}
              <HStack space={1}>
                {TASK_VIEW_TABS?.map((tab) => {
                  return (
                    <Pressable
                      key={tab?.key}
                      style={{ marginHorizontal: 1 }}
                      onPress={() => {
                        setSelectedTab(tab);
                      }}
                      {...testID(TestIdentifiers?.[tab?.code])}
                    >
                      <View
                        borderBottomColor={selectedTab?.code === tab?.code ? Colors?.Custom?.mainPrimaryPurple : Colors?.Custom?.BackgroundColor}
                        borderBottomWidth={4}
                        padding={2}>
                        <DisplayText
                          extraStyles={{
                            color: selectedTab?.code === tab?.code ? Colors.Custom.mainPrimaryPurple : Colors?.Custom?.Gray400,
                            fontWeight: 600,
                            fontSize: 16,
                          }}
                          textLocalId={tab?.title}
                        />
                      </View>
                    </Pressable>
                  )
                })}
              </HStack>
              {props.task?.id?.length && selectedTab?.code === TaskViewTabsCode?.ACTIVITY && (
                <AddOrUpdateCommentForTask task={props.task} />
              )}
            </VStack>

            <VStack style={{marginTop: 20}} mx={0} space={4}>
              {selectedTab?.code === TaskViewTabsCode?.AUTOMATION && (
                <FoldPermitCan 
                resource={MAIN_MENU_CODES.AUTOMATION} 
                action={USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code}
                component={<WorkFlowListByEntityEvents
                  isDetailsPage={true}
                  workflowlist={
                    props?.task?.referenceData &&
                    props?.task?.referenceData.workflowList &&
                    props?.task?.referenceData.workflowList.length > 0
                  }
                  addUrl={CUSTOM_AUTOMATION_URL.ADD_TASK}
                  title={ENTITY_EVENTS_TITLE_SUBTITLE.ADD_TASK_TITLE}
                  subtitle={ENTITY_EVENTS_TITLE_SUBTITLE.ADD_TASK_SUB_TITLE}
                  entityEventList={ENTITY_EVENTS_TYPE.ADD_TASK}
                  onValueChanage={(eventEntityList: any) => {
                    UpdateWorkFlow(props?.task?.referenceData, eventEntityList);
                  }}
                ></WorkFlowListByEntityEvents>}
                />
              )}
            </VStack>
          </>
        )}
      </>
    </Drawer>
  );
};
