import {
  Icon,
  Modal,
  Pressable,
  ScrollView,
  Text,
  View,
  VStack,
  HStack
} from 'native-base';
import React, {useContext, useState} from 'react';
import {Colors} from '../../../../styles';
import {ICallLogEntry, ISipNumberList, IUserList} from './interface';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {ILoginUserData} from '../../../../Interfaces';
import {PatientInfoBanner} from '../../../PatientInfoBanner/PatientInfoBanner';
import Feather from 'react-native-vector-icons/Feather';
import {getAgeValue, getChatDisplayTimeAgo} from '../../../../utils/DateUtils';
import FeatherIcon from 'react-native-vector-icons/Feather';
import {CallTranscriptionModal} from './CallLogUtils';
import { isWeb } from '../../../../utils/platformCheckUtils';
import { getCallStatus, isShowTranscription } from './CallLogsUtils';
import { IUserResp } from '../../../../services/User/interface';
import { isUuid } from '../../../common/PhoneCalling/utils';
import OutboundCallSmsView from '../../../common/OutboundCallSmsView/OutboundCallSmsView';
import { COMMON_ACTION_CODES } from '../../../../constants/ActionConst';
import { EventBus } from '../../../../utils/EventBus';
import { ActivityIndicator, Platform } from 'react-native';
import { getCallLogsAvatar } from '../Conversations/ConversationChannelNew/ConversationSidebarUtils';
import { useLazyQuery } from '@apollo/client';
import EmployeeQueries from '../../../../services/Employee/EmployeeQueries';
import { CONFIG_CODES } from '../../../../constants/AccountConfigConst';
import { isAccountConfigEnabled } from '../../../../utils/configUtils';
import {isContactConsentRequired} from '../../../../utils/commonUtils';
import {CallDirections, TWILIO_CALL_STATUS} from './CallLogsConst';

interface CallLogListProps {
  callLog: ICallLogEntry;
  displayTime?: string;
  isOpenOutgoingCall?: boolean;
  contactData?: any;
  onActionPerformed?: (actionCode: string, actionData?: any) => void;
  userList?: IUserList[];
  sipNumberList?: ISipNumberList[];
}

const CallLogEntry = (props: CallLogListProps) => {
  const {callLog} = props;
  const isCallAndTranscriptionEnable = isAccountConfigEnabled(CONFIG_CODES.SHOW_CALL_RECORDING_AND_TRANSCRIPTION);
  const isConsentCheckRequired = isContactConsentRequired();
  const [selectedSingleCall, setSelectedSingleCall] = useState({
    callLog: {} as ICallLogEntry,
    transcriptionOpen: false,
    loading: false,
  });
  const [isOutgoingCallOpen, setIsOutgoingCallOpen] = useState(false);

  const [getEmployeeConsent] = useLazyQuery(
    EmployeeQueries.getEmployeeConsent,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const commonData = useContext(CommonDataContext);
  const userList = commonData?.accountUserList;
  const sipNumberList = commonData?.sipNumberList;

  const getHeaderSubString = () => {
    if (props?.callLog?.contact?.name) {
      return props?.callLog?.contact?.name;
    } else if (props?.contactData?.name) {
      return props?.contactData?.name
    } else if (props?.callLog?.id) {
      return props?.callLog?.direction == 'inbound'
        ? props?.callLog?.fromName ?? props?.callLog?.from
        : props?.callLog?.toName ??
            (props?.callLog?.toAssignee || props?.callLog?.to);
    }
  };

  const getOverridenHeaderSubString = () => {
    return getHeaderSubString();
  }


  const handleActions = (actionCode: string, rowData?: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.CLOSE_MODAL:
        setIsOutgoingCallOpen(false);
        break;
      case COMMON_ACTION_CODES.CALL:
        if(Platform.OS === 'web'){
          const eventBus = EventBus.getEventBusInstance();
          eventBus.broadcastEvent('CLOUD_CALL', {
            contactData: props.contactData ? { ...props.contactData,
              fromNumber: rowData.fromNumber || '',
              ...props.contactData
            } : {},
            fromNumber: rowData.fromNumber,
            toNumber: props.contactData?.phoneNumber,
          });
        }
        setIsOutgoingCallOpen(false);
        break;
    }
  };

  const getCallStatusAndCallerName = () => {
    const userName = userList?.find((user) => user?.uuid === callLog?.userUUID)?.name;
    const phoneName = sipNumberList?.find((sip) => sip?.id === callLog?.sipPhoneId)?.phoneName;
    const isIncoming = callLog?.direction == CallDirections.Incoming && callLog?.status == TWILIO_CALL_STATUS.COMPLETED;
    const inOutgoing = callLog?.direction === CallDirections.Outgoing;
    if ((userName || phoneName) && (isIncoming || inOutgoing)) {
      return `${userName || phoneName} ${inOutgoing ? 'Called' : 'Answered'}`;
    }
    return getCallStatus(callLog);
  }

  return (
    <View
      style={{
        paddingVertical: 12,
        paddingLeft: isWeb() ? 0 : 4
      }}>
      <Pressable
        disabled={props?.isOpenOutgoingCall ? false : true}
        onPress={async () => {
          if (!isConsentCheckRequired) {
            if (props?.isOpenOutgoingCall) {
              setIsOutgoingCallOpen(true);
            }
            return;
          }
          setSelectedSingleCall((prev) => {
            return {
              ...prev,
              loading: true,
            };
          });
          const checkPatientConsent = await getEmployeeConsent({
            variables: {
              contactUuid: props?.contactData?.uuid,
            },
          });
          if (!checkPatientConsent?.data?.contactConsents.length) {
            props?.onActionPerformed?.(COMMON_ACTION_CODES.CONSENT_WARNING, {
              callDetails: props?.callLog,
            });
          } else {
            if (props?.isOpenOutgoingCall) {
              setIsOutgoingCallOpen(true);
            }
          }
          setSelectedSingleCall((prev) => {
            return {
              ...prev,
              loading: false,
            };
          });
        }}
      >
        <HStack flex={1}>
          <VStack flex={1}>
            <HStack pl={2} pr={2} justifyContent={'space-between'}>
              <VStack flex={isWeb() ? 0.78 : 0.8}>
                <HStack flex={1} alignItems={'center'}>
                  <PatientInfoBanner
                    isLetterAvatarShow={true}
                    name={callLog?.contact?.name}
                    bannerType={'listViewBanner'}
                    identifier={'CALL_LOGS'}
                    isSelected={false}
                    headerString={getOverridenHeaderSubString()}
                    userId={callLog?.contact?.contactUuid}
                    userType={callLog?.contact?.contactType}
                    showCustomAvatar
                    getConversationTypeAvatar={getCallLogsAvatar(callLog)}
                    lastMessageElement={
                      <>
                        <HStack
                          alignItems={'center'}
                          space={1}
                          style={{ marginLeft: 0, marginTop: 0 }}
                        >
                          <Text
                            alignSelf={'center'}
                            noOfLines={1}
                            style={{ height: 20, width: isWeb() ? 'auto' : 300 }}
                            size={
                              'smNormal'
                            }
                            color={
                              Colors.Custom.Gray500
                            }
                          >
                            {getCallStatusAndCallerName()}
                          </Text>
                        </HStack>
                      </>
                    }
                  />
                </HStack>
              </VStack>
              <VStack
                flex={isWeb() ? 0.22 : 0.2}
                alignItems={'flex-end'}
                justifyContent={'space-between'}
              >
                <Text
                  size={isWeb() ? 'smNormal' : 'xsNormal'}
                  style={{
                    color: Colors.Custom.Gray500,
                    fontSize: 12,
                    marginTop: isWeb() ? 0 : 6,
                  }}
                >
                  {props?.displayTime ? props.displayTime : getChatDisplayTimeAgo(callLog?.dateTime)}
                </Text>
                {!isWeb() &&
                isShowTranscription(callLog, isCallAndTranscriptionEnable) ? (
                  <Pressable
                    style={{
                      flex: 1,
                      minHeight: 30,
                      padding: 4,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onPress={() => {
                      setSelectedSingleCall((prev) => {
                        return {
                          ...prev,
                          callLog: callLog,
                          transcriptionOpen: true,
                        };
                      });
                    }}
                  >
                    <Icon as={FeatherIcon} name="file-text" size={4} />
                  </Pressable>
                ) : null}
              </VStack>
            </HStack>
          </VStack>
          {selectedSingleCall.loading ? (
            <View
              width={'100%'}
              height={'100%'}
              justifyContent={'center'}
              justifyItems={'center'}
              position={'absolute'}
            >
              <ActivityIndicator color={Colors.Custom.PrimaryColor} />
            </View>
          ) : (
            <></>
          )}
        </HStack>
      </Pressable>
      {selectedSingleCall.transcriptionOpen &&
      <CallTranscriptionModal
        isOpen={selectedSingleCall.transcriptionOpen}
        onClose={() => {
          setSelectedSingleCall((prev) => {
            return {
              ...prev,
              callLog: {} as ICallLogEntry,
              transcriptionOpen: false,
            };
          });
        }}
        callLogEntry={selectedSingleCall.callLog}
      />
      }
      {isOutgoingCallOpen &&
        <OutboundCallSmsView
          isHideSms={true}
          showOnlyCallNumbers={true}
          visible={true}
          selectedContactData={props.contactData}
          content={<></>}
          handleAction={(actionCode: string, rawData?: any) => {
            handleActions(actionCode, rawData);
          }}
        />
      }
    </View>
  );
};

export default CallLogEntry;
