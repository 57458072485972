export const TAB_KEYS = {
    PATIENT: 'patient',
    MY_PATIENT: 'myPatient',
    PROSPECT: 'prospect',
    GROUPS: 'groups',
    LEAD_GROUPS: 'lead_groups',
    CODE_GROUPS: 'codeGroups',
    IMPORT_SUMMARY: 'importSummary',
    OVERVIEW: 'OVERVIEW'
};

export const COMPARISON_OPERATOR = {
  ANY: 'ANY'
}

export const USER_SEARCH_TYPE = {
  CURRENT_USER_LOCATION_BASED: 'CURRENT_USER_LOCATION_BASED',
  FILTERED_CURRENT_USER_LOCATION_BASED: 'FILTERED_CURRENT_USER_LOCATION_BASED',
  FILTERED_CURRENT_USER_BASED: 'FILTERED_CURRENT_USER_BASED',
  SELECTED_LOCATION_BASED: 'SELECTED_LOCATION_BASED',
  CURRENT_SELECTED_PATIENT_BASED: 'CURRENT_SELECTED_PATIENT_BASED',
  DEFAULT: 'DEFAULT',
} as const;