import {Drawer} from 'antd';
import {View} from 'native-base';
import React, {useContext, useState} from 'react';
import {BUTTON_TYPE} from '../../../../../../constants';
import {Colors} from '../../../../../../styles';
import {
  getAccountUUID,
  getAppointmentBookingLink,
} from '../../../../../../utils/commonUtils';
import AppointmentTypeSearch from '../../../../../common/AppointmentTypeSearch/AppointmentTypeSearch';
import {USER_SELECTION_CODE} from '../../../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentConstant';
import {ModalActionTitle} from '../../../../../common/ModalActionTitle/ModalActionTitle';
import {IAppointmentType} from '../../../../AccountSettings/AppointmentTypes/Interfaces';
import {IConversationData} from '../../interfaces';
import {
  getPatientGroupMemberData,
  getShortLink,
  isGroupConversation,
  isPrivateGroupConversation,
} from '../MessagingUtils';
import { getAppointmentPlaceholderLink } from '../../../../../common/EmailDrawerCommonV2/EmailDrawerCommonV2Utils';
import { getLoggedInAccountInfoForSidecar } from '../../../../../../sidecar/common/SideCardCommonUtils';
import { CommonDataContext } from '../../../../../../context/CommonDataContext';
import { usePermissions } from '../../../../../CustomHooks/usePermissions';
import { USER_ACCESS_PERMISSION } from '../../../../UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../../../../SideMenuBar/SideBarConst';

export interface IAttachAppointmentDetails {
  id: string;
  eventName: string;
  description?: string;
  appointmentUsers?: string[];
  selectedUserType: string;
  userSelection: string;
}

export interface IAttachAppointmentProps {
  isOpen: boolean;
  onClose: () => void;
  conversationData: IConversationData;
  onSendMessage: (message: string) => void;
  isGetRawLink?: boolean;
  isUpdateMode?:boolean;
}

const AttachAppointmentDrawer = (props: IAttachAppointmentProps) => {
  const accountId = getAccountUUID();
  const [appointmentData, setAppointmentData] =
    useState<IAttachAppointmentDetails>({
      id: '',
      eventName: '',
      description: '',
      appointmentUsers: [],
      selectedUserType: '',
      userSelection:USER_SELECTION_CODE.ALL_USER
    });

  const commonData = useContext(CommonDataContext);
  const {check} = usePermissions();
  const permissionConfig = check(USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code, MAIN_MENU_CODES.CALENDAR);
  const currentUserAllowedLocations = permissionConfig?.allowedLocationIds || [];

  const onChangeHandler = (
    appointmentDetails: IAttachAppointmentDetails
  ) => {
    setAppointmentData((prev) => {
      let selectedAppointmentType = {} as IAttachAppointmentDetails;
      if (appointmentDetails?.id) {
        selectedAppointmentType = appointmentDetails;
      }

      return {
        id: selectedAppointmentType?.id,
        eventName: selectedAppointmentType?.eventName,
        description: selectedAppointmentType?.description,
        appointmentUsers: appointmentDetails?.appointmentUsers,
        selectedUserType: selectedAppointmentType?.selectedUserType ,
        userSelection: selectedAppointmentType?.userSelection,
      };
    });
  };

  const sendAppointmentLink = async () => {
    let contactUUID = props.conversationData?.conversationContact?.uuid || '';
    const appointmentType = {} as IAppointmentType;
    if (
      isGroupConversation(props.conversationData) &&
      isPrivateGroupConversation(props.conversationData)
    ) {
      const groupMemberData = getPatientGroupMemberData(
        props?.conversationData?.groupConversation?.groupMembers || []
      );
      const contactData = groupMemberData.contact;
      contactUUID = contactData?.uuid || contactUUID || '';
    }
    appointmentType.id = appointmentData.id;

    let currentSubdomainBaseUrl = '';
    if (commonData.sidecarContext?.isSidecar) {
      const loggedInUserAccountInfo = await getLoggedInAccountInfoForSidecar();
      currentSubdomainBaseUrl = loggedInUserAccountInfo?.subDomainUrl || '';
    }

    const appointmentLink = getAppointmentBookingLink(
      accountId,
      contactUUID,
      currentSubdomainBaseUrl,
      appointmentType,
      appointmentData.userSelection === USER_SELECTION_CODE.CUSTOM ? appointmentData.appointmentUsers : undefined,
    );
    if (props.isGetRawLink) {
      const placeholderLink = getAppointmentPlaceholderLink({
        appointmentId: appointmentData.id,
        appointmentUsers: appointmentData.appointmentUsers || [],
      });
      props?.onSendMessage(placeholderLink);
      return;
    }
    const link = await getShortLink(appointmentLink);
    const message = `Book an appointment \n ${link}`;
    props?.onSendMessage(message);
  };

  const isEnabledSendLink = (): boolean => {
    if (!appointmentData?.id) {
      return false;
    }
    if (
      appointmentData.selectedUserType === USER_SELECTION_CODE.CUSTOM &&
      !appointmentData?.appointmentUsers?.length
    ) {
      return false;
    }
    return true;
  };

  const isSideCarContext = commonData.sidecarContext?.isSidecar;

  return (
    <Drawer
      visible={props.isOpen}
      onClose={() => {
        props.onClose();
      }}
      destroyOnClose
      width={isSideCarContext ? '100%' : '40%'}
      mask={isSideCarContext ? false : true}
      closable={false}
      placement="right"
      title={    <ModalActionTitle
        title="Book Appointment"
        titleSize={24}
        buttonList={[
          {
            show: true,
            id: 1,
            btnText: isSideCarContext ? 'back' : 'cancel',
            textColor: Colors.Custom.mainSecondaryBrown,
            variant: BUTTON_TYPE.SECONDARY,
            isTransBtn: false,
            onClick: () => {
              props.onClose();
            },
          },
          {
            show: true,
            id: 2,
            isDisabled: !isEnabledSendLink(),
            btnText: props.isGetRawLink ? 'insert' : props.isUpdateMode ? 'addLink' : 'sendLink',
            textColor: Colors.Custom.mainPrimaryPurple,
            variant: BUTTON_TYPE.PRIMARY,
            isTransBtn: false,
            onClick: () => {
              sendAppointmentLink();
              props.onClose();
            },
          },
        ]}
      />}
    >
      <View>
        <AppointmentTypeSearch
          isShowError={false}
          onChange={onChangeHandler}
          allowUserMultiSelect
          filterLocationIds={currentUserAllowedLocations}
        />
      </View>
    </Drawer>
  );
};

export default AttachAppointmentDrawer;
