import React from 'react';
import {Drawer} from 'antd';
import {View} from 'native-base';
import MessagingWindow from '../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingWindow';
import {MessagingWindowHeader} from '../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingWindowHeader';

import {CHANNEL_TYPE_CODE} from '../../RightSideContainer/TeamInbox/Conversations/ConversationConst';
import {IConversationViewDrawerProps} from './interface';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';

const ConversationView = (props: IConversationViewDrawerProps) => {
  const {selectedConversation, contactData, isDrawerVisible, onActionPerformed} = props;
  return (
    <View>
      <Drawer
        title={
          <MessagingWindowHeader
            moduleCode={'ConversationView'}
            conversation={selectedConversation || {}}
            contactData={contactData}
            onActionPerformed={() => {
              // onActionPerformed
            }}
            isDisplayActions={false}
          />
        }
        visible={isDrawerVisible}
        width={'40%'}
        onClose={() => {
          onActionPerformed(COMMON_ACTION_CODES.CLOSE_MODAL);
        }}
      >
        <View flex={1}>
        <MessagingWindow
          moduleCode={`MessagingWindow/${CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET}`}
          isInDrawerView={true}
          isDisplayHeader={false}
          selectedConversation={selectedConversation}
          onConversationActionPerformed={() => {}}
          conversationInbox={selectedConversation?.conversationInbox}
          selectedInboxTypeCode={CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET}
        />
        </View>
      </Drawer>
    </View>
  );
};
export default React.memo(ConversationView);
