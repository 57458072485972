import {gql} from "@apollo/client";

export const GET_CONSUMER_CUSTOM_CONTACT_TYPE = gql`
  query GetCustomerCustomContactType($code: String!) {
    customContactTypes(where: {
      code: {
        _eq: $code
      },
      isDefault: {
        _eq: TRUE
      }
    }) {
      id
    }
  }
`

export const GET_CUSTOM_ATTRIBUTE_ID = gql`
  query getCustomAttributeId($key: String) {
    customAttributes(where: {key: {_eq: $key}}) {
      id
      key
    }
  }
`;

export const GET_REFERRAL_ORDERS = gql`
  query GetReferralOrders($customBooleanExpression: referral_management_bool_exp!) {
  referralManagement(where: $customBooleanExpression, order_by: {createdAt: desc}) {
    id
    status
    summary
    efaxNumber
    referralAttachments {
      attachmentUuid
    }
    createdAt
    sender {
      name
      uuid
      persons{
        personAddressDetails{
          addressDetails{
            city{
              name
            }
            zipcode{
              code
            }
          }
        }
      }
    }
    receiver {
       eFaxNo : customAttributeValues(where: {customAttribute: {label: {_eq: "E Fax Number"}}}) {
        value
      }
      speciality : customAttributeValues(where: {customAttribute: {label: {_eq: "Speciality"}}}) {
        value
      }
      uuid
      name
      personAddress {
        zipcodes {
          code
        }
        cities {
          name
        }
      }
    }
  }
}
`;

export const DELETE_REFERRAL_ORDER = gql`
  mutation updateEmployer($id: uuid!) {
  updateReferralManagements(
    where: { id: { _eq: $id } },
    _set: { isDeleted: true }
  ) {
    returning {
      id
      isDeleted
    }
  }
}
`;

export default {
  GET_CONSUMER_CUSTOM_CONTACT_TYPE,
  GET_CUSTOM_ATTRIBUTE_ID,
  GET_REFERRAL_ORDERS,
  DELETE_REFERRAL_ORDER
};
