import {useLazyQuery} from '@apollo/client';
import {
  Box,
  FlatList,
  FormControl,
  HStack,
  Input,
  Spinner,
  Stack,
  Text,
  useMediaQuery,
  VStack,
} from 'native-base';
import {v4 as uuidv4} from 'uuid';
import {useContext, useEffect, useRef, useState} from 'react';
import {PHONE_NUMBER_MASK} from '../../../../../../../constants';
import {CARESTUDIO_PROXY_TO_CRM_CONTEXT} from '../../../../../../../constants/Configs';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';
import ContactsQueries from '../../../../../../../services/Contacts/ContactsQueries';
import {
  isValidEmail,
  numericStringMask,
  unmaskPhoneNumber,
} from '../../../../../../../utils/commonUtils';
import {CityAutoComplete} from '../../../../../../common/City';
import {DisplayText} from '../../../../../../common/DisplayText/DisplayText';
import {StateAutoComplete} from '../../../../../../common/State';
import {ZipCodeAutoComplete} from '../../../../../../common/ZipCodeComponent';
import {IZipCodeData} from '../../../../../../common/ZipCodeComponent/interfaces';
import { CommunicationField, CustomComponentKey, isRequiredField } from '../../CustomComponentUtils';
import { useContainerDimensions } from '../../../../../../CustomHooks/ContainerDimensionHook';
import {componentKeys, FormError} from '../../CustomWrapper/CustomComponentHelper';
import {
  FormContext,
  IFormCommonData,
  IFormComponentProps,
} from '../../CustomWrapper/CustomWrapper';
import FormComponentError from '../../CustomWrapper/FormComponentError/FormComponentError';
import FormComponentLoader from '../../CustomWrapper/FormComponentLoader/FormComponentLoader';
import { cloneDeep } from 'lodash';
import DetailPreview, { ICustomComponentPreviewData } from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailPreview/DetailPreview';
import { useIntl } from 'react-intl';
import { Colors } from '../../../../../../../styles';
import { IFormValidationOutput } from '../../CustomWrapper/interfaces';
import AllowOverrideMessage from '../../AllowOverrideMessage/AllowOverrideMessage';
import ContactPracticeToUpdateInfoMessage from '../../ContactPracticeToUpdateInfoMessage/ContactPracticeToUpdateInfoMessage';
import CustomButton from '../../CustomWrapper/CustomButton/CustomButton';
import Feather from 'react-native-vector-icons/Feather';

export interface IPatientCommunicationComponentValue {
  email?: string;
  phoneNumber?: string;
  personAddressId?: string;
  addressLine1?: string;
  addressLine2?: string;
  zipCode?: {
    id?: string;
    code?: string;
  };
  state?: {
    id?: string;
    name?: string;
    abbr?: string;
    countryId?: string;
  };
  city?: {
    id?: string;
    name?: string;
  };
}

interface IAddOrUpdatePatientCommunicationState {
  showErrors: boolean;
  loading: boolean;
  formError?: FormError;
}


const AddOrUpdatePatientCommunication = (props: IFormComponentProps) => {
  const componentRef = useRef();
  const intl = useIntl();
  const { width } = useContainerDimensions(componentRef);
  const [isMobileScreen] = [width <= 480];
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const isPreviewMode = contextData.isPreviewMode || false;
  const isAllowToShare = props?.component?.allowToShare &&  props?.component?.allowToShare === false ? false : true;
  const isPatientForm = contextData.formContext === FormContext.patientForm;
  const isPatientNote = contextData.formContext === FormContext.patientNotes;
  const addressLine1MaxChar = contextData.capabilities?.abilities?.keyAllowedOperations?.addressLine1?.maxCharLimit;
  const addressLine2MaxChar = contextData.capabilities?.abilities?.keyAllowedOperations?.addressLine2?.maxCharLimit;
  const [componentValue, setComponentValue] =
    useState<IPatientCommunicationComponentValue>(
      props.component?.selectedValue || {}
    );
  const [importFromChartLoading, setImportFromChartLoading] = useState<boolean>(false);
  const [componentState, setComponentState] =
    useState<IAddOrUpdatePatientCommunicationState>({
      showErrors: false,
      loading: false,
      formError: contextData.formError,
    });

  const [getContactDetails] = useLazyQuery(
    ContactsQueries.GET_CONTACT_COMMUNICATION_BY_CONTACT_UUID,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_PROXY_TO_CRM_CONTEXT,
        headers: contextData.headers,
      },
    }
  );

  const validateData = (currentData: IPatientCommunicationComponentValue): IFormValidationOutput => {
    // If no patient id is not found means it's lead and for lead, the section is not applicable. So validation should always return true
    if (componentState.formError === FormError.noPatientIdFound) {
      return {isValid: true, message: ''};
    }
    setComponentState((prev) => ({...prev, showErrors: true}));
    let isValid = true;
    isValid &&= !isInvalid(CommunicationField.email, currentData, currentData.email, true);
    isValid &&= !isInvalid(
      CommunicationField.phoneNumber,
      currentData,
      currentData.phoneNumber,
      true
    );
    isValid &&= !isInvalid(
      CommunicationField.addressLine1,
      currentData,
      currentData.addressLine1,
      true
    );
    isValid &&= !isInvalid(
      CommunicationField.addressLine2,
      currentData,
      currentData.addressLine2,
      true
    );
    isValid &&= !isInvalid(
      CommunicationField.zipCode,
      currentData,
      currentData.zipCode,
      true
    );
    isValid &&= !isInvalid(CommunicationField.state, currentData, currentData.state, true);
    isValid &&= !isInvalid(CommunicationField.city, currentData, currentData.city, true);
    return { isValid: isValid, message: !isValid ? `${props.component.label}: Please fill all the mandatory fields` : '' };
  };

  const isDisabled = () => !props.component?.allowToEdit || false;

  const isRequired = (field: CommunicationField) => {
    const keyAllowedOperations = contextData.capabilities?.abilities?.keyAllowedOperations;
    return isRequiredField(CustomComponentKey.PATIENT_COMMUNICATION, field, keyAllowedOperations) && !isDisabled();
  };

  const isEmailOrPhoneAdded = (currentData: IPatientCommunicationComponentValue): boolean => {
    return !!currentData.email || !!currentData.phoneNumber;
  }

  const isInvalid = (
    field: CommunicationField,
    currentData: IPatientCommunicationComponentValue,
    value?: any,
    showErrors?: boolean,
  ) => {
    const canShowErrors = showErrors || componentState.showErrors;
    switch (field) {
      case CommunicationField.email:
        if (!props.isPatientAddress) {
          if (value) {
            return !isValidEmail(value) && canShowErrors;
          }
          return !isEmailOrPhoneAdded(currentData) && canShowErrors;
        }
        return false;

      case CommunicationField.phoneNumber:
        if (!props.isPatientAddress) {
          if (value) {
            return value.length !== 10 && canShowErrors;
          }
          return !isEmailOrPhoneAdded(currentData) && canShowErrors;
        }
        return false;

      case CommunicationField.zipCode:
        return (!value || !value.id) && isRequired(field) && canShowErrors;
      default:
        return !value && isRequired(field) && canShowErrors;
    }
  };

  const getExistingData = (contactId: string, skipLoader?: boolean) => {
    if (!skipLoader) {
      setComponentState((prev) => ({...prev, loading: true}));
    }
    getContactDetails({
      variables: {
        contactId,
      },
    })
      .then((response) => {
        if (response?.data?.contacts?.length) {
          const detail = response.data.contacts[0];
          const addressData = detail?.personAddress?.length
            ? detail?.personAddress[0]
            : undefined;
          const emailData = detail?.personContact?.find((contact: any) => {
            return contact?.personContactType?.code === 'email';
          });
          const phoneData = detail?.personContact?.find((contact: any) => {
            return contact?.personContactType?.code === 'phone';
          });
          const newData = {
            ...(!props.isPatientAddress && {
              email: emailData?.value || detail.email,
              phoneNumber: unmaskPhoneNumber(
                phoneData?.value || detail.phoneNumber || ''
              ),
             }),
            personAddressId: addressData?.id,
            addressLine1: addressData?.line1,
            addressLine2: addressData?.line2,
            zipCode: addressData?.zipcodes,
            state: addressData?.states,
            city: addressData?.cities,
          };
          setComponentValue(newData);

          const componentKey = props.isPatientAddress ? componentKeys.PATIENT_ADDRESS : componentKeys.PATIENT_COMMUNICATION;
          contextData?.updateFormPrefilledByFoldProgress?.(componentKey, newData);
        }
        setComponentState((prev) => ({
          ...prev,
          loading: false,
          formError: !response?.data?.contacts
            ? FormError.existingDataAPIFail
            : prev.formError,
        }));
        setImportFromChartLoading(false);
      })
      .catch((error) => {

        setComponentState((prev) => ({
          ...prev,
          loading: false,
          formError: FormError.existingDataAPIFail,
        }));
        setImportFromChartLoading(false);
      });
  };

  useEffect(() => {
    const contactId = contextData?.contactId;
    // if fields are not editable the latest\ data should be prefilled
    // Removing !isPatientNote check here, as we need to prefill the notes data always
    if (contactId && /*!isPatientNote &&*/ (isDisabled() || !props.component.selectedValue)) {
      getExistingData(contactId);
    }
  }, []);

  useEffect(() => {
    props.onChange(cloneDeep(componentValue));
  }, [componentValue]);

  useEffect(() => {
    const isDataAvailable = !!componentValue.email || !!componentValue.phoneNumber || !!componentValue.addressLine1 || !!componentValue.addressLine2 || !!componentValue.zipCode || !!componentValue.state || !!componentValue.city;
    contextData?.updateLoadingStatus?.(componentKeys.PATIENT_ADDRESS, componentState.loading, isDataAvailable);
  }, [componentState.loading]);

  props.validateRef.current = validateData;

  const isFieldEnabled = (field: CommunicationField) => {
    if (props.component?.enabledFields) {
      return props.component?.enabledFields[field];
    }
    return true;
  };
  const getPreviewData = (): {
    id: string;
    key: string;
    value: string;
  }[] => {
    const previewData: {
      id: string;
      key: string;
      value: string;
    }[] = [];
    const data = ['email', 'phoneNumber', 'addressLine1', 'addressLine2', 'zipCode', 'state', 'city'];
    data.forEach((item) => {
      let value = componentValue[
        item as keyof IPatientCommunicationComponentValue
      ] as any;
      switch (item) {
        case 'zipCode':
          value = value?.code;
          break;
        case 'state':
          value = value?.name;
          break;
        case 'city':
          value = value?.name;
          break;
        default:
          break;
      }
      const previewItem: {
        id: string;
        key: string;
        value: string;
      } = {
        key: `${intl.formatMessage({id: item})}`,
        value: value,
        id: uuidv4(),
      };
      if (value) previewData.push(previewItem);
    });
    return previewData;
  };
  if (isPreviewMode) {
    const data = getPreviewData();
    return (
      <>
        {!componentState.loading && !componentState.formError && (
            data.length > 0 && (
              <div className={` ${isAllowToShare ? 'page-break' : 'disallow-to-share'}`}>
              <DetailPreview titleLocalId={props.component.label}>
                <FlatList
                  data={data}
                  keyExtractor={(item) => item.id}
                  renderItem={({item}) => (
                    <HStack key={item.id} flex={1}>
                      <Text flex={3}>{item.key}</Text>
                      <Text flex={7}>
                        {item.value}
                      </Text>
                    </HStack>
                  )}
                />
              </DetailPreview>
              </div>
            )
        )}
      </>
    );
  };

  const showImportButton = isPatientNote && !isPreviewMode && !isDisabled();
  const fetchImportData = () => {
    if (isPatientNote && !isPreviewMode && contextData?.contactId) {
      setImportFromChartLoading(true);
      getExistingData(contextData?.contactId, true);
    }
  };


  return (
    <VStack ref={componentRef}>
      {isPatientForm && !props.component?.allowToEdit && <ContactPracticeToUpdateInfoMessage />}
      {componentState.loading && <FormComponentLoader />}
      {!componentState.loading && componentState.formError && (
        <FormComponentError error={componentState.formError} />
      )}
      <HStack alignItems={'center'} mb={2} mt={1}>
        <Text fontSize={16} fontWeight="bold" flex={1} >
          {props.component.label}
        </Text>
        {showImportButton && (
          <CustomButton
            styles={{alignSelf: 'flex-end'}}
            title="Import from Chart"
            isDisabled={importFromChartLoading}
            leftIcon={
              importFromChartLoading ? (
                <Spinner mr={1.5} />
              ) : (
                <Feather
                  name="download"
                  size={20}
                  color={Colors.Custom.mainPrimaryPurple}
                />
              )
            }
            onPress={fetchImportData}
          />
        )}
      </HStack>

      {!componentState.loading && !componentState.formError && (
        <VStack space={4} flex={1}>
          {!props.isPatientAddress && (
            <div className='page-break'>
            <Stack
                space={4}
                direction={isMobileScreen ? 'column' : 'row'}
                flex={1}
              >
              {isFieldEnabled(CommunicationField.email) && (
                <FormControl
                  isInvalid={isInvalid(
                    CommunicationField.email,
                    componentValue,
                    componentValue.email
                  )}
                  isDisabled={isDisabled()}
                  flex={1}
                >
                  <FormControl.Label
                    isRequired={isRequired(CommunicationField.email)}
                  >
                    <DisplayText textLocalId="email" />
                  </FormControl.Label>
                  <Input
                  _focus={{borderColor: '#D0D5DD'}}
                    placeholder="abc@xyz.com"
                    value={componentValue.email}
                    isReadOnly={props.disabled}
                    onChangeText={(text: string) => {
                      setComponentValue((prev) => ({...prev, email: text}));
                    }}
                  />
                  {componentState.showErrors &&
                    componentValue.email &&
                    !isValidEmail(componentValue.email) && (
                      <Text marginTop={1} size="smNormal" color="error.500">
                        Please enter a valid email.
                      </Text>
                    )}
                  {componentState.showErrors &&
                    !isEmailOrPhoneAdded(componentValue) && (
                      <Text marginTop={1} size="smNormal" color="error.500">
                        Email or phone number is required
                      </Text>
                    )}
                </FormControl>
              )}
              {isFieldEnabled(CommunicationField.phoneNumber) && (
              <FormControl
                isInvalid={isInvalid(
                  CommunicationField.phoneNumber,
                  componentValue,
                  componentValue.phoneNumber
                )}
                isDisabled={isDisabled()}
                flex={1}
              >
                <FormControl.Label
                  isRequired={isRequired(CommunicationField.phoneNumber)}
                  >
                  <DisplayText textLocalId="phoneNumber" />
                </FormControl.Label>
                <Input
                _focus={{borderColor: '#D0D5DD'}}
                  placeholder="(123) 123-1234"
                  maxLength={14}
                  value={numericStringMask(
                    componentValue.phoneNumber || '',
                    PHONE_NUMBER_MASK
                  )}
                  isReadOnly={props.disabled}
                  onChangeText={(text: string) => {
                    const number = unmaskPhoneNumber(text);
                    setComponentValue((prev) => ({
                      ...prev,
                      phoneNumber: number,
                    }));
                  }}
                />
                {componentState.showErrors &&
                  componentValue.phoneNumber &&
                  componentValue.phoneNumber.length !== 10 && (
                    <Text marginTop={1} size="smNormal" color="error.500">
                      Please enter a valid phone number.
                    </Text>
                  )}
                {componentState.showErrors &&
                !isEmailOrPhoneAdded(componentValue) && (
                  <Text marginTop={1} size="smNormal" color="error.500">
                    Email or phone number is required
                  </Text>
                )}
              </FormControl>
              )}
            </Stack>
            </div>
          )}
          <div className='page-break'>
          <Stack
            space={4}
            direction={isMobileScreen ? 'column' : 'row'}
            flex={1}
          >
            {isFieldEnabled(CommunicationField.addressLine1) && (
            <FormControl
              isInvalid={isInvalid(
                CommunicationField.addressLine1,
                componentValue,
                componentValue.addressLine1
              )}
              isDisabled={isDisabled()}
              flex={1}
            >
              <FormControl.Label
                isRequired={isRequired(CommunicationField.addressLine1)}
              >
                <DisplayText textLocalId="addressLine1" />
              </FormControl.Label>
              <Input
              _focus={{borderColor: '#D0D5DD'}}
                placeholder="11, Main Street"
                value={componentValue.addressLine1}
                isReadOnly={props.disabled}
                maxLength={addressLine1MaxChar}
                onChangeText={(text: string) => {
                  setComponentValue((prev) => ({...prev, addressLine1: text}));
                }}
              />
            </FormControl>
            )}
            {isFieldEnabled(CommunicationField.addressLine2) && (
            <FormControl
              isInvalid={isInvalid(
                CommunicationField.addressLine2,
                componentValue,
                componentValue.addressLine2
              )}
              isDisabled={isDisabled()}
              flex={1}
            >
              <FormControl.Label
                isRequired={isRequired(CommunicationField.addressLine2)}
              >
                <DisplayText textLocalId="addressLine2" />
              </FormControl.Label>
              <Input
              _focus={{borderColor: '#D0D5DD'}}
                placeholder="11, Main Street"
                value={componentValue.addressLine2}
                isReadOnly={props.disabled}
                maxLength={addressLine2MaxChar}
                onChangeText={(text: string) => {
                  setComponentValue((prev) => ({...prev, addressLine2: text}));
                }}
              />
            </FormControl>
            )}
          </Stack>
          </div>
          <Stack
            space={4}
            direction={isMobileScreen ? 'column' : 'row'}
            flex={1}
          >
            {isFieldEnabled(CommunicationField.zipCode) && (
              <div className='page-break' style={{flex:1}}>
              <HStack flex={1}>
                <FormControl
                  flex={1}
                  isRequired={isRequired(CommunicationField.zipCode)}
                  isDisabled={isDisabled()}
                  isInvalid={isInvalid(
                    CommunicationField.zipCode,
                    componentValue,
                    componentValue.zipCode
                  )}
                >
                    <ZipCodeAutoComplete
                      customStyles={{
                        width: '100%',
                      }}
                      flex={1}
                      name={componentValue.zipCode?.code}
                      isTabDisabled={props.disabled || isDisabled()}
                      isInvalid={isInvalid(
                        CommunicationField.zipCode,
                        componentValue,
                        componentValue.zipCode
                      )}
                      apolloContext={{
                        service: CARESTUDIO_PROXY_TO_CRM_CONTEXT,
                        headers: contextData.headers,
                      }}
                      onAutoCompleteChange={(
                        selectedZipCode?: IZipCodeData
                      ) => {
                        setComponentValue((prev) => ({
                          ...prev,
                          zipCode: {
                            code: selectedZipCode?.code,
                            id: selectedZipCode?.id,
                          },
                          state: selectedZipCode?.zipcodeState,
                          city: selectedZipCode?.zipcodeCity,
                        }));
                      }}
                    />
                </FormControl>
              </HStack>
              </div>
            )}
            <div className='page-break' style={{flex:1}}>
            <HStack space={4} flex={1}>
            {isFieldEnabled(CommunicationField.state) && (
              <FormControl
                flex={1}
                isRequired={isRequired(CommunicationField.state)}
                isDisabled={isDisabled()}
                isInvalid={isInvalid(
                  CommunicationField.state,
                  componentValue,
                  componentValue.state
                )}
              >
                <StateAutoComplete
                  isTabDisabled
                  name={componentValue.state?.name}
                  onAutoCompleteChange={() => {
                    /**/
                  }}
                />
              </FormControl>
            )}
            {isFieldEnabled(CommunicationField.city) && (
              <FormControl
                flex={1}
                isRequired={isRequired(CommunicationField.city)}
                isDisabled={isDisabled()}
                isInvalid={isInvalid(
                  CommunicationField.city,
                  componentValue,
                  componentValue.city
                )}
              >
                <CityAutoComplete
                  name={componentValue.city?.name}
                  isTabDisabled
                  onAutoCompleteChange={() => {
                    /**/
                  }}
                />
              </FormControl>
            )}
            </HStack>
            </div>
          </Stack>
          {contextData.isBuilderMode && !props.component?.allowToEdit && <AllowOverrideMessage />}
        </VStack>
      )}
    </VStack>
  );
};

export default AddOrUpdatePatientCommunication;
