import React, { useCallback, useEffect, useState } from 'react';
import {Divider, HStack, Stack, Text, View, VStack} from 'native-base';
import {DATE_FORMATS} from '../../../../constants/StringConst';
import {
  getCurrentTimeZoneAbbr,
  getDateStrFromFormat,
} from '../../../../utils/DateUtils';
import FHForm from '../../../RightSideContainer/Forms/FHFormio/FHForm/FHForm';
import DetailPreview from '../PersonDetailsView/DetailPreview/DetailPreview';
import { getFlatListedNonHiddenFormComponents } from '../../../RightSideContainer/Forms/FormBuilderWidget/AddOrUpdateForm/AddOrUpdateFormHelper';
import { COMPONENT_KEYS } from '../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import { getUserData, getUserUUID, isAvergentClient } from '../../../../utils/commonUtils';
import { IUserPracticeLocation } from '../../../../services/Location/interfaces';
import { getAddressLine } from '../../../RightSideContainer/EmployerManagement/Helper';
import { findUserById } from '../PatientNotes/PatientNotesHelper';

export interface IPrintPatientNoteProps {
  components: any[] | undefined;
  answers: any;
  optionData: any;
  isPreviewMode: boolean;
  readOnly: boolean;
  formattedContactData: any;
  personData: any;
  appointmentData: any;
  notesData: any;
  accountUserList: any;
  header: any;
  onRenderComponent: (error?: any) => void;
}

export const PatientNotePrintPreviewV2 = (props: IPrintPatientNoteProps) => {
  const {notesData, appointmentData, components, answers, optionData, accountUserList} = props;
  const userUUID = getUserUUID();
  const isAvergentPatientNote = isAvergentClient();

  const [componentState, setComponentState] = useState<{loading: boolean, formLoadingStatus: {[index: string]: boolean}, selectedComponentKeys: {[index: string]: boolean}, formSelectedValues: any}>({
    loading: false,
    selectedComponentKeys: {},
    formLoadingStatus: {},
    formSelectedValues: {}
  });

  useEffect(() => {
    let isAllFormComponentsLoaded = true;
    if (!componentState.loading) {
      const flatListOfComponents = getFlatListedNonHiddenFormComponents(components || []);
        (flatListOfComponents || []).forEach((component: any) => {
        const componentKey = component.type;
        if (componentKey && COMPONENT_KEYS.includes(componentKey)) {
          if (!componentState.formLoadingStatus?.hasOwnProperty(componentKey)) {
            isAllFormComponentsLoaded = false;
          } else {
            const componentLoadingStatus = componentState.formLoadingStatus[componentKey];
            if (componentLoadingStatus) {
              isAllFormComponentsLoaded = false;
            }
          }
        }
      });
    }
    if (isAllFormComponentsLoaded && props?.onRenderComponent && typeof props?.onRenderComponent == 'function') {
      props?.onRenderComponent();
    }
  }, [componentState.formLoadingStatus, componentState.loading]);

  const updateLoadingStatus = useCallback((componentKey: string, isLoading: boolean) => {
    if (componentKey) {
      setComponentState((prev) => {
        const formLoadingStatus = {
          ...prev.formLoadingStatus,
          [componentKey]: isLoading
        };
        const isAnyFormLoading = !!(Object.values(formLoadingStatus || {}).find(isFormLoading => isFormLoading));
        return {
          ...prev,
          loading: isAnyFormLoading,
          formLoadingStatus: {
            ...formLoadingStatus,
          }
        };
      });
    }
  }, []);

  const [intakeOptions] = useState({
    ...optionData,
    updateLoadingStatus,
  });

  const renderSignedByInfo = () => {
    const signedByUserName = notesData?.signedByUserName;
    const signedByDate = getDateStrFromFormat(
      notesData?.signedDate || '',
      DATE_FORMATS.SIGNED_NOTE_DATE,
    );
    const timezoneAbbr = getCurrentTimeZoneAbbr();
    if (signedByUserName && signedByDate) {
      return (
        <div className="page-break">
          <Divider my={2} backgroundColor="black" />
          <HStack alignItems="center" my={2}>
            <Text fontSize={16}>{'Signed By '}</Text>
            <Text fontSize={16} fontWeight={600}>
              {signedByUserName}
            </Text>
            <Text fontSize={16}>{' on '}</Text>
            <Text fontSize={16} fontWeight={600}>
              {signedByDate} ({timezoneAbbr}).
            </Text>
          </HStack>
        </div>
      );
    }
  };

  const renderVisitInfo = () => {
    if (!appointmentData?.id) {
      return <></>;
    }
    return (
      <VStack>
        <DetailPreview titleLocalId={`Visit details`}>
          <View>
            <Text>
              {`Date Of Visit: ${getDateStrFromFormat(
                notesData.createdDate,
                DATE_FORMATS.MESSAGE_DATE_FORMAT,
              )}`}
            </Text>
            {!!props.notesData.linkedAppointmentId && (
              <Text fontSize={16}>
                {`Reason For Visit : ${
                  props.appointmentData?.reasonForVisit?.displayName || '-'
                }`}
              </Text>
            )}
          </View>
        </DetailPreview>
      </VStack>
    );
  };

  const renderProviderInfo = () => {
    const providerData = getUserData();
    const loggedInUser = findUserById(userUUID, accountUserList);
    const appointmentAccountLocationUuid = appointmentData?.accountLocationId;
    let practiceLocation = []

    if (appointmentAccountLocationUuid) {
      practiceLocation = loggedInUser?.userPracticeLocations?.find((item: IUserPracticeLocation) =>
          item?.accountLocation?.uuid === appointmentAccountLocationUuid
      ) || null;
    }
    const providerPhone = loggedInUser?.persons?.personContactDetails?.reduce((acc: string | null, contact: any) => {
      const phoneDetail: any | undefined = contact.contactDetails?.find((detail: any) => detail?.relationType?.code === 'phone');
      return phoneDetail ? phoneDetail.value : acc;
    }, null);

    const practiceLocationData = practiceLocation?.accountLocation?.practiceLocation
    return (
      <div className="page-break" style={{ flex: 0.5 }}>
        <Stack direction={'column'}>
          <Stack direction={'row'}>
            <Stack direction={'column'} flex={5} alignItems="flex-start">
              {practiceLocationData && (
                <span className="font-size-16">{`${getAddressLine(practiceLocationData)
                  }`}</span>
              )}
              <Stack direction={'row'} flexWrap={'wrap'}>
                {!!providerData?.email && (
                  <span className="font-size-16"> {`${providerData.email}`}</span>
                )}
                 {(!!providerPhone && !!providerData?.email) &&(
                  <span className="font-size-16"> {`, `}</span>)}
                {!!providerPhone && (
                  <span className="font-size-16"> {`${providerPhone}`}</span>
                )}
              </Stack>

              {providerData?.name && (
                <span className="font-size-16">
                  {`Provider : ${providerData.name}`} </span>
              )}
            </Stack>
          </Stack>
        </Stack>
      </div>
    );
  };

  return (
      <div className="note-preview-styles custom-form-styles">
        {!isAvergentPatientNote && (
          <>
            <div style={{ display: 'flex'}}>
              {renderProviderInfo()}
            </div>
            {renderVisitInfo()}
          </>
        )}
        <FHForm
          components={components}
          submittedResponse={answers}
          optionData={intakeOptions}
          isPreviewMode={true}
          readOnly={true}
        />
        {renderSignedByInfo()}
      </div>
  );
};

