
import { Icon, Skeleton, Spinner, Text, View } from "native-base";
import { useContext, useState } from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { Colors } from "../../../../../../styles";
import { TestIdentifiers, testID } from "../../../../../../testUtils";
import { DonutChartGraph } from "../../../../../common/Graph/DonutChartGraph";
import { getWorkflowExecutionDataByWorkflowMasterId } from "../../AddOrUpdateWorkflow/WorkflowApi";
import { IWorkflowExecutionGraph } from "../Interfaces";
import { WorkflowStatusDetail } from "./WorflowStatusDetail";
import { WorkflowStatus } from "./WorkflowStatus";
import { graphSvg } from "../../../../../common/Svg/NewWorkflowTableViewSvg/NewWorkflowTableViewSvg";
import Feather from "react-native-vector-icons/Feather";
import { UserLocationContext } from "../../../../../../context/UserLocationContext";
import { isLoginUserBusinessOwner } from "../../../../../../utils/commonUtils";







const WorkflowExecutionGraph = (props: IWorkflowExecutionGraph): JSX.Element => {

  const TabData = {
    completed: {
      label: 'Completed',
      color: Colors.tertiary[300],
      status: 'COMPLETE'
    },
    inProgress: {
      label: 'In Progress',
      color: Colors.info[300],
      status: 'IN_PROGRESS'
    },
    failed: {
      label: 'Failed',
      color: Colors.danger[300],
      status: 'ERROR'
    }
  }

  const [activeStausTab, setActiveStatusTab] = useState(TabData.completed.label);

  const [activeIconColor, setActiveIconColor] = useState(TabData.completed.color)
  const [workflowExecutionGraphData, setWorkflowExecutionGraphData] = useState<any>();
  const [workflowExecutionData, setWorkflowExecutionData] = useState<any>()
  const UserLocationContextData = useContext(UserLocationContext);
  const isBusinessOwner = isLoginUserBusinessOwner();
  const isLocationFilterEnabled = UserLocationContextData?.isLocationFilterEnabled;
  const userPracticeLocations = UserLocationContextData?.userPracticeLocations;
  enum LOADING_STATUS {
    loadingValue,
    loadedValue,
    needToLoad,
  }
  const [workflowExecutionDataLoadingStatus, setWorkflowExecutionDataLoadingStatus] = useState(LOADING_STATUS.needToLoad)
  if (!props?.workflowData?.workflowMasterId) {
    return <></>
  }

  const createWorkflowExecutionGraphData = (workflowExecutionData: any) => {
    workflowExecutionData = workflowExecutionData || {}
    const completed = workflowExecutionData?.completed || 0;
    const failed = workflowExecutionData?.failed || 0;
    const incomplete = workflowExecutionData?.incomplete || 0;
    const queued = workflowExecutionData?.queued || 0;
    props.setDisabledStatus ? props.setDisabledStatus(props.workflowData, workflowExecutionData.totalCount === 0) : undefined;
    setWorkflowExecutionData(workflowExecutionData)

    setWorkflowExecutionGraphData([
      {
        type: 'Completed Automation',
        value: completed
      },
      {
        type: 'In Progress Automation',
        value: incomplete
      },
      {
        type: 'Failed Automation',
        value: failed
      },
      {
        type: 'Queued',
        value: queued
      }
    ])
  }

  if (LOADING_STATUS.needToLoad === workflowExecutionDataLoadingStatus) {
    setWorkflowExecutionDataLoadingStatus(LOADING_STATUS.loadingValue)
    let booleanExp = {}
    if (isLocationFilterEnabled && !isBusinessOwner) {
      booleanExp = {
        locations: {
          locationId: {
            _in: userPracticeLocations
          }
        }
      }
    }

    getWorkflowExecutionDataByWorkflowMasterId(props.workflowData?.workflowMasterId, booleanExp ).then((response) => {
      const data = response?.data;
      const formatedData = {
        completed: data.completed.aggregate.count,
        failed: data.failed.aggregate.count,
        incomplete: data.incomplete.aggregate.count,
        queued: data.queued.aggregate.count,
        totalCount: data.total.aggregate.count,
      };
      if (data.completed.aggregate.count) {
        setActiveStatusTab(TabData.completed.label)
        setActiveIconColor(TabData.completed.color)
      } else if (data.incomplete.aggregate.count) {
        setActiveStatusTab(TabData.inProgress.label)
        setActiveIconColor(TabData.inProgress.color)
      } else if (data.failed.aggregate.count) {
        setActiveStatusTab(TabData.failed.label)
        setActiveIconColor(TabData.failed.color)
      }

      createWorkflowExecutionGraphData(formatedData)
      setWorkflowExecutionDataLoadingStatus(LOADING_STATUS.loadedValue)
    }, () => {
      setWorkflowExecutionDataLoadingStatus(LOADING_STATUS.loadedValue)
    })
  }

  const config = {
    appendPadding: 0,
    data: workflowExecutionGraphData,
    radius: 0.5,
    totalCount: workflowExecutionData?.totalCount,
    legend: false,
    style: {
      height: props.isIcon ? 250 : undefined,
      left: 0
    },
    angleField: 'count',
    tooltip: props.isIcon ? false : undefined,
    colorField: 'workflowStatus',
    label: {
      type: 'inner',
      content: '',
    },
    color: [Colors.tertiary[300], Colors.info[300], Colors.danger[300], Colors.muted[300]],
    interactions: [

    ],
  };

  let activeTabCount;
  let workflowState = '';
  if (activeStausTab === TabData.completed.label) {
    activeTabCount = workflowExecutionData?.completed;
    workflowState = 'WORKFLOW_COMPLETE'
  }
  if (activeStausTab === TabData.inProgress.label) {
    activeTabCount = workflowExecutionData?.incomplete;
    workflowState = 'WORKFLOW_INCOMPLETE'
  }
  if (activeStausTab === TabData.failed.label) {
    activeTabCount = workflowExecutionData?.failed;
    workflowState = 'WORKFLOW_ERROR'
  }

  return <>
    {
      workflowExecutionDataLoadingStatus === LOADING_STATUS.loadedValue ?
        props.isNewUi ?
          // <Icon //here
          //   as={AntIcon}
          //   key={'key'}
          //   // name='piechart'
          //   name='barchart'
          //   size={18}
          //   color={workflowExecutionData.totalCount ? Colors.Custom.SuccessColor : Colors.Custom.Gray600}
          // />
          <Feather
            name="bar-chart-2"
            key={'key'}
            size={22}
            color={workflowExecutionData?.totalCount ? Colors.Custom.SuccessColor : Colors.Custom.Gray600}
          />
          :
          <View flex={1} flexDirection={'row'} gap={3} height={600}>
            <View flexDirection={'column'} alignItems={"left"} justifyContent='left' style={{ width: '30%', height: 400 }}>
              <View flex={20} marginTop={5}>

                {workflowExecutionData?.completed || workflowExecutionData?.incomplete || workflowExecutionData?.failed || workflowExecutionData?.queued ? <>
                  {/* {!props.isIcon && <Text color={Colors.Custom.Gray500} size='smMedium' >Execution Results</Text>} */}
                  <DonutChartGraph {...config} ></DonutChartGraph> </> :
                  <>No Execution</>}
              </View>
              <View flex={1} paddingTop={20} flexDirection={'column'} gap={0}>
                <WorkflowStatus
                  iconColor={TabData.completed.color}
                  isActive={activeStausTab === TabData.completed.label}
                  status={TabData.completed.label}
                  value={workflowExecutionData?.completed || 0}
                  onClick={() => {
                    setActiveStatusTab(TabData.completed.label)
                    setActiveIconColor(TabData.completed.color)
                  }}
                />

                <WorkflowStatus

                  iconColor={TabData.inProgress.color}
                  key={TabData.inProgress.label}
                  onClick={() => {
                    setActiveStatusTab(TabData.inProgress.label)
                    setActiveIconColor(TabData.inProgress.color)
                  }}
                  isActive={activeStausTab === TabData.inProgress.label}
                  status={TabData.inProgress.label}
                  value={workflowExecutionData?.incomplete || 0}
                />
                <WorkflowStatus
                  iconColor={TabData.failed.color}
                  key={TabData.failed.label}
                  onClick={() => {
                    setActiveStatusTab(TabData.failed.label)
                    setActiveIconColor(TabData.failed.color)
                  }}
                  isActive={activeStausTab === TabData.failed.label}
                  status={TabData.failed.label}
                  value={workflowExecutionData?.failed || 0}
                />

              </View>
            </View >
            <View flex={1} style={{ width: '70%' }}>
              {props.workflowData?.workflowMasterId && activeStausTab &&
                <WorkflowStatusDetail
                  key={activeStausTab + props.workflowData?.workflowMasterId}
                  workflowMasterId={props.workflowData?.workflowMasterId}
                  activeTab={activeStausTab}
                  workflowExecutionData={workflowExecutionData}
                  iconColor={activeIconColor}
                  activeTabCount={activeTabCount}
                  workflowState={workflowState}
                  foldLocations={props?.foldLocations}
                />
              }
            </View>
          </View>
        :
        props.isIcon ? <Spinner size="sm" /> :
          <View {...testID(TestIdentifiers.pageLoading)} width={'full'} height='full'>
            <Skeleton size={'full'} />
          </View>}

  </>
}
export default WorkflowExecutionGraph;
