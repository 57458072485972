import * as React from 'react';

export const MailgunErrorReason:any = {
  blacklisted: 'The recipient has unsubscribed for this email',
} as const;
export enum FlowType {
  workflow = 'WORKFLOW',
  other = 'OTHER',
  MICRO_AUTOMATION = 'MICRO_AUTOMATION',
  all= 'ALL',
  form = 'FORM',
  careJourney = 'CARE_JOURNEY',
  patientCareJourney = 'PATIENT_CARE_JOURNEY',
  patients = 'PATIENTS',
  communication = 'COMMUNICATION',
  task = 'TASK',
  scheduling = 'SCHEDULING',
  services = 'SERVICES',
  journeys = 'JOURNEYS',
  products = 'PRODUCTS',
  ivr = 'IVR',
  membership = 'MEMBERSHIP',
  lab = 'LAB',
  order = 'ORDER',
  DB_TRIGGER = 'DB_TRIGGER',
  note = 'VISIT_NOTE',
  PATIENT_CASE = 'PATIENT_CASE',
  PATIENT_REFERRAL = 'PATIENT_REFERRAL',
  IMAGING_REPORT = 'IMAGING_REPORT',
  CARE_PLAN= 'PATIENT_CARE_PLAN',
  VITALS = 'VITALS',
}

export interface IWorkflowContextData {
  nodeMasterDataMap: {[key: string]: any};
  nodeMetaData: any;
  flowType: any;
  isAllowInterAction?:boolean;
  focusOnSelectNextNode?: {isFocusOnSelectNextNode:boolean, nodeType:string,sourceId: string, isEdit:boolean, sourceHandle:string, metadata:any };
  setNodeMasterDataMap?: (nodeMasterDataMap: any) => void;
  locationIds?: string[];
  locationIntersections?: string[];
  userPracticeLocations?: string[];
  isLocationFilterEnabled?:boolean;
}

export const WorkflowContext = React.createContext<IWorkflowContextData>({
  nodeMasterDataMap: {},
  nodeMetaData: undefined,
  focusOnSelectNextNode: undefined,
  flowType: FlowType.workflow,
});
