import {useEffect, useState} from 'react';
import {
  getActivityPromise,
  getVitalsWithFilters,
} from '../../../../../../../services/CommonService/AidBoxService';
import {IGoalTargetParams, IUsetGoalTargetState} from './interface';
import {getEnabledVitalListFromCapability} from '../../../../../../../utils/VitalUtils';
import {
  getLastRecordText,
  getRecordDataFormatted,
  sortOldRecords,
} from '../../Vitals/AddOrUpdateVitals/AddOrUpdateVitalsHelper';
import {useLazyQuery} from '@apollo/client';
import {FormsQueries} from '../../../../../../../services';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../../../constants/Configs';
import {formatFormsResponse} from '../../ClinicalAssessment/ClinicalAssessmentHelper';
import {ObservationSortBy} from '../../HomeMonitoringView/interface';
import {getPatientData} from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/Activities/ActivityUtils';
import { getUnitForExternalVital } from '../../HomeMonitoringView/utils';
import { GRAPH_TYPES } from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/Activities/Activities';

export const useGoalLastRecordedValue = (params: IGoalTargetParams) => {
  const {goalEntityTypeIds, goalTarget, locationId} = params;

  const vitalList = getEnabledVitalListFromCapability(locationId);

  const [getPatientSubmittedFormResponse] = useLazyQuery(
    FormsQueries.GET_FORM_RESPONSES_SCORE_BY_FORM_ID,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
    }
  );

  useEffect(() => {
    setComponentState((prev) => ({
      ...prev,
      lastRecordedValue: '',
      lastRecordedDate: '',
      loading: false,
    }));
    if (
      goalTarget.entityTypeId &&
      (goalTarget.entityCode || goalTarget?.formId)
    ) {
      getLastRecordedValue();
    }
  }, [goalTarget.entityTypeId, goalTarget.entityCode, goalTarget?.formId]);

  const [componentState, setComponentState] = useState<IUsetGoalTargetState>({
    loading: false,
    lastRecordedValue: '',
    lastRecordedDate: '',
  });

  const getLastFormValue = async (formId: string) => {
    try {
      const response = await getPatientSubmittedFormResponse({
        variables: {
          contactId: params.patientContactUuid,
          formId: formId,
        },
      });

      if (response?.data?.forms?.length) {
        const formattedFormResponse = formatFormsResponse(
          response?.data?.forms
        );

        setComponentState((prev) => ({
          ...prev,
          lastRecordedValue:
            formattedFormResponse[0]?.recentFormScore?.toString() || '',
          lastRecordedDate:
            formattedFormResponse[0]?.lastSubmittedOn?.toString(),
          loading: false,
        }));
        console.log('form response', response);
      }
    } catch (error) {}
  };

  const getLastLabValue = async (entityCode: string) => {
    try {
      const response = await getVitalsWithFilters(
        params.patientId,
        entityCode,
        1,
        0,
        params.locationId,
        undefined,
        undefined,
        undefined,
        ObservationSortBy.DESC,
        'laboratory',
        false,
        true
      );

      const reports = response?.data?.entry;

      const lastRecordedValue = reports[0]?.resource?.valueQuantity?.value;

      if (lastRecordedValue) {
        const lastRecordedOn =
          reports[0]?.resource?.issued ||
          reports[0]?.resource?.effectiveDateTime;
        setComponentState((prev) => ({
          ...prev,
          lastRecordedValue: `${lastRecordedValue} ${
            goalTarget?.unit ? goalTarget?.unit : ''
          }`,
          lastRecordedDate: lastRecordedOn,
          loading: false,
        }));
      }
    } catch (error) {
      setComponentState((prev) => ({
        ...prev,
        loading: false,
      }));
      console.log('error in fetching goals last recorded value', error);
    }
  };

  const getVitalLastRecordedData = async (entityCode: string) => {
    const vitalConfig = vitalList.find((vitalListitem) => {
      return vitalListitem.loinc === entityCode;
    });

    try {
      const observationResponse = await getVitalsWithFilters(
        params.patientId,
        vitalConfig?.loinc || '',
        1,
        0,
        params.locationId,
        undefined,
        undefined,
        undefined,
        ObservationSortBy.DESC,
        'vital-signs',
        false,
        true
      );

      const ehrRecords = observationResponse?.data?.entry || [];
      const ehrFormattedRecords = getRecordDataFormatted(ehrRecords, vitalList);
      const observationRecords = sortOldRecords(ehrFormattedRecords);
      const data = getLastRecordText(
        observationRecords || [],
        vitalConfig,
        true
      ) as {
        valueText: string;
        unit?: string;
        recordedDate: string;
        value: string;
      };

      if (data) {
        setComponentState((prev) => ({
          ...prev,
          lastRecordedValue: data?.value,
          lastRecordedDate: data?.recordedDate,
          loading: false,
        }));
      }
    } catch (error) {
      setComponentState((prev) => ({
        ...prev,
        loading: false,
      }));
      console.log('error in fetching goals last recorded value', error);
    }
  };



  const getActivityLastRecordedData = async (entityCode: string) => {
    try {
      const observationResponse = await getActivityPromise({
        patientUuid: [params.patientId],
        startDate: undefined,
        endDate: undefined,
        types: [entityCode],
        isDaily: entityCode === GRAPH_TYPES.Time ? false : true,
        accountLocationUuid: params.locationId,
        limit: 1,
        orderBy: '-date'
      });

      const activityData = getPatientData(
        observationResponse?.data || [],
        params.patientId
      );

      const lastRecordedObj = activityData?.[0]?.entry?.[0];
      const lastRecordedValue = lastRecordedObj?.value?.toFixed();
      const unit = getUnitForExternalVital(entityCode, true);

      if (lastRecordedValue) {
        const lastRecordedOn =
          lastRecordedObj?.issued || lastRecordedObj?.effectiveDateTime;
        setComponentState((prev) => ({
          ...prev,
          lastRecordedValue: `${lastRecordedValue} ${
            goalTarget?.unit || unit || ''
          }`,
          lastRecordedDate: lastRecordedOn,
          loading: false,
        }));
      }
    } catch (error) {
      setComponentState((prev) => ({
        ...prev,
        loading: false,
      }));
      console.log('error in fetching goals last recorded value', error);
    }
  };

  const getLastRecordedValue = () => {
    switch (goalTarget.entityTypeId) {
      case goalEntityTypeIds.VITAL:
        getVitalLastRecordedData(goalTarget.entityCode || '');
        break;

      case goalEntityTypeIds.FORM:
        getLastFormValue(goalTarget.formId || '');
        break;

      case goalEntityTypeIds.LAB_ANALYTE:
        getLastLabValue(goalTarget.entityCode || '');
        break;
      case goalEntityTypeIds.ACTIVITY:
        getActivityLastRecordedData(goalTarget.entityCode || '');
        break;
    }
  };

  return {
    lastRecordedValue: componentState.lastRecordedValue,
    loading: componentState.loading,
    getLastRecordedValue,
    lastRecordedDate: componentState.lastRecordedDate,
  };
};
