import {
  HStack,
  Text,
  VStack,
  View,
  Pressable,
  Center,
  Modal,
  Menu,
} from 'native-base';
import React, {useEffect, useState} from 'react';

import {Colors} from '../../../../styles/Colors';
import InfoSvg from '../../../common/Svg/InfoSvg';
import {
  getPrimaryGenderCode,
  numericStringMask,
} from '../../../../utils/commonUtils';
import {isWeb} from '../../../../utils/platformCheckUtils';
import {Popover} from 'antd';
import {DisplayCardAvatar} from '../../../common/DisplayCard/DisplayCardAvatar';
import {GROUP_MEMBER_TYPE, PHONE_NUMBER_MASK} from '../../../../constants';
import {getPatientOrProspectElem, getUserNameByContact} from './OtherDeatilsUtils';
import { useIntl } from 'react-intl';
import { ILinkedMemberInterface } from './interfaces';
import { IContactType } from '../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import { getEhrConfig } from '../../../../utils/capabilityUtils';
import { getCurrentPatientDeceasedData } from '../../../../services/CommonService/AidBoxService';
import { ScrollView } from 'react-native';

export const LinkedMemberFromNumberView = (props: ILinkedMemberInterface) => {
  const {contacts, contactData, navigate, contactType} = props;
  const [linkNumberMemberVisible, setLinkNumberMemberVisible] = useState(false);
  const phoneNumber = contactData.phoneNumber;
  const intl = useIntl();
  const ehrConfig = getEhrConfig(
    contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid,
    ''
  );
  const handleVisibleChange = (isVisible: boolean) => {
    setLinkNumberMemberVisible(isVisible);
  };
  const [componentState, setComponentState] = useState<{
    contactDeceasedDateMap:  Map<string, string>
  }>({
    contactDeceasedDateMap: new Map<string, string>(),
  });

  const fetchPatientData = async (contactData: any) => {
    const patientId = contactData?.patient?.patientId;
    if (patientId) {
      const key = `${patientId}`;
      const mapData = componentState.contactDeceasedDateMap.get(key);
      if (!mapData) {
        const deceasedDate = await getCurrentPatientDeceasedData(contactData);
        setComponentState((prev) => {
          const map = prev.contactDeceasedDateMap;
          map.set(key, deceasedDate);
          return {
            ...prev,
            contactDeceasedDateMap: map,
          }
        });
      }
    }
  }

  useEffect(() => {
    //To disable fetch desease date data for mobile due to memory leaks and screen hang issues. (After discuss with @Prasad)
    if (isWeb() && ehrConfig.isAthena) {
      contacts.forEach((contactData) => {
        fetchPatientData(contactData);
      });
    }
  }, [contacts]);

  const getDeceasedDate = (contact: any) => {
    const patientId = contact?.patient?.patientId;
    if (patientId) {
      const key = `${patientId}`;
      return componentState.contactDeceasedDateMap.get(key);
    }
    return '';
  }

  const contentView = (
    <View height={'400'}>
      <ScrollView>
      {contacts?.map((contact: any) => {
        const userName = getUserNameByContact(contact);
        return (
          <Menu.Item
            key={contact?.uuid}
            onPress={() => {
              navigate(contact);
              handleVisibleChange(false);
            }}
            style={{
              borderBottomWidth: 1,
              borderBottomColor: Colors.Custom.Gray200,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <DisplayCardAvatar
                avatarStyle={{
                  avatarSize: '10',
                  width: 28,
                  height: 28,
                  textStyle: {
                    fontSize: 12,
                  },
                  disableTop: true
                }}
                isLetterAvatarShow={true}
                userData={{
                  userId: contactData?.id || '',
                  userType: GROUP_MEMBER_TYPE.CONTACT,
                  genderCode: getPrimaryGenderCode(contact) as any,
                  contactType: contact?.contactType?.contactType?.code || '',
                  name: contact?.person,
                  userName: userName,
                }}
              />
              <VStack>
                <Text
                  fontWeight={500}
                  fontSize={14}
                  noOfLines={1}
                  style={{
                    marginLeft: 10,
                    color: 'black',
                  }}
                >
                  {userName}
                </Text>
                <Text
                  fontSize={12}
                  fontWeight={400}
                  noOfLines={1}
                  style={{
                    color: Colors.Custom.Gray500,
                    marginLeft: 10,
                  }}
                >
                  {getPatientOrProspectElem(
                    contact,
                    contact?.contactType?.contactType || ({} as IContactType),
                    {
                      deceasedDate: getDeceasedDate(contact)
                    }
                  )}
                </Text>
              </VStack>
            </View>
          </Menu.Item>
        );
      })}
      </ScrollView>
    </View>
  );

  return (
    <VStack>
      <View
        height={'1px'}
        backgroundColor={Colors.Custom.Gray200}
        width={'100%'}
      />
      <HStack
        justifyContent={'center'}
        alignContent={'center'}
        alignItems={'center'}
        padding={2}
      >
        <InfoSvg customDimension="15px"></InfoSvg>
        <Text
          flex={0.85}
          fontSize={14}
          fontWeight={400}
          color={Colors.Custom.Gray500}
          paddingLeft={1}
        >
          {`${numericStringMask(phoneNumber, PHONE_NUMBER_MASK)} Used by ${
            contacts.length
          } ${contacts.length > 1 ? 'members' : 'member'}`}
        </Text>
        <View flex={0.15}>
          {isWeb() ? (
            <Popover
              overlayInnerStyle={{
                bottom: 3,
                borderRadius: 16,
                padding: 0,
              }}
              content={contentView}
              trigger="click"
              placement="left"
              visible={linkNumberMemberVisible}
              onVisibleChange={() => {
                handleVisibleChange(false);
              }}
              overlayStyle={{borderRadius: 8}}
            >
              <Pressable
                onPress={() => {
                  handleVisibleChange(true);
                }}
              >
                <Text
                  alignSelf={'flex-end'}
                  justifySelf={'flex-end'}
                  fontSize={14}
                  fontWeight={500}
                  color={Colors.Custom.mainPrimaryPurple}
                >
                  {intl.formatMessage({id: 'view'})}
                </Text>
              </Pressable>
            </Popover>
          ) : (
            <>
              <Pressable
                onPress={() => {
                  handleVisibleChange(true);
                }}
              >
                <Text
                  alignSelf={'flex-end'}
                  justifySelf={'flex-end'}
                  fontSize={14}
                  fontWeight={500}
                  color={Colors.Custom.mainPrimaryPurple}
                >
                  {intl.formatMessage({id: 'view'})}
                </Text>
              </Pressable>
              <Center>
                <Modal
                  onClose={() => {
                    handleVisibleChange(false);
                  }}
                  animationPreset="slide"
                  isOpen={linkNumberMemberVisible}
                  overlayVisible={true}
                  style={{
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <Modal.Content
                    width={'full'}
                    paddingX={3}
                    paddingY={3}
                    style={{
                      borderRadius: 16,
                      backgroundColor: '#fff',
                      borderWidth: 1,
                      borderColor: Colors.Custom.CardBorderColor,
                    }}
                  >
                    {contentView}
                  </Modal.Content>
                </Modal>
              </Center>
            </>
          )}
        </View>
      </HStack>
    </VStack>
  );
};

export default LinkedMemberFromNumberView;
