import { useApolloClient, useLazyQuery } from '@apollo/client';
import { Drawer, Pagination } from 'antd';
import { Checkbox, HStack, Skeleton, Text, VStack, View } from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { Dimensions } from 'react-native';
import { useParams, useSearchParams } from 'react-router-dom';
import { BUTTON_TYPE, DATE_FORMATS } from '../../../../../constants';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../constants/Configs';
import { FlowType, WorkflowContext } from '../../../../../context/WorkflowContext';
import { LeadQueries, TaskQueries } from '../../../../../services';
import { Colors } from '../../../../../styles';
import { getChatDisplayTimeAgo, getDateStrFromFormat } from '../../../../../utils/DateUtils';
import { findIsAllowToRunAutomation, getAccountUUID, isLoginUserBusinessOwner } from '../../../../../utils/commonUtils';
import { ITask } from '../../../../common/CareDashboard/CareDashboardInterfaces';
import CustomTabsView from '../../../../common/CustomTabsView/CustomTabsView';
import { ModalActionTitle } from '../../../../common/ModalActionTitle/ModalActionTitle';
import AddOrUpdateCampaign from '../../../ContentManagement/CampaignManagement/AddOrUpdateCampaign/AddOrUpdateCampaign';
import { AutomationWorkflowEmployee } from '../../../MembersManagement/AutomationWorkflowEmployee';
import {
  createCampaignSegment,
  getNodeLevelResourceLogByBoolExp
} from '../../Workflow/AddOrUpdateWorkflow/WorkflowApi';
import GetContactPhotoAndNameByContactId from '../../Workflow/WorkflowTableView/Helper/GetContactPhotoAndNameByContactId';
import GetUserPhotoAndNameByUserId from '../../Workflow/WorkflowTableView/Helper/GetUserPhotoAndNameByUserId';
import moment from 'moment';
import { isAccountConfigEnabled } from '../../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../../constants/AccountConfigConst';

const RESOURCE_TYPE = {
  CONTACT: 'CONTACT',
  USER: 'USER',
  TASK: 'TASK',
  ORDER: 'ORDER'
}

const RESOURCE_STATUS = {
  PENDING: 'PENDING'
}

const TAB_TITLE = {
  PENDING: 'Pending',
  COMPLETED: 'Completed',
  GRAPH: 'graph'
}

function ShowPeopleInfo(props: {
  uiNodeId: string;
  rootNodeType: string;
  rootNodeId?: string;
  isVisible: boolean;
  setVisible: any;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const workflowContext = useContext(WorkflowContext);
  const nodeMasterDataMap = workflowContext.nodeMasterDataMap;
  const [masterList, setMasterList] = useState<any>([]);
  const pathParams = useParams();
  const workflowMasterId = pathParams.workflowId;
  const [resourceListUniqueKey, setResourceListUniqueKey] = useState<number>(new Date().getTime());
  const [resourceList, setResourceList] = useState<any>([]);
  const [pendingResourceList, setPendingResourceList] = useState<any>([]);
  const [workflowExecutionId] = useState<any>(
    searchParams.get('workflowExecutionId') || ''
  );
  const [flowType] = useState<any>(
    searchParams.get('flowType') || ''
  );
  const [loading, setLoading] = useState(false);
  const [pendingPageNumber, setPendingPageNumber] = useState(1);
  const [completedPageNumber, setCompletedPageNumber] = useState(1);
  const client = useApolloClient();
  const [getContactsByIds] = useLazyQuery(LeadQueries.getContactsByIds);
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const isBusinessOwner = isLoginUserBusinessOwner();
  const [isExporting, setIsExporting] = useState(false)


  const splitArrayIntoBatches = (array: number[], batchSize: number): number[][] => {
    const batches: number[][] = [];

    for (let i = 0; i < array.length; i += batchSize) {
      batches.push(array.slice(i, i + batchSize));
    }

    return batches;
  }


  const getContactDetails = async (contactIds: number[]) => {
    let masterContactList: {
      id: number;
      uuid: string;
      name: string;
      sexAtBirth: string;
      email: string;
      phoneNumber: string;
    }[] = [];
    contactIds = contactIds.filter((id: number) => {
      return id != -1;
    });

    const batches = splitArrayIntoBatches(contactIds, 100);

    const promiseList: any[] = [];

    batches.forEach((list)=>{
      promiseList.push(client.query({
        query: LeadQueries.getContactsByIds,
        variables: {
          contactIdList: list
        },
        fetchPolicy: 'no-cache',
      }));
    })

    const results = await Promise.allSettled(promiseList);
    results.forEach((result) => {
      if(result?.status != 'fulfilled'){
        return;
      }
      const contactResponse = result?.value;
      const contacts = (contactResponse?.data?.contacts || []).map(
        (contact: {
          id: string;
          uuid: string;
          name?: string;
          person?: {
            firstName: string;
            lastName: string;
            sexAtBirth?: {value?: string};
          };
          email?: string;
          phoneNumber?: string;
        }) => {
          return {
            id: contact?.id || '',
            firstName: contact?.person?.firstName || '',
            lastName: contact?.person?.lastName || '',
            sexAtBirth: contact?.person?.sexAtBirth?.value || '',
            email: contact?.email || '',
            phoneNumber: contact?.phoneNumber || '',
          };
        }
      );
      masterContactList = [...masterContactList, ...contacts];
    });

    return masterContactList;
  };

  const exportToCSV = async () => {
    if (isExporting) {
      return isExporting;
    }
    setIsExporting(true);

    let contactList = [];

    if (selectedList?.length) {
      contactList = selectedList.map((item: any) => {
        return {resourceId: item?.id};
      });
    } else if (currentTab.title == TAB_TITLE.PENDING) {
      contactList = (pendingResourceList || []).filter((resource: any) => {
        return resource?.resourceType == RESOURCE_TYPE.CONTACT;
      });
    } else {
      contactList = (resourceList || []).filter((resource: any) => {
        return resource?.resourceType == RESOURCE_TYPE.CONTACT && resource?.resourceId;
      });
    }
    const contactIds: any = [];
    contactList.forEach((resource: any) => {
      try {
        const contactId = parseInt(resource?.resourceId);
        if (!contactIds.includes(contactId)) {
          contactIds.push(contactId);
        }
      } catch (Exception) {
        return -1;
      }
    });

    const contacts = await getContactDetails(contactIds);

    const csvData = contacts
      .map((item: any) => Object.values(item).join(','))
      .join('\n');

    const blob = new Blob(
      ['id,FirstName,LastName,Gender,Email,Phone Number\n' + csvData],
      {type: 'text/csv'}
    );
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'exported_data.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
    setIsExporting(false);
  };


  const tenantId = getAccountUUID();
  const [selectedList, setSelectedList] = useState<any>([]);
  const [campaignDrawerState, setCampaignDrawerState] = useState<any>({
    isVisible: false,
    segmentId: null,
  });

  const [currentTab, setCurrentTab] = useState({ title:  pendingResourceList.length ? TAB_TITLE.PENDING : TAB_TITLE.COMPLETED })

  const [isShowExportButton, setIsShowExportButton] = useState(false);

  const getSingleButtonList = (isExport:boolean)=>{
    return [
      {
        show: isExport,
        id: 1,
        btnText: 'Export',
        isLoading: isExporting,
        textColor: Colors.Custom.mainSecondaryBrown,
        variant: BUTTON_TYPE.PRIMARY,
        isTransBtn: false,
        onClick: () => {
          exportToCSV()
        },
      },
      {
        show: true,
        id: 1,
        btnText: 'Close',
        textColor: Colors.Custom.mainSecondaryBrown,
        variant: BUTTON_TYPE.SECONDARY,
        isTransBtn: false,
        onClick: () => {
          props.setVisible(false);
        },
      },

    ]
  }

  const [singleButtonList, setSingleButtonList] = useState(getSingleButtonList(isShowExportButton))
  useEffect(()=>{
    let isExport = false;
    if(currentTab?.title == TAB_TITLE.PENDING){
      isExport = (pendingResourceList || []).some((resource:any)=>{
        return resource?.resourceType == RESOURCE_TYPE.CONTACT
      });
    }else {
      isExport =  (resourceList || []).some((resource:any)=>{
        return resource?.resourceType == RESOURCE_TYPE.CONTACT
      });
    }
    setIsShowExportButton(isExport);
    setSingleButtonList(()=>{
      return getSingleButtonList(isExport)
    })
  }, [pendingResourceList, resourceList, currentTab, isExporting])


  const [openModal, setOpenModal] = useState('default');
  const getMultiSelectButtonList = () => {
    const multiSelectButtonList = [

      {
        show: true,
        id: 1,
        btnText: 'Export',
        isLoading: isExporting,
        textColor: Colors.Custom.mainSecondaryBrown,
        variant: BUTTON_TYPE.PRIMARY,
        isTransBtn: false,
        onClick: () => {
          exportToCSV()
        },
      },
      {
        show: findIsAllowToRunAutomation(),
        id: 2,
        btnText: 'runAutomation',
        textColor: Colors.Custom.mainPrimaryPurple,
        btnStype: BUTTON_TYPE.PRIMARY,
        variant: BUTTON_TYPE.PRIMARY,
        isTransBtn: false,
        onClick: () => {
          setOpenModal((prev) => {
            return 'automationWorkflow';
          });
        },
      },
      {
        show: true,
        id: 3,
        btnText: 'Create Campaign',
        textColor: Colors.Custom.mainPrimaryPurple,
        btnStype: BUTTON_TYPE.PRIMARY,
        variant: BUTTON_TYPE.PRIMARY,
        isTransBtn: false,
        onClick: () => {
          setSelectedList((prev: any) => {
            if (!prev.length) {
              return;
            }
            createCampaignSegment({
              name: '',
              tenantId: tenantId,
              metadata: {
                patientFilter: {
                  contactIds: prev.map((contact: any) => {
                    try {
                      return parseInt(contact.id);
                    } catch {
                      return contact.id
                    }
                  }),
                },
              },
            }).then((resp) => {
              if (resp?.id) {
                setCampaignDrawerState({
                  isVisible: true,
                  segmentId: resp?.id,
                });
              }
            });
            return prev;
          });
        },
      },
      {
        show: true,
        id: 1,
        btnText: 'Close',
        textColor: Colors.Custom.mainSecondaryBrown,
        btnStype: BUTTON_TYPE.SECONDARY,
        variant: BUTTON_TYPE.SECONDARY,
        isTransBtn: false,
        onClick: () => {
          props.setVisible(false);
        },
      }
    ];
    return multiSelectButtonList;
  };
  const [buttonList, setButtonList] = useState(
    !selectedList.length ? singleButtonList : getMultiSelectButtonList()
  );

  useEffect(()=>{
    setButtonList( !selectedList.length ? singleButtonList : getMultiSelectButtonList())
  },[selectedList, singleButtonList, isExporting])



  const getResourceLevelLogBoolExp = (uiNodeId: string) => {
    const fromDate = searchParams.get('fromDate');
    const toDate = searchParams.get('toDate');
    let dateFilter: any;
    if (fromDate || toDate) {
      dateFilter = {};
      if (fromDate) {
        dateFilter['_gte'] = fromDate;
      }
      if (toDate) {
        dateFilter['_lte'] = toDate;
      }
    }
    const contactId = searchParams.get('contactId');

    let resourceTypeList = [RESOURCE_TYPE.CONTACT, RESOURCE_TYPE.USER, RESOURCE_TYPE.TASK, RESOURCE_TYPE.ORDER]
    if(flowType == FlowType.patients || flowType == FlowType.form || flowType == FlowType.MICRO_AUTOMATION || flowType == FlowType.journeys) {
      resourceTypeList = [RESOURCE_TYPE.CONTACT]
    }else if(flowType == FlowType.task) {
      resourceTypeList = [RESOURCE_TYPE.TASK]
    }

    const resourceLevelLogBoolExp: any = {
      resourceType: {
        _in: resourceTypeList,
      },
      tenantId: {
        _eq: tenantId,
      },
      isDeleted: {
        _eq: false,
      },
      nodeLog: {
        uiNodeId: {
          _eq: uiNodeId,
        },
      },
    };
    if (dateFilter) {
      resourceLevelLogBoolExp.createdOn = dateFilter;
      resourceLevelLogBoolExp.nodeLog.createdOn = dateFilter;
    }

    if (contactId) {
      resourceLevelLogBoolExp.resourceId = {
        _eq: contactId,
      };
    }
    if (workflowMasterId) {
      resourceLevelLogBoolExp.workflowExecutionLog = {
        workflowMasterId: {
          _eq: workflowMasterId,
        },
      };
    }

    if (workflowExecutionId) {
      resourceLevelLogBoolExp.workflowExecutionId = {
        _eq: workflowExecutionId,
      };
    } else {
      resourceLevelLogBoolExp.workflowExecutionId = {
        _is_null: false,
      };
    }
    return resourceLevelLogBoolExp;
  };
  const getPendingList = (
    rootNodeResourceList: [any],
    currentNodeResourceList: [any]
  ) => {
    if (!currentNodeResourceList.length) {
      return rootNodeResourceList;
    }
    return rootNodeResourceList.filter((rootNodeResource: any) => {
      const isMatch = currentNodeResourceList.some((currentNodeResource) => {
        return (
          rootNodeResource.workflowExecutionId ==
          currentNodeResource.workflowExecutionId &&
          rootNodeResource.resourceId == currentNodeResource.resourceId &&
          rootNodeResource.resourceType == currentNodeResource.resourceType
        );
      });
      return !isMatch;
    });
  };

  const [tasksByIds, setTasksByIds] = useState<{ [index: string]: ITask }>({});

  const [getTasksByIds, getTasksByIdQuery] = useLazyQuery(
    TaskQueries.GET_TASKS_BY_IDS,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        data = data?.getTasks;
        if (data && data.tasks && data.tasks.length > 0) {
          const tasksByIds: { [index: string]: ITask } = {};
          data.tasks.forEach((task: ITask) => {
            tasksByIds[task.id] = task;
          });

          setTasksByIds(tasksByIds);
          setResourceListUniqueKey(new Date().getTime());
        }
      },
      onError: (error: any) => {

      },
    }
  );

  const [ordersByIds, setOrdersByIds] = useState<{ [index: string]: any }>({});
  const [getOrdersByIds, getOrdersByIdQuery] = useLazyQuery(
    TaskQueries.GET_TASK_ORDER_BY_IDS,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (data && data?.taskOrders && data?.taskOrders.length > 0) {
          const orderByIds: { [index: string]: any } = {};
          data.taskOrders.forEach((taskOrder: any) => {
            orderByIds[taskOrder.externalOrderId] = taskOrder;
          });

          setOrdersByIds(orderByIds);
          setResourceListUniqueKey(new Date().getTime());
        }
      },
      onError: (error: any) => {

      },
    }
  );

  useEffect(() => {
    setLoading(true);
    const resourceLevelLogBoolExp = getResourceLevelLogBoolExp(props.uiNodeId);
    const userPracticeLocations = workflowContext?.userPracticeLocations;
    const promise1 = getNodeLevelResourceLogByBoolExp(resourceLevelLogBoolExp, isMsoEnabled, isBusinessOwner, userPracticeLocations);
    const promiseList = [promise1];
    if (props.rootNodeId) {
      const resourceLevelLogBoolExp = getResourceLevelLogBoolExp(
        props.rootNodeId
      );
      const promise2 = getNodeLevelResourceLogByBoolExp(
        resourceLevelLogBoolExp,
        isMsoEnabled,
        isBusinessOwner,
        userPracticeLocations
      );
      promiseList.push(promise2);
    }
    Promise.all(promiseList).then((responseList: any) => {
      const response = responseList[0];
      const currentNodeResourceList = (response?.resourceLevelLogs || [])
        .map((resourceLevelLog: any) => {
          return resourceLevelLog;
        })
        .filter((resourceLevelLog: any) => {
          return resourceLevelLog.resourceId;
        });

      let rootNodeResourceList = [];
      if (responseList.length > 1) {
        const response = responseList[1];
        rootNodeResourceList = (response?.resourceLevelLogs || [])
          .map((resourceLevelLog: any) => {
            return resourceLevelLog;
          })
          .filter((resourceLevelLog: any) => {
            return resourceLevelLog.resourceId;
          });
      }
      const pendingList = getPendingList(
        rootNodeResourceList,
        currentNodeResourceList
      );

      const tasksIds: string[] = [];
      const orderIds: string[] = [];

      [...(pendingList || []), ...(currentNodeResourceList || [])]?.forEach((resource) => {
        if (resource?.resourceType == RESOURCE_TYPE.TASK && resource.resourceId) {
          tasksIds.push(resource.resourceId);
        }
      });

      [...(pendingList || []), ...(currentNodeResourceList || [])]?.forEach((resource) => {
        if (resource?.resourceType == RESOURCE_TYPE.ORDER && resource.resourceId) {
          orderIds.push(resource.resourceId);
        }
      });

      if (tasksIds.length) {
        getTasksByIds({
          variables: {
            ids: tasksIds,
          },
        })
      }
      if (orderIds.length) {
        getOrdersByIds({
          variables: {
            externalOrderIds: orderIds,
          },
        })
      }
      setResourceList(currentNodeResourceList);
      setPendingResourceList(pendingList);
      setResourceListUniqueKey(new Date().getTime());
      setMasterList([...pendingList, ...currentNodeResourceList]);
      setLoading(false);
    });
  }, [
    props.uiNodeId,
    workflowExecutionId,
    tenantId,
    workflowMasterId,
    searchParams.get('contactId'),
    searchParams.get('fromDate'),
    searchParams.get('toDate'),
  ]);
  const onChangeSelection = (item1: any, isSelected: boolean) => {
    setMasterList((masterList: any) => {
      const newMasterList = masterList.map((item2: any) => {
        if (
          item1.resourceId === item2.resourceId &&
          item1.resourceType === item2.resourceType
        ) {
          item2.isSelected = isSelected;
        }
        return item2;
      });
      const selectedList = newMasterList
        .filter((item: any) => {
          return item.isSelected;
        })
        .map((item: any) => {
          return { id: item.resourceId };
        });
      const uniqueList: any = [];
      selectedList.forEach((item: any) => {
        const isMatched = uniqueList.some((uniqueItem: any) => {
          return uniqueItem?.id == item.id;
        });
        if (!isMatched) {
          uniqueList.push(item)
        }
      })
      setSelectedList(uniqueList);
      return newMasterList;
    });
  };

  function getTaskElement(resource: any) {
    if (resource.resourceType !== RESOURCE_TYPE.TASK || !tasksByIds[resource.resourceId]) {
      return <></>;
    }
    return (
      <HStack
        flex={2}
        alignContent="center"
        justifyContent={'center'}
        alignItems={'center'}
      >
        <VStack
          flex={1}
        >
          <Text size={'smMedium'} color={Colors.Custom.Gray900}>
            {tasksByIds[resource.resourceId].title || ''}
          </Text>
          {
            tasksByIds[resource.resourceId].assigneeUser?.name &&
            <Text size={'smNormal'} color={Colors.Custom.Gray500}>
              {`Assignee: ${tasksByIds[resource.resourceId].assigneeUser?.name}`}
            </Text>
          }
          {
            tasksByIds[resource.resourceId].endDateTime &&
            <Text size={'smNormal'} color={Colors.Custom.Gray500}>
              {`Due Date: ${getDateStrFromFormat(
                tasksByIds[resource.resourceId].endDateTime || new Date(),
                DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
              ) || ''}`}
            </Text>
          }
        </VStack>
      </HStack>
    );
  }

  function getTaskOrderElement(resource: any) {
    if (resource.resourceType !== RESOURCE_TYPE.ORDER || !ordersByIds[resource.resourceId]) {
      return <></>;
    }
    return (
      <HStack
        flex={2}
        alignContent="center"
        justifyContent={'center'}
        alignItems={'center'}
      >
        <VStack
          flex={1}
        >
          <Text size={'smMedium'} color={Colors.Custom.Gray900}>
            {ordersByIds[resource.resourceId]?.description || ''}
          </Text>
          {
            ordersByIds[resource.resourceId]?.externalOrderId &&
            <Text size={'smNormal'} color={Colors.Custom.Gray500}>
              {`OrderId : ${ordersByIds[resource.resourceId]?.externalOrderId}`}
            </Text>
          }
          {
            ordersByIds[resource.resourceId]?.orderStatus &&
            <Text size={'smNormal'} color={Colors.Custom.Gray500}>
              {`Status : ${ordersByIds[resource.resourceId]?.orderStatus}`}
            </Text>
          }
          {
            ordersByIds[resource.resourceId]?.orderDate &&
            <Text size={'smNormal'} color={Colors.Custom.Gray500}>
              {`Date: ${getDateStrFromFormat(
                ordersByIds[resource.resourceId].orderDate || new Date(),
                'Do MMM YYYY'
              ) || ''}`}
            </Text>
          }
          {
            ordersByIds[resource.resourceId]?.isDeleted &&
            <Text size={'smNormal'} color={Colors.Custom.Gray500}>
              {`Order deleted from underlying EHR`}
            </Text>
          }
        </VStack>
      </HStack>
    );
  }

  useEffect(() => {
    setButtonList(
      !selectedList.length ? singleButtonList : getMultiSelectButtonList()
    );
  }, [selectedList]);


  const pageSize = 10;
  return (
    <>
      <Drawer
        visible={campaignDrawerState?.isVisible}
        width={Dimensions.get('window').width * 0.7}
        onClose={() => {
          setCampaignDrawerState({
            isVisible: false,
            segmentId: null,
          });
        }}
        headerStyle={{
          display: 'none',
        }}
      >
        {campaignDrawerState?.isVisible ? (
          <AddOrUpdateCampaign
            shouldShowEdit={true}
            initData={undefined}
            segmentId={campaignDrawerState?.segmentId}
            close={() => {
              setCampaignDrawerState({
                isVisible: false,
                segmentId: null,
              });
            }}
          />
        ) : null}
      </Drawer>

      <Drawer
        visible={props.isVisible && openModal == 'automationWorkflow'}
        width={700}
        onClose={() => props.setVisible(false)}
        closable={true}
        title={<></>}
      >
        <AutomationWorkflowEmployee
          cancelBtnTxt={'Back'}
          initData={{ selectedItems: selectedList }}
          onModalClose={(shouldTriggerModal?: boolean) => {
            setOpenModal('default');
          }}
        />
      </Drawer>
      <Drawer
        visible={props.isVisible}
        width={650}
        onClose={() => props.setVisible(false)}
        closable={true}
        title={
          <ModalActionTitle
            isHeadNotSticky={true}
            title={'List'}
            titleColor={''}
            buttonList={buttonList}
          />
        }
      >
        {(loading || getTasksByIdQuery.loading || getOrdersByIdQuery?.loading) ? (
          <Skeleton.Text lines={4}></Skeleton.Text>
        ) : (
          <CustomTabsView
            isLoading={loading || getTasksByIdQuery.loading || getOrdersByIdQuery?.loading}
            key={`${resourceListUniqueKey}_${tasksByIds?.length}_${ordersByIds?.length}_${pendingPageNumber}_${completedPageNumber}`}
            onTabClick={(tabData) => {
              setCurrentTab({ title: tabData.title })
            }}
            tabsList={[
              ...(pendingResourceList.length
                ? [
                  {
                    key: `PENDING_${resourceListUniqueKey}_${tasksByIds?.length}`,
                    title: TAB_TITLE.PENDING,
                    count: pendingResourceList?.length || 0,
                    path: currentTab.title == TAB_TITLE.PENDING ? TAB_TITLE.GRAPH : '',
                    tabContainerElem: () => {
                      return (
                        <VStack height={'full'}>
                          <View flex={10} overflow={'scroll'}>
                            {(pendingResourceList || []).slice((pendingPageNumber - 1) * pageSize, (pendingPageNumber - 1) * pageSize + pageSize)?.map(
                              (resource: any) => {
                                return (
                                  <View padding={2}>
                                    {resource?.resourceType == RESOURCE_TYPE.CONTACT ? (
                                      <HStack
                                        flex={2}
                                        alignContent="center"
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                      >
                                        <Checkbox
                                          key={resource.id}
                                          defaultIsChecked={resource.isSelected}
                                          onChange={(isSelected) => {
                                            onChangeSelection(
                                              resource,
                                              isSelected
                                            );
                                          }}
                                          value={'1'}
                                          marginRight={5}
                                        />
                                        <GetContactPhotoAndNameByContactId
                                          contactId={resource?.resourceId}
                                        ></GetContactPhotoAndNameByContactId>{' '}
                                        <VStack
                                          justifyContent={'center'}
                                          alignItems={'end'}
                                        >
                                          {/* <HStack>
                                            {RESOURCE_STATUS.PENDING}
                                          </HStack> */}
                                          <HStack>
                                            {resource?.createdOn
                                              ? moment(
                                                  resource?.createdOn
                                                ).format(
                                                  'MMM DD YYYY' + ' hh:mm A'
                                                )
                                              : ''}
                                          </HStack>
                                        </VStack>
                                      </HStack>
                                    ) : (
                                      <></>
                                    )}
                                    {resource.resourceType == RESOURCE_TYPE.USER ? (
                                      <HStack
                                        flex={2}
                                        alignContent="center"
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                      >
                                        {' '}
                                        <GetUserPhotoAndNameByUserId
                                          userId={resource.resourceId}
                                        ></GetUserPhotoAndNameByUserId>{' '}
                                        <VStack
                                          justifyContent={'center'}
                                          alignItems={'end'}
                                        >
                                          {/* <HStack>
                                            {RESOURCE_STATUS.PENDING}
                                          </HStack> */}
                                          <HStack>
                                            {resource?.createdOn
                                              ? moment(
                                                  resource?.createdOn
                                                ).format(
                                                  'MMM DD YYYY' + ' hh:mm A'
                                                )
                                              : ''}
                                          </HStack>
                                        </VStack>
                                      </HStack>
                                    ) : (
                                      <></>
                                    )}
                                    {resource.resourceType === RESOURCE_TYPE.TASK &&
                                    tasksByIds[resource.resourceId] ? (
                                      getTaskElement(resource)
                                    ) : (
                                      <></>
                                    )}
                                    {resource.resourceType === RESOURCE_TYPE.ORDER &&
                                    ordersByIds?.length &&
                                    ordersByIds[resource.resourceId] ? (
                                      getTaskOrderElement(resource)
                                    ) : (
                                      <></>
                                    )}
                                  </View>
                                );
                              }
                            )}
                          </View>
                          <View flex={1} width={'full'}>
                            <Pagination responsive={true} key={TAB_TITLE.PENDING} style={{ width:'full',marginTop: 15 }} onChange={(page, pagesize) => {
                              setPendingPageNumber(page);
                            }} showSizeChanger={false} pageSize={pageSize} defaultCurrent={pendingPageNumber} total={pendingResourceList?.length}></Pagination>
                          </View>
                        </VStack>
                      );
                    },
                  },
                ]
                : []),
              ...(resourceList.length
                ? [
                  {
                    key: `COMPLETED_${resourceListUniqueKey}_${tasksByIds?.length}_${completedPageNumber}`,
                    title: TAB_TITLE.COMPLETED,
                    count: resourceList?.length || 0,
                    path: currentTab.title == TAB_TITLE.COMPLETED ? TAB_TITLE.GRAPH : '',
                    tabContainerElem: () => {
                      return (
                        <VStack height={'full'}>
                          <View flex={10} overflow={'scroll'}>
                            {(resourceList || []).slice((completedPageNumber - 1) * pageSize, (completedPageNumber - 1) * pageSize + pageSize)?.map((resource: any) => {
                              return (
                                <View padding={2}>
                                  {resource?.resourceType == RESOURCE_TYPE.CONTACT ? (
                                    <HStack
                                      flex={2}
                                      alignContent="center"
                                      justifyContent={'center'}
                                      alignItems={'center'}
                                    >
                                      <Checkbox
                                        key={resource.id}
                                        defaultIsChecked={resource.isSelected}
                                        onChange={(isSelected) => {
                                          onChangeSelection(
                                            resource,
                                            isSelected
                                          );
                                        }}
                                        value={'1'}
                                        marginRight={5}
                                      />
                                      <GetContactPhotoAndNameByContactId
                                        contactId={resource?.resourceId}
                                      ></GetContactPhotoAndNameByContactId>{' '}
                                      <VStack
                                          justifyContent={'center'}
                                          alignItems={'end'}
                                        >
                                          {/* <HStack>
                                            {resource?.nodeLog?.status}
                                          </HStack> */}
                                          <HStack>
                                            {resource?.createdOn
                                              ? moment(
                                                  resource?.createdOn
                                                ).format(
                                                  'MMM DD YYYY' + ' hh:mm A'
                                                )
                                              : ''}
                                          </HStack>
                                        </VStack>
                                    </HStack>
                                  ) : (
                                    <></>
                                  )}
                                  {resource.resourceType == RESOURCE_TYPE.USER ? (
                                    <HStack
                                      flex={2}
                                      alignContent="center"
                                      justifyContent={'center'}
                                      alignItems={'center'}
                                    >
                                      {' '}
                                      <GetUserPhotoAndNameByUserId
                                        userId={resource.resourceId}
                                      ></GetUserPhotoAndNameByUserId>{' '}
                                      <VStack
                                          justifyContent={'center'}
                                          alignItems={'end'}
                                        >
                                          {/* <HStack>
                                            {resource?.nodeLog?.status}
                                          </HStack> */}
                                          <HStack>
                                            {resource?.createdOn
                                              ? moment(
                                                  resource?.createdOn
                                                ).format(
                                                  'MMM DD YYYY' + ' hh:mm A'
                                                )
                                              : ''}
                                          </HStack>
                                        </VStack>
                                    </HStack>
                                  ) : (
                                    <></>
                                  )}
                                  {resource.resourceType === RESOURCE_TYPE.TASK &&
                                  tasksByIds[resource.resourceId] ? (
                                    getTaskElement(resource)
                                  ) : (
                                    <></>
                                  )}
                                  {resource.resourceType === RESOURCE_TYPE.ORDER &&
                                  ordersByIds &&
                                  ordersByIds[resource.resourceId] ? (
                                    getTaskOrderElement(resource)
                                  ) : (
                                    <></>
                                  )}
                                </View>
                              );
                            })}
                          </View>
                          <View flex={1} width={'full'}>
                            <Pagination responsive={true}  key={'completed'} style={{ width:'full',marginTop: 15 }} onChange={(page, pagesize) => {
                              setCompletedPageNumber(page);
                            }} showSizeChanger={false} pageSize={pageSize} defaultCurrent={completedPageNumber} total={resourceList?.length}></Pagination>
                          </View>
                        </VStack>
                      );
                    },
                  },
                ]
                : []),
            ]}
          />
        )}
      </Drawer>
    </>
  );
}

export default ShowPeopleInfo;
