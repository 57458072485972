import { useContext, useEffect, useRef, useState } from 'react';
import { CONSENT_ERROR_FOR_SIDE_CAR, DATE_FORMATS, GROUP_MEMBER_TYPE } from '../../constants';
import { getPatientName, getSecondaryValue, isString } from '../../utils/commonUtils';
import {PatientInfoBanner} from '../PatientInfoBanner/PatientInfoBanner';
import Stack from '../common/LayoutComponents/Stack';
import { getCurrentPatientDeceasedData } from '../../services/CommonService/AidBoxService';
import {IContact} from '../RightSideContainer/TeamInbox/Conversations/interfaces';
import { getAgeValue, getDateStrFromFormat } from '../../utils/DateUtils';
import { Tooltip, notification } from 'antd';
import { Colors } from '../../styles';
import { Text } from 'native-base';
import { getContactTypeValue } from '../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingUtils';
import { COMMON_ACTION_CODES, QUICK_ACTION_CODES } from '../../constants/ActionConst';
import { View } from 'react-native';
import { isAccountConfigEnabled } from '../../utils/configUtils';
import { CONFIG_CODES } from '../../constants/AccountConfigConst';
import { CommonDataContext } from '../../context/CommonDataContext';
import { useContainerDimensions } from '../CustomHooks/ContainerDimensionHook';
import { SidecarPersonActions } from './SidecarPersonActions';

interface ISidecarPatientInfoBannerProps {
  contactData: IContact;
  headerContainerRef?: any
  showAssignJourney?: boolean;
  assignJourneyOptionClicked?: () => void;
  referralOrderOptionClicked?: () => void;
}

export const SidecarPatientInfoBanner = (props: ISidecarPatientInfoBannerProps) => {
  const {contactData, assignJourneyOptionClicked, referralOrderOptionClicked, showAssignJourney} = props;
  const [deceasedDate, setDeceasedDate] = useState('');
  const showOnlyInternalChatInSidecar = isAccountConfigEnabled(CONFIG_CODES.SHOW_ONLY_INTERNAL_CHAT_IN_SIDECAR);
  const commonData = useContext(CommonDataContext);
  const isSidecarContext = commonData?.sidecarContext?.isSidecar;
  const {width} = useContainerDimensions(props?.headerContainerRef);

  const getSubtitleText = (contactData: any): JSX.Element => {
    const ageValue = getAgeValue(
      contactData?.person?.birthDate,
      deceasedDate
    );
    return (
      <Stack direction='row'>
        <Tooltip title={ageValue} placement="top" color={Colors.Custom.Gray700}>
          <Text>
            {getAgeValue(
              contactData?.person?.birthDate,
              deceasedDate
            ) &&
              getDateStrFromFormat(
                contactData?.person?.birthDate,
                DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
              )}
          </Text>
        </Tooltip>
        <Text>{contactData?.person?.birthDate && ' | '}</Text>
        <Text>
          {contactData?.contactType?.contactType &&
            getContactTypeValue(contactData.contactType.contactType)}
        </Text>
      </Stack>
    );
  };

  useEffect(()=> {
    getPatientDeceasedData(contactData);
  }, []);

  const getPatientDeceasedData = async (contactData: IContact) => {
    const deceasedDatePatient = await getCurrentPatientDeceasedData(contactData);
    setDeceasedDate(deceasedDatePatient);
  };

  const handleEdit = (actionCode?: string) => {
    if (actionCode === COMMON_ACTION_CODES.CONSENT_WARNING) {
      const message = CONSENT_ERROR_FOR_SIDE_CAR;
      notification.error({
        message,
      });
    }
  };

  return (
    <Stack direction="row" style={{flex: 1}}>
      <PatientInfoBanner
        contactActiveStatus={contactData?.isActive}
        bannerType={'headerBanner'}
        isHorizontal={true}
        isSelected={false}
        headerString={
          isString(contactData.name)
            ? contactData.name
            : getPatientName(contactData)
        }
        genderCode={
          contactData?.person?.gender?.code ||
          contactData?.person?.sexAtBirth?.code
        }
        userId={contactData?.id}
        userType={
          contactData?.patient?.patientUuid
            ? GROUP_MEMBER_TYPE.PATIENT
            : GROUP_MEMBER_TYPE.CONTACT
        }
        deceasedDate={deceasedDate}
        subtitleElement={getSubtitleText(contactData) || '-'}
        hideConversationTypeImage={true}
        loggedInTime={contactData?.additionalAttributes?.loggedInTime}
        contactData={contactData}
        showDetailProfile={false}
        showEditProfile={false}
        name={
          isString(contactData.name)
            ? contactData.name
            : getPatientName(contactData)
        }
        isSidecarView={isSidecarContext}
        hideOnlineIndicator={false}
        headerContainerWidth={width ? width - 100 : undefined}
      />
      <View style={{alignItems: 'center', marginLeft: 2, marginTop: 8, justifyContent: 'center'}}>
        <SidecarPersonActions
          showPhoneCall={!showOnlyInternalChatInSidecar}
          shouldShowEmail={true}
          showVideoCall={!showOnlyInternalChatInSidecar}
          showSms={true}
          contactInfo={props?.contactData}
          email={
            props?.contactData?.email ||
            getSecondaryValue(contactData?.personContact, 'email') ||
            ''
          }
          phoneNumber={
            props?.contactData?.phoneNumber ||
            getSecondaryValue(contactData?.personContact, 'phone') ||
            ''
          }
          handleEdit={handleEdit}
          showAssignJourney={showAssignJourney ?? false}
          onPersonActionPerformed={(actionCode: string, rawData?: any) => {
            if (actionCode === QUICK_ACTION_CODES.ADD_CARE_JOURNEY) {
              assignJourneyOptionClicked?.();
            }
            if (actionCode === QUICK_ACTION_CODES.ADD_REFERRAL_ORDER) {
              referralOrderOptionClicked?.();
            }
          }}
        />
      </View>
    </Stack>
  );
};
