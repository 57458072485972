import React from 'react';
import {ICommonSvgProps} from './interfaces';


const PreferencesProfileSvg = (props: ICommonSvgProps) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.9744 15.6666C13.8418 13.2571 13.104 11.5 9.00008 11.5C4.89613 11.5 4.15838 13.2571 4.02576 15.6666M11.5001 6.49996C11.5001 7.88067 10.3808 8.99996 9.00008 8.99996C7.61937 8.99996 6.50008 7.88067 6.50008 6.49996C6.50008 5.11925 7.61937 3.99996 9.00008 3.99996C10.3808 3.99996 11.5001 5.11925 11.5001 6.49996ZM17.3334 8.99996C17.3334 13.6023 13.6025 17.3333 9.00008 17.3333C4.39771 17.3333 0.666748 13.6023 0.666748 8.99996C0.666748 4.39759 4.39771 0.666626 9.00008 0.666626C13.6025 0.666626 17.3334 4.39759 17.3334 8.99996Z" stroke="#6F7A90" stroke-linecap="round"/>
    </svg>
  );
};

export default PreferencesProfileSvg;