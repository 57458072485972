import { useState, useCallback } from 'react';
import axios, { AxiosResponse } from 'axios';
import LocalStorage from '../../utils/LocalStorage';

const useLazyAxiosQuery = (url: string) => {
  const [data, setData] = useState<AxiosResponse | null>(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const executeFetch = useCallback(
    async (queryParams?: object) => {
      setLoading(true);
      setError(false);
      setErrorMessage(null);

      const abortController = new AbortController();
      const userDataString = await LocalStorage.getItem('user');
      const userData = JSON.parse(userDataString);
      axios.defaults.headers = {
        'access-token': userData?.access_token || '',
        fold_access_token: userData?.fold_access_token || '',
        accountUUID: userData?.account_id || '',
      } as any;

      try {
        const response = await axios.get(url, {
          params: queryParams,
          signal: abortController.signal,
        });
        setData(response.data);
        setLoading(false);
        return response.data;
      } catch (e: any) {
        if (e.name === 'AbortError') {
          console.log('Request was aborted');
        } else {
          setError(true);
          setErrorMessage(e.message);
          setLoading(false);
        }
      }

      return () => {
        abortController.abort();
      };
    },
    [url]
  );

  return { executeFetch, data, loading, error, errorMessage };
};

export default useLazyAxiosQuery;
