import { Coding } from 'fhir/r4';
import { CheckIcon, Skeleton, VStack } from 'native-base'
import React, { useEffect, useState } from 'react'
import { getAppointmentCancelReason } from '../../../../../../services/CommonService/AidBoxService';
import { Colors } from '../../../../../../styles';
import { EXTENSION_URLS } from '../../../../../PersonOmniView/MiddleContainer/PatientNotes/components/AddOrUpdateTemplate/constant';
import { DisplayText } from '../../../../DisplayText/DisplayText';
import { ModalActionSelect } from '../../../../ModalActionCommonComponent/ModalActionSelect';

interface IAppointmentCancelReasonProps {
  isNoShow?: boolean;
  onReasonSelect: (reason: Coding | undefined) => void;
  locationId: string | undefined
}

interface IAppointmentCancelReasonState {
  loading: boolean;
  list: Coding[];
  value: Coding | undefined;
}

const AppointmentCancelReason = (props: IAppointmentCancelReasonProps) => {
  const { isNoShow, onReasonSelect, locationId } = props;
  const [componentState, setComponentState] = useState<IAppointmentCancelReasonState>({
    loading: false as boolean,
    list: [] as Coding[],
    value: undefined as Coding | undefined,
  });

  const setNoShowDefault = (reasons: Coding[]) => {
    const noShowReason = reasons.find((item) => (item.display?.toLowerCase().includes('no show') && item.extension?.find((extension) => extension.url === EXTENSION_URLS.noShow)?.valueBoolean));
    if (noShowReason) {
      setComponentState((prev) => ({...prev, value: noShowReason}));
      onReasonSelect(noShowReason);
    }
  }

  const fetchReasonData = async () => {
    setComponentState((prev) => ({ ...prev, loading: true }));
    try {
      const response = await getAppointmentCancelReason(locationId);
      let reasons: Coding[] = [];
      if (response.data?.expansion?.contains?.length) {
        reasons = response.data?.expansion?.contains;
      }
      setComponentState((prev) => ({ ...prev, loading: false, list: reasons }));
      if (isNoShow) {
        setNoShowDefault(reasons);
      }
    } catch (error) {
      setComponentState((prev) => ({ ...prev, loading: false }));
    }
  }

  useEffect(() => {
    if (isNoShow) {
      setNoShowDefault(componentState.list);
    }
  }, [isNoShow])

  useEffect(() => {
    fetchReasonData();
  }, [])

  return (
    <VStack space={2}>
      <DisplayText
        size={'smMedium'}
        extraStyles={{
          color: Colors.Custom.Gray500,
          fontWeight: 400,
          fontSize: 14,
        }}
        textLocalId={'cancelReason'}
      />
      {componentState.loading &&
        <Skeleton height={8} rounded={8} />
      }
      {!componentState.loading &&
        <ModalActionSelect
          selectedValue={componentState.value?.code}
          placeholder="Select Cancel Reason"
          endIcon={<CheckIcon size="5" />}
          onValueChange={(value: string) => {
            const reason = componentState.list.find((type) => {
              return type.code === value;
            });
            setComponentState((prev) => ({...prev, value: reason}));
            onReasonSelect(reason);
          }}
          data={componentState.list}
          selectItemProps={{
            key: 'code',
            label: 'display',
            value: 'code',
          }}
          customStyle={{ flex: 1 }}
        />
      }
    </VStack>
  )
}

export default AppointmentCancelReason
