import {MessageActionID} from '../MessageAction/MessageActionView';

export const EMAIL_REPLY_ACTIONS = {
  REPLY: 'REPLY',
  REPLY_ALL: 'REPLY_ALL',
  INTERNAL: 'INTERNAL',
  FORWARD: 'FORWARD',
} as const;

export const SEND_EMAIL_OPTIONS = {
  TEMPLATE: 'TEMPLATE',
  RAW_INPUT: 'RAW_INPUT',
} as const;

export const EMAIL_MESSAGE_ACTIONS = [
  MessageActionID.emailReply,
  MessageActionID.replyAll,
  MessageActionID.forward,
  MessageActionID.markAsReadUnread,
  MessageActionID.assignType,
  MessageActionID.createTask,
];

export const MAX_ATTACHMENT_UPLOAD_SIZE = 3 * 1000 * 1000;

export const MESSAGE_DRAFT_TYPES = {
  REPLY_DRAFT: 'REPLY_DRAFT',
  REPLY_ALL_DRAFT: 'REPLY_ALL_DRAFT',
  FORWARD_DRAFT: 'FORWARD_DRAFT',
  INTERNAL_NOTE: 'INTERNAL_NOTE',
  NEW_DRAFT: 'NEW_DRAFT',
} as const;

export const DRAFT_INTERVAL = 500;

export const NEW_DRAFT_CONVERSATION_UUID = '-1';

export const SEND_BULK_EMAIL_DELAY = 200;

export const ID_FOR_EMAIL_SIGNATURE = 'id-for-email-signature'
