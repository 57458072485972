export interface ICareProgramType {
  code: string,
  value: string,
};

export interface IPayerCareProgram {
  careProgramType: ICareProgramType;
};

export interface ICareProgramStepStatus {
  code: string;
  value: string;
}

export interface ICareProgramEnrollmentLog {
  id: string,
  enrollmentDateTime?: Date | string,
  enrollmentBy?: string,
  notes?: string
}

export interface IAdditionalAttribute {
  attributeKey: string,
  attributeValue: any,
  attributeValueDataTypeCode: string,
}

export interface ISubStep {
  careProgramSubStepTypeId: string,
  careProgramSubStepType?: {
    code: string,
    value: string,
  },
  careProgramStepStatus?: {
    code: string,
    value: string,
  }
}

export interface IStepsLog {
  careProgramStepId: string,
  careProgramStepStatusId: string,
  careProgramStepStatus: ICareProgramStepStatus,
  expiresOn?: Date | undefined,
  subStep: ISubStep,
  careProgramStep: {
    id: string,
    careProgramStepType: {
      code: string;
    }
    careProgramStepOutreaches: {
      stepOutreachLog: IStepOutreachLog
    }[]
  }
}

export interface ISetpStatus {
  value: string,
  id: string,
  sequence_number: number,
}

export enum StepOutreachLogModeOfContact {
  IN_PERSON = 'In Person',
  VOICE_CALL = 'Voice Call',
  CHAT = 'Chat',
  SMS = 'SMS',
  EMAIL = 'Email',
}

export interface IStepOutreachLog {
  outcomeId: string;
  outreachCompletedBy: string;
  outreachDateTime: string;
  outreachId: string;
  resourceId: string;
  resourceType: string;
  status: ISetpStatus
  statusId: string;
  taskId: null;
  typeId: string;
  stepId: string;
  id: string;
  note: string;
  communicationMode: StepOutreachLogModeOfContact;
  declineDate?: string;
}

export interface IContactCareProgramStepOutreach {
  sequence: number,
  name: string,
  typeId: string,
  isRequired: boolean,
  id: string,
  stepOutreachLog: IStepOutreachLog,
}

export interface IContactCareProgramSteps {
  careProgramStepTypeId: string,
  sequence_number: number,
  displaySequenceNumber: number,
  id: string,
  careProgramStepType: {
    code: string,
  }
  subjectTypeCode?: string,

}

export interface IContactCareProgramResponse {
  contactId: string,
  payerCareProgram: IPayerCareProgram,
  stepsLog: IStepsLog[],
  contactCareProgramSteps: IContactCareProgramSteps[],
  enrollmentLog?: ICareProgramEnrollmentLog,
  additionalAttributes?: IAdditionalAttribute[]
  id: string,
  membershipYear: number,
}

export interface IParticipantEle{
  contactId: string
}
export interface IAppointmentResponse {
  id: string;
  startDateTime: string;
  participants: IParticipantEle[];
  appointmentType: {
    visitType: {
      code: string;
    };
  };
}

export interface IDashboardColumnProps {
  onActionPerformed: (actionCode: string, extraData: any) => void;
  isCareProgramEnabled: boolean;
  tabCode: string;
  filterCode?: string;
  selectedItemsCount?: number;
  sortOrderBy?:{
    foldScore?: string,
    hccV24?: string,
    hccV28?: string
  }
  sortFoldScore: (order?: string) => void;
  sortHccV24: (order?: string) => void;
  sortHccV28: (order?: string) => void;
}

export interface UpdateCareProgramStepOutreachInput {
  id: string;
  stepId: string;
  statusId: string;
  typeId?: string;
  resourceId?: string;
  resourceType?: string;
  outreachId: string;
  communicationMode?: string;
  outcomeId?: string;
  note?: string;
}
