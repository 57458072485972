import {useEffect, useState} from "react";
import {View,Text, Pressable} from "react-native";
import {Colors} from "../../../styles";
import {Popover} from "antd";
import {COMMON_ACTION_CODES} from "../../../constants/ActionConst";
import {EMAIL_SIGNATURE_NO_SIGNATURE} from "./PreserenceConst";
import {styles} from "./PreferenceStyle";
import {IEmailSignature, IEmailSignaturePreference} from "./PreferencesInterface";
import './PreferenceSetting.css'
import {IAttachSignatureOptionPopover} from "../EmailDrawerCommonV2/interfaces";

export const AttachSignatureOptionPopover = (props: IAttachSignatureOptionPopover) => {
  const [stateData, setStateData] = useState({
    emailSignaturePreference : [EMAIL_SIGNATURE_NO_SIGNATURE, props?.userDefaultSignature] as IEmailSignature[],
  })

  const popoverBody = () => {
    return (
      <View style={{
        width: 300,
      }}>
        {stateData?.emailSignaturePreference?.map?.((view: IEmailSignaturePreference) => {
          return (
            <Pressable
              key={view.id}
              style={styles.radioButton}
              onPress={() => {
                props?.onActionPerformed(COMMON_ACTION_CODES.ITEM_SELECT, view)
              }}
            >
              <View style={props?.selectedSignaturePreference?.id === view?.id? styles.radioCircleSelected:styles.radioCircle}>
                {props?.selectedSignaturePreference?.id === view?.id && <View style={styles.selectedRadioCircle} />}
              </View>
              <Text style={{
                  marginLeft: 8,
                  fontSize: 14,
                  color: Colors.FoldPixel.GRAY400,
                }}
              >
                {view?.title}
              </Text>
            </Pressable>
          )
        })}
      </View>
    );
  };

  return <>
    <Popover
      placement="topLeft"
      overlayInnerStyle={{
        padding: 0,
        borderRadius: 8,
      }}
      overlayClassName="update-Signature-Info"
      content={popoverBody}
      trigger="click"
      visible={true}
      onVisibleChange={() => {
        props?.onActionPerformed(COMMON_ACTION_CODES.CLOSE_POPUP)
      }}
    ></Popover>
  </>
}