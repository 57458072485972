import { AxiosInstance } from 'axios';
import { IParticipantSearch } from '../../../../common/CalendarWidget/ParticipantAutoComplete/ParticipantInterfaces';

export interface ExecuteWorkflowProps {
  isOpen: boolean;
  workflowData?: any;
  isHideGroup?:boolean;
  isHideEmployer?:boolean;
  onCompleteAction: (actionCode: string, record?: WorkflowData, response?:any) => void;
  showWorkflowList?: boolean;
  workflowLocations?: string[];
}

export interface WorkflowData {
  id: string;
  createdOn: string;
  name: string;
  description: string;
  isEnabled: boolean;
  tagList: any;
  workflowMasterId: string;
  asyncNodeList: string;
  isSendSurveyForm: boolean;
  tenantId: string;
  eventType: string;
  entity: string;
  flowType: string;
  isDeleted: boolean;
  triggerInfo: TriggerInfo;
  isDisabled: boolean;
}

export interface TriggerInfo {
  id: string;
  date: string;
  day: string;
  frequency: string;
  time: string;
  triggerCondition: string;
  triggerType: string;
}

export enum ExecutionViewCodes {
  MemberSearch = 'MemberSearch',
  GroupSearch = 'GroupSearch',
  LeadGroupSearch = 'LeadGroupSearch',
  EmployerSearch = 'EmployerSearch'
}

export interface ExecuteWorkflowState {
  submitting: boolean;
  apiError: boolean;
  selectedMembers: IParticipantSearch[];
  selectedGroups: any[];
  selectedEmployerIds: string[];
  viewCode: ExecutionViewCodes;
  selectedOption: ExecutionViewCodes.MemberSearch | ExecutionViewCodes.GroupSearch | ExecutionViewCodes.EmployerSearch;
  isPersonLevelWorkflow: boolean;
  workflow?: WorkflowData;
  workflowList: WorkflowData[];
}

export interface ExecuteWorkflowControllerArgs {
  state: ExecuteWorkflowState;
  setState: React.Dispatch<React.SetStateAction<ExecuteWorkflowState>>;
  tenantId: string;
  userUuid: string;
  workflowService: AxiosInstance;
  toast: any;
  showToast: any;
  onCompleteAction: (actionCode: string, record?: WorkflowData) => void;
  workflowData?: WorkflowData;
  showWorkflowList?: boolean;
  level: string;
}
