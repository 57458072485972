import {deleteDraft, getDraft, saveDraft} from "../../../../../common/EmailDrawerCommonV2/EmailDrawerCommonV2Api";
import {MESSAGE_DRAFT_TYPES} from "../../../../../common/EmailDrawerCommonV2/EmailDrawerCommonV2Const";
import {IAllDraftBody, IConversationDraftData, IConversationReplyDraft, IMessageDraftTypes} from "../../../../../common/EmailDrawerCommonV2/interfaces";

export class MessageStorage {
  private static messageStorageInst: MessageStorage;
  private conversationsDraftData: IConversationDraftData;
  constructor() {
    this.conversationsDraftData = {};
    if (MessageStorage.messageStorageInst) {
      throw new Error('Use MessageStorage.eventBusInst instead of new.');
    }
    MessageStorage.messageStorageInst = this;
  }
  static getMessageStorageInstance(): MessageStorage {
    return MessageStorage.messageStorageInst ?? (MessageStorage.messageStorageInst = new MessageStorage());
  }

  public saveConversationMessage(conversationUuid: string, draftData: Partial<IAllDraftBody>, draftType: IMessageDraftTypes) {
    if (conversationUuid) {
      if (draftData && Object.keys(draftData)?.length < 2) {
        draftData.isDataPresent = true;
        this.setSingleConversationDraftData(conversationUuid, draftData as any);
      }
      saveDraft({
        conversationUuid: conversationUuid,
        draftType: draftType,
        draftData: draftData,
      });

    }
  }

  public async getSaveConversationMessage(conversationUuid: string, draftType: IMessageDraftTypes, abortSignal: AbortSignal): Promise<Record<string, any>> {
    if (conversationUuid) {
      try {
        const existingDraftData = await getDraft({
          conversationUuid: conversationUuid,
          draftType: draftType,
          abortSignal,
        });
        return existingDraftData || {};
      } catch (error) {
        return {};
      }
    }
    return {};
  }

  public async deleteDraftConversationMessage (conversationUuid: string, draftType: IMessageDraftTypes)  {
    this.deleteSingleConversationDraftData(conversationUuid);
    return await deleteDraft({conversationUuid, draftType});
  };

  public saveConversationsDraftData(conversationsDraftData: IConversationDraftData)  {
    if (conversationsDraftData && Object.keys(conversationsDraftData)?.length) {
      this.conversationsDraftData = {...this.conversationsDraftData, ...conversationsDraftData};
      if (this.conversationsDraftData && Object.keys(this.conversationsDraftData)?.length) {
        Object.keys(this.conversationsDraftData).forEach((singleConversationUuid) => {
          this.conversationsDraftData[singleConversationUuid].isDataPresent = true;
        });
      }
    }
  };

  public getSingleConversationDraftData(conversationUuid: string): IConversationReplyDraft  {
    return this.conversationsDraftData[conversationUuid];
  };

  public setSingleConversationDraftData(conversationUuid: string, conversationsDraftData: IConversationReplyDraft)  {

    return this.conversationsDraftData[conversationUuid] = conversationsDraftData;
  };

  public deleteAllConversationsDraftData()  {
    this.conversationsDraftData = {};
  };

  public deleteSingleConversationDraftData(conversationUuid: string)  {
    delete this.conversationsDraftData[conversationUuid];
  };
}
