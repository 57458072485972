import React, { useEffect, useContext, useState } from 'react';
import {Collapse, Divider} from 'antd';
import {Content} from 'antd/lib/layout/layout';
import {
  Pressable,
  ScrollView,
  Text,
  useMediaQuery,
  View,
  VStack,
} from 'native-base';
import {Dimensions} from 'react-native';
import {
  GROUP_MEMBER_TYPE,
  MLOV_CATEGORY,
  PARENT_CODE,
  PERSON_TYPES,
  SMALL_WINDOW_1400
} from '../../../../../constants';
import {COMMON_ACTION_CODES, CONVERSATION_ACTION_CODES, PATIENT_QUICK_PROFILE_PARENT_CODES} from '../../../../../constants/ActionConst';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {Colors} from '../../../../../styles';
import {
  getSecondaryValue,
  isEmployerRole,
  IS_FEATURE_FLAG_ENABLED,
  isEnableCareProgram,
  isInsuranceEnable,
  isMasterAccount,
  getPatientLocationUuidFromPracticeLocations,
  getCaslAbility,
} from '../../../../../utils/commonUtils';
import {EventBus} from '../../../../../utils/EventBus';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../../../utils/mlovUtils';
import ContactNotesView from '../../../../common/ContactNotesView/ContactNotesView';
import {ContactRelationView} from '../../../../common/ContactRelationView';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import GroupListPanel from '../../../../common/GroupList/GroupListPanel';
// import { TrackedDataView } from '../../../../PersonOmniView/LeftContainer/OtherDetails/TrackedDataView.web';
import {PersonActions} from '../../../../common/PersonActions/PersonActions';
import PrivateMessageView from '../../../../common/PrivateMessageView/PrivateMessageView';
import SubscriptionView from '../../../../common/SubscriptionView/SubscriptionView';
import TaskIconOther from '../../../../common/Svg/TaskIconOther';
import ContactDetailView from '../../../../PersonOmniView/LeftContainer/OtherDetails/ContactDetailView';
import {DevicePropertiesView} from '../../../../PersonOmniView/LeftContainer/OtherDetails/DevicePropertiesView.web';
import {PatientAppointmentDataView} from '../../../../PersonOmniView/LeftContainer/OtherDetails/PatientAppointmentDataView';
import StickyNotes from '../../../../PersonOmniView/LeftContainer/OtherDetails/StickyNotes';
import {TagDataListView} from '../../../../PersonOmniView/LeftContainer/OtherDetails/TagDataListView';
import EntityEventsListView from '../../../../PersonOmniView/LeftContainer/PamiDetail/EntityEventsListView';
import {PERSON_ACTION_CODES} from '../../../../PersonOmniView/PersonHeaderBar/PersonAction/PersonActionPopover/ActionConst';
import CareTeamView from '../../../../PersonOmniView/RightContainer/CareTeamView/CareTeamView';
import {MemberGroupList} from '../../../../PersonOmniView/RightContainer/MemberGroupList';
import {MAIN_MENU_CODES, USER_ROLE_SIDE_BAR_CODES_PERMISSION_WISE} from '../../../../SideMenuBar/SideBarConst';
import ConversationActionView from '../ConversationActionView/ConversationActionView';
import {
  IContact,
  IContactType,
  IConversationData,
  ICustomerDetailsProps,
} from '../interfaces';
import {isGroupConversation} from '../MessagingWindow/MessagingUtils';
import ContactTimelineView from './ContactDetailsTabs/ActivityTimeLine/ContactTimelineView/ContactTimelineView';
import {DetailsPanelReadOnly} from './DetailsPanelReadOnly';
import {styles} from './MessagingContactDetailsStyles';
import ProfileDetailView from './ProfileDetailView';
import {TaskDetailPanel} from './TaskDetailPanel';
import TaskPanel from './TaskPanel';
import { useNavigate } from 'react-router-dom';
import CareJourneyDetailsView from '../../../../common/CareJourneyDeatilsView/CareJourneyDetailsView';
import { isPatientDetailViewAllowed, USER_ACCESS_PERMISSION } from '../../../UserAccess/UserAccessPermission';
import { TaskPanelType } from '../../../../TaskCard/TaskEnum';
import { getDeceasedDate } from '../../../../../services/CommonService/AidBoxService';
import { usePrevious } from 'react-use';
import { checkAbilityAccess, checkAccountConfigAbilityAccess, getEhrConfig } from '../../../../../utils/capabilityUtils';
import { getOrgAbilites } from '../../../../../utils/capabilityUtils';
import { FHIR_RESOURCE } from '../../../../../constants/FhirConstant';
import { useIntl } from 'react-intl';
import InsuranceView from '../../../../Insurance';
import CareProgramPanel from '../../../../common/CareProgramPanel/CareProgramPanel';
import Stack from '../../../../common/LayoutComponents/Stack';
import NoteSvgIcon from '../../../../common/Svg/NoteSvgIcon';
import TimeLoggingComponent from './TimeLoggingComponent';

import FoldPermitCan from '../../../../CommonComponents/FoldPermitCan/FoldPermitCan';


interface customerDetailsProps {
  currentCustomer: ICustomerDetailsProps;
  contactData: IContact;
  conversationData?: IConversationData;
  onActionPerformed?: (
    actionCode: string,
    selectedData: unknown,
    isRank2?: boolean
  ) => {};
  isPatientDetailView?: boolean;
  contactType?: IContactType;
  borderLessView?: boolean;
  height?: number;
  extra?: any;
  showEditProfileOnPatientInfoBanner?: boolean;
  parentCode: string;
  hintExtraDetail?: any;
  showDetailProfile?: boolean;
  btnTextVisibility?: any;
  showCloseDrawerIcon?: boolean;
  patientHieSyncCall?: boolean;
  isEmployerDrillDown?: boolean;
}
// {
//   showEhrBtn?: boolean;
//   showMeetBtn?: boolean;
//   showChatBtn?: boolean;
//   showPhoneBtn?: boolean;
//   shouldShowBtn?: boolean;
//   showAppointmentBtn?: boolean;
//   showChartBtn?: boolean;
//   showSmsBtn?: boolean;
//   showEmailBtn?:boolean;
// };
export const DetailsPanel = (props: customerDetailsProps) => {
  const {height} = Dimensions.get('window');
  const {Panel} = Collapse;
  const commonData = useContext(CommonDataContext);
  const userSettings = commonData.userSettings;
  const allowInsurance = isInsuranceEnable(userSettings);
  const isMasterAccountFlag = isMasterAccount();
  const {contactData, conversationData, borderLessView, parentCode, contactType} = props;  
  const intl = useIntl();
  const eventBus = EventBus.getEventBusInstance();

  const navigate = useNavigate();
  const isPatientDetailViewAllowedConfig = isPatientDetailViewAllowed();
  
  const isGroupMessage = () => {
    if (conversationData) {
      return isGroupConversation(conversationData);
    }
    return false;
  };
  const [stateData, setStateData] = useState({
    showSubscriptionData: contactData?.uuid ? true : false,
  });

  const [activeTab, setActiveTab] = useState(isEmployerRole() ? 2 : 0);
  const isSideCarContext = commonData.sidecarContext?.isSidecar;
  const [isSmallWindow] = useMediaQuery([
    { maxWidth: SMALL_WINDOW_1400 },
  ]);
  const isSmallScreen = isSmallWindow && !isSideCarContext;

  const mlovData = useContext(CommonDataContext);
  const isCareProgramEnabled = isEnableCareProgram(mlovData.userSettings);
  const [shouldShowApointment, showAppointment] = useState(false);
  const [shouldShowAddNotes, showAddNotes] = useState(false);
  const [deceasedDate, setDeceasedDate] = useState('');
  const showPatientTaskPanel = contactData?.patient !== null;
  const patientLocationUuid = getPatientLocationUuidFromPracticeLocations(contactData?.contactPracticeLocations);

  const canPatientUpdate = checkAccountConfigAbilityAccess(FHIR_RESOURCE.PATIENT, 'canUpdate')?.isEnabled;

  const onGroupListActionPerformed = (actionCode: string, actionData: any) => {
    if (props?.onActionPerformed) {
      props?.onActionPerformed(actionCode, actionData);
    }
  };
  const isCompactView = props.parentCode === PATIENT_QUICK_PROFILE_PARENT_CODES.MESSAGING;

  const getGroupListElemWithoutConversation = () => {
    let groupListElem = <View></View>;
    if (!contactData?.uuid) {
      return groupListElem;
    }
    const groupMemberTypeList =
      getMlovListFromCategory(mlovData.MLOV, MLOV_CATEGORY.GroupMemberType) ||
      [];
    const contactTypeId = getMlovIdFromCode(
      groupMemberTypeList,
      GROUP_MEMBER_TYPE.PATIENT
    );
    const groupMemberData = {
      groupUserId: contactData?.uuid,
      groupUserTypeId: contactTypeId,
    };
    groupListElem = (
      <GroupListPanel
        isDefaultClose={false}
        groupMemberData={groupMemberData as any}
        onGroupListActionPerformed={onGroupListActionPerformed}
        parentCode={PARENT_CODE.MESSAGING_DETAIL_PANEL}
        style={{marginBottom: 10, borderWidth: 0}}
        shouldDisableLimit={true}
        contactData={contactData}
        conversationData={conversationData}
      />
    );

    return groupListElem;
  };

  const handleEdit = (actionCode: string) => {
    if (props?.contactType?.code === PERSON_TYPES.CUSTOMER && !canPatientUpdate){
      return;
    }
    if (props.onActionPerformed) {
      let newContactData = contactData;
      if (actionCode === COMMON_ACTION_CODES.CONSENT_WARNING) {
        newContactData = {...contactData, showConsentError: true};
      }
      props.onActionPerformed(COMMON_ACTION_CODES.EDIT, newContactData);
    }
  };

  const handleEmail = (isRank2: boolean) => {
    if (props.onActionPerformed) {
      props.onActionPerformed(COMMON_ACTION_CODES.MAIL, contactData, isRank2);
    }
  };
  const handleSMS = (data: any) => {
    if (props.onActionPerformed) {
      props.onActionPerformed(COMMON_ACTION_CODES.SMS, contactData);
    }
  };
  const handleCloudCall = (
    actionCode: string,
    contactData: IContact,
    fromNumber: string,
    toNumber?: string
  ) => {
    if (actionCode === COMMON_ACTION_CODES.CLOUD_CALL) {
      eventBus.broadcastEvent('CLOUD_CALL', {
        contactData: contactData,
        fromNumber: fromNumber,
        toNumber: toNumber,
      });
    }
  };
  const ehrConfig = getEhrConfig(patientLocationUuid, '');
  const handleCall = () => {
    if (props.onActionPerformed) {
      props.onActionPerformed(COMMON_ACTION_CODES.CALL, contactData);
    }
  };
  const canShowMembership = () => {
    return IS_FEATURE_FLAG_ENABLED('ENABLE_MEMBERSHIP');
  };



  useEffect(() => {
    setStateData((prev) => {
      return {
        ...prev,
        showSubscriptionData: contactData?.uuid ? true : false,
      };
    });
  }, [props.contactData]);

  const renderTaskContent = () => {
    if (!conversationData) {
      return;
    }
    return (
      <VStack style={styles.panelBox}>
        <View
          style={[
            {
              backgroundColor: '#ffffff',
              marginHorizontal: 24,
              borderRadius: 8,
              // @ts-expect-error: Let's ignore a compile error like this unreachable code
              boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.06)`,
              paddingHorizontal: 16,
              paddingVertical: 8,
            },
          ]}
        >
          <TaskDetailPanel
            contactData={contactData}
            conversationData={conversationData}
          />
        </View>
      </VStack>
    );
  };

  const renderCarePrograms = () => {
    const showCareProgramWidget = isEnableCareProgram(mlovData.userSettings);
    const contactId = contactData.uuid;
    if (!showCareProgramWidget || !contactId) {
      return <></>;
    }
    return (
      <VStack style={styles.panelBox}>
        <Stack direction='row' style={{alignItems: 'center', margin: 24  }}>
          <View style={{marginRight: 8}}>
            <NoteSvgIcon />
          </View>
          <Text style={{ fontSize: 20, fontWeight: '700' }}>
            {'Care Programs'}
          </Text>
        </Stack>
        <View
          style={[
            {
              marginHorizontal: 24,
              borderRadius: 8,
            },
          ]}
        >
          <CareProgramPanel
            contactId={contactId}
            contact={contactData}
            contactType={contactType || {} as IContactType}
            locationId={patientLocationUuid}
          />
        </View>
      </VStack>
    );
  };

  const renderConversationDetails = () => {
    return (
      <VStack style={styles.panelBox}>
        <View
          style={[
            {
              backgroundColor: '#ffffff',
              marginHorizontal: 24,
              borderRadius: 8,
              // @ts-expect-error: Let's ignore a compile error like this unreachable code
              boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.06)`,
              paddingHorizontal: 16,
              paddingVertical: 8,
            },
          ]}
        >
          <DetailsPanelReadOnly contactData={contactData} conversationUuid={conversationData?.uuid} channelType={conversationData?.conversationInbox?.channelType}/>
        </View>
      </VStack>
    );
  };

  const privateMessage = () => {
    return (
      <PrivateMessageView
        isDefaultClose={false}
        conversationData={conversationData?.id}
        style={{
          marginBottom: 10,
          borderWidth: borderLessView ? 0 : 1,
        }}
      />
    );
  };

  const appointmentData = () => {
    return (
      <PatientAppointmentDataView
        style={{marginBottom: 10, borderWidth: 0}}
        taggableType={'Contact'}
        contactId={contactData.id}
        contactData={contactData}
        shouldShowApointment={shouldShowApointment}
        onClose={() => {
          showAppointment(false);
        }}
        borderLessView={props?.borderLessView}
        showMore
      />
    );
  };
  const stickyNotesData = () => {
    return (
      <StickyNotes
        contactId={contactData?.id}
        contactUuid={contactData?.uuid}
        titleText={intl.formatMessage({id: 'stickyNote'})}
      />
    );
  };
  const contactNotesData = () => {
    return (
      <ContactNotesView
        isDefaultClose={false}
        contactId={contactData.id}
        style={{marginBottom: 10, borderWidth: 0}}
        shouldShowAddNotes={shouldShowAddNotes}
        onClose={() => {
          showAddNotes(false);
        }}
      />
    );
  };


  const getMemberGroupList = () => {
    return <MemberGroupList contactId={contactData?.id} />;
  };
  const getCareTeamView = () => {
    const CONTACT_TYPE_CUSTOMER = PERSON_TYPES.CUSTOMER;
    if (props.contactType?.code === CONTACT_TYPE_CUSTOMER) {
    return (
      <CareTeamView
        isDefaultClose={false}
        style={{marginBottom: 10}}
        contactData={contactData}
      />
    );
  }
  return <></>;
  };

  const getContactRelationView = () => {
    return (
      <ContactRelationView
        isDefaultClose={false}
        contactData={contactData}
        style={{marginBottom: 10, borderWidth: 0}}
        isCompactView={isCompactView}
      />
    );
  };

  const getInsuranceData = () => {
    return (
      <InsuranceView
        contactId={contactData?.id}
        patientId={contactData?.patient?.patientId}
        contactUuid={contactData?.uuid}
        patientLocationUuid={patientLocationUuid}
      />
    )
  }

  const getSubscriptionData = () => {
    return (
      <View>
        <SubscriptionView
          contactUuid={contactData.uuid || ''}
          isDefaultClose={false}
          style={{marginBottom: 10, borderWidth: 0}}
          stripeCusId={contactData?.contactAdditionalInfos?.stripeCustomerId}
        />
      </View>
    );
  };


  const getConversationInfoData = () => {
    return (
      <View
        style={{
          backgroundColor: Colors.Custom.Gray50,
        }}
      >
        <View style={{padding: 24}}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginLeft: 9,
              }}
            >
              <View style={{marginRight: 10}}>
                <TaskIconOther />
              </View>
              <DisplayText
                size={'xlBold'}
                extraStyles={{color: Colors.primary['700'] || ''}}
                textLocalId={'inboxDetails'}
              />
            </View>
          </View>
        </View>
        {renderConversationDetails()}
      </View>
    );
  };


  const renderBottomBox = () => {
    switch (activeTab) {
      case 0:
        return (
          <View>
            {stickyNotesData()}
            {renderCarePrograms()}
            {showPatientTaskPanel && !isMasterAccountFlag &&
             <FoldPermitCan 
             resource={MAIN_MENU_CODES.TASKS}
             action={USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code}
             component={<TaskPanel
              key={TaskPanelType.PATIENT}
              contactData={contactData}
              showDetailProfile={props.showDetailProfile}
              panelType={TaskPanelType.PATIENT}
            />}
             />
            }
            {!isMasterAccountFlag &&
            <FoldPermitCan 
            resource={MAIN_MENU_CODES.TASKS}
            action={USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code}
            component={<TaskPanel
              key={TaskPanelType.INTERNAL}
              panelType={TaskPanelType.INTERNAL}
              contactData={contactData}
              showDetailProfile={props.showDetailProfile}
              />}
            />
          }
            <View>
              {!isGroupMessage() &&
                conversationData &&
                getConversationInfoData()}
            </View>
            <FoldPermitCan 
            resource={MAIN_MENU_CODES.CALENDAR}
            action={USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code}
            component={appointmentData()}
            />
            {/* <CareTeam contactUuid={contactData.uuid || ''} /> */}
            {conversationData?.id ? privateMessage() : null}
            {contactNotesData()}
            {/* FOR FUTURE PURPOSE */}
            {props?.contactType?.code === 'CUSTOMER' && (
              <CareJourneyDetailsView contactData={contactData} />
            )}
            {getContactRelationView()}
            {getMemberGroupList()}
            {getCareTeamView()}
            {canShowMembership() && stateData.showSubscriptionData
              ? getSubscriptionData()
              : null}
            {!conversationData ? (
              <EntityEventsListView patient={contactData} />
            ) : null}
            {/* <RelatedConversationView
              style={{marginBottom: 10}}
              contactData={contactData}
              conversationData={conversationData}
            /> */}
            {/* <TrackedDataView contactData={contactData} /> */}
            {!isGroupMessage() && conversationData && false && ( //due to absence of data hide the device info. May useful in future
              <DevicePropertiesView
                isDefaultClose={false}
                style={{marginBottom: 10, borderWidth: 0}}
                conversationData={conversationData}
              />
            )}
            {(allowInsurance) &&  contactData?.patient !== null &&  getInsuranceData()}
          </View>
        );
      case 1:
        return <View>{getGroupListElemWithoutConversation()}</View>;
      case 2:
        return (
          <View>
            <ContactDetailView
              hintExtraDetail={props.hintExtraDetail}
              isDefaultClose={false}
              contactData={contactData}
              onlyShowInnerContent={true}
              handleEmail={handleEmail}
              onActionPerformed={props?.onActionPerformed}
              handleCloudCall={handleCloudCall}
              handleEdit={handleEdit}
              navigate={navigate}
              contactType={props?.contactType}
            />
          </View>
        );
      case 3:
        return <ContactTimelineView contactData={contactData} />;
      default:
        return null;
    }
  };

  const shouldShowCommunication = () => {
    if (USER_ROLE_SIDE_BAR_CODES_PERMISSION_WISE.USER.includes(
      MAIN_MENU_CODES.INBOX
    )) {
      return true;
    } else {
      return false
    }
  }

  return (
    <Content className="common-collapse">
      <View
        style={{
          borderWidth: !borderLessView ? 1 : 0,
          borderLeftWidth: 0,
          borderTopWidth: 0,
          borderColor: '#e4e7ec',
          overflow: 'hidden',
          // marginLeft: !borderLessView ? 10 : 0,
          backgroundColor: '#fff',
          borderRightWidth: 0,
        }}
      >
        <VStack style={{height: (props.height || height)}}>
          <View style={{width: '100%', zIndex: 2, flexShrink: 0}}>
            <View
              backgroundColor={'#fff'}
              style={{
                borderRadius: 8,
                paddingVertical: 24,
                paddingHorizontal: props?.borderLessView ? 10 : 24,
                borderBottomEndRadius: 0,
                borderBottomStartRadius: 0,
                paddingBottom: 24,
              }}
            >
              <ProfileDetailView
                hintExtraDetail={props.hintExtraDetail}
                isBigBannerNameTruncate={'73%'}
                conversationData={conversationData}
                contactData={contactData}
                contactType={props?.contactType}
                handleEmail={handleEmail}
                handleSMS={handleSMS}
                handleCall={handleCall}
                handleCloudCall={handleCloudCall}
                handleEdit={handleEdit}
                showEditProfileOnPatientInfoBanner={
                  props.showEditProfileOnPatientInfoBanner &&
                  canPatientUpdate
                }
                showDetailProfile={props.showDetailProfile}
                onActionPerformed={(type: any, data: any) => {
                  if (type == PERSON_ACTION_CODES.CREATE_NOTES) {
                    showAddNotes(true);
                  }

                  if (type == PERSON_ACTION_CODES.SCHEDULE_APPOINTMENT) {
                    showAppointment(true);
                  }
                  props?.onActionPerformed?.(type, data);
                }}
                parentCode={parentCode}
                showCloseDrawerIcon={props?.showCloseDrawerIcon}
                patientHieSyncCall={props.patientHieSyncCall}
              />
            </View>
            <View width={'full'} alignItems={'center'}>
              <View
                style={{
                  height: 1,
                  backgroundColor: Colors.Custom.Gray200,
                }}
                width={'100%'}
              ></View>
            </View>
            {isEmployerRole() ? (
              <></>
            ) : (
              <>
                <TagDataListView
                  taggableType={'Contact'}
                  contactData={contactData}
                  conversationData={conversationData}
                  style={{marginBottom: 10}}
                />
                <View width={'full'} alignItems={'center'}>
                  <View
                    style={{
                      height: 1,
                      backgroundColor: Colors.Custom.Gray200,
                    }}
                    width={'100%'}
                  ></View>
                </View>
                <View
                  style={{
                    paddingHorizontal: 15,
                    paddingVertical: 12,
                    backgroundColor: '#ffffff',
                  }}
                >
                  <PersonActions
                    showPhoneCall={props.btnTextVisibility?.showPhoneBtn}
                    shouldShowEmail={props.btnTextVisibility?.showEmailBtn}
                    showVideoCall={props.btnTextVisibility?.showMeetBtn}
                    showAppointment={
                      props.btnTextVisibility?.showAppointmentBtn
                    }
                    showNotes={props.btnTextVisibility?.showNotes}
                    showSms={props.btnTextVisibility?.showSmsBtn}
                    showShowInEhr={props.btnTextVisibility?.showEhrBtn}
                    showCharts={
                      contactData?.contactType?.contactType?.code !=
                        PERSON_TYPES.CUSTOMER || isPatientDetailViewAllowedConfig
                        ? props.btnTextVisibility?.showCharts
                        : false
                    }
                    showViewProfile={
                      contactData?.contactType?.contactType?.code !=
                        PERSON_TYPES.CUSTOMER || isPatientDetailViewAllowedConfig
                        ? props.btnTextVisibility?.showViewProfile
                        : false
                    }
                    contactInfo={props?.contactData}
                    email={
                      props?.contactData?.email ||
                      getSecondaryValue(contactData?.personContact, 'email') ||
                      ''
                    }
                    phoneNumber={
                      props?.contactData?.phoneNumber ||
                      getSecondaryValue(contactData?.personContact, 'phone') ||
                      ''
                    }
                    extra={props?.extra}
                    handleEdit={handleEdit}
                    parentCode={parentCode}
                    onPersonActionPerformed={(code: string, data: any) => {
                      props.onActionPerformed?.(code, data);
                    }}
                    showEditProfileOnPersonAction={
                      !props.showEditProfileOnPatientInfoBanner &&
                      canPatientUpdate
                    }
                    onEditDetails={() => {
                      props.onActionPerformed?.(
                        COMMON_ACTION_CODES.EDIT,
                        contactData
                      );
                    }}
                    isEmployerDrillDown={props?.isEmployerDrillDown}
                  />
                </View>
                <View width={'full'} alignItems={'center'}>
                  <View
                    style={{
                      height: 1,
                      backgroundColor: Colors.Custom.Gray200,
                    }}
                    width={'100%'}
                  ></View>
                </View>
              </>
            )}
            <View
              style={{
                maxWidth: '100%',
                overflow: 'scroll',
              }}
            >
              <View
                style={{
                  borderBottomWidth: 1,
                  borderColor: '#e4e7ec',
                  backgroundColor: '#ffffff',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {!isEmployerRole() ? (
                  <>
                    {' '}
                    <Pressable
                      onPress={() => {
                        setActiveTab(0);
                      }}
                      style={{
                        paddingVertical: 16,
                        paddingHorizontal: 8,
                        backgroundColor: activeTab == 0 ? '#FAF5FE' : '#ffffff',
                        borderBottomColor: '#825AC7',
                        borderBottomWidth: activeTab == 0 ? 2 : 0,
                      }}
                    >
                      <Text
                        color={
                          activeTab == 0 ? '#825AC7' : Colors.Custom.Gray500
                        }
                        size={'smBold'}
                      >
                        Summary
                      </Text>
                    </Pressable>{' '}
                    {shouldShowCommunication() &&
                    <FoldPermitCan 
                    resource={MAIN_MENU_CODES.INBOX}
                    action={USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code}
                    component={<Pressable
                      onPress={() => {
                        setActiveTab(1);
                      }}
                      style={{
                        paddingVertical: 16,
                        paddingHorizontal: 8,
                        backgroundColor: activeTab == 1 ? '#FAF5FE' : '#ffffff',
                        borderBottomColor: '#825AC7',
                        borderBottomWidth: activeTab == 1 ? 2 : 0,
                      }}
                    >
                      <Text
                        color={
                          activeTab == 1 ? '#825AC7' : Colors.Custom.Gray500
                        }
                        size={'smBold'}
                      >
                        Communication
                      </Text>
                    </Pressable>}
                    />
                    }
                  </>
                ) : (
                  <></>
                )}

                <Pressable
                  onPress={() => {
                    // eventBus.broadcastEvent(WINDOW_EVENT_CODES.PATIENT_UPDATES, { fromSection: 'PERSON_OMNI_VIEW' });
                    setActiveTab(2);
                  }}
                  style={{
                    paddingVertical: 16,
                    paddingHorizontal: 8,
                    backgroundColor:
                      activeTab == 2 || isEmployerRole()
                        ? '#FAF5FE'
                        : '#ffffff',
                    borderBottomColor: '#825AC7',
                    borderBottomWidth:
                      activeTab == 2 || isEmployerRole() ? 2 : 0,
                  }}
                >
                  <Text
                    color={
                      activeTab == 2 || isEmployerRole()
                        ? '#825AC7'
                        : Colors.Custom.Gray500
                    }
                    size={'smBold'}
                  >
                    Profile
                  </Text>
                </Pressable>

                {contactData?.patient?.id && !isEmployerRole() ? (
                  <Pressable
                    onPress={() => {
                      setActiveTab(3);
                    }}
                    style={{
                      paddingVertical: 16,
                      paddingHorizontal: 8,
                      backgroundColor: activeTab == 3 ? '#FAF5FE' : '#ffffff',
                      borderBottomColor: '#825AC7',
                      borderBottomWidth: activeTab == 3 ? 3 : 0,
                    }}
                  >
                    <Text
                      color={activeTab == 3 ? '#825AC7' : Colors.Custom.Gray500}
                      size={'smBold'}
                    >
                      CRM Activity
                    </Text>
                  </Pressable>
                ) : (
                  <></>
                )}
              </View>
            </View>
          </View>
          <ScrollView style={{flexGrow: 1}}>
            <VStack
              style={
                {
                  // paddingTop: props?.height ? 350 : 318,
                }
              }
            >
              <VStack style={{marginBottom: 0}}>
                <View
                  style={{
                    backgroundColor: Colors.Custom.Gray50,
                    paddingBottom: 20,
                  }}
                >
                  {renderBottomBox()}
                </View>
              </VStack>

              <View>
                {!isGroupMessage() && false && (
                  <Collapse
                    defaultActiveKey={['Conversation_Details_1']}
                    expandIconPosition={'right'}
                    style={{marginBottom: 10}}
                  >
                    <Panel
                      header={
                        <DisplayText
                          size={'lgMedium'}
                          extraStyles={{color: Colors.Custom.Gray900}}
                          textLocalId={'conversationDetails'}
                        />
                      }
                      key={'Conversation_Details_1'}
                    >
                      <VStack style={styles.panelBox}>
                        <DetailsPanelReadOnly contactData={contactData} />
                      </VStack>
                    </Panel>
                  </Collapse>
                )}
              </View>
            </VStack>
          </ScrollView>
        </VStack>
      </View>
    </Content>
  );
};
