import React from 'react';
import {ClipPath, Defs, G, Path, Rect, Svg} from 'react-native-svg';
import {Colors} from '../../../styles/Colors';
import {ICommonSvgProps} from './interfaces';

const PenSvg = (props: ICommonSvgProps) => {
  const strokeColor = props.customStrokeColor || Colors.FoldPixel.PRIMARY300;
  const size = props.size || 16;
  return (
    <Svg width={size} height={size} viewBox="0 0 16 16" fill="none">
      <G clip-path="url(#clip0_846_5064)">
        <Path
          d="M9.57338 2.7191L10.1913 2.10118C11.2151 1.07738 12.875 1.07738 13.8988 2.10118C14.9226 3.12498 14.9226 4.7849 13.8988 5.8087L13.2809 6.42662M9.57338 2.7191C9.57338 2.7191 9.65062 4.03218 10.8092 5.19078C11.9678 6.34938 13.2809 6.42662 13.2809 6.42662M9.57338 2.7191L3.89255 8.39993C3.50778 8.7847 3.31539 8.97709 3.14993 9.18922C2.95476 9.43945 2.78743 9.7102 2.6509 9.99668C2.53516 10.2395 2.44912 10.4976 2.27704 11.0139L1.54787 13.2014M13.2809 6.42662L7.60007 12.1074C7.21529 12.4922 7.02291 12.6846 6.81078 12.8501C6.56055 13.0452 6.2898 13.2126 6.00332 13.3491C5.76047 13.4648 5.50235 13.5509 4.98612 13.723L2.79862 14.4521M2.79862 14.4521L2.2639 14.6304C2.00986 14.715 1.72977 14.6489 1.54042 14.4596C1.35107 14.2702 1.28495 13.9901 1.36963 13.7361L1.54787 13.2014M2.79862 14.4521L1.54787 13.2014"
          stroke={strokeColor}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_846_5064">
          <Rect width="16" height="16" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default PenSvg;
