import React from 'react';
import {ISearchFieldProps} from '../../../Interfaces';
import PAMISearch, { PAMISearchType } from '../PAMISearch/PAMISearch';

const MedicationSearch = (props: ISearchFieldProps) => {
  const {value, isShowError, onChange, locationId} = props;

  const getSelectedValue = () => {
    return value ? {
      text: value.name || value.text || value.drugName,
      coding: value.coding
    } : undefined;
  };

  return (
    <PAMISearch
      value={getSelectedValue()}
      addNewOptionEnabled={true}
      searchType={PAMISearchType.medication}
      placeholder="Search medications"
      isShowError={isShowError}
      locationId={locationId}
      onChange={(value) => {
        if (value) {
          onChange({
            name: value.text || value.name,
            coding: value.coding,
          });
        } else {
          onChange(undefined);
        }
      }}
    />
  );
};

export default MedicationSearch;
