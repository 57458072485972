import {useIntl} from "react-intl";
import {Text, View} from 'react-native'
import PreferencesNoEmailSignatureSvg from "../Svg/PreferencesNoEmailSignatureSvg"
import {FoldButton} from "../../CommonComponents/FoldButton/FoldButton";
import {COMMON_ACTION_CODES} from "../../../constants/ActionConst";
import {Colors} from "../../../styles";

export const NoEmailSignatureView = (props: {
  onActionPerformed: (actionCode: string, actionData?: any) => void
}) => {
  const intl = useIntl();

  return <>
    <View style={{
      alignContent: 'center',
      justifyContent: 'center',
      height: 262
    }}>
      <View style={{
        alignItems: 'center'
      }}>
        <PreferencesNoEmailSignatureSvg/>
      </View>
      <Text style={{
        textAlign: 'center',
        marginTop: 16,
        paddingHorizontal: 120,
        color: Colors.FoldPixel.GRAY200,
        lineHeight: 16.8
      }}>
        {intl.formatMessage({id: 'noSignatureMessage'})}
      </Text>
      <View style={{
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 16,
      }}>
        <FoldButton
          customProps={{
            btnText: 'Add Signature',
          }}
          nativeProps={{
            onPress() {
              props?.onActionPerformed(COMMON_ACTION_CODES.ADD)
            },
            backgroundColor: Colors.Custom.Primary300,
            _text: {
              fontSize: 14,
              lineHeight: 16.8,
            },
            paddingX: 2,
            marginLeft: 2
          }}
        />
      </View>
    </View>
  </>
}