import React from "react";
import {Svg, Path} from "react-native-svg";

function RedirectIconSvg() {
  return (
    <Svg
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <Path
        stroke="#8C5AE2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4 12l8-8m0 0H6m6 0v6"
      ></Path>
    </Svg>
  );
}

export default RedirectIconSvg;
