import { BellOutlined, ExclamationCircleOutlined, MailOutlined } from '@ant-design/icons';
import { Drawer, Table, Timeline } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { IconButton, Text, View } from 'native-base';
import { useEffect, useState } from 'react';
import { SmsIcon } from '../../../../../../assets/images/Avatar/SmsIcon';
import { BUTTON_TYPE } from '../../../../../../constants';
import { Colors } from '../../../../../../styles';
import { getFormattedDate } from '../../../../../../utils/DateUtils';
import { replacePlaceHolder } from '../../../../../../utils/smsUtils';
import { DisplayText } from '../../../../../common/DisplayText/DisplayText';
import { ModalActionTitle } from '../../../../../common/ModalActionTitle/ModalActionTitle';
import DisplayTemplateById from '../../../../../common/TemplateSearch/DisplayTemplateById';
import { TeamMemberProfile } from '../../../../Contacts/TeamMembers/TeamMembersTable/TeamMemberProfile';
import { getServiceLogsByWorkflowExecutionID } from '../../AddOrUpdateWorkflow/WorkflowApi'
import { ContentTypes } from '../../../../ContentManagement/ContentManagementConsts';
import { getTemplateById } from '../../../../ContentManagement/ContentManagementUtils';
import { FlowType, MailgunErrorReason } from '../../../../../../context/WorkflowContext';
import TagsColumnView from '../../../../Contacts/TeamMembers/TeamMembersTable/TagsColumnView';
import { isAccountConfigEnabled } from '../../../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../../../constants/AccountConfigConst';

const COMMUNICATION_LOG_TYPE = {
  SMS_SEND: 'SMS_SEND',
  EMAIL: 'EMAIL',
  PUSH_NOTIFICATION_SEND: 'PUSH_NOTIFICATION_SEND',
  EMAIL_SEND: 'EMAIL_SEND',
} as const;

export const WORKFLOW_COMMUNICATION_STATUS = {
  IN_PROGRESS: 'IN_PROGRESS',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
}

const ANALYTIC_TAG_EVENT_CODE = {
  failed: 'failed',
  succeeded: 'succeeded'
}
export const getElapsedTime = (createdOn:string) => {
  const oldDate = new Date(createdOn);
  const currentDate = new Date();
  const timeElapsed: number = (currentDate.getTime() - oldDate.getTime())/(60*60*1000);
  return timeElapsed
}

export const checkWorkflowCommunicationStatus = (item: any) => {
  const defaultErrorMessage = "Temporary Service Disruption: We're experiencing heavy demand, please retry in a moment";
  let errorMessage : string
  let serviceResponse: any;
  let isInProgress = false;
  try {
    if(JSON.parse(item?.metadata?.error)) {
      errorMessage = JSON.parse(
        item?.metadata?.error || '{}'
      )?.message;
    } else {
      errorMessage = defaultErrorMessage;
    }
  } catch {
    errorMessage = defaultErrorMessage;
  }

  let isError =
    item?.logType == COMMUNICATION_LOG_TYPE.EMAIL_SEND
      ? (item?.emailAnalyticTag?.logs &&
          !item?.emailAnalyticTag?.logs?.length) ||
        item?.emailAnalyticTag?.logs?.some(
          (log: {eventCode: string; body: any}) => {
            if (log?.eventCode === ANALYTIC_TAG_EVENT_CODE.failed) {
              const reason =
                MailgunErrorReason[log?.body?.['event-data']?.reason];
              errorMessage =
                reason ||
                log.body?.['event-data']?.['delivery-status']?.message ||
                errorMessage ||
                '';
              return true;
            }
          }
        ) ||
        item?.status == WORKFLOW_COMMUNICATION_STATUS.ERROR
      : item?.status == WORKFLOW_COMMUNICATION_STATUS.ERROR;

  if (item?.status === WORKFLOW_COMMUNICATION_STATUS.IN_PROGRESS) {
    if (getElapsedTime(item?.createdOn) > 24) {
      isError = true;
    } else {
      isError = false;
      isInProgress = true;
    }
  }

  const metaData = item?.metadata?.requestData;
  try {
    serviceResponse = JSON.parse(item?.metadata?.serviceResponse || '{}');
  } catch {
    serviceResponse = defaultErrorMessage
  }
  errorMessage =
    isError && !errorMessage ? serviceResponse?.message || '' : errorMessage;

  const workflowCommunicationStatus = isError?
   WORKFLOW_COMMUNICATION_STATUS.ERROR:
    isInProgress?
    WORKFLOW_COMMUNICATION_STATUS.IN_PROGRESS:
    WORKFLOW_COMMUNICATION_STATUS.SUCCESS

  return {
    workflowCommunicationStatus: workflowCommunicationStatus,
    reason: errorMessage
  }
};

export const DisplaySmsOrNotificationBody = (props: {
  logType: string;
  templateId?: string;
  body?: string;
  mergeTag?: any;
}) => {
  const [body, setBody] = useState(props.body || '');

  const fetchBodyByTemplateId = async () => {
    const path =
      props.logType === COMMUNICATION_LOG_TYPE.SMS_SEND
        ? ContentTypes.textMessages.path
        : ContentTypes.pushNotifications.path;
    const response = await getTemplateById(path, props.templateId);
    setBody(response?.data?.attributes?.body || '-');
  };
  useEffect(() => {
    if (props.body && props.body != body) {
      setBody(body);
    } else if (!body && props.templateId) {
      fetchBodyByTemplateId();
    }
  }, [props.body, props.templateId]);
  return (
    body ?
      <View> {replacePlaceHolder(body, props.mergeTag || {})} </View> :
      <Text> Template not found </Text>
  )
};

const TableColumn = ({ value, align}: any)=> {
  return (
        <View >
            <Text style={{
              color: '#101828',
              fontSize: 16,
              fontFamily: 'Manrope',
              fontWeight: '400',
              lineHeight: 24,
              textAlign: align || 'left'

            }}>{value}</Text>
        </View>
    )
}

export const getColumns = (
  // actionFn: (actionCode: string, record: any) => {}
): ColumnsType<any> => {
  return [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="name"
        />
      ),
      dataIndex: 'userName',
      key: 'userName',
      fixed: 'left',
      width: '20%',
      render: (userName: string) => {
        return <View>--</View>;
      },
    },

  ];
};

export const ShowWorkflowExecutionCommunication = (props: {isShow: boolean, onClose: any, workflowExecutionId: string, isLoading: boolean, setIsLoading: any, itemId? : string}) =>{

  const [serviceLogs, setServiceLogs] = useState([]);

  useEffect(()=>{
    loadServiceLogs();
  },[props.workflowExecutionId, props?.itemId])

  const loadServiceLogs = async () => {
    props.setIsLoading(true);
    try{
      const response = await getServiceLogsByWorkflowExecutionID( props.workflowExecutionId, props.itemId);
      setServiceLogs(response.serviceLogs);

      props.setIsLoading(false);
    } catch ( error ){

      props.setIsLoading(false);
    }
  }

  return (<>{props.isLoading ? <></> : <ActionModal isShow={props.isShow} onClose={props.onClose} userDetail={{}} data={serviceLogs}/>}</>);
}

export const ActionModal = ({ isShow, onClose, data, userDetail }: any)=> {

  const getTimelineIcons =(logType: string, color? : string)=> {
    if(logType === 'SMS_SEND'){
      return (
        <View
          borderWidth={1}
          borderColor={Colors.Custom.Gray500}
          borderRadius={'50%'}
          padding={1}
      >
        <SmsIcon color={color}/>
        </View>
      );
    } else if(logType === 'EMAIL_SEND' || logType === 'EMAIL'){
        return (
          <View
          borderWidth={1}
          borderColor={Colors.Custom.Gray500}
          borderRadius={'50%'}
          padding={1}
      >
        <MailOutlined style={{color: color}}/>
        </View>
        )
    } else {
      return (
        <View
        borderWidth={1}
        borderColor={Colors.Custom.Gray500}
        padding={1}
        borderRadius={'50%'}>
      <BellOutlined style={{color: color}}/>
      </View>
      )
    }
  }
  return (
    <>
      <Drawer
        open={isShow}
        onClose={() => {
          onClose();
        }}
        width={'50vw'}
        title={
          <ModalActionTitle
            // titleNode={<View><TeamMemberProfile fullName={userDetail.name} phone={userDetail.phoneNumber}/></View>}
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'Close',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  onClose();
                },
              },
            ]}
          />
        }
      >
        <View padding={0} paddingTop={0} flex={1} flexDirection={'column'}>
          <View>
            <Text
              style={{
                // Automation execution
                color: 'black',
                fontSize: 18,
                fontFamily: 'Manrope',
                fontWeight: '600',
                lineHeight: 27,
                marginBottom: '24px',
                // marginLeft: '1em'
              }}
            >
              Automation
            </Text>
          </View>
          {data.length > 0 && (
            <Timeline style={{marginLeft: '3em'}} className="workflow-timeline">
              {data
                ?.filter((item: any) => {
                  return [
                    COMMUNICATION_LOG_TYPE.EMAIL,
                    COMMUNICATION_LOG_TYPE.SMS_SEND,
                    COMMUNICATION_LOG_TYPE.PUSH_NOTIFICATION_SEND,
                    COMMUNICATION_LOG_TYPE.EMAIL_SEND,
                  ].includes(item?.logType);
                })
                .map((item: any) => {
                    const metaData = item?.metadata?.requestData;
                    const status = checkWorkflowCommunicationStatus(item)
                    let serviceResponse;
                    const iconColor = status?.workflowCommunicationStatus===WORKFLOW_COMMUNICATION_STATUS.ERROR
                    ? Colors.Custom.ErrorColor
                    : Colors.Custom.SuccessColor;
                    const errorMessage = status?.reason
                    try{
                      serviceResponse = JSON.parse(item?.metadata?.serviceResponse || '{}');
                    } catch {
                      serviceResponse = errorMessage
                    }

                    const toEmail = serviceResponse?.recipientList?.[0]?.to;
                    return (
                    <>
                      <Timeline.Item
                        dot={
                          <View marginBottom={4}>
                            {getFormattedDate(item?.createdOn, 'MMM YYYY')}
                          </View>
                        }
                        color={Colors.Custom.Gray400}
                        style={{
                          color: '#98A2B3',
                          fontSize: 14,
                          fontFamily: 'Manrope',
                          fontWeight: '600',
                          textTransform: 'uppercase',
                          letterSpacing: 1.4,
                          wordWrap: 'break-word',
                          marginTop: '16px',
                          marginBottom: '16px',
                        }}
                      >
                        <span style={{color: 'transparent'}}>T</span>
                      </Timeline.Item>
                      <Timeline.Item
                        dot={getTimelineIcons(item.logType, iconColor)}
                        color={Colors.Custom.Gray400}
                      >
                        <View
                          flex={1}
                          flexDirection={'column'}
                          style={{padding: '2em', paddingTop: '0 !important'}}
                        >
                          <span
                            style={{
                              color: '#98A2B3',
                              fontSize: 12,
                              fontFamily: 'Manrope',
                              fontWeight: '600',
                              textTransform: 'uppercase',
                              letterSpacing: 1.2,
                              wordWrap: 'break-word',
                            }}
                          >
                            {getFormattedDate(item?.createdOn, 'llll')}
                          </span>
                          <span>{metaData?.title || ''}</span>
                          <span style={{color: Colors.Custom.ErrorColor}}>
                            {status?.workflowCommunicationStatus===WORKFLOW_COMMUNICATION_STATUS.ERROR ? errorMessage || '' : ''}
                          </span>
                          {[
                            COMMUNICATION_LOG_TYPE.PUSH_NOTIFICATION_SEND,
                            COMMUNICATION_LOG_TYPE.SMS_SEND,
                          ].includes(item?.logType) && (
                            <span>
                              <DisplaySmsOrNotificationBody
                                logType={item?.logType}
                                templateId={
                                  metaData?.smsTemplateId ||
                                  metaData?.templateId ||
                                  metaData?.template
                                }
                                body={metaData?.smsBody || metaData?.body}
                                mergeTag={
                                  metaData?.mergeTag ||
                                  metaData?.mergeTags ||
                                  metaData?.data
                                }
                              />{' '}
                            </span>
                          )}
                          {[
                            COMMUNICATION_LOG_TYPE.EMAIL_SEND,
                            COMMUNICATION_LOG_TYPE.EMAIL,
                          ].includes(item?.logType) && (
                            <>
                              <span>{toEmail || ''}</span>
                              <span>
                                {replacePlaceHolder(
                                  metaData?.subject,
                                  metaData?.mergeTag ||
                                    metaData?.mergeTags ||
                                    metaData?.data
                                )}
                              </span>
                              {metaData?.template && (
                                <DisplayTemplateById
                                  key={metaData?.template}
                                  hideEdit
                                  templateId={metaData?.template}
                                  templateCategories={[]}
                                  mergeTags={
                                    metaData?.mergeTag ||
                                    metaData?.mergeTags ||
                                    metaData?.data
                                  }
                                />
                              )}
                            </>
                          )}
                        </View>
                      </Timeline.Item>
                    </>
                  );
                })}
            </Timeline>
          )}
          {data.length == 0 && (
            <Text>No Communication Occured with this patient</Text>
          )}
        </View>
      </Drawer>
    </>
  );
}



export  const WorflowStatusTable = ({ column, allTask, contacts, userWorksFlows,activeTab,activeTabCount, currentPage, onPageChange, pageSize }: any)=> {
  const [ isShow, setIsShow] = useState(false);
  const [ currentData, setCurrentData ] = useState([]);
  const [ userDetail, setUserDetail] = useState({})
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const columns = [
    {
      title: 'Name',
      key: 'resourceLevelLog',
      width: '30%',
      render: (data: any) => {
        const flowType = data?.workflow?.flowType;
        const resourceLevelLog = data?.resourceLevelLog;
        let resource = resourceLevelLog.filter((item: any) => {
          if (flowType === FlowType.task && item.resourceType === 'TASK') {
            return true;
          } else if (
            flowType !== FlowType.task &&
            item.resourceType === 'CONTACT'
          ) {
            return true;
          }
        });
        if (!resource?.length) {
          resource = resourceLevelLog.filter((item: any) => {
            return (
              item.resourceType === 'TASK' || item.resourceType === 'CONTACT'
            );
          });
        }
        const id = resource?.[0]?.resourceId;
        const name =  contacts?.[id]?.name || allTask?.[id]?.assigneeUser?.name;
        const isUserDeleted = contacts?.[id]?.is_deleted
        return <TeamMemberProfile fullName={name} phone={contacts?.[id]?.phoneNumber} isUserDeleted={isUserDeleted}/>;
      },
    },
    {
      title: 'Date and Time',
      dataIndex: 'createdOn',
      key: 'createdOn',
      render: (createdOn: string) => {


        return <TableColumn value={getFormattedDate(createdOn, 'MM/DD/YYYY, h:mm a')}/>
      },
      width: '25%',
    },
    {
      title: 'Email',
      dataIndex: 'emailLog',
      key: 'emailLog',
      width: '9%',
      render: (emailLog: any) => {


        return <TableColumn value={emailLog?.aggregate?.count}/>
      },
    },
    {
      title: 'SMS',
      dataIndex: 'smsLog',
      key: 'smsLog',
      width: '9%',
      render: (smsLog: any) => {


        return <TableColumn value={smsLog?.aggregate?.count} />
      },
    },
    {
      title: 'Notification',
      dataIndex: 'notificationLog',
      key: 'notificationLog',
      width: '15%',
      render: (notificationLog: any) => {


        return <TableColumn value={notificationLog?.aggregate?.count}/>
      },
    },
    ...(isMsoEnabled ? [{
      title: 'Locations',
      dataIndex: 'locationNames',
      key: 'locationNames',
      width: '15%',
      render: (locationNames: string[]) => {
        return <TableColumn value={
          <TagsColumnView
            color={Colors.Custom.Gray900}
            bgColor={Colors.Custom.Primary50}
            labelData={locationNames || []}
          />
        } />
      },
    }] : []),
    {
      title: 'Actions',
      dataIndex: 'serviceLog',
      key: 'serviceLog',
      width: '12%',
        render: (serviceLog: any, rowData: any) => {
        const resource = rowData?.resourceLevelLog.filter((item: any)=> item.resourceType === 'CONTACT' || item.resourceType === 'TASK');
        const id = resource?.[0]?.resourceId;
        const user =  contacts?.[id] || allTask?.[id]?.assigneeUser;

          return (

            <View flex={1} justifyContent={'center'} flexDirection={'row'}>
            <IconButton
              icon={<ExclamationCircleOutlined />}
              onPress={() => {
                setIsShow(true)
                setCurrentData(serviceLog)
                setUserDetail(user)
              }}
              />
          </View>
          );
        },

    },
  ];

  const columnForFailed = [
    {
      title: 'Members Name',
      dataIndex: 'resourceLevelLog',
      key: 'resourceLevelLog',
      // width: '20%',
      render: (resourceLevelLog: any) => {
        const resource = resourceLevelLog.filter((item: any)=> item.resourceType === 'CONTACT');
        const id = resource?.[0]?.resourceId;

        return <TeamMemberProfile fullName={contacts?.[id]?.name} phone={contacts?.[id]?.phoneNumber}/>;
      },
    },
    {
      title: 'Failing Reason',
      dataIndex: 'serviceLog',
      key: 'serviceLog',
      render: (serviceLog: any) => {
        let error ;
        {serviceLog.map((item: any)=>{
          error = item?.metadata?.error;
        })}

        return <TableColumn value={error || 'Error while fetching data'}/>
      },
    },
  ];

  if(isShow && currentData?.length >0){
    return <ActionModal isShow={isShow} onClose={()=> setIsShow(false)} data={currentData} userDetail={userDetail}/>
  }

  userWorksFlows = userWorksFlows?.[0] ? userWorksFlows : []

    return (
        <View width={'100%'} >

           <Table
            dataSource={userWorksFlows}
            className='workflow-table'
            columns={ column?.[0] ? column : activeTab === 'failed' ? columnForFailed: columns}
            scroll={{x:'100%', y:500}}
            pagination={{

            pageSize: pageSize,
            current: currentPage,
            total: activeTabCount,
            pageSizeOptions:['5', '10', '15', '20'],
            onChange: (page, pageSize) => {

            onPageChange(page, pageSize)
          },
        }}/>
        </View>
    )
}

