import { FlatList, Pressable, Text } from 'react-native'
import React, { useEffect, useState } from 'react'
import { IAutomation, INode } from '../../../Forms/FHFormio/CustomComponents/ContactRules/interfaces';
import { getPopGroupWorkflowList } from '../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { TriggerCondition } from '../../../Workflow/Workflow/AddOrUpdateWorkflow';
import { getAccountUUID } from '../../../../../utils/commonUtils';
import CollapsePanelWithSwitch from '../../../../TaskCard/CollapsePanelWithSwitch';
import ReadOnlyAutomationViewDrawer from '../../../Forms/FHFormio/CustomComponents/ContactRules/AddOrUpdateContactRules/ReadOnlyAutomationViewDrawer';
import { Skeleton, Spin } from 'antd';
import { ModalActionAntSelect } from '../../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import { debounce, isEqual } from 'lodash';
import Stack from '../../../../common/LayoutComponents/Stack';
import { Colors } from '../../../../../styles';
import { testID } from '../../../../../testUtils';


interface IPopGroupAutomationProps {
  popGroupDetails: {
    name: string;
    id: string;
    locationIds?: string[];
    isUpdate: boolean;
  };
  onChange?: (selectedList: IAutomation[], updatedList: IAutomation[]) => void;
}

const PopGroupAutomation = (props: IPopGroupAutomationProps) => {
  // Constants
  const { popGroupDetails, onChange } = props;
  const tenantId = getAccountUUID();
  const POP_GROUP_FILTER_NODE = 'ContactAddedInPopGroupFilterNode';
  const POP_GROUP_SEARCH_FIELD = 'POP_GROUP_SEARCH';
  const NOT_IN_OPERATOR = 'NOT_IN';
  const IN_OPERATOR = 'IN';

  // States
  const [componentState, setComponentState] = useState<{
    allGroupAutomationList: IAutomation[];
    selectedPopGroupAutomationList: IAutomation[];
    automationToUpdate: IAutomation[];
    loading: boolean;
    automationSearchLoading: boolean;
    showAutomationSearch: boolean;
    searchText: string;
    locationIds?: string[];
  }>({
    allGroupAutomationList: [],
    selectedPopGroupAutomationList: [],
    automationToUpdate: [],
    loading: false,
    automationSearchLoading: false,
    showAutomationSearch: false,
    searchText: '',
    locationIds: popGroupDetails.locationIds,
  });

  const [readOnlyAutomationViewState, setReadOnlyAutomationViewState] = useState<{
    isReadOnlyAutomationViewOpen: boolean;
    flowType: string;
    selectedWorkflowId: string;
    workflowName: string;
  }>({
    isReadOnlyAutomationViewOpen: false,
    flowType:'',
    selectedWorkflowId: '',
    workflowName: ''
  });

  // Lifecycle Hooks
  useEffect(() => {
    fetchAllPopGroupAutomation({ isInitial: true });
  }, []);

  useEffect(() => {
    if (isEqual(popGroupDetails.locationIds, componentState.locationIds)) {
      return;
    }
    setComponentState((prev) => ({ ...prev, locationIds: popGroupDetails.locationIds }));
    fetchAllPopGroupAutomation({ isInitial: true });
  }, [popGroupDetails.locationIds]);

  useEffect(() => {
    onChange?.(componentState.selectedPopGroupAutomationList, componentState.automationToUpdate);
  }, [componentState.selectedPopGroupAutomationList, componentState.automationToUpdate]);

  // Other methods
  const fetchAllPopGroupAutomation = async (params: { isInitial: boolean; searchText?: string }) => {
    try {
      setComponentState((prev) => ({
        ...prev,
        loading: params.isInitial,
        automationSearchLoading: !params.isInitial,
      }));

      const response = await getPopGroupWorkflowList({
        tenantId,
        triggerCondition: TriggerCondition.onPatientAddedInPopGroup,
        locationIds: popGroupDetails.locationIds,
        searchText: params.searchText,
      });
      const automationList = response?.data?.data?.workflows || [];
      setComponentState((prev) => {
        return {
          ...prev,
          loading: false,
          automationSearchLoading: false,
          allGroupAutomationList: params.isInitial ? automationList : getCombinedAutomation(prev.allGroupAutomationList, automationList),
          ...(params.isInitial && { selectedPopGroupAutomationList: getFilteredAutomationList(automationList) }),
        }
      });
    } catch {
      setComponentState((prev) => ({
        ...prev,
        loading: false,
        automationSearchLoading: false,
        ...(params.isInitial && { allGroupAutomationList: [] }),
      }));
    }
  }

  const getCombinedAutomation = (existingList: IAutomation[], newList: IAutomation[]) => {
    const newData = newList.filter((item: IAutomation) => {
      return !existingList.some((existingItem: IAutomation) => {
        return existingItem.id === item.id;
      });
    });
    return [...existingList, ...newData];
  }

  const getFilteredAutomationList = (automationList: IAutomation[]) => {
    return automationList.filter((item: IAutomation) => {
      let hasPopGroupFilterNode = false;
      const hasValidPopGroupFilterNode = item.graph.nodes?.some((node: INode) => {
        const hasFilterNode = node.data?.nodeType === POP_GROUP_FILTER_NODE;
        hasPopGroupFilterNode = hasPopGroupFilterNode || hasFilterNode;
        return hasFilterNode && node.data?.metaData?.userInputFieldList?.some((userInputField: any) => {
          if (userInputField.fieldType === POP_GROUP_SEARCH_FIELD) {
            const isSelected = userInputField.value?.selectedOptions?.some((option: any) => option.id === popGroupDetails.id);
            return (userInputField.selectedComparisonOperator?.value === IN_OPERATOR && isSelected) ||
              (userInputField.selectedComparisonOperator?.value === NOT_IN_OPERATOR && !isSelected);
          }
        });
      });
      item.isWithoutPopGroupFilterNode = !hasPopGroupFilterNode;
      return hasValidPopGroupFilterNode || !hasPopGroupFilterNode;
    });
  }

  const onAutomationAdd = (automation: IAutomation) => {
    automation.graph.nodes?.find((node: INode) => {
      if (node.data?.nodeType === POP_GROUP_FILTER_NODE) {
        node.data.metaData.userInputFieldList.forEach((userInputField: any) => {
          if (userInputField.fieldType === POP_GROUP_SEARCH_FIELD) {
            const isNotIn = userInputField.selectedComparisonOperator?.value === NOT_IN_OPERATOR;
            let idList = userInputField?.value?.idList || [];
            let selectedOptions = userInputField?.value?.selectedOptions || [];

            if (isNotIn) {
              idList = idList.filter((id: string) => id !== popGroupDetails.id);
              selectedOptions = selectedOptions.filter((option: any) => option.id !== popGroupDetails.id);
            } else {
              if (!idList.includes(popGroupDetails.id)) {
                idList.push(popGroupDetails.id);
              }
              if (!selectedOptions.some((option: any) => option.id === popGroupDetails.id)) {
                selectedOptions.push({
                  id: popGroupDetails.id,
                  name: popGroupDetails.name,
                });
              }
            }

            userInputField.value = {
              ...userInputField.value,
              idList: idList,
              selectedOptions: selectedOptions,
            };
          }
        });
      }
    });

    setComponentState((prev) => {
      const isAlreadyUpdated = prev.automationToUpdate.some((item: IAutomation) => { return item.id === automation.id });
      const isAlreadySelected = prev.selectedPopGroupAutomationList.some((item: IAutomation) => { return item.id === automation.id });
      const selected = isAlreadySelected ? prev.selectedPopGroupAutomationList : [...prev.selectedPopGroupAutomationList, automation];
      return {
        ...prev,
        showAutomationSearch: selected.length === 0,
        selectedPopGroupAutomationList: selected,
        automationToUpdate: isAlreadyUpdated ?
          prev.automationToUpdate.filter((item: IAutomation) => { return item.id !== automation.id }) :
          [...prev.automationToUpdate, automation],
      }
    });
  }

  const onAutomationRemove = (automation: IAutomation) => {
    automation.graph.nodes?.find((node: INode) => {
      if (node.data?.nodeType === POP_GROUP_FILTER_NODE) {
        node.data.metaData.userInputFieldList.forEach((userInputField: any) => {
          if (userInputField.fieldType === POP_GROUP_SEARCH_FIELD) {
            const isNotIn = userInputField.selectedComparisonOperator?.value === NOT_IN_OPERATOR;
            let idList = userInputField?.value?.idList || [];
            let selectedOptions = userInputField?.value?.selectedOptions || [];

            if (!isNotIn) {
              idList = idList.filter((id: string) => id !== automation.id);
              selectedOptions = selectedOptions.filter((option: any) => option.id !== automation.id);
            } else {
              if (!idList.includes(automation.id)) {
                idList.push(popGroupDetails.id);
              }
              if (!selectedOptions.some((option: any) => option.id === automation.id)) {
                selectedOptions.push({
                  id: popGroupDetails.id,
                  name: popGroupDetails.name,
                });
              }
            }

            userInputField.value = {
              ...userInputField.value,
              idList: idList,
              selectedOptions: selectedOptions,
            };
          }
        });
      }
    });
    setComponentState((prev) => {
      const isAlreadyUpdated = prev.automationToUpdate.some((item: IAutomation) => { return item.id === automation.id });
      const filteredList = prev.selectedPopGroupAutomationList.filter((item: IAutomation) => { return item.id !== automation.id });
      return {
        ...prev,
        showAutomationSearch: filteredList.length === 0,
        selectedPopGroupAutomationList: filteredList,
        automationToUpdate: isAlreadyUpdated ?
          prev.automationToUpdate.filter((item: IAutomation) => { return item.id !== automation.id }) :
          [...prev.automationToUpdate, automation],
      }
    });
  }

  const getDataFromId = (id: string) => {
    const matchedData = componentState.allGroupAutomationList.filter((item: IAutomation) => {
      return item.id === id;
    });
    if (matchedData.length > 0) {
      return {
        ...matchedData[0],
      };
    }
  };

  const getDataSource = () => {
    if (componentState.automationSearchLoading) {
      return [];
    }
    const selectedAutomationIds = componentState.selectedPopGroupAutomationList.map((item: IAutomation) => {
      return item.id;
    });
    return componentState.allGroupAutomationList.filter((item: IAutomation) => {
      return item.name?.toLowerCase()?.includes(componentState.searchText?.toLowerCase()) && !selectedAutomationIds.includes(item.id);
    });
  }

  const onSearch = (searchText: string) => {
    setComponentState((prev) => {
      return {
        ...prev,
        searchText,
      }
    });
    if (searchText) {
      fetchAllPopGroupAutomation({ isInitial: false, searchText: searchText || '' });
    }
  }

  const renderAddNewAutomation = () => {
    return (
      <Pressable
        onPress={() => {
          const url = window.location.origin + '/#/admin/patients/automation/create?currentTab=TRIGGER&flowType=PATIENTS';
          window.open(url, '_blank');
        }}
        {...testID('CreateNewAutomation')}
      >
        <Text>{'+ Create New Automation'}</Text>
      </Pressable>
    );
  }

  const renderAutomationSearch = () => {
    const dataAvailableToSelect = getDataSource().length > 0;
    if (componentState.selectedPopGroupAutomationList.length > 0 && !componentState.showAutomationSearch && dataAvailableToSelect) {
      return (
        <Pressable
          onPress={() => {
            setComponentState((prev) => {
              return {
                ...prev,
                showAutomationSearch: true,
              }
            });
          }}
          {...testID('AddMoreAutomation')}
        >
          <Text style={{color: Colors.Custom.Primary300, fontSize: 15, marginTop: 2}}>
            {'+ Add more automation'}
          </Text>
        </Pressable>
      );
    }
    return (
      <ModalActionAntSelect
        showSearch={true}
        allowClear={true}
        onSearch={debounce(onSearch, 500)}
        filterOption={false}
        onChange={(value: any[], data: any) => {
          if (!data?.value) {
            return;
          }
          const selectedAutomation = getDataFromId(data.value);
          if (selectedAutomation) {
            onAutomationAdd(selectedAutomation);
          };
        }}
        placeholder= {'Search Automation'}
        loading={componentState.automationSearchLoading}
        notFoundContent={(componentState.automationSearchLoading) ? <Spin size="small" /> : renderAddNewAutomation()}
        extraStyle={{flex: 1}}
        data={getDataSource()}
        optionProps={{key: 'id', value: 'id', label: 'name'}}
        errors={false}
        renderCustomOption={(option: any) => {
          return (
            <CollapsePanelWithSwitch
              key={option.id}
              data={option}
              index={option.id}
              isDetailsPage={false}
              showAutomation={(show: any, workflowMasterId: any, flowType: string, workflowName: string) => {
                setReadOnlyAutomationViewState({
                  isReadOnlyAutomationViewOpen: show,
                  flowType,
                  selectedWorkflowId: workflowMasterId,
                  workflowName
                })
              }}
              forGoalRuleAlert={true}
            />
          );
        }}
      />
    );
  }

  const renderAutomationItem = (data: {item: IAutomation}) => {
    const item = data.item;
    return (
      <CollapsePanelWithSwitch
        key={item.id}
        data={item}
        index={item.id}
        isDetailsPage={false}
        hideCrossIcon={item.isWithoutPopGroupFilterNode}
        showAutomation={(show: any, workflowMasterId: any, flowType: string, workflowName: string) => {
          setReadOnlyAutomationViewState({
            isReadOnlyAutomationViewOpen: show,
            flowType,
            selectedWorkflowId: workflowMasterId,
            workflowName
          });
        }}
        onAutomationClear={() => {
          onAutomationRemove(item);
        }}
        forGoalRuleAlert={true}
      />
    );
  }

  const renderSelectedAutomationList = () => {
    return (
      <FlatList
        data={componentState.selectedPopGroupAutomationList}
        renderItem={renderAutomationItem}
        keyExtractor={(item) => `Selected_${item?.id}`}
      />
    )
  }

  return (
    <Stack direction='column' space={16}>
      {componentState.loading && <Skeleton active />}
      {!componentState.loading && renderSelectedAutomationList()}
      {!componentState.loading && renderAutomationSearch()}
      {readOnlyAutomationViewState.isReadOnlyAutomationViewOpen &&
        <ReadOnlyAutomationViewDrawer
          title={readOnlyAutomationViewState.workflowName}
          open={readOnlyAutomationViewState.isReadOnlyAutomationViewOpen}
          flowType={readOnlyAutomationViewState.flowType}
          workflowDetails={{
            workflowMasterId: readOnlyAutomationViewState.selectedWorkflowId,
            workflowConfig: null,
          }}
          onClose={() => {
            setReadOnlyAutomationViewState((prev) => ({
              ...prev,
              selectedWorkflowId: '',
              isReadOnlyAutomationViewOpen: false,
              workflowName: ''
            }));
          }}
        />
      }
    </Stack>
  )
}

export default PopGroupAutomation
