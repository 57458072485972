import {View,Text,Pressable,Image} from "react-native"
import {AddSignatureInputView} from "./AddSignatureInputView"
import { default as AntIcon } from 'react-native-vector-icons/AntDesign';
import {Colors} from "../../../styles"
import {COMMON_ACTION_CODES} from "../../../constants/ActionConst";
import JoditRichEditor from "../JoditRichEditor/JoditRichEditor";
import {IAddEditSignatureView, IEmailSignature} from "./PreferencesInterface";
import {useIntl} from "react-intl";
import {Popover} from "antd";
import {useState} from "react";
import Feather from "react-native-vector-icons/Feather";

export const AddEditSignatureView = (props: IAddEditSignatureView) => {
  const intl = useIntl();
  const [stateData, setStateData] = useState({
    showOption: false,
    selectedSignature: null as IEmailSignature | null,
  })

  return <>
    <View style={{
      padding: 10
    }}>
      <View style={{
        borderBottomWidth: 0.5,
        borderBottomColor: Colors.FoldPixel.GRAY150,
        marginBottom: 2,
      }}>
        <Text style={{
          color: Colors.FoldPixel.GRAY200,
          paddingBottom: 4
        }}>
          {intl.formatMessage({id: 'signature'})}
        </Text>
      </View>
      <View>
        {props?.userSignatures?.map?.((signature: IEmailSignature) => {
          return (
            <>
              {!props?.editSelectedSignatures?.includes(signature?.id) && <View
                style={{
                  paddingVertical: 10,
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <Pressable style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    flex: 1
                  }}
                  onPress={() => {
                    props?.onActionPerformed(COMMON_ACTION_CODES.ITEM_SELECT, signature)
                  }}
                >
                  <Text style={{
                    color: Colors.FoldPixel.GRAY400
                  }}>
                    {signature?.title}
                  </Text>
                  <AntIcon
                    style={{
                      marginLeft: 3,
                    }}
                    name= {props?.selectedSignatureView?.includes(signature?.id) ? "caretup" : "caretdown"}
                    color={Colors.FoldPixel.GRAY300}
                    size={6}
                  />
                  {signature?.isDefault && <View style={{
                    backgroundColor: Colors.FoldPixel.PRIMARY100,
                    borderRadius: 4,
                    marginLeft: 4
                  }}>
                    <Text style={{
                      paddingHorizontal: 4,
                      paddingVertical: 2,
                      color: Colors.FoldPixel.PRIMARY300,
                      fontSize: 12
                    }}>
                      {intl.formatMessage({id: 'default'})}
                    </Text>
                  </View>}
                </Pressable>
                <View style={{
                  flexDirection: 'row-reverse',
                  alignItems: 'center',
                }}>
                  <Popover
                    placement="topLeft"
                    overlayInnerStyle={{
                      padding: 4,
                      borderRadius: 8,
                    }}
                    content={
                      <div style={{
                        cursor: 'not-allowed',
                        opacity: signature?.isDefault ? 0.5 : 1,
                      }}>
                        <Pressable
                          onPress={()=> {
                            props?.onActionPerformed(COMMON_ACTION_CODES.SET_DEFAULT, signature);
                            setStateData((prev) => {
                              return {
                                ...prev,
                                showOption: !prev.showOption,
                                selectedSignature: null,
                              }
                            })
                          }}
                          disabled={signature?.isDefault}
                        >
                          <Text
                            style={{
                              alignItems: 'center',
                              color: Colors.Custom.Gray700,
                            }}
                          >
                            {intl.formatMessage({id: 'setAsDefault'})}
                          </Text>
                        </Pressable>
                      </div>
                    }
                    trigger="click"
                    visible={stateData?.showOption && stateData?.selectedSignature?.id === signature?.id}
                    onVisibleChange={() => {
                      setStateData((prev) => {
                        return {
                          ...prev,
                          showOption: !prev.showOption,
                          selectedSignature: signature,
                        }
                      })
                    }}
                  >
                    <Pressable
                      onPress={() => {
                        setStateData((prev) => {
                          return {
                            ...prev,
                            showOption: true,
                            selectedSignature: signature
                          }
                        })
                      }}
                      style={{marginLeft: 4}}
                    >
                      <Feather
                        name="more-horizontal"
                        style={{
                          fontSize: 14,
                          color: Colors.Custom.Gray400
                          }}
                        />
                    </Pressable>
                  </Popover>
                  <View
                    style={{
                      width: 0.5,
                      height: 16,
                      marginLeft: 14,
                      backgroundColor: Colors.FoldPixel.GRAY150,
                      marginRight: 10
                    }}
                  />
                  <Pressable
                    style={{
                      alignItems: 'center',
                      alignSelf: 'center',
                    }}
                    onPress={() => {
                      props?.onActionPerformed(COMMON_ACTION_CODES.DELETE, signature)
                    }}
                  >
                    <Image
                      resizeMode="contain"
                      style={{
                        width: 18,
                        height: 18,
                      }}
                      source={require('../../../assets/images/trash.png')}
                    />
                  </Pressable>
                  <View
                    style={{
                      width: 0.5,
                      height: 16,
                      marginLeft: 14,
                      backgroundColor: Colors.FoldPixel.GRAY150,
                      marginRight: 14
                    }}
                  />
                    <Pressable
                      style={{
                        alignItems: 'center',
                        alignSelf: 'center',
                      }}
                      onPress={() => {
                        props?.onActionPerformed(COMMON_ACTION_CODES.EDIT, signature)
                      }}
                    >
                      <Image
                        resizeMode="contain"
                        style={{
                          width: 18,
                          height: 18,
                        }}
                        source={require('../../../assets/images/signatureEdit.png')}
                      />
                    </Pressable>
                  </View>
              </View>}
              {props?.selectedSignatureView?.includes(signature?.id) && <View
                style={{
                  borderWidth: 0.5,
                  borderRadius: 12,
                  borderColor: Colors.FoldPixel.GRAY150,
                  backgroundColor: Colors.FoldPixel.GRAY50,
                  paddingHorizontal: 12,
                  paddingVertical: 2,
                  marginBottom: 10,
                  minHeight: 50,
                }}
              >
                <JoditRichEditor
                  style={{
                    backgroundColor: Colors.FoldPixel.GRAY50,
                  }}
                  buttons={[]}
                  readonly={true}
                  className={'add-signature-JoditRichEditor-readOnly'}
                  content={signature?.bodyText}
                />
              </View>}
              {props?.editSelectedSignatures?.includes(signature?.id) && <View style={{
                marginTop: 4,
              }}>
                <AddSignatureInputView
                  selectedSignature={signature}
                  onActionPerformed={props?.onActionPerformed}
                  userSignatureInfoChecked={props?.userSignatureInfoChecked}
                />
              </View>}
            </>
          )
        })}
      </View>
      {!props?.showAddSignatureView && <Pressable style={{
          marginTop: 4,
          flexDirection: 'row',
          alignItems: 'center'
        }}
        onPress={() => {
          return props?.onActionPerformed(COMMON_ACTION_CODES.ADD)
        }}
      >
        <AntIcon
          style={{
            marginTop: 4,
          }}
          name="plus"
          color={Colors.Custom.Primary300}
          size={14}
        />
        <Text style={{
          marginLeft: 4,
          fontWeight: '400',
          color: Colors.Custom.Primary300
        }}>
          {'Create New'}
        </Text>
      </Pressable>}
      {props?.showAddSignatureView && <View
        style={{
          borderWidth: 0.5,
          borderColor: Colors.FoldPixel.GRAY100,
          borderRadius: 12,
          paddingHorizontal: 16,
          paddingVertical: 8,
          marginTop: 4,
        }}
      >
        <AddSignatureInputView
          selectedSignature={props?.selectedSignature}
          onActionPerformed={props?.onActionPerformed}
        />
      </View>}
    </View>
  </>
}