import React from "react";
import {IMlov} from "../../../../../../../Interfaces";
import {currentMonth, currentYear} from "../../../../../../../utils/DateUtils";
import {IBatchedAccountUsersInterface} from "../../../../../../CustomHooks/useGetBatchedAccountUsers";
import {IAppointmentType} from "../../../../../../RightSideContainer/AccountSettings/AppointmentTypes/Interfaces";
import {IRescheduleData} from "../AppointmentBooking";
import {IAppointmentBookingState, IAppointmentTypeWiseUsersMap, IAppointmentUserDetail, IRestrictedUserAppointmentTypes, ISlot} from "../AppointmentBookingIntefaces";


type UpdateSelectableLocations =  (
    appointmentType: IAppointmentType | undefined,
    selectedPrimaryUserId: string,
    participants: any[],
    selectedLocationType: IMlov | undefined,
    changed: {
      patient?: boolean;
      appointmentType?: boolean;
      user?: boolean;
      locationType?: boolean;
    },
    additionalInfo?: {
      appointmentTypeWiseUsers?: Map<string, IAppointmentTypeWiseUsersMap>,
      selectedPracticeLocationId?: string;
      selectedAccountLocationId?: string;
      selectedSecondaryUserIds?: string[]
    }
  ) => Promise<any>



const AppointmentDataContext = React.createContext<{
  appointmentBookingState: IAppointmentBookingState;
  handleChangeAppointmentbookingState: (
    state: Partial<IAppointmentBookingState>
  ) => void;
  accountUserList: IBatchedAccountUsersInterface[];
  updateSelectableLocations: UpdateSelectableLocations | undefined;
  slotRange: {
    slotStartDate: string;
    slotEndDate: string;
    selectedMonth: number;
    selectedYear?: number;
  };
  rescheduleData?: IRescheduleData;
  isRescheduleWorkflow: () => boolean;
  handleRestrictedUserDataChange: (data:IRestrictedUserAppointmentTypes) => void,
  handleSlotSelection: (slot: ISlot, user?: IAppointmentUserDetail) => void,
  handleAppointmentDateChange: (date: any) => void
  handleSpecificTimeAndSlotClick: () => void,
  updateSlotDateAndTimeForSpecificTime: (date: string | Date, duration: number, selectedSlot?: ISlot) => void,
  renderPrimaryUser: () => JSX.Element,
  renderSecondaryUsers: () => JSX.Element,
  renderNativeCalendar?: () => JSX.Element,
  renderNativeCustomTimePicker?: () => JSX.Element,
}>({
  appointmentBookingState: {} as IAppointmentBookingState,
  handleChangeAppointmentbookingState: () => {},
  isRescheduleWorkflow: () => false,
  accountUserList: [],
  updateSelectableLocations: undefined,
  slotRange: {
    slotStartDate: '' as string,
    slotEndDate: '' as string,
    selectedMonth: currentMonth(),
    selectedYear: currentYear(),
  },
  handleRestrictedUserDataChange: (data:IRestrictedUserAppointmentTypes) => {},
  handleSlotSelection: (slot: ISlot) => {},
  handleAppointmentDateChange: (date: any) => {},
  handleSpecificTimeAndSlotClick: () => {},
  updateSlotDateAndTimeForSpecificTime: () => {},
  renderPrimaryUser: () => <></>,
  renderSecondaryUsers: () => <></>,
  renderNativeCalendar: () => <></>,
  renderNativeCustomTimePicker: () => <></>,
});

export const AppointmentDataProvider = AppointmentDataContext.Provider;

export const useAppointmentDataContext = () => React.useContext(AppointmentDataContext);

export default AppointmentDataContext;