import { useLazyQuery } from '@apollo/client';
import {Center, HStack, Modal, Pressable, Skeleton, Spinner, Text, View, VStack, Tooltip, Divider} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {GROUP_MEMBER_TYPE, PERSON_CONTACT_TYPE_CODES} from '../../../constants';
import { GET_SINGLE_USER } from '../../../services/User/UserQueries';
import {Colors} from '../../../styles';
import {
  getAccountUUID,
  getCurrentUserRoleCodes,
  getInitialsFromFullName,
  getUserId,
  getUserName,
  getUsersAccounts,
  isChildAccount,
  isEmployerRole,
  isMasterAccount,
} from '../../../utils/commonUtils';
import { getContactDetailsByContactType } from '../../RightSideContainer/Contacts/TeamMembers/AddEditUser/addEditUsersUtils';
import { ContactDetailState, Person } from '../../RightSideContainer/Contacts/TeamMembers/AddEditUser/interfaces';
import {DisplayCardAvatar} from '../DisplayCard/DisplayCardAvatar';
import EditPenSvg from '../Svg/EditPenSvg';
import LogoutSvg from '../Svg/LogoutSvg';
import { User } from '../UsageLevel/interfaces';
import NotificationSvg from '../Svg/NotificationSvg';
import UserAccountsList from './UserAccountsList';
import {CommonDataContext} from '../../../context/CommonDataContext';
import Feather from 'react-native-vector-icons/Feather';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import PreferenceSvg from '../Svg/PreferenceSvg';

const ProfileViewModal = (props: any) => {
  const {userEditData} = props;
  const currentUserRoles = getCurrentUserRoleCodes();
  const userId = getUserId();
  const userName = getUserName()
  const accountUuid = getAccountUUID();
  const userAccounts = getUsersAccounts()
  const [visible, setVisible] = useState(true);
  const isEmployer = isEmployerRole();
  const isMasterAccountFlag = isMasterAccount();
  const isChildAccountFlag = isChildAccount();
  const [userFormData, setUserFormData]: any = useState({
    loading: true,
    userData: {},
  });
    // GET SINGLE USER
    const [getSingleUserById] = useLazyQuery(GET_SINGLE_USER, {
      fetchPolicy: 'no-cache',
    });

  const onCLose = () => {
    setVisible(false);
    props.onActionPerformed('CLOSE');
  };

  useEffect(() => {
    getSingleUserById({
      variables: {
        userId: userEditData.id,
      },
      onCompleted: (data) => {
        const userData: any = data?.users[0] as User;
        const personData: Person = userData?.persons;
        const contactDetails: ContactDetailState =
        getContactDetailsByContactType(personData?.personContactDetails, PERSON_CONTACT_TYPE_CODES.PHONE);

        setUserFormData({
          ...userFormData,
          loading: false,
          userData: {
            ...userEditData,
            ...data.users[0],
            phone: contactDetails,
          }
        });
      },
    });
  }, [props]);

  return (
    <Center>
      <Modal
        isOpen={visible}
        style={{
          justifyContent: 'flex-start',
          alignItems: 'flex-end',
          paddingRight: 40,
          paddingTop: 40,
        }}
        onClose={() => {
          onCLose();
        }}
      >
        <Modal.Content
          style={{
            width: 325,
            // height: 324,
            borderRadius: 16,
            backgroundColor: '#fff',
            borderWidth: 1,
            borderColor: Colors.Custom.Gray100,
            paddingHorizontal: 16,
            paddingVertical: 24,
          }}
        >
          <VStack>
          {userFormData.loading  ?
          <Skeleton.Text style={{height: 71}} lines={4} />
          :
            <HStack
              style={{
                borderColor: Colors.Custom.Gray200,
                borderBottomWidth: userAccounts.length > 1 ? 0 : 1,
                paddingBottom: 24,
              }}
              alignItems={'center'}
              flex={1}
            >
              <VStack>
                <DisplayCardAvatar
                  avatarStyle={{
                    avatarSize: '12',
                  }}
                  isLetterAvatarShow
                  userData={{
                    userId: userId,
                    userType: GROUP_MEMBER_TYPE.USER,
                    imgSrc: userFormData.userData?.profileImage,
                    roles: currentUserRoles,
                    userName: userName
                  }}
                />
              </VStack>
              <VStack style={{marginLeft: 16}}>
                <Text size={'mdBold'}>{userFormData.userData?.name}</Text>
                {userFormData.userData?.email && <Tooltip label={userFormData.userData?.email}>
                <Text size={'smMedium'} maxWidth={220} isTruncated={true} color={Colors.Custom.Gray500}>
                  {userFormData.userData?.email}
                </Text>
                </Tooltip>}
                {userFormData.userData?.phone?.value && <Text size={'smMedium'} color={Colors.Custom.Gray500}>
                  {userFormData.userData?.phone?.value}
                </Text>}
              </VStack>
            </HStack>
          }
          {userAccounts.length > 1 && (isMasterAccountFlag || isChildAccountFlag) && <UserAccountsList
            accountUuid={accountUuid}
            userAccounts={userAccounts}
            />}
            {!(userAccounts.length > 1 && (isMasterAccountFlag || isChildAccountFlag)) && <View
                style={{
                  width: '100%',
                  height: 1,
                  backgroundColor: Colors.Custom.Gray200,
                }}
              ></View>}
            <Pressable
              onPress={() => {
                props?.onActionPerformed?.(COMMON_ACTION_CODES.PREFERENCES);
                setVisible(false);
              }}
            >
              <HStack
                style={{
                  paddingVertical: 10,
                  borderColor: Colors.Custom.Gray200,
                }}
              >
                <PreferenceSvg
                  customStrokeColor={Colors.FoldPixel.GRAY300}
                  strokeWidth={'2'}
                />
                <Text
                  size={'smSemibold'}
                  color={Colors.FoldPixel.GRAY400}
                  style={{marginLeft: 16}}
                >
                  Preferences
                </Text>
              </HStack>
            </Pressable>
            <Pressable
              onPress={() => {
                props.onActionPerformed('LOGOUT');
                setVisible(false);
              }}
            >
              <HStack
                style={{
                  paddingTop: 10,
                }}
              >
                <View style={{
                    transform: [{ rotate: '180deg' }]
                }}>
                  <LogoutSvg customStrokeColor={Colors.FoldPixel.STATUS_ERROR}/>
                </View>
                <Text
                  size={'smSemibold'}
                  color={Colors.FoldPixel.STATUS_ERROR}
                  style={{marginLeft: 16}}
                >
                  Logout
                </Text>
              </HStack>
            </Pressable>
          </VStack>
        </Modal.Content>
      </Modal>
    </Center>
  );
};

export default ProfileViewModal;
