import { Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Colors } from '../../../styles';
import { Divider, HStack, Input, Text, VStack, FormControl, Stack } from 'native-base';
import { FoldButton } from '../../CommonComponents/FoldButton/FoldButton';
import { BUTTON_TYPE } from '../../../constants';
import { useIntl } from 'react-intl';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { DateTimeDurationInput } from '../DateTimeDurationInput/DateTimeDurationInput';
import { CaretDownOutlined } from '@ant-design/icons';
import EditIconSvg from '../Svg/InterventionSvg/EditIconSvg';
import { interventionType, interventiondurationMlovs, INTERVENTION_IMMEDIATE_CODE } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/Intervention/AddOrUpdateIntervention/InterventionConstants';
import { getInterventionIcon, getInterventionType } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/Intervention/AddOrUpdateIntervention/AddOrUpdateInterventionHelper';
import { IInterventionType } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/Intervention/interfaces';
import InterventionRecurrence, {IFrequencyObjectState} from './InterventionRecurrence';
import {frequencyPeriodUnitValuesByCode} from '../../RightSideContainer/Workflow/UserInputFields/FrequencyUserInputField/FrequencyUserInputField';

interface IInterventionRowProps {
    disabled?: boolean;
    isInvalid?: boolean;
    onInterventionTypeChange: (value: string) => void
    onInterventionTitleChange: (value: string) => void
    onCancel: () => void
    onSaveIntervention: (recurrence?: {
     frequency: any | undefined;
     duration: any | undefined;
     displayValue?: string;
    }) => void
    onTaskEdit: (value: string) => void
    onDurationChange: (value: any) => void
    interventionTitle?: string
    selectedDuration: any
    metdataError: boolean
    selectedIntervention: string | undefined
    disableIntervention: boolean
    recurrence?: {
        frequency: any | undefined;
        duration: any | undefined;
        displayValue?: string;
    }
    durationInfoMessage?: any;
}

interface IInterventionTypeState {
    loading: boolean;
    selectedInterventionType: string | undefined;
    interventionTitle: string;
    selectedDuration: { id: string, value: string };
}

const InterventionRow = (props: IInterventionRowProps) => {
    const [openType, setOpenType] = useState(false)
    const {
        onInterventionTypeChange,
        onInterventionTitleChange,
        onDurationChange,
        onCancel,
        onSaveIntervention,
        onTaskEdit,
        metdataError,
        disableIntervention,
        interventionTitle,
        selectedDuration,
        selectedIntervention,
    } = props;
    const initialDuration = {
        id: 'day',
        value: '1'
    }
    const [componentState, setComponentState] = useState<IInterventionTypeState>({
        loading: false,
        selectedInterventionType: '',
        selectedDuration: initialDuration,
        interventionTitle: '',
    });
    const [interventionRecurrence, setInterventionRecurrence] = useState<{
      frequencyObject: IFrequencyObjectState | undefined;
      displayValue: string;
    }>({
      frequencyObject: {
        code: {
          value: props?.recurrence?.frequency?.code || '',
          isValid: true,
        },
        frequency: {
          value: props?.recurrence?.frequency?.times || 1,
          isValid: true,
        },
        period: {
          value: props?.recurrence?.frequency?.per?.value || 1,
          isValid: true,
        },
        periodUnit: {
          value: props?.recurrence?.frequency?.per?.unit || '',
          isValid: true,
        },
        endValue: {
          value: props?.recurrence?.duration?.value || '',
          isValid: true,
        },
        endUnit: {
          value: props?.recurrence?.duration?.unit || '',
          isValid: true,
        },
      },
      displayValue: props.recurrence?.displayValue || '',
    });
    const [recurrenceEnabled, setRecurrenceEnabled] = useState(!!props?.recurrence?.frequency);
    const [errors, setErrors] = useState<{ interventionType: boolean, executionAfter: boolean, interventionTitle: boolean, recurrence: boolean }>({
        interventionType: false,
        executionAfter: false,
        interventionTitle: false,
        recurrence: false
    });
    const commonData = useContext(CommonDataContext);
    const [isInterventionError, setIsInterventionError] = useState('')
    const intl = useIntl();

    useEffect(() => {
        if (interventionTitle) {
            setComponentState((prev) => ({
              ...prev,
              interventionTitle: interventionTitle,
              selectedDuration:
                selectedDuration === 'second'
                  ? {unit: INTERVENTION_IMMEDIATE_CODE, value: 10}
                  : selectedDuration,
            }));
        }

    }, [interventionTitle, selectedDuration, selectedIntervention])

    useEffect(() => {
        if (selectedIntervention) {
            const selectedInterventionType: IInterventionType | undefined = getInterventionType(selectedIntervention)
            setComponentState((prev) => ({
                ...prev,
                selectedInterventionType: selectedInterventionType?.value || selectedIntervention
            }))
        }
    }, [interventionTitle, selectedDuration, selectedIntervention])

    useEffect(() => {
        if (metdataError) {
            handleSave(metdataError)
        }
    }, [metdataError])

    function getDisplayValue(frequencyObject: IFrequencyObjectState) {
        if (isFormValid(frequencyObject)) {
          const isFrequencyCodeValue = frequencyObject.code.value;
          const frequencyValue = frequencyObject.frequency.value;
          const periodValue = frequencyObject.period.value;
          const periodUnitValue = frequencyObject.periodUnit.value;

          const frequencyDisplayValue = (frequencyValue === 1) ? ' Once' : `${frequencyValue} times`;
          const periodDisplayValue= (periodValue === 1) ? 'every' : `every ${periodValue}`;
          let periodUnitDisplayValue= (frequencyPeriodUnitValuesByCode[periodUnitValue] || periodUnitValue || '').toLowerCase();
          let endsValue = '';
            if (frequencyObject.endValue.value && frequencyObject.endUnit.value) {
                endsValue = ` for ${frequencyObject.endValue.value} ${frequencyObject.endUnit.value}`;
            }


          if (periodValue !== 1) {periodUnitDisplayValue += '(s)'}
          return `${frequencyDisplayValue} ${periodDisplayValue} ${periodUnitDisplayValue} ${endsValue}`;

        } else {
          return '';
        }
      }

    function isFormValid(frequencyObject?: IFrequencyObjectState) {
        if (!frequencyObject) {
            return false;
        }
      const isFrequencyCodeValid = frequencyObject.code.isValid;
      const isFrequencyValid = frequencyObject.frequency.isValid;
      const isPeriodValid = frequencyObject.period.isValid;
      const isPeriodUnitValid = frequencyObject.periodUnit.isValid;

      return isFrequencyValid && isPeriodValid && isPeriodUnitValid;
    }



    const formatRecurence = (frequencyObjectState?: IFrequencyObjectState) => {
      if (!frequencyObjectState) {
        return {
          frequency: undefined,
          duration: undefined,
        };
      }
      const isFrequencyCodeValue = frequencyObjectState.code.value;
      const frequencyValue = frequencyObjectState.frequency.value;
      const periodValue = frequencyObjectState.period.value;
      const periodUnitValue = frequencyObjectState.periodUnit.value;

      const value = {
        code: isFrequencyCodeValue,
        times: frequencyValue,
        per: {
          value: periodValue,
          unit: periodUnitValue,
        },
      };
      const end = {
        value: frequencyObjectState.endValue.value,
        unit: frequencyObjectState.endUnit.value,
      };
      return {
        frequency: value,
        duration: end,
      };
    };

    const handleSave = (metaDataError: boolean) => {
        const isValid = recurrenceEnabled ? isFormValid(interventionRecurrence.frequencyObject) : true;
        const reccurence =  formatRecurence(interventionRecurrence.frequencyObject)
        const newErrors = {
            interventionType: componentState.selectedInterventionType && componentState.selectedInterventionType.length > 1 ? false : true,
            executionAfter: Number(componentState.selectedDuration.value) > 0 ? false : true,
            interventionTitle: componentState.interventionTitle.length > 1 ? false : true,
            recurrence: !isValid
        };

        setErrors(newErrors);

        if (!newErrors.interventionType && !newErrors.executionAfter && !newErrors.interventionTitle && !metaDataError && newErrors.recurrence === false) {
            onSaveIntervention(recurrenceEnabled ? {...reccurence, displayValue: interventionRecurrence.displayValue} : undefined);
        } else {
            if (metaDataError) {
                setIsInterventionError('Type details are mandatory')
            } else {
                Object.values(newErrors).some((error) => error !== null) ? setIsInterventionError('All fields are mandatory') : setIsInterventionError('');
            }
        }
    };

    return (
      <VStack
        style={{
          borderWidth: 1,
          borderRadius: 8,
          width: '97%',
          borderColor: Colors.FoldPixel.GRAY150,
          alignSelf: 'center',
          marginTop: 10,
        }}
      >
        <HStack
          w={'100%'}
          style={{padding: 15, flex: 1, justifyContent: 'space-between'}}
        >
          <FormControl width={'20%'} isInvalid={errors.interventionType}>
            <FormControl.Label isRequired={true}>Type</FormControl.Label>
            <Select
              value={componentState.selectedInterventionType || undefined}
              placeholder={'Select Intervention Type'}
              disabled={disableIntervention}
              open={openType && !disableIntervention}
              onChange={() => setOpenType(!openType)}
              onClick={() => {
                if (!disableIntervention) {
                  setOpenType(true);
                }
                if (
                  disableIntervention &&
                  componentState.selectedInterventionType
                ) {
                  onTaskEdit(componentState.selectedInterventionType);
                }
              }}
              onBlur={() => setOpenType(!openType)}
              suffixIcon={
                disableIntervention ? (
                  <EditIconSvg />
                ) : (
                  <CaretDownOutlined onClick={() => setOpenType(!openType)} />
                )
              }
              onSelect={(value: any) => {
                if (
                  componentState.selectedInterventionType &&
                  value !==
                    getInterventionType(componentState.selectedInterventionType)
                      ?.code
                ) {
                  setOpenType(false);
                  setComponentState((prev) => ({
                    ...prev,
                    selectedInterventionType: value,
                  }));
                  setErrors((prev) => ({
                    ...prev,
                    interventionType: false,
                  }));
                }
                onInterventionTypeChange(value);
              }}
              className={
                errors.interventionType ? 'field-error' : 'intervention-type'
              }
            >
              {interventionType.map((item: any, index: any) => (
                <>
                  {item.code === 'internalTask' && <Divider />}
                  <Select.Option key={index} value={item?.code}>
                    <HStack alignItems={'center'} h={'35px'}>
                      {getInterventionIcon(item?.code)}
                      <Text marginLeft={-3} textOverflow={'ellipsis'}>
                        {item.value}
                      </Text>
                    </HStack>
                  </Select.Option>
                </>
              ))}
            </Select>
          </FormControl>
          <FormControl
            style={{width: '60%', paddingLeft: 15, paddingRight: 15}}
            isInvalid={errors.interventionTitle}
          >
            <FormControl.Label isRequired={true}> Title </FormControl.Label>
            <Input
              value={interventionTitle}
              _focus={{borderColor: '#D0D5DD'}}
              width={'100%'}
              onChangeText={(text: string) => {
                setComponentState((prev) => ({
                  ...prev,
                  interventionTitle: text,
                }));
                onInterventionTitleChange(text);
              }}
              height={38}
              background={'#FFFFFF'}
              borderRadius={4}
              textAlign={'left'}
              fontSize={14}
              fontWeight={500}
              lineHeight={20}
              paddingX={0}
              placeholder="Enter the Intervention Title"
            />
          </FormControl>

          <FormControl width={'20%'} isInvalid={errors.executionAfter}>
            <FormControl.Label isRequired={true}>
              Execute After
            </FormControl.Label>
            <DateTimeDurationInput
              durationUnits={interventiondurationMlovs}
              borderColor={
                errors.executionAfter
                  ? Colors.Custom.ErrorColor
                  : selectedDuration.unit === INTERVENTION_IMMEDIATE_CODE
                  ? 'white'
                  : Colors.Custom.Gray300
              }
              borderWidth={selectedDuration.unit === INTERVENTION_IMMEDIATE_CODE ? 0 : 1}
              inputFieldWidth={50}
              suffixIcon={<CaretDownOutlined />}
              hideInputField={selectedDuration.unit === INTERVENTION_IMMEDIATE_CODE}
              value={selectedDuration.value}
              placeholder={'Enter the time'}
              selectedDurationUnitId={selectedDuration.unit === 'second' ? INTERVENTION_IMMEDIATE_CODE : selectedDuration.unit}
              onValueChange={(value) => {
                onDurationChange({value: parseInt(value)});
              }}
              onDurationUnitChange={(id) => {
                onDurationChange({ unit: id});
              }}
            />
          </FormControl>
        </HStack>
        <InterventionRecurrence
          repeat={recurrenceEnabled}
          onChange={(data: IFrequencyObjectState | undefined) => {
            const displayValue = data ? getDisplayValue(data) : '';
            setInterventionRecurrence({
              frequencyObject: data,
              displayValue,
            });
          }}
          onRepeatChange={(value: boolean) => {
            setRecurrenceEnabled(value);
          }}
          frequency={interventionRecurrence.frequencyObject?.frequency?.value}
          period={interventionRecurrence.frequencyObject?.period?.value}
          periodUnit={interventionRecurrence.frequencyObject?.periodUnit?.value}
          endValue={
            interventionRecurrence.frequencyObject?.endValue?.value
              ? parseInt(
                  interventionRecurrence?.frequencyObject?.endValue?.value as string
                )
              : undefined
          }
          endUnit={interventionRecurrence.frequencyObject?.endUnit?.value}
        />

        {isInterventionError && (
          <Text
            style={{
              color: Colors.Custom.ErrorColor,
              fontWeight: '500',
              paddingLeft: 15,
            }}
          >
            {isInterventionError}
          </Text>
        )}
        <Stack
          direction="row"
          style={{justifyContent: 'flex-end', alignItems: 'center'}}
          padding={15}
        >
          {props?.durationInfoMessage && (
            <Text color={Colors.Custom.InterventionDurationInfoColor} style={{marginRight: 'auto'}}>{props?.durationInfoMessage}</Text>
          )}
          <FoldButton
            nativeProps={{
              variant: BUTTON_TYPE.SECONDARY,
              style: {margin: 3},
              onPress: onCancel,
            }}
            customProps={{
              btnText: intl.formatMessage({
                id: 'cancel',
              }),
              withRightBorder: false,
            }}
          />
          <FoldButton
            nativeProps={{
              variant: BUTTON_TYPE.PRIMARY,
              style: {margin: 3},
              onPress: () => handleSave(false),
            }}
            customProps={{
              btnText: intl.formatMessage({
                id: 'save',
              }),
              withRightBorder: false,
            }}
          />
        </Stack>
      </VStack>
    );
};

export default InterventionRow;
