import {Select} from 'antd';
import {debounce} from 'lodash';
import React, {useState} from 'react';
import {ISearchFieldProps, IVitalData} from '../../../Interfaces';
import { getEnabledVitalsFromCapability, getVitalListFromCapability } from '../../../utils/capabilityUtils';
import { getEnabledVitalListFromCapability } from '../../../utils/VitalUtils';

const VitalSearch = (props: ISearchFieldProps) => {
  const {value, isShowError, onChange, locationId} = props;

  const vitalList = getEnabledVitalListFromCapability(locationId);

  const [originalData] = useState<IVitalData[]>(vitalList);
  const [vitalData, setVitalData] = useState<IVitalData[]>(vitalList);

  const onSearch = (searchString: string) => {
    if (searchString.length > 0) {
      const filteredData = originalData.filter((item) => {
        return item.foldDisplay?.toLowerCase().includes(searchString.toLowerCase());
      });
      setVitalData([...filteredData]);
    } else {
      setVitalData([...originalData]);
    }
  };

  const getDataFromId = (id: any) => {
    const matchedData = vitalData.filter((item) => {
      return item.loinc === id;
    });
    if (matchedData.length > 0) {
      const vital = matchedData?.[0];
      return {
        loinc: vital.loinc,
        name: vital.foldDisplay || vital.display,
        units:[vital.unit],
        displayUnit: vital.displayUnit,
        decimalPrecision: vital.allowedDecimalPlaces,
      };
    }
  };

  return (
    <Select
      size="large"
      showSearch
      allowClear
      filterOption={false}
      value={value ? value.loinc : undefined}
      onSearch={debounce(onSearch, 500)}
      onChange={(value: any[], data: any) => {
        if (data && data.value) {
          onChange(getDataFromId(data.value));
        } else {
          onChange(undefined);
        }
      }}
      placeholder="Search Vital"
      style={{height: '40px'}}
      className={isShowError && !value ? 'field-error' : ''}
    >
      {vitalData.map((vital, index) => {
        return (
          <Select.Option
            key={`${vital.loinc}_${index}`}
            value={vital.loinc}
          >
            {vital.foldDisplay || vital.display}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default VitalSearch;
