import { Collapse, Tooltip, Switch } from 'antd';
import { Badge, HStack, Icon, Text, View, VStack } from 'native-base';
import { useState } from "react";
import { Pressable } from 'react-native';
import AntDesign from 'react-native-vector-icons/AntDesign';
import Feather from 'react-native-vector-icons/Feather';
import '../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBookingStyles.css';
import { FormStatus } from '../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBookingEnums';
import { findIsAllowToRunAutomation } from '../../utils/commonUtils';
import { Colors } from '../../styles/Colors';
import { testID } from '../../testUtils';

const { Panel } = Collapse;

const CollapsePanelWithSwitch = (props: {
  data: any;
  index: any;
  workflowEventEntityAllList?: any;
  entityEventList?: any;
  onValueChanage?: (eventEntityList: any) => void,
  isDetailsPage: any;
  showAutomation: (show: boolean, workflowMasterId: any, flowType: string, workflowName: string) => void,
  forGoalRuleAlert?: boolean;
  onAutomationClear?: () => void,
  hideCrossIcon?: boolean;
}) => {
  const [isSwitch, setIsSwitch] = useState(false);

  const {forGoalRuleAlert = false, hideCrossIcon} = props;

  const getAllCollapseActiveHeader = (data: any) => {
    let result = false;
    if (data.formNodeList && data.formNodeList.length > 0) {
      result = true;
    } else {
      result = false;
    }
    return result;
  }
  const isAllowToRunAutomation = findIsAllowToRunAutomation();

  const panelHeader = (data: any, index: number) => {
    return (
      <VStack>
        <HStack key={1} space={1} flex={1} justifyContent='center' alignItems='center'>
          <HStack flex={8.5}>
            <Feather name="zap" style={{ fontSize: 20, alignSelf: 'center', justifyContent: 'center' }} color={Colors.Custom.alertsDescriptionColor} />
            <VStack paddingLeft={2} flex={1}>
              <Text color={Colors.FoldPixel.GRAY400} fontWeight={500}>
                {data.name}
              </Text>
              <Tooltip title={data.description}>
                <Text color={Colors.FoldPixel.GRAY300} fontWeight={400} noOfLines={1}>
                  {data.description}
                </Text>
              </Tooltip>
            </VStack>
          </HStack>
          <HStack flex={1.5} paddingRight={1} justifyContent={forGoalRuleAlert ? 'flex-end' : 'space-between'}>
            <Tooltip title={'Show automation'}>
              <Pressable
                onPress={() => {
                  props.showAutomation(true, data.workflowMasterId, data.flowType, data.name);
                }}
                {...testID('ShowAutomationEyeIcon')}
              >
                <Feather name="eye" style={{ fontSize: 20, alignSelf: 'center', justifyContent: 'center' }} color={Colors.Custom.alertsDescriptionColor} />
              </Pressable>
            </Tooltip>
            {forGoalRuleAlert && !hideCrossIcon && props?.onAutomationClear && <View backgroundColor={Colors.Custom.Gray500} width={0.2} height={6} marginX={3} />}
            {forGoalRuleAlert && !hideCrossIcon && props?.onAutomationClear && (
              <Tooltip title={'Clear automation'}>
                <Pressable
                  onPress={props.onAutomationClear}
                  {...testID('ClearAutomationCrossIcon')}
                >
                  <Feather name="x" style={{ fontSize: 20, alignSelf: 'center', justifyContent: 'center' }} color={Colors.Custom.alertsDescriptionColor} />
                </Pressable>
              </Tooltip>
            )}
            {!forGoalRuleAlert && (
              <Tooltip title={!isAllowToRunAutomation ? 'Fold users not allowed to trigger automation' : ''}>
                <Switch
                  style={{
                    backgroundColor: data.isEnabled
                      ? Colors.Custom.mainPrimaryPurple
                      : Colors.Custom.Gray300,
                  }}
                  disabled={(props.isDetailsPage ? setSwitchEnableOrDisable(data.workflowMasterId) : false) || !isAllowToRunAutomation}
                  defaultChecked={data.isEnabled}
                  onChange={(checked) => {
                    const isChecked = checked;
                    const eventEntityList: any = [...props.workflowEventEntityAllList];
                    eventEntityList[index] = { ...eventEntityList[index], isEnabled: isChecked };
                    setIsSwitch(!isChecked)
                    props.onValueChanage && props.onValueChanage(eventEntityList)
                  }}
                />
              </Tooltip>
            )}
          </HStack>
        </HStack>
      </VStack>
    )
  }
  const setFormSubList = (data: any, index: number) => {
    return props.workflowEventEntityAllList.map((workflowFormList: any) => {
      if (workflowFormList.workflowMasterId === data.workflowMasterId) {
        return workflowFormList.formNodeList.map(
          (formDataList: any, index1: number) => {
            return (
              <HStack
                key={1}
                space={1}
                paddingLeft={2}
                paddingRight={2}
                flex={1}
                justifyContent="center"
                alignItems="center"
                //marginTop={1}
                borderRadius={0}
                background="white"
              >
                <HStack flex={8} padding={2}>
                  <Icon
                    as={AntDesign}
                    name="form"
                    size="5"
                    color="black.500"
                    alignSelf={'center'}
                    justifySelf={'center'}
                  />
                  <HStack flex={1} paddingLeft={1}>
                    <Text paddingRight={2}>{formDataList.name}</Text>
                    <Badge colorScheme="primary" alignSelf="center" width={20}>
                      {formDataList?.formStatus === FormStatus.received
                        ? 'Received'
                        : 'Pending'}
                    </Badge>
                  </HStack>
                </HStack>
                <HStack flex={2} paddingRight={0.3} marginRight={3}>
                  <Switch
                    defaultChecked={formDataList.isEnabled}
                    onChange={(checked) => {
                      const isChecked = checked;
                      const eventEntityList: any = [
                        ...props.workflowEventEntityAllList,
                      ];
                      eventEntityList[index].formNodeList[index1] = {
                        ...eventEntityList[index].formNodeList[index1],
                        isEnabled: isChecked,
                      };
                      props.onValueChanage &&
                        props.onValueChanage(eventEntityList);
                    }}
                  />
                </HStack>
              </HStack>
            );
          }
        );
      }
    });
  };

  const getWorkflowEventType = (workflowMaster_Id: string) => {
    return props.workflowEventEntityAllList.map((workflowList: any) =>
      workflowList.workflowMasterId === workflowMaster_Id
        ? workflowList.eventType
        : ''
    );
  };

  const setSwitchEnableOrDisable = (workflowMaster_Id: string) => {
    let result = false;
    props.entityEventList.map((entityEventList: any) => {
      if (
        entityEventList.eventType ===
        getWorkflowEventType(workflowMaster_Id).toString().replace(/,/g, '')
      ) {
        result = entityEventList.isDisableInDetailsPage;
      }
    });
    return result;
  };

  return (
    <Collapse
      bordered={false}
      activeKey={
        props.data.isEnabled && getAllCollapseActiveHeader(props.data)
          ? props.index
          : null
      }
      collapsible={
        props.data.isEnabled && getAllCollapseActiveHeader(props.data)
          ? 'header'
          : 'disabled'
      }
      style={{
        marginTop: 5,
        backgroundColor: 'white',
        padding: 0,
        flex: 1,
        borderRadius: 8,
      }}
      onChange={() => {
        setIsSwitch(!isSwitch);
      }}
    >
      <Panel
        className={`collapse-panel-custom ${props.onAutomationClear ? 'collapse-panel-custom-cursor-default' : forGoalRuleAlert ? 'collapse-panel-custom-cursor-pointer' : ''}`}
        showArrow={false}
        header={panelHeader(props.data, props.index)}
        key={props.index}
      >
        {!forGoalRuleAlert && !isSwitch && (
          <VStack background="gray.100" flex={1} borderTopColor={'gray.200'} borderTopWidth={1}>
            {setFormSubList(props.data, props.index)}
          </VStack>
        )}
      </Panel>
    </Collapse>
  );
};

export default CollapsePanelWithSwitch;
