import {useLazyQuery} from '@apollo/client';
import {Empty, Modal, Pagination, Skeleton, Tooltip, notification} from 'antd';
import {debounce} from 'lodash';
import {
  Box,
  Center,
  FlatList,
  HStack,
  Input,
  Pressable,
  Spacer,
  Spinner,
  Text,
  TextArea,
  VStack,
  View,
} from 'native-base';
import {useCallback, useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import Feather from 'react-native-vector-icons/Feather';
import {BUTTON_TYPE, MLOV_CATEGORY, PERSON_TYPES, getInActiveContactError} from '../../../../../../../constants';
import {APPOINTMENT_PARTICIPANT_STATUS_CODES, LOCATION_TYPE_CODES} from '../../../../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';
import {getBulkContactByUUID} from '../../../../../../../services/Lead/LeadQueries';
import {Colors} from '../../../../../../../styles';
import {getAccountId, isActiveContact} from '../../../../../../../utils/commonUtils';
import {getMlovById, getMlovId, getMlovListFromCategory} from '../../../../../../../utils/mlovUtils';
import {onPagination} from '../../../../../../RightSideContainer/Contacts/Leads/LeadView/LeadTableView/LeadListView/LeadListHelper';
import FHActionPopover from '../../../../../FHActionPopover/FHActionPopover';
import MemberInfoListItem from '../../../../../MemberInfoListItem/MemberInfoListItem';
import {
  GroupAppointmentParticipantActions,
  IParticipantData,
} from '../AppointmentBookingIntefaces';
import {generateUserAndStatusMap} from './SideTrackingHelpers';
import SideTrackingListSort from './components/SideTrackingListSort';
import {
  ISideTrackingComponentState,
  ISideTrackingViewProps,
} from './interfaces';
import { withMiniContactViewHOC } from '../../../../../../MiniContactViewHOC';
import { FoldButton } from '../../../../../../CommonComponents/FoldButton/FoldButton';
import { DisplayText } from '../../../../../DisplayText/DisplayText';
import { IMlov } from '../../../../../../../Interfaces';

const SideTrackingView = (props: ISideTrackingViewProps) => {
  const accountId = getAccountId();
  const defaultpageSizeOptions = {
    page: 1,
    pageSize: 10,
    total: props?.allContactList.length
  };
  const {
    allContactList,
    groupNameMap,
    groupParticipantsMap,
    participantStatusLoadingId,
    handleParticipantActions,
  } = props;
  const intl = useIntl();
  const mlovData = useContext(CommonDataContext);
  const [paginationData, setPaginationData] = useState(defaultpageSizeOptions);
  const [getContactsByIds] = useLazyQuery(getBulkContactByUUID, {
    fetchPolicy: 'no-cache',
  });
  const [componentState, setComponentState] =
    useState<ISideTrackingComponentState>({
      searchString: '',
      contactsLoading: true,
      contactList: [],
      deleteInvite: {
        confirm: false,
      }
    });

  const onComponentStateChange = (
    key: keyof ISideTrackingComponentState,
    value: any
  ) => {
    setComponentState((prev) => ({...prev, [key]: value}));
  };

  const participantStatusMapData = generateUserAndStatusMap(allContactList);
  const isRsvpEnabled = !!props?.appointment?.isRsvpEnabled;
  const isVirtualVisit = props?.appointment?.locationType?.code === LOCATION_TYPE_CODES.VIRTUAL
  const APPOINTMENT_PARTICIPANT_STATUS_LIST: any = {
    accepted: getMlovId(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.APPOINTMENT_PARTICIPANT_STATUS,
      APPOINTMENT_PARTICIPANT_STATUS_CODES.ACCEPTED
    ),
    declined: getMlovId(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.APPOINTMENT_PARTICIPANT_STATUS,
      APPOINTMENT_PARTICIPANT_STATUS_CODES.DECLINED
    ),
    need_action: getMlovId(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.APPOINTMENT_PARTICIPANT_STATUS,
      APPOINTMENT_PARTICIPANT_STATUS_CODES.NEEDS_ACTION
    ),
  };

  const groupAppointmentParticipantAction: {
    [key: string]: {
      code: string;
      name: string;
    };
  } = {
    accept: {
      code: GroupAppointmentParticipantActions.ACCEPT,
      name: intl.formatMessage({id: 'accept'}),
    },
    declined: {
      code: GroupAppointmentParticipantActions.DECLINE,
      name: intl.formatMessage({id: 'decline'}),
    },
    copyRsvpLink: {
      code: GroupAppointmentParticipantActions.COPY_RSVP_LINK,
      name: intl.formatMessage({id: 'copyRsvpLink'}),
    },
    sendRsvpLink: {
      code: GroupAppointmentParticipantActions.SEND_RSVP_LINK,
      name: intl.formatMessage({id: 'sendRsvpLink'}),
    },
    copyMeetingLink: {
      code: GroupAppointmentParticipantActions.COPY_MEETING_LINK,
      name: intl.formatMessage({id: 'copyMeetingLink'}),
    },
    sendMeetingLink: {
      code: GroupAppointmentParticipantActions.SEND_MEETING_LINK,
      name: intl.formatMessage({id: 'sendMeetingLink'}),
    },
    deleteInvite: {
      code: GroupAppointmentParticipantActions.DELETE_INVITATION,
      name:  intl.formatMessage({id: isRsvpEnabled ? 'deleteInvite' : 'removeMemberTitle'}),
    },
  };

  const getContactActions = (contactData: IParticipantData) => {
    const action = [];
    if (isRsvpEnabled && contactData?.statusId === null) {
      action.push(
        groupAppointmentParticipantAction.accept,
        groupAppointmentParticipantAction.declined
      );
    }
    if (
      isRsvpEnabled &&
      contactData?.statusId === APPOINTMENT_PARTICIPANT_STATUS_LIST.accepted
    ) {
      action.push(groupAppointmentParticipantAction.declined);
    }
    if (
      isRsvpEnabled &&
      contactData?.statusId === APPOINTMENT_PARTICIPANT_STATUS_LIST.declined
    ) {
      action.push(groupAppointmentParticipantAction.accept);
    }
    action.push(groupAppointmentParticipantAction.deleteInvite);

    const endDateTime = props.appointment?.endDateTime;
    const isAppointmentInPast = endDateTime ? (new Date(endDateTime).getTime() < new Date()?.getTime()) : false;

    if (isAppointmentInPast) {
      return action;
    }

    if (!contactData?.statusId && isRsvpEnabled) {
      action.push(groupAppointmentParticipantAction.copyRsvpLink);
      action.push(groupAppointmentParticipantAction.sendRsvpLink);
    }

    if (contactData?.statusId === APPOINTMENT_PARTICIPANT_STATUS_LIST.accepted && props.appointment?.locationType?.code === LOCATION_TYPE_CODES.VIRTUAL) {
      action.push(groupAppointmentParticipantAction.copyMeetingLink);
      action.push(groupAppointmentParticipantAction.sendMeetingLink);
    }

    return action;
  };

  const renderContactActionsPopover = (contactData: IParticipantData) => {
    return (
      <FHActionPopover
        actions={getContactActions(contactData)}
        onActionClick={(code) => {
          if (code === GroupAppointmentParticipantActions.DELETE_INVITATION) {
            setComponentState((prev) => ({
              ...prev,
              deleteInvite: {
                confirm: true,
                data: contactData,
              },
            }));
            return;
          }
          handleParticipantActions(code, contactData);
        }}
      />
    );
  };


  const renderParticipantStatus = (statusId: string | null | undefined) => {
    if (statusId === APPOINTMENT_PARTICIPANT_STATUS_LIST.accepted) {
      return (
        <Tooltip title="Accepted">
          <Feather name="check-circle" size={18} color={Colors.success[500]} />
        </Tooltip>
      );
    }
    if (statusId === APPOINTMENT_PARTICIPANT_STATUS_LIST.declined) {
      return (
        <Tooltip title="Declined">
          <AntIcon name="closecircleo" size={18} color={Colors.danger[500]} />
        </Tooltip>
      );
    }
    return (
      <Tooltip title="No response">
        <AntIcon name="clockcircleo" size={18} color={Colors.warning[400]} />
      </Tooltip>
    );
  };

  const renderPopGroupInfo = (patientId: string) => {
    const allGroupIds: string[] = [];
    Object.keys(groupParticipantsMap).map((key) => {
      if (groupParticipantsMap[key].includes(patientId)) {
        allGroupIds.push(key);
      }
    });

    const groupNameList: string[] = [];

    Object.keys(groupNameMap).map((key) => {
      if (allGroupIds.includes(key)) {
        groupNameList.push(groupNameMap[key]);
      }
    });
    if (groupNameList.length > 1) {
      const [, ...rest] = groupNameList;
      return (
        <HStack>
          <Tooltip title={rest.join(', ')}>
            <Text color={Colors.Custom.Gray500}>{`${groupNameList[0]} + ${
              groupNameList.length - 1
            } more`}</Text>
          </Tooltip>
        </HStack>
      );
    }

    return (
      <HStack>
        <Text color={Colors.Custom.Gray500}>{groupNameList.join(', ')}</Text>
      </HStack>
    );
  };

  const onClickContact = (contactData: any) => {
    props?.openContactInWindowOrOpenContactIdDrawer?.(contactData?.id);
  }

  const renderContactListItem = ({item}: {item: any}) => {
    const contactData = item;
    const participant = contactData?.uuid
      ? participantStatusMapData[contactData?.uuid]
      : null;
    return (
      <HStack
        paddingY={3}
        style={{
          borderBottomColor: Colors.Custom.Gray300,
          borderBottomWidth: 1,
          alignItems: 'center',
        }}
        mx={3}
      >
        <HStack>
          <MemberInfoListItem
            showDateOfBirth={true}
            contactData={contactData as any}
            isPatientSearchCard
            showPhoneNumber={true}
            showEmail={true}
            contactNameMaxWidth={"150px"}
            showTagging={false}
            extaSubHeader={
              <VStack w="100%" pl={2} maxWidth={180}>
                {renderPopGroupInfo(contactData?.uuid)}
              </VStack>
            }
          />
          <Tooltip title="View profile">
            <Feather
              style={{marginLeft: 8}}
              size={18}
              name="external-link"
              onPress={() => onClickContact(contactData)}
              color={Colors.Custom.mainPrimaryPurple}
            />
          </Tooltip>
        </HStack>
        <Spacer />
        {renderParticipantStatus(participant?.statusId)}
        {(props?.currentAppointmentStatus?.id !== props?.appointmentStatusIds?.cancelled) && (props?.currentAppointmentStatus?.id !== props?.appointmentStatusIds?.checkedOut) && renderContactActionsPopover({
          statusId: participant?.statusId,
          contactId: contactData?.uuid,
          id: participant?.id,
          contact: contactData,
          reference: participant?.reference,
        })}
      </HStack>
    );
  };

  const fetchContacts = async (searchQuery: string) => {
    const contactIdList = allContactList?.map((item) => item.contactId);
    const offset = onPagination(paginationData?.page, paginationData?.pageSize);
    if (!componentState?.contactsLoading) {
      onComponentStateChange('contactsLoading', true);
    }
    await getContactsByIds({
      variables: {
        contactIdList,
        accountId: accountId,
        offset: Math.max(0, offset),
        limit: paginationData?.pageSize,
        name: `%${searchQuery}%`,
      },
      onCompleted: (data) => {
        setComponentState((prev) => ({
          ...prev,
          contactList: data.contacts,
          contactsLoading: false,
        }));
        setPaginationData((prev) => ({
          ...prev,
          total: data?.contactAggregate?.aggregate?.count,
        }));
      },
      onError: (err) => {
        onComponentStateChange('contactsLoading', false);
      },
    });
  };

  const resetPagination = () => {
    setPaginationData(defaultpageSizeOptions);
  };

  const fetchDebouncedContacts = useCallback(
    debounce((value) => {
      onComponentStateChange('contactsLoading', true);
      fetchContacts(value);
    }, 800),
    [props.filterStatusId, allContactList.length]
  );

  useEffect(() => {
    fetchContacts(componentState.searchString);
  }, [
    paginationData.page,
    props.allContactList.length,
    props.filterStatusId,
  ]);

  useEffect(() => {
    if (componentState.contactsLoading) {
      return;
    }
    fetchDebouncedContacts(componentState.searchString);
  }, [componentState.searchString]);

  const {contactList, contactsLoading, searchString} = componentState;

  return (
    <VStack
      flex={2}
      style={{
        backgroundColor: Colors.Custom.Gray50,
        borderRadius: 8,
        height: '87.5vh',
        marginLeft: 8,
        paddingBottom: 16,
      }}
    >
      <VStack
        key={'searchaddmemberTop'}
        backgroundColor={Colors.Custom.Gray50}
        style={{
          width: '100%',
          borderBottomColor: Colors.Custom.Gray300,
          borderBottomWidth: 1,
          paddingHorizontal: 16,
          paddingVertical: 16,
        }}
      >
        <View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Text fontWeight={700} fontSize={18}>
              Tracking
            </Text>
          </View>
          {props.subTitle && 
            <Text color={Colors.Custom.Gray500} fontSize={16} mb={4}>
              {props.subTitle}
            </Text>
          }
          
        </View>
        <HStack w="100%" marginTop={2}>
          <Input
            value={componentState.searchString}
            onChangeText={(value) => {
              onComponentStateChange('searchString', value);
            }}
            flex={1}
            _focus={{
              borderColor: Colors.Custom.Gray300,
            }}
            style={{
              borderRadius: 6,
              borderColor: Colors.Custom.Gray300,
              borderWidth: 1,
            }}
            placeholder="Search Members"
            backgroundColor={Colors.Custom.IconColorWhite}
            leftElement={
              <AntIcon
                style={{
                  paddingHorizontal: 12,
                }}
                name="search1"
                color={Colors.Custom.Gray300}
                size={18}
              />
            }
            rightElement={
              !!componentState.searchString ? (
                <AntIcon
                  name="close"
                  size={15}
                  style={{paddingRight: 12}}
                  color={Colors.Custom.Gray400}
                  onPress={() => {
                    fetchContacts('');
                    onComponentStateChange('searchString', '');
                  }}
                />
              ) : (
                <> </>
              )
            }
            fontSize={15}
          />
          {isRsvpEnabled &&
            <SideTrackingListSort
              currentActiveFilter={props?.filterStatusId}
              appointmentStatusList={APPOINTMENT_PARTICIPANT_STATUS_LIST}
              onFilterChange={(filter) => {
                resetPagination();
                if (filter === APPOINTMENT_PARTICIPANT_STATUS_LIST?.need_action) {
                  props?.onFilterChange(null);
                  return;
                }
                props?.onFilterChange(filter);
              }}
            />
          }
        </HStack>
      </VStack>
      <VStack
        overflowY={'scroll'}
        flex={1}
        position={'relative'}
        style={{
          maxHeight: '70vh',
        }}
        opacity={participantStatusLoadingId ? 0.5 : 1}
      >
        {contactsLoading ? (
          <View p={4}>
            <Skeleton />
          </View>
        ) : (
          <>
            <FlatList
              key={JSON.stringify(contactList)}
              contentContainerStyle={{
                paddingBottom: 30,
              }}
              ListEmptyComponent={
                <Center h={'50vh'}>
                  <Empty />
                </Center>
              }
              data={contactList}
              renderItem={renderContactListItem}
              keyExtractor={(item) =>
                item.uuid + participantStatusMapData[item.uuid]
              }
            />
            {props?.participantStatusLoadingId && (
              <View
                style={{
                  backgroundColor: 'transparent',
                  opacity: 1,
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Spinner size={'lg'} />
              </View>
            )}
          </>
        )}
      </VStack>
      <View
        bottom={0}
        position={'absolute'}
        w="100%"
        backgroundColor={Colors.Custom.Gray50}
        alignItems={'center'}
        p={2}
        borderTopWidth={1}
        borderColor={Colors.Custom.Gray300}
      >
        <Pagination
          total={paginationData.total}
          current={paginationData.page}
          style={{
            justifyContent: 'center',
            display: 'flex',
          }}
          showSizeChanger={false}
          pageSize={paginationData.pageSize}
          onChange={(page, pageSize) => {
            setPaginationData(prev => ({
              ...prev,
              page: page,
              pageSize: pageSize,
            }));
          }}
        />
      </View>
      <Modal
        key={JSON.stringify(componentState?.deleteInvite)}
        open={componentState?.deleteInvite?.confirm}
        footer={null}
        closable
        closeIcon={<></>}
      >
        <VStack>
          <Text fontWeight={'bold'} fontSize={18}>
            {intl.formatMessage({id: isRsvpEnabled ? 'deleteInvite' : 'removeMemberTitle'})}
          </Text>
          {
            <Text>
              {intl.formatMessage({
                id: isRsvpEnabled
                  ? 'deleteInviteConfirmation'
                  : 'removeMemberConfirmation',
              })}
            </Text>
          }
          {isRsvpEnabled && componentState.deleteInvite.data?.statusId ===
            APPOINTMENT_PARTICIPANT_STATUS_LIST.accepted && (
            <Text>Note: Patient has already accepted the invite</Text>
          )}
          <HStack space={2} mt={4}>
            <Spacer />
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: () =>
                  setComponentState((prev) => ({
                    ...prev,
                    deleteInvite: {confirm: false},
                  })),
              }}
              customProps={{
                btnText: (
                  <DisplayText
                    textLocalId={'cancel'}
                    size={'smBold'}
                    extraStyles={{
                      color: Colors.Custom.mainSecondaryBrown,
                    }}
                  />
                ),
                withRightBorder: false,
              }}
            ></FoldButton>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  handleParticipantActions(
                    GroupAppointmentParticipantActions.DELETE_INVITATION,
                    componentState.deleteInvite.data
                  );
                  setComponentState((prev) => ({
                    ...prev,
                    deleteInvite: {confirm: false},
                  }));
                },
              }}
              customProps={{
                btnText: (
                  <DisplayText
                    textLocalId={isRsvpEnabled ? 'delete' : 'remove'}
                    size={'smBold'}
                    extraStyles={{
                      color: Colors.Custom.mainPrimaryPurple,
                    }}
                  />
                ),
                withRightBorder: false,
              }}
            ></FoldButton>
          </HStack>
        </VStack>
      </Modal>
    </VStack>
  );
};

export default withMiniContactViewHOC(SideTrackingView);
