import {Checkbox, Popover} from "antd"
import {useIntl} from "react-intl";
import {View,Text} from "react-native";
import {Colors} from "../../../styles";
import {FoldButton} from "../../CommonComponents/FoldButton/FoldButton";
import {useState} from "react";
import {COMMON_ACTION_CODES} from "../../../constants/ActionConst";

export const UpdateSignatureInfoPopover = (props: {
  onActionPerformed: (actionCode: string, actionData?: any) => void
}) => {
  const intl = useIntl();
  const [stateData, setStateData] = useState({
    isChecked: false
  })

  const popoverBody = () => {
    return (
      <View style={{
        paddingVertical: 2,
        width: 338,
      }}>
        <Text style={{
          fontSize: 14,
          lineHeight: 16.8,
          color: Colors.FoldPixel.GRAY300,
          textAlign: 'center'
        }}>
          {intl.formatMessage({id: 'signatureUpdatePopoverInfo'})}
        </Text>
        <View style={{
          alignItems: 'center',
          marginVertical: 12,
        }}>
          <Checkbox
            style={{
              alignItems: 'flex-start'
            }}
            key={'UpdateSignatureInfoPopover'}
            className="copy-checkbox"prefixCls=""
            onChange={(e) => {
              setStateData((prev) => {
                return {
                  ...prev,
                  isChecked: e?.target?.checked
                }
              })
            }}
          >
            <Text style={{
              fontSize: 14,
              lineHeight: 16.8,
              color: Colors.FoldPixel.GRAY300,
              textAlign: 'center',
            }}>
              {intl.formatMessage({id: 'signatureUpdatePopoverCheckboxText'})}
            </Text>
          </Checkbox>
          </View>
        <FoldButton
          customProps={{
            btnText: 'Done',
          }}
          nativeProps={{
            onPress() {
              props?.onActionPerformed(COMMON_ACTION_CODES.SAVE, {isChecked: stateData?.isChecked});
            },
            backgroundColor: Colors.Custom.Primary300,
            _text: {
              fontSize: 14,
              fontWeight: 500,
              lineHeight: 16.8,
              color: 'white',
            },
            paddingX: 2,
            borderWidth: 0.5,
            borderColor: Colors.Custom.Primary300,
          }}
        />
      </View>
    );
  };

  return <>
    <Popover
      placement="topLeft"
      overlayInnerStyle={{
        padding: 8,
        borderRadius: 16,
      }}
      overlayClassName="update-Signature-Info"
      content={popoverBody}
      trigger="click"
      visible={true}
      onVisibleChange={() => {
        props?.onActionPerformed(COMMON_ACTION_CODES.CLOSE_POPUP)
      }}
    ></Popover>
  </>
}