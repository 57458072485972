import {Pressable, Text} from 'native-base';
import React from 'react';
import {DATE_FORMATS} from '../../../../../constants';
import {Colors} from '../../../../../styles';
import {testID} from '../../../../../testUtils';
import {convertToTimezone} from '../../../../../utils/DateUtils';
import {ISlot} from '../BookAppointment/Interfaces';
import {isWeb} from '../../../../../utils/platformCheckUtils';

interface SlotProps {
  handleSlotSelection: (slot: any) => void;
  slotListItem: ISlot;
  selectedSlot?: ISlot;
  selectedTimezone: string;
}

const Slot = (props: SlotProps) => {
  const {slotListItem, selectedSlot} = props;
  const title = convertToTimezone(
    slotListItem.startDateTime,
    props.selectedTimezone
  ).format(DATE_FORMATS.DISPLAY_TIME_FORMAT);

  const isActiveSlot = selectedSlot?.startDateTime === slotListItem.startDateTime 
  return (
    <Pressable
      px={2}
      minW={isWeb() ? 24 : 16}
      py={1}
      borderRadius={4}
      borderColor={isActiveSlot ? Colors.FoldPixel.PRIMARY300 : Colors.FoldPixel.GRAY50}
      borderWidth={0.5}
      onPress={() => {
        props.handleSlotSelection(slotListItem);
      }}
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor:
          isActiveSlot
            ? Colors.FoldPixel.PRIMARY100
            : Colors.FoldPixel.GRAY50,
      }}
      flexDirection={'row'}
      backgroundColor={Colors.Custom.ContainerBGColor}
      {...testID(title)}
    >
      <Text
        fontSize={12}
        fontWeight={500}
        style={{
          marginHorizontal: 5,
          textAlign: 'center',
          color:
            isActiveSlot
              ? Colors.FoldPixel.PRIMARY300
              : Colors.FoldPixel.GRAY300,
        }}
      >
        {title}
      </Text>
    </Pressable>
  );
};

export default Slot;
