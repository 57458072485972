import {useLazyQuery} from '@apollo/client';
import {useEffect, useState} from 'react';
import {GET_CONTACT_ACCOUNT_LOCATIONS_BY_CONTACT_UUIDS} from '../../services/Contacts/ContactsQueries';
import {IContactLocation, IContactLocationProps} from './interfaces';

export const useContactsLocation = (props: IContactLocationProps) => {
  const {contactUuid, skipCall} = props;
  const [contactsLocationState, setContactsLocations] = useState<{
    loading: boolean;
    data: IContactLocation[];
  }>({loading: false, data: []});

  const [getContactAccountLocationsByContactUuids] = useLazyQuery(
    GET_CONTACT_ACCOUNT_LOCATIONS_BY_CONTACT_UUIDS,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const getContactsLocations = async (contactUuid: string) => {
    try {
      const response = await getContactAccountLocationsByContactUuids({
        variables: {
          contactId: contactUuid,
        },
      });
      setContactsLocations(prev => ({...prev, loading: false, data: response?.data?.userContactPracticeLocations || []}));
      return response?.data?.userContactPracticeLocations || [];
    } catch (error) {
      console.error(error);
      setContactsLocations(prev => ({...prev, loading: false, data: []}));
      return [];
    }
  };

  useEffect(() => {
    if (!contactUuid || skipCall) {
      setContactsLocations({loading: false, data: []});
      return;
    }
    if (!contactsLocationState.loading) {
      setContactsLocations(prev => ({...prev, loading: true}));
      getContactsLocations(contactUuid);
    }
  }, [contactUuid]);

  return {
    loading: contactsLocationState.loading,
    data: contactsLocationState?.data || [],
    getContactsLocations,
  };
};
