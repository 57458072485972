import { gql } from '@apollo/client';

export const GET_SMS_TAGS_WITH_CONTACT = gql`
query getSmsTags($smsStatusBoolExp: smsStatus_bool_exp, $smsTagsBoolExp: smsTags_bool_exp) {
  smsTags(distinct_on: [smsSid], where: $smsTagsBoolExp) {
    smsSid
    createdOn: createdAt
    contactSmsLog {
      contactUuid
      toPhoneNumber
    }
    smsStatus(where: $smsStatusBoolExp) {
      status
      createdOn: createdAt
    }
  }
}
`;

export const GET_SMS_TAGS_WITHOUT_CONTACT = gql`
query getSmsTags($smsStatusBoolExp: smsStatus_bool_exp, $smsTagsBoolExp: smsTags_bool_exp) {
  smsTags(distinct_on: [smsSid], where: $smsTagsBoolExp) {
    smsSid
    createdOn: createdAt
    smsStatus(where: $smsStatusBoolExp) {
      status
      createdOn: createdAt
    }
  }
}
`;

export const GET_SMS_TAGS_WITH_CONTACT_NEW = gql`
query getSmsTags($smsStatusBoolExp: smsStatus_bool_exp, $smsTagsBoolExp1: smsTags_bool_exp, $smsTagsBoolExp2: smsTags_bool_exp) {
  communicationLogs(distinct_on: [serviceId], where: {communicationType: {_eq: "SMS"}, _and: [{communicationTags: $smsTagsBoolExp1}, {communicationTags: $smsTagsBoolExp2}, {communicationStatus: $smsStatusBoolExp}]}) {
    serviceId
    receiverResourceType
    receiverContact{
      uuid
    }
    receiverContact{
      uuid
    }
    receiverUser{
      uuid
    }
    communicationTags(distinct_on:smsSid) {
      
      smsSid
      tagType
      tag
      createdOn: createdAt
      smsStatus(where: $smsStatusBoolExp) {
        status
        createdOn: createdAt
      }
      contactSmsLog {
        contactUuid
        toPhoneNumber
      }
    }
  }
}`
;

export const GET_SMS_TAGS_WITHOUT_CONTACT_NEW = gql`
query getSmsTags($smsStatusBoolExp: smsStatus_bool_exp, $smsTagsBoolExp1: smsTags_bool_exp, $smsTagsBoolExp2: smsTags_bool_exp) {
  communicationLogs(distinct_on:[serviceId],where: {communicationType:{_eq:"SMS"},_and:[{communicationTags:$smsTagsBoolExp1},{communicationTags:$smsTagsBoolExp2}, {communicationStatus: $smsStatusBoolExp} ]}) {
    serviceId
    communicationTags(distinct_on:[smsSid]) {
      smsSid
      tagType
      tag
      createdOn: createdAt
      smsStatus(where: $smsStatusBoolExp) {
        status
        createdOn: createdAt
      }
    }
  }
}
`;


export default {
  GET_SMS_TAGS_WITH_CONTACT,
  GET_SMS_TAGS_WITHOUT_CONTACT,
  GET_SMS_TAGS_WITH_CONTACT_NEW,
  GET_SMS_TAGS_WITHOUT_CONTACT_NEW,
};
