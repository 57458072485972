import {Select, Tooltip} from 'antd';
import {Box, Button, FormControl, HStack, Icon, Text, View} from 'native-base';
import React, { useState } from 'react';
import {Colors} from '../../../../../../../styles';
import {
  APPOINTMENT_COLOR_INDICATOR_SIZE,
  DefaultFallbackAppointmentTypeColor,
} from '../../../../../../RightSideContainer/AccountSettings/AppointmentTypes/AppointmentTypeConst';
import {IAppointmentType} from '../../../../../../RightSideContainer/AccountSettings/AppointmentTypes/Interfaces';
import {DisplayText} from '../../../../../DisplayText/DisplayText';
import {useIntl} from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import './styles.css';
import {CaretDownOutlined} from '@ant-design/icons';
const renderSelectElem = (
  item: IAppointmentType,
  isSelectedValueRender: boolean
) => {
  if (item === undefined) {
    return null;
  }
  return (
    <HStack
      alignItems={'center'}
      mt={0.5}
      maxW={isSelectedValueRender ? '100%' : undefined}
      pl={
        isSelectedValueRender
          ? 1
          : undefined
      }
    >
      <Box
        w={APPOINTMENT_COLOR_INDICATOR_SIZE}
        h={APPOINTMENT_COLOR_INDICATOR_SIZE}
        mr={2}
        rounded="full"
        backgroundColor={
          item?.appointmentCardProperties?.bgColorPrimary ||
          DefaultFallbackAppointmentTypeColor
        }
      />
      <Text
        fontSize={14}
        fontWeight={'medium'}
        {...(isSelectedValueRender ? {maxW: '90%', isTruncated: true} : {})}
      >
        {item?.eventName}
      </Text>
    </HStack>
  );
};

const CustomAppointmentTypeSelect = (props: {
  allowClear: boolean;
  showSearch: boolean;
  labelInValue: boolean;
  filterOption: any;
  isRequired: boolean;
  onChange: (data: any) => any;
  value: any;
  data: IAppointmentType[];
  optionProps: any;
  extraStyle?: any;
  customStyle?: any;
  isDisabled?: boolean;
}) => {
  const {
    allowClear,
    showSearch,
    labelInValue,
    filterOption,
    isRequired,
    onChange,
    value,
    data,
    optionProps,
    customStyle,
    extraStyle,
  } = props;
  const intl = useIntl();
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const getWidth = () => {
    if (extraStyle?.flex) {
      return '100%';
    } else {
      return 300;
    }
  };

  return (
    <>
      <FormControl isRequired={isRequired}>
        <HStack space={1} marginBottom={1} justifyContent={'space-between'}>
          <HStack>
            <FormControl.Label marginLeft={0}>
              <HStack space={1} alignItems={'center'}>
                <DisplayText
                  size={'smMedium'}
                  extraStyles={{
                    color: Colors.Custom.Gray500,
                    fontWeight: extraStyle?.fontWeight || 400,
                    fontSize: extraStyle?.fontSize || 14,
                  }}
                  textLocalId={'appointmentType' || ''}
                />
              </HStack>
            </FormControl.Label>
            <Tooltip
              title={intl.formatMessage({id: 'appointmentTypeInfoMsg'})}
              placement={'top'}
              open={showTooltip}
              destroyTooltipOnHide={true}
            >
              <View>
                <Button
                  onHoverIn={() => setShowTooltip(true)}
                  onHoverOut={() => setShowTooltip(false)}
                  marginTop={0.5}
                  marginLeft={-2}
                  marginRight={2}
                  height={5}
                  width={5}
                  size={'smMedium'}
                  variant={'unstyled'}
                  _text={{
                    color: Colors.Custom.Gray500,
                    fontWeight: extraStyle?.fontWeight || 400,
                    fontSize: extraStyle?.fontSize || 14,
                  }}
                  _hover={{
                    _text: {
                      color: Colors.Custom.PurpleColor,
                    },
                  }}
                  endIcon={
                    <Icon as={AntIcon} name="infocirlceo" size="smMedium" />
                  }
                />
              </View>
            </Tooltip>
          </HStack>
        </HStack>
        <Select
          className="custom-select-box"
          placeholder="Select appointment type"
          allowClear={allowClear}
          value={
            value
              ? {
                  key: value?.id,
                  label: renderSelectElem(
                    data.filter(
                      (item: IAppointmentType) => item?.id === value?.id
                    )?.[0],
                    true
                  ),
                  value: value?.id,
                }
              : undefined
          }
          showSearch={props?.showSearch}
          disabled={props?.isDisabled}
          labelInValue={labelInValue}
          filterOption={filterOption}
          onChange={(value, option) => {
            onChange(value);
          }}
          style={{
            width: customStyle?.width ? customStyle?.width : getWidth(),
            height: customStyle?.height ? customStyle.height : 32,
            backgroundColor: customStyle?.backgroundColor
              ? customStyle.backgroundColor
              : 'none',
          }}
          suffixIcon={
            <CaretDownOutlined
              style={{
                color: Colors.FoldPixel.GRAY300,
              }}
            />
          }
        >
          {data.map((item: IAppointmentType) => (
            <Select.Option key={item.id} title={item.eventName}>
              {renderSelectElem(item, false)}
            </Select.Option>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default CustomAppointmentTypeSelect;
