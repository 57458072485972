import { useContext, useState } from "react";
import MessagingContactDetailsDrawer from "../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/MessagingContactDetailsDrawer";
import { COMMON_ACTION_CODES, CONVERSATION_ACTION_CODES } from "../../constants/ActionConst";
import { isPatientDetailViewAllowed } from "../RightSideContainer/UserAccess/UserAccessPermission";
import { useNavigate, } from "react-router-dom";
import { CommonDataContext } from "../../context/CommonDataContext";
import { getViewInEHRLink, redirectToNewTab } from "../SideCar/SidecarUtils";
import { useLazyQuery } from "@apollo/client";
import { GET_PATIENT_ID_BY_CONTACT_IDS } from "../../services/Contacts/ContactsQueries";
import { getEHRUrl } from "../../utils/capabilityUtils";


export function withMiniContactViewHOC(component: any) {


    const withMiniContactView: any = (props: any) => {
        const [contactId, setContactId] = useState<any>(null);
        const [patientLocationId, setPatientLocationId] = useState('');
        const commonData = useContext(CommonDataContext);
        const navigate = useNavigate();
        const ehrURL = getEHRUrl(patientLocationId, '');
        const isPatientDetailViewAllowedConfig = isPatientDetailViewAllowed(commonData.currentUserRoles,commonData.userPermissions);
        const [getContactDetails] = useLazyQuery(GET_PATIENT_ID_BY_CONTACT_IDS, {
            fetchPolicy: 'no-cache',
          },
        );

        const redirectToNewTabForSidecar = async (contactId: string) => {
          try {
            const contactData = await getContactDetails({
              variables: {
                contactIdList: [contactId]
              }
            });
            const patientId = contactData?.data?.contacts?.[0]?.patient?.patientId;
            const locationId =
              contactData?.data?.contact?.contactPracticeLocations?.[0]
                ?.accountLocation?.uuid;
            if (locationId) {
              setPatientLocationId(locationId);
            }
            if (patientId) {
              const openInEhrUrl = getViewInEHRLink(patientId, ehrURL);
              window.open(openInEhrUrl, '_blank', 'noopener,noreferrer');
            } else {
              redirectToNewTab(`/members/patient/${contactId}`);
            }
          } catch (error) {

            redirectToNewTab(`/members/patient/${contactId}`);
          }
        }

        const openContactInWindowOrOpenContactIdDrawer = (contactId?: string) => {
            if (!contactId) {
                return;
            }

            if(isPatientDetailViewAllowedConfig){
                if (
                  commonData.sidecarContext?.isSidecar
                ) {
                  redirectToNewTabForSidecar(contactId);
                  return;
                }
                window.open(
                    `/#/members/patient/${contactId}`,
                    '_blank'
                  )
            }else{
                setContactId(contactId);
            }
        }

        const navigateOrOpenContactIdDrawer = (contactId?: string, additionalData?:any) => {
            if (!contactId) {
                return;
            }

            if(isPatientDetailViewAllowedConfig){
                if (
                  commonData.sidecarContext?.isSidecar
                ) {
                  redirectToNewTabForSidecar(contactId);
                  return;
                }
                navigate(`/members/patient/${contactId}`, additionalData)
            }else{
                setContactId(contactId);
            }
        }

        const navigateOrOpenLeadInWindow = (contactId?: string | number, isNavigate?: boolean) => {
            if (!contactId) {
              return;
            }
            if (commonData.sidecarContext?.isSidecar) {
              redirectToNewTab(`/members/prospect/${contactId}`);
              return;
            }

            if (isNavigate) {
              navigate(`/members/prospect/${contactId}`);
            } else {
              window.open(`/#/members/prospect/${contactId}`, '_blank');
            }
        }

        const openContactDrawer = (contactId?: string) => {
            if (!contactId) {
                return;
            }

            setContactId(contactId);
        }
        return (<>
            {
                contactId ? <MessagingContactDetailsDrawer
                    contactData={{
                        id: contactId
                    }}
                    isDrawerVisible={contactId ? true : false}
                    onSideBarActionPerformed={(action?: any, actionData?: any) => {
                        if (action == CONVERSATION_ACTION_CODES.DRAWER_CLOSE) {
                            setContactId(null);
                            return
                        } else if (action == COMMON_ACTION_CODES.ADDED_OR_UPDATED) {
                            // props?.onActionPerformed(action, actionData);
                            setContactId(null);
                            return
                        }
                    }}
                    borderLessView={true}
                /> : null
            }

            <>{component({ ...props, openContactDrawer, navigateOrOpenContactIdDrawer, openContactInWindowOrOpenContactIdDrawer, navigateOrOpenLeadInWindow })}</>
        </>)
    }
    return withMiniContactView
}

