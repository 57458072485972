import React from 'react';
import {Path, Svg} from 'react-native-svg';
import {ICommonSvgProps} from './interfaces';

const InfoIconSvg = (props: ICommonSvgProps) => {
  return (
    <Svg
      width={props.size || "22"}
      height={props.size || "22"}
      viewBox="0 0 22 22"
      fill="none"
    >
      <Path
        d="M11 16V10M11 7.5V7M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
        stroke={props.customStrokeColor || "#0E66E9"}
        stroke-linecap="round"
      />
    </Svg>
  );
};

export default InfoIconSvg;
