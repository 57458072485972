import React from 'react';
import {Path, Svg} from 'react-native-svg';

const InProgressSvg = (props: any) => {
  return (
    <Svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <Path
        d="M6 1.5V3M10.5 6H9M6 10.5V9M1.5 6H3M2.81795 2.81802L3.87861 3.87868M9.18192 2.81802L8.12125 3.87868M9.18205 9.18197L8.12139 8.12131M2.81808 9.18197L3.87874 8.12131"
        stroke="#F79009"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Svg>
  );
};

export default InProgressSvg;
