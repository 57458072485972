import {Select, Tooltip, Spin} from 'antd';
import {Button,FormControl,HStack,Icon,Text,View} from 'native-base';
import {useEffect,useState} from 'react';
import { useIntl } from 'react-intl';
import {Colors} from '../../../styles';
import {DisplayText} from '../DisplayText/DisplayText';
import AntIcon from 'react-native-vector-icons/AntDesign';
import './ModalActionStyle.css';
import { CaretDownOutlined } from '@ant-design/icons';
import { TestIdentifiers, testID } from '../../../testUtils';
const {Option} = Select;

interface IModalActionAntSelect {
  isRequired?: boolean;
  isInvalid?: string | boolean | any;
  value?: any;
  label?: string;
  labelInfo?: string;
  isOpen?: boolean;
  defaultValue?: any;
  isDisabled?: any;
  labelInValue?: boolean;
  onFocus?: any;
  onBlur?: any;
  onSearch?: any;
  onChange?: any;
  onSelect?: any;
  disabled?: any;
  filterOption?: boolean;
  placeholder?: string;
  loading?: any;
  mode?: 'multiple' | 'tags' | undefined;
  tagRender?: any;
  notFoundContent?: any;
  options?: any;
  allowClear?: boolean;
  showSearch?: boolean;
  className?: any;
  errors?: string | boolean;
  errorText?: string | boolean;
  customStyle?: any;
  optionProps?: IOptionProps;
  data?: any;
  marginTop?: string | number;
  extraStyle?: any;
  bordered?: boolean;
  onClear: () => void;
  size?: string;
  selectStyle?: any;
}

interface IOptionProps {
  key: any;
  value: any;
  label: any;
  disabled: boolean;
}

export const ModalActionAntSelect = (props: any) => {
  const {
    toShowSelectDropdownArrowSvg,
    isRequired,
    disabled,
    value,
    label,
    labelInfo,
    defaultValue,
    allowClear,
    labelInValue,
    isOpen,
    onFocus,
    onBlur,
    onSearch,
    onChange,
    onSelect,
    placeholder,
    loading,
    mode,
    tagRender,
    notFoundContent,
    options,
    className,
    errors,
    errorText,
    customStyle,
    optionProps,
    data,
    marginTop,
    extraStyle,
    isInvalid,
    showSearch,
    filterOption,
    isDisabled,
    style,
    leftMargin,
    bordered,
    rightLabelTitle,
    onRightLabelClick,
    filterIds,
    optionsArray,
    extraLabel,
    extraLabelText,
    height,
    dropdownStyle,
    onClear,
    infoText,
    enableSorting,
    selectActionLoading,
    size,
    hideCustomIcon,
    isSidecarTaskLabelSelect,
    selectStyle,
    renderCustomOption,
  } = props;

  const intl = useIntl();

  const getWidth = () => {
    if (extraStyle?.flex) {
      return '100%';
    } else {
      return 300;
    }
  };

  const [dataIs,setData] = useState([])

  const getSortedData = (data: any[]) => {
   return  data.sort((a, b) => {
      if (a && b) {
        const labelA = a[optionProps?.label]?.toString();
        const labelB = b[optionProps?.label]?.toString();
        return labelA?.localeCompare(labelB);
      } else {
        return 0;
      }
    })
  };

  const getSuffixIcon = (toShowSelectDropdownArrowSvg: boolean, selectActionLoading: boolean ): JSX.Element => {
    return (toShowSelectDropdownArrowSvg || selectActionLoading) ?
      <Spin size="small" /> :
      <CaretDownOutlined
        style={{
          color: Colors.FoldPixel.GRAY300
        }}
      />
  }

  const getOption = (option: any, optionProps: any) => {
    return renderCustomOption ? renderCustomOption(option) : option?.[optionProps?.label]
  }

  useEffect(()=>{
    setData(data)
  },[data])

  return (
    <View style={[props.style]} mt={marginTop ? marginTop : ''}>
      <FormControl
        isRequired={isRequired}
        isInvalid={errors || isInvalid}
        isDisabled={isDisabled}
        marginLeft={leftMargin ? parseInt(leftMargin, 10) : 0}
        {...testID(isRequired ? TestIdentifiers.isRequiredInput : '')}
      >
        {label && (
          <HStack space={1} marginBottom={customStyle?.labelBottomMargin != undefined ? customStyle?.labelBottomMargin : (isSidecarTaskLabelSelect ? 0 : 1)} justifyContent={'space-between'}>
            <HStack>
              <FormControl.Label
                marginTop={rightLabelTitle ? 2 : 0}
                marginLeft={0}
              >
                <HStack space={1} alignItems={'center'}>
                  <DisplayText
                    size={customStyle?.labelSize || 'smMedium'}
                    extraStyles={{
                      color: extraStyle?.fontColor || Colors.Custom.Gray500,
                      fontWeight: extraStyle?.fontWeight || 400,
                      fontSize: extraStyle?.fontSize || 14,
                    }}
                    textLocalId={label || ''}
                  />
                  {infoText ?       
                    <Tooltip
                      title={infoText}
                      placement={'top'}
                      destroyTooltipOnHide={true}
                    >
                      <Icon
                        mx={2}
                        as={AntIcon}
                        color={Colors.Custom.Gray500}
                        name="infocirlceo"
                        size="smMedium"
                        {...testID('image')}
                      />
                    </Tooltip> : null
                  }
                </HStack>
              </FormControl.Label>
              {
                labelInfo &&
                <Tooltip
                  title={intl.formatMessage({ id: labelInfo })}
                  placement={'top'} destroyTooltipOnHide={true} >
                  <View>
                    <Button
                      marginTop={0.5}
                      marginLeft={-2}
                      marginRight={2}
                      height={5}
                      width={5}
                      size={'smMedium'}
                      variant={'unstyled'}
                      _text={{
                        color: Colors.Custom.Gray500,
                        fontWeight: extraStyle?.fontWeight || 400,
                        fontSize: extraStyle?.fontSize || 14,
                      }}
                      _hover={{
                        _text: {
                          color: Colors.Custom.PurpleColor,
                        }
                      }}
                      endIcon={<Icon as={AntIcon} name="infocirlceo" size="smMedium"  {...testID('image')}/>}
                      {...testID(TestIdentifiers.infoBtn)}
                    />
                  </View>
                </Tooltip>
              }
              {extraLabel && (
                <Text mt={0.5} ml={-2} fontSize="xs" color="gray.500">
                  {extraLabelText}
                </Text>
              )}
            </HStack>
            {rightLabelTitle && (
              <Button
                style={{
                  height: 36,
                }}
                color={Colors.secondary['800']}
                variant="link"
                size={'lg'}
                onPress={() => {
                  onRightLabelClick && onRightLabelClick();
                }}
                {...testID(rightLabelTitle)}
              >
                {`+ ${rightLabelTitle}`}
              </Button>
            )}
          </HStack>
        )}

        <Select
          size={size || 'large'}
          status={errors || isInvalid ? 'error' : ''}
          allowClear={allowClear}
          suffixIcon={!hideCustomIcon ? getSuffixIcon(toShowSelectDropdownArrowSvg, selectActionLoading): undefined}
          showSearch={showSearch}
          labelInValue={labelInValue}
          filterOption={filterOption}
          disabled={disabled}
          style={{
            color: selectStyle?.fontColor || Colors.FoldPixel.GRAY400,
            width: customStyle?.width ? customStyle?.width : getWidth(),
            height: customStyle?.height ? customStyle.height : 40,
            backgroundColor: customStyle?.backgroundColor
              ? customStyle.backgroundColor
              : 'none',
            borderRadius: customStyle?.borderRadius ? customStyle?.borderRadius : 8,
            borderColor: customStyle?.borderColor ? customStyle?.borderColor : 'none',
          }}
          dropdownStyle={{
            borderRadius: dropdownStyle ? dropdownStyle?.borderRadius : 8,
          }}
          value={value}
          defaultValue={defaultValue}
          open={isOpen}
          onClear={onClear}
          onFocus={() => onFocus && onFocus()}
          onBlur={() => onBlur && onBlur()}
          onSearch={onSearch && onSearch}
          onChange={(value: any[], data: any) => {
            onChange && onChange(value, data);
          }}
          onSelect={() => onSelect && onSelect()}
          placeholder={placeholder}
          {...testID(placeholder)}
          loading={loading}
          mode={mode ? mode : undefined}
          tagRender={tagRender}
          notFoundContent={notFoundContent}
          options={options}
          className={className}
          bordered={bordered}
          maxTagCount={'responsive'}
          data-testid={TestIdentifiers.selectDropdown}
        >
          {optionsArray?.length
            ? optionsArray
            : (enableSorting
              ? getSortedData(dataIs|| []): (dataIs || [])).map((option: any) => {
                  return filterIds &&
                    filterIds.length > 0 &&
                    filterIds.includes(option.key) ? null : (
                    <Option
                      disabled={optionProps?.disabled || false}
                      key={option && option[optionProps?.key]}
                      value={option && option[optionProps?.value]}
                      {...testID(option[optionProps?.label])}
                    >
                      {getOption(option, optionProps)}
                    </Option>
                  );
                })}
        </Select>

        {errors && (
          <FormControl.ErrorMessage
            _text={{
              fontSize: 'xs',
              color: 'error.500',
              fontWeight: 500,
            }}
            {...testID(TestIdentifiers.errorMessage)}
          >
            {errorText}
          </FormControl.ErrorMessage>
        )}
        {alert && (
          <Text fontSize="xs" color="error.500" fontWeight="500">
            {alert}
          </Text>
        )}
      </FormControl>
    </View>
  );
};
