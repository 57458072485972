import React from 'react';
import {Select, Spin, Tag} from 'antd';
import {debounce} from 'lodash';
import {styles} from '../CreateChannelView/CreateChannelViewStyle';
import {FormControl, Text} from 'native-base';
import {Colors} from '../../../styles';
import {DisplayText} from '../DisplayText/DisplayText';
import {filterInternalUsers, isStringEmpty} from './CustomUserSearchUtils';
import {OptionCheck} from './UserOptionCheck';
import Feather from 'react-native-vector-icons/Feather';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {useIntl} from 'react-intl';

import {
  CUSTOM_USER_SEARCH_MODE,
  ICustomUserSearchViewProps,
  ICustomUserTagProps,
  IInternalUserOption,
  IUserOptionCheck,
} from './interface';
import { TestIdentifiers, testID } from '../../../testUtils';

export const CustomUserSearchView = (props: ICustomUserSearchViewProps) => {
  const intl = useIntl();

  const isDisabledUser = (uuid: string) => props.disabledUsers.includes(uuid);

  const isMultiMode = props.mode === CUSTOM_USER_SEARCH_MODE.MULTIPLE_SELECT;
  // set the state only when CUSTOM_USER_SEARCH_MODE is SINGLE_SELECT
  const [isFocused, setFocusState] = React.useState<boolean>(false);

  const orderBySelectedUsers = (
    users: IUserOptionCheck[]
  ): IUserOptionCheck[] => {
    return users.sort(
      (firstUser: IUserOptionCheck, secUser: IUserOptionCheck) => {
        if (firstUser.checked === secUser.checked) {
          return firstUser?.name?.localeCompare(secUser?.name);
        } else {
          return firstUser.checked ? -1 : 1;
        }
      }
    );
  };

  const getInternalUserOptions = (
    userFinalList: string[]
  ): IInternalUserOption[] => {
    const userList: IUserOptionCheck[] =
      filterInternalUsers(
        props.internalUsersOptions,
        props.disabledUsers,
        props.selectedUsersUUIDs
      ) || [];

    const groupedList: IUserOptionCheck[] = orderBySelectedUsers(userList);
    return groupedList.map((singleUser) => {
      return {
        label: (
          <OptionCheck
            name={singleUser?.name}
            value={singleUser?.uuid}
            userRoles={singleUser?.userRoles || []}
            checked={singleUser?.checked || false}
            isDisabled={singleUser.isDisabled || false}
            showCheckbox={isMultiMode}
            hideTooltip={props?.hideTooltip || false}
          />
        ),
        value: singleUser?.uuid,
        id: singleUser?.id,
        disabled: singleUser.isDisabled || false,
        className: 'custom-option-white',
      };
    });
  };

  const searchUser = (searchValue: string) => {
    props.onActionPerformed(COMMON_ACTION_CODES.SEARCH, searchValue);
  };

  const getPlaceholder = () : string => {
    const isSingleSelectMode = !isMultiMode;
    if (isSingleSelectMode && isFocused) {
      const name = props.selectedUsersList?.[0]?.user?.name;
      if (name) {
        return name;
      }
    }
    return props.placeholder || intl.formatMessage({id: 'search'})
  }

  const CustomUserTag = (params: ICustomUserTagProps) => {
    return (
      <>
        <Tag
          onMouseDown={params.onPreventMouseDown}
          closable={params.isDisabled && !isDisabledUser(params.value)}
          closeIcon={<AntIcon name="close" color="gray" />}
          onClose={() => {
            params.onTagClose(params?.value);
          }}
          icon={
            <Feather
              name="user"
              size={12}
              style={{marginRight: 5}}
              color={Colors.Custom.Gray400}
            />
          }
        >
          {params.label}
        </Tag>
      </>
    );
  };

  return (
    <>
      <FormControl
        isRequired={props.isRequired}
        {...testID(props.isRequired ? TestIdentifiers.isRequiredInput : '')}
      >
        <FormControl.Label
          style={[props.formControlLabelStyles]}
          {...testID(props.label ?? 'internalUsers')}
        >
          {isStringEmpty(props.label) ? (
            <></>
          ) : (
            <DisplayText
              size={props.titleSize}
              extraStyles={{
                color: Colors.Custom.Gray500,
                ...props.extraTitleStyles,
              }}
              textLocalId={props.label ?? 'internalUsers'}
            />
          )}
        </FormControl.Label>

        <Select
          className={props?.className}
          ref={props.userSelectRef && props.userSelectRef}
          mode="multiple"
          style={{width: '100%', alignItems: 'center'}}
          options={getInternalUserOptions(props.selectedUsersUUIDs)}
          disabled={props.isDisabled}
          placeholder={getPlaceholder()}
          onSearch={debounce(searchUser, 600)}
          onFocus={() => {
            const isSingleSelectMode = !isMultiMode;
            if (isSingleSelectMode) {
              setFocusState(true);
            }
          }}
          onBlur={() => {
            searchUser("")
            setFocusState(false);
          }}
          maxTagCount="responsive"
          value={(props.isDisabled || isFocused) ? [] : props.selectedUsersUUIDs}
          onChange={(values, options) => {
            if (isMultiMode) {
              props.onActionPerformed(COMMON_ACTION_CODES.USER_CHANGE, {
                values,
                options,
              });
            } else {
              const value = values[values.length - 1];
              let option = options;
              if (Array.isArray(options)) {
                option = options[options.length - 1];
              }
              if (value && option) {
                props.onActionPerformed(COMMON_ACTION_CODES.USER_CHANGE, {
                  values: [value],
                  options: [option],
                });
              }
            }
          }}
          autoClearSearchValue={false}
          allowClear={props?.allowClear || false}
          onClear={() => {
            props.onActionPerformed(COMMON_ACTION_CODES.USER_CHANGE, {
              values: [],
              options: [],
            });
          }}
          defaultActiveFirstOption={false}
          menuItemSelectedIcon={false}
          tagRender={(tags: any) => {
            const onPreventMouseDown = (event: any) => {
              event.preventDefault();
              event.stopPropagation();
            };
            const label = tags?.label.props?.name || intl.formatMessage({id: 'nameNotFound'});
            return isMultiMode ? (
              <CustomUserTag
                label={label}
                onPreventMouseDown={onPreventMouseDown}
                onTagClose={(uuid: string) => {
                  props.onActionPerformed(
                    COMMON_ACTION_CODES.USER_REMOVE,
                    uuid
                  );
                }}
                value={tags.value}
                isDisabled={!props.isDisabled}
              />
            ) : (
              <>
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: '400',
                    color: Colors.FoldPixel.GRAY400,
                    paddingHorizontal: 8,
                  }}
                >
                  {label}
                </Text>
              </>
            );
          }}
          filterOption={() => true}
          notFoundContent={
            props.isLoading ? (
              <div style={{textAlign: 'center'}}>
                <Spin size="default" />
              </div>
            ) : (
              <Text {...testID(TestIdentifiers.noDataFound)}>{intl.formatMessage({id: 'noDataFound'})}</Text>
            )
          }
          data-testid={TestIdentifiers.selectDropdown}
        ></Select>
      </FormControl>
    </>
  );
};
