import { Drawer } from 'antd';
import {
  HStack,
  Radio,
  Select,
  Text,
  useMediaQuery,
  useToast,
  View,
  VStack
} from 'native-base';
import { useEffect, useState } from 'react';
import {
  BUTTON_TYPE,
  ENTITY_EVENTS_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH
} from '../../../../../constants';
import CommonService from '../../../../../services/CommonService/CommonService';
import { Colors } from '../../../../../styles';
import {
  getAccountUUID,
  getUserActionObjectFromActionCode,
  getUserUUID,
  isEmptyObject,
  isLoginUserBusinessOwner
} from '../../../../../utils/commonUtils';
import { showToast } from '../../../../../utils/commonViewUtils';
import ParticipantAutoComplete from '../../../../common/CalendarWidget/ParticipantAutoComplete/ParticipantAutoComplete';
import { IParticipantSearch } from '../../../../common/CalendarWidget/ParticipantAutoComplete/ParticipantInterfaces';
import { GroupSearch } from '../../../../common/GroupSearch';
import { ModalActionTitle } from '../../../../common/ModalActionTitle/ModalActionTitle';
import EmployerDropdownList from '../../../ContentManagement/CampaignManagement/CampaignSegmentForm/EmployerDropdownList';
import { getWorkFlowListByEventEntity } from '../AddOrUpdateWorkflow/WorkflowApi';
import { executeWorkflowController } from './ExecuteWorkflowUtils';
import InactiveWorkflowWarning from './InactiveWorkflowWarning';
import {
  ExecuteWorkflowProps,
  ExecuteWorkflowState,
  ExecutionViewCodes,
  WorkflowData
} from './interfaces';

const ExecuteWorkflow = (props: ExecuteWorkflowProps) => {
  const isBusinessOwner = isLoginUserBusinessOwner();
  const { isOpen, workflowData, onCompleteAction, showWorkflowList, workflowLocations } = props;
  const [state, setState] = useState<ExecuteWorkflowState>({
    submitting: false,
    apiError: false,
    selectedMembers: [] as IParticipantSearch[],
    selectedGroups: [] as any[],
    selectedEmployerIds: [] as string[],
    viewCode: ExecutionViewCodes.MemberSearch,
    selectedOption: ExecutionViewCodes.MemberSearch as ExecutionViewCodes.MemberSearch | ExecutionViewCodes.GroupSearch | ExecutionViewCodes.EmployerSearch,
    isPersonLevelWorkflow: false,
    workflow: undefined,
    workflowList: [] as WorkflowData[],
  });

  const tenantId = getAccountUUID();
  const userUuid = getUserUUID();

  const commonService = CommonService.getCommonServiceInstance();
  const workflowService = commonService.workflowService;

  const toast = useToast();

  const userActionObject = getUserActionObjectFromActionCode(
    'RUN_EXISTING_AUTOMATION_ON_A_MEMBER'
  );
  const level = userActionObject.level || '';

  const {
    handleSubmit,
    handleOnClose,
    setGroups,
    setSelectedEmployerIds,
    setMembers,
    setPersonLevelWorkflow,
    setViewCode,
    isSubmitButtonDisabled,
  } = executeWorkflowController({
    workflowService,
    workflowData: state.workflow,
    state,
    tenantId,
    userUuid,
    toast,
    showWorkflowList,
    onCompleteAction,
    setState,
    showToast,
    level,
  });

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    { maxWidth: IPAD_WIDTH },
    { maxWidth: IPAD_MINI_WIDTH },
  ]);
  const drawerWidth = isIPadMiniScreen || isIPadScreen ? '50vw' : '38vw';

  useEffect(() => {
    if (!isEmptyObject(workflowData)) {
      setState((prev) => {
        return {
          ...prev,
          workflow: workflowData,
        };
      });
    }
    if (showWorkflowList) {
      getWorkFlowListByEventEntity(ENTITY_EVENTS_TYPE.WORKFLOW_STATIC,
        tenantId,
        false,
        [],
        () => '',
        () => '',
      ).then((response) => {
        setState((prev) => {
          return {
            ...prev,
            workflowList: response,
          };
        });
      });
    }

    setPersonLevelWorkflow();
  }, []);

  return (
    <Drawer
      width={drawerWidth}
      visible={isOpen}
      onClose={() => {
        handleOnClose();
      }}
      title={
        <>
          <ModalActionTitle
            title={'executeWorkflow'}
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'cancel',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  handleOnClose();
                },
              },
              {
                show: true,
                id: 2,
                btnText: 'submit',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                isLoading: state.submitting,
                isDisabled: isSubmitButtonDisabled(),
                onClick: () => {
                  handleSubmit();
                },
              },
            ]}
          />
        </>
      }
    >
      <VStack >
        <VStack style={{ marginBottom: 12 }}>
          <Text style={{ marginVertical: 4 }}>Select an option</Text>
          <Radio.Group
            name="select"
            value={state.selectedOption}
            onChange={(value) => {
              setViewCode(value);
            }}
          >
            <Radio style={{ marginVertical: 4 }} value={ExecutionViewCodes.MemberSearch}>
              Member
            </Radio>
            { !props.isHideGroup ? <Radio style={{ marginVertical: 4 }} value={ExecutionViewCodes.GroupSearch}>
              Patient Group
            </Radio> : <></>}
            { !props.isHideGroup ? <Radio style={{ marginVertical: 4 }} value={ExecutionViewCodes.LeadGroupSearch}>
              Lead Group
            </Radio> : <></>}
            { !props.isHideEmployer ? <Radio style={{ marginVertical: 4 }} value={ExecutionViewCodes.EmployerSearch}>
              Employer
            </Radio> : <></>}
          </Radio.Group>
        </VStack>
      </VStack>

      {state.viewCode === ExecutionViewCodes.MemberSearch && (
        <VStack style={{ marginVertical: 12 }}>
          <ParticipantAutoComplete
            isShowWithEmail={true}
            disableLeadsSearch={false}
            disableUserSearch={true}
            defaultValue={state.selectedMembers}
            onChange={(members) => {
              setMembers(members);
            }}
            showError={false}
            patientsOptionLabel="Members"
            workflowLocations={workflowLocations}
          />
        </VStack>
      )}

      {state.viewCode === ExecutionViewCodes.GroupSearch && (
        <VStack
          style={{
            marginVertical: 12,
          }}
        >
          <GroupSearch
            selectedGroups={state.selectedGroups}
            onSelectGroups={(groupIds) => {
              setGroups(groupIds);
            }}
          />
        </VStack>
      )}
      {state.viewCode === ExecutionViewCodes.LeadGroupSearch && (
        <VStack
          style={{
            marginVertical: 12,
          }}
        >
          <GroupSearch
            contactType={'LEAD'}
            selectedGroups={state.selectedGroups}
            onSelectGroups={(groupIds) => {
              setGroups(groupIds);
            }}
          />
        </VStack>
      )}

      {state.viewCode === ExecutionViewCodes.EmployerSearch && (
        <VStack
          style={{
            marginVertical: 12,
          }}
        >
          <EmployerDropdownList
            defaultValue={state?.selectedEmployerIds}
            onChange={(actionData: any, extraData) => {
              setSelectedEmployerIds(actionData)
            }}
          />
        </VStack>
      )}

      {showWorkflowList && (
        <>
          <VStack style={{ marginVertical: 12 }}>
            <View style={{ marginVertical: 4 }}>
              <Text>Select an Automation</Text>
            </View>

            <Select
              onValueChange={(value) => {
                const selectedWorkflow = state.workflowList.find(
                  (item) => item.id == value
                );
                setState((prev) => {
                  return {
                    ...prev,
                    workflow: selectedWorkflow,
                  };
                });
              }}
            >
              {state.workflowList.map((item) => {
                return (
                  <Select.Item
                    label={item.name || ''}
                    value={item.id}
                    key={item.id}
                  />
                );
              })}
            </Select>
          </VStack>

        </>
      )}
      <HStack marginTop={5}>
        <InactiveWorkflowWarning></InactiveWorkflowWarning>
      </HStack>
    </Drawer>
  );
};

export default ExecuteWorkflow;
