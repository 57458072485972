
import { HStack, Spinner, Text, View, VStack } from "native-base";
import { useContext, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  WorkflowContext
} from '../../../../../context/WorkflowContext';
import { Colors } from "../../../../../styles";
import { getAccountUUID, getDisplayTimeSaved, isLoginUserBusinessOwner } from "../../../../../utils/commonUtils";
import { getNodeLevelExecutionCount, getNodeLevelExecutionStatus, getNodeLevelExecutionStatusWithTime } from "../../Workflow/AddOrUpdateWorkflow/WorkflowApi";
import { IConditionState, IUserActionState } from "./FlowNodeInterface";
import ShowNodeLevelPeopleLogInfo from "./ShowNodeLevelPeopleLogInfo";
import { NODE_TYPE } from "../FlowComponentConstants";
import { isAccountConfigEnabled } from "../../../../../utils/configUtils";
import { CONFIG_CODES } from "../../../../../constants/AccountConfigConst";




function NodeLevelExecutionLogFirst(props: {getPreviousImmediateNode?:any, rootNodeType: string, rootNodeId?: string, parentNode?: string, nodeMetaData: IConditionState | IUserActionState, uiNodeId: string }) {
  const workflowContext = useContext(WorkflowContext);
  const nodeMasterDataMap = workflowContext.nodeMasterDataMap;
  const groupByEventList: string[] = nodeMasterDataMap[props.rootNodeType]?.groupByEventList || ['CONTACT']
  const infoUiNode = (props?.nodeMetaData?.type == 'AllCondition' && props.parentNode ? props.parentNode : props.uiNodeId) || '';
  const [searchParams, setSearchParams] = useSearchParams();
  const [backGroundColor, onChangeBackGroundColorChange] = useState<any>(Colors.FoldPixel.GRAY200)
  const [workflowExecutionId] = useState<any>((searchParams.get('workflowExecutionId') || ''));
  const accountUUID = getAccountUUID();
  const isBusinessOwner = isLoginUserBusinessOwner();
  const pathParams = useParams();
  const workflowMasterId = pathParams.workflowId;
  const [uiNodeId] = useState(infoUiNode.slice(0, 65))
  const [rootNodeId] = useState((props.rootNodeId || '').slice(0, 65))
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED)
  const locationIntersections = workflowContext?.locationIntersections;
  const [parentNodeId, setParentNodeId] = useState((props.parentNode || '').slice(0, 65))
  const auditFilterLocationIds = (searchParams.get('auditFilterLocationIds') ? searchParams.get('auditFilterLocationIds') : undefined)
  const filterLocationIds = auditFilterLocationIds? auditFilterLocationIds.split(',') : locationIntersections

  useEffect(()=>{
    if(props.uiNodeId && props.getPreviousImmediateNode){
      
      let parentNode = props.getPreviousImmediateNode(props.uiNodeId)
      if(parentNode && parentNode?.data?.nodeType == NODE_TYPE.BITWISE_AND || parentNode?.data?.nodeType == NODE_TYPE.BITWISE_OR){
         parentNode = props.getPreviousImmediateNode(parentNode.id)
      }
      if(parentNode?.id != parentNodeId){
        setParentNodeId(parentNode?.id)
      }
    }
  }, [props.getPreviousImmediateNode, props.uiNodeId ])

  const EXECUTION_STATUS = {
    COMPLETE: 'COMPLETE',
    ERROR: 'ERROR',
    IN_PROGRESS: 'IN_PROGRESS',
    NO_RUN: 'NO_RUN'
  }


  const getRequestParams = (nodeId: string) => {
    const contactId = searchParams.get('contactId');
    const fromDate = searchParams.get('fromDate');
    const toDate = searchParams.get('toDate');
    let dateFilter: any;
    if (fromDate || toDate) {
      dateFilter = {

      }
      if (fromDate) {
        dateFilter['_gte'] = fromDate
      }
      if (toDate) {
        dateFilter['_lte'] = toDate
      }
    }

    if (!accountUUID) {
      return;
    }
    const nodeLogBoolExp: any = {
      tenantId: {
        _eq: accountUUID
      }
    };
    if (dateFilter) {
      nodeLogBoolExp.createdOn = dateFilter
    }

    if (uiNodeId) {
      nodeLogBoolExp.uiNodeId = {
        _eq: nodeId.slice(0, 65)
      }
    }
    if (workflowMasterId && !workflowExecutionId) {
      nodeLogBoolExp.workflowExecutionLog = {
        workflowMasterId: {
          _eq: workflowMasterId
        }
      }
    }
    if (workflowExecutionId) {
      nodeLogBoolExp.workflowExecutionId = {
        _eq: workflowExecutionId
      }
    } else {
      nodeLogBoolExp.workflowExecutionId = {
        _is_null: false
      }
    }
    let resourceLevelLogBoolExp: any = {
      nodeLog: JSON.parse(JSON.stringify(nodeLogBoolExp)),
    }
    if (contactId) {
      resourceLevelLogBoolExp = {
        nodeLog: JSON.parse(JSON.stringify(nodeLogBoolExp)),
        resourceType: {
          _in: groupByEventList
        },
        resourceId: {
          _eq: contactId
        }
      };
      nodeLogBoolExp.resourceLevelLog = resourceLevelLogBoolExp;
    } else {
      resourceLevelLogBoolExp = {
        nodeLog: JSON.parse(JSON.stringify(nodeLogBoolExp)),
        resourceType: {
          _in: groupByEventList
        }
      };
      if (workflowMasterId && !workflowExecutionId) {
        resourceLevelLogBoolExp.workflowExecutionLog = {
          workflowMasterId: {
            _eq: workflowMasterId
          }
        }
      }
      if(workflowExecutionId){
        resourceLevelLogBoolExp.workflowExecutionId = {
          _eq: workflowExecutionId
        }
      }
      nodeLogBoolExp.resourceLevelLog = resourceLevelLogBoolExp;
    }
    if (dateFilter) {
      resourceLevelLogBoolExp.createdOn = dateFilter
    }
    return { nodeLogBoolExp, resourceLevelLogBoolExp };
  }

  const setBackGroundColorByStatus = (status: string) => {
    if (status === EXECUTION_STATUS.NO_RUN) {
      onChangeBackGroundColorChange('gray.100')
    } else if (status === EXECUTION_STATUS.COMPLETE) {
      onChangeBackGroundColorChange(Colors.FoldPixel.STATUS_DARK_SUCCESS)
    } else if (status === EXECUTION_STATUS.ERROR) {
      onChangeBackGroundColorChange(Colors.FoldPixel.STATUS_ERROR)
    } else if (status === EXECUTION_STATUS.IN_PROGRESS) {
      onChangeBackGroundColorChange(Colors.FoldPixel.STATUS_IN_PROGRESS)
    }
  }

  const [uiNodeTotalExecutionCount, setUiNodeTotalExecutionCount] = useState(0)
  const [uiNodeTimeSavedInSeconds, setUiNodeTimeSavedInSeconds] = useState(0);
  const [isCountLoading, setIsCountLoading] = useState(false);
  const [isSavedTimeLoading, setIsSavedTimeLoading] = useState(false);

  useEffect(() => {
    const variables = getRequestParams(infoUiNode)
    const promiseUiNodeId = getNodeLevelExecutionStatus(variables, filterLocationIds, isMsoEnabled, isBusinessOwner)
    const promiseList = [promiseUiNodeId]
    if (parentNodeId && parentNodeId != infoUiNode) {
        const variablesParent = getRequestParams(parentNodeId)
        if(variablesParent?.resourceLevelLogBoolExp?.workflowExecutionLog){
          delete variablesParent?.resourceLevelLogBoolExp?.nodeLog?.workflowExecutionLog
        }
        const promise = getNodeLevelExecutionStatus(variablesParent, filterLocationIds, isMsoEnabled, isBusinessOwner)
        promiseList.push(promise)
    } else {
        promiseList.push(promiseUiNodeId)
    }
    setIsCountLoading(true)
    Promise.all(promiseList).then((responseList: any) => {
      setIsCountLoading(false)
      const uiNodeResult = responseList[0];
      const rootNodeResult = responseList[1];
      const uiNodeResultTotal = uiNodeResult?.aggregateResourceLevelLog?.aggregate?.count || 0;
      const rootNodeResultTotal = rootNodeResult?.aggregateResourceLevelLog?.aggregate?.count || 0;
      if (uiNodeResultTotal == rootNodeResultTotal && rootNodeResultTotal != 0) {
        setBackGroundColorByStatus(EXECUTION_STATUS.COMPLETE)
      } else if(uiNodeResultTotal) {
        setBackGroundColorByStatus(EXECUTION_STATUS.IN_PROGRESS)
      }
      setUiNodeTotalExecutionCount(uiNodeResultTotal)
      if(uiNodeResultTotal && false){
        setIsSavedTimeLoading(true);
        const variables = getRequestParams(infoUiNode)
        getNodeLevelExecutionStatusWithTime(variables).then((uiNodeResult)=>{
          setIsSavedTimeLoading(false)
          const uiNodeTimeSavedInSeconds = uiNodeResult?.timeSaveInSeconds?.aggregate?.sum?.timeSaveInSeconds || 0;
          setUiNodeTimeSavedInSeconds(uiNodeTimeSavedInSeconds);
        })
      }
    })
    
  }, [uiNodeId, parentNodeId, workflowExecutionId, searchParams.get('contactId'), searchParams.get('fromDate'), searchParams.get('toDate'), searchParams.get('auditFilterLocationIds')])


  return <VStack backgroundColor={backGroundColor} flex={1} borderLeftRadius={12}>
    {/* <HStack flex={1} margin={0} justifyContent={'center'} alignItems={'center'} height={'100%'} > */}
      <View flex={1} justifyContent={'center'} alignItems={'center'} height={'100%'} paddingX={3} paddingY={2} gap={2} borderLeftRadius={12}>
        {
          groupByEventList?.length > 0 &&
          groupByEventList.map((groupByEvent) => {
            return (
              <ShowNodeLevelPeopleLogInfo
                groupByEvent={groupByEvent}
                uiNodeTimeSavedInSeconds={uiNodeTimeSavedInSeconds}
                uiNodeTotalExecutionCount={uiNodeTotalExecutionCount}
                isCountLoading={isCountLoading}
                rootNodeType={props.rootNodeType}
                parentNodeId={parentNodeId}
                uiNodeId={infoUiNode}
              ></ShowNodeLevelPeopleLogInfo>
            );
          })
        }
        {uiNodeTimeSavedInSeconds ? 
        <VStack alignItems={'center'} width={'100%'}>
          <Text fontSize={14} fontWeight={600} style={{ zIndex: 1000 }} color={'white'}>
            {getDisplayTimeSaved(uiNodeTimeSavedInSeconds)}
          </Text>
          <Text fontSize={12} fontWeight={400} style={{ zIndex: 1000 }} color={'white'}>
            {'saved'}
          </Text>
        </VStack> : isSavedTimeLoading ? <Spinner justifyContent='center' alignItems={'center'} position={'absolute'} bottom={2} /> : <></>}
      </View>

    {/* </HStack> */}
  </VStack>
}

export default NodeLevelExecutionLogFirst;
