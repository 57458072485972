import React from 'react';
import ReactDOM from 'react-dom';
import {ReactComponent} from '@foldhealth/formio-react';
import {Formio} from '@foldhealth/formiojs';
import CustomWrapper from '../CustomWrapper/CustomWrapper';
import KeyField from '../../EditFormFields/KeyField';
import ConditionalComponents from '../../EditFormFields/ConditionalFields';
import CustomClassComponent from '../CustomWrapper/CustomClassComponent';
import {CapabilityResource} from '../CustomWrapper/CustomComponentHelper';
import AddOrUpdateGoals from './AddOrUpdateGoals/AddOrUpdateGoals';
import {HashRouter} from 'react-router-dom';

export default class Goals extends CustomClassComponent {
  static get builderInfo() {
    return {
      title: 'Goals',
      icon: 'filetext1',
      group: 'advanced',
      documentation: '',
      schema: Goals.schema(),
    };
  }

  static schema() {
    return ReactComponent.schema({
      type: 'goals',
      label: 'Goals',
      key: 'goals',
    });
  }

  attachReact(element: any) {
    return ReactDOM.render(
      <CustomWrapper
        formOptionData={this.options}
        capabilityList={[CapabilityResource.observation]}
      >
        <HashRouter>
          <AddOrUpdateGoals
            options={this.options}
            disabled={this.disabled}
            component={this.component}
            validateRef={this.validateRef}
            onChange={this.updateValue}
          />
        </HashRouter>
      </CustomWrapper>,
      element
    );
  }

  static editForm = function () {
    return {
      key: 'display',
      components: [
        {
          type: 'oldtextfield',
          key: 'label',
          label: 'Label',
          input: true,
          validate: {
            required: true,
          },
        },
        {
          type: 'checkbox',
          key: 'allowToShare',
          label: 'Share with patient',
          input: true,
          defaultValue: true,
        },
        // ...ConditionalComponents,
        ...KeyField,
      ],
    };
  };
}

Formio.Components.addComponent('goals', Goals);
