import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { ICommonSvgProps } from "../interfaces";
import { Colors } from "../../../../styles";

const RuleTemplateSvg = (props: ICommonSvgProps) => {
  const size = props.size || 16;
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <Path
        d="M9.87499 3.00614C9.03125 3.00623 6.54016 2.9647 5.9375 5.81228C5.32831 8.69071 3.04464 11.3514 2 11.9995M2 11.9995C3.04464 12.6476 5.32831 15.3089 5.9375 18.1873C6.54016 21.0349 9.03125 20.9997 9.87499 20.9995M2 11.9995H9.87499M14.375 11.9998H21.125M14.375 3H21.125M14.375 21H21.125"
        stroke={props.customStrokeColor || Colors.Custom.Gray500}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
export default RuleTemplateSvg;