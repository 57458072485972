import { DocumentReferenceContent } from 'fhir/r4';
import {toBase64} from '../../../../../../utils/commonUtils';
import {getDateStrFromFormat, getDateToMomentISOString} from '../../../../../../utils/DateUtils';
import { ICodeableParam, ICondition } from '../../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Conditions/interfaces';
import { REASON } from '../../constants';
import {DocStatus, EHRName, IFormComponent} from '../../interfaces';
import {getCurrentEHR} from '../../PatientNotesHelper';
import { ELATION_CATEGORY, EXTENSION_URLS, FOLD_CATEGORY } from './constant';
import { IFormSelectedValue } from './interfaces';
import { v4 as uuidV4 } from 'uuid';
import { isWeb } from '../../../../../../utils/platformCheckUtils';


export const clipText = (text: string, length: number) => {
  if (text.length > length) {
    return text.slice(0, length) + '...';
  }
  return text;
};

export const getDiagnosisFromHealthGorila = (
  diagnosis: ICodeableParam[],
  formAnswers: any
) => {
  const data: ICondition[] = [];
  diagnosis.forEach((item) => {
    const name = item.text || item.coding?.[0]?.display;
    const currentDate = getDateToMomentISOString();
    if (
      formAnswers?.data?.diagnosis?.conditions
        ?.map((item: ICondition) => item.name)
        .includes(name)
    ) {
      return;
    }
    const uniqueId = uuidV4();
    data.push({
      condition: item,
      name: name,
      onSetDateTime: getDateStrFromFormat(currentDate, 'YYYY-MM-DD'),
      recordedDate: currentDate,
      clinicalStatus: {
        code: 'active',
        display: 'Active',
      },
      isFreeTextRecord: false,
      isAssociatedToOrder: true,
      id: uniqueId,
      uniqueId: uniqueId,
    });
  });
  return data;
};

const getResourceExtension = (
  formName?: string,
  formId?: string,
  prevResourceDate?: string,
  activeActions?: {[index: string]: IFormComponent[]},
  prevSignDate?: string,
  prevSignedByUserId?: string,
  isAmend?: boolean,
  userUUID?: string,
  foldVisitNoteWithEncountersEnabled?: boolean,
  personData?: any,
  formLogId?: string,
  formResponseId?: string,
  isElationTemplate?: boolean,
  associatedTaskId?: string,
) => {
  const extension = [];
  const isFoldDrivenNote =  foldVisitNoteWithEncountersEnabled && !isElationTemplate;
  if (isFoldDrivenNote) {
    extension.push(
      {
        url: EXTENSION_URLS.formName,
        valueString: formName,
      },

      {
        url: EXTENSION_URLS.formId,
        valueString: formId,
      }
    );


    if (personData?.id) {
      extension.push({ url: EXTENSION_URLS.noteContactId, valueString: `${personData?.id}` });
    }
    if (personData?.contactUUID) {
      extension.push({ url: EXTENSION_URLS.noteContactUuid, valueString: personData?.contactUUID });
    }
    if (isAmend) {
      extension.push({ url: EXTENSION_URLS.amendedBy, valueString: userUUID });
      extension.push({ url: EXTENSION_URLS.amendedDate, valueString: getDateToMomentISOString() });
    }

    if (prevSignDate) {
      extension.push({ url: EXTENSION_URLS.signedDate, valueString: prevSignDate });
    }
    if (prevSignedByUserId) {
      extension.push({ url: EXTENSION_URLS.signedBy, valueString: prevSignedByUserId });
    }
    if (activeActions && Object.keys(activeActions).length) {
      const keys = Object.keys(activeActions);
      extension.push({
        url: EXTENSION_URLS.docRefAdditionalSection,
        valueString: keys.join('|'),
      })
    }
  } else if (isElationTemplate) {
    extension.push({
      url: EXTENSION_URLS.elationCreatedDate,
      valueString: prevResourceDate || getDateToMomentISOString(),
    });
  }

  if (formLogId) {
    extension.push({
      url: EXTENSION_URLS.formLogId,
      valueString: formLogId
    });
  }

  if (formResponseId) {
    extension.push({
      url: EXTENSION_URLS.formResponseId,
      valueString: formResponseId
    });
  }

  if (associatedTaskId) {
    extension.push({
      url: EXTENSION_URLS.taskId,
      valueString: associatedTaskId,
    });
  }

  return extension;
};

const getCatergoryData = (currentEHR: string, formId?: string) => {
  const category = [];
  if (currentEHR === EHRName.FOLD) {
    category.push(FOLD_CATEGORY.clinicalNote);
  } else if (currentEHR === EHRName.ELATION) {
    category.push({
      ...ELATION_CATEGORY.visitNoteType,
      display: formId,
    });
  }
  return category;
};

const getFormValue = (key: string, value: any, currentEHR?: EHRName) => {
  if(!isWeb()){
    return value;
  }
  if (currentEHR === EHRName.ELATION && key === REASON) {
    return value?.chiefComplaint?.displayName;
  }
  return value;
};

export const getPostDataForNotes = (
  formData: IFormSelectedValue[],
  personData: any,
  extraData?: {
    associatedTaskId?: string,
    currentEHR: EHRName;
    appointmentId: string;
    userUUID: string;
    isSign: boolean;
    isAmend?: boolean;
    formId?: string;
    formName?: string;
    id?: string;
    prevAuthorId?: string;
    prevResourceDate?: string;
    prevAppointmentId?: string;
    orders: DocumentReferenceContent[];
    noteType?: string;
    activeActions?: {[index: string]: IFormComponent[]};
    prevSignedByUserId?: string;
    prevSignDate?: string;
    foldVisitNoteWithEncountersEnabled?: boolean,
    noteFormResponse?: any;
    noteHTML?: string;
    noteTitle?:string;
    patientShareHtml?: string;
    allowWriteBack?: boolean;
    allowToShowToPatient?: boolean;
    formLogId?: string;
    formResponseId?: string;
    isShareWithEHR?: boolean;
    isElationTemplate?: boolean;
  }
) => {
  const isFoldDrivenNote =  extraData?.foldVisitNoteWithEncountersEnabled && !extraData.isElationTemplate
  let content: DocumentReferenceContent[] = [];
  // GENERATE FORM DATA
  formData.map((item) => {
    let value = `${item.selectedValue}`;
    if (typeof item.selectedValue === 'object' && item.selectedValue !== null) {
      value = `${JSON.stringify(item.selectedValue)}`
    }
    const data = isFoldDrivenNote ? toBase64(value) : getFormValue(item.key, item.selectedValue, extraData?.currentEHR);
    content.push({
      ...(isFoldDrivenNote && {
        format: FOLD_CATEGORY.mimeTypeSufficient,
      }),
      attachment: {
        data: data,
        title: isFoldDrivenNote ? `${item.type}` : item.key,
        contentType: isFoldDrivenNote ? 'text/plain; charset=utf-8' : 'text/plain',
        ...(extraData?.isElationTemplate && {hash: 'md5 hash of base64 section content'}),
        ...(isFoldDrivenNote && {
          extension: [
            {
              url: EXTENSION_URLS.formKey,
              valueString: `${item.key}`,
            },
          ],
        }),
      },
    });
  });
  // ADD ORDERS
  if (extraData?.orders.length) {
    content = [...content, ...extraData.orders];
  }
  // GENERATE MAIN POST DATA
  const postData:any = {
    ...(extraData?.id && {id: extraData.id}),
    content,
    docStatus: extraData?.isAmend ? DocStatus.AMENDED :  (extraData?.isSign ? DocStatus.FINAL : DocStatus.PRELIMINARY),
    author: [
      {
        reference:
          'Practitioner/' + (extraData?.prevAuthorId || extraData?.userUUID),
      },
    ],
    extension:
      getResourceExtension(
        extraData?.formName,
        extraData?.formId,
        extraData?.prevResourceDate,
        extraData?.activeActions,
        extraData?.prevSignDate,
        extraData?.prevSignedByUserId,
        extraData?.isAmend,
        extraData?.userUUID,
        extraData?.foldVisitNoteWithEncountersEnabled || false,
        personData,
        extraData?.formLogId,
        extraData?.formResponseId,
        extraData?.isElationTemplate,
        extraData?.associatedTaskId,
      ),
    ...(isFoldDrivenNote && {
      date: extraData?.prevResourceDate || getDateToMomentISOString(),
      status: 'current',
      type: {
        coding: [FOLD_CATEGORY.evaluationPlanNote],
      },
    }),
    subject: {
      reference: `Patient/${personData.patientId || personData.patientUuid}`,
    },
    category: [
      {
        coding: isFoldDrivenNote
          ? [FOLD_CATEGORY.clinicalNote]
          : extraData?.isElationTemplate
          ? [
              {
                system: 'Elation',
                code: extraData?.noteType,
              },
            ]
          : [],
      },
    ],
    ...(extraData?.isElationTemplate && {
      type: {
        coding: [
          {
            system: 'Elation',
            code: extraData?.formId,
          },
        ],
      },
    }),
    resourceType: 'DocumentReference',
  };
  if (extraData?.isSign) {
    postData?.extension?.push(
      {
        url:EXTENSION_URLS.signedDate,
        valueString: getDateToMomentISOString(),
      },
      {
        url: EXTENSION_URLS.signedBy,
        valueString: extraData?.userUUID,
      },
      { url: EXTENSION_URLS.noteContactId, valueString: `${personData?.id}` },
      { url: EXTENSION_URLS.noteContactUuid, valueString: personData?.contactUUID },
    );

  }
  if(extraData?.isSign || extraData?.isAmend){

    if (extraData?.allowToShowToPatient) {
      postData?.extension?.push({
        url: EXTENSION_URLS.showToPatient,
        valueBoolean: true,
      });
    }

    if (extraData?.noteFormResponse) {
      postData?.extension?.push({
        url: EXTENSION_URLS.formPayload,
        valueString: JSON.stringify({ components: extraData?.noteFormResponse})
      });
    }
    if (extraData?.allowWriteBack) {
      postData.extension.push({
        url: EXTENSION_URLS.allowDocumentReferenceToWriteBack,
        valueBoolean: true,
      });
    }
    if(extraData?.noteHTML){
      postData.extension?.push({
        url: EXTENSION_URLS.formHTML,
        valueString: extraData?.noteHTML,
      });
    }
    if (extraData?.patientShareHtml) {
      postData.extension?.push({
        url: EXTENSION_URLS.patientShareHtml,
        valueString: extraData?.patientShareHtml,
      });
    }
    postData?.extension?.push({
      url: EXTENSION_URLS.syncWithEhr,
      valueBoolean: extraData?.isShareWithEHR,
    });
  }
  if(extraData?.noteTitle){
    postData["description"] = extraData.noteTitle;
  }
  return extraData?.appointmentId !== ''
    ? {
        ...postData,
        context: {
          related: [
            {
              reference: `Appointment/${extraData?.appointmentId}`,
            },
          ],
        },
      }
    : postData;
};
export const getNoteData = (
  formdata: any[],
  personData: any,
  extraData?: {
    isLinked: boolean;
    appointmentId: string;
    userUUID: string;
    isSign: boolean;
    formId?: string;
    formName?: string;
    id?: string;
    prevAuthorId?: string;
    prevAppointmentId?: string;
  }
) => {
  const content: {
    attachment: {
      data: string;
      title: string;
      contentType: string;
      extension: any[];
    };
    format: {
      code: string;
      system: string;
      display: string;
    };
  }[] = [];
  formdata.map((item) => {
    content.push({
      format: {
        code: FOLD_CATEGORY.mimeTypeSufficient.code,
        system: FOLD_CATEGORY.mimeTypeSufficient.system,
        display: FOLD_CATEGORY.mimeTypeSufficient.display,
      },
      attachment: {
        data: toBase64(
          typeof item.selectedValue === 'object' && item.selectedValue !== null
            ? `${JSON.stringify(item.selectedValue)}`
            : `${item.selectedValue}`
        ),
        title: `${item.type}`,
        contentType: 'text/plain; charset=utf-8',
        extension: [
          {
            url: EXTENSION_URLS.formKey,
            valueString: `${item.key}`,
          },
        ],
      },
    });
  });
  const postData = {
    ...(extraData?.id && {id: extraData.id}),
    content,
    docStatus: extraData?.isSign ? DocStatus.FINAL : DocStatus.PRELIMINARY,
    author: [
      {
        reference:
          'Practitioner/' + (extraData?.prevAuthorId || extraData?.userUUID),
      },
    ],
    extension: [
      {
        url: EXTENSION_URLS.formName,
        valueString: extraData?.formName,
      },

      {
        url: EXTENSION_URLS.formKey,
        valueString: extraData?.formId,
      },
    ],
    date: getDateToMomentISOString(),
    status: 'current',
    type: {
      coding: [
        FOLD_CATEGORY.evaluationPlanNote,
      ],
    },
    subject: {
      reference: `Patient/${personData.patientId || personData.patientUuid}`,
    },
    category: [
      {
        coding: [
          FOLD_CATEGORY.clinicalNote,
        ],
      },
    ],
    resourceType: 'DocumentReference',
  };
  if (extraData?.isSign) {
    postData.extension.push(
      {
        url: EXTENSION_URLS.signedDate,
        valueString: getDateToMomentISOString(),
      },
      {
        url: EXTENSION_URLS.signedBy,
        valueString: extraData?.userUUID,
      }
    );
  }
  return extraData?.isLinked
    ? {
        ...postData,
        context: {
          related: [
            {
              reference: `Appointment/${extraData?.appointmentId}`,
            },
          ],
        },
      }
    : postData;
};

/**
 * Returns the value of `isShareWithEHR` if it is not null or undefined, otherwise returns true by default.
 *
 * @param {any} isShareWithEHR - The value to check if it will be shared with EHR.
 * @return {boolean} The value of `isShareWithEHR` if it is not null or undefined, otherwise true.
 */
export const getIsShareWithEHRFlag = (isShareWithEHR: any) => {
  if (isShareWithEHR === null || isShareWithEHR === undefined) {
    return true;
  }
  return isShareWithEHR;
}

export const checkIsFoldDrivenNotes = (note: any) => {
    const isFoldNote =
      note?.category?.[0]?.coding?.[0]?.code === 'clinical-note';
    return isFoldNote;
}

/**
 * Returns whether a note is from elation or not.
 *
 * @param {any} note The note object.
 * @return {boolean} true/false.
 */
export const checkIsElationNote = (note: any) => {
  const noteCategoryCode = note?.category?.[0]?.coding?.[0].code;
  const isElationNote = noteCategoryCode === 'visit_notes' || noteCategoryCode === 'non_visit_notes';
  return isElationNote;
}
