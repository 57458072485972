import {
  Box,
  Button,
  CheckIcon,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Pressable,
  Spacer,
  Spinner,
  Text,
  View,
  VStack,
} from 'native-base';
import React, {useRef, useState} from 'react';
import {Colors} from '../../../styles';
import {getVitalConfigFromCapability} from '../../../utils/capabilityUtils';
import {
  convertVitalValues,
  feetAndInchesToCm,
  feetAndInchesToInches,
  getBMIValue,
  isInchesUnit,
} from '../../../utils/vitalUnitConversions';
import {Vital, VitalInputType, VitalUnit} from '../../../utils/VitalUtils';
import { DATE_FORMATS } from '../../../constants';
import CustomDatePicker from '../../common/CustomComponents/CustomDatePicker/CustomDatePicker';
import { ModalActionSelect } from '../../common/ModalActionCommonComponent/ModalActionSelect';
import { isWeb } from '../../../utils/platformCheckUtils';
import { useContainerDimensions } from '../../CustomHooks/ContainerDimensionHook';
import { testID } from '../../../testUtils';

export interface IVitalCaptureData {
  vital: {
    value?: string | number;
    minValue?: number;
    maxValue?: number;
  };
  vital1?: {
    value?: string | number;
    minValue?: number;
    maxValue?: number;
  };
  unit?: string;
  vitalType: VitalType;
  loading?: boolean;
  loinc: string;
  onComplete?: (data: IVitalCaptureData) => void;
  isTimelineView?: boolean;
  isKanbanView?: boolean;
  locationId?: string;
}

export interface IVitalValue {
  value?: string;
  value1?: string;
}

export enum VitalType {
  normal,
  bloodPressure,
  heightWithFeetAndInches,
}

const VitalCaptureInput = (props: IVitalCaptureData) => {
  const {locationId} = props;
  const [vitalData, setVitalData] = useState<IVitalValue>({
    value: props.vital.value?.toString() || '',
    value1: props.vital1?.value?.toString() || '',
  });
  const [errors, setErrors] = useState({
    vital: '',
    vital1: '',
  });
  const [showErrors, setShowErrors] = useState(false);
  const [BMIData, setBMIData] = useState({
    height: {feet: '', inches: ''},
    weight: '',
  });
  const weightConfig = getVitalConfigFromCapability(Vital.weight, locationId);
  const currentVitalConfig = getVitalConfigFromCapability(props.loinc, locationId);
  const defaultMinValue = 0;
  const defaultMaxValue = 200;
  const {isTimelineView} = props;

  const componentRef = useRef();
  const { width } = useContainerDimensions(componentRef);
  const isSmallScreenWidth = isWeb() && width < 250;
  const inputFieldWidth = width < 210 ? 10 : width < 250 ? 12 : 16;

  const onSubmit = () => {
    setShowErrors(true);
    const updatedErrors = getUpdateErrors(vitalData);
    setErrors(updatedErrors);
    if (isValidData(updatedErrors) && props.onComplete) {
      const finalData = {
        vital: {
          value: getVitalValueForSave(),
          minValue: props.vital.minValue || defaultMinValue,
          maxValue: props.vital.maxValue || defaultMaxValue,
        },
        vitalType: props.vitalType,
        unit: props.unit,
        loinc: props.loinc,
        inputType: currentVitalConfig?.inputType,
      };
      props.onComplete(finalData);
    }
  };

  const calculateBMIValue = (feet: string, inches: string, weight: string) => {
    let bmi;
    if (feet && inches && weight) {
      const heightInInches = feetAndInchesToInches(
        parseFloat(feet),
        parseFloat(inches)
      );
      const bmiValue = getBMIValue(
        {value: `${heightInInches}`, unit: VitalUnit.inches},
        {value: weight, unit: weightConfig?.displayUnit || weightConfig?.unit}
      );
      if (bmiValue) {
        bmi = bmiValue.toFixed(currentVitalConfig?.allowedDecimalPlaces || 0);
      }
    }
    const data = {...vitalData, value: bmi};
    setVitalData(data);
    setErrors(getUpdateErrors(data));
  };

  const getVitalValueForSave = () => {
    if (props.vitalType === VitalType.bloodPressure) {
      const systolic = parseFloat(vitalData.value || '');
      const diastolic = parseFloat(vitalData.value1 || '');
      return `${systolic}/${diastolic}`;
    } else if (props.vitalType === VitalType.heightWithFeetAndInches) {
      const feet = parseFloat(vitalData.value || '');
      const inches = parseFloat(vitalData.value1 || '');
      if (isInchesUnit(props.unit)) {
        const result = feetAndInchesToInches(feet, inches);
        return `${result}`;
      } else {
        const result = feetAndInchesToCm(feet, inches);
        return `${result}`;
      }
    } else if (vitalData.value && currentVitalConfig?.unit && currentVitalConfig?.displayUnit && currentVitalConfig?.displayUnit !== currentVitalConfig?.unit) {
      const result = convertVitalValues(vitalData.value, currentVitalConfig.displayUnit, currentVitalConfig.unit, currentVitalConfig?.allowedDecimalPlaces || 0);
      return result;
    }
    if (currentVitalConfig?.inputType !== VitalInputType.choice && currentVitalConfig?.inputType !== VitalInputType.date) {
      return `${parseFloat(vitalData.value || '')}`;
    }
    return vitalData.value || '';
  };

  const isValidData = (errors: any) => {
    return !errors.vital && !errors.vital1;
  };

  const getUpdateErrors = (data: IVitalValue) => {
    return {
      vital: validateVital(
        data.value,
        props.vital.minValue,
        props.vital.maxValue,
        currentVitalConfig?.inputType
      ),
      vital1:
        props.vitalType === VitalType.bloodPressure ||
        props.vitalType === VitalType.heightWithFeetAndInches
          ? validateVital(
              data.value1,
              props.vital1?.minValue,
              props.vital1?.maxValue,
              currentVitalConfig?.inputType
            )
          : '',
    };
  };

  const validateVital = (
    value?: string,
    minValue?: number,
    maxValue?: number,
    inputType?: VitalInputType,
  ) => {
    let vitalError = !value ? 'Value is required' : '';
    if (inputType === VitalInputType.date || inputType === VitalInputType.choice) {
      return vitalError;
    }

    const validValue = (value || '').match(/[^0-9\.]/g);
    if (validValue && validValue.length !== 0) {
      vitalError = 'Please enter a valid value';
    }

    if (!vitalError) {
      const min = minValue || defaultMinValue;
      const max = maxValue || defaultMaxValue;
      const valueDecimal = parseFloat(value || '');
      vitalError =
        value && (valueDecimal < min || valueDecimal > max)
          ? `Please enter value between ${min} and ${max}`
          : vitalError;
    }
    return vitalError;
  };

  // const getNumberFromText = (text: string) => {
  //   const number = text.replace(/[^0-9\.]/g, '');
  //   return number ? parseFloat(number) : undefined;
  // };

  return (
    <VStack ref={componentRef}>
      {!props.isKanbanView && (
        <HStack space={2} justifyContent={'space-between'} alignItems={'center'}>
          <Text color={Colors.Custom.alertsDescriptionColor}>Vital Value</Text>
          <View marginTop={isTimelineView && isSmallScreenWidth ? 4: 0} maxWidth={isTimelineView && isSmallScreenWidth? 120: undefined} alignSelf={'flex-start'} >
            <Button
              fontSize={12}
              borderWidth={1}
              isLoading={props.loading || false}
              style={{
                backgroundColor:Colors.Custom.PrimaryColor100,
                borderRadius:4,
                borderColor:Colors.Custom.PrimaryColor200
              }}
              _spinner={!isTimelineView ? { color:  Colors.Custom.mainPrimaryPurple}: {}}
              size={isTimelineView ? 'xs' : 'md'}
              px={isTimelineView ? 3 : 3}
              py={isTimelineView ? 2 : 2}
              onPress={() => {
                onSubmit();
              }}
            >
              {!props.loading && (
              <Text
                fontSize={12}
                fontWeight={isTimelineView ? 700 : 500}
                color={Colors.Custom.PurpleColor}
              >
              {isTimelineView ? 'Mark as Done' : 'Done'}
              </Text>
              )}
            </Button>
          </View>
        </HStack>
      )}
      {props.loinc === Vital.bmi && (
        <VStack space={2} marginTop={2}>
          <HStack space={2} justifyContent={'flex-start'} alignItems={'center'}>
            <Text marginRight={2} color={Colors.FoldPixel.GRAY400} flex={0.2}>Height:</Text>
            <HStack flex={0.8} alignItems={'center'}>
              <Pressable
                onPress={(event) => {
                  event.stopPropagation();
                }}
                accessible={false}
              >
                <Input
                _focus={{borderColor: '#D0D5DD'}}
                  width={inputFieldWidth}
                  keyboardType="decimal-pad"
                  value={BMIData.height.feet?.toString() || ''}
                  isDisabled={props.loading || false}
                  onChangeText={(text) => {
                    setBMIData((prev) => ({
                      ...prev,
                      height: {...prev.height, feet: text},
                    }));
                    calculateBMIValue(
                      text || '',
                      BMIData.height.inches,
                      BMIData.weight
                    );
                  }}
                  {...testID('HeightFtInput')}
                />
              </Pressable>
              <Text color={Colors.FoldPixel.GRAY400} marginLeft={1} marginRight={1}>ft</Text>
              <View>
                <Pressable
                  onPress={(event) => {
                    event.stopPropagation();
                  }}
                  accessible={false}
                >
                  <Input
                  _focus={{borderColor: '#D0D5DD'}}
                    width={inputFieldWidth}
                    keyboardType="decimal-pad"
                    value={BMIData.height.inches?.toString() || ''}
                    isDisabled={props.loading || false}
                    onChangeText={(text) => {
                      setBMIData((prev) => ({
                        ...prev,
                        height: {...prev.height, inches: text},
                      }));
                      calculateBMIValue(
                        BMIData.height.feet,
                        text || '',
                        BMIData.weight
                      );
                    }}
                    {...testID('HeightInchesInput')}
                  />
                </Pressable>
              </View>
              <Text color={Colors.FoldPixel.GRAY400} marginLeft={1} marginRight={1}>in</Text>
            </HStack>
          </HStack>

          <HStack space={2} justifyContent={'flex-start'} alignItems={'center'}>
            <Text marginRight={2} color={Colors.FoldPixel.GRAY400} flex={0.2}>Weight:</Text>
            <HStack flex={.8} alignItems={'center'}>
              <Pressable
                onPress={(event) => {
                  event.stopPropagation();
                }}
                accessible={false}
              >
                <Input
                _focus={{borderColor: '#D0D5DD'}}
                  width={20}
                  keyboardType="decimal-pad"
                  value={BMIData.weight?.toString() || ''}
                  isDisabled={props.loading || false}
                  onChangeText={(text) => {
                    setBMIData((prev) => ({...prev, weight: text}));
                    calculateBMIValue(
                      BMIData.height.feet,
                      BMIData.height.inches,
                      text || ''
                    );
                  }}
                  {...testID('WeightInput')}
                />
              </Pressable>
              {(!!weightConfig?.displayUnit || !!weightConfig?.unit) && (
                <Text color={Colors.FoldPixel.GRAY400} marginX={1} {...testID('WeightUnit')}>
                  {weightConfig?.displayUnit || weightConfig?.unit}
                </Text>
              )}
            </HStack>
          </HStack>
        </VStack>
      )}
      <HStack space={2} marginTop={2} alignItems={'center'} justifyContent={'flex-start'} flexDirection={isTimelineView && isSmallScreenWidth ? 'column' : 'row'}>
        {!props.isKanbanView && (
          <Text marginRight={2} color={Colors.FoldPixel.GRAY400} flex={.2}>{currentVitalConfig?.foldDisplay}:</Text>
        )}
        <HStack flex={.8}>
          {props.vitalType === VitalType.normal && (
            <HStack alignItems={'center'}>
              <Pressable
                  onPress={(event) => {
                    event.stopPropagation();
                  }}
                  accessible={false}
                >
                {currentVitalConfig?.inputType !== VitalInputType.choice && currentVitalConfig?.inputType !== VitalInputType.date && (
                  <Input
                  _focus={{borderColor: '#D0D5DD'}}
                    width={20}
                    keyboardType="decimal-pad"
                    value={vitalData.value?.toString() || ''}
                    isDisabled={props.loading || false}
                    onChangeText={(text) => {
                      const data = {...vitalData, value: text};
                      setVitalData(data);
                      setErrors(getUpdateErrors(data));
                    }}
                    {...testID('VitalInput')}
                  />
                )}
                {currentVitalConfig?.inputType === VitalInputType.choice && (
                  <ModalActionSelect
                    selectedValue={vitalData.value?.toString() || ''}
                    placeholder="Select"
                    endIcon={<CheckIcon size="5" />}
                    onValueChange={(value: string) => {
                      const data = {...vitalData, value: value};
                      setVitalData(data);
                      setErrors(getUpdateErrors(data));
                    }}
                    data={currentVitalConfig?.possibleValues || []}
                    selectItemProps={{
                      key: 'code',
                      label: 'display',
                      value: 'code',
                    }}
                    customStyle={isWeb() ? { flex: 1 } : { width: 176 }}
                  />
                )}
                {currentVitalConfig?.inputType === VitalInputType.date && (
                  <View style={isWeb() ? { flex: 1 } : { width: '100%' }}>
                    <CustomDatePicker
                      value={vitalData.value?.toString() || ''}
                      isRequired
                      format={currentVitalConfig?.format || DATE_FORMATS.DISPLAY_DATE_FORMAT}
                      placeholder={currentVitalConfig?.format || DATE_FORMATS.DISPLAY_DATE_FORMAT}
                      maxDate={new Date()}
                      customStyle={{width: 48}}
                      onChange={(date) => {
                        const data = {...vitalData, value: date};
                        setVitalData(data);
                        setErrors(getUpdateErrors(data));
                      }}
                    />
                  </View>
                )}
              </Pressable>
              {(!!currentVitalConfig?.displayUnit || !!currentVitalConfig?.unit) && (
                <Text color={Colors.FoldPixel.GRAY400} marginX={1} {...testID('VitalUnit')}>
                  {currentVitalConfig?.displayUnit || currentVitalConfig?.unit}
                </Text>
              )}
            </HStack>
          )}
          {props.vitalType === VitalType.bloodPressure && (
            <HStack alignItems="center">
              <Pressable
                onPress={(event) => {
                  event.stopPropagation();
                }}
              >
                <Input
                _focus={{borderColor: '#D0D5DD'}}
                  width={inputFieldWidth}
                  keyboardType="decimal-pad"
                  value={vitalData.value?.toString() || ''}
                  isDisabled={props.loading || false}
                  onChangeText={(text) => {
                    const data = {...vitalData, value: text};
                    setVitalData(data);
                    setErrors(getUpdateErrors(data));
                  }}
                />
              </Pressable>
              <Text color={Colors.FoldPixel.GRAY400} marginX={1}>/</Text>
              <View>
                <Pressable
                  onPress={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <Input
                  _focus={{borderColor: '#D0D5DD'}}
                    width={inputFieldWidth}
                    keyboardType="decimal-pad"
                    value={vitalData.value1?.toString() || ''}
                    isDisabled={props.loading || false}
                    onChangeText={(text) => {
                      const data = {...vitalData, value1: text};
                      setVitalData(data);
                      setErrors(getUpdateErrors(data));
                    }}
                  />
                </Pressable>
              </View>
              {!!props.unit && (
                <Text color={Colors.FoldPixel.GRAY400} marginX={1}>
                  {props.unit}
                </Text>
              )}
            </HStack>
          )}
          {props.vitalType === VitalType.heightWithFeetAndInches && (
            <HStack alignItems="center">
              <Pressable
                onPress={(event) => {
                  event.stopPropagation();
                }}
              >
                <Input
                _focus={{borderColor: '#D0D5DD'}}
                  width={inputFieldWidth}
                  keyboardType="decimal-pad"
                  value={vitalData.value?.toString() || ''}
                  isDisabled={props.loading || false}
                  onChangeText={(text) => {
                    const data = {...vitalData, value: text};
                    setVitalData(data);
                    setErrors(getUpdateErrors(data));
                  }}
                />
              </Pressable>
              <Text color={Colors.FoldPixel.GRAY400} marginX={1}>ft</Text>
              <View>
                <Pressable
                  onPress={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <Input
                  _focus={{borderColor: '#D0D5DD'}}
                    width={inputFieldWidth}
                    keyboardType="decimal-pad"
                    value={vitalData.value1?.toString() || ''}
                    isDisabled={props.loading || false}
                    onChangeText={(text) => {
                      const data = {...vitalData, value1: text};
                      setVitalData(data);
                      setErrors(getUpdateErrors(data));
                    }}
                  />
                </Pressable>
              </View>
              <Text color={Colors.FoldPixel.GRAY400} marginX={1}>in</Text>
            </HStack>
          )}
        </HStack>
        {props.isKanbanView && (
        <View marginTop={isTimelineView && isSmallScreenWidth ? 4: 0} maxWidth={isTimelineView && isSmallScreenWidth? 120: undefined} alignSelf={'flex-start'} >
          <Button
            fontSize={12}
            borderWidth={1}
            isLoading={props.loading || false}
            style={{
              backgroundColor:Colors.Custom.PrimaryColor100,
              borderRadius:4,
              borderColor:Colors.Custom.PrimaryColor200
            }}
            _spinner={!isTimelineView ? { color:  Colors.Custom.mainPrimaryPurple}: {}}
            size={isTimelineView ? 'xs' : 'md'}
            px={isTimelineView ? 3 : 3}
            py={isTimelineView ? 2 : 2}
            onPress={() => {
              onSubmit();
            }}
          >
            {!props.loading && (
            <Text
              fontSize={12}
              fontWeight={isTimelineView ? 700 : 500}
              color={Colors.Custom.PurpleColor}
            >
             {isTimelineView ? 'Mark as Done' : 'Done'}
            </Text>
            )}
          </Button>
        </View>
        )}
      </HStack>
      {showErrors && (!!errors.vital || !!errors.vital1) && (
        <Text color="error.500">{errors.vital || errors.vital1}</Text>
      )}
    </VStack>
  );
};

export default VitalCaptureInput;
