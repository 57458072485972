import {Divider, HStack, Text} from 'native-base';
import {View} from 'react-native';
import {
  getIconBasedOnVital,
  sortSettings,
  updateAllListSequenceInAscendingOrder,
} from './utils';
import {Colors} from '../../../../../../styles';
import {getEHRCapability} from '../../../../../../utils/commonUtils';
import {useContext, useEffect, useState} from 'react';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import {CapabilityResource} from '../CustomWrapper/CustomComponentHelper';
import {
  AllowedWearableDataCategories,
  WEARABLE_DATA_SYNC_CATEGORIES,
} from '../../../../WearableDataIngest/WearableDataConstants';
import Stack from '../../../../../common/LayoutComponents/Stack';
import {Switch} from 'antd';
import {IWearableSetting, IWearableSettingListItem} from './interface';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import DraggableIconSvg from '../../../../../common/Svg/DraggableIconSvg';
import { cloneDeep } from 'lodash';
import { getAccountConfigCapability } from '../../../../../../utils/capabilityUtils';

interface HomeMonitoringSettingsViewProps {
  onListUpdate: (wearableSettings: IWearableSetting) => void;
  existingWearableSetting: IWearableSetting;
}

interface HomeMonitoringSettingsViewState {
  allowedWearableList: IWearableSettingListItem[];
  settings: IWearableSetting;
}

export const HomeMonitoringSettingsView = (
  props: HomeMonitoringSettingsViewProps
) => {
  const {existingWearableSetting} = props;
  const context = useContext(CommonDataContext);
  const ehrCapabilities = context.ehrCapabilities || [];
  const allowedWearableList =
    getAccountConfigCapability(CapabilityResource.wearable)?.abilities
      ?.allowedWearableList || [];

  const [componentState, setComponentState] =
    useState<HomeMonitoringSettingsViewState>({
      allowedWearableList: cloneDeep(allowedWearableList),
      settings: {},
    });

  useEffect(() => {
    const settings: IWearableSetting = {};

    WEARABLE_DATA_SYNC_CATEGORIES.forEach((item) => {
      const existingWearableList = existingWearableSetting[item.key] || [];
      const wearableParamatersList = getWearableParameters(item.key)?.filter(wearable => wearable.enabled);
      const updatedSettingsList = wearableParamatersList?.map((wearable) => {
        const existingWearableListItem = existingWearableList?.find(
          (existingWearableListItem) =>
            existingWearableListItem.code === wearable.code
        );
        if (existingWearableListItem) {
          if (existingWearableListItem.isSelected) {
            wearable.isSelected = true;
          }
          wearable.sequence = existingWearableListItem.sequence;
        }
        return wearable;
      });

      settings[item.key] = sortSettings(updatedSettingsList);
    });

    setComponentState((prev) => ({
      ...prev,
      settings: settings,
    }));
  }, []);

  const onSwitchChanged = (
    setting: IWearableSettingListItem,
    isSelected: boolean,
    key: string
  ) => {
    const updatedSetting = componentState.settings;
    const updatedWearableList = componentState.settings[key].map((wearable) => {
      if (wearable.code === setting.code) {
        wearable.isSelected = isSelected;
      }
      return wearable;
    });
    updatedSetting[key] = updatedWearableList;
    setComponentState((prev) => ({
      ...prev,
      settings: updatedSetting,
    }));
    props.onListUpdate(updatedSetting);
  };

  const renderItem = (setting: IWearableSettingListItem, key: string) => {
    const Icon = getIconBasedOnVital(setting.code);
    return (
      <HStack paddingLeft={1} paddingY={1} paddingRight={2} flex={1}>
        <Stack
          direction="row"
          style={{
            justifyContent: 'space-between',
            flex: 1,
            alignItems: 'center',
            paddingLeft: 8,
            paddingVertical: 8,
            backgroundColor: Colors.Custom.White
          }}
        >
          <Stack direction="row" style={{alignItems: 'center'}}>
            <DraggableIconSvg style={{marginLeft: 8, marginRight: 12}} />
            <Icon
              strokeColor={Colors.FoldPixel.GRAY300}
              width="24"
              height="24"
            />
            <Text
              fontSize={14}
              marginLeft={2}
              color={Colors.Custom.Gray900}
              selectable={false}
            >
              {`${setting.display}`}
            </Text>
          </Stack>
          <Switch
            onChange={(checked) => {
              onSwitchChanged(setting, checked, key);
            }}
            size="small"
            checked={setting.isSelected}
            style={{
              backgroundColor: setting.isSelected
                ? Colors.Custom.mainPrimaryPurple
                : Colors.Custom.Gray200,
            }}
          />
        </Stack>
      </HStack>
    );
  };

  const getWearableParameters = (code: AllowedWearableDataCategories) => {
    return componentState.allowedWearableList.filter(
      (item) => item.category === code
    );
  };

  const renderSeparator = () => (
    <Divider
      style={{
        backgroundColor: Colors.FoldPixel.GRAY150,
        height: 0.5,
      }}
    />
  );

  const onDragEnd = (result: any, key: string) => {
    if (!result.destination) {
      return;
    }

    const updatedList = reorder(
      componentState.settings[key],
      result.source.index,
      result.destination.index
    );

    const updatedSetting = componentState.settings;
    updatedSetting[key] = updatedList;
    setComponentState((prev) => ({
      ...prev,
      settings: updatedSetting,
    }));

    props.onListUpdate(updatedSetting);
  };

  const reorder = (
    list: IWearableSettingListItem[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return updateAllListSequenceInAscendingOrder(result);
  };

  const renderDraggableView = (
    wearableParamatersList: IWearableSettingListItem[],
    key: string
  ) => {
    return (
      <View
        style={{
          borderWidth: 1,
          borderRadius: 12,
          borderColor: Colors.FoldPixel.GRAY150,
          marginTop: 12,
        }}
      >
        <DragDropContext
          onDragEnd={(result) => {
            onDragEnd(result, key);
          }}
        >
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {wearableParamatersList.map((item, index) => (
                  <Draggable
                    key={item.code}
                    draggableId={item.code}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {renderItem(item, key)}
                        {wearableParamatersList.length - 1 !== index ? renderSeparator() : null}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </View>
    );
  };

  return (
    <Stack direction="column" style={{paddingBottom: 12, marginHorizontal: 4}}>
      <Text
        fontSize={16}
        marginLeft={2}
        color={Colors.FoldPixel.GRAY400}
        selectable={false}
        fontWeight={'500'}
      >
        {`Select the widgets you'd like to view first`}
      </Text>
      {Object.keys(componentState.settings).map((key, index) => {
        const wearableParamatersList = componentState.settings[key];
        if (!wearableParamatersList?.length) {
          return null;
        }
        return (
          <Stack direction="column" style={{paddingHorizontal: 8}}>           
            <Text
              color={Colors.Custom.Gray500}
              fontSize={14}
              fontWeight={400}
              marginTop={3}
            >
              {key?.toUpperCase()}
            </Text>
            {renderDraggableView(wearableParamatersList, key)}
          </Stack>
        );
      })}
    </Stack>
  );
};
