import {View, Text, Pressable} from 'react-native';
import React, { useContext, useEffect } from 'react';
import {FormControl, Input, Spacer, Stack} from 'native-base';
import {DisplayText} from '../../../../../../common/DisplayText/DisplayText';
import {ModalActionAntSelect} from '../../../../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import {GoalOperator, IGoalTarget} from './interface';
import {GOAL_ENTITY_TYPES, GOAL_OPERATOR_LIST} from './GoalConstants';
import GoalEntitySearch from '../GoalEntitySearch/GoalEntitySearch';
import {IGoalEntityResult} from '../GoalEntitySearch/interface';
import {Colors} from '../../../../../../../styles';
import CrossIconSvg from '../../../../../../common/Svg/CrossIconSvg';
import VitalInput from '../../Vitals/VitalInput/VitalInput';
import {Vital, VitalInputType} from '../../../../../../../utils/VitalUtils';
import {getVitalListFromCapability} from '../../../../../../../utils/capabilityUtils';
import {VitalType} from '../../../../../../TaskCard/VitalCaptureInput/VitalCaptureInput';
import { IVitalData } from '../../../../../../../Interfaces';
import { CommonDataContext } from '../../../../../../../context/CommonDataContext';
import { IFormCommonData } from '../../CustomWrapper/CustomWrapper';
import { getDefaultGoalValue } from './GoalUtils';
import { useGoalLastRecordedValue } from './useGoalLastRecordedValue';
import { getFormattedDate } from '../../../../../../PersonOmniView/LeftContainer/OtherDetails/OtherDeatilsUtils';
import { DISPLAY_DATE_FORMAT } from '../../../../../../../constants';

interface IGoalTargetViewProps {
  goalTarget: IGoalTarget;
  disabled: boolean;
  showErrors?: boolean;
  metaData: {
    goalEntityTypeIds: {[index: string]: string};
    patientId: string;
    patientContactUuid: string;
    locationId: string;
  };
  onChange: (item: IGoalTarget) => void;
}

const GoalTargetView = (props: IGoalTargetViewProps) => {
  // Constants
  const {goalTarget, disabled, metaData, showErrors, onChange} = props;
  const {goalEntityTypeIds, patientContactUuid, patientId, locationId} = metaData;
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const goalEntityTypes = GOAL_ENTITY_TYPES.map((item) => {
    return {
      id: goalEntityTypeIds[item.code],
      code: item.code,
      value: item.value,
    }
  });
  const currentEntityTypeCode = goalEntityTypes.find(item => item.id === goalTarget.entityTypeId)?.code;
  const measurableEntityTypeIds = [
    goalEntityTypeIds.ACTIVITY,
    goalEntityTypeIds.LAB_ANALYTE,
    goalEntityTypeIds.VITAL,
    goalEntityTypeIds.FORM
  ];

  const {lastRecordedValue, loading, lastRecordedDate} = useGoalLastRecordedValue({
    patientId: patientId,
    locationId: locationId,
    goalEntityTypeIds: goalEntityTypeIds,
    goalTarget: goalTarget,
    patientContactUuid: patientContactUuid,
  });

  // Other methods
  const getVitalList = (): IVitalData[] => {
    const vitals = contextData.capabilities?.abilities?.allowedVitalList || [];
    return vitals.filter(item => !item.isHidden);
  };

  const renderVitalInput = (
    selectedValue?: number | string,
    onChange?: (value?: string | undefined) => void
  ) => {
    if (goalTarget.entityTypeId === goalEntityTypeIds.VITAL) {
      const vitals = getVitalList();
      const vitalConfig = vitals.find((item) => {
        return item.loinc === goalTarget.entityCode;
      });
      const defaultValue = getDefaultGoalValue(selectedValue, goalTarget.entityCode, vitalConfig);
      const referenceRange = vitalConfig?.range || [];
      let vitalType = VitalType.normal;
      if (goalTarget.entityCode === Vital.bloodPressure) {
        vitalType = VitalType.bloodPressure;
      } else if (goalTarget.entityCode === Vital.height) {
        vitalType = VitalType.heightWithFeetAndInches;
      }
      return (
        <VitalInput
          showErrors={showErrors && !(selectedValue || selectedValue === 0)}
          vital={{
            value:
              selectedValue || selectedValue === 0 ? `${selectedValue}` : '',
            min: referenceRange?.[0]?.min || 0,
            max: referenceRange?.[0]?.max || 1000,
          }}
          defaultValue={{
            vital1: defaultValue?.vital1,
            vital2: defaultValue?.vital2,
          }}
          decimalPrecision={vitalConfig?.allowedDecimalPlaces || 0}
          inputType={vitalConfig?.inputType || VitalInputType.numeric}
          label={''}
          unit={vitalConfig?.unit}
          displayUnit={vitalConfig?.displayUnit}
          disabled={disabled}
          type={vitalType}
          possibleValues={vitalConfig?.possibleValues || []}
          format={vitalConfig?.format}
          loinc={vitalConfig?.loinc}
          onChange={(value) => {
            onChange?.(value);
          }}
        />
      );
    } else {
      const defaultValue = { vital1: selectedValue || selectedValue === 0 ? `${selectedValue}` : '' };
      return (
        <VitalInput
          showErrors={showErrors && !(selectedValue || selectedValue === 0)}
          vital={{
            value:
              selectedValue || selectedValue === 0 ? `${selectedValue}` : '',
            min: -10000,
            max: 10000,
          }}
          defaultValue={{
            vital1: defaultValue.vital1,
            vital2: undefined,
          }}
          decimalPrecision={4}
          inputType={VitalInputType.numeric}
          label={''}
          unit={goalTarget.measure?.unit}
          displayUnit={goalTarget.measure?.unit}
          disabled={disabled}
          type={VitalType.normal}
          possibleValues={[]}
          onChange={(value) => {
            onChange?.(value);
          }}
        />
      );
    }
  };

  const getEntityTitle = () => {
    const entityTypeId = goalTarget?.entityTypeId;
    if (measurableEntityTypeIds.includes(entityTypeId || '')) {
      const entityType = goalEntityTypes?.find(
        (type) => type.id === entityTypeId
      );
      return entityType ? entityType.value : '';
    }
    return '';
  };
  const entity = getEntityTitle();

  return (
    <View>
      <Stack
        space={4}
        direction={'row'}
        style={{flex: 1, alignItems: 'center'}}
      >
        <FormControl isInvalid={showErrors && !goalTarget.entityTypeId} isDisabled={false} flex={1}>
          <FormControl.Label isRequired={true}>
            <DisplayText textLocalId="entityType" />
          </FormControl.Label>
          <ModalActionAntSelect
            showSearch={false}
            value={goalTarget.entityTypeId}
            accessibilityLabel="Select Category"
            placeholder="Select Category"
            isDisabled={disabled}
            isInvalid={showErrors && !goalTarget.entityTypeId}
            onChange={(itemValue: any) => {
              goalTarget.entityTypeId = itemValue;
              goalTarget.measure = undefined;
              goalTarget.entityCode = undefined;
              goalTarget.formId = undefined;
              goalTarget.title = '';
              onChange(goalTarget);
            }}
            hideCustomIcon
            data={goalEntityTypes}
            optionProps={{
              key: 'id',
              label: 'value',
              value: 'id',
            }}
            extraStyle={{flex: 1}}
            filterOption={(input: string, option: any) => {
              return (option?.children || '')
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
          />
        </FormControl>
        <FormControl isInvalid={showErrors && ((!goalTarget.entityCode && !goalTarget.formId) || !goalTarget.title)} isDisabled={false} flex={1}>
          {goalTarget.entityTypeId !== goalEntityTypeIds.OTHER && (
            <>
              <FormControl.Label isRequired={true}>
                <DisplayText textLocalId={`Select ${entity}`} />
              </FormControl.Label>
              <GoalEntitySearch
                value={
                  goalTarget.entityCode
                    ? {code: goalTarget.entityCode, display: goalTarget.title}
                    : goalTarget.formId
                    ? {formId: goalTarget.formId, display: goalTarget.title}
                    : undefined
                }
                placeholder="Search Entities"
                isShowError={!!showErrors && ((!goalTarget.entityCode && !goalTarget.formId) || !goalTarget.title)}
                additionalAPIParams={
                  goalTarget.entityTypeId === goalEntityTypeIds.OTHER
                    ? undefined
                    : {categoryCodes: currentEntityTypeCode ? [currentEntityTypeCode] : []}
                }
                onChange={(data: IGoalEntityResult) => {
                  if (data) {
                    goalTarget.entityCode = data.code || '';
                    goalTarget.title = data.display || '';
                    if (data.formId) {
                      goalTarget.formId = data.formId || '';
                    }
                  } else {
                    goalTarget.entityCode = undefined;
                    goalTarget.title = '';
                    goalTarget.formId = undefined;
                  }

                  goalTarget.measure = undefined;
                  goalTarget.unit = data?.unit;
                  onChange(goalTarget);
                }}
              />
            </>
          )}
        </FormControl>
      </Stack>
      {lastRecordedValue && (
        <Text
          style={{color: Colors.FoldPixel.GRAY300, marginTop: 8, fontSize: 14}}
        >{`Last Recorded: ${lastRecordedValue} ${
          lastRecordedDate
            ? `(${getFormattedDate(lastRecordedDate, DISPLAY_DATE_FORMAT)})`
            : ''
        }`}</Text>
      )}
      {measurableEntityTypeIds.includes(goalTarget.entityTypeId || '') &&
        !goalTarget?.measure?.targetValue && (
          <View style={{marginTop: 8}}>
            <Pressable
              onPress={() => {
                goalTarget.measure = {
                  targetType: 'measure',
                  targetValue: {},
                  operator: GoalOperator.eq,
                  unit: goalTarget.unit,
                };
                onChange(goalTarget);
              }}
            >
              <Text style={{color: Colors.Custom.Primary300, fontSize: 15, marginTop: 2}}>
                {'Set Target'}
              </Text>
            </Pressable>
          </View>
        )}
      {measurableEntityTypeIds.includes(goalTarget.entityTypeId || '') &&
        !!goalTarget.measure?.targetValue && (
          <Stack direction="row" space={3} style={{alignItems: 'center', marginTop: 8}}>
            <View style={{minWidth: 40, marginBottom: 2}}>
              <Text>{goalTarget.entityTypeId === goalEntityTypeIds.FORM ? 'Score' : goalTarget.title}</Text>
            </View>
            <Stack direction="row" space={0} style={{alignItems: 'center', flex: 1}}>
              <Stack
                direction={
                  [Vital.height, Vital.bloodPressure].includes(goalTarget.entityCode as Vital) &&
                  goalTarget.measure?.operator === GoalOperator.between ?
                  'column' : 'row'
                }
                style={[Vital.height, Vital.bloodPressure].includes(goalTarget.entityCode as Vital) &&
                  goalTarget.measure?.operator === GoalOperator.between ? {} : { alignItems: 'center' }}
                space={2}
              >
                <View>
                  <ModalActionAntSelect
                    value={goalTarget.measure?.operator}
                    accessibilityLabel="Select Operator"
                    placeholder="Operator"
                    onChange={(itemValue: any) => {
                      if (goalTarget.measure) {
                        goalTarget.measure.operator = itemValue;
                      }
                      onChange(goalTarget);
                    }}
                    hideCustomIcon
                    size="middle"
                    data={GOAL_OPERATOR_LIST}
                    isInvalid={showErrors && !goalTarget.measure?.operator}
                    optionProps={{
                      key: 'code',
                      label: 'value',
                      value: 'code',
                    }}
                    marginTop={'5px'}
                    customStyle={{flex: 1, width: 102, height: 40, marginBottom: 0}}
                    filterOption={(input: string, option: any) => {
                      return (option?.children || '')
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  />
                </View>
                <View>
                  {goalTarget?.measure?.operator !== GoalOperator.between &&
                    renderVitalInput(
                      goalTarget?.measure?.targetValue?.value,
                      (value) => {
                        if (goalTarget.measure) {
                          goalTarget.measure.targetValue = {
                            low: undefined,
                            high: undefined,
                            value: value,
                          };
                        }
                        onChange(goalTarget);
                      }
                    )}
                  {goalTarget.measure?.operator === GoalOperator.between && (
                    <Stack
                      direction="row"
                      space={4}
                      style={{alignItems: 'center'}}
                    >
                      {renderVitalInput(
                        goalTarget?.measure?.targetValue?.low,
                        (value) => {
                          if (goalTarget.measure?.targetValue) {
                            goalTarget.measure.targetValue.low = value;
                            goalTarget.measure.targetValue.value = undefined;
                          }
                          onChange(goalTarget);
                        }
                      )}
                      <Text style={{fontSize: 12, color: Colors.Custom.Gray400}}>
                        {'-'}
                      </Text>
                      {renderVitalInput(
                        goalTarget?.measure?.targetValue?.high,
                        (value) => {
                          if (goalTarget.measure?.targetValue) {
                            goalTarget.measure.targetValue.high = value;
                            goalTarget.measure.targetValue.value = undefined;
                          }
                          onChange(goalTarget);
                        }
                      )}
                    </Stack>
                  )}
                </View>
              </Stack>
              <View>
                <Pressable
                  onPress={() => {
                    if (goalTarget.measure) {
                      goalTarget.measure.targetValue = undefined;
                    }
                    onChange(goalTarget);
                  }}
                >
                  <CrossIconSvg size={16} />
                </Pressable>
              </View>
            </Stack>
          </Stack>
        )}
    </View>
  );
};

export default GoalTargetView;
