import { Drawer } from 'antd';
import { VStack } from 'native-base';
import { useEffect, useState } from 'react';
import { Dimensions } from 'react-native';
import { useSearchParams } from 'react-router-dom';
import { BUTTON_TYPE } from '../../../../../constants';
import { Colors } from '../../../../../styles';
import { findIsAllowToRunAutomation, getAccountUUID } from '../../../../../utils/commonUtils';
import { ModalActionTitle } from '../../../../common/ModalActionTitle/ModalActionTitle';
import AddOrUpdateCampaign from '../../../ContentManagement/CampaignManagement/AddOrUpdateCampaign/AddOrUpdateCampaign';
import { AutomationWorkflowEmployee } from '../../../MembersManagement/AutomationWorkflowEmployee';
import { createCampaignSegment } from '../../Workflow/AddOrUpdateWorkflow/WorkflowApi';
import WorkflowPersonLevelEmailInfoByExecutionIdV1 from '../../Workflow/WorkflowTableView/Helper/WorkflowPersonLevelEmailInfoByExecutionIdV1';
import { IShowServiceLogType } from './interface';
import { ShowWorkflowExecutionCommunication } from '../../Workflow/WorkflowTableView/Helper/WorkflowStatusTable';

function ShowNodeLevelLogInfo(props: {
  logTypeList: string[];
  uiNodeId: string;
  total: number;
  isVisible: boolean;
  setVisible: any;
  locationIds?: string[]
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [contactId, setContactId] = useState<string | undefined>(
    searchParams.get('contactId') || undefined
  );
  const workflowExecutionId =
    searchParams.get('workflowExecutionId') || undefined;
  const [contactIdList, setContactIdList] = useState<any>([]);
  useEffect(() => {
    setContactId(searchParams.get('contactId') || undefined);
  }, [searchParams.get('contactId')]);

  const tenantId = getAccountUUID();
  const [selectedList, setSelectedList] = useState<any>([]);
  const [campaignDrawerState, setCampaignDrawerState] = useState<any>({
    isVisible: false,
    segmentId: null,
  });
  const [isExecutionCommunicationLoading, setIsExecutionCommunicationLoading] = useState(false);
  const singleButtonList = [
    {
      show: true,
      id: 1,
      btnText: 'close',
      textColor: Colors.Custom.mainSecondaryBrown,
      variant: BUTTON_TYPE.SECONDARY,
      isTransBtn: false,
      onClick: () => {
        props.setVisible(false);
      },
    },
  ];
  const [openModal, setOpenModal] = useState('default');
  const getMultiSelectButtonList = (current: string) => {
    const multiSelectButtonList = [
      {
        show: true,
        id: 1,
        btnText: 'cancel',
        textColor: Colors.Custom.mainSecondaryBrown,
        btnStype: BUTTON_TYPE.SECONDARY,
        variant: BUTTON_TYPE.SECONDARY,
        isTransBtn: false,
        onClick: () => {
          props.setVisible(false);
        },
      },
      {
        show: findIsAllowToRunAutomation(),
        id: 2,
        btnText: 'runAutomation',
        textColor: Colors.Custom.mainPrimaryPurple,
        btnStype: BUTTON_TYPE.PRIMARY,
        variant: BUTTON_TYPE.PRIMARY,
        isTransBtn: false,
        onClick: () => {
          setOpenModal((prev) => {
            return 'automationWorkflow';
          });
        },
      },
      {
        show: true,
        id: 3,
        btnText: 'Create Campaign',
        textColor: Colors.Custom.mainPrimaryPurple,
        btnStype: BUTTON_TYPE.PRIMARY,
        variant: BUTTON_TYPE.PRIMARY,
        isTransBtn: false,
        onClick: () => {
          setContactIdList((prev: any) => {
            if (!prev.length) {
              return;
            }
            createCampaignSegment({
              name: '',
              tenantId: tenantId,
              metadata: {
                patientFilter: {
                  contactIds: prev.map((contact: any) => {
                    return contact.id;
                  }),
                },
              },
            }).then((resp) => {
              if (resp?.id) {
                setCampaignDrawerState({
                  isVisible: true,
                  segmentId: resp?.id,
                });
              }
            });
            return prev;
          });
        },
      },
    ];
    return multiSelectButtonList;
  };
  const [buttonList, setButtonList] = useState(singleButtonList);

  const onSelectListChange = (selectedList: any, type: string) => {
    const currentModel = type || openModal;
    setSelectedList(selectedList);
    setContactList(selectedList);
    setButtonList(
      selectedList.length > 0 || currentModel != 'default'
        ? getMultiSelectButtonList(type || openModal)
        : singleButtonList
    );
  };

  useEffect(() => {
    onSelectListChange(selectedList, openModal);
  }, [openModal]);


  const [showServiceLogs, setShowServiceLogs] = useState<IShowServiceLogType>({isShow: false, workflowExecutionId: ''});
  const onSingleIconClick = (workflowExecutionId: string, itemId?: string) => {
    setShowServiceLogs((prev: IShowServiceLogType) => {
      const idObj: IShowServiceLogType = {
        workflowExecutionId: workflowExecutionId,
        isShow: true,
        itemId: itemId
      }

      return idObj;
    });
  }

  const onWorkflowExecutionCommunicationClose = () => {
    setShowServiceLogs((prev: IShowServiceLogType)=>{
      const idObj: IShowServiceLogType = {...prev};
      idObj.isShow = false;
      return idObj;
    });
  }

  const setContactList = (selectedList: any) => {
    const contactIdList: number[] = [];
    (selectedList || []).forEach((item: any) => {
      (item?.resourceLevelLog || []).forEach((data: any) => {
        const contactId = parseInt(data?.resourceId);
        if (contactIdList.indexOf(contactId) == -1) {
          contactIdList.push(contactId);
        }
      });
    });
    const contactList = contactIdList.map((contactId) => {
      return {id: contactId};
    });
    setContactIdList(contactList);
  };

  return (
    <>
      {showServiceLogs?.isShow && showServiceLogs?.workflowExecutionId ? (
        <ShowWorkflowExecutionCommunication
          isShow={showServiceLogs.isShow}
          workflowExecutionId={showServiceLogs.workflowExecutionId}
          onClose={onWorkflowExecutionCommunicationClose}
          isLoading={isExecutionCommunicationLoading}
          setIsLoading={setIsExecutionCommunicationLoading}
          itemId={showServiceLogs?.itemId}
        />
      ) : (
        <></>
      )}
      <Drawer
        visible={campaignDrawerState?.isVisible}
        width={Dimensions.get('window').width * 0.7}
        onClose={() => {
          setCampaignDrawerState({
            isVisible: false,
            segmentId: null,
          });
        }}
        headerStyle={{
          display: 'none',
        }}
      >
        {campaignDrawerState?.isVisible ? (
          <AddOrUpdateCampaign
            shouldShowEdit={true}
            initData={undefined}
            segmentId={campaignDrawerState?.segmentId}
            close={() => {
              setCampaignDrawerState({
                isVisible: false,
                segmentId: null,
              });
            }}
          />
        ) : null}
      </Drawer>
      <Drawer
        visible={props.isVisible && openModal == 'default'}
        width={700}
        onClose={() => props.setVisible(false)}
        closable={true}
        title={
          <ModalActionTitle
            title={'List'}
            titleColor={''}
            buttonList={buttonList}
          />
        }
      >
        {
          <VStack paddingY={20} paddingTop={0}>
            <WorkflowPersonLevelEmailInfoByExecutionIdV1
              isShowCheckbox={true}
              setSelectedList={onSelectListChange}
              logTypeList={props.logTypeList}
              key={
                props.total +
                (contactId || '') +
                props.uiNodeId +
                (workflowExecutionId || '')
              }
              contactId={contactId}
              workflowExecutionId={workflowExecutionId || undefined}
              uiNodeId={props.uiNodeId}
              total={props.total}
              setShowServiceLogs={onSingleIconClick}
              locationIds={props?.locationIds}
            ></WorkflowPersonLevelEmailInfoByExecutionIdV1>
          </VStack>
        }
      </Drawer>
      <Drawer
        visible={props.isVisible && openModal == 'automationWorkflow'}
        width={700}
        onClose={() => props.setVisible(false)}
        closable={true}
        title={<></>}
      >
        <AutomationWorkflowEmployee
          cancelBtnTxt={'Back'}
          initData={{selectedItems: contactIdList}}
          onModalClose={(shouldTriggerModal?: boolean) => {
            setOpenModal('default');
          }}
        />
      </Drawer>
    </>
  );
}

export default ShowNodeLevelLogInfo;
