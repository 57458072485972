import React, { useEffect, useRef } from 'react';
import Viewer, { EventMap } from '@toast-ui/editor/dist/toastui-editor-viewer';
import {EventNames, ViewerProps} from '@toast-ui/react-editor';


const CustomViewer: React.FC<ViewerProps> = (props) => {
  const rootEl = useRef<HTMLDivElement | null>(null);
  const viewerInst = useRef<Viewer | null>(null);

  const getBindingEventNames = () => {
    return Object.keys(props)
      .filter((key) => /^on[A-Z][a-zA-Z]+/.test(key))
      .filter((key) => props[key as EventNames]);
  };

  const bindEventHandlers = (props: ViewerProps) => {
    getBindingEventNames().forEach((key) => {
      const eventName = key[2].toLowerCase() + key.slice(3);

      viewerInst.current?.off(eventName);
      viewerInst.current?.on(eventName, props[key as EventNames]!);
    });
  };

  const getInitEvents = () => {
    return getBindingEventNames().reduce(
      (acc: Record<string, EventMap[keyof EventMap]>, key) => {
        const eventName = (key[2].toLowerCase() + key.slice(3)) as keyof EventMap;

        acc[eventName] = props[key as EventNames];

        return acc;
      },
      {}
    );
  };

  useEffect(() => {
    if (rootEl.current) {
      if (viewerInst?.current) {
        viewerInst.current?.destroy();
      }
      viewerInst.current = new Viewer({
        el: rootEl.current,
        ...props,
        events: getInitEvents(),
      });
    }

    // Cleanup function to unbind events and destroy the viewer instance
    return () => {
      if (viewerInst.current) {
        getBindingEventNames().forEach((key) => {
          const eventName = key[2].toLowerCase() + key.slice(3);
          viewerInst.current?.off(eventName);
        });

        // Destroy the viewer instance to prevent memory leaks
        viewerInst.current.destroy();
        viewerInst.current = null;
      }
    };
  }, []); // Empty dependency array ensures this runs once on mount and unmount

  useEffect(() => {
    const instance = viewerInst.current;

    if (instance) {
      // Rebind event handlers with updated props
      bindEventHandlers(props);
    }
  }, [props]); // This effect will run whenever props change

  return <div ref={rootEl} />;
};

export default CustomViewer;
