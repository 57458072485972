import {useLazyQuery, useMutation} from '@apollo/client';
import {AutoComplete, Drawer, Select, Tooltip} from 'antd';
import {
  Center,
  FormControl,
  HStack,
  Icon,
  Spinner,
  Text,
  TextArea,
  useMediaQuery,
  useToast,
  View,
  VStack,
} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {useNavigate} from 'react-router-dom';
import {CLOUD_TELEPHONY_APOLLO_CONTEXT} from '../../../../../../constants/Configs';
import {MLOV_CATEGORY} from '../../../../../../constants/MlovConst';
import {
  BUTTON_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
  PARENT_CODE,
  PATIENT_CONSENT_ERROR,
  RESPONSE_CATEGORY_CODE,
  TCPA_INFO,
} from '../../../../../../constants/StringConst';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import {ILoginUserData} from '../../../../../../Interfaces/CommonInterfaces';
import {CloudTelephonyQueries} from '../../../../../../services';
import {getDefaultPhoneNumberByLocation} from '../../../../../../services/CloudTelephony/CloudTelephonyQueries';
import {GET_CONTACT_BY_ID} from '../../../../../../services/Contacts/ContactsQueries';
import ConversationsQueries from '../../../../../../services/Conversations/ConversationsQueries';
import EmployeeQueries from '../../../../../../services/Employee/EmployeeQueries';
import InboxQueries from '../../../../../../services/Inbox/InboxQueries';
import {
  IContactInbox,
  IContactInboxesResp,
  IInbox,
} from '../../../../../../services/Inbox/interfaces';
import LeadQueries from '../../../../../../services/Lead/LeadQueries';
import {Colors} from '../../../../../../styles/Colors';
import {
  canCreateSMSInboxWithPhoneNumber,
  getAccountId,
  getAccountUUID,
  getContactUuid,
  getFormattedToNumber,
  getPhoneNumberAndCountryCode,
  getUserId,
  isContactConsentRequired,
  isLoggedInUserGlobalAdmin,
  isLoggedInUserWorkFlowOrCustomerSuccess,
  isLoginUserBusinessOwner,
} from '../../../../../../utils/commonUtils';
import {
  showPhiWarningToast,
  showToast,
  ToastType,
} from '../../../../../../utils/commonViewUtils';
import {getContactTypeId, getMlovCodeIdObj, getMlovId, getMlovListFromCategory} from '../../../../../../utils/mlovUtils';
import {IContact, IDefaultNumberForLocation} from '../../../../../common/CreateChannelView/interface';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../../../../common/ModalActionBtn/ModalActionBtn';
import {ModalActionTitle} from '../../../../../common/ModalActionTitle/ModalActionTitle';
import {NoDataFound} from '../../../../../common/NoDataFound';
import PatientSearchAndSelect from '../../../../../common/PatientSearch/PatientSearchAndSelect';
import PhoneRingSvg from '../../../../../common/Svg/PhoneRingSvg';
import {FoldButton} from '../../../../../CommonComponents/FoldButton/FoldButton';
import {capitalizeText} from '../../../../ContentManagement/CampaignManagement/Helper';
import {SMS_INBOX_ACTION_CODES} from '../../../Integrations/IntegrationCreate/SmsInboxCreate/SmsInboxConst';
import {CHANNEL_TYPE} from '../../ConversationConst';
import {INewConversationResponse} from '../../interfaces';
import {getUpdatedCreateSMSConversationErrorMessages} from '../ChannelTabsUtils';
import ContinueWithExistingConversationPopup from './ContinueWithExistingConversationPopup';
import ContinueWithExistingSmsConversationPopup from './ContinueWithExistingSmsConversation';
import {styles} from './CreateSmsConversationDrawerStyle';
import {
  ICreateSmsConversationDrawerProps,
  ICreateSmsConversationDrawerState,
} from './interface';
import {
  createSmsInboxConversation,
  sendMessageAPI,
} from './SmsConversationServices';
import ActionMessageView from '../../../../../common/ActionMessageView/ActionMessageView';
import {ACTION_MESSAGE_CODE} from '../../../../../common/ActionMessageView/ActionMessageViewConst';
import {BlockNumberActionMessage} from '../../MessagingWindow/MsgConst';
import {addOrUpdateInboxMembersAPI, createInboxMembersAPI} from '../../../Integrations/IntegrationCreate/SmsInboxCreate/SmsService';
import {CreateAndDialNewNumber} from '../../../../CloudTelephony/ActiveNumbersTable/SelectPatientCallDrawer';
import {EventBus} from '../../../../../../utils/EventBus';
import {SUPPORTED_EVENT_CODE} from '../../../../../../constants/WebSocketConst';
import {isAccountConfigEnabled} from '../../../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../../../constants/AccountConfigConst';
import { getBlockNumberActionMessage, getSearchStringForCannedResponseForInput } from '../../MessagingWindow/MessagingUtils';
import CannedResponses from '../../MessagingWindow/CannedResponses/CannedResponses';
import { ICannedResponses } from '../../MessagingWindow/CannedResponses/interface';
import { KEY_PRESS_CODES } from '../ConversationChannelTabConst';
import { IVirtualPhoneNumber, IVirtualPhoneNumberResp } from '../../../../CloudTelephony/interfaces';
import { isChannelExistWithPhoneNumber } from '../../../Integrations/IntegrationCreate/SmsInboxCreate/SmsInboxUtils';
import { getLastTenDigit } from '../../../../../common/PhoneCalling/utils';
const Option = Select;
const {Option: AutoCompleteOption} = AutoComplete;
const CreateSmsConversationDrawer = (
  props: ICreateSmsConversationDrawerProps
) => {
  const isDisableConversationOnPhoneNumberMissMatch = isAccountConfigEnabled(CONFIG_CODES.DISABLE_CONVERSATION_ON_PHONE_NUMBER_MISS_MATCH);
  const navigate = useNavigate();
  const smsConversationInboxToast = useToast();
  const intl = useIntl();
  const accountId = getAccountId();
  const userId = getUserId();
  const commonData = useContext(CommonDataContext);
  const isSideCarContext = commonData.sidecarContext?.isSidecar;
  const userData = commonData.userData || ({} as ILoginUserData);
  const isConsentRequired = isContactConsentRequired();

  const mlovData = useContext(CommonDataContext);
  // const contactTypeUuid = getContactTypeId('CUSTOMER');
  const contactTypeUuid = getContactTypeId('CUSTOMER');
  const {width} = Dimensions.get('window');
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);
  const accountUUID = getAccountUUID();
  const isCurrentUserBusinessOwner = isLoginUserBusinessOwner() || isLoggedInUserGlobalAdmin() || isLoggedInUserWorkFlowOrCustomerSuccess()
  const isSmallScreen = isIPadMiniScreen || isIPadScreen;
  const finalWidth = isSideCarContext ? '100%' : isSmallScreen ? width / 1.5 : width / 3;
  const cloudTelMlovData = commonData?.CLOUD_TELEPHONY_MLOV;
  const CommunicationType = cloudTelMlovData?.CommunicationType || [];
  const consentProviderId = getContactUuid();
  const callTypeId = CommunicationType?.find(
    (item: any) => item.code === 'SMS'
  );
  const mlovDataObj = mlovData.MLOV;
  const groupMemberTypeList = mlovDataObj[MLOV_CATEGORY.GroupMemberType];
  const groupMemberCodeIdObj = getMlovCodeIdObj(groupMemberTypeList);
  const {
    selectedPatient,
    isDrawerVisible,
    selectedInbox,
    onCreateSmsConversationActionPerformed,
  } = props;
  const enableChatHistoryRead = isAccountConfigEnabled(CONFIG_CODES.ENABLE_CHAT_HISTORY_READ)
  const [getEmployeeConsent] = useLazyQuery(
    EmployeeQueries.getEmployeeConsent,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [getPatientList] = useLazyQuery(
    LeadQueries.GetContactsForSMSInboxCreate,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [getInboxesForContact] = useLazyQuery<IContactInboxesResp>(
    InboxQueries.GetInboxesForContact,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [getInboxesForNumber] = useLazyQuery<IContactInboxesResp>(
    InboxQueries.GetInboxesForNumber,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [createInboxMembers] = useMutation(InboxQueries.createInboxMembers);
  const [getInboxesDataByPhoneNumber] = useLazyQuery<{inboxes: IInbox[]}>(
    InboxQueries.GetInboxesData,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [getContactById] = useLazyQuery(GET_CONTACT_BY_ID);

  const getExistingContactData = async (args: {id: number}) => {
    const {id} = args;
    const response = await getContactById({
      variables: {
        id: id,
      },
    });
    return response?.data?.contact;
  };

  const [GET_DEFAULT_CALL_NUMBER] = useLazyQuery(
    CloudTelephonyQueries.GET_DEFAULT_CALL_NUMBER,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );
  const [getAccountLocationId] = useLazyQuery(
    CloudTelephonyQueries.getAccountLocationIdByPracticeLocationId,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );
  const locationUuid = selectedPatient?.contactPracticeLocations?.[0]?.practiceLocationUuid;
  const getTypeIdByCode = (code: string) => {
    const typeObj = CommunicationType.filter((communication) => {
      return communication?.code === code;
    });
    return typeObj[0].id;
  };
  const [getConversationByConversationUuid] =
    useLazyQuery<INewConversationResponse>(
      ConversationsQueries.GetConversationByConversationUuid,
      {
        fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      }
    );
  const [checkNumberIsBlocked] = useLazyQuery(InboxQueries.CheckBlockNumber, {
    fetchPolicy: 'no-cache',
  });
  const consentType = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.CONSENT
  );
  const ConsentProviderType = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.CONSENT_PROVIDER_TYPE
  );
  const [CreateConsent] = useMutation(EmployeeQueries.createConsent);

  const registerConsent = async (contactUuid: string | any) => {
    const consentObjArray = getConsentObjArray(contactUuid);
    const responseData = await CreateConsent({
      variables: {consent: consentObjArray},
    });
  };

  const getConsentProviderTypeUser = () => {
    return ConsentProviderType.filter((providerType) => {
      return providerType?.code === 'USER';
    });
  };

  const getConsentObjArray = (contactUuid: string) => {
    const consentProviderTypeUser = getConsentProviderTypeUser();
    const consentObjArray: any = [];
    consentType.forEach((consent) => {
      const consentObj = {
        accountUuid: accountUUID,
        consentId: consent?.id,
        consentProviderId: consentProviderId,
        consentProviderTypeId: consentProviderTypeUser[0]?.id,
        contactUuid: contactUuid,
      };
      consentObjArray.push(consentObj);
    });
    return consentObjArray;
  };

  const [
    createSmsConversationDrawerState,
    setCreateSmsConversationDrawerState,
  ] = useState<ICreateSmsConversationDrawerState>({
    selectedInbox: props.selectedInbox
      ? {id: props.selectedInboxId}
      : selectedInbox?.id
        ? selectedInbox
        : ({} as any),
    isPatientDataLoading: false,
    isLoading: true,
    selectedPatient: selectedPatient || ({} as IContact),
    patientLists: [] as IContact[],
    messageStr: props?.content,
    errors: {} as any,
    isConfirmModalOpen: false,
    existingConversation: {} as any,
    inboxList: [] as IInbox[],
    isConsentGiven: true as boolean,
    showConversationExist: false,
    isBlockNumber: false,
    newNumber: '',
  });

  useEffect(() => {
    setCreateSmsConversationDrawerState((prev) => {
      return {
        ...prev,
        messageStr: props.content,
      };
    });
  }, [props.content]);

  useEffect(() => {
    checkInboxNumberIsBlocked();
    if (createSmsConversationDrawerState?.selectedPatient?.uuid) {
      checkForConsent(createSmsConversationDrawerState?.selectedPatient);
    }
  }, [
    createSmsConversationDrawerState?.selectedInbox,
    createSmsConversationDrawerState?.selectedPatient,
  ]);

  const isDisplayCannedResponses = () => {
    return (
      createSmsConversationDrawerState.messageStr &&
      createSmsConversationDrawerState.messageStr.indexOf('/') === 0
    );
  };

  const checkInboxNumberIsBlocked = async () => {
    if (
      createSmsConversationDrawerState?.selectedPatient?.phoneNumber &&
      createSmsConversationDrawerState?.selectedInbox?.channelTwilioSms
        ?.phoneNumber
    ) {
      setCreateSmsConversationDrawerState((prev) => {
        return {
          ...prev,
          isLoading: true,
        };
      });
      const numberBlockRes = await checkNumberIsBlocked({
        variables: {
          toNumber: getFormattedToNumber(
            createSmsConversationDrawerState.selectedPatient.phoneNumber.toString()
          ),
          fromNumber: getFormattedToNumber(
            createSmsConversationDrawerState.selectedInbox.channelTwilioSms.phoneNumber.toString()
          ),
        },
      });
      if (numberBlockRes?.data?.blockNumbers?.length) {
        if (numberBlockRes.data.blockNumbers[0]?.id) {
          setCreateSmsConversationDrawerState((prev) => {
            return {
              ...prev,
              isBlockNumber: true,
              isLoading: false,
            };
          });
        }
      } else {
        setCreateSmsConversationDrawerState((prev) => {
          return {
            ...prev,
            isBlockNumber: false,
            isLoading: false,
          };
        });
      }
    }
  };

  const [getInboxesWithChannelTypeFilter] = useLazyQuery(
    InboxQueries.GetInboxesWithChannelTypeFilter,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      notifyOnNetworkStatusChange: true,
      variables: {
        whereCondition: {
          name: {
            _ilike: '%%',
          },
          accountId: {
            _eq: accountId,
          },
          isDeleted: {
            _eq: false,
          },
          channelType: {
            _eq: CHANNEL_TYPE.CHANNEL_TWILIO_SMS,
          },
          inboxMembers: {
            userId: {
              _eq: userId,
            },
            isDeleted: {
              _eq: false,
            },
          },
        },
      },
    }
  );

  const [GET_VIRTUAL_PHONE_NUMBER] = useLazyQuery<IVirtualPhoneNumberResp>(
    CloudTelephonyQueries.GET_VIRTUAL_PHONE_NUMBER_BY_ACCOUNT_UUID,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const getInboxesData = async () => {
    const promiseList = [getInboxesWithChannelTypeFilter({
      variables: {
        whereCondition: {
          name: {
            _ilike: '%%',
          },
          accountId: {
            _eq: accountId,
          },
          isDeleted: {
            _eq: false,
          },
          channelType: {
            _eq: CHANNEL_TYPE.CHANNEL_TWILIO_SMS,
          },
          inboxMembers: {
            userId: {
              _eq: userId,
            },
            isDeleted: {
              _eq: false,
            },
          },
        },
      },
      }), GET_VIRTUAL_PHONE_NUMBER({
        context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
        variables: {
          accountUuid: accountUUID
        },
      })
    ]
    Promise.all(promiseList)
    .then(response => {
      const inboxResp: IInbox[] = response[0]?.data?.inboxes;
      const virtualNumberResp: IVirtualPhoneNumber[] = response[1]?.data?.virtualPhoneNumberAssignees || [];
      if (inboxResp?.length && virtualNumberResp?.length) {
        const finalInboxData : IInbox[] = [];
        inboxResp.forEach((inboxItem) => {
          if (
            isChannelExistWithPhoneNumber(
              virtualNumberResp,
              inboxItem
            )
          ) {
            finalInboxData.push(inboxItem);
          }
        });
        setCreateSmsConversationDrawerState((prev : any) => {
          return {
            ...prev,
            selectedInbox: props.selectedInboxId
              ? {id: props.selectedInboxId}
              : selectedInbox?.id
                ? selectedInbox
                : finalInboxData?.[0] || {},
            isPatientDataLoading: false,
            isLoading: false,
            selectedPatient: selectedPatient || ({} as IContact),
            patientLists: [] as IContact[],
            errors: {} as any,
            isConfirmModalOpen: false,
            existingConversation: {} as any,
          };
        });
        const filteredInboxes = (finalInboxData as IInbox[]).filter(
          (item) =>
            canCreateSMSInboxWithPhoneNumber(item?.channelTwilioSms?.phoneNumber)
        );
        if (!selectedInbox?.id) {
          getDefaultSmsNumber(filteredInboxes);
        } else{
          setCreateSmsConversationDrawerState((prev) =>{
            return {
              ...prev,
              inboxList: filteredInboxes,
            }
          })
        }
      } else {
        setCreateSmsConversationDrawerState((prev) => {
          return {
            ...prev,
            isPatientDataLoading: false,
            isLoading: false,
            selectedPatient: selectedPatient || ({} as IContact),
            patientLists: [] as IContact[],
            errors: {} as any,
            isConfirmModalOpen: false,
            existingConversation: {} as any,
            inboxList: [],
          };
        });
      }
    }).catch((error) => {
      setCreateSmsConversationDrawerState((prev) => {
        return {
          ...prev,
          selectedInbox: props.selectedInboxId
            ? {id: props.selectedInboxId}
            : selectedInbox?.id
              ? selectedInbox
              : ({} as any),
          isPatientDataLoading: false,
          isLoading: false,
          selectedPatient: selectedPatient || ({} as IContact),
          patientLists: [] as IContact[],
          errors: {} as any,
          isConfirmModalOpen: false,
          existingConversation: {} as any,
          inboxList: [],
        };
      });
    });
  };

  const getDefaultSmsNumber = async (inboxData: IInbox[]) => {
    setCreateSmsConversationDrawerState((prev: any) => {
      return {
        ...prev,
        isLoading: true
      };
    });
    const communicationTypeId = getTypeIdByCode('SMS');
    let accountLocationResp: any;
    let defaultForLocation: any;
    if (locationUuid) {
      accountLocationResp = await getAccountLocationId({
        variables: {
          accountUuid: accountUUID,
          locationUuid: locationUuid
        }
      })
      const locationId = accountLocationResp?.data?.accountLocations?.[0]?.uuid
      defaultForLocation = await getDefaultPhoneNumberByLocation({
        accountUuid: accountUUID,
        locationId: locationId,
        communicationTypeId: communicationTypeId,
      });
    }
    const response = await GET_DEFAULT_CALL_NUMBER({
      context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
      variables: {
        accountUuid: accountUUID,
        defaultCommunicationTypeId: callTypeId?.id || communicationTypeId,
        tenantId: accountUUID
      },
    });
    setCreateSmsConversationDrawerState((prev: any) => {
      return {
        ...prev,
        isLoading: false
      };
    });
    const practiceLocationDefaultNumber = defaultForLocation?.data ? defaultForLocation?.data : [];
    if (inboxData.length) {
      if (
        response?.data?.numberDefaultCommunications?.length ||
        practiceLocationDefaultNumber?.length
      ) {
        const selectedInboxIndex = inboxData.findIndex((inbox) => {
          return practiceLocationDefaultNumber?.length
            ? inbox?.channelTwilioSms?.phoneNumber ===
            practiceLocationDefaultNumber[0]?.VirtualPhoneNumberAssignees
              ?.virtualPhoneNumber
            : inbox?.channelTwilioSms?.phoneNumber ===
            response?.data?.numberDefaultCommunications[0]
              ?.virtualNumberAssignee?.virtualPhoneNumber;
        });
        let immutableInbox = [] as IInbox[];
        if (selectedInboxIndex != -1) {
          const selectedInbox = selectedInboxIndex != -1
            ? inboxData[selectedInboxIndex]
            : ({} as IInbox);
          immutableInbox = [...inboxData];
          immutableInbox.splice(selectedInboxIndex, 1);
          immutableInbox.splice(0, 0, selectedInbox);
        }

        setCreateSmsConversationDrawerState((prev: any) => {
          return {
            ...prev,
            inboxList: immutableInbox.length
              ? [...immutableInbox]
              : [...inboxData],
            selectedInbox: immutableInbox.length
              ? immutableInbox[0]
              : inboxData[0],
          };
        });
      } else {
        setCreateSmsConversationDrawerState((prev: any) => {
          return {
            ...prev,
            inboxList: [...inboxData],
            selectedInbox: inboxData[0],
          };
        });
      }
    } else {

      if (
        (response?.data?.numberDefaultCommunications?.length &&
          response?.data?.numberDefaultCommunications[0]?.virtualNumberAssignee
            ?.virtualPhoneNumber) ||
        practiceLocationDefaultNumber?.length ||
        practiceLocationDefaultNumber[0]?.VirtualPhoneNumberAssignees
          ?.virtualPhoneNumber
      ) {
        const finalDefaultNumber =
          practiceLocationDefaultNumber[0]?.VirtualPhoneNumberAssignees
            ?.virtualPhoneNumber ||
          response?.data?.numberDefaultCommunications[0]?.virtualNumberAssignee
            ?.virtualPhoneNumber;
        const defaultInboxData = await getInboxesDataByPhoneNumber({
          variables: {
            whereCondition: {
              channelType: {
                _eq: CHANNEL_TYPE.CHANNEL_TWILIO_SMS,
              },
              channelTwilioSms: {
                phoneNumber: {
                  _eq: finalDefaultNumber,
                },
              },
            },
          },
        });
        if (defaultInboxData?.data?.inboxes?.length) {
          const singleInbox = defaultInboxData?.data?.inboxes[0];
          if (enableChatHistoryRead) {
            await createInboxMembersAPI({
              memberList: [
                {
                  userId: Number(userId),
                  inboxId: singleInbox.id
                }
              ], inboxId: singleInbox.id
            });
          } else {
            // await createInboxMembers({
            //   variables: {
            //     objects: [
            //       {
            //         userId: userId,
            //         inboxId: singleInbox.id,
            //       },
            //     ],
            //   },
            // });
            const userObjectList = [{
              userId: Number(userId),
              inboxId: singleInbox.id,
            }]
            const addedMemberData = await addOrUpdateInboxMembersAPI({memberList: userObjectList, inboxId: singleInbox.id});
          }
        }
      }
      setCreateSmsConversationDrawerState((prev: any) => {
        return {
          ...prev,
          selectedInbox: {} as any,
          inboxList: [],
        };
      });
    }
  };

  useEffect(() => {
    if (isDrawerVisible) {
      getInboxesData();
    }
  }, [isDrawerVisible]);

  const onInboxChange = (inboxId: number) => {
    const curSelectedInbox =
      createSmsConversationDrawerState?.inboxList.find((inboxData) => {
        return inboxData.id === inboxId;
      }) || ({} as any);

    setCreateSmsConversationDrawerState((prev) => {
      return {
        ...prev,
        selectedInbox: curSelectedInbox,
      };
    });
  };
  const setEmptyPatientList = () => {
    setCreateSmsConversationDrawerState((prev) => {
      return {
        ...prev,
        patientLists: [],
        isPatientDataLoading: false,
      };
    });
  };
  const getPatientLists = async (searchPatient: string): Promise<any[]> => {
    if (searchPatient.length) {
      setCreateSmsConversationDrawerState((prev) => {
        return {
          ...prev,
          patientLists: [],
          selectedPatient: {} as IContact,
          loadingPatientData: true,
          errors: {
            ...prev.errors,
            patient: '',
          },
        };
      });
      const responseData = await getPatientList({
        variables: {
          accountId: accountId,
          typeId: contactTypeUuid,
          isDeleted: false,
          searchString: `%${searchPatient}%`,
        },
      });
      if (responseData?.data?.contacts?.length) {
        setCreateSmsConversationDrawerState((prev) => {
          return {
            ...prev,
            patientLists: responseData?.data?.contacts || [],
            isPatientDataLoading: false,
          };
        });
      } else {
        setEmptyPatientList();
      }
    } else {
      setEmptyPatientList();
    }

    return [];
  };

  const getPatientListOptElem = () => {
    return createSmsConversationDrawerState?.patientLists?.map((patient) => {
      return (
        <AutoCompleteOption
          key={patient.id}
          patientData={patient}
          value={patient.name}
        >
          <Text>{patient.name}</Text>
          <Text>{patient?.phoneNumber}</Text>
        </AutoCompleteOption>
      );
    });
  };
  const checkForConsent = async (contactData: any) => {
    if (!isConsentRequired) {
      setCreateSmsConversationDrawerState((prev) => ({
        ...prev,
        isConsentGiven: true,
        selectedPatient: contactData,
        isLoading: false,
      }));
      return;
    }
    const checkPatientConsent = await getEmployeeConsent({
      variables: {
        contactUuid: contactData?.uuid,
      },
    });
    if (checkPatientConsent?.data?.contactConsents.length === 0) {
      setCreateSmsConversationDrawerState((prev) => ({
        ...prev,
        isConsentGiven: false,
        selectedPatient: contactData,
        isLoading: false,
      }));
    } else {
      setCreateSmsConversationDrawerState((prev) => {
        return {
          ...prev,
          isConsentGiven: true,
          selectedPatient: contactData,
          isLoading: false,
        };
      });
    }
  };
  const getSmsConversationBodyElem = () => {
    if (createSmsConversationDrawerState?.inboxList?.length) {
      return (
        <VStack>
          <View mb={2}>
            {showPhiWarningToast(
              intl.formatMessage({
                id: 'phiWarning',
              })
            )}
          </View>
          <HStack>
            <FormControl>
              <FormControl.Label>
                <DisplayText size={'mdNormal'} textLocalId={'fromNumber'} />
              </FormControl.Label>

              <Select
                // size="large"
                placeholder="Select from number"
                showSearch={true}
                filterOption={(input: string, option: any) => {
                  return (option?.searchValue || '')
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
                onChange={(inboxId) => {
                  onInboxChange(inboxId);
                }}
                disabled={
                  props.selectedInboxId || selectedInbox?.id ? true : false
                }
                value={
                  props.selectedInboxId ||
                  selectedInbox?.id ||
                  createSmsConversationDrawerState?.selectedInbox?.id
                }
                className={'chat-inbox-select'}
              >
                {createSmsConversationDrawerState?.inboxList.map(
                  (singleInbox) => {
                    return (
                      <Option
                        searchValue={
                          singleInbox?.name +
                          singleInbox?.channelTwilioSms?.phoneNumber
                        }
                        key={singleInbox?.id}
                        value={singleInbox?.id}
                      >
                        <View flexDirection={'row'}>
                          <View style={{padding: 5}}>
                            <PhoneRingSvg />
                          </View>
                          <View style={{marginLeft: 12}}>
                            <Text
                              fontSize={'14px'}
                              color={Colors.Custom.Gray900}
                            >
                              {singleInbox?.channelTwilioSms?.phoneNumber}
                            </Text>
                            <Text
                              style={{marginTop: 0}}
                              fontSize={'12px'}
                              color={Colors.Custom.Gray500}
                            >
                              {capitalizeText(singleInbox?.name)}
                            </Text>
                          </View>
                        </View>
                      </Option>
                    );
                  }
                )}
              </Select>
            </FormControl>
          </HStack>
          <HStack pt={5}>
            <FormControl
              isInvalid={createSmsConversationDrawerState?.errors?.patient}
            >
              <FormControl.Label style={styles.formLabel} isRequired>
                <DisplayText
                  size={'smMedium'}
                  extraStyles={{color: Colors.Custom.Gray700}}
                  textLocalId={'toMember'}
                />
              </FormControl.Label>
              {createSmsConversationDrawerState.isPatientDataLoading && (
                <Spinner zIndex={1000} position={'absolute'} left={16} />
              )}

              <PatientSearchAndSelect
                isFromCreateSmsConversation={true}
                parentCode={PARENT_CODE.SMS_CONVERSATION_DRAWER}
                value={
                  createSmsConversationDrawerState.selectedPatient?.name ||
                  createSmsConversationDrawerState.newNumber
                }
                isProspect
                disableUserSearch
                isDisabled={selectedPatient?.id ? true : false}
                placeholder="Search member or type new number"
                onChange={(participant: any, data?: any) => {
                  const contactData = participant?.label?.props?.contactData;
                  if (contactData) {
                    setCreateSmsConversationDrawerState((prev) => {
                      return {
                        ...prev,
                        selectedPatient: contactData,
                      };
                    });
                    checkForConsent(contactData);
                  } else {
                    setCreateSmsConversationDrawerState((prev) => {
                      return {
                        ...prev,
                        newNumber: data,
                      };
                    });
                  }
                }}
              />

              {/* <AutoComplete
                size="large"
                style={{fontSize: 12}}
                clearIcon={false}
                placeholder="Search Patients"
                onSearch={debounce(getPatientLists, 300)}
                disabled={selectedPatient?.id ? true : false}
                value={createSmsConversationDrawerState.selectedPatient?.name}
                // onChange={(value, options: any) => {
                //   debounce(() => {
                //     getPatientLists(value);
                //   }, 300);
                // }}
                onSelect={(data: any, options: any) => {
                  checkForConsent(options);
                }}
                notFoundContent={
                  createSmsConversationDrawerState.isPatientDataLoading && (
                    <Spin size="small" />
                  )
                }
              >
                {getPatientListOptElem()}
              </AutoComplete> */}

              <FormControl.ErrorMessage
                _text={{
                  fontSize: 'xs',
                  color: 'error.500',
                  fontWeight: 500,
                }}
              >
                {createSmsConversationDrawerState.errors.patient}
              </FormControl.ErrorMessage>
            </FormControl>
          </HStack>

          {!createSmsConversationDrawerState?.isConsentGiven && (
            <HStack pl={1}>
              <Text fontSize={12} color={Colors.Custom.MissedCallIconColor}>
                {`Please give consent for "${createSmsConversationDrawerState?.selectedPatient?.name}" ${PATIENT_CONSENT_ERROR}`}
              </Text>
            </HStack>
          )}

          <HStack pt={5}>
            <FormControl
              isInvalid={createSmsConversationDrawerState?.errors?.message}
            >
              <HStack alignItems={'center'}>
                <FormControl.Label style={styles.formLabel} isRequired>
                  <DisplayText
                    size={'smMedium'}
                    extraStyles={{color: Colors.Custom.Gray700}}
                    textLocalId={'message'}
                  />
                </FormControl.Label>
                <View mb={2} ml={-4}>
                  <Tooltip placement="right" title={TCPA_INFO}>
                    <Icon
                      m="2"
                      ml="3"
                      size="3"
                      color="gray.400"
                      as={<AntIcon name="infocirlce" />}
                    />
                  </Tooltip>
                </View>
              </HStack>
              <View>
                {!createSmsConversationDrawerState.isBlockNumber ? (
                  <TextArea
                    defaultValue=""
                    variant={'outline'}
                    value={createSmsConversationDrawerState.messageStr}
                    style={{
                      color: Colors.Custom.TextColor,
                      fontSize: 18,
                    }}
                    height={65}
                    maxH={100}
                    width={'100%'}
                    autoComplete="off"
                    multiline={true}
                    onChangeText={(plainString) => {
                      setCreateSmsConversationDrawerState((prev) => {
                        return {
                          ...prev,
                          messageStr: plainString,
                          errors: {
                            ...prev.errors,
                            message: plainString ? '' : 'Message is required',
                          },
                        };
                      });
                    }}
                    onKeyPress={(event) => {
                      if (
                        isDisplayCannedResponses() &&
                        (event.nativeEvent.key === KEY_PRESS_CODES.ARROW_UP ||
                          event.nativeEvent.key ===
                            KEY_PRESS_CODES.ARROW_DOWN ||
                          event.nativeEvent.key === KEY_PRESS_CODES.ENTER)
                      ) {
                        event.preventDefault();
                      }
                    }}
                  />
                ) : (
                  <ActionMessageView
                    actionCode={ACTION_MESSAGE_CODE.INBOX_UNSUBSCRIBE}
                    conversationId={''}
                    message={getBlockNumberActionMessage(
                      createSmsConversationDrawerState?.selectedPatient?.name ?? '',
                      createSmsConversationDrawerState?.selectedInbox?.channelTwilioSms?.phoneNumber?.toString(),
                      intl
                    )}
                    customStyle={{
                      backgroundColor: Colors.Custom.Gray100,
                      textColor: Colors.Custom.Gray500,
                      height: 100,
                      marginTop: 1,
                    }}
                  />
                )}
              </View>
              <FormControl.ErrorMessage
                _text={{
                  fontSize: 'xs',
                  color: 'error.500',
                  fontWeight: 500,
                }}
              >
                {createSmsConversationDrawerState.errors.message}
              </FormControl.ErrorMessage>
              <View>
                {isDisplayCannedResponses() ? (
                  <CannedResponses
                    isReplyMessage={false}
                    categoryCode={RESPONSE_CATEGORY_CODE.SMS}
                    searchString={getSearchStringForCannedResponseForInput(createSmsConversationDrawerState.messageStr)}
                    onCannedResponsesSelection={(
                      cannedResponseData: ICannedResponses
                    ) => {
                      const finalText = `${cannedResponseData.content}${
                        createSmsConversationDrawerState.messageStr || ''
                      }`.replace(
                        getSearchStringForCannedResponseForInput(
                          createSmsConversationDrawerState.messageStr
                        ),
                        ''
                      );
                      setCreateSmsConversationDrawerState((prev) => {
                        return {
                          ...prev,
                          messageStr: finalText || '',
                        };
                      });
                    }}
                    parentCode={PARENT_CODE.CREATE_SMS_DRAWER}
                  />
                ) : (
                  <View></View>
                )}
              </View>
            </FormControl>
          </HStack>
        </VStack>
      );
    } else if (isCurrentUserBusinessOwner && !createSmsConversationDrawerState?.isLoading) {
      return (
        <VStack height={'full'}>
          <View
            height={'full'}
            width={'full'}
            position="absolute"
            zIndex={10}
            bg={'coolGray.50:alpha.70'}
          >
            <Center
              top={'30%'}
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              <NoDataFound displayString={isSideCarContext ? 'noInboxMessage' : "noInboxFound"} />
              {!isSideCarContext ?
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.PRIMARY,
                  onPress: () => {
                    navigate('/admin/communication/inbox/create');
                  },
                }}
                customProps={{
                  btnText: intl.formatMessage({
                    id: 'newInbox',
                  }),
                  withRightBorder: false,
                }}
              ></FoldButton>
              : <></>}
            </Center>
          </View>
        </VStack>
      );
    } else if (!createSmsConversationDrawerState?.isLoading) {
      return (
        <VStack style={{marginTop: 50}} height={'full'}>
          <View
            height={'full'}
            width={'full'}
            position="absolute"
            zIndex={10}
            bg={'coolGray.50:alpha.70'}
          >
            <Center
              top={'30%'}
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              <NoDataFound displayString={isSideCarContext ? 'noInboxMessage' : "noInboxFound"}/>
            </Center>
          </View>
        </VStack>
      );
    }
  };
  const closeModal = () => {
    onCreateSmsConversationActionPerformed(SMS_INBOX_ACTION_CODES.DRAWER_CLOSE);
  };

  const createSMSConversationWithSelectedInbox = async (contactData: any) => {
    setCreateSmsConversationDrawerState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    const phoneData = getPhoneNumberAndCountryCode(
      contactData?.phoneNumber || ''
    );
    const conversationBodyData = {
      additional_attributes: {
        mail_subject: '',
      },
      inbox_id: createSmsConversationDrawerState?.selectedInbox?.id,
      contact_id: contactData?.id,
      assignee_id: userData?.id,
      message: {
        content: createSmsConversationDrawerState?.messageStr,
      },
      source_id:
        phoneData?.phoneNumberCountryCode + phoneData?.formattedPhoneNumber,
    };

    const conversationData: any = await createSmsInboxConversation(
      {
        bodyParams: conversationBodyData,
      },
      accountId
    ).catch((error) => {

      const errMsg =
        error?.response?.data?.error || intl.formatMessage({id: 'apiErrorMsg'});
      showToast(smsConversationInboxToast, errMsg, ToastType.error);
      setCreateSmsConversationDrawerState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    });
    if (conversationData?.data) {
      showToast(
        smsConversationInboxToast,
        intl.formatMessage({id: 'conversationCreatedSuccessfully'}),
        ToastType.success
      );
      setCreateSmsConversationDrawerState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
        const selectedInboxConversationData =
        await getConversationByConversationUuid({
          variables: {
            conversationUuid: conversationData?.data?.messages[0]?.conversation_uuid,
            loginUserIntId: userData.id,
            loginUserUuid: userData.uuid,
          },
        });
      if (conversationData.data?.conversations?.[0]?.id) {
        const eventBus = EventBus.getEventBusInstance();
        eventBus.broadcastEvent(SUPPORTED_EVENT_CODE.CONVERSATION_CREATED, conversationData.data?.conversations?.[0]);
      }
      onCreateSmsConversationActionPerformed(
        SMS_INBOX_ACTION_CODES.DRAWER_CLOSE,
        {
          inboxData: {
            id: createSmsConversationDrawerState?.selectedInbox?.id,
          },
          selectedInboxConversationData: selectedInboxConversationData.data,
        }
      );
    }
  };

  const getIfNumberExist = async (contactData: any) => {
    const inboxId = createSmsConversationDrawerState?.selectedInbox.id;
    const phoneData = getPhoneNumberAndCountryCode(
      contactData?.phoneNumber || ''
    );
    const sourceId =
      phoneData.phoneNumberCountryCode + phoneData.formattedPhoneNumber;
    const ifExist = await getInboxesForNumber({
      variables: {
        inboxId,
        sourceId,
      },
    });
    if (ifExist?.data?.contactInboxes?.length) {
      if (isDisableConversationOnPhoneNumberMissMatch) {
        const contactInboxSourceId = ifExist?.data?.contactInboxes?.[0].sourceId;
        const contactData = ifExist?.data?.contactInboxes?.[0]?.contact;
        if (contactData?.phoneNumber && contactInboxSourceId) {
          const newPatientNumber = getLastTenDigit(contactData?.phoneNumber);
          const contactCurrentPhoneNumber = contactData?.phoneNumberCountryCode + newPatientNumber;
          if (contactCurrentPhoneNumber && contactCurrentPhoneNumber !== contactInboxSourceId) {
            return {} as IContactInbox;
          }
        }
      }
      return ifExist?.data?.contactInboxes[0];
    }
    return {} as IContactInbox;
  };

  const getExistingConversationWithSelectedInbox = async (contactData: any) => {
    const contactId = contactData?.id;
    const inboxId = createSmsConversationDrawerState?.selectedInbox.id;
    const contactExistInboxes = await getInboxesForContact({
      variables: {
        contactId,
        inboxId,
      },
    });
    if (contactExistInboxes.data?.contactInboxes?.length) {
      return contactExistInboxes.data?.contactInboxes[0];
    }
    return {} as any;
  };
  const createSMSConversation = async (contactData?: any) => {
    const errorData = getUpdatedCreateSMSConversationErrorMessages(
      createSmsConversationDrawerState, contactData
    );
    if (
      errorData?.isErrorPresent ||
      !createSmsConversationDrawerState?.isConsentGiven
    ) {
      setCreateSmsConversationDrawerState((prev) => {
        return {
          ...prev,
          errors: {
            ...prev.errors,
            ...errorData.errors,
          },
        };
      });
    } else {
      setCreateSmsConversationDrawerState((prev) => {
        return {
          ...prev,
          isLoading: true,
        };
      });
      const contactId = contactData?.id;
      const ifNumberExist: IContactInbox = await getIfNumberExist(contactData);
      if (!ifNumberExist?.id) {
        setCreateSmsConversationDrawerState((prev) => {
          return {
            ...prev,
            isLoading: false,
            existingConversation: {} as any,
          };
        });
        await createSMSConversationWithSelectedInbox(contactData);
        return;
      }
      const existingConversation: IContactInbox =
        await getExistingConversationWithSelectedInbox(contactData);
      const checkConversationExist = () => {
        if (ifNumberExist?.id && ifNumberExist?.contactId === contactId) {
          return true;
        } else if (existingConversation?.conversations?.[0]?.id) {
          return true;
        }
        return false;
      };

      if (createSmsConversationDrawerState.showConversationExist) {
        setCreateSmsConversationDrawerState((prev) => {
          return {
            ...prev,
            showConversationExist: false,
          };
        });
        await onContinueWithConversation(ifNumberExist);
      } else if (ifNumberExist?.id && ifNumberExist?.contactId !== contactId) {
        const existingContactData = await getExistingContactData({
          id: ifNumberExist?.contactId,
        });
        setCreateSmsConversationDrawerState((prev) => {
          return {
            ...prev,
            isLoading: false,
            existingConversation: {} as any,
          };
        });
        setCreateSmsConversationDrawerState((prev) => {
          return {
            ...prev,
            isLinkedWithCustomerOrLead:
              ifNumberExist?.contact?.contactType?.contactType?.code,
            showConversationExist: true,
            existingConversation: ifNumberExist,
          };
        });
        const errMsg = `Inbox with this number already exist with ${existingContactData?.name}`;
        showToast(smsConversationInboxToast, errMsg, ToastType.error);
      } else if (checkConversationExist()) {
        setCreateSmsConversationDrawerState((prev) => {
          return {
            ...prev,
            isConfirmModalOpen: false,
            existingConversation: ifNumberExist as any,
          };
        });
        if (ifNumberExist?.id) {
          await onContinueWithConversation(ifNumberExist);
        } else if (existingConversation?.conversations?.[0]?.id) {
          await onContinueWithConversation(existingConversation);
        } else {
          await createSMSConversationWithSelectedInbox(contactData);
        }
      } else {
        setCreateSmsConversationDrawerState((prev) => {
          return {
            ...prev,
            isLoading: false,
            existingConversation: {} as any,
          };
        });
        await createSMSConversationWithSelectedInbox(contactData);
      }
    }
  };

  const onContinueWithConversation = async (existingConversation?: any) => {
    const existingSingleConversation =
      existingConversation?.conversations?.[0] ||
      createSmsConversationDrawerState?.existingConversation
        ?.conversations?.[0];
    const messageCreatedResp = await sendMessageAPI({
      privateNote: 'false',
      textContent: createSmsConversationDrawerState?.messageStr || '',
      accountId: accountId + '',
      conversationId: existingSingleConversation?.displayId + '',
      groupMemberCodeIdObj,
    });
    if (messageCreatedResp?.data?.id) {
      const eventBus = EventBus.getEventBusInstance();
      eventBus.broadcastEvent(SUPPORTED_EVENT_CODE.MESSAGE_CREATED, messageCreatedResp?.data);
    }
    setCreateSmsConversationDrawerState((prev) => {
      return {
        ...prev,
        isConfirmModalOpen: false,
      };
    });
    const selectedInboxConversationData = await getConversationByConversationUuid(
      {
        variables: {
          conversationUuid: existingSingleConversation?.uuid,
          loginUserIntId: userData.id,
          loginUserUuid: userData.uuid,
        },
      }
    );
    onCreateSmsConversationActionPerformed(
      SMS_INBOX_ACTION_CODES.DRAWER_CLOSE,
      {
        inboxData: {
          id: createSmsConversationDrawerState?.selectedInbox?.id,
        },
        selectedInboxConversationData: selectedInboxConversationData?.data,
      }
    );
    setCreateSmsConversationDrawerState((prev) => {
      return {
        ...prev,
        isLoading: false,
      };
    });
  };

  const onModalActionPerformed = (actionCode: string) => {
    switch (actionCode) {
      case SMS_INBOX_ACTION_CODES.MODAL_CLOSE:
        setCreateSmsConversationDrawerState((prev) => {
          return {
            ...prev,
            isConfirmModalOpen: false,
            existingConversation: {} as any,
            showConversationExist: false,
            isLoading: false,
          };
        });
        break;
      case SMS_INBOX_ACTION_CODES.CONTINUE_WITH_THE_CONVERSATION:
        onContinueWithConversation();
        break;
      case SMS_INBOX_ACTION_CODES.CONTINUE_WITH_THE_SMS_CONVERSATION:
        createSMSConversation(createSmsConversationDrawerState.selectedPatient);
        break;
    }
  };
  const createNewEntry = async (serachText: string | any) => {
    const numberToCreate = '+1' + serachText;
    const newData = await CreateAndDialNewNumber(
      numberToCreate,
      accountUUID,
      'SMS'
    )
    const contactUuid = newData?.data?.data?.createContactPerson?.contact?.uuid;
    setCreateSmsConversationDrawerState((prev) => {
      return {
        ...prev,
        selectedPatient: newData?.data?.data?.createContactPerson?.contact,
      };
    });
    registerConsent(contactUuid);
    createSMSConversation(newData?.data?.data?.createContactPerson?.contact);
  };

  return (
    <View>
      <Drawer
        visible={isDrawerVisible}
        width={finalWidth}
        mask={isSideCarContext ? false : true}
        closable
        onClose={() => closeModal()}
        title={
          <>
            <ModalActionTitle
              title={'newSMS'}
              titleColor=""
              buttonList={[
                {
                  ...(isSideCarContext && {
                    show: true,
                    id: 1,
                    btnText: 'back',
                    textColor: Colors.Custom.mainSecondaryBrown,
                    variant: BUTTON_TYPE.SECONDARY,
                    isTransBtn: false,
                    onClick: () => {
                      closeModal()
                    },
                  }),
                },
                {
                  show: true,
                  id: 1,
                  btnText: 'sendSms',
                  textColor: Colors.Custom.mainPrimaryPurple,
                  variant: BUTTON_TYPE.PRIMARY,
                  isTransBtn: false,
                  isDisabled: !createSmsConversationDrawerState?.isConsentGiven || !createSmsConversationDrawerState?.inboxList?.length,
                  onClick: () => {
                    if (!createSmsConversationDrawerState.newNumber) {
                      createSMSConversation(createSmsConversationDrawerState.selectedPatient);
                    }
                    else {
                      createNewEntry(createSmsConversationDrawerState.newNumber);
                    }
                  },
                },
              ]}
            />
          </>
        }
      >
        {createSmsConversationDrawerState.isLoading && (
          <View
            height={'full'}
            width={'full'}
            position="absolute"
            zIndex={10}
            bg={'coolGray.50:alpha.70'}
          >
            <Center
              top={'30%'}
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              <Spinner size={'lg'} />
            </Center>
          </View>
        )}
        {getSmsConversationBodyElem()}
      </Drawer>
      <ContinueWithExistingConversationPopup
        isOpen={createSmsConversationDrawerState.isConfirmModalOpen}
        onActionPerformed={onModalActionPerformed}
      />
      <ContinueWithExistingSmsConversationPopup
        existingConversation={
          createSmsConversationDrawerState?.existingConversation
        }
        isOpen={createSmsConversationDrawerState.showConversationExist || false}
        onActionPerformed={onModalActionPerformed}
      />
    </View>
  );
};

export default CreateSmsConversationDrawer;
