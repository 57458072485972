import React from 'react';
import { Path, Svg } from 'react-native-svg';
import { ICommonSvgProps } from './interfaces';
import { Colors } from '../../../styles/Colors';

const SideCarBackSvg = (props: ICommonSvgProps) => {
  const strokeColor = props?.customStrokeColor || Colors.FoldPixel.GRAY300;

  return (
    <Svg
      width={props?.width || "24"}
      height={props?.height || "24"}
      fill="none"
      viewBox="0 0 24 24"
    >
      <Path
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M20 12H4m0 0l6-6m-6 6l6 6"
      ></Path>
    </Svg>
  );
};

export default SideCarBackSvg;
