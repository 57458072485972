import { notification } from 'antd';
import { HStack, Pressable, VStack, Text, Spinner, useToast, View } from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { Colors } from '../../../../styles';
import { useLazyQuery, useMutation } from '@apollo/client';
import { TaskQueries } from '../../../../services';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../constants/Configs';
import { getMlovListFromCategory } from '../../../../utils/mlovUtils';
import { MLOV_CATEGORY } from '../../../../constants';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { getCompletedTaskStatusId, getStatusIdByCode } from '../../CareDashboard/CareDashboardUtils/CareDashboardUtils';
import { ToastType, showToast } from '../../../../utils/commonViewUtils';
import { ITask } from '../../CareDashboard/CareDashboardInterfaces';
import { useIntl } from 'react-intl';
import { isTaskWithType } from '../../../TaskCard/TaskCardHelper';
import { EntityType } from '../../../TaskCard/TaskEnum';
import { ITaskActions, ITaskDrawerAction } from '../interfaces';
import { isWeb } from '../../../../utils/platformCheckUtils';
import { EventBus } from '../../../../utils/EventBus';
import { TASK_EVENTS } from '../../CareDashboard/CareDashboardConstants';
import { DocStatus } from '../../../PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import useCarePlanStatus from '../../../PersonOmniView/MiddleContainer/CarePlan/useCarePlanStatus';
import { GET_CARE_PLAN_BY_ID } from '../../../../services/CarePlan/CarePlanQueries';
import { ModalActionAntSelect } from '../../ModalActionCommonComponent/ModalActionAntSelect';
import { IPatientNoteCardProps } from '../../../PersonOmniView/MiddleContainer/interfaces';

interface ITaskDrawerActionWithSelect {
  actions: ITaskActions[],
  task: ITask,
  onComplete?: ()=> void,
  onStatusChange?: (statusId: string)=> void,
  linkPatientNote?: IPatientNoteCardProps,
  linkedCarePlanId?: string,
  disableTaskStatusChange?: boolean;
}
export const TaskDrawerActionWithSelect = (props: ITaskDrawerActionWithSelect) => {
  const [stateData, setStateData] = useState({
    taskStatus: 'pending',
    loading: false,
  });

  const mlovData = useContext(CommonDataContext);
  const toast = useToast();
  const intl = useIntl();
  const eventBus = EventBus.getEventBusInstance();
  const taskStatusMlov = getMlovListFromCategory(mlovData.CARE_STUDIO_MLOV, MLOV_CATEGORY.TASK_STATUS) || [];
  const carePlanStatus = useCarePlanStatus();
  const completedStatusId = getCompletedTaskStatusId(taskStatusMlov);
  const [getSubTaskByIds] = useLazyQuery(TaskQueries.GET_SUB_TASK_BY_ID, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });

  const [getCarePlan] = useLazyQuery(GET_CARE_PLAN_BY_ID, {
    fetchPolicy: 'no-cache',
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
  });

  const [updateTaskStatus] = useMutation(TaskQueries.UPDATE_TASK_STATUS_AND_REFERENCE, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    onError: () => {
      setStateData((prev) => ({
        ...prev,
        loading: false,
      }));
      showToast(toast, 'Error in updating status', ToastType.error, 1500);
    },
  });

  const getTaskByCode = (statusCode: string)=> {
    let code = statusCode;
    if (statusCode === 'pending') {
      code = "accepted"
    }
    return getStatusIdByCode(taskStatusMlov,code)
  }

  useEffect(() => {
    setStateData((prev) => ({
      ...prev,
      taskStatus: props?.task?.status?.value || 'pending',
    }));
  }, []);

  const checkIfAllSubTasksAreCompleted = async (task: ITask) => {
    if (!task.subTasks || task.subTasks?.length === 0) {
      return true;
    }
    const response = await getSubTaskByIds({
      variables: {
        ids: task.subTasks?.map((task) => task?.id),
      },
    });
    if (response?.data?.getTasks?.tasks?.length) {
      if (response?.data?.getTasks?.tasks?.every((subTask: any) => subTask.statusId === completedStatusId)) {
        return true;
      } else {
        notification.destroy();
        notification.info({
          message: intl.formatMessage({
            id: 'subTaskPendingMsg',
          }),
          duration: 3.0,
        });
        return false;
      }
    } else {
      notification.destroy();
      notification.info({
        message: intl.formatMessage({
          id: 'apiErrorMsg',
        }),
        duration: 3.0,
      });
      return false;
    }
  };

  const isVitalTask = isTaskWithType(props?.task || ({} as ITask), EntityType.VITAL);
  const isFormTask = isTaskWithType(props?.task || ({} as ITask), EntityType.FORM);

  const onStatusChange = async (statusId: string) => {
    if (!props.task) {
      return;
    }
    const task = props.task;
    const statusMlov = taskStatusMlov.filter((item) => item.id === statusId)?.[0];
    eventBus.broadcastEvent(TASK_EVENTS.TASK_UPDATED, {
      task: {
        ...task,
        statusId: statusId,
        isCompleted: statusId === completedStatusId,
        status: {
          id: statusMlov?.id || '',
          code: statusMlov?.code || '',
          value: statusMlov?.value || '',
        },
      },
    });
  };

  const handleStatusChange = async (status: any, statusCode: any) => {
    if (status !== stateData?.taskStatus) {
      if (statusCode === 'completed') {
        const allSubmitted = await checkIfAllSubTasksAreCompleted(props?.task || ({} as ITask));
        if (!allSubmitted) {
          return;
        }
      }
      if ((isVitalTask || isFormTask) && statusCode === 'completed') {
        const message = isFormTask ? 'Please fill the form to complete the task' : 'Please enter requested values to complete the task';
        notification.destroy();
        notification.info({
          message: message,
          duration: 2.0,
          placement: 'top',
        });
        return;
      }
      if (isFormTask && props?.task?.status?.code === 'completed') {
        const message = isFormTask ? 'Form already filled cannot change status of completed task' : 'Vitals already recorded cannot change status of completed task';
        notification.destroy();
        notification.info({
          message: message,
          duration: 3.0,
          placement: 'top',
        });
        return;
      }

      if (statusCode === 'completed' && props.linkedCarePlanId) {
        let isInReview = false;
        const carePlanResponse = await getCarePlan({ variables: { id: props.linkedCarePlanId } });
        const carePlan = carePlanResponse?.data?.contactCarePlans?.[0];
        isInReview = carePlan?.statusId === carePlanStatus.inReviewCarePlanStatusId;

        if (isInReview) {
          notification.destroy();
          notification.warning({
            message: intl.formatMessage({
              id: 'carePlanInReviewMsg',
            }),
            duration: 3.0,
            placement: 'top',
          });
          return;
        }
      }

      if (statusCode === 'completed' && props?.linkPatientNote?.resourceId && props?.linkPatientNote?.status === DocStatus.PRELIMINARY) {
        notification.destroy();
        notification.warning({
          message: intl.formatMessage({
            id: 'completeTaskLinkNoteMessage',
          }),
          duration: 3.0,
          placement: 'top',
        });
        return;
      }
      setStateData((prev) => ({
        ...prev,
        loading: true,
      }));
      const statusId = getTaskByCode(statusCode);
      updateTaskStatus({
        variables: {
          params: {
            id: props?.task?.id,
            data: {
              statusId: statusId,
            },
          },
        },
      })
        .then((res) => {
          if (res?.data?.updateTask?.statusId) {
            setStateData((prev) => ({
              ...prev,
              taskStatus: status || '',
              loading: false
            }));
            onStatusChange(res?.data?.updateTask?.statusId);
          }
          if (isWeb()) {
            notification.destroy();
            notification.info({
              message: `Task status updated successfully`,
              duration: 3.0,
              placement: 'top',
            });
          } else {
            showToast(toast, 'Task status updated successfully', ToastType.success, 1500);
          }
          props?.onComplete?.();
        })
        .catch(() => {
          showToast(toast, 'Error in updating status', ToastType.error, 1500);
          setStateData((prev) => ({
            ...prev,
            loading: false
          }));
        })
        .finally(() => {
          setStateData((prev) => ({
            ...prev,
            loading: false,
          }));
        });
    }
  };

  return (
      <ModalActionAntSelect
        className={'custom-task-style'}
        allowClear={false}
        isRequired={false}
        leftMargin={'0'}
        isInvalid={stateData.loading}
        label={'Status'}
        value={stateData.taskStatus}
        placeholder={'Status'}
        data={props.actions}
        optionProps={{
          key: 'key',
          value: 'code',
          label: 'value',
        }}
        onChange={(value: any) => {
          const selectedAction = props.actions.find((action) => action.code === value);
          handleStatusChange(selectedAction?.value, selectedAction?.code);
        }}
        selectActionLoading={stateData.loading}
        disabled={props.disableTaskStatusChange || stateData.loading}
        extraStyle={{ fontColor: Colors.Custom.alertsDescriptionColor }}
        customStyle={{borderRadius: 4, borderColor: Colors.Custom.alertsDescriptionColor, labelBottomMargin: 0, height: 40, width: '100%'}}
        dropdownStyle={{ borderRadius: 4 }}
      />
  );
};
