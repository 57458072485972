import React from "react";
import {Path, Svg} from "react-native-svg";

function MapIcon() {
  return (
    <Svg
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <Path
        fill="#6F7A90"
        d="M8.975 14.45l-.211-.453.211.453zm-1.95 0l.211-.453-.211.453zm1.739-.453a1.8 1.8 0 01-1.528 0l-.423.906a2.8 2.8 0 002.374 0l-.423-.906zM2.167 6.762c0 1.577.449 3.27 1.243 4.733.794 1.46 1.953 2.73 3.403 3.408l.423-.906c-1.205-.564-2.225-1.65-2.947-2.98C3.57 9.69 3.167 8.16 3.167 6.762h-1zm10.666 0c0 1.398-.401 2.928-1.122 4.255-.722 1.33-1.742 2.416-2.947 2.98l.423.906c1.45-.678 2.61-1.948 3.403-3.408.794-1.463 1.243-3.156 1.243-4.733h-1zM8 1.833c2.661 0 4.833 2.199 4.833 4.93h1c0-3.267-2.603-5.93-5.833-5.93v1zm0-1c-3.23 0-5.833 2.663-5.833 5.93h1c0-2.731 2.172-4.93 4.833-4.93v-1zm1.5 5.834a1.5 1.5 0 01-1.5 1.5v1a2.5 2.5 0 002.5-2.5h-1zM8 8.167a1.5 1.5 0 01-1.5-1.5h-1a2.5 2.5 0 002.5 2.5v-1zm-1.5-1.5a1.5 1.5 0 011.5-1.5v-1a2.5 2.5 0 00-2.5 2.5h1zm1.5-1.5a1.5 1.5 0 011.5 1.5h1a2.5 2.5 0 00-2.5-2.5v1z"
      ></Path>
    </Svg>
  );
}

export default MapIcon;
