import { gql } from '@apollo/client';

export const createEmployee = gql`
  mutation createEmployee($employee: employees_insert_input!) {
    createEmployee(object: $employee) {
      id
      contact {
        uuid
      }
    }
  }
`;

export const deleteEmployees = gql`
  mutation deleteEmployee($employeeIdList: [uuid!]!) {
    updateEmployees(
      _set: {isDeleted: true}
      where: {id: {_in: $employeeIdList}}
    ) {
      returning {
        id
        isDeleted
      }
    }
  }
`;

export const deleteEmployee = gql`
  mutation deleteEmployee($employeeId: uuid!) {
    updateEmployee(pk_columns: {id: $employeeId}, _set: {isDeleted: true}) {
      isDeleted
      id
    }
  }
`;

export const getEmployeeWithMembershipFilter = gql`
query getEmployeeWithMembershipFilter($employerId: uuid!, $limit: Int!, $offset: Int!, $searchString: String = "%%", $contactMembershipsBoolExp: contact_memberships_bool_exp = {}) {
  employeeAggregate(where: {employerId: {_eq: $employerId}, contact: {_or: [{email: {_ilike: $searchString}}, {name: {_ilike: $searchString}}]}, contactMembership: $contactMembershipsBoolExp, isDeleted: {_eq: false}}) {
    aggregate {
      count
    }
  }
  employees(limit: $limit, offset: $offset, where: {employerId: {_eq: $employerId}, contact: {_or: [{email: {_ilike: $searchString}}, {name: {_ilike: $searchString}}]}, contactMembership: $contactMembershipsBoolExp, isDeleted: {_eq: false}}, order_by: {createdAt: desc}) {
    id
    employerId
    type
    contactMembership {
      companyId
      endDate
      startDate
      planType
      planName
      payerId
      enrollmentStatus
      externalMembershipId
      status
      updatedAt
    }
    contact {
      email
      name
      id
      uuid
      phoneNumber
    }
  }
}
`;

export const getEmployeeBySearchContactAction = gql`
  query getEmployeeBySearchContactAction($params: SearchContactsInput!) {
    searchContacts(params: $params) {
      contactAggregate{ 
        aggregate {
          count
        }
      }
      contacts {
        contactPracticeLocations{
          practiceLocation {
            name
          }
        }
        contactMemberships {
          companyId
          endDate
          startDate
          planType
          planName
          payerId
          enrollmentStatus
          status
          externalMembershipId
          updatedAt
        }
        email
        name
        id
        uuid
        contactConsents {
          contactUuid
        }
        phoneNumber
        isActive
        additionalAttributes
        patient {
          id
          patientId
          patientUuid
        }
        person {
          gender {
            value
            code
          }
          sexAtBirth {
            value
            code
          }
        }
      }
    }
  }
`

export const getEmployee = gql`
query getEmployee(
  $limit:Int!
  $offset:Int!
  $where: employees_bool_exp
) {
  employeeAggregate(
    distinct_on:[employerId, contactId]
    where: $where
  ) {
    aggregate {
      count
    }
  }
  employees(
    limit:$limit,
    offset:$offset,
    distinct_on:[employerId, contactId]
    where: $where
    order_by: [{employerId:asc},{contactId:desc},{createdAt: desc}]
  ) {
    id
    employerId
    type
    contactMembership(where: {isDeleted: {_eq: false}}) {
      companyId
      endDate
      startDate
      planType
      planName
      payerId
      enrollmentStatus
      status
      externalMembershipId
      updatedAt
    }
    contact {
      email
      name
      id
      uuid
      contactConsents {
        contactUuid
      }
      phoneNumber
      isActive
      additionalAttributes
      patient {
        id
        patientId
        patientUuid
      }
      person {
        gender {
          value
          code
        }
        sexAtBirth {
          value
          code
        }
      }
    }
  }
}
`;


export const getEmployeeGroup = `
query getGroup($tenantId:uuid){
  campaignSegments(order_by:{createdOn:desc}, where: {name:{_is_null:false, _neq:""}, isDeleted: {_eq:false} ,tenantId:{_eq:$tenantId}}) {
    id
    name
    metadata
    externalId
    type
  }
}
`;

export const deleteEmployeeGroup = `
  mutation deleteGroup($segmentId:uuid!) {
    updateCampaignSegment(pk_columns:{id: $segmentId},_set: {isDeleted: true}) {
      id
    }
  }
`;

export const updateEmployeeGroup = `
  mutation updateGroup(
    $segmentId: uuid!
    $name: String
    $metadata: jsonb
    $description: String
  ) {
    updateCampaignSegment(
      pk_columns: {id: $segmentId}
      _set: {metadata: $metadata, name: $name, description: $description}
    ) {
      id
    }
  }
`;

export const updateEmployee = gql`
  mutation updateEmployee($employerId: uuid, $contactId: uuid!) {
    updateEmployees(
      where: {isDeleted: {_eq: false}, contactId: {_eq: $contactId}}
      _set: {employerId: $employerId}
    ) {
      returning {
        id
      }
    }
  }
`;

export const addEmployeeInEmployer = gql`
  mutation updateEmployee(
    $employerId: uuid
    $contactId: uuid!
    $accountUuid: uuid
  ) {
    createEmployees(
      objects: {
        contactId: $contactId
        employerId: $employerId
        accountUuid: $accountUuid
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const getEmployeeListByGroup = `
  query getEmployeeListByGroup($segmentId:uuid!) {
    campaignSegment(id: $segmentId){
      name
      metadata
    }
  }
`;

export const getEmployeesForExport = gql`
  query GetEmployees($employerId: uuid) {
    employees(
      distinct_on: [contactId]
      where: {employerId: {_eq: $employerId}, isDeleted: {_eq: false}}
    ) {
      contact {
        name
        email
        phoneNumber
        personAddress {
          line1
          zipcodes {
            code
          }
        }
        contactPracticeLocations {
          practiceLocation {
            name
          }
        }
        person {
          firstName
          middleName
          lastName
          gender {
            value
          }
          birthDate
          sexAtBirth {
            value
            code
          }
        }
      }
    }
  }
`;

export const getEmployerByContact = gql`
  query GetEmployerByContact($contactId: bigint) {
    employees(where: {contact: {id: {_eq: $contactId}}}) {
      contactId
      employer {
        email
        name
        phoneNumber
      }
    }
  }
`;

export const createConsent = gql`
  mutation CreateConsent($consent: [contact_consents_insert_input!]!) {
    createContactConsents(objects: $consent) {
      returning {
        accountUuid
      }
    }
  }
`;

export const getEmployeeConsent = gql`
  query GetEmployeeConsent($contactUuid: uuid!) {
    contactConsents(where: {contactUuid: {_eq: $contactUuid}}) {
      consentId
    }
  }
`;

export const deleteUserConsent = gql`
  mutation DeleteUserConsent($contactUuid: uuid!) {
    updateContactConsents(
      where: {contactUuid: {_eq: $contactUuid}}
      _set: {isDeleted: true}
    ) {
      returning {
        contactUuid
      }
    }
  }
`;

export const updateEmployees = gql`
  mutation updateEmployees($contactEmployeeId: uuid, $contactEmployee: employees_set_input) {
  updateEmployees(where: {id: {_eq: $contactEmployeeId}}, _set: $contactEmployee) {
    returning {
      id
    }
  }
}
`;

export const updateConsent = gql`
  mutation updateContactConsents($ids: [uuid!]!) {
    updateContactConsents(where: {id: {_in: $ids}}, _set: {isDeleted: true}) {
      returning {
        id
      }
    }
  }
`;

export const CONTACT_CONSENT = gql`
  query GetContactConsent($contactUuid: uuid!) {
    contactConsents(where: {contactUuid: {_eq: $contactUuid}, isDeleted: {_eq: false}}) {
      consentId
      id
    }
  }
`;

export const GetRelationsFromContactId = gql`
  query GetRelationsFromContactId($contactId: Int) {
    contactRelations(where: {contactId: {_eq: $contactId}}) {
      contactId
      realatedContact {
        id
        email
        contactType {
          contactType {
            code
            value
          }
        }
        person {
          birthDate
          firstName
          lastName
          gender {
            code
            value
          }
        }
      }
      relationType {
        code
        value
      }
    }
  }
`;

export default {
  createEmployee,
  getEmployee,
  deleteEmployees,
  deleteEmployee,
  getEmployeeGroup,
  deleteEmployeeGroup,
  updateEmployee,
  addEmployeeInEmployer,
  updateEmployeeGroup,
  getEmployeeListByGroup,
  getEmployeeWithMembershipFilter,
  getEmployeesForExport,
  getEmployerByContact,
  createConsent,
  getEmployeeConsent,
  deleteUserConsent,
  updateEmployees,
  updateConsent,
  CONTACT_CONSENT,
  GetRelationsFromContactId,
  getEmployeeBySearchContactAction
};
