
import { Pressable, Spinner, Text } from 'native-base';
import React from 'react';
import { Colors } from '../../../../../styles';
import Stack from '../../../../common/LayoutComponents/Stack';
import { View } from 'react-native';

interface IFoldButtonV2Props {
  label: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  buttonType: ButtonType;
  onPress?: () => void;
  showRightBorder?: boolean;
  rightIcon?: JSX.Element;
  textSize?: number | string;
  height?: number;
  flex?: number;
  onTextClick?: () => void;
}

export enum ButtonType {
  'primary', 
  'secondary', 
  'tertiary'
}

const FoldButtonV2 = (props: IFoldButtonV2Props) => {
  const { onPress, buttonType, label, isDisabled, isLoading, rightIcon, showRightBorder, height, flex, onTextClick } = props;  

  const getBorderColor = () => {
    if (buttonType === ButtonType.primary) {
      return Colors.FoldPixel.PRIMARY300;
    } else if (buttonType === ButtonType.tertiary) {
      return Colors.Custom.PrimaryColor200;
    } else {
      return Colors.FoldPixel.GRAY300;
    }
  }

  const getBackgroundColor = () => {
    if (isDisabled) {
      if (buttonType === ButtonType.primary) {
        return Colors.FoldPixel.PRIMARY100;
      } else if (buttonType === ButtonType.tertiary) {
        return Colors.FoldPixel.PRIMARY100;
      } else {
        return Colors.Custom.White;
      }
    } else {
      if (buttonType === ButtonType.primary) {
        return Colors.FoldPixel.PRIMARY300;
      } else if (buttonType === ButtonType.tertiary) {
        return Colors.FoldPixel.PRIMARY100;
      } else {
        return Colors.Custom.White;
      }
    }
  }

  const getTextColor = () => {
    if (isDisabled) {
      if (buttonType === ButtonType.primary) {
        return Colors.Custom.White;
      } else if (buttonType === ButtonType.tertiary) {
        return Colors.FoldPixel.PRIMARY100;
      } else {
        return Colors.FoldPixel.GRAY200;
      }
    } else {
      if (buttonType === ButtonType.primary) {
        return Colors.Custom.White;
      } else if (buttonType === ButtonType.tertiary) {
        return Colors.FoldPixel.PRIMARY300;
      } else {
        return Colors.FoldPixel.GRAY300;
      }
    }
  }

  return (
    <Pressable
      px={2}
      py={1}
      rounded={'sm'}
      borderWidth={0.5}
      mx={1}
      flex={flex}
      alignItems={"center"}
      onPress={onPress}
      isDisabled={isDisabled}
      borderColor={
        getBorderColor()
      }
      backgroundColor={getBackgroundColor()}
    >
      <Stack direction='row' space={4} style={{alignItems:'center', height: height}}>
        {isLoading && <Spinner size={'sm'} color={Colors.FoldPixel.GRAY300} />}
        <Text
          color={getTextColor()}
          onPress={onTextClick}
        >
          {label}
        </Text>
        {showRightBorder && <View style={{marginHorizontal: 4, height: 16, width: 1, backgroundColor: getBorderColor()}}/>}
        {rightIcon}
      </Stack>
    </Pressable>
  );
};

export default FoldButtonV2;
