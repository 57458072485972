import { useQuery } from "@apollo/client";
import React, { useContext } from 'react';
import { Pressable, Skeleton, VStack } from "native-base";
import { useState } from "react";
import { CONVERSATION_ACTION_CODES } from "../../../../../../constants/ActionConst";
import AppInfoQueries from "../../../../../../services/AppInfo/AppInfoQueries";
import { IContact } from "../../../../../RightSideContainer/TeamInbox/Conversations/interfaces";
import MessagingContactDetailsDrawer from "../../../../../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/MessagingContactDetailsDrawer";
import MemberInfoListItem from "../../../../MemberInfoListItem/MemberInfoListItem";
import { IContactCardItemProps } from "./interfase";
import { CommonDataContext } from "../../../../../../context/CommonDataContext";
import { redirectToNewTab } from "../../../../../SideCar/SidecarUtils";
import { PERSON_TYPES } from "../../../../../../constants";
import { getPatientEHRUrl } from "../../../../../../utils/capabilityUtils";
import { CONFIG_CODES } from "../../../../../../constants/AccountConfigConst";
import { isAccountConfigEnabled } from "../../../../../../utils/configUtils";
import { getAllowedUserPracticeLocationUuids, getUserUUID } from "../../../../../../utils/commonUtils";
import UserPracticeLocationQueries from "../../../../../../services/Location/UserPracticeLocationQueries";
import { getLocationUuidFromPracticeLocations } from "../../../../CustomUserSearch/CustomUserSearchUtils";
import { useToast as useCustomToast} from "../../../../../Toast/ToastProvider";
import { CUSTOM_FIELD_TOAST_ERROR_DURATION_LONG } from "../../../../../RightSideContainer/Contacts/CustomField/CustomFieldConst";
import { ToastType } from "../../../../../../utils/commonViewUtils";
import { useIntl } from "react-intl";
import { USER_ACCESS_PERMISSION } from "../../../../../RightSideContainer/UserAccess/UserAccessPermission";
import { MAIN_MENU_CODES } from "../../../../../SideMenuBar/SideBarConst";


const ContactCardView = (props: IContactCardItemProps) => {
  const {
    contactUUID,
    textColor,
  } = props;
  const userUuid = getUserUUID();
  const allowedUserPracticeLocationUuids = getAllowedUserPracticeLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.INBOX
  );
  const [stateData, setStateData] = useState({
    contactData: {} as IContact,
    contactDrawerOpen: false,
  });
  const getContactByUuidApi = useQuery(AppInfoQueries.GET_CONTACT_BY_UUID, {
    variables: {
      contactUUID: contactUUID,
    },
    onCompleted: (data: any) => {
      if (data?.contacts?.length) {
        setStateData({
          ...stateData,
          contactData: data.contacts[0],
        });
      } else {
        setStateData({
          ...stateData,
          contactData: {} as IContact,
        });
      }
    },
  });

  const commonData = useContext(CommonDataContext);
  const isSideCarContext = commonData.sidecarContext?.isSidecar;
  const isCommunicationLocationHandlingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_COMMUNICATION_LOCATION_HANDLING
  );
  const ehrPatientId = stateData.contactData?.patient?.patientId || '';
  const openInEhrUrl = getPatientEHRUrl(
    ehrPatientId,
    stateData.contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid,
    ''
  );
  const customToast = useCustomToast();
  const intl = useIntl();
  const openProfileInSideCar = () => {
    if (
      stateData.contactData.contactType?.contactType.code ==
      PERSON_TYPES.CUSTOMER
    ) {
      if (openInEhrUrl) {
        window.open(openInEhrUrl, '_blank', 'noopener,noreferrer');
      } else {
        redirectToNewTab(`/members/patient/${stateData.contactData?.id}`);
      }
    } else if (
      stateData.contactData.contactType?.contactType.code == PERSON_TYPES.LEAD
    ) {
      redirectToNewTab(`/members/prospect/${stateData.contactData?.id}`);
    }
  };

  const openContactProfile = () => {
    if (isSideCarContext) {
      openProfileInSideCar();
    } else {
      setStateData({
        ...stateData,
        contactDrawerOpen: true,
      });
    }
  };

  const showContactProfile = () => {
    if (isCommunicationLocationHandlingEnabled) {
      const contactLocationUuid =
        stateData.contactData?.contactPracticeLocations?.[0]
          ?.practiceLocationUuid;
      const isSameLocation =
        allowedUserPracticeLocationUuids?.includes(contactLocationUuid);
      if (!isSameLocation) {
        customToast({
          message: intl.formatMessage({
            id: 'patientProfileAccessDenyMsg',
          }),
          toastType: ToastType.info,
          closeAllPrevToast: true,
        });
      } else {
        openContactProfile();
      }
    } else {
      openContactProfile();
    }
  };

  return (
    <VStack>
      {getContactByUuidApi.loading ? (
        <Skeleton></Skeleton>
      ) : (
        <Pressable onPress={showContactProfile}>
          <MemberInfoListItem
            contactData={stateData.contactData || ({} as IContact)}
            isPatientSearchCard
            textColor={textColor}
            showDetailIcon
            showDateOfBirth={true}
          />
        </Pressable>
      )}

      {stateData.contactDrawerOpen && (
        <MessagingContactDetailsDrawer
          contactData={stateData.contactData}
          isDrawerVisible={stateData.contactDrawerOpen ? true : false}
          onSideBarActionPerformed={(action?: any) => {
            if (action == CONVERSATION_ACTION_CODES.DRAWER_CLOSE) {
              setStateData({
                ...stateData,
                contactDrawerOpen: false,
              });
            }
          }}
          borderLessView={true}
        />
      )}
    </VStack>
  );
}

export default ContactCardView;