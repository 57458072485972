import {InputNumber} from 'antd';
import {HStack, Text, VStack} from 'native-base';
import React, { useState } from 'react';
import {
  cmToFeetAndInches,
  feetAndInchesToCm,
  feetAndInchesToInches,
  inchesToFeetAndInches,
  isInchesUnit,
} from '../../utils/vitalUnitConversions';
import {Vital} from '../../utils/VitalUtils';
import {IFormCommonData} from '../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper';
import {VitalType} from '../TaskCard/VitalCaptureInput/VitalCaptureInput';
import {Colors} from '../../styles';
import { getVitalListFromCapability } from '../../utils/capabilityUtils';
import { Observation } from 'fhir/r4';

interface IVitalLimits {
  min: number;
  max: number;
  value: string;
}

interface IHeightWeightInput {
  // unit: {weight: string; height: Array<string>};
  // unit: string;
  vitals: Observation[];
  disabled?: boolean;
  lastRecordedText?: string;
  onChange: (vitalCode: string, value?: string) => void;
  weightVital?: IVitalLimits;
  heightVital?: IVitalLimits;
  isRequired?: boolean;
  errors?: {[key: string]: string};
}

const HeightWeightInput = ({
  // unit,
  disabled,
  lastRecordedText,
  onChange,
  weightVital,
  heightVital,
  isRequired,
  errors,
  vitals,
}: IHeightWeightInput) => {
  const vitalList = getVitalListFromCapability('', '');
  const [componentState, setComponentState] = useState<{
    heightValue: {
      vital1?: string | number;
      vital2?: string | number;
    },
    weightValue: {
      vital1?: string | number;
    }
  }>({
    heightValue: {
      vital1: '',
      vital2: ''
    },
    weightValue: {
      vital1: ''
    }
  });
  const heightAlreadyAdded = vitals.find((item) => !!item.id && item.code?.coding?.[0]?.code == Vital.height);
  const weightAlreadyAdded = vitals.find((item) => !!item.id && item.code?.coding?.[0]?.code == Vital.weight);

  const getVitalConfig = (vitalLoinc: string) => {
    const vitalConfig = vitalList.find(
      (vitalConfig) => vitalConfig.loinc === vitalLoinc
    );
    return vitalConfig;
  };

  const heightVitalConfig = getVitalConfig(Vital.height);
  const weightVitalConfig = getVitalConfig(Vital.weight);

  const heightReferenceRange = heightVitalConfig?.range || [];
  const weightReferenceRange = weightVitalConfig?.range || [];
  const defaultRange = {min: 0, max: 400};

  if (heightReferenceRange.length === 0) {
    heightReferenceRange.push(defaultRange);
  }
  if (weightReferenceRange.length === 0) {
    weightReferenceRange.push(defaultRange);
  }

  const weightVitalLimits =
    weightVital !== undefined ? weightVital : {min: 0, max: 0, value: ''};
  const heightVitalLimits =
    heightVital !== undefined ? heightVital : {min: 0, max: 0, value: ''};

  const getValue = (type: VitalType, vital: IVitalLimits) => {
    if (type === VitalType.normal && vital?.value) {
      return {vital1: vital.value};
    } else if (type === VitalType.heightWithFeetAndInches && vital?.value) {
      const item = parseFloat(vital.value);
      const unit = heightVitalConfig?.unit;
      if (isInchesUnit(unit)) {
        const {feet, inches} = inchesToFeetAndInches(item);
        return {vital1: feet, vital2: inches};
      } else {
        const {feet, inches} = cmToFeetAndInches(item);
        return {vital1: feet, vital2: inches};
      }
    }
    return {};
  };

  const handleChange = (
    type: VitalType,
    data: {
      vital1?: string | number;
      vital2?: string | number;
    }
  ) => {
    let result = undefined;
    if (type === VitalType.heightWithFeetAndInches) {
      setComponentState((prev) => ({
        ...prev,
        heightValue: {
          vital1: data.vital1,
          vital2: data.vital2,
        }
      }));
    } else {
      setComponentState((prev) => ({
        ...prev,
        weightValue: {
          vital1: data.vital1
        }
      }));
    }
    if (type === VitalType.normal) {
      result = data.vital1;
    } else if (
      type === VitalType.bloodPressure &&
      (data.vital1 || data.vital2)
    ) {
      result = `${data.vital1 || ''}/${data.vital2 || ''}`;
    } else if (
      type === VitalType.heightWithFeetAndInches &&
      (data.vital1 != undefined ||
        data.vital1 != '' ||
        data.vital2 != undefined ||
        data.vital2 != '')
    ) {
      const vital1Value = parseFloat(`${data.vital1 || 0}`).toFixed(0);
      const vital2Value = parseFloat(`${data.vital2 || 0}`).toFixed(0);
      const unit = heightVitalConfig?.unit;
      if (isInchesUnit(unit)) {
        result = feetAndInchesToInches(
          parseFloat(vital1Value),
          parseFloat(vital2Value)
        );
      } else {
        result = feetAndInchesToCm(
          parseFloat(vital1Value),
          parseFloat(vital2Value)
        );
      }
    }
    result = result ? `${result}` : undefined;
    if (type === VitalType.normal) {
      onChange(Vital.weight, result);
    }
    if (type === VitalType.heightWithFeetAndInches) {
      onChange(Vital.height, result);
    }
  };

  const valueFormatter = (
    value: string | number | undefined,
    info: {userTyping: boolean; input: string}
  ): string => {
    const data = `${value}`;
    if (data.includes('.') && !info.userTyping) {
      return `${value}`.replace('\\.0*$', '');
    }
    return `${value}`;
  };

  const heightError =
    errors?.heightError !== undefined ? errors.heightError : '';
  const weightError =
    errors?.weightError !== undefined ? errors.weightError : '';
  return (
    <HStack alignItems={'center'} justifyContent="space-between" space={10}>
      {!heightAlreadyAdded && (
        <VStack width={'48%'}>
          <Text fontSize={14} color={Colors.Custom.Gray700} mb={2}>
            Height
          </Text>
          <HStack alignItems={'center'} space={2}>
            <HStack space={2} alignItems="center">
              <InputNumber
                value={componentState.heightValue.vital1}
                min={'0'}
                max={'200'}
                readOnly={disabled}
                required={isRequired}
                status={heightError?.length > 0 ? 'error' : ''}
                size="middle"
                style={{borderRadius: 8}}
                precision={heightVitalConfig?.allowedDecimalPlaces || 0}
                formatter={valueFormatter}
                onChange={(text) => {
                  handleChange(VitalType.heightWithFeetAndInches, {
                    vital1: text || '',
                    vital2: componentState.heightValue.vital2,
                  });
                }}
              />
              <Text>ft</Text>
            </HStack>
            <HStack space={2} alignItems="center">
              <InputNumber
                value={componentState.heightValue.vital2}
                min={'0'}
                max={'200'}
                readOnly={disabled}
                required={isRequired}
                status={heightError?.length > 0 ? 'error' : ''}
                style={{borderRadius: 8}}
                size="middle"
                precision={heightVitalConfig?.allowedDecimalPlaces || 0}
                formatter={valueFormatter}
                onChange={(text) => {
                  handleChange(VitalType.heightWithFeetAndInches, {
                    vital1: componentState.heightValue.vital1,
                    vital2: text || '',
                  });
                }}
              />
              <Text>in</Text>
            </HStack>
          </HStack>
          <Text
            mt={2}
            fontSize={12}
            color={Colors.Custom.ErrorColor}
            fontWeight={500}
          >
            {heightError}
          </Text>
        </VStack>
      )}
      {!weightAlreadyAdded && (
        <VStack width={'48%'}>
          <Text fontSize={14} color={Colors.Custom.Gray700} mb={2}>
            Weight
          </Text>
          {/* {lastRecordedText && (
            <Text size="xsLight" italic color="gray.500">
              {lastRecordedText}
            </Text>
          )} */}
          <HStack alignItems={'center'} space={2}>
            <InputNumber
              value={componentState.weightValue.vital1}
              min={'0'}
              max={'10000'}
              required={isRequired}
              readOnly={disabled}
              style={{borderRadius: 8}}
              status={weightError?.length > 0 ? 'error' : ''}
              size="middle"
              precision={weightVitalConfig?.allowedDecimalPlaces || 0}
              formatter={valueFormatter}
              onChange={(text) => {
                handleChange(VitalType.normal, {
                  vital1: text || ''
                });
              }}
            />
            {weightVitalConfig?.unit && <Text>{weightVitalConfig.unit}</Text>}
          </HStack>
          <Text
            mt={2}
            fontSize={12}
            color={Colors.Custom.ErrorColor}
            fontWeight={500}
          >
            {weightError}
          </Text>
        </VStack>
      )}
    </HStack>
  );
};

export default HeightWeightInput;
