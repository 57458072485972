import React, {useImperativeHandle} from 'react';
import {
  Button,
  Center,
  FormControl,
  HStack,
  Pressable,
  Spinner,
  Text,
  TextArea,
  VStack,
  View,
} from 'native-base';
import {Colors} from '../../../styles/Colors';
import {Switch, Modal} from 'antd';
import RedirectSvg from '../Svg/RedirectSvg';
import InfoSvg from '../Svg/InfoSvg';
import {useContext, useEffect, useState} from 'react';
import EditUserWithBusinessHours from '../../RightSideContainer/Contacts/TeamMembers/AddEditUser/EditUserWithBusinessHours';
import {GET_USER_BY_UUID} from '../../../services/User/UserQueries';
import {IUser} from '../../RightSideContainer/Contacts/TeamMembers/interfaces';
import {useLazyQuery} from '@apollo/client';
import {getAccountUUID, getUserAccountLocationUuids, getUserUUID} from '../../../utils/commonUtils';
import {IAutoReplyProps, IAutoReplyState} from './PreferencesInterface';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../constants/Configs';
import {
  AVAILABILITY_TYPE_CODES,
  MLOV_CATEGORY,
} from '../../../constants/MlovConst';
import {ScheduleQueries} from '../../../services';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../utils/mlovUtils';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {
  addOrUpdateAutoResponse,
  addTimeZonesToAvailabilities,
  getUserAutoResponse,
} from '../../RightSideContainer/AutoReplyView/AutoReplyViewUtils';
import {AUTO_RESPONSE_TYPES} from '../../../constants';
import {showToast, ToastType} from '../../../utils/commonViewUtils';
import {
  IAutoResponseConfig,
  IPracticeAvailabilityConfig,
} from '../../RightSideContainer/AutoReplyView/interfaces';
import {useToast as useCustomToast} from '../../Toast/ToastProvider';
import {FoldButton} from '../../CommonComponents/FoldButton/FoldButton';
import {useIntl} from 'react-intl';
import AlertSvg from '../Svg/AlertSvg';
import {TestIdentifiers, testID} from '../../../testUtils';

const AutoReply = React.forwardRef((props: IAutoReplyProps, ref) => {
  const userUuid = getUserUUID();
  const accountUuid = getAccountUUID();
  const commonData = useContext(CommonDataContext);
  const intl = useIntl();
  const customToast = useCustomToast();
  const userAccountLocationUuids = getUserAccountLocationUuids();
  const availabilityTypes =
    getMlovListFromCategory(
      commonData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.AVAILABILITY_TYPE
    ) || [];
  const practiceScheduleAvailabilityTypeId = getMlovIdFromCode(
    availabilityTypes,
    AVAILABILITY_TYPE_CODES.PRACTICE_SCHEDULE
  );

  const [state, setState] = useState<IAutoReplyState>({
    isEnabled: true,
    userData: {} as IUser,
    showCloseModal: false,
    userDataLoading: true,
    autoResponseLoading: true,
    isBusinessHoursSet: false,
    isEditBusinessHoursDrawerVisible: false,
    autoResponseText: '',
    initialAutoResponseText: '',
    responseId: '',
    availabilities: [] as IPracticeAvailabilityConfig[],
  });

  const [getSingleUserById] = useLazyQuery(GET_USER_BY_UUID, {
    fetchPolicy: 'no-cache',
  });
  const [getTimezonesByUuids] = useLazyQuery(
    ScheduleQueries.GET_TIMEZONES_BY_UUIDS
  );

  const getPracticeAvailabilityWhereCondition = () => {
    let whereCondition: any = {};
    whereCondition = {
      isDeleted: {_eq: false},
      typeId: {_eq: practiceScheduleAvailabilityTypeId},
      _or: [
        {
          userId: {
            _eq: userUuid,
          },
        },
        {
          locationId: {
            _in: userAccountLocationUuids,
          },
          userId: {
            _is_null: true
          }
        },
      ],
    };

    return whereCondition;
  };

  const [getAvailabilitiesAPI, {loading: isGetAvailabilitiesAPILoading}] =
    useLazyQuery(ScheduleQueries.GET_PRACTICE_SCHEDULE, {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      variables: {
        whereCondition: getPracticeAvailabilityWhereCondition(),
      },
      onCompleted: (response) => {
        if (response?.schedules?.length) {
          setState((prev) => {
            return {
              ...prev,
              isBusinessHoursSet: true,
            };
          });
        } else {
          setState((prev) => {
            return {
              ...prev,
              isBusinessHoursSet: false,
            };
          });
        }
      },
      onError: (error) => {},
    });

  const getCurrentUserData = async () => {
    try {
      setState((prev) => {
        return {
          ...prev,
          userDataLoading: true,
        };
      });
      const userData = await getSingleUserById({
        variables: {
          userUuid: userUuid,
        },
      });
      setState((prev) => {
        return {
          ...prev,
          userData: userData?.data?.users[0],
          userDataLoading: false,
        };
      });
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          userDataLoading: false,
        };
      });
    }
  };

  const getAutoResponse = async () => {
    try {
      setState((prev) => {
        return {
          ...prev,
          autoResponseLoading: true,
        };
      });
      const response = await getUserAutoResponse({
        inboxId: -1,
        userUuid: userUuid,
      });
      setState((prev) => {
        return {
          ...prev,
          autoResponseText: response?.replyText || '',
          initialAutoResponseText: response?.replyText || '',
          availabilities: response?.practiceAvailabilityConfig || [],
          responseId: response?.id || '',
          isEnabled: response?.isEnabled || state.isEnabled,
          autoResponseLoading: false,
        };
      });
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          autoResponseLoading: false,
        };
      });
    }
  };

  const getAvailabilitiesData = async () => {
    const availabilitiesResponse = await getAvailabilitiesAPI({
      variables: {
        whereCondition: getPracticeAvailabilityWhereCondition(),
      },
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    });
    const timezoneIds = availabilitiesResponse.data.schedules.map(
      (item: any) => item.timezoneId
    );
    const timezoneResponse = await getTimezonesByUuids({
      variables: {
        uuids: timezoneIds,
      },
    });
    const availabilities = addTimeZonesToAvailabilities({
      timezones: timezoneResponse.data.timezones,
      availabilities: availabilitiesResponse.data.schedules || [],
    });
    return availabilities;
  };

  const getAutoResponseConfig = (isEnabled?: boolean): IAutoResponseConfig => {
    return {
      id: state.responseId,
      inboxId: -1,
      accountId: accountUuid,
      userId: userUuid,
      practiceAvailabilityConfig: state.availabilities,
      replyText: state.autoResponseText.trim(),
      replyType: AUTO_RESPONSE_TYPES.PATIENT_ONE_ON_ONE_CHAT,
      assigneeUuid: userUuid,
      isEnabled: isEnabled ?? state.isEnabled,
    };
  };

  const handleSubmit = async (isEnabled?: boolean) => {
    setState((prev) => {
      return {
        ...prev,
        showCloseModal: false,
      };
    });
    try {
      const body = getAutoResponseConfig(isEnabled);
      const availabilities = await getAvailabilitiesData();
      body.practiceAvailabilityConfig = availabilities || [];
      const response = await addOrUpdateAutoResponse({body});
      const data = response?.data;
      if (!data?.id) {
        return;
      }
      setState((prev) => {
        return {
          ...prev,
          responseId: data?.id,
          initialAutoResponseText: state.autoResponseText.trim(),
        };
      });
      if (isEnabled === undefined) {
        showToast(
          customToast,
          intl.formatMessage({id: 'autoReplySaveSuccess'}),
          ToastType.success,
          2000,
          true
        );
      }
    } catch (error) {
      showToast(
        customToast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error,
        2000,
        true
      );
    }
  };

  const handleClose = () => {
    if (isSaveButtonDisabled()) {
      props?.onClose();
    } else {
      setState((prev) => {
        return {
          ...prev,
          showCloseModal: true,
        };
      });
    }
  };

  useImperativeHandle(ref, () => ({
    handleClose() {
      handleClose();
    },
  }));

  useEffect(() => {
    getCurrentUserData();
    getAutoResponse();
  }, []);

  useEffect(() => {
    if (!state.isEditBusinessHoursDrawerVisible) {
      getAvailabilitiesAPI();
    }
  }, [state.isEditBusinessHoursDrawerVisible]);

  const showEditBusinessHours = () => {
    setState((prev) => {
      return {
        ...prev,
        isEditBusinessHoursDrawerVisible: true,
      };
    });
  };

  const isSaveButtonDisabled = () => {
    const disabled =
      !state.isBusinessHoursSet ||
      !state.autoResponseText.trim().length ||
      state.autoResponseText.trim() === state.initialAutoResponseText.trim();
    return disabled;
  };

  const showAutoReplySwitchElem = () => {
    return (
      <HStack
        style={{
          borderColor: Colors.Custom.GRAY150,
          borderRadius: 10,
          borderWidth: 1,
          padding: 10,
          marginBottom: 15,
        }}
      >
        <HStack>
          <View>
            <Text
              style={{
                fontSize: 14,
                fontWeight: '500',
                lineHeight: 14.4,
                color: Colors.Custom.Gray800,
                padding: 1,
              }}
            >
              Set Auto Reply For Chats
            </Text>
            <Text
              fontSize={14}
              fontWeight={400}
              lineHeight={14.4}
              color={Colors.Custom.Gray400}
              padding={1}
            >
              Reply automatically when you are away from work
            </Text>
          </View>
        </HStack>
        <HStack flex={1} justifyContent={'flex-end'}>
          <Switch
            style={{
              alignSelf: 'center',
              background: Colors.Custom.Primary300,
              borderRadius: 4,
              backgroundColor: state?.isEnabled
                ? Colors.Custom.Primary300
                : Colors.Custom.Gray200,
            }}
            checked={state.isEnabled}
            onChange={(switchStatus) => {
              setState((prev) => {
                return {
                  ...prev,
                  isEnabled: switchStatus,
                };
              });
              if (!switchStatus) {
                handleSubmit(switchStatus);
              }
            }}
            {...testID('AutoReplySwitch')}
          />
        </HStack>
      </HStack>
    );
  };

  const showBusinessHourNotSetErrorElem = () => {
    return (
      <View
        style={{
          backgroundColor: Colors.Custom.Red100,
          borderRadius: 10,
          marginTop: 5,
          marginBottom: 5,
        }}
      >
        <HStack width={'100%'} style={{flexWrap: 'wrap'}}>
          <VStack padding={15} alignItems={'center'} alignContent={'center'}>
            <InfoSvg
              customColor={Colors.Custom.crossIconColor}
              customDimension={'20'}
            />
          </VStack>
          <VStack paddingTop={15} paddingBottom={15} style={{flex: 1}}>
            <Text
              style={{
                flexShrink: 1,
                flexWrap: 'wrap',
                fontSize: 12,
                fontWeight: '400',
                lineHeight: 14.4,
                color: Colors.Custom.Gray800,
              }}
            >
              Looks like you've not set your business hours in the system. Auto
              replies are sent during non-business hours.
            </Text>
          </VStack>
          <VStack alignItems={'center'} justifyContent={'center'}>
            <HStack padding={15}>
              <Pressable
                style={{flexDirection: 'row', alignItems: 'center'}}
                onPress={showEditBusinessHours}
                {...testID('editBusinessHour')}
              >
                <Text
                  style={{
                    paddingRight: 7,
                    fontSize: 12,
                    fontWeight: '400',
                    lineHeight: 14.4,
                    flexShrink: 1,
                    color: Colors.Custom.crossIconColor,
                  }}
                >
                  Update Business Hours
                </Text>
                <RedirectSvg customStrokeColor={Colors.Custom.crossIconColor} />
              </Pressable>
            </HStack>
          </VStack>
        </HStack>
      </View>
    );
  };

  const showUpdateBusinessHourFooter = () => {
    return (
      <View marginBottom={5}>
        <HStack
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <FoldButton
            customProps={{
              btnText: 'Save',
            }}
            nativeProps={{
              onPress() {
                handleSubmit();
              },
              isDisabled: isSaveButtonDisabled(),
              backgroundColor: isSaveButtonDisabled()
                ? Colors.Custom.Gray50
                : Colors.Custom.Primary300,
              _text: {
                fontSize: 14,
                lineHeight: 16.8,
                color: isSaveButtonDisabled()
                  ? Colors.FoldPixel.GRAY150
                  : Colors.Custom.White,
              },
              _hover: {
                backgroundColor: Colors.Custom.BookedAppointmentHomeBtn,
                color: Colors.Custom.White,
              },
              width: 70,
            }}
            {...testID(TestIdentifiers.saveBtn)}
          />
          {state.isBusinessHoursSet ? (
            <Pressable
              style={{flexDirection: 'row', alignItems: 'center'}}
              onPress={showEditBusinessHours}
              {...testID('editBusinessHour')}
            >
              <Text
                style={{
                  paddingRight: 7,
                  fontSize: 12,
                  fontWeight: '400',
                  lineHeight: 14.4,
                  color: Colors.Custom.Primary300,
                  flexShrink: 1,
                }}
              >
                Update Business Hours
              </Text>
              <RedirectSvg />
            </Pressable>
          ) : (
            <></>
          )}
        </HStack>
      </View>
    );
  };

  const getCloseModal = () => {
    return (
      <View>
        <Modal
          title=""
          width={325}
          open={state.showCloseModal}
          confirmLoading={state.showCloseModal}
          closable={false}
          getContainer={false}
          style={{
            position: 'absolute',
            top: '10%',
            right: '8%',
          }}
          className="auto-reply-close-modal"
          footer={
            <View
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'row',
                padding: 0,
                margin: 0,
              }}
            >
              <Button.Group>
                <FoldButton
                  nativeProps={{
                    backgroundColor: Colors.Custom.Red100,
                    color: Colors.Custom.crossIconColor,
                    borderColor: Colors.Custom.crossIconColor,
                    borderWidth: 0.5,
                    variant: 'subtle',
                    colorScheme: 'primary',
                    width: 135,
                    _text: {
                      color: Colors.Custom.crossIconColor,
                    },
                    _hover: {
                      backgroundColor: Colors.Custom.crossIconColor,
                      color: Colors.Custom.White,
                      variant: 'solid',
                      colorScheme: 'primary',
                      _text: {
                        color: Colors.Custom.White,
                      },
                    },
                    onPress() {
                      props?.onClose();
                    },
                  }}
                  customProps={{
                    btnText: 'Discard',
                  }}
                  {...testID(TestIdentifiers.discardBtn)}
                />
                <FoldButton
                  nativeProps={{
                    backgroundColor: Colors.Custom.Primary300,
                    color: Colors.Custom.White,
                    width: 135,
                    _hover: {
                      backgroundColor: Colors.Custom.BookedAppointmentHomeBtn,
                      color: Colors.Custom.White,
                      variant: 'solid',
                      colorScheme: 'primary',
                    },
                    isDisabled: isSaveButtonDisabled(),
                    onPress() {
                      handleSubmit();
                    },
                  }}
                  customProps={{
                    btnText: 'Save',
                  }}
                  {...testID(TestIdentifiers.saveBtn)}
                />
              </Button.Group>
            </View>
          }
          onCancel={() => {
            setState((prev) => {
              return {
                ...prev,
                showCloseModal: false,
              };
            });
          }}
        >
          <VStack alignItems={'center'}>
            <View marginBottom={1}>
              <AlertSvg height={'30'} width={'30'} />
            </View>
            <Text
              style={{
                fontSize: 16,
                fontWeight: '500',
                lineHeight: 20,
                textAlign: 'center',
                color: Colors.Custom.Gray800,
                paddingBottom: 1,
              }}
            >
              Changes Not Saved
            </Text>
            <Text
              style={{
                fontSize: 14,
                fontWeight: '400',
                lineHeight: 16,
                textAlign: 'center',
                color: Colors.Custom.Gray400,
              }}
            >
              Are you sure you want close the Screen without saving changes
            </Text>
          </VStack>
        </Modal>
      </View>
    );
  };

  return (
    <View>
      {state.userDataLoading ||
      state.autoResponseLoading ||
      isGetAvailabilitiesAPILoading ? (
        <Center
          top={'50%'}
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <Spinner size="lg" />
        </Center>
      ) : (
        <View width={'100%'}>
          {showAutoReplySwitchElem()}
          {state.isEnabled ? (
            <View>
              {!state.isBusinessHoursSet ? (
                showBusinessHourNotSetErrorElem()
              ) : (
                <></>
              )}
              <View marginBottom={5}>
                <VStack>
                  <HStack alignContent="center">
                    <Text
                      style={{
                        fontSize: 12,
                        fontWeight: '500',
                        lineHeight: 14.4,
                        color: Colors.Custom.Gray800,
                        padding: 1,
                      }}
                    >
                      Auto Reply Message For Patients
                    </Text>
                    <span className="required-dot"></span>
                  </HStack>
                  <Text
                    style={{
                      fontSize: 12,
                      fontWeight: '400',
                      lineHeight: 14.4,
                      color: Colors.Custom.Gray400,
                      padding: 1,
                    }}
                  >
                    Set up a message to let others know you're on vacation or
                    not available to reply
                  </Text>
                </VStack>
              </View>
              <View marginBottom={1}>
                <TextArea
                  defaultValue=""
                  isRequired={true}
                  isDisabled={!state.isBusinessHoursSet}
                  value={state.autoResponseText}
                  placeholder="Type message here..."
                  variant={'outline'}
                  style={{
                    color: Colors.Custom.Gray50,
                    fontSize: 18,
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: Colors.Custom.Gray200,
                  }}
                  height={88}
                  maxH={100}
                  width={'100%'}
                  autoComplete="off"
                  multiline={true}
                  onChangeText={(plainString) => {
                    setState((prev) => {
                      return {
                        ...prev,
                        autoResponseText: plainString,
                      };
                    });
                  }}
                  maxLength={500}
                />
                <View
                  style={{
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      fontWeight: '200',
                      color: Colors.Custom.Gray400,
                    }}
                  >
                    {state?.autoResponseText.trim()?.length} / 500
                  </Text>
                </View>
              </View>
              {state.isEditBusinessHoursDrawerVisible && (
                <EditUserWithBusinessHours
                  onClose={(actionCode) => {
                    setState((prev) => {
                      return {
                        ...prev,
                        isEditBusinessHoursDrawerVisible: false,
                      };
                    });
                  }}
                  user={state.userData}
                />
              )}
              {showUpdateBusinessHourFooter()}
              {state.showCloseModal ? getCloseModal() : <></>}
            </View>
          ) : (
            <></>
          )}
        </View>
      )}
    </View>
  );
});

export default AutoReply;
