import {HStack, Pressable, Text, View} from 'native-base';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {useEffect, useState} from 'react';
import {Colors} from '../../../../../styles';
import {UserData} from '../InviteUserDrawer/interfaces';
import AddAccountRoleView from './AddAccountRoleView';
import {ACCOUNTS_AND_ROLES_ACTION_CONST} from '../../../../../constants';
import {IAccountRole, IAccountRoleResp} from './interface';
import {getFilteredLocations, getUserAccountRoleId, isAddPracticeBtnActive, processRoles, validAccountUserLocation} from './utils';
import {IMlov} from '../../../../../Interfaces';
import {getAccountUUID} from '../../../../../utils/commonUtils';
import {useLazyQuery} from '@apollo/client';
import TeamQueries from '../../../../../services/Team/TeamQueries';
import {getFormattedAccountLocationList} from '../AccountLocations/utils';
import {ILocationData} from '../AccountLocations/interface';
import permissionQueries from '../../../UserAccess/permissionQueries';
import {Skeleton} from 'antd';
import {MLOV_CATEGORY, USER_ROLE_CODES} from '../../../../../constants/MlovConst';
import { IUserRoleMlov } from '../AddEditUser/interfaces';
import { ModalActionSubTitle } from '../../../../common/ModalActionTitle/ModalActionSubTitle';
import {ModalActionAntSelect} from '../../../../common/ModalActionCommonComponent/ModalActionAntSelect';
interface IAssociatedAccountView {
  userData: UserData;
  onActionPerformed?: (code: string,data?: IAccountRole[] | string[]) => void;
  isDisabled: boolean;
  associatedAccounts?: IAccountRole[];
  isEditMode?: boolean;
  selectedGlobalRoles: string[];
  errors?: any;
}
const AssociatedAccountView = (props: IAssociatedAccountView) => {
  const { onActionPerformed, isDisabled, associatedAccounts, isEditMode, selectedGlobalRoles, errors} =
    props;
  const accountUuid = getAccountUUID();
  const [count, setCount] = useState(2);
  const [accountUserRoleList, setAccountUserRoleList] = useState([
    {
      id: 1,
      editMode: false,
      externalUserId: '',
      rolesToAdd: [] as string[],
      isDeleted: false,
      roles: [] as string[],
      editedRoles: [] as string[],
      ...(isEditMode ? {
        editedRoles: [] as string[],
        rolesToDelete: [] as string[],
        isDelete: false
      } : {}),
      locationUuid: '',
      locationId: -1,
      locationGroupId: '',
      accountUuid: '',
      accountId: -1,
    },
  ] as IAccountRole[]);
  const [stateData, setStateData] = useState({
    loading: false,
    roleLoading: false,
    pageSize: 10,
    offset: 0,
    currentPage: 1,
    searchString: '',
    accountLocation: [] as ILocationData[],
    userRoles: [] as IUserRoleMlov[],
    globalRoles: [] as IUserRoleMlov[],
    selectedGlobalRoles: []  as string[],
    selectedUserRoles: []  as string[],
  })
  const [getLocations] = useLazyQuery(TeamQueries.getAccountLocationForUser, {
    fetchPolicy: 'no-cache',
  });
  const [getAccountRoles] = useLazyQuery(
    permissionQueries.getAccountRoles,{
      fetchPolicy: 'no-cache',
    }
  );
  const addPractice = () => {
    const singlePracticeObj = {
      id: count,
      accountUuid: '',
      accountId: 0,
      externalUserId: '',
      rolesToAdd: accountUserRoleList[0].rolesToAdd as string[],
      rolesToDelete: [] as string[],
      roles: [] as string[],
      editedRoles: [] as string[],
      locationUuid: '',
      locationId: 0,
      locationGroupId: '',
      isDeleted: false
    };
    setCount((prv) => prv + 1);
    setAccountUserRoleList([...accountUserRoleList, singlePracticeObj]);
    onActionPerformed?.(ACCOUNTS_AND_ROLES_ACTION_CONST.LOCATION_AND_ROLE,[...accountUserRoleList, singlePracticeObj]);
  };

  const removePractice = (locationRole: IAccountRole) => {
    let updatedList: IAccountRole[] = []
    if (isEditMode && locationRole.editMode) {
      updatedList =  accountUserRoleList.map((item) => {
        if (item.id == locationRole.id) {
          return {...item, isDeleted: true}
        }
        return {...item}
      });
    } else {
      updatedList =  accountUserRoleList.filter((item) => item.id !== locationRole.id);
    }
    setAccountUserRoleList(updatedList);
    onActionPerformed?.(ACCOUNTS_AND_ROLES_ACTION_CONST.LOCATION_AND_ROLE,updatedList);
  };
  const addUpdateFieldValue = (index: number, code: string, value: any) => {
    const locationRolesList  = [...accountUserRoleList]
    let selectedUserRoleObj = {...locationRolesList[index]};
    switch (code) {
      case ACCOUNTS_AND_ROLES_ACTION_CONST.PRACTICE_NAME:
        if (value.locationUuid) {
          const externalUserId =
            accountUserRoleList.find(
              (item) => item.locationGroupId === value.locationGroupId
            )?.externalUserId || '';
          selectedUserRoleObj.locationId = value.locationId
          selectedUserRoleObj.locationUuid = value.locationUuid
          selectedUserRoleObj.locationGroupId = value.locationGroupId
          selectedUserRoleObj.externalUserId = externalUserId
          {/* /TEMPORARY PLACED THIS: WILL REMOVE IN SEPTEMBER RELEASE/ */}
          selectedUserRoleObj.rolesToAdd = stateData.selectedUserRoles
          selectedUserRoleObj.editedRoles = stateData.selectedUserRoles
          locationRolesList[index] = selectedUserRoleObj;
        } else {
          locationRolesList[index] =  {}
        }
        setAccountUserRoleList([...locationRolesList]);
        onActionPerformed?.(ACCOUNTS_AND_ROLES_ACTION_CONST.LOCATION_AND_ROLE,locationRolesList);
        break;
      case ACCOUNTS_AND_ROLES_ACTION_CONST.EHR_USER:
        let updatelocationEhr = locationRolesList;
        if (!value?.externalUserId) {
          selectedUserRoleObj.externalUserId = '';
          updatelocationEhr[index] = selectedUserRoleObj
        } else {
          updatelocationEhr = locationRolesList.map((locationRole) => {
            return locationRole.locationGroupId === value.locationGroupId
              ? {...locationRole, externalUserId: value.externalUserId}
              : {...locationRole};
          });
        }
        setAccountUserRoleList([...updatelocationEhr]);
        onActionPerformed?.(ACCOUNTS_AND_ROLES_ACTION_CONST.LOCATION_AND_ROLE,updatelocationEhr);
        break;
      case ACCOUNTS_AND_ROLES_ACTION_CONST.ROLES:
        selectedUserRoleObj = {...locationRolesList[index]};
        selectedUserRoleObj.rolesToAdd = value.rolesToAdd;
        selectedUserRoleObj.editedRoles = value.rolesToAdd;
        locationRolesList[index] = selectedUserRoleObj;
        setAccountUserRoleList([...locationRolesList]);
        onActionPerformed?.(ACCOUNTS_AND_ROLES_ACTION_CONST.LOCATION_AND_ROLE,locationRolesList);
        break;
      case ACCOUNTS_AND_ROLES_ACTION_CONST.UPDATE_ALL_LOCATION_ROLES:
        {/* /TEMPORARY PLACED CASE: WILL REMOVE IN SEPTEMBER RELEASE/ */}
        const updateLocationRoleList = locationRolesList.map((locationRole)=> {
          return {
            ...locationRole,
            rolesToAdd: value,
            editedRoles: value
          }
        })
        setAccountUserRoleList([...updateLocationRoleList]);
        onActionPerformed?.(ACCOUNTS_AND_ROLES_ACTION_CONST.LOCATION_AND_ROLE,updateLocationRoleList);
        break;
      default:
        break;
    }
  };
  const getLocationDataByAccount = async (offSet?: number) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const locationData = await getLocations({
      variables: {
        accountUuid: accountUuid
      },
    });
    if (locationData?.data?.accountLocations?.length) {
      const formattedData = getFormattedAccountLocationList(
        locationData?.data?.accountLocations
      );
      setStateData(prev=> {
        return {
          ...prev,
          accountLocation: formattedData,
          loading: false
        }
      })
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          accountLocation: [],
          loading: false,
        };
      });
    }
  };
  
  const getAccountRolesFunc = async () => {
    setStateData(prev => ({
      ...prev,
      roleLoading: true,
    }));

    const globalUserRolesResponse = await  getAccountRoles({
      variables: {
        category: [MLOV_CATEGORY.GLOBAL_USER_ROLES]
      }
    })
    const userRolesResponse = await  getAccountRoles({
      variables: {
        category: [MLOV_CATEGORY.USER_ROLES]
      }
    })

    let userRoles: IUserRoleMlov[] = [];
    let globalRoles: IUserRoleMlov[] = [];
  
    if (userRolesResponse.data?.accountRoles?.length) {
      userRoles = processRoles(userRolesResponse.data.accountRoles, USER_ROLE_CODES.EMPLOYER);
    }
  
    if (globalUserRolesResponse.data?.accountRoles?.length) {
      globalRoles = processRoles(globalUserRolesResponse.data.accountRoles, USER_ROLE_CODES.EMPLOYER);
    }
  
    setStateData(prev => ({
      ...prev,
      userRoles,
      globalRoles,
      roleLoading: false
    }));
  };

  useEffect(() => {
    setStateData(prev=> {
      return {
        ...prev,
        selectedGlobalRoles: selectedGlobalRoles || [],
      }
    })
    onActionPerformed?.(ACCOUNTS_AND_ROLES_ACTION_CONST.GLOBAL_USER_ROLES,selectedGlobalRoles);
    if (associatedAccounts?.length) {
      setCount(associatedAccounts?.length + 1);
      setAccountUserRoleList(() => {
        return [...associatedAccounts];
      });
      onActionPerformed?.(ACCOUNTS_AND_ROLES_ACTION_CONST.LOCATION_AND_ROLE,associatedAccounts);
      setStateData(prev=> {
        return {
          ...prev,
          selectedGlobalRoles: selectedGlobalRoles || [],
          selectedUserRoles: getUserAccountRoleId(associatedAccounts)
        }
      })
    } else {
      setAccountUserRoleList([
        {
          id: 1,
          accountUuid: '',
          accountId: 0,
          externalUserId: '',
          rolesToAdd: [] as string[],
          rolesToDelete: [] as string[],
          roles: [] as string[],
          editedRoles: [] as string[],
          locationUuid: '',
          locationId: 0,
          isDeleted: false
        },
      ]);
      setCount(2);
    }
  }, [associatedAccounts?.length]);

  useEffect(()=> {
    getLocationDataByAccount();
    getAccountRolesFunc()
  },[])
  return (
    <View>
      {!stateData.loading && !stateData.roleLoading && stateData.userRoles?.length > 0 && 
        (<><ModalActionAntSelect
            mode="multiple"
            value={stateData.selectedGlobalRoles}
            allowClear={true}
            label={'globalRoles'}
            placeholder="Global Roles"
            errors={errors?.userRoleRequiered}
            errorText={errors?.userRoleRequiered}
            onClear={() => {
              setStateData(prev=> {
                return {
                  ...prev,
                  selectedGlobalRoles: []
                }
              })
            }}
            onChange={(value: any) => {
              setStateData(prev=> {
                return {
                  ...prev,
                  selectedGlobalRoles: value
                }
              })
              onActionPerformed?.(ACCOUNTS_AND_ROLES_ACTION_CONST.GLOBAL_USER_ROLES,value);
            }}
            filterOption={(input: string, option: any) => {
              return (option?.children || '')
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
            data={stateData.globalRoles}
            optionProps={{key: 'id', value: 'id', label: 'value'}}
            extraStyle={{flex: 1,
              fontColor: '#000',
              fontWeight: 400,
              fontSize: 14,
            }}
          />
        {/* /TEMPORARY PLACED WILL REMOVE IN SEPTEMBER RELEASE/ */}
       <ModalActionAntSelect
        marginTop={2}
        value={stateData.selectedUserRoles}
        mode="multiple"
        isRequired={false}
        errors={errors?.userRoleRequiered}
        errorText={errors?.userRoleRequiered}
        disabled={isDisabled}
        allowClear={true}
        label={'roles'}
        placeholder="Please select"
        onChange={(value: any) => {
          setStateData(prev=> {
            return {
              ...prev,
              selectedUserRoles: value
            }
          })
          addUpdateFieldValue(
            -1,
            ACCOUNTS_AND_ROLES_ACTION_CONST.UPDATE_ALL_LOCATION_ROLES,
            value
          );
        }}
        filterOption={(input: string, option: any) => {
          return (option?.children || '')
            ?.toLowerCase()
            ?.includes(input?.toLowerCase());
        }}
        data={stateData?.userRoles}
        optionProps={{key: 'id', value: 'id', label: 'value'}}
        extraStyle={{
          flex: 1,
          fontColor: '#000',
          fontWeight: 400,
          fontSize: 14}}
      />
      </>
      )
    }
      <ModalActionSubTitle marginBottom={5} marginTop={5} hideDivider={true} subTitle={'Locations'} />
      {!stateData.loading &&
        !stateData.roleLoading &&
        stateData.userRoles?.length > 0 &&
        accountUserRoleList?.map(
          (singleAccountRoleObj: IAccountRole, index) => {
            if (singleAccountRoleObj?.isDeleted && isEditMode) {
              return <></>;
            }
        return (
          <View
          key={`${singleAccountRoleObj.locationUuid}_${index}`}
            style={{position: 'relative'}}
          >
            <Pressable
              onPress={() => {
                removePractice(singleAccountRoleObj);
              }}
              style={{
                position: 'absolute',
                right: 12,
                top: 12,
                zIndex: 99,
                display: (!props.isDisabled && accountUserRoleList.length > 1) || isEditMode  ? 'flex' : 'none',
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <View
                  style={{
                    width: 1.5,
                    backgroundColor: Colors.Custom.Gray200,
                    marginHorizontal: 8,
                    height: 20,
                  }}
                />
                <AntIcon name="close" size={20} color={Colors.Custom.Gray500} />
              </View>
            </Pressable>
            <AddAccountRoleView
              userRoles={ stateData.userRoles}
              isEditMode={isEditMode}
              isDisabled={isDisabled}
              accountUserRoleList={accountUserRoleList}
              accountLocations={getFilteredLocations({
                locationUuid: singleAccountRoleObj.locationUuid,
                masterLocation: stateData.accountLocation,
                locationAndRolesList: accountUserRoleList
              })}
              addUpdateFieldValue={(code: string, data?: any) => {
                addUpdateFieldValue(index, code, data);
              }}
              singleAccountRoleObj={singleAccountRoleObj}
            />
          </View>
        );
      })}
      <Pressable
        onPress={() => {
          addPractice();
        }}
        display={
          (stateData.loading || stateData?.roleLoading) ||
          isDisabled ||
          ( validAccountUserLocation(accountUserRoleList).length === stateData.accountLocation.length) ||
          !isAddPracticeBtnActive(accountUserRoleList)
            ? 'none'
            : 'flex'
        }
      >
        <HStack
          style={{
            justifyContent: 'flex-start',
          }}
        >
          <AntIcon name="plus" size={15} color={Colors.FoldPixel.PRIMARY300} />
          <Text color={Colors.FoldPixel.PRIMARY300}>{'Add Location'}</Text>
        </HStack>
      </Pressable>
      {(stateData.loading || stateData?.roleLoading) && <Skeleton></Skeleton>}
    </View>
  );
};

export default AssociatedAccountView;
