export enum FormStatus {
  pending,
  received,
}

export enum AppointmentAction {
  reschedule = 'RESCHEDULE',
  cancel = 'CANCEL',
  joinMeeting = 'JOIN_APPOINTMENT',
  checkInPatient = 'CHECK_IN_PATIENT',
  unblock = 'UNBLOCK',
  noShow = 'NO_SHOW',
  checkOutPatient = 'CHECK_OUT_PATIENT',
  schedule = 'SCHEDULE',
  editSeries = 'EDIT_SERIES',
  editOccurence = 'EDIT_OCCURENCE',
  removeSeries = 'REMOVE_SERIES',
  removeOccurence = 'REMOVE_OCURENCE',
  editBlockSeries = 'EDIT_BLOCK_SERIES',
  editBlockOccurence = 'EDIT_BLOCK_OCCURENCE',
  removeBlockSeries = 'REMOVE_BLOCK_SERIES',
  removeBlockOccurence = 'REMOVE_BLOCK_OCURENCE',
  convertAWV = 'CONVERT_AWV'
}

export enum AppointmentDetailField {
  appointmentType, dateTime, primaryUser, secondaryUser, reasonForVisit, location
}

export enum AppointmentAPIErrorCodes {
  APPT_MEMBER_LIMIT_REACHED = 'APPT_MEMBER_LIMIT_REACHED',
}


export enum SelectSlotsBy {
  Provider = 'Provider',
  Date = 'Date',
}