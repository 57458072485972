import React from 'react';
import {useAppointmentDataContext} from '../../../contexts/AppointmentDataContext';
import Stack from '../../../../../../../LayoutComponents/Stack';
import {ModalActionDatePicker} from '../../../../../../../ModalActionCommonComponent/ModalActionDatePicker';
import {
  getCurrentTimeZone,
  getMomentObj,
  isPastDayOfCalendar,
} from '../../../../../../../../../utils/DateUtils';
import {DISPLAY_DATE_FORMAT} from '../../../../../../../../../constants';
import SlotView from '../SlotView/SlotView';
import {isWeb} from '../../../../../../../../../utils/platformCheckUtils';

const SelectSlotByDate = () => {
  const {
    appointmentBookingState,
    handleAppointmentDateChange,
    renderSecondaryUsers,
    renderNativeCalendar,
  } = useAppointmentDataContext();
  return (
    <Stack direction="column" space={8} style={{marginTop: 8}}>
      {isWeb() ? (
        <ModalActionDatePicker
          isRequired={true}
          className="custom-date-picker"
          label={'appointmentDate'}
          defaultValue={getMomentObj(appointmentBookingState.selectedDate)}
          format={DISPLAY_DATE_FORMAT}
          onChange={handleAppointmentDateChange}
          disabledDate={(current: any) => {
            return (
              current &&
              isPastDayOfCalendar(
                current,
                appointmentBookingState.selectedTimezone?.timezone ||
                  getCurrentTimeZone()
              )
            );
          }}
          value={getMomentObj(appointmentBookingState.selectedDate)}
          customStyle={{
            flex: 1,
          }}
        />
      ) : (
        renderNativeCalendar?.()
      )}
      {!appointmentBookingState.isSpecificTimeView && renderSecondaryUsers()}
      <SlotView isByDate={true} />
    </Stack>
  );
};

export default SelectSlotByDate;
