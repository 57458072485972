import {View} from "react-native";
import {PARENT_CODE} from "../../../constants";
import AddEditUser from "../../RightSideContainer/Contacts/TeamMembers/AddEditUser/AddEditUser";
import NotificationSettings from "../Notifications/NotificationSettings";
import {PREFERENCES_TAB_LIST_CODE} from "./PreserenceConst";
import {forwardRef, useImperativeHandle, useRef} from "react";
import {COMMON_ACTION_CODES} from "../../../constants/ActionConst";
import {EmailPreferenceSettings} from "./EmailPreferenceSettings";
import {IPreferenceView} from "./PreferencesInterface";
import {isAccountConfigEnabled} from "../../../utils/configUtils";
import {CONFIG_CODES} from "../../../constants/AccountConfigConst";
import AutoReply from "./AutoReply";

export const PreferenceView = forwardRef((props: IPreferenceView, ref: any) => {
  const showEmailInboxPreferencesSetting = isAccountConfigEnabled(CONFIG_CODES.EMAIL_VIEW_USER_PREFERENCE);
  const addEditUserRef = useRef<any>(null);

  useImperativeHandle(ref, () => ({
    handleSubmit() {
      if (
        props?.selectedPreferenceTab?.code ===
          PREFERENCES_TAB_LIST_CODE.ACCOUNT_AND_PROFILE
      ) {
        if (ref && ref?.current && ref?.current?.handleSubmit) {
          addEditUserRef?.current?.handleSubmit();
        }
      }
    },
    handleClose() {
      if (
        props?.selectedPreferenceTab?.code ===
        PREFERENCES_TAB_LIST_CODE.AUTO_REPLY
      ) {
        if (ref && ref?.current && ref?.current?.handleClose) {
          addEditUserRef?.current?.handleClose();
        }
      }
    },
  }));
  return <>
    <View>
      {props?.selectedPreferenceTab?.code === PREFERENCES_TAB_LIST_CODE.EMAIL_SETTINGS &&  <EmailPreferenceSettings
        selectedEmailInboxUserView={props?.selectedEmailInboxUserView}
        preferenceData={props?.preferenceData}
        preferenceDataId={props?.preferenceDataId}
        userPreferenceId={props?.userPreferenceId}
        onActionPerformed={props?.onActionPerformed}
        showEmailInboxPreferencesSetting={showEmailInboxPreferencesSetting}
      />}
      {props?.selectedPreferenceTab?.code === PREFERENCES_TAB_LIST_CODE.NOTIFICATION_SETTING && <NotificationSettings/>}
      {props?.selectedPreferenceTab?.code ===
          PREFERENCES_TAB_LIST_CODE.AUTO_REPLY && (
          <AutoReply
            ref={addEditUserRef}
            onActionPerformed={props?.onActionPerformed}
            onClose={() => {
              props?.onClose();
            }}
          />
        )}
      {props?.selectedPreferenceTab?.code === PREFERENCES_TAB_LIST_CODE.ACCOUNT_AND_PROFILE && <AddEditUser
        ref={addEditUserRef}
        roleIsDisable
        onClose={(actionCode, data) => {
          if (actionCode === COMMON_ACTION_CODES.COMPLETE) {
            props?.onActionPerformed(COMMON_ACTION_CODES.COMPLETE, data)
            return;
          }
          props?.onActionPerformed(COMMON_ACTION_CODES.LOADING_COMPLETE)
        }}
        user={{
          id: props?.userData.id,
          email: props?.userEditData.email,
          name: props?.userEditData.name,
          avatar_url: props?.userEditData.profileImage,
          userRoles: props?.userEditData?.userInfo?.userRoles,
          uuid: props?.userData.uuid,
        }}
        parentCode={PARENT_CODE.SIDE_MENU_PANEL}
        preferenceView={true}
      />
      }
    </View>
  </>
})