import React from 'react';
import {ClipPath, Defs, G, Path, Rect, Svg} from 'react-native-svg';

const CancelledStatusSvg = (props: any) => {
  return (
    <Svg width="12" height="12" viewBox="0 0 12 12" fill="none">
      <G clip-path="url(#clip0_990_36)">
        <Path
          d="M9.25 2.75L2.75001 9.24988M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
          stroke="#D92D20"
          stroke-linecap="round"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_990_36">
          <Rect width="12" height="12" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default CancelledStatusSvg;
