import React from 'react';
import {Path, Svg} from 'react-native-svg';

const FinishedStatusSvg = (props: any) => {
  return (
    <Svg width="12" height="12" viewBox="0 0 12 12" fill="none">
      <Path
        d="M2 6.44998L3.57143 8.24998L7.5 3.74998M10.0001 3.78125L5.71426 8.28125L5.5001 8"
        stroke="#12B76A"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Svg>
  );
};

export default FinishedStatusSvg;
