import { View, Text, Pressable, FlatList, Dimensions } from 'react-native'
import React, { useEffect, useState } from 'react'
import { Modal, Skeleton } from 'antd'
import { SearchBar } from '../../../../../../common/SearchBar'
import { IRule } from '../interfaces';
import { Colors } from '../../../../../../../styles';
import Stack from '../../../../../../common/LayoutComponents/Stack';
import FoldButtonV2, { ButtonType } from '../../../../../../PersonOmniView/MiddleContainer/CarePlan/components/FoldButtonV2';
import { useIntl } from 'react-intl';
import CrossIconSvg from '../../../../../../common/Svg/CrossIconSvg';
import './ContactRuleTemplateSearchModal.css';
import { NoCarePlan } from '../../../../../../PersonOmniView/MiddleContainer/CarePlan/components/NoCarePlan';
import { getRuleTemplates } from '../../../../../ContentManagement/CampaignManagement/GroupDetails/PopulationGroupApi';
import { ToastType } from '../../../../../../../utils/commonViewUtils';
import { useToast } from '../../../../../../Toast/ToastProvider';
import ClipBoardSvg from '../../../../../../common/Svg/ClipBoardSvg';
import { debounce } from 'lodash';
import { transformTemplateDataToRules } from './ContactRulesHelper';
import { getGroupDetailById } from '../../../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { Drawer } from 'antd';
import RuleTemplateSvg from '../../../../../../common/Svg/CarePlanSvgs/RuleTemplateSvg';
import { HStack, Spinner } from 'native-base';
import { FoldButton } from '../../../../../../CommonComponents/FoldButton/FoldButton';
import Feather from 'react-native-vector-icons/Feather';
import { BUTTON_TYPE } from '../../../../../../../constants';
import StarsSvg from '../../../../../../common/Svg/StarsSvg';
import { testID } from '../../../../../../../testUtils';

interface IContactRuleTemplateSearchModalProps {
  open: boolean;
  onCancel: () => void;
  onAddNew: (searchText: string) => void;
  onTemplateSelect: (rule: IRule) => void;
}

const ContactRuleTemplateSearchModal = (props: IContactRuleTemplateSearchModalProps) => {
  // Constants
  const { open, onAddNew, onTemplateSelect, onCancel } = props;
  const intl = useIntl();
  const toast = useToast();
  const { height } = Dimensions.get('window');

  // States
  const [componentState, setComponentState] = useState<{
    loading: boolean;
    selectedTemplate?: IRule;
    templates: IRule[];
    searchText: string;
    selectedTemplateRuleId?: string;
    openState?: boolean;
  }>({
    loading: false,
    selectedTemplate: undefined,
    templates: [],
    searchText: '',
    selectedTemplateRuleId: undefined,
    openState: open
  });

  // APIs
  const fetchTemplates = async (searchString: string) => {
    setComponentState((prev) => ({...prev, templates: [], loading: true}));
    try {
      const response = await getRuleTemplates({
        name: searchString,
        limit: 20,
        offset: 0,
      });
      const templates: IRule[] = transformTemplateDataToRules(response?.data?.data) || [];
      setComponentState((prev) => ({...prev, templates: templates, loading: false}));
    } catch(error) {
      toast({
        message: 'Something went wrong. Please try again later',
        toastType: ToastType.error,
        closeAllPrevToast: true,
      });
      setComponentState((prev) => ({...prev, templates: [], loading: false}));
    }
  }

  // Lifecycle methods
  useEffect(() => {
    if (open) {
      fetchTemplates('');
    }
  }, [open])

  // Other methods
  const onSearch = (text: string) => {
    fetchTemplates(text);
    setComponentState((prev) => ({...prev, searchText: text }))
  }

  const onSave = async (template: IRule) => {
    if (template?.ruleId) {
      try {
        setComponentState((prev) => ({...prev, loading: true, selectedTemplateRuleId: template?.ruleId}));
        const response = await getGroupDetailById(template?.ruleId);
        template.displayJson = response.data?.displayJson;
        template.rule = response.data?.rule;
        setComponentState((prev) => ({...prev, loading: false, selectedTemplateRuleId: undefined, openState: false}));
        onTemplateSelect?.(template);
      } catch (error) {
        setComponentState((prev) => ({...prev, loading: false, selectedTemplateRuleId: undefined, openState: false}));
        toast({
          message: 'Something went wrong. Please try again later',
          toastType: ToastType.error,
          closeAllPrevToast: true,
        });
      }
    }
  }

  const renderTemplateRow = (data: { item: IRule }) => {
    const item = data.item;
    const isSelected = componentState.selectedTemplate?.ruleId === item.ruleId;
    return (
      <Pressable onPress={() => { onSave(item) }} style={{ marginBottom: 8 }} disabled={componentState.loading} {...testID('AutomationItemWhileSettingTargetAndRule')}>
        <Stack
          direction='row'
          space={12}
          style={
            {
             alignItems: 'center',
             paddingVertical: 10,
             paddingHorizontal: 8,
             borderWidth: 0.5,
             borderColor: isSelected ? Colors.Custom.PrimaryColor200 : Colors.FoldPixel.GRAY100,
             backgroundColor: isSelected ? Colors.Custom.purpleBackgroundContainer : Colors.Custom.White,
             borderRadius: 8,
            }
          }>
          <RuleTemplateSvg size={24} customStrokeColor={isSelected ? Colors.FoldPixel.PRIMARY300 : Colors.Custom.Gray500} />
          <Stack direction='column' space={4} style={{ flex: 1 }}>
            <Text style={{ color: isSelected ? Colors.FoldPixel.PRIMARY300 : Colors.Custom.Gray500 }}>{item.name}</Text>
            <Text style={{ color: Colors.Custom.Gray400, fontSize: 12 }}>{item.description}</Text>
          </Stack>
          {componentState.loading && (componentState.selectedTemplateRuleId === item.ruleId) && <Spinner size="sm" color={Colors.Custom.Gray500} />}
        </Stack>
      </Pressable>
    );
  }

  const renderEmptyComponent = () => {
    if (componentState.loading) {
      return <View style={{ padding: 16 }}><Skeleton /></View>
    }
    return (
      <View style={{ marginTop: 44, justifyContent: 'center' }}>
        <NoCarePlan title="noTemplatesFound" />
        <View style={{ width: '100%', alignItems: 'center', paddingTop: 6 }}>
          {getAddNewButton()}
        </View>
      </View>
    )
  }

  const getAddNewButton = () => {
    return (
      <FoldButton
        customProps={{
          btnText: intl.formatMessage({ id: 'createNew' }),
        }}
        nativeProps={{
          leftIcon: (
            <Feather
              name="plus"
              color={Colors.Custom.mainPrimaryPurple}
              size={14}
            />
          ),
          backgroundColor: Colors.Custom.BackgroundColor,
          variant: BUTTON_TYPE.PRIMARY,
          color: Colors.Custom.mainPrimaryPurple,
          onPress: () => {
            onAddNew(componentState.searchText);
          },
        }}
      />
    );
  }

  const renderTitle = () => {
    return (
      <Stack direction='row' space={8} style={{alignItems: 'center', justifyContent: 'space-between'}}>
        <Text style={{ fontSize: 24, fontWeight: '500', color: Colors.Custom.Gray800 }}>
          {'Set Target & Rule'} 
        </Text>
        <Stack direction='row' space={12} style={{ alignItems: 'center' }}>
          <Pressable key={'CrossButton'} onPress={onCancel}>
            <CrossIconSvg size={18} />
          </Pressable>
        </Stack>
      </Stack>
    );
  }

  return (
    <Drawer
      open={componentState?.openState}
      footer={false}
      width={'40%'}
      closeIcon={null}
      closable={false}
      maskClosable={true}
      destroyOnClose
      onClose={onCancel}
      title={renderTitle()}
    >
      <Stack direction="column">
        <Stack direction="row" space={8} style={{ paddingVertical: 6, alignItems: 'center', justifyContent: 'space-between'}}>
          <SearchBar width={'100%'} placeholderText="Search rule alert templates" onChange={debounce(onSearch, 500)} />
        </Stack>
        <Stack direction="column" space={8} style={{ paddingTop: 8 }}>
          <HStack mb={2} alignItems="center" justifyContent="flex-end">
            {/* When we can filter the rules (like based on BMI) and the filtering is properly handled then only un-comment below code and change above justifyContent="space-between" */}
            {/* <Text style={{ color: Colors.Custom.Gray500, fontSize: 14 }}>
              <StarsSvg />
              {' Few rule Suggestions based on BMI'}
            </Text> */}
            {componentState.templates.length > 0 && (
              getAddNewButton()
            )}
          </HStack>
          <FlatList
            data={componentState.templates}
            renderItem={renderTemplateRow}
            keyExtractor={(item) => `rule_template_${item?.ruleId}`}
            ListEmptyComponent={renderEmptyComponent()}
            style={{ height: height * 0.77 }}
          />
        </Stack>
      </Stack>
    </Drawer>
  )
}

export default ContactRuleTemplateSearchModal
