import React from "react";
import { Svg, G, Path, Defs } from "react-native-svg";

function EditPenSvg() {
    return (
        <Svg
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
        >
            <G clipPath="url(#clip0_2314_2455)">
                <Path
                    stroke="#8C5AE2"
                    d="M9.574 2.719l.617-.618A2.622 2.622 0 1113.9 5.81l-.618.618M9.574 2.719s.077 1.313 1.235 2.472c1.159 1.158 2.472 1.236 2.472 1.236M9.574 2.719l-5.681 5.68c-.385.386-.577.578-.743.79a4.37 4.37 0 00-.499.808c-.116.242-.202.5-.374 1.017L1.548 13.2m11.733-6.774l-5.68 5.68c-.386.385-.578.578-.79.743-.25.195-.521.363-.808.499-.242.116-.5.202-1.017.374l-2.187.729m0 0l-.535.178a.707.707 0 01-.894-.894l.178-.535m1.25 1.251l-1.25-1.25"
                ></Path>
            </G>
            <Defs>
                <clipPath id="clip0_2314_2455">
                    <Path fill="#fff" d="M0 0H16V16H0z"></Path>
                </clipPath>
            </Defs>
        </Svg>
    );
}

export default EditPenSvg;
