import {useLazyQuery} from '@apollo/client';
import {Drawer, notification, Popover, Select} from 'antd';
import {
  Center,
  FormControl,
  HStack,
  Image,
  Pressable,
  Spinner,
  Text,
  useMediaQuery,
  useToast,
  View,
  VStack
} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import {
  BUTTON_TYPE,
  CREATE_CHANNEL_CONFIRMATION_MODAL,
  DEFAULT_COMMUNICATION_GROUP_NAME,
  DEFAULT_GENERAL_GROUP,
  GROUP_MEMBER_TYPE,
  GROUP_TYPES,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
  MLOV_CATEGORY
} from '../../../constants';
import {
  COMMON_ACTION_CODES,
  CONVERSATION_ACTION_CODES,
  GROUP_ACTION_CODES
} from '../../../constants/ActionConst';
import {GROUP_TYPE_CODES, USER_ROLE_CODES} from '../../../constants/MlovConst';
import {CONVERSATION_LOCAL_EVENT_CODES, SUPPORTED_EVENT_CODE, WEB_SOCKET_EVENT_CODE} from '../../../constants/WebSocketConst';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {IUserRoleCode} from '../../../Interfaces';
import {LeadQueries} from '../../../services';
import BaseService from '../../../services/CommonService/BaseService';
import ContactRelation from '../../../services/ContactRelation/ContactRelation';
import ConversationsQueries from '../../../services/Conversations/ConversationsQueries';
import {IGroupContact, IGroupMember, IGroupUser} from '../../../services/Conversations/interfaces';
import InboxQueries from '../../../services/Inbox/InboxQueries';
import {ITeamResp} from '../../../services/Team/interfaces';
import TeamQueries from '../../../services/Team/TeamQueries';
import {Colors} from '../../../styles';
import {
  getAccountId,
  getAccountUUID,
  getAllowedUserPracticeLocationUuids,
  getCurrentUserRole,
  getFeatureFlag,
  getUserActionObjectFromActionCode,
  getUserData,
  getUserId,
  getUserUUID,
  isEmployerRole
} from '../../../utils/commonUtils';

import {showToast, ToastType} from '../../../utils/commonViewUtils';
import {EventBus} from '../../../utils/EventBus';
import {
  getContactTypeId,
  getMlovId,
  getMlovIdFromCode,
  getMlovListFromCategory
} from '../../../utils/mlovUtils';
import {patientNotLoggedInError} from '../../RightSideContainer/TeamInbox/Conversations/ConversationContainer/ConversationConst';
import {IConversationData, INewConversationResponse} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {
  getAddedAndRemovedContactIDs,
  getAddedAndRemovedUserIDs,
  getGroupMemberUserOrContactIdListByTypeCode,
  getMembersIdsAndContactsFromGroupMember,
  getPatientDataFromGroupMember,
  isGroupConversation,
  isLoginUserOwnerOfCurrentGroup,
  isPrivateGroupConversation
} from '../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingUtils';
import {USER_PERMISSIONS_CONST} from '../../RightSideContainer/UserAccess/UserPermissionConst';
import {DisplayText} from '../DisplayText/DisplayText';
import {ModalActionAntSelect} from '../ModalActionCommonComponent/ModalActionAntSelect';
import {ModalActionCheckbox} from '../ModalActionCommonComponent/ModalActionCheckBox';
import {ModalActionInput} from '../ModalActionCommonComponent/ModalActionInput';
import {ModalActionTitle} from '../ModalActionTitle/ModalActionTitle';
import PatientSearchAndSelect from '../PatientSearch/PatientSearchAndSelect';
import {handleChatWithTeammateAction} from '../UsageLevel/UsageLevelHandlers';
import {getGroupInternalUserUuids, getUpdatedErrorMessages, leaveGroupApi} from './CreateChannelUtils';
import {Patient_Segment_Group} from './DUMMYDATA';
// import {debounce} from 'lodash';
import {
  IConfirmationModal,
  IContact,
  ICreateChannelData,
  ICreateChannelView,
  ICreateGroupBodyParams,
  IFormData,
  ISupportingContact,
  IUpdateGroupBodyParams,
  IUserList,
  IUserOption
} from './interface';
import {COMMON_VIEW_CONST, form_Input_Field} from './StringConst';
import {IParticipantSearch} from '../CalendarWidget/ParticipantAutoComplete/ParticipantInterfaces';
import { CONFIG_CODES } from '../../../constants/AccountConfigConst';
import {unAssignConversationAPI} from '../../RightSideContainer/TeamInbox/Conversations/ConversationChannelNew/ConversationAPIService';
import {sendConversationNotificationNoMessageEvent} from '../../../services/SendNotification';
import RemoveMemberConfirmation from './RemoveMemberConfirmation';
import {isAccountConfigEnabled} from '../../../utils/configUtils';
import OverrideAntdModal from '../../RightSideContainer/ContentManagement/OverrideModal/OverrideAntdModal';
import { ParticipantType } from '../CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import {localBroadcastEvent} from '../../../utils/CustomEventHandler';
import { CustomUserSearch } from '../CustomUserSearch/CustomUserSearch';
import { IInternalUsers } from '../CustomUserSearch/interface';
import LogoutSvg from '../Svg/LogoutSvg';
import UserListView from '../../RightSideContainer/TeamInbox/Conversations/ConversationActionView/UserListView';
import { getGroupMembersListForAssignee } from '../../RightSideContainer/TeamInbox/Conversations/ConversationChannelNew/ConversationSidebarUtils';
import LeaveGroupView from './LeaveGroupView';
import { testID, TestIdentifiers } from '../../../testUtils';
import ConversationsQueriesV2 from '../../../services/Conversations/V2/ConversationsQueriesV2';
import { USER_SEARCH_TYPE } from '../../RightSideContainer/constants';
import { USER_ACCESS_PERMISSION } from '../../RightSideContainer/UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../SideMenuBar/SideBarConst';
const {Option} = Select;
const CreateChannelView = (props: ICreateChannelView) => {
  const createGroupToast = useToast();
  const intl = useIntl();
  const {
    isDrawerVisible,
    onCreateChannelActionPerformed,
    selectedConversation,
    selectedTabCode,
  } = props;
  const {width} = Dimensions.get('window');
  const accountId = getAccountId();
  const accountUuid = getAccountUUID();
  const userId = getUserId();
  const useruuid = getUserUUID();
  const currentUserData = getUserData();
  const mlovData = useContext(CommonDataContext);
  const currentUserRoles = getCurrentUserRole();
  // const contactTypeUuid = getContactTypeId('CUSTOMER');
  const contactTypeUuid = getContactTypeId('CUSTOMER');
  const isPatientMessagingEnabled = isAccountConfigEnabled(CONFIG_CODES.PATIENT_MESSAGING_ENABLED);
  const isBroadcastMessagingEnabled = getFeatureFlag(CONFIG_CODES.ENABLE_BROADCAST_MESSAGING, mlovData);
  const isSideCarContext = mlovData.sidecarContext?.isSidecar;
  const showOnlyInternalChatInSidecar = isAccountConfigEnabled(CONFIG_CODES.SHOW_ONLY_INTERNAL_CHAT_IN_SIDECAR);
  const isCommunicationLocationHandlingEnabled = isAccountConfigEnabled(CONFIG_CODES.ENABLE_COMMUNICATION_LOCATION_HANDLING);
  const allowedUserPracticeLocationUuids = getAllowedUserPracticeLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.INBOX
  );
  const [createChannelStateData, setCreateChannelStateData] = useState({
    formData: {
      sendEmailTo: true,
      patientSegment: 'All',
      supportingContact: [] as string[],
      channelName: '',
      showLoginError: false,
      selectedPatient: [] as IContact[],
    } as IFormData,
    internalUsers: [] as IUserList[],
    selectedInternalUsers:[] as IInternalUsers[],
    patientLists: [] as IContact[],
    loadingStates: true as boolean,
    loadingPatientData: false as boolean,
    type: !isPatientMessagingEnabled
      ? GROUP_TYPE_CODES.INTERNAL
      : GROUP_TYPE_CODES.PRIVATE,
    supportingContact: [] as ISupportingContact[],
    searchString: '' as any,
    errors: {},
    confirmationModal: {} as IConfirmationModal,
    tempChannelName: '',
    showLeaveChatConfirmation: false,
    selectedGroupOwner: {} as IGroupUser,
    showInternalUserPopover: false,
  } as ICreateChannelData);
  const [internalUsersSelectedByDefaultInState, setInternalUsersSelectedByDefaultInState] = useState<string[]>()
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [getAccountUsers] = useLazyQuery(InboxQueries.GetAgents, {
    fetchPolicy: 'no-cache',
  });
  const [getPatientList] = useLazyQuery(LeadQueries.GetContactsForGroup, {
    fetchPolicy: 'no-cache',
  });
  const [getInternalUsersDataByUUIDs] = useLazyQuery(InboxQueries.GetInternalUsersByUUIDs,{
    fetchPolicy: 'no-cache',
  });
  const [getSupportingMembers] = useLazyQuery(
    ContactRelation.GetRelativeContact
  );
  const [getSupportingMembersByLocationUuids] = useLazyQuery(
    ContactRelation.GetRelativeContactByLocationUuids
  );
  const [getConversationByConversationId] =
    useLazyQuery<INewConversationResponse>(
      ConversationsQueries.GetConversationByConversationId,
      {
        fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      }
    );
  const [getDefaultCommunicationGroup] = useLazyQuery<ITeamResp>(TeamQueries.GetCommunicationGroupTeams, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    variables: {
      accountUuid: accountUuid,
      name: DEFAULT_COMMUNICATION_GROUP_NAME
    },
  });

  const [GetGroupMembersByConversationUuid] = useLazyQuery(
    ConversationsQueriesV2.GetGroupMembersByConversationUuid,
    {
      variables: {
        conversationUuid: selectedConversation?.uuid
      },
      fetchPolicy: 'no-cache',
    }
  )
  const groupTypeList =
    getMlovListFromCategory(mlovData?.MLOV, MLOV_CATEGORY?.GroupType) || [];
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const finalWidth = isSideCarContext
    ? '100%'
    : isIPadScreen || isIPadMiniScreen
    ? width / 2
    : width / 3;

  const onCancel = () => {
    onCreateChannelActionPerformed(CONVERSATION_ACTION_CODES.DRAWER_CLOSE);
  };

  const isGroupChannel = (): boolean => {
    if (selectedConversation) {
      return isGroupConversation(selectedConversation);
    }
    return false;
  };

  const isAllowedEditAccess = () => {
    if (
      isGroupChannel() &&
      (selectedConversation?.groupConversation?.ownerId === useruuid ||
        isLoginUserOwnerOfCurrentGroup(currentUserRoles as IUserRoleCode[]))
    ) {
      return true;
    } else if (!isGroupChannel()) {
      return true;
    }
    return false;
  };

  const isFamilyMemberDisabled = () => {
    if (!isGroupChannel() && !isPatientSelected()) {
      return true;
    } else if (isGroupChannel() && !isAllowedEditAccess()) {
      return true;
    }
    return false;
  };


  const memberObj = (supportingContact: ISupportingContact[]) => {
    const newObj = supportingContact.map((member: ISupportingContact) => {
      return member?.realatedContact;
    });
    return newObj;
  };

  const isPatientSearchAndSelectDisabled = () => {
    let isDisabled = false;
    if (isGroupConversation(props.selectedConversation || {} as any)
      && isPrivateGroupConversation(props.selectedConversation || {} as any)
      && createChannelStateData?.formData?.patient?.id) {
      isDisabled = true;
    }
    if (props?.contactData?.uuid) {
      isDisabled = true;
    }
    return isDisabled;
  }

  const getGroupNameByPatientName = (patientName: string, channelName: string) => {
    if (patientName  && channelName !== patientName) {
        return patientName
    }
    return channelName;
  };

  const onPatientSearchAndSelectSegmentChange = async (participant: IParticipantSearch[]) => {
    const newParticipants: IContact[] = [];
    let showLoginError = false;
    participant.map((allParticipants: IParticipantSearch) => {
      const contactData =
        allParticipants?.contactData ||
        allParticipants?.label?.props?.contactData;
      if (contactData?.uuid) {
        newParticipants.push(contactData);
        if (!showLoginError) {
          showLoginError = contactData?.additionalAttributes
            ?.loggedInTime
            ? false
            : true;
        }
      }
    });
    createChannelStateData.formData.selectedPatient = newParticipants;
    setCreateChannelStateData((prev) => {
      return {
        ...prev,
        formData: {
          ...createChannelStateData.formData,
          showLoginError: showLoginError,
          selectedPatient: newParticipants,
        },
        errors: {
          ...prev.errors,
          patient: showLoginError ? patientNotLoggedInError : '',
        },
      };
    });
  }

  const getPatientMultiselectData = () => {
    const selectedPatientsData =
      createChannelStateData.formData?.selectedPatient?.map((item) => ({
        value: item?.uuid,
        type: ParticipantType.patient,
        email: item?.email,
        contactId: item?.id,
        key: item?.uuid,
        label: item?.name,
        contactData: item,
      }));
    return selectedPatientsData;
  };

  const onFamilyMemberChange = (value: string[]) => {
    setCreateChannelStateData((prev) => {
      return {
        ...prev,
        formData: {
          ...createChannelStateData.formData,
          supportingContact: value,
        },
      };
    });
  };

  const onSendEmailChange = (value: any) => {
    setCreateChannelStateData((prev) => {
      return {
        ...prev,
        formData: {
          ...prev.formData,
          sendEmailTo: value,
        },
      };
    });
  };

  const onPatientSearchAndSelectChange = async (participant: any) => {
    const contactData = participant?.label?.props?.contactData;
    let showLoginError = false;
    if (participant?.label?.props?.contactData) {
      showLoginError = participant?.label?.props?.contactData?.additionalAttributes?.loggedInTime ? false : true
    }
    const practiseLoactionUuid = contactData?.contactPracticeLocations?.[0]?.practiceLocationUuid
    const groupName = getGroupNameByPatientName(contactData?.name, createChannelStateData?.formData?.channelName)
    setCreateChannelStateData((prev) => {
      return {
        ...prev,
        supportingContact: [],
        formData: {
          ...createChannelStateData.formData,
          patient: contactData,
          showLoginError: showLoginError,
          channelName: groupName,
        },
        tempChannelName: contactData?.name,
        errors: {
          ...prev.errors,
          patient: showLoginError ? patientNotLoggedInError : '',
        },
      };
    });
    await getInitData();

    if (contactData?.id){
      getUserLoactionData(practiseLoactionUuid);
    }
    getSupportingMember(contactData?.id);
  };

  const onChannelNameChange = (value: any) => {
    setCreateChannelStateData((prev: any) => {
      return {
        ...prev,
        formData: {
          ...createChannelStateData.formData,
          channelName: value,
        },
        tempChannelName: value,
      };
    });
  };


  const getFormInputField = (filterCode: string) => {
    let panelElem = <></>;
    switch (filterCode) {
      case COMMON_VIEW_CONST.CHANNEL_NAME:
        panelElem = (
          <>
            <ModalActionInput
              fieldIs={'input'}
              label={'groupName'}
              isDisabled={filterCode === COMMON_VIEW_CONST.FAMILY_MEMBER}
              placeholder="Enter channel name"
              isRequired={createChannelStateData.type !== GROUP_TYPE_CODES.INTERNAL}
              isInvalid={createChannelStateData.errors?.chatNameErrorString}
              errors={createChannelStateData.errors?.chatNameErrorString}
              errorText={createChannelStateData.errors?.chatNameErrorString}
              value={createChannelStateData.formData.channelName}
              onChangeText={onChannelNameChange}
              extraStyle={{flex: 1}}
            />
          </>
        );
        break;
      case COMMON_VIEW_CONST.INTERNAL_USERS:
        panelElem =(
          getInternalUser(filterCode)
        )
        break;
      case COMMON_VIEW_CONST.PATIENTS:
        panelElem = (
          <>
            <PatientSearchAndSelect
              isRequired={true}
              label={'patient'}
              value={createChannelStateData?.formData?.patient?.name}
              showError={createChannelStateData?.formData?.showLoginError}
              errors={createChannelStateData?.errors}
              isProspect={false}
              isDisabled={isPatientSearchAndSelectDisabled()}
              placeholder="Search patients"
              showOnlyLoggedInPatients={true}
              labelInfo={intl.formatMessage({id: 'searchPatientInfo'})}
              onChange={onPatientSearchAndSelectChange}
            />
          </>
        );
        break;
      case COMMON_VIEW_CONST.SEND_MAIL:
        {
          if (isGroupChannel()) {
            panelElem = <View></View>;
          } else {
            panelElem = (
              <>{false &&
                <HStack mt={2}>
                  <VStack>
                    <ModalActionCheckbox
                      isChecked={createChannelStateData?.formData?.sendEmailTo}
                      onChange={onSendEmailChange}
                      value={'sendEmailToAll'}
                    />
                  </VStack>
                  <VStack>
                    <Text
                      style={{marginLeft: 5}}
                      size={'smMedium'}
                      color={Colors.Custom.Gray900}
                    >
                      Send email to all
                    </Text>
                  </VStack>
                </HStack>
              }</>
            );
          }
        }
        break;
      case COMMON_VIEW_CONST.FAMILY_MEMBER:
        panelElem = (
          <>
            <ModalActionAntSelect
              disabled={isFamilyMemberDisabled()}
              isDisabled={isPatientSelected()}
              value={createChannelStateData.formData.supportingContact}
              mode={'multiple'}
              allowClear={true}
              isRequired={false}
              label={'familyMember'}
              placeholder="Select patient family members"
              onChange={onFamilyMemberChange}
              data={memberObj(createChannelStateData?.supportingContact)}
              optionProps={{key: 'uuid', value: 'uuid', label: 'name'}}
              extraStyle={{flex: 1}}
            />
          </>
        );
        break;
      case COMMON_VIEW_CONST.PATIENTS_SEGMENT:
        panelElem = (
          <>
            <PatientSearchAndSelect
              label={'patients'}
              mode={'multiple'}
              value={getPatientMultiselectData()}
              disableUserSearch={true}
              disableLeadsSearch={true}
              showError={createChannelStateData?.formData?.showLoginError}
              errors={createChannelStateData?.errors}
              isProspect={false}
              isDisabled={isPatientSearchAndSelectDisabled()}
              placeholder="Search patients"
              showOnlyLoggedInPatients={true}
              labelInfo={intl.formatMessage({id: 'searchPatientInfo'})}
              onChange={onPatientSearchAndSelectSegmentChange}
            />
          </>
        );
        break;
    }
    return panelElem;
  };

  const getDefaultCommunicationGroupData = async () => {
    const usersOfDefaultMessagingChatGroup = await getDefaultCommunicationGroup({
      variables: {
        accountUuid: accountUuid,
        name: DEFAULT_COMMUNICATION_GROUP_NAME,
        where: getTeamsWhereCondition()
      }
    });

    if (usersOfDefaultMessagingChatGroup?.data?.teams?.[0]?.teamMembers.length) {
      internalUsersSelectedByDefault = usersOfDefaultMessagingChatGroup?.data?.teams?.[0]?.teamMembers?.map((teamMember) => teamMember?.userUuid)
      setInternalUsersSelectedByDefaultInState(internalUsersSelectedByDefault)
    }
  }

  const isDisabledInternalMemberField = (filterCode: string) => {
    return (
      !isAllowedEditAccess() ||
      filterCode === COMMON_VIEW_CONST.FAMILY_MEMBER ||
      (!createChannelStateData?.formData?.patient &&
        createChannelStateData.type !== GROUP_TYPE_CODES.INTERNAL)
    );
  };

  const getDisabledUsersList = ():string[] => {
    const list:string[] = [];
    if (!isGroupChannel()) {
      return list;
    }
    list.push(useruuid);
    selectedConversation?.groupConversation?.ownerId && list.push(selectedConversation?.groupConversation?.ownerId)
    return list;
  }

  const checkPatientSelected = (data: ICreateChannelData) => {
    return (
      (createChannelStateData?.formData?.patient ||
        createChannelStateData.type === GROUP_TYPE_CODES.INTERNAL) &&
        createChannelStateData?.formData?.internalUsers &&
      !createChannelStateData?.loadingStates
    );
  };

  const handleLeaveGroup = (internalUserUuids: string[]) => {
    const groupOwnerIsLoggedInUser =
      selectedConversation?.groupConversation?.ownerId === useruuid || false;
    if (
      selectedConversation?.groupConversation?.groupType?.code ===
      GROUP_TYPES.INTERNAL
    ) {
      if (internalUserUuids && internalUserUuids?.length > 2) {
        setCreateChannelStateData((prev) => {
          return {
            ...prev,
            showInternalUserPopover: groupOwnerIsLoggedInUser,
            showLeaveChatConfirmation: !groupOwnerIsLoggedInUser,
          };
        });
      } else {
        notification.info({
          message: intl.formatMessage({id: 'twoInternalUserPresentError'}),
          duration: 2.0,
        });
      }
    } else if (
      selectedConversation?.groupConversation?.groupType?.code ===
        GROUP_TYPES.PRIVATE &&
      internalUserUuids &&
      internalUserUuids?.length > 1
    ) {
      setCreateChannelStateData((prev) => {
        return {
          ...prev,
          showInternalUserPopover: groupOwnerIsLoggedInUser,
          showLeaveChatConfirmation: !groupOwnerIsLoggedInUser,
        };
      });
    } else {
      notification.info({
        message: intl.formatMessage({id: 'oneInternalUserPresentError'}),
        duration: 2.0,
      });
    }
  };

  const onLeaveAction = async() => {
    if (selectedConversation?.uuid && accountId) {
      try {
        setCreateChannelStateData((prev) => {
          return {
            ...prev,
            showLeaveChatConfirmation: false,
            loadingStates: true,
          }
        })
        const newGroupOwnerUuid = createChannelStateData?.selectedGroupOwner?.uuid;
        const bodyData = {
          conversationUuid: selectedConversation?.uuid,
          ...(newGroupOwnerUuid && {newGroupOwnerUuid: createChannelStateData?.selectedGroupOwner?.uuid}),
        }
        const groupInfo = await leaveGroupApi(bodyData, accountId)
        if (groupInfo) {
          getGroupConversationAfterUpdateOrCreate(groupInfo, true, true);
        }
      } catch (error) {
        if (isSideCarContext) {
          notification.error({
            message: intl.formatMessage({id: 'apiErrorMsg'}),
            duration: 3.0,
            placement: 'topRight',
          });
        } else {
          showToast(
            createGroupToast,
            intl.formatMessage({id: 'apiErrorMsg'}),
            ToastType.error
          );
        }
        setCreateChannelStateData((prev) => {
          return {
            ...prev,
            loadingStates: false,
          }
        })
      }
    } else {
      setCreateChannelStateData((prev) => {
        return {
          ...prev,
          loadingStates: false,
        }
      })
      if (isSideCarContext) {
        notification.error({
          message: intl.formatMessage({id: 'apiErrorMsg'}),
          duration: 3.0,
          placement: 'topRight',
        });
      } else {
      showToast(
        createGroupToast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error
      );
    }
    }
  }


  const leaveGroupElement = () => {
    const groupMembers =
      createChannelStateData?.selectedConversationGroupMembers
        ? createChannelStateData?.selectedConversationGroupMembers
        : selectedConversation?.groupConversation?.groupMembers || [];
    const internalUserUuids = getGroupInternalUserUuids(groupMembers);
    const isLoggedInUserGroupMember = internalUserUuids?.includes(useruuid);
    return (
      <HStack>
        {(internalUserUuids && isLoggedInUserGroupMember) && (
          <Popover
            overlayInnerStyle={{borderRadius: 16}}
            style={{borderRadius: 16}}
            placement={'left'}
            trigger="click"
            content={
              <LeaveGroupView selectedConversation={selectedConversation}
              onActionPerformed={(actionCode: string, actionData?: any) => {
                if (actionCode === COMMON_ACTION_CODES.ASSIGN_USER) {
                  setCreateChannelStateData((prev) => {
                    return {
                      ...prev,
                      showInternalUserPopover: false,
                      showLeaveChatConfirmation: true,
                      selectedGroupOwner: actionData
                    };
                  });
                }
              }}/>
            }
            visible={createChannelStateData.showInternalUserPopover}
            onVisibleChange={() => {
              setCreateChannelStateData((prev) => {
                return {
                  ...prev,
                  showInternalUserPopover: false,
                };
              });
            }}
          >
            <Pressable
              onPress={() => {
                handleLeaveGroup(internalUserUuids)
              }}
            >
              <HStack alignItems={'center'} marginTop={1}>
                <Text
                  paddingLeft={1}
                  color={Colors.Custom.ErrorColor}
                  fontSize={'sm'}
                  {...testID('LeaveChat')}
                >
                  {intl.formatMessage({id: 'leaveChat'})}
                </Text>
              </HStack>
            </Pressable>
          </Popover>
        )}
      </HStack>
    );
  };


  const onCustomUserSearch = (selectedUsersUUIDs: string[]) => {
    setCreateChannelStateData((prev: any) => {
      return {
        ...prev,
        formData: {
          ...createChannelStateData?.formData,
          internalUsers: selectedUsersUUIDs,
        },
      };
    });
  };

  const getInternalUser = (filterCode: string) => {
    return (
      <>
        <FormControl
          isInvalid={createChannelStateData.errors?.internalUserErrorString}
        >
          <CustomUserSearch
            label={'internalUsers'}
            isRequired={createChannelStateData.type === GROUP_TYPE_CODES.INTERNAL}
            placeholder={'Search internal users'}
            selectedUsersData={createChannelStateData.selectedInternalUsers}
            disabledUsers={getDisabledUsersList()}
            userSearchType={
              isCommunicationLocationHandlingEnabled
                ? createChannelStateData?.type === GROUP_TYPE_CODES.PRIVATE
                  ? USER_SEARCH_TYPE.CURRENT_SELECTED_PATIENT_BASED
                  : USER_SEARCH_TYPE.FILTERED_CURRENT_USER_LOCATION_BASED
                : USER_SEARCH_TYPE.FILTERED_CURRENT_USER_BASED
            }
            selectedPatientLocationUuid={
              createChannelStateData.formData.patient
                ?.contactPracticeLocations?.[0]?.practiceLocationUuid
            }
            isDisabled={isDisabledInternalMemberField(filterCode)}
            userSelectRef={undefined}
            onChange={onCustomUserSearch}
            filterLoggedInUser={true}
          />

          {createChannelStateData.errors?.internalUserErrorString && (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
              {...testID(TestIdentifiers.errorMessage)}
            >
              {createChannelStateData.errors?.internalUserErrorString}
            </FormControl.ErrorMessage>
          )}
          {isGroupChannel() && (
           leaveGroupElement()
          )}
        </FormControl>
      </>
    );
  };


  const userActionObject = getUserActionObjectFromActionCode('CHAT_WITH_A_TEAM_MATE')
  const level = userActionObject.level || ''
  let internalUsersSelectedByDefault: string[] = []

  const getUserListData = async (roleCode: string, groupMemberList?: IGroupMember[]) => {
    const responseData = await getAccountUsers({
      variables: {
        accountId: accountId,
        roleCode: roleCode
      },
    });
    if (responseData?.data?.accountUsers?.length) {
      const filteredAccountUserList = getFilterUserList(responseData?.data?.accountUsers);
      if (selectedConversation?.groupConversation) {
        const groupMembers = groupMemberList?.length
          ? groupMemberList
          : createChannelStateData?.selectedConversationGroupMembers?.length
          ? createChannelStateData?.selectedConversationGroupMembers
          : selectedConversation?.groupConversation?.groupMembers || [];
        const selectedUsers = groupMembers?.map(member=>member?.groupUserId)
        const internalUsersData:any = await getInternalUsersDataByUUIDs({
          variables: {
            userUuids: selectedUsers
          },
        });

        if (internalUsersData.data?.accountUsers) {
          setCreateChannelStateData(prev=>{
            return {
              ...prev,
              selectedInternalUsers:internalUsersData.data?.accountUsers,
            }
          })
        }

      }
      setCreateChannelStateData(prev => {
        const {type} = prev;
        const updatedStateData = {
          ...prev,
          internalUsers: [...filteredAccountUserList],
        };
        if (type === GROUP_TYPES.PRIVATE) {
          updatedStateData.formData = {
            ...prev.formData,
            internalUsers: internalUsersSelectedByDefaultInState || internalUsersSelectedByDefault,
          };
        }
        return updatedStateData;
      });
      return [...responseData?.data?.accountUsers];
    }
    return [];
  };

  const getFilterUserList = (accountUserList: any[]) => {
    return accountUserList?.filter((item) => {
      if (!item?.user?.userRoles?.length) {
        return true;
      }

      for (const userRole of item?.user?.userRoles) {
        if (
          userRole?.userRole?.userRole?.code === 'WORKFLOW' ||
          userRole?.userRole?.userRole?.code === 'CUSTOMER_SUCCESS'
        ) {
          return false;
        }
      }
      return true;
    });
    // return userList.filter((userData) => {
    //   return userData?.user?.uuid !== currentUserData.uuid;
    // });
  };

  const getPatientLists = async (searchPatient: string) => {
    if (searchPatient.length) {
      setCreateChannelStateData((prev) => {
        return {
          ...prev,
          patientLists: [],
          loadingPatientData: true,
        };
      });
      const responseData = await getPatientList({
        variables: {
          accountId: accountId,
          typeId: contactTypeUuid,
          isDeleted: false,
          // searchString: `%${searchString}%`,
          searchString: `%${searchPatient}%`,
        },
      });
      if (responseData?.data?.contacts?.length) {
        setCreateChannelStateData((prev) => {
          return {
            ...prev,
            patientLists: responseData.data.contacts,
            loadingStates: false,
            loadingPatientData: false,
          };
        });
        return [...responseData.data.contacts];
      } else {
        setCreateChannelStateData((prev) => {
          return {
            ...prev,
            patientLists: [],
            loadingStates: false,
            loadingPatientData: false,
          };
        });
      }
    } else {
      setCreateChannelStateData((prev) => {
        return {
          ...prev,
          loadingStates: false,
          patientLists: [],
        };
      });
    }

    return [];
  };

  const getSupportingMember = async (contactId: number) => {
    if (isCommunicationLocationHandlingEnabled) {
      const responseData = await getSupportingMembersByLocationUuids({
        variables: {
          contactId: contactId,
          locationUuids: allowedUserPracticeLocationUuids,
        },
      });
      if (responseData?.data?.contactRelations?.length) {
        const updatedContactRelative = responseData?.data?.contactRelations?.map((item: any) => {
          return {
            ...item,
            ...item.realatedContact
          }
        })
        setCreateChannelStateData((prev) => {
          return {
            ...prev,
            supportingContact: updatedContactRelative,
          };
        });
      }
    } else {
      const responseData = await getSupportingMembers({
        variables: {
          contactId: contactId,
        },
      });
      if (responseData?.data?.contactRelations?.length) {
        const updatedContactRelative = responseData?.data?.contactRelations?.map((item: any) => {
          return {
            ...item,
            ...item.realatedContact
          }
        })
        setCreateChannelStateData((prev) => {
          return {
            ...prev,
            supportingContact: updatedContactRelative,
          };
        });
      }
    }
  };
  const updateGroupDataInState = async (roleCode: string, groupMemberList?: IGroupMember[]) => {
    const groupData = selectedConversation?.groupConversation;
    const groupMember = groupMemberList?.length
      ? groupMemberList
      : createChannelStateData?.selectedConversationGroupMembers?.length
      ? createChannelStateData?.selectedConversationGroupMembers
      : groupData?.groupMembers || [];
    const internalUsers = await getUserListData(roleCode, groupMember)
    if (groupData && Object.keys(groupData).length) {
      const selectedUserIds = getGroupMemberUserOrContactIdListByTypeCode(
        groupMember,
        GROUP_MEMBER_TYPE.USER,
        internalUsers
      );
      // selectedUserIds = getFilterUserUuids(selectedUserIds);
      let selectedContactIds: string[] = [];
      let patientLists: IContact[] = [];
      let contactData = {} as any;
      if (selectedTabCode === GROUP_TYPE_CODES.PRIVATE) {
        selectedContactIds = getGroupMemberUserOrContactIdListByTypeCode(
          groupMember,
          GROUP_MEMBER_TYPE.CONTACT,
          internalUsers
        );
        contactData = selectedConversation?.conversationContact || getPatientDataFromGroupMember(groupMember);
        await getSupportingMember(contactData.id);
      }
      if (
        isBroadcastMessagingEnabled &&
        groupData?.groupType?.code === GROUP_TYPE_CODES.PATIENT_BROADCAST
      ) {
        const membersData = getMembersIdsAndContactsFromGroupMember(
          groupMember
        );
        selectedContactIds = membersData?.groupMembersIdsList;
        patientLists = membersData?.patientLists;
      }
      setCreateChannelStateData((prev) => {
        const updatedSelectedContactIds = selectedContactIds.filter((item) =>
          prev.supportingContact.find((data) => data?.uuid === item)
        );
        const stateData = {
          formData: {
            sendEmailTo: true,
            patientSegment: 'All',
            supportingContact: [...updatedSelectedContactIds],
            internalUsers: [...selectedUserIds],
            patient: contactData as IContact,
            channelName: groupData.name,
            selectedPatient: patientLists,
          } as IFormData,
          loadingStates: false,
          type: groupData?.groupType?.code,
          errors: {},
        };
        return {
          ...prev,
          ...stateData,
        };
      });
    } else {
      setCreateChannelStateData(prev => {
        const {type} = prev;
        const updatedFormData: any = {
          ...prev.formData,
          sendEmailTo: true,
          patientSegment: 'All',
          supportingContact: [],
          selectedPatient: [] as IContact[],
        };

        const updatedStateData = {
          ...prev,
          formData: updatedFormData,
          loadingStates: false,
          errors: {},
        };

        if (type === GROUP_TYPES.PRIVATE) {
          updatedStateData.formData.internalUsers = internalUsersSelectedByDefaultInState || internalUsersSelectedByDefault;
        }
        return updatedStateData;
      });
    }
  };

  const getInitData = async () => {
    let roleCode = USER_ROLE_CODES.EMPLOYER;
    if (
      selectedConversation?.groupConversation?.groupType?.code ===
      GROUP_TYPES.INTERNAL
    ) {
      roleCode = '';
    }
    const usersOfDefaultMessagingChatGroup = await getDefaultCommunicationGroup({
      variables: {
        accountUuid: accountUuid,
        name: DEFAULT_COMMUNICATION_GROUP_NAME,
        where: getTeamsWhereCondition()
      }
    });

    if (usersOfDefaultMessagingChatGroup?.data?.teams?.[0]?.teamMembers.length) {
      internalUsersSelectedByDefault = usersOfDefaultMessagingChatGroup?.data?.teams?.[0]?.teamMembers?.map((teamMember) => teamMember?.userUuid)
      setInternalUsersSelectedByDefaultInState(internalUsersSelectedByDefault)
    }
    setCreateChannelStateData((prev) => {
      return {
        ...prev,
        loadingStates: true,
        patientLists: [],
      };
    });
    let groupMembers = [] as IGroupMember[]
    if (selectedConversation?.uuid) {
      groupMembers = await getGroupMemberData();
    }
    const userData = await getUserListData(roleCode);
    const patientData = await getPatientLists('');
    if (userData && patientData) {
      updateGroupDataInState(roleCode, groupMembers);
    }
  };

  const getGroupMemberData = async() => {
    const groupData = selectedConversation?.groupConversation;
    let groupMembers = groupData?.groupMembers || [];
    if (groupData?.groupMembers?.length !== groupData?.groupMembers_aggregate?.aggregate?.count) {
      const groupMembersData = await GetGroupMembersByConversationUuid();
      if (groupMembersData?.data?.groupConversations?.[0]?.groupMembers) {
        groupMembers = groupMembersData?.data?.groupConversations?.[0]?.groupMembers;
      }
    }
    setCreateChannelStateData((prev) => {
      return {
        ...prev,
        selectedConversationGroupMembers: groupMembers,
      }
    })
    return groupMembers;
  }

  const getUserLoactionData = async (practiseLoactionUuid?: string) => {
    setCreateChannelStateData((prev) => {
      return {
        ...prev,
        loadingStates: true,
      };
    });
    let roleCode = USER_ROLE_CODES.EMPLOYER;
    if (
      selectedConversation?.groupConversation?.groupType?.code ===
      GROUP_TYPES.INTERNAL
    ) {
      roleCode = '';
    }
    const usersOfDefaultMessagingChatGroup = await getDefaultCommunicationGroup(
      {
        variables: {
          accountUuid: accountUuid,
          name: DEFAULT_COMMUNICATION_GROUP_NAME,
          where: getTeamsWhereCondition(practiseLoactionUuid),
        },
      }
    );
    if (
      usersOfDefaultMessagingChatGroup?.data?.teams?.[0]?.teamMembers.length
    ) {
      internalUsersSelectedByDefault =
        usersOfDefaultMessagingChatGroup?.data?.teams?.[0]?.teamMembers?.map(
          (teamMember) => teamMember?.userUuid
        );
    }
    const userData = await getUserListData(roleCode);
    setCreateChannelStateData((prev) => {
      return {
        ...prev,
        loadingStates: true,
      };
    });
    const patientData = await getPatientLists('');
    if (userData && patientData && internalUsersSelectedByDefault.length > 0) {
      setCreateChannelStateData((prev) => {
        return {
          ...prev,
          formData: {
            ...prev?.formData,
            internalUsers: internalUsersSelectedByDefault,
          },
          loadingStates: false,
        };
      });
    }

    if (selectedConversation?.groupConversation) {
      const groupMembers =
        createChannelStateData?.selectedConversationGroupMembers?.length
          ? createChannelStateData?.selectedConversationGroupMembers
          : selectedConversation?.groupConversation?.groupMembers;
      const selectedUsers = groupMembers?.map(member=>member?.groupUserId)
      const internalUsersData:any = await getInternalUsersDataByUUIDs({
        variables: {
          userUuids: selectedUsers
        },
      });

      if (internalUsersData.data?.accountUsers) {
        setCreateChannelStateData(prev=>{
          return {
            ...prev,
            selectedInternalUsers:internalUsersData.data?.accountUsers,
          }
        })
      }

    } else {
      if (internalUsersSelectedByDefault?.length) {
        const internalUsersData:any = await  getInternalUsersDataByUUIDs({
          variables: {
            userUuids:  internalUsersSelectedByDefault,
          },
        });

        if (internalUsersData.data?.accountUsers) {
          setCreateChannelStateData(prev=>{
            return {
              ...prev,
              selectedInternalUsers:internalUsersData.data?.accountUsers,
            }
          })
        }

      }
    }
  };

  const getTeamsWhereCondition = (practiseLoactionUuid?: string) => {
    const whereCondition: any = {
      accountUuid: {_eq: accountUuid},
      _or: [
        {
          name: {
            _eq: DEFAULT_COMMUNICATION_GROUP_NAME,
          },
        },
        {
          teamPreferencesRel: {
            preferenceMlov: {code: {_eq: DEFAULT_GENERAL_GROUP}},
          },
        },
      ],
    };
    if (practiseLoactionUuid) {
      whereCondition.practiceLocationUuid = {_eq: practiseLoactionUuid};
    } else {
      whereCondition.practiceLocationUuid = {_is_null: true};
    }
    return whereCondition;
  };
  const getSetType = (
    conversation?: IConversationData,
    selectedTabCode?: string
  ) => {
    if (conversation?.groupConversation?.groupType?.code) {
      return conversation?.groupConversation?.groupType?.code;
    }
    if (!isPatientMessagingEnabled) {
      return GROUP_TYPE_CODES.INTERNAL;
    } else {
      if (selectedTabCode === GROUP_TYPES.INTERNAL) {
        return GROUP_TYPES.INTERNAL;
      }
      return GROUP_TYPES.PRIVATE;
    }
  };

  const getInitialDataForContact = async () => {
    const practiseLoactionUuid =
      props?.contactData?.contactPracticeLocations?.[0]?.practiceLocationUuid ||
      '';
    const groupName = getGroupNameByPatientName(
      props?.contactData?.name || '',
      createChannelStateData?.formData?.channelName
    );
    setCreateChannelStateData((prev: any) => {
      return {
        ...prev,
        formData: {
          ...prev.formData,
          patient: props?.contactData,
          channelName: groupName,
        },
      };
    });
    await getInitData();
    if (props?.contactData?.id) {
      getUserLoactionData(practiseLoactionUuid);
    }
  };

  useEffect(() => {
    if (props.isDrawerVisible && props.selectedConversation?.uuid) {
      getInitData();
    } else if (props.isDrawerVisible && props?.contactData?.uuid){
      getInitialDataForContact();
    } else {
      setCreateChannelStateData((prev) => {
        return {
          ...prev,
          loadingStates: false,
        };
      });
    }
    const setType = getSetType(selectedConversation, selectedTabCode);
    setCreateChannelStateData((prev)=>{
      return {
        ...prev,
        type: setType
      }
    });
  }, [props.isDrawerVisible, props.selectedConversation?.uuid]);

  const createGroupConversation = async (isConfirmed?: boolean) => {
    const errorData = getUpdatedErrorMessages(createChannelStateData);
    if (
      (createChannelStateData.type === GROUP_TYPES.PRIVATE ||
        createChannelStateData.type === GROUP_TYPES.PATIENT_BROADCAST ||
        createChannelStateData.type === GROUP_TYPES.INTERNAL) &&
      errorData?.isErrorPresent
    ) {
      setCreateChannelStateData((prev) => {
        return {
          ...prev,
          errors: {
            ...prev.errors,
            ...errorData.errors,
          },
        };
      });
    } else {
      setCreateChannelStateData((prev) => {
        return {
          ...prev,
          loadingStates: true,
        };
      });
      const groupMemberTypeList =
        getMlovListFromCategory(mlovData.MLOV, MLOV_CATEGORY.GroupMemberType) ||
        [];
      const userTypeId = getMlovIdFromCode(
        groupMemberTypeList,
        GROUP_MEMBER_TYPE.USER
      );
      const baseService = BaseService.getSharedInstance().axios;
      const createGroupUrl = `/crm-nest/account/${accountId}/group`;

      if (selectedConversation?.groupConversation?.id) {
        const groupMembers =
          createChannelStateData?.selectedConversationGroupMembers ||
          selectedConversation?.groupConversation?.groupMembers;
        const uniqueUserIds = [
          ...new Set(createChannelStateData.formData.internalUsers),
        ];
        let familyMemberIds =
          createChannelStateData.formData.supportingContact;
        let addFamilyMemberIds: string[] = [];
        let removeFamilyMemberIds: string[] = [];
        let addUserIds: string[] = [];
        let removeUserIds: string[] = [];

        if (
          createChannelStateData.type === GROUP_TYPE_CODES.PATIENT_BROADCAST &&
          createChannelStateData?.formData?.selectedPatient?.length
        ) {
          const membersUuids = createChannelStateData.formData.selectedPatient.map((item) => item.uuid);
          const uniqueUserIds = [
            ...new Set(membersUuids),
          ] as string[];
          familyMemberIds = uniqueUserIds;
          const contactData = getAddedAndRemovedContactIDs(
            groupMembers,
            familyMemberIds
          );
          addFamilyMemberIds = contactData.addFamilyMemberIds;
          removeFamilyMemberIds = contactData.removeFamilyMemberIds;
        }

        if (
          selectedConversation?.groupConversation?.groupType?.code ===
          GROUP_TYPES.PRIVATE
        ) {
          const userData = getAddedAndRemovedUserIDs(
            groupMembers,
            uniqueUserIds,
            useruuid
          );
          addUserIds = userData.addUserIds;
          removeUserIds = userData.removeUserIds;
        }

        if (
          selectedConversation?.groupConversation?.groupType?.code ===
          GROUP_TYPES.INTERNAL
        ) {
          const userData = getAddedAndRemovedUserIDs(
            groupMembers,
            uniqueUserIds,
            useruuid
          );
          addUserIds = userData.addUserIds;
          removeUserIds = userData.removeUserIds;
        }

        if (
          selectedConversation?.groupConversation?.groupType?.code ===
          GROUP_TYPES.PRIVATE
        ) {
          const contactData = getAddedAndRemovedContactIDs(
            groupMembers,
            familyMemberIds
          );
          addFamilyMemberIds = contactData.addFamilyMemberIds;
          removeFamilyMemberIds = contactData.removeFamilyMemberIds;
        }
        if (selectedConversation.assigneeId && !isConfirmed) {
          let userName = ''
          const isAssignUsersExistInRemoveList = removeUserIds.find((user)=> {
            if (user === selectedConversation?.assignedUser?.uuid) {
              userName = selectedConversation?.assignedUser?.name
              return true
            }
            return false;
          })
          if (isAssignUsersExistInRemoveList) {
            setCreateChannelStateData(prev=> {
              return {
                ...prev,
                confirmationModal: {
                  code: CREATE_CHANNEL_CONFIRMATION_MODAL.REMOVE_ASSIGNED_USER,
                  data: {
                    name: userName
                  }
                },
                loadingStates: false,
              }
            })
            return
          }
        }
        const body: IUpdateGroupBodyParams = {
          groupId: selectedConversation?.groupConversation?.id,
          groupName: createChannelStateData.formData.channelName,
          groupType: selectedConversation?.groupConversation?.groupType?.code,
          groupData: {
            addUserIds,
            removeUserIds,
            addFamilyMemberIds,
            removeFamilyMemberIds,
          },
        };
        const groupInfo = await baseService
          .put(
            createGroupUrl + '/' + selectedConversation?.groupConversation?.id,
            body,
            {
              headers: {
                ['user-type-id']: userTypeId,
                api_access_token: currentUserData.access_token,
              },
            }
          )
          .catch((err) => {
            if (isSideCarContext) {
              notification.error({
                message: intl.formatMessage({id: 'apiErrorMsg'}),
                duration: 3.0,
                placement: 'topRight',
              });
            } else {
            showToast(
              createGroupToast,
              intl.formatMessage({id: 'apiErrorMsg'}),
              ToastType.error
            );
          }
          });
        if (groupInfo) {
          getGroupConversationAfterUpdateOrCreate(groupInfo?.data, true);
        }
      } else {
        const bodyData: ICreateGroupBodyParams = getCreateGroupData();
        const groupInfo = await baseService
          .post(createGroupUrl, bodyData, {
            headers: {
              ['user-type-id']: userTypeId,
              api_access_token: currentUserData.access_token,
            },
          })
          .catch(() => {
            if (isSideCarContext) {
              notification.error({
                message: intl.formatMessage({id: 'apiErrorMsg'}),
                duration: 3.0,
                placement: 'topRight',
              });
            } else {
            showToast(
              createGroupToast,
              intl.formatMessage({id: 'apiErrorMsg'}),
              ToastType.error
            );
            }
            setCreateChannelStateData((prev) => {
              return {
                ...prev,
                loadingStates: false,
              };
            });
          });
        if (groupInfo) {
          getGroupConversationAfterUpdateOrCreate(groupInfo?.data, false);
        }
      }

      if (createChannelStateData.type === GROUP_TYPES.INTERNAL) {
        await handleChatWithTeammateAction({
          accountUuid: accountUuid,
          userUuid: useruuid,
          level
        });
      }
    }
  };
  const getGroupConversationAfterUpdateOrCreate = async (
    groupInfo: any,
    isUpdated: boolean,
    isLeaveGroup?: boolean,
  ) => {
    let conversationId = -1;
    let groupMsg = 'groupCreatedMsg';
    if (isUpdated) {
      conversationId = groupInfo?.conversation?.id;
      groupMsg = 'groupUpdatedMsg';
    } else {
      conversationId = groupInfo?.id;
    }
    const conversationData = await getConversationByConversationId({
      variables: {
        conversationId: conversationId,
        loginUserIntId: userId,
        loginUserUuid: useruuid,
      },
      });

    if (conversationData.data?.conversations?.[0]?.id) {
      const conversation = conversationData.data?.conversations?.[0];
      if (conversation.assigneeId) {
        const groupMembers = conversation?.groupConversation?.groupMembers;
        const assignedUser = groupMembers.find((user)=> {
          return user.user?.id === conversation.assigneeId
        })
        if (!assignedUser?.id) {
          const body = {
            conversationUuid: conversation?.uuid || '',
          };

          const unAssignResponse = await unAssignConversationAPI({
            body,
          })
          if (unAssignResponse.data?.conversationUuid) {
            sendConversationNotificationNoMessageEvent({
              conversationUuid: conversation?.uuid,
              eventData: {
                assigneeUuid: '',
                assigneeName: '',
                previousAssigneeId: '',
                conversationUuid: conversation?.uuid,
                displayId: selectedConversation?.displayId,
                conversationId: selectedConversation?.id,
                assignByUserId: userId,
              },
              eventCode: SUPPORTED_EVENT_CODE.ASSIGNEE_CHANGED,
            });
          }
        }
      }
      onCreateChannelActionPerformed(
        GROUP_ACTION_CODES.GROUP_CREATE,
        conversationData.data?.conversations?.[0]
      );
      const eventBus = EventBus.getEventBusInstance();
      // REMOVE flag based code
      const groupConversationData = {
        ...conversationData?.data?.conversations?.[0],
        eventCode: CONVERSATION_LOCAL_EVENT_CODES.LOCAL_GROUP_CONVERSATION_CREATED
      }
      localBroadcastEvent(CONVERSATION_LOCAL_EVENT_CODES.LOCAL_GROUP_CONVERSATION_CREATED, {
        conversation: groupConversationData
      });
      eventBus.broadcastEvent(SUPPORTED_EVENT_CODE.GROUP_CONVERSATION_CREATED, conversationData.data?.conversations?.[0]);
      eventBus.broadcastEvent(
        WEB_SOCKET_EVENT_CODE.MEMBER_ADDED_HEADER,
        selectedConversation
      );
      eventBus.broadcastEvent(
        WEB_SOCKET_EVENT_CODE.MEMBER_ADDED,
        selectedConversation
      );
    }
    if (isSideCarContext) {
      notification.success({
        message: intl.formatMessage({
          id: isLeaveGroup
            ? selectedConversation?.assigneeId
              ? 'unassignAndLeftGroup'
              : 'leaveGroupSuccessMsg'
            : groupMsg,
        }),
        duration: 3.0,
        placement: 'topRight',
      });
    } else {
      showToast(
        createGroupToast,
        intl.formatMessage({
          id: isLeaveGroup
            ? selectedConversation?.assigneeId
              ? 'unassignAndLeftGroup'
              : 'leaveGroupSuccessMsg'
            : groupMsg,
        }),
        ToastType.success
      );
    }
    setCreateChannelStateData((prev) => {
      return {
        ...prev,
        loadingStates: false,
      };
    });
  };
  const getCreateGroupData = (): ICreateGroupBodyParams => {
    let userIds: string[] = [currentUserData.uuid];
    if (
      createChannelStateData.type === GROUP_TYPES.INTERNAL ||
      createChannelStateData.type === GROUP_TYPES.USER_BROADCAST ||
      createChannelStateData.type === GROUP_TYPES.PRIVATE
    ) {
      userIds = [
        currentUserData.uuid,
        ...(createChannelStateData?.formData?.internalUsers || []),
      ];
    }

    const uniqueUserIds = [...new Set(userIds)];
    const createGroupData: ICreateGroupBodyParams = {
      groupType: createChannelStateData.type,
      groupName: createChannelStateData.formData.channelName,
      groupData: {
        userIds: uniqueUserIds,
      },
    };
    if (createChannelStateData.type === GROUP_TYPE_CODES.PRIVATE) {
      createGroupData.groupData.contactId =
        createChannelStateData?.formData?.patient?.uuid;

      createGroupData.groupData.familyMemberIds =
        createChannelStateData?.formData?.supportingContact;
    }
    if (
      createChannelStateData.type === GROUP_TYPE_CODES.PATIENT_BROADCAST &&
      createChannelStateData?.formData?.selectedPatient?.length
    ) {
      const membersUuids = createChannelStateData.formData.selectedPatient.map((item) => item.uuid);
      const uniqueUserIds = [
        ...new Set(membersUuids),
      ] as string[];
      createGroupData.groupData.familyMemberIds = uniqueUserIds;
    }

    return createGroupData;
  };

  const getGroupType = (groupTypeList: any) => {
    let newList = groupTypeList.filter((groupType: any) => {
      if (GROUP_TYPE_CODES.PATIENT_BROADCAST === groupType.code && isBroadcastMessagingEnabled) {
        return true;
      }
      return (
        GROUP_TYPE_CODES.PRIVATE === groupType.code ||
        GROUP_TYPE_CODES.INTERNAL === groupType.code
      );
    });
    if (!isPatientMessagingEnabled) {
      newList = newList.filter((groupType: any) => {
        return (
          GROUP_TYPE_CODES.PRIVATE !== groupType.code &&
          GROUP_TYPE_CODES.PATIENT_BROADCAST !== groupType.code
        );
      })
    }
    return newList;
  };

  const isPatientSelected = () => {
    if (createChannelStateData?.formData?.patient?.name) {
      return true;
    }
    return false;
  };
  const onActionPerformed = (code: string) => {
    switch (code) {
      case COMMON_ACTION_CODES.CANCEL:
        setCreateChannelStateData(prev=> {
          return {
            ...prev,
            confirmationModal: {} as IConfirmationModal
          }
        })
        break;
      case COMMON_ACTION_CODES.SAVE:
        setCreateChannelStateData(prev=> {
          return {
            ...prev,
            confirmationModal: {} as IConfirmationModal
          }
        })
        createGroupConversation(true)
        break;
      default:
        break;
    }
  };

  const onOverrideAntModalClose = () => {
    setCreateChannelStateData((prev) => {
      return {
        ...prev,
        showLeaveChatConfirmation: false,
      };
    });
  };

  const onTypeChange = (value: any) => {
    if (value === GROUP_TYPE_CODES.INTERNAL) {
      setCreateChannelStateData((prev: any) => {
        return {
          ...prev,
          selectedInternalUsers: [],
        };
      });
      updateGroupDataInState('');
    } else {
      updateGroupDataInState(USER_ROLE_CODES.EMPLOYER);
    }
    setCreateChannelStateData((prev: any) => {
      return {
        ...prev,
        type: value,
        formData: {
          type: value,
          sendEmailTo: true,
          channelName: prev.formData.channelName || '',
        },
      };
    });
  };

  const onStartChatOrUpdateChatClick = () => {
    if (isGroupChannel()) {
      if (
        GROUP_TYPE_CODES.INTERNAL ===
          createChannelStateData.type &&
        createChannelStateData?.formData?.internalUsers?.length <
          2
      ) {
        notification.info({
          message: intl.formatMessage({
            id: 'oneInternalUserPresentErrorForUpdate',
          }),
          duration: 2.0,
        });
        return;
      }

      setOpenConfirmationModal(true);
    } else {
      createGroupConversation();
    }
  }

  return (
    <Drawer
      visible={isDrawerVisible}
      width={finalWidth}
      mask={isSideCarContext ? false : true}
      onClose={onCancel}
      title={
        <>
          <ModalActionTitle
            {...testID('newGroupChat')}
            title={'newGroupChat'}
            // titleFontSize={24}
            buttonList={[
              {
                ...(isSideCarContext && {
                  show: true,
                  id: 1,
                  btnText: 'back',
                  textColor: Colors.Custom.mainSecondaryBrown,
                  variant: BUTTON_TYPE.SECONDARY,
                  isTransBtn: false,
                  onClick: onCancel,
                }),
              },
              {
                show: true,
                id: 1,
                btnText: isGroupChannel() ? 'updateChat' : 'startChat',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                // leftIcon: '',
                onClick: onStartChatOrUpdateChatClick,
              },
            ]}
          />
        </>
      }
    >
      {createChannelStateData.loadingStates && (
        <View
          height={'full'}
          width={'full'}
          position="absolute"
          zIndex={10}
          bg={'coolGray.50:alpha.70'}
        >
          <Center
            top={'30%'}
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Spinner size={'lg'} />
          </Center>
        </View>
      )}
      <VStack ml={0} height="full">
        <View flex={1}>
          <ModalActionAntSelect
            isRequired={false}
            allowClear={false}
            showSearch={false}
            labelInValue={false}
            filterOption={false}
            label={'type'}
            disabled={
              (isEmployerRole() &&
                USER_PERMISSIONS_CONST.IS_EMPLOYER_OWNER_SHOW_ONLY_INTERNAL) ||
              isGroupChannel() ||
              props?.contactData?.uuid ||
              (isSideCarContext && showOnlyInternalChatInSidecar)
            }
            value={createChannelStateData.type}
            onChange={onTypeChange}
            data={getGroupType(groupTypeList)}
            optionProps={{key: 'code', value: 'code', label: 'value'}}
            extraStyle={{flex: 1}}
          />
          <VStack mt={4} space={4}>
            {form_Input_Field[createChannelStateData.type].map(
              (actionCode: string, index: number) => {
                if (actionCode !== COMMON_VIEW_CONST.FAMILY_MEMBER) {
                  return <View>{getFormInputField(actionCode)}</View>;
                } else if (actionCode === COMMON_VIEW_CONST.FAMILY_MEMBER) {
                  return (
                    <View>
                      {getFormInputField(COMMON_VIEW_CONST.FAMILY_MEMBER)}
                    </View>
                  );
                }
              }
            )}
          </VStack>
        </View>
      </VStack>
      {createChannelStateData.confirmationModal?.code ==
        CREATE_CHANNEL_CONFIRMATION_MODAL.REMOVE_ASSIGNED_USER && (
        <RemoveMemberConfirmation
          onActionPerformed={onActionPerformed}
          modalData={createChannelStateData.confirmationModal?.data}
        />
      )}
      {openConfirmationModal && (
        <OverrideAntdModal
          isOpen={openConfirmationModal}
          onClose={() => {
            setOpenConfirmationModal(false);
          }}
          onConfirm={() => {
            setOpenConfirmationModal(false);
            createGroupConversation();
          }}
          closeButtonText="cancel"
          textContent={{
            headerText: 'Confirmation',
            message: `Are you sure you want to update this chat?`,
          }}
        />
      )}
      {createChannelStateData.showLeaveChatConfirmation && (
        <OverrideAntdModal
          isOpen={createChannelStateData.showLeaveChatConfirmation}
          onClose={onOverrideAntModalClose}
          onConfirm={onLeaveAction}
          closeButtonText="Don't Leave"
          closeButtonType={BUTTON_TYPE.PRIMARY}
          confirmButtonText="Leave"
          confirmButtonType={BUTTON_TYPE.SECONDARY}
          textContent={{
            headerText: 'Leave Group',
            message: `Are you sure you want to exit this conversation?`,
          }}
        />
      )}
    </Drawer>
  );
};
export default CreateChannelView;
