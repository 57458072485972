import { Tooltip } from 'antd';
import {Badge, HStack, Icon, Pressable, Skeleton, Stack, Text, useMediaQuery, View, VStack} from 'native-base';
import React, { useEffect, useState } from 'react';
import EditSvg from '../../assets/Icons/EditSvg';
import {BANNER_SCREEN_OFFSET_WIDTH, DATE_FORMATS, IS_DEACTIVATE_CONTACT_ENABLED, SMALL_WINDOW, PERSON_TYPES, BUTTON_TYPE} from '../../constants';
import BaseService from '../../services/CommonService/BaseService';
import {Colors} from '../../styles';
import {  isEmployerRole, isValidJsonString } from '../../utils/commonUtils';
import {IUserType} from '../../utils/interfaces';
import {isWeb} from '../../utils/platformCheckUtils';
import {capitalizeText} from '../common/ContactRelationView/ContactRelationUtils';
import {DisplayCardAvatar} from '../common/DisplayCard/DisplayCardAvatar';
import {IGroupType} from '../common/DisplayCard/interface';
import ChatCircleSvg from '../common/Svg/ChatCircleSvg';
import MailCircleSvg from '../common/Svg/MailCircleSvg';
import AppUsingPatientSvg from '../common/Svg/SideMenu/AppUsingPatientSvg';
import SMSCircleSvg from '../common/Svg/SMSCircleSvg';
import {CHANNEL_TYPE} from '../RightSideContainer/TeamInbox/Conversations/ConversationConst';
import MsgHighlighter from '../RightSideContainer/TeamInbox/Conversations/ConversationContainer/ConversationList/MsgHighlighter';
import {
  IContact,
  IConversationData,
} from '../RightSideContainer/TeamInbox/Conversations/interfaces';
import {  isPatientDetailViewAllowed } from '../RightSideContainer/UserAccess/UserAccessPermission';
import FeatherIcon from 'react-native-vector-icons/Feather';
import { getAgeValue, getDateStrFromFormat } from '../../utils/DateUtils';
import { IMessageSubtitleWithMemberHeaderInfo } from '../RightSideContainer/TeamInbox/Conversations/MessagingWindow/interface';
import {GROUP_ACTION_CODES} from '../../constants/ActionConst';
import { MEMBER_SUBTITLE_DATA_CODE } from '../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MsgConst';
import { SubtitleData } from '../common/EmailPopupView/interface';
import { testID } from '../../testUtils';
import { getSideCarSubStringObject } from '../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingUtils';
import AssignActionSvg from '../common/Svg/AssignActionSvg';
import ArrowRightFillSvg from '../common/Svg/ArrowRightFillSvg';
import {isAffiliatePatient} from '../RightSideContainer/Contacts/ContactsUtils';

interface PatientInfoBannerProps {
  identifier?: string;
  userId: string | number;
  userType: IUserType;
  genderCode?: any;
  groupType?: IGroupType;
  subtitleString?: string;
  subtitleElement?: JSX.Element;
  loggedInTime?: string;
  isSelected?: boolean;
  isHorizontal?: boolean;
  searchString?: string;
  headerString?: string;
  headerSubString?: string;
  shouldShowSubtitleHighlighter?: boolean;
  bannerType: 'listViewBanner' | 'headerBanner' | 'bigBanner' | 'callLogBanner';
  showDetailProfile?: boolean;
  showEditProfile?: boolean;
  onShowDetails?: any;
  onEditDetails?: any;
  contactActiveStatus?: boolean;
  contactData?: IContact;
  hintExtraDetail?:any
  isBigBannerNameTruncate?:string;
  lastMessageElement?: any;
  showEditProfileOnPatientInfoBanner?: boolean;
  singleConversationData?: IConversationData;
  groupName?:string;
  hideConversationTypeImage?: boolean;
  bannerDirection?:string;
  getConversationTypeAvatar?: any;
  showCustomAvatar?: boolean;
  getConversationTypeImgSvg?: boolean;
  deceasedDate?:string;
  assignUserName?:string;
  isShowAssignedIcon?:boolean;
  showRightArrow?:boolean;
  name?:string;
  isLetterAvatarShow?:boolean;
  onLockScreen?: boolean;
  onCallScreen?: boolean;
  contactType?: {
    code?: string
  };
  showPronounName?: string;
  headerContainerWidth?: number;
  onPatientProfileIconClick?: () => void;
  deceasedDateOrientation?: 'row' | 'column';
  memberSubtitleData?: IMessageSubtitleWithMemberHeaderInfo[];
  onActionPerformed?: (code: string , data?: any) => void;
  isVerticalWithBigBanner?: boolean;
  patientHieSyncCallView?: JSX.Element;
  messagingWindowHeaderLoading?: boolean
  isSidecarView?: boolean;
  hideOnlineIndicator?: boolean;
  viewStylePropsForBigBannerSubHeaderView?: any;
}

export const PatientInfoBanner = (props: PatientInfoBannerProps) => {
  const { patientHieSyncCallView, isSidecarView } = props;
  const [profilePhoto, setProfilePhoto] = useState('');
  const {assignUserName, isShowAssignedIcon, contactType} = props
  const isValidJson = isValidJsonString(props?.subtitleString || '');
  useEffect(()=>{
    if (props?.contactData?.contactProfilePhotos?.length && props?.contactData?.contactProfilePhotos[0]?.attachmentId) {
     getProfilePic();
    }else{
      setProfilePhoto('')
    }
  }, [props?.contactData?.contactProfilePhotos?.length]);
  const isPatient = contactType?.code == 'CUSTOMER'
  const [isSmallWindow] = useMediaQuery([
    { maxWidth: SMALL_WINDOW }
  ]);
  const isSmallScreen =  isSmallWindow;

  const headerNameWidth = props?.headerContainerWidth
    ? props?.headerContainerWidth / 2
    : isSmallScreen
    ? BANNER_SCREEN_OFFSET_WIDTH.BANNER_SCREEN_OFFSET_250
    : BANNER_SCREEN_OFFSET_WIDTH.BANNER_SCREEN_OFFSET_300;

  const getProfilePic = async ()=> {
    const baseService = BaseService.getSharedInstance().axios;
    const url = `/crm-nest/api/attachment/` + props?.contactData?.contactProfilePhotos[0]?.attachmentId;
    baseService.get(url)
      .then((resp) => {
        setProfilePhoto(resp.data?.url)
        //return resp.data?.url;
      });
  }

  const getHeader = () => {
    if (props?.bannerType == 'bigBanner') {
      return (
        <HStack mr={props?.isVerticalWithBigBanner ? 0 : 2}>
          <Tooltip
            title={
              props?.isSidecarView
                ? capitalizeText(props?.headerString || '')
                : ''
            }
          >
            <Text
              fontSize={18}
              numberOfLines={isWeb() ? (props?.isSidecarView ? 1 : 0) : 1}
              maxWidth={
                props?.isSidecarView
                  ? BANNER_SCREEN_OFFSET_WIDTH.BANNER_SCREEN_OFFSET_300
                  : 'full'
              }
              size={'xslBold'}
              flexWrap={'wrap'}
              textAlign={
                props?.onCallScreen || props?.isVerticalWithBigBanner
                  ? 'center'
                  : undefined
              }
              marginLeft={props?.onCallScreen ? 8 : undefined}
              {...testID('HeaderString')}
            >
              {props?.headerString || ''}
            </Text>
          </Tooltip>
          {props?.userType == 'PATIENT' && (
            <View mt={1.5}>
              <AppUsingPatientSvg loggedInTime={props?.loggedInTime} />
            </View>
          )}
        </HStack>
      );
    }
    if (props?.bannerType == 'headerBanner') {
      return (
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <Tooltip title={capitalizeText(props?.headerString || '')}>
            <Text
              noOfLines={1}
              maxWidth={headerNameWidth}
              size={'lgMedium'}
              style={{marginRight: 4}}
              {...testID('HeaderString')}
            >
              {capitalizeText(props?.headerString || '')}
            </Text>
          </Tooltip>
          {props?.showPronounName?.length ? (
            <Text
              noOfLines={1}
              fontWeight={700}
              fontSize={12}
              color={Colors.Custom.Gray500}
              style={{marginRight: 4}}
              {...testID('PronounName')}
            >
              {props?.showPronounName || ''}
            </Text>
          ) : (
            <></>
          )}
          {props.showRightArrow ? (
            <ArrowRightFillSvg customStrokeColor={Colors.Custom.Gray500}/>
          ) : (
            <></>
          )}
        </View>
      );
    }

    if (props?.bannerType == 'callLogBanner') {
      return (
        <>
          <View>
            <Text
              // noOfLines={1}
              style={{fontSize: 16, marginBottom: 2}}
              size={'smSemibold'}
              color={'#000000'}
            >
              {props?.headerSubString || '-'}
            </Text>
            <Text
              // noOfLines={1}
              style={{fontSize: 14, marginBottom: 4}}
              size={'xsMedium'}
              color={Colors?.Custom?.Gray500}
            >
              {props?.headerString || '-'}
            </Text>
          </View>
        </>
      );
    }

    return (
      <Tooltip title={props?.headerString} placement="topLeft">
        <View style={{width: '100%'}}>
          <Text
            size={
              props?.bannerType == 'listViewBanner' ? 'smSemibold' : 'smSemibold'
            }
            isTruncated={props?.bannerType == 'listViewBanner' ? true : false}
            maxW={props?.bannerType == 'listViewBanner' ? '87%' : '100%'}
            noOfLines={1}
            fontWeight={600}
            color={
              props?.isSelected ? Colors.Custom.Gray900 : Colors.Custom.Gray700
            }
            //style={{height: 20}}
            {...testID('GroupName')}
          >
            {props?.searchString ? (
              <MsgHighlighter
                searchString={props?.searchString || ''}
                contentString={props?.headerString || ''}
              />
            ) : (
              capitalizeText((props.groupName ?  props.groupName : props?.headerString) || '')
            )}
          </Text>
        </View>
      </Tooltip>
    );
  };

  const getViewDetails = () => {
    return (
      <>
        <HStack flex={1} space={1} marginTop={3}>
          <Pressable
            width={'165px'}
            onPress={() => {
              props?.onShowDetails?.();
            }}
            style={{
              borderWidth: 1,
              borderColor: '#825AC7',
              borderRadius: 8,
              paddingHorizontal: 4,
              paddingVertical: 8
            }}
            accessible={false}
          >
            <HStack flex={1}>
              <Icon
                as={FeatherIcon}
                name={'eye'}
                size="xs"
                color={'#825AC7'}
                marginLeft={2}
                marginRight={2}
              />
              <Text
                fontSize={12}
                fontWeight={600}
                fontStyle={'normal'}
                style={{
                  color: '#825AC7',
                }}
                {...testID('ViewDetailProfile')}
              >
                View Detailed Profile
              </Text>
            </HStack>
          </Pressable>

          <Pressable
            style={{
              paddingHorizontal: 4,
              paddingVertical: 8
            }}
            onPress={props?.onPatientProfileIconClick}
            {...testID('ExternalLink')}
            >
            <HStack space={2}>
              <Icon
                as={FeatherIcon}
                name="external-link"
                size={5}
                color={'#825AC7'}
              />
            </HStack>
          </Pressable>
        </HStack>
      </>
    );
  };

  const getContactActiveStatus = () => {
    return (
      IS_DEACTIVATE_CONTACT_ENABLED && isPatient && (
        <>
          {props.contactActiveStatus ? (
            <></>
          ) : (
            <HStack>
              <View alignItems={'center'} flexDirection={'row'}>
                <Text color={Colors.Custom.Gray500} {...testID('FoldStatusLabel')}>
                  Fold Status:
                </Text>
                <Text color={Colors.danger[500]} {...testID('FoldStatus')}>
                  {` Inactive`}
                </Text>
              </View>
            </HStack>
          )}
        </>
      )
    );
  };
  const isPatientDetailViewAllowedConfig = isPatientDetailViewAllowed();
  const showProfileView = () => {
    return (
      <>
        {(props?.showDetailProfile ?? true) &&
        !isEmployerRole() &&
        (isPatientDetailViewAllowedConfig ||
          contactType?.code != PERSON_TYPES.CUSTOMER)
          ? getViewDetails()
          : null}
        {props?.showEditProfile && !isEmployerRole()
          ? getEditPatientDetails()
          : null}
      </>
    );
  };

  const formattedBirthDate = getDateStrFromFormat(
    props?.contactData?.person?.birthDate || '',
    DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
  )

  const getEditPatientDetails = () => {
    if (isAffiliatePatient(props.contactData?.additionalAttributes)) {
      return <></>;
    }
    return (
      <Text
        size={'smNormal'}
        fontWeight={500}
        noOfLines={1}
        style={{
          color: '#825AC7',
          marginTop: 5,
          backgroundColor: '#FDFAFF',
          paddingVertical: 4,
          width: 100,
          borderWidth: 1,
          borderRadius: 14,
          borderColor: '#EFE0FC',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onPress={() => {
          props?.onEditDetails?.();
        }}
        {...testID('EditProfile')}
      >
        Edit Profile
      </Text>
    );
  };

  const getPracticeLocations = () => {
    return (
      <Text style={{color: Colors.Custom.Gray500}}>
        {props?.contactData?.contactPracticeLocations?.[0]?.practiceLocation
          ?.name || ''}
      </Text>
    );
  };

  const getSubstringByObject = () => {
    if (props?.isSidecarView && props?.contactData) {
      const subStringData = getSideCarSubStringObject(props?.contactData, props?.deceasedDate || '')
      return (
        getOtherSubstringData(subStringData, 0)
      )
    }
    else if (props?.messagingWindowHeaderLoading) {
      return (
        <Text style={{color: Colors.Custom.Gray500}}>Loading ...</Text>
        // <Skeleton.Text  lines={1} width={headerNameWidth}></Skeleton.Text>
      )
    } else if (isValidJson) {
      const subStringData: any = JSON.parse(props?.subtitleString || '')?.[0];
      const keys = Object.keys(subStringData);
      return (
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          {keys.map((key, index) => {
            const value = subStringData[key];
            if (key === SubtitleData.gender && value?.code?.length) {
              return (
                <View
                  key={key}
                  flexDir={'row'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  {index > 0 && ' | '}
                  <Tooltip
                    title={value?.genderHoverText}
                  >
                    <Text
                      size={
                        props?.bannerType == 'headerBanner'
                          ? 'smNormal'
                          : 'xsNormal'
                      }
                      fontWeight={400}
                      noOfLines={1}
                      style={{
                        color: '#FFA477',
                        marginEnd: 1,
                        marginStart: 1,
                      }}
                      {...testID('GenderLabelText')}
                    >
                      {'\u00A0' + value?.genderLabelText + '\u00A0'}
                    </Text>
                  </Tooltip>
                </View>
              );
            }
            else if(
              key === SubtitleData.dateOfBirth && value?.dobLabelText?.length && value?.dobHoverText?.length
            ){
              return (
                <View
                  key={key}
                  flexDir={'row'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  {index > 0 && ' | '}
                  <Tooltip title={value?.dobHoverText}>
                    <Text
                      size={
                        props?.bannerType == 'headerBanner'
                          ? 'smNormal'
                          : 'xsNormal'
                      }
                      fontWeight={400}
                      noOfLines={1}
                      style={{
                        // color: '#FFA477',
                        marginEnd: 1,
                        marginStart: 1,
                      }}
                      {...testID('DOBLabelText')}
                    >
                      {'\u00A0' + value?.dobLabelText + '\u00A0'}
                    </Text>
                  </Tooltip>
                </View>
              );
            }
            else if (
              key === SubtitleData.birthSex &&
              !subStringData['gender']?.code?.length
            ) {
              return (
                <View
                  key={key}
                  flexDir={'row'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  {index > 0 && ' | '}
                  <Tooltip title={subStringData['gender']?.genderHoverText}>
                    <Text
                      size={
                        props?.bannerType == 'headerBanner'
                          ? 'smNormal'
                          : 'xsNormal'
                      }
                      fontWeight={400}
                      noOfLines={1}
                      style={{
                        color: '#FFA477',
                        marginEnd: 1,
                        marginStart: 1,
                      }}
                      {...testID('GenderLabelText')}
                    >
                      {'\u00A0' + subStringData['gender']?.genderLabelText + '\u00A0'}
                    </Text>
                  </Tooltip>
                </View>
              );
            } else if (key === SubtitleData.memberCountStr) {
              return (getMemberCountString(subStringData[key], index));
            } else if (
              key !== SubtitleData.gender &&
              key !== SubtitleData.birthSex &&
              value?.length
            ) {
              return (
                <View
                  key={key}
                  flexDir={'row'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  {index > 0 && ' | '}
                  <Text
                    size={
                      props?.bannerType == 'headerBanner'
                        ? 'smNormal'
                        : 'xsNormal'
                    }
                    fontWeight={400}
                    noOfLines={1}
                    style={{
                      color: Colors.Custom.Gray500,
                      marginEnd: 1,
                      marginStart: 1,
                    }}
                  >
                    {(index > 0 ? '\u00A0' : '') + subStringData[key] + '\u00A0'}
                  </Text>
                </View>
              );
            }
            return null;
          })}
        </View>
      );
    }
    else{
      if (props?.memberSubtitleData){
        const memberSubtitleString = props?.memberSubtitleData;
        return(
          getMemberSubtitleDataElement(memberSubtitleString)
        );
      }
      return (
        <>
         {!!props?.subtitleElement ? (
            props?.subtitleElement
          ) : (
          <Text
          size={props?.bannerType == 'headerBanner' ? 'smNormal' : 'xsNormal'}
          fontWeight={400}
          noOfLines={1}
          maxWidth={headerNameWidth + 100}
          style={{
            color: Colors.Custom.Gray500,
            marginTop: props?.bannerType == 'headerBanner' ? 0 : 3,
            height: 18,
            marginEnd: 1,
            marginStart: 1,
          }}
          {...testID('SubTitleString')}
        >
          {props?.subtitleString}
        </Text>
         )}
        </>
      );
    }
  };

  const getMemberSubtitleDataElement = (
    memberSubtitleString: IMessageSubtitleWithMemberHeaderInfo[]
  ) => {
    return memberSubtitleString?.map(
      (data: IMessageSubtitleWithMemberHeaderInfo, index: number) => {
        switch (data?.code) {
          case MEMBER_SUBTITLE_DATA_CODE.MEMBER_COUNT:
            return getMemberCountString(data?.value, index);
          default:
            return getOtherSubstring(data?.value, index);
        }
      }
    );
  };

  const getOtherSubstringData = (data: string, index: number) => {
    return (
      <Text
        size={props?.bannerType == 'headerBanner' ? 'smNormal' : 'xsNormal'}
        fontWeight={400}
        noOfLines={1}
        style={{
          color: Colors.Custom.Gray500,
          marginEnd: 1,
          marginStart: 1,
        }}
      >
        {(index > 0 ? '\u00A0' : '') + data + '\u00A0'}
      </Text>
    );
  }

  const getOtherSubstring = (data: string, index: number) => {
    return (
      <View
        key={`${data}_${index}`}
        flexDir={'row'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        {index > 0 && '|'}
        {!!props?.contactData?.person?.birthDate &&
        data ===
          getDateStrFromFormat(
            props?.contactData?.person?.birthDate,
            DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
          ) ? (
          <Tooltip
            title={getAgeValue(
              props?.contactData?.person?.birthDate || '',
              props?.deceasedDate || ''
            )}
          >
            {getOtherSubstringData(data, index)}
          </Tooltip>
        ) : (
           getOtherSubstringData(data, index)
        )}
      </View>
    );
  }

  const getMemberCountString = (data: string, index: number) => {
    return (
      <View
        key={`${data}_${index}`}
        flexDir={'row'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        {index > 0 && '|'}
        <Pressable
          style={{marginLeft: 4}}
          onPress={() => {
            props.onActionPerformed?.(
              GROUP_ACTION_CODES.GROUP_HEADER_CLICKED
            );
          }}
          {...testID('MemberCount')}
        >
          <Text color={Colors.Custom.mainPrimaryPurple}>
            {data}
          </Text>
        </Pressable>
      </View>
    );
  }

  const getSubtitle = () => {
    if (props?.bannerType == 'callLogBanner') { //not used
      return (
        <>
        {!!props?.subtitleElement ? props?.subtitleElement :
        <Text
          size={'smMedium'}
          // noOfLines={1}
          style={{
            color: Colors.Custom.Gray500,
            fontSize: 12,
          }}
          {...testID('SubTitleString')}
        >
          {props?.subtitleString || '-'}
        </Text>
        }
        </>
      );
    }
    if (props?.bannerType == 'bigBanner') {
      return (
        <VStack>
          <HStack>
            {!!props?.deceasedDate && (
              <Stack
                alignItems={
                  props.deceasedDateOrientation === 'row' ? 'center' : undefined
                }
                direction={props.deceasedDateOrientation || 'row'}
              >
                <Badge
                  fontSize={12}
                  fontWeight={700}
                  backgroundColor={Colors.Custom.DeceasedTagBackgroundColor}
                  _text={{
                    color: Colors.Custom.DeceasedTagColor,
                  }}
                  marginLeft={props?.onCallScreen ? 100 : undefined}
                  alignSelf={
                    props.deceasedDateOrientation === 'row'
                      ? 'center'
                      : 'flex-start'
                  }
                  rounded="full"
                  justifyContent={'center'}
                  borderColor={Colors.Custom.DeceasedTagBorderColor}
                  marginRight={2}
                  marginBottom={1}
                >
                  {`Deceased : ${props.deceasedDate}`}
                </Badge>
              </Stack>
            )}
          </HStack>
          {!!props?.subtitleElement ? (
            props.subtitleElement
          ) : (
          <Text
            size={'smMedium'}
            // noOfLines={1}
            style={{
              color: Colors.Custom.Gray500,
            }}
          >
            {props?.subtitleString}
          </Text>
          )}
        </VStack>
      );
    }

    const getConversationTypeImgSvg = (
      singleConversationData: IConversationData
    ): JSX.Element => {
      if (singleConversationData?.conversationInbox?.channelType) {
        const type =
          singleConversationData?.conversationInbox?.channelType || '';
        if (type === CHANNEL_TYPE.CHANNEL_EMAIL) {
          return <MailCircleSvg></MailCircleSvg>;
        } else if (type === CHANNEL_TYPE.CHANNEL_TWILIO_SMS) {
          return <SMSCircleSvg></SMSCircleSvg>;
        } else {
          return <ChatCircleSvg></ChatCircleSvg>;
        }
      } else {
        return <ChatCircleSvg></ChatCircleSvg>;
      }
    };

    return (
      <HStack>
        {props?.getConversationTypeImgSvg &&
        <View style={{width: 20, height: 20, marginRight: 5}}>
          {!props.hideConversationTypeImage && getConversationTypeImgSvg(
            props?.singleConversationData || ({} as IConversationData)
          )}
        </View>
        }
        <Text
          size={props?.bannerType == 'headerBanner' ? 'smNormal' : 'xsNormal'}
          fontWeight={400}
          noOfLines={1}
          maxWidth={headerNameWidth + 100}
          style={{
            color: Colors.Custom.Gray500,
            marginTop: props?.bannerType == 'headerBanner' ? 0 : 3,
            height: props?.messagingWindowHeaderLoading ? 20 : 18,
          }}
          {...testID('SubTitleString')}
        >
          {props?.shouldShowSubtitleHighlighter ? (
            <MsgHighlighter
              searchString={props?.searchString || ''}
              contentString={props?.subtitleString || ''}
            />
          ) : (
            getSubstringByObject()
          )}
        </Text>
      </HStack>
    );
  };

  if (props?.bannerType == 'headerBanner') {
    return (
      <HStack style={{width: '100%'}} flex={1}>
        <VStack>
          <DisplayCardAvatar
            avatarStyle={{
              avatarSize: '12',
            }}
            isLetterAvatarShow
            userData={{
              userId: props?.userId,
              userType: props?.userType,
              genderCode: props?.genderCode as any,
              imgSrc: profilePhoto,
              userName: props.name
            }}
            groupType={props?.groupType as any}
            hideOnlineIndicator={props?.hideOnlineIndicator}
          />
        </VStack>
        <VStack marginLeft={3} justifyContent={'center'}>
          <HStack alignItems={'center'}>
            {props?.headerString && getHeader()}
            {props?.userType == 'PATIENT' && (
              <AppUsingPatientSvg loggedInTime={props?.loggedInTime} />
            )}
          </HStack>
          {(props?.subtitleString || props ?.subtitleElement)? getSubtitle() : null}
        </VStack>
      </HStack>
    );
  }

  if (props?.bannerType == 'listViewBanner') {
    const userData= {
      userId: props?.userId,
      userType: props?.userType,
    }

    return (
      <HStack flex={1}>
        <VStack>
          {props?.showCustomAvatar && props.getConversationTypeAvatar ? (
           <DisplayCardAvatar
              customAvatarElem={props.getConversationTypeAvatar}
              userData={{
                userId: props?.userId,
                userType: props?.userType,
                genderCode: props?.genderCode as any,
                imgSrc: profilePhoto,
                userName: props.name
              }}
              groupType={props?.groupType as any}
              hideOnlineIndicator={props?.hideOnlineIndicator}
            />
          ) : (
            <DisplayCardAvatar
              avatarStyle={{
                avatarSize: '12',
              }}
              isLetterAvatarShow
              userData={{
                userId: props?.userId,
                userType: props?.userType,
                genderCode: props?.genderCode as any,
                imgSrc: profilePhoto,
                userName: props.name
              }}
              groupType={props?.groupType as any}
            />
          )}
        </VStack>
        <VStack justifyContent={'center'} flex={1}>
          <HStack alignItems={'center'} key={'listViewBanner'}>
            {isShowAssignedIcon && assignUserName ? (
              <Tooltip
                title={assignUserName?.length ? assignUserName : 'Unassigned'}
              >
                <Pressable style={{marginRight: 2, marginLeft: -4}}>
                  <VStack alignItems={'center'}>
                    <AssignActionSvg
                      customStrokeColor={
                        assignUserName && assignUserName.length
                          ? '#12B76A'
                          : Colors.Custom.Gray500
                      }
                    />
                  </VStack>
                </Pressable>
              </Tooltip>
            ) : (
              <></>
            )}
            {props?.headerString && getHeader()}
            {props?.identifier != 'CALL_LOGS' &&
              props?.userType == 'PATIENT' && (
                <AppUsingPatientSvg loggedInTime={props?.loggedInTime} />
              )}
          </HStack>
          {(props?.subtitleString || props?.subtitleElement) ? getSubtitle() : null}
          {props?.lastMessageElement ? props.lastMessageElement : <></>}
        </VStack>
      </HStack>
    );
  }

  if (props?.bannerType == 'callLogBanner') {
    return (
      <HStack>
        <VStack>
          <DisplayCardAvatar
            avatarStyle={{
              avatarSize: '10',
            }}
            isLetterAvatarShow
            userData={{
              userId: props?.userId,
              userType: props?.userType,
              genderCode: props?.genderCode as any,
              imgSrc: profilePhoto,
              userName: props.name
            }}
            groupType={props?.groupType as any}
          />
        </VStack>
        <VStack ml={3}>
          {props?.headerString || props?.headerSubString ? getHeader() : null}
          {(props?.subtitleString || props?.subtitleElement) ?  getSubtitle() : null}
        </VStack>
      </HStack>
    );
  }

  const renderViewDetailsButton = () => {
    if ((props?.showDetailProfile ?? true) && !isEmployerRole() && (isPatientDetailViewAllowedConfig || contactType?.code != PERSON_TYPES.CUSTOMER )) {
      return getViewDetails();
    }
    return <></>
  }

  if (props?.bannerType == 'bigBanner') {
    if(props.bannerDirection == 'HORIZONTAL') {
      return(
        <HStack justifyContent={'space-between'} style={{position: 'relative'}} flex={ isWeb() ?  1 : 0}>
          <View  style={{flexDirection: 'column', alignItems: 'center'}} flex={1}>
            <VStack>
              <DisplayCardAvatar
                avatarStyle={{
                  avatarSize: '20',
                  textStyle: {
                    fontSize: 40
                  }
                }}
                isLetterAvatarShow
                userData={{
                  userId: props?.userId,
                  userType: props?.userType,
                  genderCode: props?.genderCode as any,
                  imgSrc: profilePhoto,
                  userName: props.name
                }}
                groupType={props?.groupType as any}
              />
            </VStack>
            <View flex={1} style={{ alignItems: 'center', justifyContent: 'center'}}>
              {props?.headerString && getHeader()}
              {(props?.subtitleString || props?.subtitleElement) ? getSubtitle() : null}
              {props.hintExtraDetail ? props.hintExtraDetail : null}
              {getContactActiveStatus()}
              {showProfileView()}
            </View>
          </View>
          {props.showEditProfileOnPatientInfoBanner && !isEmployerRole() ? (
            <View flex={.1} style={{width: 36, height: 36, position: 'absolute', right: 0}}>
              <Pressable
                style={{width: 36, height: 36}}
                onPress={() => {
                  props?.onEditDetails?.();
                }}
              >
              <EditSvg />
              </Pressable>
            </View>
          ) : (
            <></>
          )}
        </HStack>
      )
    }
    if (props?.isHorizontal) {
      return (
        <HStack justifyContent={'space-between'} flex={ isWeb() ?  1 : 0}>
          <HStack flex={1}>
            <VStack>
              <DisplayCardAvatar
                avatarStyle={{
                  avatarSize: '20',
                  textStyle: {
                    fontSize: 40
                  }
                }}
                isLetterAvatarShow
                userData={{
                  userId: props?.userId,
                  userType: props?.userType,
                  genderCode: props?.genderCode as any,
                  imgSrc: profilePhoto,
                  userName: props.name
                }}
                groupType={props?.groupType as any}
              />
            </VStack>
            <View flex={1} style={{marginLeft: 16, ...props?.viewStylePropsForBigBannerSubHeaderView}}>
              {props?.headerString && getHeader()}
              {(props?.subtitleString || props?.subtitleElement) ? getSubtitle() : null}
              {getPracticeLocations()}
              {props.hintExtraDetail ? props.hintExtraDetail : null}
              {getContactActiveStatus()}
              {showProfileView()}
            </View>
            {patientHieSyncCallView}
          </HStack>
          {props.showEditProfileOnPatientInfoBanner && !isEmployerRole() ? (
            <View flex={.1} style={{width: 36, height: 36}}>
              <Pressable
                style={{width: 36, height: 36}}
                onPress={() => {
                  props?.onEditDetails?.();
                }}
                {...testID('EditIcon')}
              >
                <EditSvg />
              </Pressable>
            </View>
          ) : (
            <></>
          )}
        </HStack>
      );
    }

    return (
      <VStack
        justifyContent={props?.isVerticalWithBigBanner ? 'center' : undefined}
        alignItems={props?.isVerticalWithBigBanner ? 'center' : undefined}
      >
        <VStack>
          <DisplayCardAvatar
            avatarStyle={{
              avatarSize: 20,
              textStyle: {
                fontSize: 25
              }
            }}
            isLetterAvatarShow
            userData={{
              userId: props?.userId,
              userType: props?.userType,
              genderCode: props?.genderCode as any,
              imgSrc: profilePhoto,
              userName: props.name
            }}
            groupType={props?.groupType as any}
          />
        </VStack>
        <View
          style={{marginTop: 16}}
          alignItems={'center'}
          justifyContent={'center'}
          >
          {props?.headerString ? getHeader() : null}
          {(props?.subtitleString || props?.subtitleElement) ? getSubtitle() : null}
          {props?.isVerticalWithBigBanner && (
            <>
            {getPracticeLocations()}
            {props.hintExtraDetail ? props.hintExtraDetail : null}
            </>
          )}
          {getContactActiveStatus()}
          {props?.isVerticalWithBigBanner && (
            <>
              {showProfileView()}
            </>
          )}
        </View>
      </VStack>
    );
  }

  return null;
};
