import {EmailPreferenceView} from "./EmailPreferenceView"
import {EmailSignatureSetting} from "./EmailSignatureSetting"
import {IEmailPreferenceSettings} from "./PreferencesInterface"

export const EmailPreferenceSettings = (props: IEmailPreferenceSettings) => {
  return <>
    {props?.showEmailInboxPreferencesSetting && <EmailPreferenceView
      selectedEmailInboxUserView={props?.selectedEmailInboxUserView}
      preferenceData={props?.preferenceData}
      preferenceDataId={props?.preferenceDataId}
      userPreferenceId={props?.userPreferenceId}
      onActionPerformed={props?.onActionPerformed}
    />}
    <EmailSignatureSetting
      preferenceData={props?.preferenceData}
      preferenceDataId={props?.preferenceDataId}
      userPreferenceId={props?.userPreferenceId}
      onActionPerformed={props?.onActionPerformed}
    />
  </>
}