import {Divider, Pressable, Text, Icon, Stack, ScrollView} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Colors} from '../../../../styles/Colors';
import {isWeb} from '../../../../utils/platformCheckUtils';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import {IPatientNoteCardProps} from '../interfaces';
import FeatherIcon from 'react-native-vector-icons/Feather';
import styles from '../PatientNotes/PatientNotesStyles';
import {
  isOnlyChiefComplaintExist,
  NoteExtraComponents,
  renderPatientNoteAuthoredAndSignedBy,
  renderPatientNoteCardHeadLine,
  renderPatientNoteChiefComplaint,
  renderPatientNoteDateAndStatus,
  renderPatientNoteDiagnosis,
} from './NoteUtils';
import {
  getCurrentTimeZoneAbbr,
  getDateStrFromFormat,
} from '../../../../utils/DateUtils';
import {DISPLAY_DATE_FORMAT} from '../../../../constants/StringConst';
import {DocStatus, IFormComponent} from '../PatientNotes/interfaces';
import {
  getDocumentStatusLabel,
  getFormComponents,
  getSubmittedResponse,
} from '../PatientNotes/components/DocumentationViewHelper';
import {
  getEhrConfig,
  getResourceAbilities,
} from '../../../../utils/capabilityUtils';
import {useLazyQuery} from '@apollo/client';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {GET_FORM_BY_ID} from '../../../../services/Forms/FormsQueries';
import {getDocumentRefByReferenceId} from '../../../../services/CommonService/OrderService';
import {FHIR_RESOURCE} from '../../../../constants/FhirConstant';
import {
  getFormContentByEhrAndFormData,
  hasChiefComplaintComponent,
} from '../PatientNotes/PatientNotesHelper';
import {getAccountUUID} from '../../../../utils/commonUtils';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import FHForm from '../../../RightSideContainer/Forms/FHFormio/FHForm/FHForm';
import {FormContext} from '../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper';
import useEHRCapabilities from '../../../../screens/BusinessStudio/useEHRCapabilities';
import { checkIsFoldDrivenNotes } from '../PatientNotes/components/AddOrUpdateTemplate/helper';

export const PatientLinkNoteCard = (props: {
  data: IPatientNoteCardProps;
  onNoteLinkClick?: () => void;
  accountLocationUuid?: string;
}) => {
  const [showMore, setShowMore] = useState<{
    [key: string]: boolean;
  }>({
    [NoteExtraComponents.DIAGNOSIS]: false,
    [NoteExtraComponents.CHIEF_COMPLAINT]: false,
  });
  const {accountLocationUuid} = props;
  const [customBorderColor, setCustomBorderColor] = useState('');
  const [showAdditionalDetail, setAdditionalDetail] = useState(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [readOnlyData, setReadOnlyFormData] = useState<{
    components: any;
    answers: any;
  }>({
    components: undefined,
    answers: undefined,
  });
  const intl = useIntl();
  const ehrConfig = getEhrConfig(accountLocationUuid, '');
  const resourceAbilities = getResourceAbilities(
    FHIR_RESOURCE.DOCUMENT_REFERENCE,
    '',
    accountLocationUuid
  );
  const foldVisitNoteWithEncountersEnabled =
    resourceAbilities?.foldVisitNoteEnabled || false;
  const accountUUID = getAccountUUID();
  const commonData = useContext(CommonDataContext);
  const ehrCapabilities = useEHRCapabilities({locationId: accountLocationUuid});
  const userSettings = commonData.userSettings;
  const isSidecarContext = commonData?.sidecarContext?.isSidecar;

  const isFold = ehrConfig.isFold;
  const {
    status,
    createdDate,
    formName,
    description,
    resourceId,
    signedDate,
    signedByUserId,
    formId,
    formContent,
  } = props.data;

  const setInitData = async () => {
    const formData = await getFormById({variables: {id: formId}});
    const documentRefData = await getDocumentRefByReferenceId(
      `${resourceId || ''}`,
      foldVisitNoteWithEncountersEnabled,
      props?.accountLocationUuid
    );
    const initData = {
      formContent: [] as any[],
    };
    let formComponents: any[] = [];
    let formAnswer: any = {};
    const isFoldDrivenNote = checkIsFoldDrivenNotes(documentRefData);
    const formContent: IFormComponent[] = getFormContentByEhrAndFormData(
      documentRefData?.data?.content || [],
      isFoldDrivenNote,
    );
    if (formData?.data?.form) {
      initData.formContent = formContent;
      formComponents = getFormComponents(
        formData?.data?.form?.components,
        {},
        initData,
        false,
      );
      formAnswer = getSubmittedResponse(
        initData,
        {},
        false,
        hasChiefComplaintComponent(formData),
      );
    }
    setReadOnlyFormData(prev => {
      return {
        ...prev,
        components: formComponents,
        answers: formAnswer,
      };
    });
    setLoader(false);
  };

  useEffect(() => {
    setLoader(true);
    setInitData();
  }, []);

  const handleShowMoreClick = (key: string) => {
    setShowMore(prev => ({...prev, [key]: !prev[key]}));
  };

  const renderVisitNoteLinkElement = (): JSX.Element => {
    return (
      <Pressable
        onHoverIn={() => {
          setCustomBorderColor(Colors.FoldPixel.PRIMARY_FOCUS);
        }}
        onHoverOut={() => {
          setCustomBorderColor('');
        }}
        onPress={() => {
          if (
            props?.onNoteLinkClick &&
            typeof props?.onNoteLinkClick === 'function'
          ) {
            props?.onNoteLinkClick();
          }
        }}>
        <Stack
          direction={'row'}
          alignItems="center"
          style={{
            borderColor: customBorderColor,
            backgroundColor: customBorderColor ? '#F9F5FF' : '',
          }}>
          <Text fontSize="16" color={Colors.Custom.Primary300}>
            View
          </Text>
          <Icon
            as={FeatherIcon}
            name="arrow-up-right"
            size="5"
            color={Colors.Custom.Primary300}
          />
        </Stack>
      </Pressable>
    );
  };

  const renderAdditionalDetailLinkElement = (): JSX.Element => {
    return (
      <Pressable
        onPress={() => {
          setAdditionalDetail(!showAdditionalDetail);
        }}>
        <Stack
          direction={'row'}
          space={2}
          alignItems="center"
          style={{
            borderColor: customBorderColor,
            backgroundColor: customBorderColor ? '#F9F5FF' : '',
          }}>
          <Icon
            as={FeatherIcon}
            name={showAdditionalDetail ? 'chevron-up' : 'chevron-down'}
            size="5"
            color={Colors.FoldPixel.GRAY300}
          />
        </Stack>
      </Pressable>
    );
  };

  const [getFormById] = useLazyQuery(GET_FORM_BY_ID, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });

  const renderNoteAdditionalDetail = (): JSX.Element => {
    return (
      <>
        <ScrollView
          maxHeight={300}
          padding={4}
          borderWidth={1}
          borderColor={Colors.Custom.Gray200}
          borderRadius={6}
          backgroundColor={Colors.Custom.Gray50}>
          <div className="note-preview-styles custom-form-styles">
            <FHForm
              components={readOnlyData.components}
              submittedResponse={readOnlyData.answers}
              optionData={{
                userSettings,
                accountUUID,
                ehrCapabilities,
                formContext: FormContext.patientNotes,
              }}
              isPreviewMode={true}
              readOnly={true}
            />
          </div>
        </ScrollView>
      </>
    );
  };

  return (
    <>
        <Stack direction={'row'} key={`patient_link_${resourceId}`} marginTop={isWeb() ? 0 : 6}>
          <DisplayText
            size={'smMedium'}
            extraStyles={{
              color: Colors.Custom.alertsDescriptionColor,
              fontWeight: '400',
              fontSize: 14,
            }}
            textLocalId={'linkNote'}
          />
        </Stack>
        <Stack
          direction={'column'}
          key={resourceId}
          style={styles.noteCard}
          my={1}>
          <Stack direction={'row'} flex={1}>
            <Stack direction={'row'} flex={9} alignItems="center">
              <Stack direction={'column'} flex={1}>
                {renderPatientNoteDateAndStatus(props.data, intl)}
                {renderPatientNoteCardHeadLine(
                  <>
                    {`${description || formName}`}
                    {isFold && (
                      <Text
                        color={Colors.Custom.Gray500}
                        fontSize={14}>{` • ${renderPatientNoteDiagnosis(
                        props?.data,
                      )}`}</Text>
                    )}
                  </>,
                )}
                {isOnlyChiefComplaintExist(props?.data) &&
                  renderPatientNoteChiefComplaint(
                    props?.data,
                    showMore[NoteExtraComponents.CHIEF_COMPLAINT],
                    handleShowMoreClick,
                  )}
                {renderPatientNoteAuthoredAndSignedBy(props?.data)}
              </Stack>
            </Stack>
            <Stack direction={'row'} alignItems="center" space={2}>
              {isWeb() && (
                <>
                  {renderVisitNoteLinkElement()}
                  {!isSidecarContext && (
                    <>
                      <Divider
                        orientation={'vertical'}
                        style={{
                          height: 18,
                          width: 1,
                          backgroundColor: Colors.Custom.Gray200,
                        }}
                      />

                      {renderAdditionalDetailLinkElement()}
                    </>
                  )}
                </>
              )}
            </Stack>
          </Stack>
        </Stack>
        {showAdditionalDetail && renderNoteAdditionalDetail()}
    </>
  );
};

export default PatientLinkNoteCard;
