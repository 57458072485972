import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {HStack, Text, VStack} from 'native-base';
import {
  getGenderTextByGenderCode,
  getPrimaryGenderCode,
} from '../../../../utils/commonUtils';
import {
  DISPLAY_SLASH_DATE_FORMAT,
  GROUP_MEMBER_TYPE,
  PERSON_TYPES,
} from '../../../../constants';
import {isWeb} from '../../../../utils/platformCheckUtils';
import {DisplayCardAvatar} from '../../DisplayCard/DisplayCardAvatar';
import AppUsingPatientSvg from '../../Svg/SideMenu/AppUsingPatientSvg';
import {IMemberInfoListItem} from '../../MemberInfoListItem/interface';
import {getCurrentPatientDeceasedData} from '../../../../services/CommonService/AidBoxService';
import {getEhrConfig} from '../../../../utils/capabilityUtils';
import {IContact} from '../../CareDashboard/CareDashboardInterfaces';
import {getAgeValue} from '../../../../utils/DateUtils';
import {Colors} from '../../../../styles';
import {Tooltip} from 'antd';
import {Tooltip as NBTooltip} from 'native-base';

const MemberContactInfo = (props: IMemberInfoListItem) => {
  const {
    contactData,
    isOnHeader,
    hideAvatar: hideAvatar,
    textColor,
    showDateOfBirth,
  } = props;
  const [deceasedDate, setDeceasedDate] = useState('');
  const birthDate = contactData?.person?.birthDate
    ? moment(contactData?.person?.birthDate).format(DISPLAY_SLASH_DATE_FORMAT)
    : '';
  const gender = getGenderTextByGenderCode(contactData);
  const genderProfileCode = getPrimaryGenderCode(contactData);
  const contactType = contactData?.contactType?.contactType
    ? contactData.contactType.contactType.code === PERSON_TYPES.CUSTOMER
      ? 'Patient'
      : contactData.contactType.contactType.code === PERSON_TYPES.CUSTOMER
      ? 'Prospect'
      : contactData.contactType.contactType.value
    : 'Prospect';
  const getPatientDeceasedData = async (contactData: IContact | undefined) => {
    const patientDeceasedDate = await getCurrentPatientDeceasedData(
      contactData || {}
    );
    setDeceasedDate(patientDeceasedDate);
  };
  const ehrConfig = getEhrConfig(
    contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid,
    ''
  );
  useEffect(() => {
    ehrConfig.isAthena && getPatientDeceasedData(contactData);
  }, [contactData?.patient?.patientId]);

  const getPatientSubTitle = (): string => {
    let subTitle = '';
    const ageValue = contactData?.person?.birthDate
      ? getAgeValue(contactData?.person?.birthDate, deceasedDate)
      : '';
    if (gender) {
      subTitle += `${gender}, `;
    }

    if (showDateOfBirth) {
      subTitle += `${birthDate}`;
      if (ageValue.length > 0) {
        subTitle += `(${ageValue})`;
      }
    } else {
      subTitle += `${ageValue}`;
    }
    return subTitle;
  };

  const renderContactName = (contactData: IContact | undefined) => {
    const Name = contactData?.name ? `${contactData?.name}` : ' ';
    const title = contactData?.name || '';

    if (isWeb()) {
      return (
        <Tooltip title={title} placement="top">
          {Name}
        </Tooltip>
      );
    } else {
      return (
        <NBTooltip label={title} placement="top">
          {Name}
        </NBTooltip>
      );
    }
  };

  return (
    <HStack key={contactData?.id} flex={1}>
      <DisplayCardAvatar
        avatarStyle={{
          avatarSize: '12',
          width: isOnHeader ? 32 : 48,
          height: isOnHeader ? 32 : 48,
        }}
        isLetterAvatarShow
        userData={{
          userId: contactData?.id || '',
          userType: GROUP_MEMBER_TYPE.CONTACT,
          genderCode: genderProfileCode as any,
          contactType: contactData?.contactType?.contactType?.code || '',
          name: contactData?.person,
          imgSrc:
            contactData?.contactProfilePhotos &&
            contactData?.contactProfilePhotos.length
              ? contactData?.contactProfilePhotos[0]?.profilePhotoUrl
              : undefined,
          userName: contactData?.name || '',
        }}
      />

      <VStack style={{marginLeft: 8}} flex={1} maxW={'100%'}>
        <HStack>
          <Text
            size={'smBold'}
            color={textColor || 'coolGray.700'}
            justifyContent={'center'}
            isTruncated={true}
            noOfLines={1}
            maxW={'90%'}
          >
            {renderContactName(contactData)}
          </Text>
          {contactType == 'Patient' && (
            <AppUsingPatientSvg
              loggedInTime={contactData?.additionalAttributes?.loggedInTime}
            />
          )}
        </HStack>
        <Text
          color={textColor ? Colors.Custom.Gray200 : Colors.Custom.Gray500}
          size={'xsMedium'}
        >
          {getPatientSubTitle()}
        </Text>
      </VStack>
    </HStack>
  );
};

export default MemberContactInfo;
