import React from 'react';
import {ClipPath, Defs, G, Path, Rect, Svg} from 'react-native-svg';

const UnknownStatusSvg = (props: any) => {
  return (
    <Svg width="12" height="12" viewBox="0 0 12 12" fill="none">
      <G clip-path="url(#clip0_990_66)">
        <Path
          d="M5.0625 4.4375C5.0625 3.91973 5.48223 3.5 6 3.5C6.51777 3.5 6.9375 3.91973 6.9375 4.4375C6.9375 4.78122 6.75252 5.08174 6.47668 5.24494C6.23902 5.38555 6 5.59886 6 5.875V6.5M6 7.75V8M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
          stroke="#6F7A90"
          stroke-linecap="round"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_990_66">
          <Rect width="12" height="12" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default UnknownStatusSvg;
