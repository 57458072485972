import {ApolloError, useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {Calendar, Popover, Radio, Segmented, Select} from 'antd';
import {Content} from 'antd/lib/layout/layout';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Icon,
  Skeleton,
  Slider,
  Spacer,
  Spinner,
  Stack,
  Text,
  useToast,
  View,
  VStack,
} from 'native-base';
import {useCallback, useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import Feather from 'react-native-vector-icons/Feather';
import {StyleProp, StyleSheet, TextStyle} from 'react-native';
import {
  BUTTON_TYPE,
  DATE_FORMATS,
  GROUP_MEMBER_TYPE,
  SCREEN_CONTEXT,
} from '../../../constants';
import {
  CARESTUDIO_APOLLO_CONTEXT,
  CARESTUDIO_PROXY_TO_CRM_CONTEXT,
} from '../../../constants/Configs';
import {
  APPOINTMENT_PARTICIPANT_TYPE_CODES,
  APPOINTMENT_STATUS_CODES,
  LOCATION_TYPE_CODES,
  MLOV_CATEGORY,
  MLOV_CODES,
} from '../../../constants/MlovConst';
import {IEhrCapability, IUser} from '../../../Interfaces';
import {
  AppointmentQueries,
  FormsQueries,
  MlovQueries,
  ScheduleEventQueries,
  UserQueries,
} from '../../../services';
import ContactsQueries from '../../../services/Contacts/ContactsQueries';
import {ITimezone} from '../../../services/Location/interfaces';
import {IUserSettingsByCode} from '../../../services/UserSettings/interfaces';
import * as UserSettingQueries from '../../../services/UserSettings/UserSettingQueries';
import {Colors} from '../../../styles';
import {
  getConfigDataFromCode,
  getInitialsFromFullName,
  isVirtualLocationDisabled,
  isVirtualLocationEnabledInAvailability,
  skipSecondaryUsersForCareTeamTypeAppointmentType,
} from '../../../utils/commonUtils';
import {showToast, ToastType} from '../../../utils/commonViewUtils';
import {
  addDaysInDate,
  convertToTimezone,
  currentMonth,
  currentYear,
  detectMonthYearChange,
  getCurrentTimeZone,
  getCustomDateRangeForAppointments,
  getDateObject,
  getDateObjectFromStringAndFormat,
  getDateRangeForAppointmentSlots,
  getDateStrFromFormat,
  getDateStrFromMomentObj,
  getDefaultEndDate,
  getDefaultStartDate,
  getFirstDateByMonthAndYear,
  getFormattedDate,
  getMomentObj,
  isCurrentDateInFutureComparedToOther,
  isDateBetweenRange,
  isDateInNextMonth,
  isDisabledMonth,
  isPastDateTime,
  isSameDate,
  isSameMonth,
} from '../../../utils/DateUtils';
import {
  getMlovByCategory,
  getMlovCodeFromId,
  getMlovIdFromCode,
  getMlovListFromCategory,
  getMlovValueFromId,
} from '../../../utils/mlovUtils';
import BrandingLogoWrapper from '../../common/BrandingLogoWrapper/BrandingLogoWrapper';
import {
  filterSlotsByAppointentType,
  formatAppointmentTypes,
  getBookingErrorTextMessage,
  getMaxDateForBooking,
} from '../../common/CalendarWidget/BookingWorkflows/BookAppointment/BookAppointmentHelper';
import {FormStatus} from '../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBookingEnums';
import {
  CONSENT_FORMS,
  getAccountLocationListFromUsersList,
  getApplicableContactLocations,
  getCompletePracticeLocation,
  isContactAndUserPracticeLocationSame,
  isFutureDateAllowed,
} from '../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBookingHelper';
import {
  IAppointmentUserDetail,
  ISlot,
  IUserPracticeLocation,
  IContact,
} from '../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBookingIntefaces';
import {DisplayCardAvatar} from '../../common/DisplayCard/DisplayCardAvatar';
import {DisplayText} from '../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../common/ModalActionBtn/ModalActionBtn';
import {TimezoneSelect} from '../../common/TimezoneSelect/TimezoneSelect';
import {IAppointmentType} from '../../RightSideContainer/AccountSettings/AppointmentTypes/Interfaces';
import {FHForm} from '../../RightSideContainer/Forms/FHFormio';
import {forEachExtensiveFormComponent} from '../../RightSideContainer/Forms/FormBuilderWidget/AddOrUpdateForm/AddOrUpdateFormHelper';
import {IContactPracticeLocations} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import AppointmentBookingSuccessPage from './AppointmentBookingSuccessPage';
import {
  APPOINTMENT_FORM_CODE,
  getConsentFormsFormAPIResponse,
  getDataForAppointmentBooking,
  getLocationFromId,
  getLocationTypeValue,
  getSelectedLocationValue,
  getSelectedUserValue,
  getUserFromId,
  isVirtualLocationType,
} from './AppointmentBookingWidgetHelper';
import './AppointmentDatePicker.css';
import ReasonForVisitFreeText from './ReasonForVisitFreeText';
import {cloneDeep} from 'lodash';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {AppointmentAvailableSlots} from '../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentAvailableSlots';
import {IDefaultFormTheme} from '../../RightSideContainer/Forms/interfaces';
import {CONFIG_CODES} from '../../../constants/AccountConfigConst';
import {FoldButton} from '../../CommonComponents/FoldButton/FoldButton';
import {
  getEhrCapabilitiesWithResource,
  getEhrCapabilitiesWithResourcePromise,
} from '../../../services/CommonService/AidBoxService';
import {CapabilityResource} from '../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import {IAccountLocation} from '../../RightSideContainer/Contacts/TeamMembers/interfaces';
import {AppointmentAvailabilityCode} from '../../RightSideContainer/AccountSettings/AppointmentTypes/constants';
import {GET_CARE_TEAM} from '../../../services/CareTeam/CareTeamQueries';
import TeamQueries from '../../../services/Team/TeamQueries';
import moment from 'moment';
import SlotsGroup from '../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/SlotsGroup';
import {usePractitionerIdentifierFilter} from '../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/hooks/usePractitionerIdentifierFilter/usePractitionerIdentifierFilter';
import {APPOINTMENT_TYPE_DATA_TYPE_CODES} from '../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentConstant';
import InviteeContact from './components/InviteeContact';
import {ModalActionAntSelect} from '../../common/ModalActionCommonComponent/ModalActionAntSelect';
import WriteInfoBackBanner from '../../PersonOmniView/MiddleContainer/CareTimeline/components/WriteInfoBackBanner';
import BookingDataMsg from './components/BookingDataMsg';
import WidgetDateAndSlotView from './components/WidgetDateAndSlotView';
import {fontWeight} from 'html2canvas/dist/types/css/property-descriptors/font-weight';
import {labData} from '../../../dummyData';
import WidgetCalendarView from './components/WidgetCalendarView';
import WidgetAppointmentDetails from './components/WidgetAppointmentDetails';
import {GET_USERS_AVAILABLE_SLOTS_FOR_DATE_RANGE} from '../../../services/Appointment/AppointmentQueries';
import {PracticeLocationsByLocationGroupId} from '../../../services/Location/UserPracticeLocationQueries';

export interface IAppointmentBookingParams {
  accountId: string;
  contactId?: string;
  roleId?: string;
  appointmentTypeId?: string;
  appointmentUsers?: string;
  [key: string]: any;
}

export interface IBookingWidgetData {
  initDataLoading: boolean;
  allGroupLocations?: IUserPracticeLocation[];
  appointmentType?: IAppointmentType;
  user?: IUser;
  slot?: ISlot;
  location?: IUserPracticeLocation;
  selectedDate: Date;
  userList: IUser[];
  secondaryUserList: IUser[];
  slotList: ISlot[];
  locationList: IUserPracticeLocation[];
  error: string;
  slotError: string;
  bookingAPILoading: boolean;
  reasonForVisit?: {
    code: string;
    displayName: string;
  };
  inviteeContact?: {
    name: string;
    id: string;
    uuid: string;
    email?: string;
    contactPracticeLocations?: IContactPracticeLocations[];
    personAddress?: {
      cityId?: string;
      stateId?: string;
      countryId?: string;
      zipcodeId?: string;
      zipcodes?: {
        cityId?: string;
        stateId?: string;
      };
      states?: {
        abbr?: string;
        id?: string;
      };
    }[];
  };
  consentForms: {
    id: string;
    name: string;
    isSelected: boolean;
    status: FormStatus;
  }[];
  appointmentCapability?: IEhrCapability;
  minDate: string;
  maxDate: string | undefined;
  slotSelectedDate: string;
  dayWiseSlotCountMap: Map<string, number>;
  dayWiseSlotMap: Map<string, ISlot[]>;
  userWiseSlotMap: Map<
    string,
    {
      [key: string]: ISlot[];
    }
  >;
  slotLoading: boolean;
  isAtClinicScheduleDisabled: boolean;
  disallowToScheduleForOtherLocation: boolean;
  accountSettings?: IUserSettingsByCode;
  skipSecondaryUsersForCareTeamType: boolean;
  locationTypeId: any;
  isShowPopover: boolean;
  selectedTimezone?: ITimezone;
  disAllowVirtualLocation?: boolean;
  displayContactLocation?: boolean;
  accountLocations: IAccountLocation[];
  allAccountLocations: IAccountLocation[];
}

enum BookingFlow {
  withContact,
  withoutContact,
}

enum BookingScreen {
  slotBooking,
  contactForm,
  successScreen,
}

const AppointmentBookingWidget = (props: {
  params: IAppointmentBookingParams;
}) => {
  const toast = useToast();
  const intl = useIntl();
  const apiErrorMessage = intl.formatMessage({id: 'apiErrorMsg'});
  const bookingFlow = props.params.contactId
    ? BookingFlow.withContact
    : BookingFlow.withoutContact;
  const [optionData] = useState({
    accountUUID: props.params.accountId,
  });
  const [bookingData, setBookingData] = useState<IBookingWidgetData>({
    error: '',
    slotError: '',
    userList: [],
    slotList: [],
    selectedDate: new Date(),
    consentForms: [],
    inviteeContact: undefined,
    bookingAPILoading: false,
    reasonForVisit: undefined,
    locationList: [],
    slotSelectedDate: '',
    maxDate: undefined,
    minDate: '',
    dayWiseSlotCountMap: new Map<string, number>(),
    dayWiseSlotMap: new Map<string, ISlot[]>(),
    slotLoading: true,
    isAtClinicScheduleDisabled: false,
    disallowToScheduleForOtherLocation: false,
    skipSecondaryUsersForCareTeamType: false,
    locationTypeId: '',
    isShowPopover: false,
    disAllowVirtualLocation: false,
    displayContactLocation: false,
    accountLocations: [],
    secondaryUserList: [],
    allAccountLocations: [],
    userWiseSlotMap: {} as Map<
      string,
      {
        [key: string]: ISlot[];
      }
    >,
    initDataLoading: false,
  });

  const [slotRange, setSlotRange] = useState({
    slotStartDate: '' as string,
    slotEndDate: '' as string,
    selectedMonth: currentMonth(),
    selectedYear: currentYear(),
  });
  const [bookingScreen, setBookingScreen] = useState<BookingScreen>(
    BookingScreen.slotBooking
  );
  const [bookingFormDetails, setBookingFormDetails] = useState<{
    bookingFormComponents: any[];
    formId: string;
    formValues?: any;
    isValid: boolean;
  }>({
    bookingFormComponents: [],
    formId: '',
    formValues: undefined,
    isValid: bookingFlow === BookingFlow.withContact,
  });
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const defaultThemeConfig = getConfigDataFromCode(CONFIG_CODES.DEFAULT_THEME);
  let configJson = undefined;
  let formThemeConfig: IDefaultFormTheme | undefined = {} as IDefaultFormTheme;
  if (
    defaultThemeConfig?.accountConfigurations &&
    defaultThemeConfig?.accountConfigurations?.length
  ) {
    configJson = JSON.parse(
      defaultThemeConfig?.accountConfigurations[0]?.value
    );
    formThemeConfig = configJson?.form || undefined;
  } else if (defaultThemeConfig?.defaultValue) {
    configJson = JSON.parse(defaultThemeConfig?.defaultValue);
    formThemeConfig = configJson?.form || undefined;
  }

  const numberAccountId = props.params.numberAccountId;
  const accountIdHeaders = {
    'account-uuid': props.params.accountId,
    ...(numberAccountId && {'x-hasura-account-id': numberAccountId}),
  };
  const headers = {
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
      headers: {
        ...accountIdHeaders,
      },
    },
  };
  const {practitionerAPILoading, getFilteredUsersBasedOnLicensedState} =
    usePractitionerIdentifierFilter({
      useProxy: true,
      headers: accountIdHeaders,
      userSettings: bookingData.accountSettings,
    });

  const careStudioMlovData = useQuery(MlovQueries.GetAllCareMlovsWithCategory, {
    fetchPolicy: 'no-cache',
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });

  const [getCareTeam, careTeamQuery] = useLazyQuery(GET_CARE_TEAM, {
    fetchPolicy: 'no-cache',
    ...headers,
  });

  const crmMlovData = useQuery(MlovQueries.GetAllMlovsWithCategory, {
    fetchPolicy: 'no-cache',
    context: {service: CARESTUDIO_PROXY_TO_CRM_CONTEXT},
  });

  const [fetchAllLocations] = useLazyQuery(PracticeLocationsByLocationGroupId, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_PROXY_TO_CRM_CONTEXT,
      headers: {
        ...accountIdHeaders,
      },
    },
  });

  const onCompleteAccountLocationsAPI = (
    data: any,
    newBookingData: IBookingWidgetData
  ) => {
    if (data?.accountLocations?.length) {
      setBookingData((prev) => ({
        ...prev,
        allAccountLocations: data?.accountLocations,
        isAccountLocationApiCompleted: true,
      }));
    } else {
      setBookingData((prev) => ({
        ...prev,
        isAccountLocationApiCompleted: true,
      }));
    }
  };

  const [getAccountLocations] = useLazyQuery(TeamQueries.GetLocations, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_PROXY_TO_CRM_CONTEXT,
      headers: {
        ...accountIdHeaders,
      },
    },
    variables: {
      accountUuid: props.params.accountId,
    },
    onError: (error) => {
      setBookingData((prev) => ({
        ...prev,
        isAccountLocationApiCompleted: false,
      }));
    },
  });

  const onCompleteAccountSettingsAPI = (
    response: any,
    newBookingData: IBookingWidgetData
  ) => {
    const accountSettings: IUserSettingsByCode = {};

    let isAtClinicScheduleDisabled = false;
    let disallowToScheduleForOtherLocation = false;
    let displayContactLocation = false;

    (response?.defaultUserSettings || []).forEach((defaultUserSetting: any) => {
      if (!defaultUserSetting) return;

      const id: string = defaultUserSetting.id;
      const code: string = defaultUserSetting.code;
      let value: string = defaultUserSetting.value;

      if (defaultUserSetting.userSettings?.[0]) {
        value = defaultUserSetting.userSettings[0].value;
      }

      accountSettings[code] = {id, code, value};

      isAtClinicScheduleDisabled =
        accountSettings['is_at_clinic_schedule_disabled']?.value === 'True' ||
        false;
      disallowToScheduleForOtherLocation =
        accountSettings['disallow_to_schedule_for_other_location']?.value ===
          'True' || false;
      displayContactLocation =
        accountSettings['is_allow_virtual_location_for_schedule_availability']
          ?.value === 'True' || false;

      // setBookingData((prev) => {
      //   return {
      //     ...prev,
      //     accountSettings,
      //     isAccountSettingsApiCompleted: true,
      //     isAtClinicScheduleDisabled,
      //     disallowToScheduleForOtherLocation,
      //     displayContactLocation,
      //     skipSecondaryUsersForCareTeamType:
      //       skipSecondaryUsersForCareTeamTypeAppointmentType(
      //         accountSettings
      //       ),
      //   };
      // });

      newBookingData.accountSettings = accountSettings;
      newBookingData.isAtClinicScheduleDisabled = isAtClinicScheduleDisabled;
      newBookingData.disallowToScheduleForOtherLocation =
        disallowToScheduleForOtherLocation;
      newBookingData.displayContactLocation = displayContactLocation;
      newBookingData.skipSecondaryUsersForCareTeamType =
        skipSecondaryUsersForCareTeamTypeAppointmentType(accountSettings);
      return newBookingData;
    });
  };

  const [accountSettingsApi] = useLazyQuery(
    UserSettingQueries.GetAccountSettings,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
      variables: {
        tenantId: props.params.accountId,
      },
      onError: () => {
        handleError('Something went wrong!!!');
      },
    }
  );

  const fetchCapability = (capabilityList: string[]) => {
    return getEhrCapabilitiesWithResourcePromise(
      capabilityList[0],
      true,
      {
        ...accountIdHeaders,
      },
      bookingData?.inviteeContact?.contactPracticeLocations?.[0]
        ?.accountLocation?.uuid
    );
  };

  const formattedCareStudioMlovData = getMlovByCategory(
    careStudioMlovData.data
  );

  const formattedCrmMlovData = getMlovByCategory(crmMlovData.data);

  const appointmentParticipantStatusList =
    getMlovListFromCategory(
      formattedCareStudioMlovData,
      MLOV_CATEGORY.APPOINTMENT_PARTICIPANT_STATUS
    ) || [];
  const appointmentStatusList =
    getMlovListFromCategory(
      formattedCareStudioMlovData,
      MLOV_CATEGORY.APPOINTMENT_STATUS
    ) || [];

  const userRoles =
    getMlovListFromCategory(
      formattedCrmMlovData || {},
      MLOV_CATEGORY.USER_ROLES
    ) || [];

  const appointmentFieldTypeMlovs =
    getMlovListFromCategory(
      formattedCareStudioMlovData || {},
      MLOV_CATEGORY.APPOINTMENT_CUSTOM_FIELD_TYPE
    ) || [];

  const durationMlovs =
    getMlovListFromCategory(
      formattedCareStudioMlovData || {},
      MLOV_CATEGORY.DATE_TIME_DURATION
    ) || [];

  const scheduleLocationTypeList =
    getMlovListFromCategory(
      formattedCareStudioMlovData,
      MLOV_CATEGORY.SCHEDULE_LOCATION_TYPE
    ) || [];

  const appointmentParticipantType =
    getMlovListFromCategory(
      formattedCareStudioMlovData,
      MLOV_CATEGORY.APPOINTMENT_PARTICIPANT_TYPE
    ) || [];
  const APPOINTMENT_PARTICIPANT_TYPE_IDS = {
    patient: getMlovIdFromCode(
      appointmentParticipantType,
      APPOINTMENT_PARTICIPANT_TYPE_CODES.PATIENT
    ),
    primaryUser: getMlovIdFromCode(
      appointmentParticipantType,
      APPOINTMENT_PARTICIPANT_TYPE_CODES.PRIMARY_USER
    ),
    secondaryUser: getMlovIdFromCode(
      appointmentParticipantType,
      APPOINTMENT_PARTICIPANT_TYPE_CODES.SECONDARY_USER
    ),
  };

  const [bookAppointment] = useMutation(AppointmentQueries.BOOK_APPOINTMENT);
  const [submitForm] = useMutation(FormsQueries.SUBMIT_FORM_RESPONSE);

  const MAX_FUTURE_DATE_RANGE = 90;

  const getSlotDate = () => {
    const defaultStartDate = getDefaultStartDate();
    const defaultEndDate = getMomentObj(new Date())
      .endOf('day')
      .format(DATE_FORMATS.DISPLAY_DATE_FORMAT);
    const slotStartTime = bookingData?.appointmentType?.slotStartTime;
    const slotEndTime = bookingData?.appointmentType?.slotEndTime;

    if (slotStartTime || slotEndTime) {
      const customSlotRange = getDateRangeForAppointmentSlots(
        slotStartTime,
        slotEndTime
      );
      return {
        slotStartDate:
          customSlotRange?.startDate ||
          slotRange?.slotStartDate ||
          defaultStartDate,
        slotEndDate:
          customSlotRange?.endDate || slotRange?.slotEndDate || defaultEndDate,
      };
    } else {
      return {
        slotStartDate: slotRange?.slotStartDate || defaultStartDate,
        slotEndDate: slotRange?.slotEndDate || defaultEndDate,
      };
    }
  };

  const getSlotQueryParams = (
    selectedLocationId: string | undefined,
    isVirtualLocation: boolean
  ) => {
    let appointmentData = undefined;
    if (
      bookingData?.appointmentType?.appointmentTypeGroup &&
      bookingData?.appointmentType?.appointmentTypeGroup.length > 0
    ) {
      appointmentData = bookingData?.appointmentType.appointmentTypeGroup;
    }
    const userIds = [...bookingData.userList.map((user) => user.uuid)];
    const secondaryUserIds: string[] = [];
    if (
      bookingData?.appointmentType?.availabilityTypeCode ===
        AppointmentAvailabilityCode.CARE_TEAM ||
      bookingData?.appointmentType?.availabilityTypeCode ===
        AppointmentAvailabilityCode.PROVIDER
    ) {
      const otherSecondaryUsers = bookingData.userList.filter(
        (item) => item.uuid !== bookingData.user?.uuid
      );
      const secondaryUsers = [
        ...otherSecondaryUsers,
        ...bookingData?.secondaryUserList,
      ];

      if (
        secondaryUsers?.length &&
        !bookingData.skipSecondaryUsersForCareTeamType
      ) {
        (secondaryUsers || []).forEach((item) =>
          secondaryUserIds.push(item.uuid)
        );
      }
    }

    const slotDate = getMomentObj(bookingData.selectedDate).format(
      DATE_FORMATS.DISPLAY_DATE_FORMAT
    );

    return {
      locationId: selectedLocationId,
      ...(bookingData?.appointmentType?.id && {
        appointmentTypeId: bookingData?.appointmentType?.id,
      }),
      userIds: userIds,
      secondaryUserIds,
      ...(props?.params?.contactId && {contactIds: [props.params.contactId]}),
      slotStartDate: slotDate,
      slotEndDate: slotDate,
      duration: bookingData?.appointmentType?.duration || 30,
      isVirtualLocation: isVirtualLocation,
      timezone: bookingData.selectedTimezone?.timezone || getCurrentTimeZone(),
    };
  };

  const getAvailableUserTimeSlots = (
    userWiseSlotMap: Map<
      string,
      {
        [key: string]: ISlot[];
      }
    >,
    dayKey: string
  ) => {
    const slotListOfAllUsers: ISlot[][] = [];
    if (userWiseSlotMap?.size && bookingData?.user?.uuid) {
      const slots = userWiseSlotMap.get(bookingData.user.uuid) || {};
      slotListOfAllUsers.push(slots[dayKey as keyof typeof slots] || []);
    }
    return !slotListOfAllUsers.some((slotList) => slotList?.length === 0);
  };

  const getAvailableTimeSlotsDate = (
    dayWiseSlots: Map<string, ISlot[]>,
    dayKey: string | undefined
  ) => {
    if (dayWiseSlots?.size) {
      if (dayKey) {
        const slots: ISlot[] = dayWiseSlots.get(dayKey) || [];
        if (slots?.length) {
          return dayKey;
        }
      }
      let availableSlotDayKey: string | undefined = undefined;
      dayWiseSlots.forEach((slotCount, key) => {
        if (slotCount?.length > 0 && !availableSlotDayKey) {
          availableSlotDayKey = key;
        }
      });
      return availableSlotDayKey;
    }
    return undefined;
  };

  const [getUserWiseAvailabelSlots] = useLazyQuery(
    GET_USERS_AVAILABLE_SLOTS_FOR_DATE_RANGE,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data: any) => {
        const userWiseSlotMap: Map<
          string,
          {
            [key: string]: ISlot[];
          }
        > = new Map<
          string,
          {
            [key: string]: ISlot[];
          }
        >();
        const responseSlot = data?.getAvailableSlots?.slots || {};
        if (Object.keys(responseSlot)?.length) {
          Object.keys(responseSlot).forEach((key) => {
            userWiseSlotMap.set(key, responseSlot[key]?.slots);
          });
        } else {
          userWiseSlotMap.set(bookingData.selectedDate.toString(), {});
        }
        setBookingData((prev) => ({
          ...prev,
          userWiseSlotMap: userWiseSlotMap,
          slotLoading: false,
        }));
      },
    }
  );

  const [getAvailableTimeSlotsForDateRange, getAvailableTimeSlotsQuery] =
    useLazyQuery(AppointmentQueries.GET_AVAILABLE_SLOTS_FOR_DATE_RANGE, {
      fetchPolicy: 'no-cache',
      onCompleted: (data: any) => {
        let availableDaySlots: ISlot[] = [];
        const dayWiseSlots: Map<string, ISlot[]> = new Map<string, ISlot[]>();
        const dayWiseSlotCount: Map<string, number> = new Map<string, number>();
        const responseSlot =
          data?.getAvailableTimeSlotsForDateRange?.slots || {};
        if (Object.keys(responseSlot)?.length) {
          const appointmentDateKey = Object.keys(responseSlot).sort(
            (prev: string, next: string) => {
              const date1 = new Date(prev);
              const date2 = new Date(next);
              return date1.getTime() - date2.getTime();
            }
          );
          appointmentDateKey.forEach((key: string) => {
            const dayKey = key.split('/').join('-');
            const slotList = getDayWiseSlotsFromResponse(
              responseSlot[key] || []
            );
            dayWiseSlots.set(dayKey, slotList);
            dayWiseSlotCount.set(dayKey, slotList.length);
          });
        }

        if (dayWiseSlots?.size) {
          const currentSelectDateStr = getDateStrFromFormat(
            bookingData.selectedDate,
            DATE_FORMATS.DISPLAY_DATE_FORMAT
          );
          const availableSlotDayKey = getAvailableTimeSlotsDate(
            dayWiseSlots,
            currentSelectDateStr
          );
          if (availableSlotDayKey) {
            availableDaySlots = dayWiseSlots?.get(availableSlotDayKey) || [];
            const selectedDate = getDateObject(availableSlotDayKey);
            const slotSelectDate = getDateObject(currentSelectDateStr);
            if (!isSameDate(selectedDate, slotSelectDate)) {
              showToast(
                toast,
                'Taking you to next available slot',
                ToastType.info
              );
            }
            const filteredSlotList = filterSlotsByAppointentType(
              availableDaySlots,
              bookingData.appointmentType
            );
            setBookingData((prev) => ({
              ...prev,
              dayWiseSlotCountMap: dayWiseSlotCount,
              dayWiseSlotMap: dayWiseSlots,
              selectedDate: availableSlotDayKey
                ? new Date(availableSlotDayKey)
                : new Date(),
              slotList: filteredSlotList,
              // No need to set selected slots
              // slot: filteredSlotList?.length
              //   ? filteredSlotList[0]
              //   : undefined,
              slotLoading: false,
            }));
          } else {
            setBookingData((prev) => ({
              ...prev,
              dayWiseSlotCountMap: dayWiseSlotCount,
              dayWiseSlotMap: dayWiseSlots,
              selectedDate: availableSlotDayKey
                ? new Date(availableSlotDayKey)
                : new Date(),
              slotList: filterSlotsByAppointentType(
                availableDaySlots,
                bookingData.appointmentType
              ),
              slot: undefined,
              slotLoading: false,
            }));
          }
        } else {
          setBookingData((prev) => ({
            ...prev,
            dayWiseSlotCountMap: new Map<string, number>(),
            dayWiseSlotMap: new Map<string, ISlot[]>(),
            slotList: [],
            slot: undefined,
            slotLoading: false,
          }));
        }
      },
      onError: (error) => {
        setBookingData((prev) => ({
          ...prev,
          slotLoading: false,
        }));
      },
    });

  const getDayWiseSlotsFromResponse = (slotList: any[]): ISlot[] => {
    if (slotList && slotList?.length) {
      return slotList.filter((slot: ISlot) => {
        return isCurrentDateInFutureComparedToOther(
          slot.startDateTime,
          new Date()
        );
      });
    }
    return [] as ISlot[];
  };

  const [getContactUUID] = useLazyQuery(
    ContactsQueries.GET_CONTACT_UUID_BY_EMAIL,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_PROXY_TO_CRM_CONTEXT,
        headers: {
          ...accountIdHeaders,
        },
      },
    }
  );

  const [getUserDetails, usersAPIQuery] = useLazyQuery(
    UserQueries.GET_USERS_BY_ROLE_IDS,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_PROXY_TO_CRM_CONTEXT,
        headers: {
          ...accountIdHeaders,
        },
      },
    }
  );

  const [getUserDetailsById, usersByIdAPIQuery] = useLazyQuery(
    UserQueries.GET_USERS_WITH_LOCATION_AND_ROLES_FROM_IDS,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_PROXY_TO_CRM_CONTEXT,
        headers: {
          ...accountIdHeaders,
        },
      },
    }
  );

  let appointmentTypeQueryCode =
    ScheduleEventQueries.GET_DEFAULT_APPOINTMENT_TYPE;
  if (props.params.roleId) {
    appointmentTypeQueryCode =
      ScheduleEventQueries.GET_PATIENT_FACING_APPOINTMENT_TYPE_BY_ROLE_ID;
  } else if (props.params.appointmentTypeId) {
    appointmentTypeQueryCode =
      ScheduleEventQueries.GET_PATIENT_FACING_APPOINTMENT_TYPE_BY_APPOINTMENT_TYPE_ID;
  }

  const onAppointmentTypeAPICompleted = async (data: any, contact?: IBookingWidgetData['inviteeContact']) => {
    if (data && data.appointmentTypes && data.appointmentTypes.length > 0) {
      data.appointmentTypes = data.appointmentTypes.filter(
        (appointmentType: IAppointmentType) => {
          if (
            bookingData.isAtClinicScheduleDisabled &&
            appointmentType?.locationType?.code ===
              LOCATION_TYPE_CODES.AT_CLINIC
          ) {
            return false;
          }
          if (
            appointmentType?.appointmentTypeGroup?.length > 0 ||
            appointmentType.availabilityTypeCode ===
              AppointmentAvailabilityCode.CARE_TEAM ||
            appointmentType.availabilityTypeCode ===
              AppointmentAvailabilityCode.PROVIDER
          ) {
            return true;
          }
          return false;
        }
      );

      if (props.params.appointmentTypeId) {
        const appointmentType = data.appointmentTypes.find(
          (appointmentType: IAppointmentType) => {
            if (props.params.appointmentTypeId === appointmentType?.id) {
              return true;
            }
            return false;
          }
        );

        if (appointmentType) {
          data.appointmentTypes = [appointmentType];
        } else {
          return handleError(
            'Your practice do not have the selected appointment type'
          );
        }
      }

      const formattedAppointmentTypes = formatAppointmentTypes(
        data.appointmentTypes,
        [...durationMlovs, ...appointmentFieldTypeMlovs]
      );
      const selectedAppointmentType =
        formattedAppointmentTypes?.[0] as IAppointmentType;
      const todayDateString = getFormattedDate(
        new Date(),
        DATE_FORMATS.DISPLAY_DATE_FORMAT
      );

      const locationResponse = await fetchAllLocations({
        variables: {
          locationGroupId: selectedAppointmentType.locationGroupId,
        },
      });
      const locationList = locationResponse?.data
        ?.userPracticeLocations as IUserPracticeLocation[];

      setBookingData((prev) => ({
        ...prev,
        appointmentType: selectedAppointmentType,
        locationTypeId: selectedAppointmentType.locationTypeId,
        minDate: todayDateString,
        maxDate: getMaxDateForBooking(selectedAppointmentType),
        allGroupLocations: locationList,
      }));
     await fetchUserData(selectedAppointmentType, locationList, contact || bookingData.inviteeContact);
    } else {
      handleError(
        props.params.roleId
          ? 'There is no staff available for this service in the practice, please call your practice to book an appointment'
          : 'Your practice has not set up any appointments to be booked directly, please call your practice to book an appointment'
      );
      setBookingData((prev) => ({
        ...prev,
        slotLoading: false,
      }));
    }
  };

  const [getAppointmentTypeAPI, appointmentTypeAPIQuery] = useLazyQuery(
    appointmentTypeQueryCode,
    {
      variables: {
        searchString: `%%`,
        categoryCodes: [MLOV_CODES.ONE_ON_ONE_APPOINTMENT],
        ...(props.params.roleId && {roleId: props.params.roleId}),
        ...(props.params.appointmentTypeId && {
          id: props.params.appointmentTypeId,
        }),
      },
      fetchPolicy: 'no-cache',
      ...headers,
      onError: (error: ApolloError) => {
        handleError(apiErrorMessage, error);
        setBookingData((prev) => ({
          ...prev,
          slotLoading: false,
        }));
      },
    }
  );

  useQuery(FormsQueries.GET_FORM_BY_CODE, {
    skip: bookingFlow === BookingFlow.withContact,
    variables: {
      code: APPOINTMENT_FORM_CODE,
    },
    fetchPolicy: 'no-cache',
    ...headers,
    onCompleted: (data) => {
      if (data?.form?.components) {
        const components = data.form.components.filter(
          (component: any) => component.action !== 'submit'
        );
        setBookingFormDetails((prev) => ({
          ...prev,
          formId: data.form.id,
          bookingFormComponents: components || [],
        }));
      }
    },
  });

  const consentFormData = useQuery(
    bookingFlow === BookingFlow.withContact
      ? FormsQueries.GET_FORMS_BY_NAMES_WITH_AGGREGATE
      : FormsQueries.GET_FORMS_WITH_NAMES,
    {
      variables: {
        formNames: CONSENT_FORMS,
        contactId: props.params.contactId,
      },
      fetchPolicy: 'no-cache',
      ...headers,
      onCompleted: (data) => {
        if (data.forms && data.forms.length > 0) {
          const formData = getConsentFormsFormAPIResponse(data);
          setBookingData((prev) => ({
            ...prev,
            consentForms: formData,
          }));
        }
      },
    }
  );

  // const findContactLocationFromLocationList = (
  //   contactPracticeLocations?: IContactPracticeLocations[],
  //   locationList?: IUserPracticeLocation[]
  // ) => {
  //   if (
  //     !contactPracticeLocations ||
  //     !contactPracticeLocations.length ||
  //     !locationList ||
  //     !locationList.length
  //   ) {
  //     return undefined;
  //   }

  //   const practiceLocationUuids = contactPracticeLocations
  //     ?.map?.((location: IUserPracticeLocation) => {
  //       return (
  //         location?.practiceLocationUuid ||
  //         location?.accountLocation?.practiceLocation?.uuid
  //       );
  //     })
  //     .filter((locationUuid: any) => !!locationUuid);

  //   if (!practiceLocationUuids || !practiceLocationUuids.length)
  //     return undefined;

  //   const location = locationList.find((location) => {
  //     const practiceLocationUuid =
  //       location?.practiceLocationUuid ||
  //       location?.accountLocation?.practiceLocation?.uuid;
  //     if (
  //       practiceLocationUuid &&
  //       practiceLocationUuids.includes(practiceLocationUuid)
  //     )
  //       return true;
  //   });

  //   return location;
  // };

  const onContactAPICompleted = (data: any) => {
    if (data && data.contacts && data.contacts.length > 0) {
      return data.contacts[0];
    } else {
      handleError('This link is not valid, please contact support');
    }
  };

  const [getContactApi, contactAPIQuery] = useLazyQuery(
    ContactsQueries.GET_CONTACT_BY_UUID,
    {
      // skip: bookingFlow === BookingFlow.withoutContact,
      variables: {
        contactId: props.params.contactId,
      },
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_PROXY_TO_CRM_CONTEXT,
        headers: {
          ...accountIdHeaders,
        },
      },
      onError: (error) => {
        handleError(apiErrorMessage, error);
      },
    }
  );

  const isUserExist = (userId: string, userIds: string[]) => {
    return userIds.some((id) => {
      return userId === id;
    });
  };

  const setWidgetUsers = (userList: any[]) => {
    const userIds = props.params?.appointmentUsers || undefined;
    if (userIds?.length && userList?.length) {
      const userIdList = userIds.split(',');
      return userList.filter((user: any) => {
        return isUserExist(user.uuid, userIdList);
      });
    }
    return userList;
  };

  const fetchUserData = (type: IAppointmentType, allGroupLocations: IUserPracticeLocation[], contact: IBookingWidgetData['inviteeContact']) => {
    let promise = undefined
    switch (type.availabilityTypeCode) {
      case AppointmentAvailabilityCode.CARE_TEAM:
        promise = setCareTeamBasedUsers;
        break;
      case AppointmentAvailabilityCode.PROVIDER:
        promise = setProviderBasedUsers;
        break;
      case AppointmentAvailabilityCode.ROLE:
      default:
        promise = setRoleBasedUsers;
        break;
    }

    return promise?.(type, allGroupLocations,contact);
  };

  const setProviderBasedUsers = async (type: IAppointmentType, allGroupLocations: IUserPracticeLocation[], contact: IBookingWidgetData['inviteeContact']) => {
    const primaryUserIds: string[] = [];
    const secondaryUserIds: string[] = [];
    type.userPool?.userPoolUsers?.forEach((item) => {
      if (item.userId) {
        if (item.isDefault) {
          primaryUserIds.push(item.userId);
        } else {
          secondaryUserIds.push(item.userId);
        }
      }
    });
    try {
      const userData = await getUserDetailsById({
        variables: {
          userIds: [...primaryUserIds, ...secondaryUserIds],
          accountId: props.params.accountId,
        },
      });
      const filteredUsers: IUser[] = getFilteredUsers(userData) || [];
      const primaryFilteredUsers: IUser[] = [];
      const secondaryFilteredUsers: IUser[] = [];
      filteredUsers?.forEach((item) => {
        const isPrimary = primaryUserIds.includes(item.uuid);
        if (isPrimary) {
          primaryFilteredUsers.push(item);
        } else {
          secondaryFilteredUsers.push(item);
        }
      });
      setPrimaryAndSecondaryUsers(
        type,
        primaryFilteredUsers,
        secondaryFilteredUsers,
        allGroupLocations,
        contact
      );
      setBookingData((prev) => ({
        ...prev,
        slotLoading: false,
      }));
    } catch (error) {
      setBookingData((prev) => ({
        ...prev,
        slotLoading: false,
      }));
    }
  };

  const setPrimaryAndSecondaryUsers = (
    type: IAppointmentType,
    primaryFilteredUsers: IUser[],
    secondaryFilteredUsers: IUser[],
    allGroupLocations: IUserPracticeLocation[],
    contact: IBookingWidgetData['inviteeContact']
  ) => {
    if (primaryFilteredUsers && primaryFilteredUsers.length > 0) {
      const primaryUsers = getFilteredUsersBasedOnLicensedState(
        primaryFilteredUsers,
        contact
      );
      const secondaryUsers = getFilteredUsersBasedOnLicensedState(
        secondaryFilteredUsers,
        contact
      );
      const contactLocationId =
        contact?.contactPracticeLocations?.[0]?.uuid;
      const accountLocations =
        getAccountLocationListFromUsersList(primaryUsers);
      const isVirtualVisit =
        type.locationType?.code === LOCATION_TYPE_CODES.VIRTUAL;
      const selectedUser = primaryUsers[0];
      const userLocationList: IUserPracticeLocation[] =
        getSelectedUserLocations(selectedUser?.uuid, primaryUsers);
      const isVirtualLocation =
        isVirtualVisit &&
        !bookingData.disAllowVirtualLocation &&
        !bookingData.disallowToScheduleForOtherLocation;

      const locationList = getLocationList(
        isVirtualLocation,
        bookingData.disallowToScheduleForOtherLocation,
        allGroupLocations || [],
        contact as any
      );
      const contactAccountLocationId =
        contact?.contactPracticeLocations?.[0]
          ?.accountLocation?.uuid;
      const selectedLocation = locationList?.length
        ? locationList.find(
            (loc) => loc.accountLocation?.uuid === contactAccountLocationId
          )
        : undefined;

      setBookingData((prev) => ({
        ...prev,
        userList: primaryUsers,
        secondaryUserList: secondaryUsers,
        location: selectedLocation,
        locationList: locationList,
        accountLocations: accountLocations,
      }));
    } else {
      handleError(
        'Your Practice does not have an available staff to complete this appointment'
      );
    }
  };

  const setCareTeamBasedUsers = async (type: IAppointmentType, allGroupLocations: IUserPracticeLocation[], contact: IBookingWidgetData['inviteeContact']) => {
    try {
      const contactId = props?.params?.contactId;
      if (contactId) {
        const careTeamResponse = await getCareTeam({
          variables: {
            contactId: contactId,
          },
        });
        const careTeamUsers =
          careTeamResponse?.data?.careTeams?.[0]?.userPool?.userPoolUsers?.map(
            (user: any) => user.userId
          ) || [];
        const userData = await getUserDetailsById({
          variables: {
            userIds: careTeamUsers,
            accountId: props.params.accountId,
          },
        });
        const primaryRoleIds: string[] = ((type?.appointmentTypeGroup || [])
          .map((item) => item.roleId)
          .filter((item) => !!item) || []) as string[];
        const filteredUsers: IUser[] = getFilteredUsers(userData) || [];
        const primaryFilteredUsers: IUser[] = [];
        const secondaryFilteredUsers: IUser[] = [];
        filteredUsers?.forEach((item) => {
          if (item.userRoles?.length) {
            const isPrimary = item.userRoles.some((role) => {
              return (
                role.userRole?.userRole?.id &&
                primaryRoleIds.includes(role.userRole.userRole.id)
              );
            });
            if (isPrimary) {
              primaryFilteredUsers.push(item);
            } else {
              secondaryFilteredUsers.push(item);
            }
          } else {
            secondaryFilteredUsers.push(item);
          }
        });
        setPrimaryAndSecondaryUsers(
          type,
          primaryFilteredUsers,
          bookingData.skipSecondaryUsersForCareTeamType
            ? []
            : secondaryFilteredUsers,
          allGroupLocations,
          contact
        );
      }
      setBookingData((prev) => ({
        ...prev,
        slotLoading: false,
      }));
    } catch (error) {
      setBookingData((prev) => ({
        ...prev,
        slotLoading: false,
      }));
    }
  };

  const getFilteredUsers = (response: any) => {
    if (response?.data?.users?.length > 0) {
      let users = setWidgetUsers(response.data.users);
      const accountLocations = getAccountLocationListFromUsersList(users);
      const contactAvailableLocations = getApplicableContactLocations(
        bookingData.inviteeContact,
        accountLocations
      );
      if (
        (bookingData.disallowToScheduleForOtherLocation ||
          bookingData.displayContactLocation) &&
        users?.length &&
        contactAvailableLocations.length
      ) {
        users = users.filter((user: IUser) => {
          let locations = user.userPracticeLocations;
          locations = locations?.filter((location) => {
            if (
              isContactAndUserPracticeLocationSame(
                contactAvailableLocations,
                location as any
              )
            ) {
              return true;
            }
          });
          if (locations?.length) {
            user.userPracticeLocations = locations;
            return true;
          }
        });
      }
      if (bookingData.disAllowVirtualLocation) {
        users = users.filter((user: IUser) => {
          return user?.userPracticeLocations?.length ? true : false;
        });
      }
      return users;
    }
  };

  const setRoleBasedUsers = async (type: IAppointmentType, allGroupLocations: IUserPracticeLocation[], contact: IBookingWidgetData['inviteeContact']) => {
    const locationGroupId = type?.locationGroupId;
    const roleIds = (type?.appointmentTypeGroup || [])
      .map((group) => group.roleId)
      .filter((role) => !!role);

    if (roleIds.length > 0) {
      try {
        const response = await getUserDetails({
          variables: {
            roleIds,
            accountId: props.params.accountId,
          },
        });

        setBookingData((prev) => ({
          ...prev,
          slotLoading: false,
        }));
        if (response?.data?.users?.length > 0) {
          const defaultUsers = response.data.users;
          const filterUsers: any[] = [];
          let isMatchUserLocationGroup = false;
          (defaultUsers || []).forEach((user: any) => {
            if (user.userPracticeLocations?.length) {
              user.userPracticeLocations.forEach(
                (userPracticeLocation: any) => {
                  if (
                    userPracticeLocation.accountLocation?.locationGroupId ===
                    locationGroupId
                  ) {
                    isMatchUserLocationGroup = true;
                    user.userPracticeLocations = [userPracticeLocation];
                  }
                }
              );
            }
            if(isMatchUserLocationGroup) {
              filterUsers.push(user);
            }
          });
          let users = setWidgetUsers(filterUsers || []);
          const accountLocations = getAccountLocationListFromUsersList(users);
          const contactAvailableLocations = getApplicableContactLocations(
            contact,
            bookingData.allAccountLocations
          );
          users = getFilteredUsersBasedOnLicensedState(
            users,
            contact
          );
          if (
            (bookingData.disallowToScheduleForOtherLocation ||
              bookingData.displayContactLocation) &&
            users?.length &&
            contactAvailableLocations.length
          ) {
            users = users.filter((user: IUser) => {
              let locations = user.userPracticeLocations;

              locations = locations?.filter((location) => {
                if (
                  isContactAndUserPracticeLocationSame(
                    contactAvailableLocations,
                    location as any
                  )
                ) {
                  return true;
                }
              });

              if (locations?.length) {
                user.userPracticeLocations = locations;
                return true;
              }
            });
          }

          if (bookingData.disAllowVirtualLocation) {
            users = users.filter((user: IUser) => {
              return user?.userPracticeLocations?.length ? true : false;
            });
          }

          if (users && users.length > 0) {
            const isVirtualVisit =
              type.locationType?.code === LOCATION_TYPE_CODES.VIRTUAL;
            const isVirtualLocation =
              isVirtualVisit &&
              !bookingData.disAllowVirtualLocation &&
              !bookingData.disallowToScheduleForOtherLocation;

            const locationList = getLocationList(
              isVirtualLocation,
              bookingData.disallowToScheduleForOtherLocation,
              allGroupLocations || [],
              contact as any
            );
            const contactAccountLocationId =
              contact?.contactPracticeLocations?.[0]
                ?.accountLocation?.uuid;
            const selectedLocation = locationList?.length
              ? locationList.find(
                  (loc) =>
                    loc.accountLocation?.uuid === contactAccountLocationId
                )
              : undefined;
            const uniqueUserList = new Set(users.map((user) => user.uuid));
            const newList: IUser[] = [];
            uniqueUserList.forEach((userUuid) => {
              const user = users.find((user) => user.uuid === userUuid);
              if (user) {
                newList.push(user);
              }
            });
            setBookingData((prev) => ({
              ...prev,
              userList: newList,
              location: selectedLocation,
              accountLocations: accountLocations,
              locationList: locationList,
            }));
          } else {
            handleError(
              'Your Practice does not have an available staff to complete this appointment'
            );
          }
        } else {
          handleError(
            'This link is not valid as practice does not have any staff registered'
          );
        }
      } catch (error: any) {
        handleError(apiErrorMessage, error);
        setBookingData((prev) => ({
          ...prev,
          slotLoading: false,
        }));
      }
    }
  };

  const getSelectedUserLocations = (
    selectedUserId: string,
    userList: any[]
  ): any[] => {
    const selectedUser = (userList || []).find((singleUser) => {
      return singleUser?.uuid === selectedUserId;
    });

    const locations = selectedUser?.userPracticeLocations || [];
    return locations.filter(
      (location: any) => !!location.accountLocation?.practiceLocation
    );
  };

  const getShortLocationName = () => {
    const isVirtualVisit = isVirtualLocationType(
      scheduleLocationTypeList,
      bookingData.locationTypeId
    );
    if (
      isVirtualVisit &&
      bookingData.location?.accountLocation?.practiceLocation?.name &&
      isVirtualLocationEnabledInAvailability(bookingData.accountSettings)
    ) {
      return 'Virtual Appointment';
    }
    if (bookingData.location?.accountLocation?.practiceLocation?.name) {
      return bookingData.location.accountLocation.practiceLocation?.name;
    }
    return bookingData?.appointmentType?.locationType?.value || '';
  };

  const getLocationNameForDropdown = (location?: IUserPracticeLocation) => {
    if (location?.accountLocation?.practiceLocation?.name) {
      return location.accountLocation.practiceLocation.name;
    }
    return bookingData?.appointmentType?.locationType?.value || '';
  };

  const getLocationName = (): string => {
    if (bookingData.locationTypeId) {
      const locationType = scheduleLocationTypeList.find((locationType) => {
        return (
          bookingData.locationTypeId &&
          locationType.id === bookingData.locationTypeId &&
          locationType.code === 'VIRTUAL'
        );
      });
      if (locationType?.value) {
        return locationType?.value;
      }
    }
    if (bookingData.location?.accountLocation?.practiceLocation?.name) {
      const userPracticeLocation =
        bookingData.location.accountLocation.practiceLocation;
      let location = getCompletePracticeLocation(userPracticeLocation);
      location = location
        ? `${location} (${userPracticeLocation.name})`
        : userPracticeLocation.name;
      return location;
    }
    return bookingData?.appointmentType?.locationType?.value || '';
  };

  const bookBtnClicked = () => {
    // if (bookingFlow === BookingFlow.withContact) {
    //   scheduleAppointment(bookingData.inviteeContact?.uuid || '');
    // }
    setBookingScreen(BookingScreen.contactForm);
  };

  const scheduleAppointment = (contactId: string) => {
    setBookingData((prev) => ({...prev, bookingAPILoading: true}));
    const pendingAppointmentStatusId = getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.PENDING
    );
    bookAppointment({
      variables: {
        data: getDataForAppointmentBooking(
          bookingData,
          pendingAppointmentStatusId,
          contactId,
          appointmentParticipantStatusList,
          APPOINTMENT_PARTICIPANT_TYPE_IDS
        ),
      },
      ...headers,
      onCompleted: (response) => {
        if (response?.createBookedAppointments?.length) {
          const appointmentData = response.createBookedAppointments[0];
          setBookingScreen(BookingScreen.successScreen);
        }
        setBookingData((prev) => ({...prev, bookingAPILoading: false}));
      },
      onError: (error: ApolloError) => {
        setBookingData((prev) => ({...prev, bookingAPILoading: false}));
        showToast(toast, apiErrorMessage, ToastType.error);
      },
    });
  };

  const isValidContactAppointmentForm = () => {
    return (
      bookingFormDetails.isValid &&
      (bookingFormDetails.formValues?.email ||
        bookingFormDetails.formValues?.phoneNumber)
    );
  };

  const confirmButtonClicked = () => {
    if (bookingFlow === BookingFlow.withContact) {
      scheduleAppointment(bookingData.inviteeContact?.uuid || '');
      return;
    }
    const formChanges = bookingFormDetails.formValues;
    const updatedComponents = bookingFormDetails.bookingFormComponents;
    if (isValidContactAppointmentForm()) {
      forEachExtensiveFormComponent(updatedComponents, (component) => {
        if (formChanges?.hasOwnProperty(component.key)) {
          component.selectedValue = formChanges[component.key];
        }
      });
      submitFormData(updatedComponents);

      setBookingData((prev) => ({...prev, bookingAPILoading: true}));
      /*
      Commented below code as it was fetching contact by email as it was unique before, now email is not unique anymore thus removed this
      Now every time new lead will be created

      getContactUUID({
        variables: {
          email: bookingFormDetails.formValues?.email,
        },
      })
        .then((response) => {
          let contactId: string | undefined = undefined;
          if (response?.data?.contacts?.length) {
            contactId = response.data.contacts[0].uuid;
          }
          forEachExtensiveFormComponent(updatedComponents, (component) => {
            if (formChanges?.hasOwnProperty(component.key)) {
              component.selectedValue = formChanges[component.key];
            }
          });
          submitFormData(updatedComponents, contactId);
        })
        .catch(() => {
          setBookingData((prev) => ({...prev, bookingAPILoading: false}));
        });
        */
    } else {
      showToast(
        toast,
        intl.formatMessage({id: 'emailOrPhoneNumberRequired'}),
        ToastType.error
      );
    }
  };

  const submitFormData = (updatedComponents: any, contactId?: string) => {
    const data: any = {
      formId: bookingFormDetails.formId,
      formResponse: {components: updatedComponents},
      contactId,
    };
    submitForm({
      variables: {
        data,
      },
      ...headers,
      onCompleted: (response) => {
        const contactUUID = contactId || response.submitFormResponse?.contactId;
        if (contactUUID) {
          scheduleAppointment(contactUUID);
        } else {
          setBookingData((prev) => ({...prev, bookingAPILoading: false}));
        }
      },
      onError: () => {
        setBookingData((prev) => ({...prev, bookingAPILoading: false}));
      },
    });
  };

  const handleSlotError = (message: string, error?: ApolloError) => {
    setBookingData((prev) => ({
      ...prev,
      slot: undefined,
      slotError: message,
    }));
  };

  const handleError = (message: string, error?: ApolloError) => {
    setBookingData((prev) => ({
      ...prev,
      error: message,
    }));
  };

  const fetchAvailableSlots = (
    selectedLocation?: IUserPracticeLocation,
    isUserChange?: boolean
  ) => {
    setBookingData((prev) => ({
      ...prev,
      slotLoading: true,
    }));
    const selectedLocationId = isUserChange
      ? selectedLocation?.accountLocation?.uuid
      : bookingData.location?.accountLocation?.uuid;

    const isVirtualVisit = isVirtualLocationType(
      scheduleLocationTypeList,
      bookingData.locationTypeId
    );
    let isVirtualLocation =
      (isVirtualVisit &&
        isVirtualLocationEnabledInAvailability(bookingData.accountSettings)) ||
      (isVirtualVisit &&
        !bookingData.disAllowVirtualLocation &&
        !bookingData.disallowToScheduleForOtherLocation);
    let locationId = selectedLocationId;

    if (bookingData.disallowToScheduleForOtherLocation) {
      if (selectedLocationId) {
        locationId = selectedLocationId;
      } else if (isVirtualLocation) {
        locationId = undefined;
      }
    } else if (isVirtualLocation) {
      locationId = undefined;
    }

    if (
      isVirtualVisit &&
      isVirtualLocationEnabledInAvailability(bookingData.accountSettings)
    ) {
      isVirtualLocation = true;
      locationId = undefined;
    }
    if (isVirtualLocation || locationId) {
      getUserWiseAvailabelSlots({
        context: {
          service: CARESTUDIO_APOLLO_CONTEXT,
          headers: {
            ...accountIdHeaders,
          },
        },
        variables: {
          data: getSlotQueryParams(locationId, isVirtualLocation),
        },
      });
    } else {
      setBookingData((prev) => ({
        ...prev,
        dayWiseSlotCountMap: new Map<string, number>(),
        dayWiseSlotMap: new Map<string, ISlot[]>(),
        slotList: [],
        slot: undefined,
        slotLoading: false,
      }));
    }
  };
  useEffect(() => {
    if (!bookingData.initDataLoading) {
      fetchAvailableSlots();
    }
  }, [
    bookingData.location,
    bookingData.selectedTimezone,
    slotRange?.slotStartDate,
    slotRange?.slotEndDate,
    bookingData.selectedDate,
    bookingData.disAllowVirtualLocation,
    bookingData.disallowToScheduleForOtherLocation,
    bookingData.locationTypeId,
    bookingData.initDataLoading,
  ]);

  const fetchInitData = () => {
    const promiseList = [] as Promise<any>[];
    promiseList.push(fetchCapability([CapabilityResource.appointment]));
    promiseList.push(accountSettingsApi());
    promiseList.push(getAccountLocations());

    return Promise.all(promiseList);
  };

  const processInitData = async () => {
    setBookingData((prev) => ({
      ...prev,
      initDataLoading: true,
    }));
    try {
      const [
        capabilityResponse,
        accountSettingsResponse,
        accountLocationResponse,
      ] = await fetchInitData();

      const newBookingData = bookingData;

      if (capabilityResponse?.data?.length) {
        newBookingData.appointmentCapability = capabilityResponse.data[0];
      }

      if (accountSettingsResponse?.data?.length) {
        onCompleteAccountSettingsAPI(
          accountSettingsResponse.data[0],
          newBookingData
        );
      }

      onCompleteAccountLocationsAPI(accountLocationResponse, newBookingData);

      const disAllowVirtualLocation = isVirtualLocationDisabled(
        bookingData.accountSettings || {},
        [bookingData.appointmentCapability || {}]
      );

      setBookingData((prev) => ({
        ...prev,
        disAllowVirtualLocation: disAllowVirtualLocation,
      }));

      if (bookingFlow === BookingFlow.withoutContact) {
        const appointmentTypeResponse = await getAppointmentTypeAPI();
        await onAppointmentTypeAPICompleted(appointmentTypeResponse?.data);
        setBookingData((prev) => ({
          ...prev,
          initDataLoading: false,
        }));
      } else {
        const contactApiResponse = await getContactApi();
        const contactData = await onContactAPICompleted(
          contactApiResponse?.data
        );
        const appointmentTypeResponse = await getAppointmentTypeAPI();
        await onAppointmentTypeAPICompleted(appointmentTypeResponse?.data, contactData);
        setBookingData((prev) => ({
          ...prev,
          inviteeContact: contactData,
          initDataLoading: false,
        }));
      }
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    processInitData();
  }, []);

  const handleFormOnChange = useCallback((data) => {
    setBookingFormDetails((prev) => ({
      ...prev,
      isValid: data.isValid,
      formValues: data.data,
    }));
  }, []);

  const getUserRole = () => {
    if (bookingData?.appointmentType?.appointmentTypeGroup?.length) {
      const roleId =
        bookingData.appointmentType?.appointmentTypeGroup[0].roleId;
      if (roleId) return getMlovValueFromId(userRoles, roleId);
    } else if (
      bookingData.user?.userRoles?.length &&
      bookingData.user.userRoles[0]?.userRole?.userRole?.value
    ) {
      return bookingData.user.userRoles[0]?.userRole?.userRole?.value;
    }
  };

  const isDisabledDate = (date: any) => {
    const renderDate = date?.format(DATE_FORMATS.DISPLAY_DATE_FORMAT);
    const count = bookingData?.dayWiseSlotCountMap?.get(renderDate) || 0;
    const isDisabled = !isDateBetweenRange(
      date,
      getSlotDate()?.slotStartDate,
      getSlotDate()?.slotEndDate
    );
    if (isDisabled) {
      return isDisabled;
    }
    return count === 0;
  };

  const selectedTimezone =
    bookingData.selectedTimezone?.timezone || getCurrentTimeZone();

  const renderTimeSlotErrors = (): JSX.Element => {
    const dayKey = getMomentObj(bookingData.selectedDate).format(
      DATE_FORMATS.DISPLAY_DATE_FORMAT
    );
    const isAvailable = getAvailableUserTimeSlots(
      bookingData.userWiseSlotMap,
      dayKey
    );
    if (!getAvailableTimeSlotsQuery.loading && !isAvailable) {
      return (
        <DisplayText
          textLocalId={'noUsersAvailable'}
          extraStyles={{
            color: Colors.Custom.Gray700,
            fontWeight: 500,
            fontSize: 14,
          }}
        />
      );
    }
    return <></>;
  };

  const handleSlotSelection = (slot: ISlot, user: IUser) => {
    setSlotRange((prev) => ({
      ...prev,
      startDateTime: slotRange.slotStartDate,
      endDateTime: slotRange.slotEndDate,
    }));
    setBookingData((prev) => ({
      ...prev,
      slot: slot,
      user: user,
    }));
    bookBtnClicked();
  };

  const getLocationList = (
    isVirtualLocation: boolean,
    disallowToScheduleForOtherLocation: boolean,
    locations: IUserPracticeLocation[],
    inviteeContact?: IContact
  ) => {
    const contactAvailableLocations = getApplicableContactLocations(
      bookingData.inviteeContact,
      bookingData.allAccountLocations
    );
    if (
      (disallowToScheduleForOtherLocation ||
        bookingData.displayContactLocation) &&
      contactAvailableLocations.length &&
      inviteeContact
    ) {
      return filterContactLocations(inviteeContact, locations);
    } else if (
      bookingData.displayContactLocation &&
      (!contactAvailableLocations.length || !locations?.length)
    ) {
      return [];
    } else if (
      disallowToScheduleForOtherLocation &&
      !contactAvailableLocations.length &&
      locations?.length
    ) {
      return locations;
    } else if (
      disallowToScheduleForOtherLocation &&
      !contactAvailableLocations.length &&
      !locations?.length
    ) {
      return [];
    } else if (isVirtualLocation) {
      return [];
    } else {
      return locations;
    }
  };

  const filterContactLocations = (
    inviteeContact: IContact,
    userPracticeLocations: IUserPracticeLocation[]
  ) => {
    if (userPracticeLocations?.length) {
      const contactAvailableLocations = getApplicableContactLocations(
        bookingData.inviteeContact,
        bookingData.allAccountLocations
      );
      return userPracticeLocations.filter((userPracticeLocation) => {
        return isContactAndUserPracticeLocationSame(
          contactAvailableLocations,
          userPracticeLocation
        );
      });
    }
    return [];
  };

  const isVirtualVisit = isVirtualLocationType(
    scheduleLocationTypeList,
    bookingData.locationTypeId
  );
  const isVirtualLocation =
    isVirtualVisit &&
    !bookingData.disAllowVirtualLocation &&
    !bookingData.disallowToScheduleForOtherLocation;

  const loading = bookingData.initDataLoading;

  const bgColor = props.params.bg ? `#${props.params.bg}` : 'white';
  const shortLocationName = getShortLocationName();

  const isCalendarMonthYearChange = (selectedMonth: number, selectedYear: number)=> {
    return detectMonthYearChange(selectedMonth,selectedYear,slotRange?.selectedMonth,slotRange?.selectedYear)
  }

  const allowToRenderLocation = () => {
    if (
      bookingData.disallowToScheduleForOtherLocation ||
      bookingData.displayContactLocation
    ) {
      return bookingData.locationList.length > 1;
    }
    return bookingData.locationList.length > 1;
  };

  const calendarHeaderText = () => {
    const dayKey = getMomentObj(bookingData.selectedDate).format(
      DATE_FORMATS.DISPLAY_DATE_FORMAT
    );

    const availableSlotDate = getAvailableUserTimeSlots(
      bookingData.userWiseSlotMap,
      dayKey
    );
    if (loading || getAvailableTimeSlotsQuery.loading) {
      return <></>;
    }
    if (!availableSlotDate) {
      return (
        <View flex={['none', 'none', 1]} alignItems={'center'}>
          <Text
            style={{
              fontSize: 16,
              fontWeight: '700',
              color: Colors.FoldPixel.GRAY400,
            }}
          >
            {`There is no slot available`}
          </Text>
        </View>
      );
    } else if (availableSlotDate && isPastDateTime(bookingData.selectedDate)) {
      return (
        <Text
          style={{
            fontSize: 16,
            fontWeight: '700',
            color: Colors.FoldPixel.GRAY400,
            textAlign: 'center',
          }}
        >
          Please select date
        </Text>
      );
    } else {
      return (
        <Text
          style={{
            fontSize: 16,
            fontWeight: '700',
            color: Colors.FoldPixel.GRAY400,
          }}
        >
          {getDateStrFromFormat(bookingData.selectedDate, DATE_FORMATS.DAY_NAME_WITH_MONTH_AND_YEAR)}
        </Text>
      );
    }
  };
  return (
    <Box
      borderColor={Colors.Custom.BorderColor}
      rounded="lg"
      borderWidth={1}
      backgroundColor={bgColor}
      marginX={{
        base: 0,
        lg: '5%',
        xl: '15%',
      }}
      marginY={
        props.params.isEmbedded === 'true'
          ? 0
          : {
              base: 0,
              lg: 8,
            }
      }
    >
      <View
        backgroundColor={formThemeConfig?.topBar?.backgroundColor || 'white'}
        height={100}
        rounded={'xs'}
      >
        <View marginY={2}>
          <BrandingLogoWrapper />
        </View>
      </View>
      <Divider orientation="horizontal" />
      <View
        padding={{
          base: 4,
          sm: 2,
          md: 4,
          lg: 4,
          xl: 4,
        }}
      >
        {loading && (
          <View minHeight={400} justifyContent={'center'} alignItems={'center'}>
            <Spinner size={'lg'} color={Colors.Custom.Gray500} />
          </View>
        )}
        {!loading && !!bookingData.error && (
          <Heading>{bookingData.error}</Heading>
        )}
        {!loading &&
          !bookingData.error &&
          bookingScreen === BookingScreen.slotBooking && (
            <VStack space={4}>
              <Stack
                direction={['column', 'column', 'row']}
                borderWidth={1}
                borderColor={Colors.Custom.BorderColor}
                rounded="lg"
              >
                <WidgetAppointmentDetails
                  isReadOnly={false}
                  bookingData={bookingData}
                  scheduleLocationTypeList={scheduleLocationTypeList}
                  handleModeOfAppointmentChange={(value: any[], data: any) => {
                    if (data?.value) {
                      setBookingData((prev) => {
                        return {
                          ...prev,
                          locationTypeId: data.value,
                          isShowPopover: !prev.isShowPopover,
                        };
                      });
                    }
                  }}
                  handleLocationChange={(value: any[], data: any) => {
                    if (data?.value) {
                      setBookingData((prev) => {
                        return {
                          ...prev,
                          location: bookingData.locationList.find(
                            (location) => location.uuid === data.value
                          ),
                          isShowPopover: !prev.isShowPopover,
                        };
                      });
                    }
                  }}
                  accountIdHeaders={accountIdHeaders}
                  handleTimezoneChange={(timezone?: ITimezone) => {
                    setBookingData((prev: any) => ({
                      ...prev,
                      selectedTimezone: timezone,
                    }));
                  }}
                />
                <WidgetCalendarView
                  bookingData={bookingData}
                  loading={loading || getAvailableTimeSlotsQuery.loading}
                  onChange={(momentDate) => {
                    const date = momentDate.format(
                      DATE_FORMATS.DISPLAY_DATE_FORMAT
                    );
                    const selectedMonth = getMomentObj(date).month() + 1;
                    const selectedYear = getMomentObj(date).year();
                    const bookWithinDays = bookingData?.appointmentType?.bookWithinDays;

                    const isPastDate = momentDate.isBefore(getMomentObj(new Date()), 'day');
                    if (isPastDate) {
                      showToast(
                        toast,
                        'Can not select date in past',
                        ToastType.info
                      );
                      return;
                    }
                    if (isFutureDateAllowed(date,bookWithinDays)) {
                      showToast(
                        toast,
                        getBookingErrorTextMessage(bookingData?.appointmentType, intl),
                        ToastType.info,
                        5000
                      );
                      return;
                    }
                    // if (selectedMonth !== slotRange?.selectedMonth || selectedYear !== slotRange?.selectedYear) {
                    //   setSlotRange((prev)=>{
                    //     return {
                    //       ...prev,
                    //       slotStartDate: startDate,
                    //       slotEndDate: endDate,
                    //       selectedMonth: selectedMonth,
                    //       selectedYear: selectedYear
                    //     }
                    //   })
                    // }
                    const sameMonth = isSameMonth(date)
                    const isMonthYearChange = (selectedMonth !== slotRange?.selectedMonth || selectedYear !== slotRange?.selectedYear)
                    // selectedDate: isSameMonth ? new Date() : moment(startDate),

                    setBookingData((prev:any) => {
                      const slotList =
                        prev.dayWiseSlotMap.get(date) || [];
                      const filteredSlotList = filterSlotsByAppointentType(slotList, bookingData.appointmentType);

                      return {
                        ...prev,
                        selectedDate: momentDate.toDate(),
                        slotSelectedDate: ''
                      };
                    });
                  }}
                  getSlotDate={getSlotDate}
                  slotRange={slotRange}
                />
                <WidgetDateAndSlotView
                  bookingData={bookingData}
                  selectedTimezone={selectedTimezone}
                  shortLocationName={shortLocationName}
                  slotsLoading={getAvailableTimeSlotsQuery.loading}
                  handleSlotSelection={handleSlotSelection}
                  calendarHeaderTextElem={calendarHeaderText}
                  renderTimeSlotErrors={renderTimeSlotErrors}
                />
              </Stack>
            </VStack>
          )}
        {!loading && bookingScreen === BookingScreen.contactForm && (
          <Stack
            direction={['column', 'column', 'row']}
            space={4}
            borderWidth={1}
            borderColor={Colors.Custom.BorderColor}
            rounded="lg"
          >
            <WidgetAppointmentDetails
              getUserRole={getUserRole}
              isReadOnly={true}
              bookingData={bookingData}
              scheduleLocationTypeList={scheduleLocationTypeList}
              accountIdHeaders={accountIdHeaders}
            />
            <VStack flex={['none', 'none', 0.6]} space={2} padding={2}>
              {bookingFlow === BookingFlow.withoutContact && (
                <FHForm
                  optionData={optionData}
                  components={bookingFormDetails.bookingFormComponents}
                  onChange={handleFormOnChange}
                />
              )}
              <View>
                <ReasonForVisitFreeText
                  customPlaceHolder="Enter Reason for Visit"
                  isShowError={true}
                  value={bookingData.reasonForVisit?.displayName}
                  onChange={(reasonForVisit) => {
                    setBookingData((prev) => ({
                      ...prev,
                      reasonForVisit: reasonForVisit,
                    }));
                  }}
                />
              </View>
              <View flex={1} justifyContent={'flex-end'}>
                <HStack space={2}>
                  {!isMobile ? <Spacer /> : <></>}
                  <FoldButton
                    nativeProps={{
                      variant: BUTTON_TYPE.SECONDARY,
                      isDisabled: bookingData.bookingAPILoading,
                      // isLoading: bookingData.bookingAPILoading,
                      onPress: () => {
                        setBookingScreen(BookingScreen.slotBooking);
                        setBookingData((prev) => ({
                          ...prev,
                          slot: undefined,
                          slotSelectedDate: '',
                          user: undefined,
                        }));
                      },
                    }}
                    customProps={{
                      btnText: 'Back',
                      withRightBorder: false,
                    }}
                  />
                  <FoldButton
                    nativeProps={{
                      variant: BUTTON_TYPE.PRIMARY,
                      isDisabled: bookingData.bookingAPILoading,
                      isLoading: bookingData.bookingAPILoading,
                      onPress: () => {
                        confirmButtonClicked();
                      },
                    }}
                    customProps={{
                      btnText: 'Confirm',
                      withRightBorder: false,
                    }}
                  />
                </HStack>
              </View>
            </VStack>
          </Stack>
        )}
        {!loading && bookingScreen === BookingScreen.successScreen && (
          <AppointmentBookingSuccessPage
            appointmentName={bookingData.appointmentType?.eventName || ''}
            appointmentStartDate={bookingData.slot?.startDateTime || ''}
            appointmentEndDate={bookingData.slot?.endDateTime || ''}
            appointmentLocation={getLocationName()}
            isRSVPAppointment={
              bookingData.appointmentType?.isRsvpEnabled || false
            }
            customWhereMessage={
              bookingData.appointmentType?.customFields?.find(
                (field) =>
                  field.valueDataTypeCode ===
                  APPOINTMENT_TYPE_DATA_TYPE_CODES.DISPLAY_CONFIG
              )?.value?.locationCustomMessage
            }
          />
        )}
      </View>
    </Box>
  );
};

const styles = StyleSheet.create({
  scrollBar: {},
});

export default AppointmentBookingWidget;
