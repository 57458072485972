import {Select, Spin} from 'antd';
import {debounce} from 'lodash';
import React, {useEffect, useState} from 'react';
import {ISearchFieldProps} from '../../../Interfaces';
import BaseService from '../../../services/CommonService/BaseService';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {Colors} from '../../../styles';
import { Icon, Text } from 'native-base';
import { Coding } from 'fhir/r4';
import { getExternalOrderDisplay, OrderSearchType } from './Helper';

interface IOrderSearchFieldProps extends ISearchFieldProps {
  searchType: OrderSearchType;
  placeholder?: string;
  disabled?: boolean;
  addNewOptionEnabled?: boolean;
  clearOnSelection?: boolean;
  additionalHeaders?: {[index: string]: any};
  skipAPICall?: boolean;
  enablePaginationOnScroll?: boolean;
  isInvalid?: boolean;
  locationId: string
}

interface IOrderSearchState {
  loading: boolean;
  searchValue: string;
  dataSource: Coding[];
  originalData: Coding[];
  selectedValue?: Coding;
  offset: number;
  pageSize: number;
  dataFinished: boolean;
}

const OrderSearch = (props: IOrderSearchFieldProps) => {
  const defaultOffset = 0;
  const defaultPageSize = 25;
  const addNewPrefix = "Add "
  const {value, isShowError, searchType, isInvalid, onChange, locationId} = props;
  const [componentState, setComponentState] = useState<IOrderSearchState>({
    loading: false,
    searchValue: '',
    dataSource: [],
    originalData: [],
    selectedValue: undefined,
    offset: defaultOffset,
    pageSize: defaultPageSize,
    dataFinished: false,
  })

  useEffect(() => {
    if (value?.display && componentState.dataSource.length === 0) {
      const item = {
        display: value.display,
        code: value.code,
        system: value.system,
        extension: value.extension,
      };
      setComponentState((prev) => ({
        ...prev,
        dataSource: [item],
        originalData: [],
        selectedValue: value.code,
      }));
    }
  }, []);

  const searchOrder = (searchString: string, forceTrigger?: boolean) => {
    const offset = defaultOffset;
    const pageSize = defaultPageSize;
    setComponentState((prev) => ({
      ...prev,
      searchValue: searchString,
      offset: offset,
      pageSize: pageSize,
      dataFinished: false,
      dataSource: [],
      originalData: []
    }));
    if (props.skipAPICall) {
      const list: Coding[] = [];
      if (props.addNewOptionEnabled) {
        list.push({
          display: searchString.trim(),
        });
      }
      setComponentState((prev) => ({...prev, dataSource: list, originalData: list}));
    } else if (searchString.length > 1 || forceTrigger) {
      callAPI(searchString, offset, pageSize, locationId);
    }
  };

  const callAPI = (searchString: string, offset: number, pageSize: number, locationId: string) => {
    setComponentState((prev) => ({...prev, loading: true}));
    const {axios} = BaseService.getSharedInstance();
    let path = `/integration/api/codes?limit=${pageSize}&offset=${offset}&searchType=${searchType}&searchString=${searchString}&location=${locationId}`;
    if (searchType == OrderSearchType.radiology) {
      path += `&source=health-gorilla`
    }
    axios
      .get(
        path,
        {
          headers: props.additionalHeaders,
        }
      )
      .then((result) => {
        const list: Coding[] = result.data?.expansion?.contains || [];
        setComponentState((prev) => {
          let finalList = prev.dataSource;
          const totalCount = list.length;
          if (props.addNewOptionEnabled) {
            finalList.splice(finalList.length - 1, 1);
          }
          finalList = offset !== 0 ? [...finalList, ...list] : list;
          if (props.addNewOptionEnabled) {
            finalList.push({
              display: searchString.trim(),
            });
          }
          return {
            ...prev,
            loading: false,
            dataFinished: totalCount === 0 || totalCount < prev.pageSize,
            offset: offset,
            dataSource: finalList,
            originalData: finalList
          }
        });
      })
      .catch((error) => {

        setComponentState((prev) => ({...prev, loading: false}));
      });
  }

  const getDataFromId = (id: string) => {
    const matchedData = componentState.dataSource.filter((item) => {
      return item.code === id;
    });
    if (matchedData.length > 0) {
      return {
        display: matchedData[0].display,
        code: matchedData[0].code,
        system: matchedData[0].system,
        extension: matchedData[0].extension,
      };
    }
  };

  const onOptionScroll = (event: any) => {
    const target = event.target
    if (
        props.enablePaginationOnScroll &&
        !componentState.loading &&
        !componentState.dataFinished &&
        target.scrollTop + target.offsetHeight === target.scrollHeight
      ) {
      const offset = componentState.offset + componentState.pageSize;
      setComponentState((prev) => ({...prev, loading: true}));
      target.scrollTo(0, target.scrollHeight);
      callAPI(componentState.searchValue, offset, componentState.pageSize, locationId);
    }
  };

  return (
    <Select
      size="large"
      showSearch
      allowClear
      status={isInvalid ? 'error' : undefined}
      suffixIcon={
        !componentState.searchValue && !props.skipAPICall ? (
          <Icon
            color={Colors.Custom.Gray500}
            as={AntIcon}
            name={'search1'}
            size="4"
          />
        ) : null
      }
      filterOption={false}
      disabled={props.disabled}
      value={props.clearOnSelection ? componentState.selectedValue : (value ? value.text : undefined)}
      onSearch={debounce(searchOrder, 500)}
      onChange={(value: any[], data: any) => {
        if (data?.value) {
          const selectedValue = getDataFromId(data.value);
          setComponentState((prev) => ({...prev, searchValue: selectedValue?.display || ''}));
          onChange(selectedValue);
          if (props.clearOnSelection) {
            setComponentState((prev) => ({...prev, selectedValue: selectedValue}));
            setTimeout(() => {
              setComponentState((prev) => ({...prev, selectedValue: undefined}));
            }, 500);
          }
        } else {
          onChange(undefined);
          setComponentState((prev) => ({...prev, searchValue: ''}));
        }
      }}
      placeholder={props.placeholder || 'Search'}
      // loading={componentState.loading}
      notFoundContent={componentState.loading && <Spin size="small" />}
      style={{height: '40px'}}
      className={isShowError && !value ? 'pami-search field-error' : 'pami-search'}
      onPopupScroll={onOptionScroll}
    >
      {componentState.dataSource.map((item, index) => {
        return (
          <Select.Option key={`${item.display}_${index}`} value={item.code}>
            <Text>
              {props.addNewOptionEnabled && !item.code ? `${addNewPrefix}${getExternalOrderDisplay(item)}` : `${getExternalOrderDisplay(item)}`}
            </Text>
          </Select.Option>
        );
      })}
      {componentState.dataSource.length && componentState.loading && (
        <Select.Option key={`${props.searchType}_loading`} disabled>
          <Spin size="small" />
        </Select.Option>
      )}
    </Select>
  );
};

export default OrderSearch;
