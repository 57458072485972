import {DisplayCardAvatar} from '../DisplayCard/DisplayCardAvatar';
import {
  GROUP_MEMBER_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
} from '../../../constants';
import {Tooltip} from 'antd';
import Stack from '../LayoutComponents/Stack';
import {View, Text, Dimensions} from 'react-native';
import {Checkbox, useMediaQuery} from 'native-base';
import {Colors} from '../../../styles';
import {isWeb} from '../../../utils/platformCheckUtils';
import {IOptionCheckProps} from './interface';
import {IUserRole} from '../../../Interfaces';

export const OptionCheck = (props: IOptionCheckProps) => {
  const {width} = Dimensions.get('window');
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);
  const finalWidth = isIPadScreen || isIPadMiniScreen ? width / 2 : width / 3;

  const userRoles = [...new Set(
    props.userRoles
      .filter((role) => role?.userRole?.roleName)
      .map((role) => role.userRole.roleName)
  )].join(', ');

  const generateInitials = (name: string): string => {
    const words = name.split(/\s+/);
    let initials = words[0] ? words[0].charAt(0).toUpperCase() : '';
    if (words.length > 1) {
      initials += words[words.length - 1].charAt(0).toUpperCase();
    }
    return initials;
  };

  return (
    <>
      <View style={{width: '100%'}}>
        <Stack
          direction="row"
          style={{
            alignItems: 'center',
            direction: isWeb() ? undefined : 'rtl',
            justifyContent: isWeb() ? undefined : 'space-between',
          }}
        >
          {props?.showCheckbox && <Checkbox
            isChecked={props.checked}
            value=""
            isDisabled={props?.isDisabled}
            background={Colors.Custom.White}
            _hover={{value: '', shadow: 'none'}}
            _icon={{
              size: 2.5,
            }}
            _disabled={
              props.checked
                ? {
                    value: '',
                    borderColor: Colors.FoldPixel.GRAY200,
                    backgroundColor: Colors.FoldPixel.GRAY200,
                  }
                : {
                    value: '',
                    borderColor: Colors.FoldPixel.GRAY200,
                    backgroundColor: Colors.FoldPixel.GRAY100,
                  }
            }
            size={'sm'}
            borderWidth={1}
            borderColor={Colors.FoldPixel.GRAY150}
            _checked={{
              value: '',
              borderColor: Colors.FoldPixel.PRIMARY300,
              backgroundColor: Colors.FoldPixel.PRIMARY300,
            }}
            style={{marginRight: 8, padding: 2}}
          />}
          <Stack direction="row" style={{direction: 'ltr'}}>
            <View>
              {props.isDisabled ? (
                <View
                  style={[
                    {
                      borderRadius: 50,
                      borderColor: Colors.FoldPixel.GRAY200,
                      borderWidth: 0.5,
                      width: 36,
                      height: 36,
                      alignItems: 'center',
                      alignSelf: 'center',
                      justifyContent: 'center',
                      alignContent: 'center',
                    },
                  ]}
                >
                  <span
                    style={{
                      color: Colors.FoldPixel.GRAY300,
                    }}
                  >
                    {generateInitials(props.name)}
                  </span>
                </View>
              ) : (
                <DisplayCardAvatar
                  avatarStyle={{
                    width: 36,
                    height: 36,
                    textStyle: {fontSize: 14},
                    borderWidth: 0.5,
                  }}
                  userData={{
                    userType: GROUP_MEMBER_TYPE.USER,
                    userId: props.value,
                    name: props.name,
                    userName: props.name,
                    imgSrc: '',
                  }}
                  isLetterAvatarShow
                />
              )}
            </View>
            <Stack direction="column">
              <Stack direction="column" style={{paddingStart: 8}}>
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: '600',
                    color: props.isDisabled
                      ? Colors.FoldPixel.GRAY200
                      : 'black',
                  }}
                  numberOfLines={1}
                >
                  {props?.name}
                </Text>
              </Stack>
              <View style={{paddingStart: 8}}>
                {props?.hideTooltip ? (
                  <Text
                    style={{
                      color: Colors.Custom.Gray400,
                      fontSize: 12,
                      maxWidth: finalWidth - 150,
                    }}
                    numberOfLines={1}
                  >
                    {userRoles}
                  </Text>
                ) : (
                  <Tooltip title={userRoles} placement="left">
                    <Text
                      style={{
                        color: Colors.Custom.Gray400,
                        fontSize: 12,
                        maxWidth: finalWidth - 150,
                      }}
                      numberOfLines={1}
                    >
                      {userRoles}
                    </Text>
                  </Tooltip>
                )}
              </View>
            </Stack>
          </Stack>
        </Stack>
      </View>
    </>
  );
};
