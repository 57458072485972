import {
  Button,
  Divider,
  HStack,
  Pressable,
  ScrollView,
  Text,
  View,
  VStack,
} from 'native-base';
import React, {useEffect, useState} from 'react';
import {DATE_FORMATS, SCREEN_CONTEXT} from '../../../../../../constants';
import {
  convertToTimezone,
  getDateStrFromFormat,
  getFormattedDate,
  isBetweenDate,
  setHourAndMinutesOfDate,
} from '../../../../../../utils/DateUtils';
import {
  AppointmentSlotGroup,
  DefaultSlotGroupTime,
} from './AppointmentConstant';
import {DisplayText} from '../../../../DisplayText/DisplayText';
import {Colors} from '../../../../../../styles/Colors';
import { testID } from '../../../../../../testUtils';
import { ISlot } from './AppointmentBookingIntefaces';
import Slot from '../Slot';


const SlotsGroup = (props: {
  selectedSlotDate: Date;
  selectedEndDate?: Date | undefined;
  selectedTimezone: string;
  userSlots: ISlot[];
  selectedSlot?: any;
  handleSlotSelection: (slot: ISlot | any) => void;
  isMobileView?: boolean;
  screenContext?: string;
  skipGrouping?: boolean;
}) => {
  const timeSlotDate = props.selectedSlotDate;
  const [slotLoading, setSlotLoading] = useState<boolean>(true);
  const selectedSlot = props.selectedSlot;
  // const [selectedSlot, setSelectedSlot] = useState<any>(props.selectedSlot);
  const {isMobileView} = props;
  const [slotGroupState, SetSlotGroupState] = useState({
    allSlots: [],
    slotGroup: {
      Morning: {
        groupName: AppointmentSlotGroup.morning,
        selectedSlot: '',
        slots: [] as ISlot[],
        slotTimings: DefaultSlotGroupTime.Morning,
        startDate: setHourAndMinutesOfDate(
          timeSlotDate,
          DefaultSlotGroupTime.Morning.StartTime.HOURS,
          DefaultSlotGroupTime.Morning.StartTime.MINUTES
        ),
        endDate: setHourAndMinutesOfDate(
          timeSlotDate,
          DefaultSlotGroupTime.Morning.EndTime.HOURS,
          DefaultSlotGroupTime.Morning.EndTime.MINUTES
        ),
      },
      AfterNoon: {
        groupName: AppointmentSlotGroup.afterNoon,
        slots: [] as ISlot[],
        selectedSlot: '',
        slotTimings: DefaultSlotGroupTime.AfterNoon,
        startDate: setHourAndMinutesOfDate(
          timeSlotDate,
          DefaultSlotGroupTime.AfterNoon.StartTime.HOURS,
          DefaultSlotGroupTime.AfterNoon.StartTime.MINUTES
        ),
        endDate: setHourAndMinutesOfDate(
          timeSlotDate,
          DefaultSlotGroupTime.AfterNoon.EndTime.HOURS,
          DefaultSlotGroupTime.AfterNoon.EndTime.MINUTES
        ),
      },
      Evening: {
        groupName: AppointmentSlotGroup.evening,
        slots: [] as ISlot[],
        selectedSlot: '',
        slotTimings: DefaultSlotGroupTime.Evening,
        startDate: setHourAndMinutesOfDate(
          timeSlotDate,
          DefaultSlotGroupTime.Evening.StartTime.HOURS,
          DefaultSlotGroupTime.Evening.StartTime.MINUTES
        ),
        endDate: setHourAndMinutesOfDate(
          timeSlotDate,
          DefaultSlotGroupTime.Evening.EndTime.HOURS,
          DefaultSlotGroupTime.Evening.EndTime.MINUTES
        ),
      },
    },
  });

  const isSelectedSlotFitInSlotGroup = (
    slot: ISlot | any,
    slotGroup: any,
    timezone: string
  ): boolean => {
    try {
      const slotStartDate = convertToTimezone(slot.startDateTime, timezone);
      const slotStartHours = parseInt(slotStartDate.format('HH'));
      const slotStartMinutes = parseInt(slotStartDate.format('mm'));

      const slotGroupStartHours = slotGroup.slotTimings.StartTime.HOURS;
      const slotGroupEndHours = slotGroup.slotTimings.EndTime.HOURS;
      const slotGroupStartMinutes = slotGroup.slotTimings.StartTime.MINUTES;
      const slotGroupEndMinutes = slotGroup.slotTimings.EndTime.MINUTES;

      if (
        (slotStartHours > slotGroupStartHours ||
          (slotStartHours === slotGroupStartHours &&
            slotStartMinutes >= slotGroupStartMinutes)) &&
        (slotStartHours < slotGroupEndHours ||
          (slotStartHours === slotGroupEndHours &&
            slotStartMinutes <= slotGroupEndMinutes))
      ) {
        return true;
      }
    } catch (error) {

    }
    return false;
    // const slotGroupStartTime = getDateStrFromFormat(
    //   slotGroup.startDate,
    //   DATE_FORMATS.API_DATE_FORMAT
    // );
    // const slotGroupEndTime = getDateStrFromFormat(
    //   slotGroup.endDate,
    //   DATE_FORMATS.API_DATE_FORMAT
    // );


    // if (
    //   isBetweenDate(
    //     slot.startDateTime,
    //     slotGroupStartTime,
    //     slotGroupEndTime,
    //     '[)'
    //   )
    // ) {
    // }
    // return isBetweenDate(
    //   slot.startDateTime,
    //   slotGroupStartTime,
    //   slotGroupEndTime,
    //   '[)'
    // );
  };

  const generateSlots = () => {
    setSlotLoading(true);
    const inputSlots = props.userSlots || [];
    const morningSlot = slotGroupState.slotGroup.Morning;
    const eveningSlots = slotGroupState.slotGroup.Evening;
    const noonSlots = slotGroupState.slotGroup.AfterNoon;

    morningSlot.slots = [];
    eveningSlots.slots = [];
    noonSlots.slots = [];

    inputSlots.forEach((slot: ISlot) => {
      if (
        isSelectedSlotFitInSlotGroup(slot, morningSlot, props.selectedTimezone)
      ) {
        morningSlot.slots.push(slot);
      } else if (
        isSelectedSlotFitInSlotGroup(slot, eveningSlots, props.selectedTimezone)
      ) {
        eveningSlots.slots.push(slot);
      } else if (
        isSelectedSlotFitInSlotGroup(slot, noonSlots, props.selectedTimezone)
      ) {
        noonSlots.slots.push(slot);
      }
    });

    SetSlotGroupState((prev: any) => ({
      ...prev,
      Morning: morningSlot,
      Evening: eveningSlots,
      AfterNoon: noonSlots,
      allSlots: [...morningSlot.slots, ...eveningSlots.slots, ...noonSlots.slots],
    }));

    setSlotLoading(false);
  };

  useEffect(() => {
    generateSlots();
  }, [props.userSlots]);


  const getSlotView = (slots: ISlot[]) => {
    const colSize = 3;
    const elemList: JSX.Element[] = [];

    for (let index = 0; index < slots.length; index += colSize) {
      const rowElem: JSX.Element[] = [];

      for (let colIndex = 0; colIndex < colSize; colIndex++) {
        const slotIndex = index + colIndex;
        const slotListItem = slots[slotIndex];

        if (slotListItem) {
          const title =  convertToTimezone(
            slotListItem.startDateTime,
            props.selectedTimezone
          ).format(DATE_FORMATS.DISPLAY_TIME_FORMAT);
          rowElem.push(
            <Slot
              key={slotListItem.endDateTime + slotListItem.startDateTime}
              slotListItem={slotListItem}
              handleSlotSelection={props.handleSlotSelection}
              selectedTimezone={props?.selectedTimezone}
            />
          );
        } else {
          rowElem.push(<VStack key={`empty-${colIndex}`} px={2} flex={0.32} my={2}></VStack>);
        }
      }

      elemList.push(<HStack key={`row-${index}`} flex={1}>{rowElem}</HStack>);
    }

    return elemList;
  };


  const renderSlotsWithoutGrouping = () => {
    return (
      <HStack flex={isMobileView ? 0 : 1} space={2}>
        <View
          flex={1}
          flexDirection={isMobileView ? 'column' : 'row'}
          flexWrap={isMobileView ? 'nowrap' : 'wrap'}
        >
          {slotGroupState.allSlots.map((slot: any, index: any) => {
            return (
              <View key={slot.startDateTime + index} mr={2} my={2}>
                <Slot
                  selectedTimezone={props?.selectedTimezone}
                  handleSlotSelection={props.handleSlotSelection}
                  slotListItem={slot}
                  key={slot.startDateTime}
                  selectedSlot={selectedSlot}
                />
              </View>
            );
          })}
        </View>
      </HStack>
    );
  };

  return (
    <>
      {!slotLoading && (
        <View
          mt={isMobileView ? 0 : 2}
          p={isMobileView ? 3 : 0}
          flex={isMobileView ? 0 : 1}
        >
          {props?.skipGrouping ? renderSlotsWithoutGrouping() : 
           Object.keys(slotGroupState.slotGroup).map((groupKey,index) => {
            const slotGroup = slotGroupState.slotGroup[groupKey as keyof typeof slotGroupState.slotGroup];
            if (slotGroup.slots.length) {
              return (
                <ScrollView key={groupKey + index}>
                  <VStack mb={isMobileView ? 2 : 0}>
                    {isMobileView ? (
                      <Text
                        style={{
                          color: Colors.Custom.Gray500,
                          fontWeight: '500',
                          fontSize: 14,
                        }}
                        mb={2}
                        ml={1}
                      >
                        {slotGroup.groupName}
                      </Text>
                    ) : (
                      <DisplayText
                        size={'xsLight'}
                        extraStyles={{
                          color: Colors.Custom.Gray500,
                          fontWeight: isMobileView ? '500' : 500,
                          fontSize: 14,
                        }}
                        textLocalId={slotGroup.groupName}
                      />
                    )}

                    <HStack
                      flex={isMobileView ? 0 : 1}
                      space={2}
                      key={slotGroup.groupName}
                    >
                      <View
                        flex={1}
                        flexDirection={'row'}
                        flexWrap={'wrap'}
                      >
                        {slotGroup.slots.map((slot: any, index: any) => {
                          return (
                            <View
                              key={slot.startDateTime + index}
                              mr={2}
                              my={2}
                            >
                              <Slot
                                selectedTimezone={props?.selectedTimezone}
                                handleSlotSelection={props.handleSlotSelection}
                                slotListItem={slot}
                                key={slot.startDateTime}
                                selectedSlot={selectedSlot}
                              />
                            </View>
                          );
                        })}
                      </View>
                    </HStack>
                  </VStack>
                </ScrollView>
              );
            }
          })}
        </View>
      )}
    </>
  );
};

export default SlotsGroup;

// momentTz.tz(slot.startDateTime, DATE_FORMATS.DISPLAY_TIME_FORMAT, props.selectedTimezone)
