import {useEffect, useState} from 'react';
import {getPractitionersWithHeaders} from '../../../services/CommonService/AidBoxService';

const usePractitioners = <T>(params: {
  hasLoggedInContext: boolean;
  additionalHeaders?: Record<string, string>;
  skip: boolean;
  locationId?: string
}) => {
  const {locationId} = params
  const [stateData, setStateData] = useState<{
    data: T[];
    loading: boolean;
    error: boolean;
    fetched: boolean;
  }>({
    data: [],
    loading: false,
    error: false,
    fetched: false
  });

  const fetchPractitioners = () => {
    setStateData(prev => ({
      ...prev,
      data: [],
      loading: true,
      error: false,
    }));
    getPractitionersWithHeaders({
      name: '',
      useProxy: !params.hasLoggedInContext,
      additionalHeaders: params.additionalHeaders || {},
      successHandler: (response) => {
        const entries: T[] = [];
        if (response?.data?.entry?.length) {
          response.data.entry.forEach((data: {resource: T}) => {
            if (data.resource) {
              entries.push(data.resource);
            }
          });
        }
        setStateData((prev) => ({
          ...prev,
          data: entries,
          loading: false,
          error: false,
          fetched: true
        }));
      },
      errorHandler: (error) => {

        setStateData((prev) => ({
          ...prev,
          data: [],
          loading: false,
          error: true,
        }));
      },
      locationId: locationId
    });
  };

  useEffect(() => {
    if (!params?.skip && !stateData.loading) {
      fetchPractitioners();
    }
  }, []);
  return stateData;
};


export default usePractitioners;
