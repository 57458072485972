import {processQueryBuilderJSON} from '../../../../../ContentManagement/CampaignManagement/QueryBuilder/QueryBuilderHelper';
import {IGoal} from '../../Goals/AddOrUpdateGoals/interface';
import {
  ContactRuleCategory,
  IAutomation,
  IContactRule,
  IRule,
  IRuleType,
  ITemplate,
} from '../interfaces';
import {CONTACT_RULE_CATEGORY} from './ContactRulesConstants';
import {Utils as QbUtils} from '@react-awesome-query-builder/antd';
import {v4 as uuidv4} from 'uuid';

export const getDefaultInitTree = (ruleType?: IRuleType) => {
  switch (ruleType?.ruleEntityType) {
    case CONTACT_RULE_CATEGORY.ACTIVITY:
      return {
        id: QbUtils.uuid(),
        type: 'group',
        children1: [
          {
            id: QbUtils.uuid(),
            type: 'rule_group',
            properties: {
              conjunction: 'AND',
              not: false,
              field: 'wearable',
              fieldSrc: 'field',
            },
            children1: [
              {
                type: 'rule',
                id: QbUtils.uuid(),
                properties: {
                  fieldSrc: 'field',
                  field: 'wearable.name',
                  operator: 'select_equals',
                  value: [null],
                  valueSrc: ['value'],
                  valueError: [null],
                  valueType: ['select'],
                },
              },
            ],
          },
        ],
      };

    case CONTACT_RULE_CATEGORY.LAB_ANALYTE:
      return {
        id: QbUtils.uuid(),
        type: 'group',
        children1: [
          {
            id: QbUtils.uuid(),
            type: 'rule_group',
            properties: {
              conjunction: 'AND',
              not: false,
              field: 'hasLabResult',
              fieldSrc: 'field',
            },
            children1: [
              {
                type: 'rule',
                id: QbUtils.uuid(),
                properties: {
                  fieldSrc: 'field',
                  field: 'hasLabResult.name',
                  operator: 'select_equals',
                  value: [],
                  valueSrc: ['value'],
                  valueError: [null],
                  valueType: ['select'],
                },
              },
            ],
          },
        ],
      };

    case CONTACT_RULE_CATEGORY.FORM:
      return {
        id: QbUtils.uuid(),
        type: 'group',
        children1: [
          {
            id: QbUtils.uuid(),
            type: 'rule_group',
            properties: {
              conjunction: 'AND',
              not: false,
              field: 'form',
              fieldSrc: 'field',
            },
            children1: [
              {
                type: 'rule',
                id: QbUtils.uuid(),
                properties: {
                  fieldSrc: 'field',
                  field: 'form.name',
                  operator: 'select_equals',
                  value: [null],
                  valueSrc: ['value'],
                  valueError: [null],
                  valueType: ['select'],
                },
              },
            ],
          },
        ],
      };

    case CONTACT_RULE_CATEGORY.VITAL:
    case CONTACT_RULE_CATEGORY.OTHER:
    case CONTACT_RULE_CATEGORY.SOCIAL:
    default:
      return {
        id: QbUtils.uuid(),
        type: 'group',
        children1: [
          {
            id: QbUtils.uuid(),
            type: 'rule_group',
            properties: {
              conjunction: 'AND',
              not: false,
              field: 'hasLastVitalValue',
              fieldSrc: 'field',
            },
            children1: [
              {
                type: 'rule',
                id: QbUtils.uuid(),
                properties: {
                  fieldSrc: 'field',
                  field: 'hasLastVitalValue.name',
                  operator: 'select_equals',
                  value: ruleType?.ruleEntityCode
                    ? [ruleType.ruleEntityCode]
                    : [],
                  valueSrc: ['value'],
                  valueError: [null],
                  valueType: ['select'],
                },
              },
            ],
          },
        ],
      };
  }
};

export const getUpdatedRuleJson = (rule: IRule, automation: IAutomation) => {
  const queryBuilderProcessedData = processQueryBuilderJSON(rule.displayJson);
  return {
    id: rule.id,
    rule: {
      conditions: queryBuilderProcessedData.conditions,
      event: {
        type: 'testing',
      },
    },
    type: 'DYNAMIC',
    name: rule.name,
    description: rule.description || 'None',
    displayJson: queryBuilderProcessedData.jsonTree,
    displayText: '',
    isTemplate: true,
    reference: {
      workflows: [automation],
    },
  };
};

export const getRuleType = (
  goal: IGoal,
  typeIds: {
    VITAL: string;
    ACTIVITY: string;
    LAB_ANALYTE: string;
    SOCIAL: string;
    FORM: string;
    OTHER: string;
  }
): IRuleType => {
  const entityTypeId = goal.goalTargets?.[0]?.entityTypeId;
  const entityCode = goal.goalTargets?.[0]?.entityCode;
  let category = CONTACT_RULE_CATEGORY.OTHER;
  switch (entityTypeId) {
    case typeIds.ACTIVITY:
      category = CONTACT_RULE_CATEGORY.ACTIVITY;
      break;
    case typeIds.FORM:
      category = CONTACT_RULE_CATEGORY.FORM;
      break;
    case typeIds.LAB_ANALYTE:
      category = CONTACT_RULE_CATEGORY.LAB_ANALYTE;
      break;
    case typeIds.SOCIAL:
      category = CONTACT_RULE_CATEGORY.SOCIAL;
      break;
    case typeIds.VITAL:
      category = CONTACT_RULE_CATEGORY.VITAL;
      break;
    default:
      category = CONTACT_RULE_CATEGORY.OTHER;
  }
  return {
    ruleEntityType: category as ContactRuleCategory,
    ruleEntityCode: entityCode,
  };
};

export const transformRuleToContactRule = (
  rule: IRule,
  metadata: {
    contactId?: string;
    entityId: string;
    entityTypeId: string;
  }
): IContactRule => {
  return {
    id: uuidv4(),
    ruleId: rule.ruleId || '',
    entityId: metadata.entityId,
    entityTypeId: metadata.entityTypeId,
    isDeleted: false,
    contactId: metadata.contactId,
    contactRuleId: rule.id,
    contactRuleWorkflow: rule.reference?.workflows || [],
    rule: rule,
  };
};

export const transformTemplateDataToRules = (
  templates: ITemplate[]
): IRule[] => {
  return templates.map((template) => {
    return {
      id: undefined,
      contactUuid: undefined,
      name: template.name,
      rule: {},
      displayJson: {},
      ruleId: template.id,
      isTemplate: template.isTemplate,
      description: template.description,
      isDeleted: false,
      reference: template.reference,
    };
  });
};
