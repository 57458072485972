import React from 'react';
import { Svg, Path } from 'react-native-svg';
import { ICommonSvgProps } from '../interfaces';

function PendingAlertsSvg(svgProps: ICommonSvgProps) {
    return (
        <Svg
            width={svgProps.size || "16"}
            height={svgProps.size || "16"}
            fill="none"
            viewBox="0 0 16 16"
        >
            <Path
                stroke="#6F7A90"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.333 8H2.667m0 0l4-4m-4 4l4 4"
            ></Path>
        </Svg>
    );
}

export default PendingAlertsSvg;
