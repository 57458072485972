import {
  Divider,
  HStack,
  useMediaQuery,
  View,
  VStack,
  Text,
  Stack,
  IconButton,
  Icon,
  Button,
  Spacer,
  Pressable,
} from 'native-base';
import React, {useContext, useRef} from 'react';
import {useIntl} from 'react-intl';
import {Colors} from '../../../../../../../styles';
import PAMISearch, {
  PAMISearchType,
} from '../../../../../../common/PAMISearch/PAMISearch';
import {
  FamilyHXFields,
  getConditionName,
  getGroupedHXByRelation,
  getAdditionalDetails,
  isInvalid,
} from '../AddOrUpdateFamilyHistory/AddOrUpdateFamilyHistoryHelper';
import {IFamilyHistory} from '../interfaces';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {v4 as uuidv4} from 'uuid';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';
import {IFormCommonData} from '../../CustomWrapper/CustomWrapper';
import {IKeyOperation, PossibleValue} from '../../../../../../../Interfaces';
import InfoSvg from '../../../../../../common/Svg/InfoSvg';
import { useContainerDimensions } from '../../../../../../CustomHooks/ContainerDimensionHook';
import Feather from 'react-native-vector-icons/Feather';
const FamilyHistoryTable = (props: {
  items: IFamilyHistory[];
  showEHRMessage: boolean;
  openInEhrUrl?: string;
  showErrors: boolean;
  disabled: boolean;
  canCreate: boolean;
  canUpdate: boolean;
  metaData?: {
    relationshipList: PossibleValue[];
    fields?: IKeyOperation;
    allowFreeText: boolean;
  };
  onAddNew: (data: IFamilyHistory) => void;
  onDetailChange: (data: IFamilyHistory) => void;
  canDelete:() => boolean;
  canShowDeleteBtn: (id?: string) => boolean;
  getDeleteFunction:(item:IFamilyHistory)=> void;
}) => {
  const {
    items,
    showErrors,
    disabled,
    metaData,
    canUpdate,
    canCreate,
    showEHRMessage,
    openInEhrUrl,
    onAddNew,
    onDetailChange,
    canDelete,
    canShowDeleteBtn,
    getDeleteFunction
  } = props;
  const componentRef = useRef();
  const { width } = useContainerDimensions(componentRef);
  const [isMobileScreen] = [width <= 480];
  const intl = useIntl();
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const groupedHistoryByRelation = getGroupedHXByRelation(items);
  return (
    <VStack
      ref={componentRef}
      paddingTop={0.5}
      paddingX={0.5}
      paddingBottom={0}
      space={2}
    >
      {!isMobileScreen && (
        <View>
          <HStack paddingY={4} marginLeft={3}>
            <View flex={2} width="100%">
              <Text size="smSemibold">
                {intl.formatMessage({id: 'relationship'})}
              </Text>
            </View>
            <View flex={4} width="100%">
              <Text flex={1} size="smSemibold">
                {intl.formatMessage({id: 'conditions'})}
              </Text>
            </View>
          </HStack>
          <Divider />
        </View>
      )}
      {groupedHistoryByRelation.map((group, index) => {
        return (
          <View
            key={group.relation}
            style={
              isMobileScreen
                ? {
                    borderColor: Colors.Custom.BorderColor,
                    borderWidth: 1,
                    borderRadius: 10,
                  }
                : {}
            }
          >
            <Stack
              direction={isMobileScreen ? 'column' : 'row'}
              alignItems="flex-start"
              justifyContent={isMobileScreen ? 'center' : 'flex-start'}
              space={2}
              paddingBottom={3}
              marginLeft={3}
            >
              <View flex={2} width="100%">
                <Text>{group.relation || '-'}</Text>
              </View>
              <VStack
                flex={6}
                width="100%"
                paddingRight={isMobileScreen ? 0 : 2}
                space={1}
              >
                {group.list.map((item, index) => {
                  const isReadOnly = disabled || (!canUpdate && !!item.id);
                  const canShowSearchInRow =
                    !isReadOnly && group.list.length === 1 && !item.condition && group.relation;
                  return (
                    <HStack
                      space={4}
                      flex={1}
                      paddingY={2}
                      key={`${group.relation}_${index}`}
                    >
                      <View flex={9} width="100%">
                        {canShowSearchInRow && (
                          <PAMISearch
                            placeholder="Search And Add New Problem"
                            addNewOptionEnabled={metaData?.allowFreeText}
                            searchType={PAMISearchType.problem}
                            additionalHeaders={contextData.headers}
                            value={item.condition}
                            isShowError={true}
                            onChange={(value) => {
                              item.condition = value;
                              onDetailChange(item);
                            }}
                            locationId={contextData.locationId}
                          />
                        )}
                        {!canShowSearchInRow && (
                          <VStack>
                            <Text>{getConditionName(item)}</Text>
                            {getAdditionalDetails(item, false).length > 0 && (
                              <Text color={Colors.Custom.Gray500} fontSize={12}>{getAdditionalDetails(item, false)}</Text>
                            )}
                          </VStack>
                        )}
                      </View>
                      {!disabled && group.relation && (
                        <View>
                          {canShowDeleteBtn(item.id) ? (
                            <Pressable
                              marginTop={-1}
                              onPress={() => getDeleteFunction(item)}
                              borderWidth={0.3}
                              backgroundColor={Colors.Custom.Base50}
                              borderColor={Colors.Custom.ActionBtnBorder}
                              px={2}
                              py={1}
                              rounded="full"
                              shadow={2}
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Feather
                                name={
                                  canDelete() || contextData.isBuilderMode
                                    ? 'trash-2'
                                    : 'x'
                                }
                                size={20}
                                color={Colors.Custom.PurpleColor}
                              />
                            </Pressable>
                          ) : null}
                        </View>
                      )}
                    </HStack>
                  );
                })}
                {!disabled &&
                  canCreate &&
                  group.relation &&
                  group.list.length > 0 &&
                  group.list[0].condition && (
                    <PAMISearch
                      placeholder="Search And Add New Problem"
                      clearOnSelection
                      addNewOptionEnabled={metaData?.allowFreeText}
                      searchType={PAMISearchType.problem}
                      additionalHeaders={contextData.headers}
                      isShowError={group.list.length === 0}
                      onChange={(value) => {
                        const data: IFamilyHistory = {
                          condition: value,
                          relationship: {
                            code: group.relation,
                            display: group.relation,
                          },
                          uniqueId: uuidv4(),
                          isFreeTextRecord: false,
                        };
                        onAddNew(data);
                      }}
                      locationId={contextData.locationId}
                    />
                  )}
                {/* {!disabled && canCreate && (
                  <HStack>
                    <Button
                      rounded="3xl"
                      size="sm"
                      variant="link"
                      onPress={() => {
                        const data: IFamilyHistory = {
                          condition: undefined,
                          relationship: {
                            code: group.relation,
                            display: group.relation,
                          },
                          uniqueId: uuidv4(),
                          isFreeTextRecord: false,
                        };
                        onAddNew(data);
                      }}
                    >
                      + Add New Condition
                    </Button>
                    <Spacer />
                  </HStack>
                )} */}
              </VStack>
            </Stack>
            {index !== groupedHistoryByRelation.length - 1 &&
              !isMobileScreen && <Divider />}
          </View>
        );
      })}
    </VStack>
  );
};

export default FamilyHistoryTable;
