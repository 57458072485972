import {View,Text, Pressable} from "react-native"
import {Colors} from "../../../styles"
import {PREFERENCES_TAB_LIST_CODE} from "./PreserenceConst"
import {useIntl} from "react-intl";
import {FoldButton} from "../../CommonComponents/FoldButton/FoldButton";
import AntIcon from 'react-native-vector-icons/AntDesign';
import {COMMON_ACTION_CODES} from "../../../constants/ActionConst";
import {IPreferenceSettingHeader} from "./PreferencesInterface";
import { TestIdentifiers, testID } from "../../../testUtils";

export const PreferenceSettingHeader = (props: IPreferenceSettingHeader) => {
  const intl = useIntl();
  const getHeader = (tabCode: string) => {
    switch(tabCode) {
      case PREFERENCES_TAB_LIST_CODE.ACCOUNT_AND_PROFILE:
        return intl.formatMessage({id: 'accountAndProfile'});
      case PREFERENCES_TAB_LIST_CODE.EMAIL_SETTINGS:
        return intl.formatMessage({id: 'emailSettings'})
      case PREFERENCES_TAB_LIST_CODE.NOTIFICATION_SETTING:
        return intl.formatMessage({id: 'notificationSettings'})
      case PREFERENCES_TAB_LIST_CODE.AUTO_REPLY:
        return intl.formatMessage({id: 'autoReplySetting'})
      default :
       return '';
    }
  }

  const getActionButtons = (tabCode: string) => {
    switch(tabCode) {
      case PREFERENCES_TAB_LIST_CODE.ACCOUNT_AND_PROFILE:
        return <>
          <View style={{
            alignItems: 'flex-end',
            justifyContent: 'center',
          }}>
            <View
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <FoldButton
                customProps={{
                  btnText: 'Submit',
                }}
                nativeProps={{
                  onPress() {
                    props?.onActionPerformed(COMMON_ACTION_CODES.SAVE)
                  },
                  isLoading: props?.userProfileLoading,
                  isDisabled: props?.userProfileLoading,
                  backgroundColor: Colors.Custom.Primary300,
                  _text: {
                    fontSize: 14,
                    lineHeight: 16.8,
                  },
                  paddingX: 2,
                  marginLeft: 2
                }}
              />
              <View
                style={{
                  width: 1,
                  height: 16,
                  marginLeft: 10,
                  backgroundColor: Colors.Custom.Gray300,
                  marginRight: 5
                }}
              />
              <Pressable
                style={{
                  alignItems: 'flex-end',
                  justifyContent: 'center',
                }}
                onPress={()=> {
                  props?.onClose();
                }}
              >
                <AntIcon size={20} name="close" color={Colors.FoldPixel.GRAY300}/>
              </Pressable>
            </View>
          </View>
        </>;
      case PREFERENCES_TAB_LIST_CODE.AUTO_REPLY:
        return (
          <>
            <View
              style={{
                alignItems: 'flex-end',
                justifyContent: 'center',
              }}
            >
              <View
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <Pressable
                  style={{
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                  }}
                  onPress={() => {
                    props?.onActionPerformed(
                      COMMON_ACTION_CODES.CLOSE_AUTO_REPLY
                    );
                  }}
                  {...testID(TestIdentifiers.closeBtn)}
                >
                  <AntIcon
                    size={20}
                    name="close"
                    color={Colors.FoldPixel.GRAY300}
                  />
                </Pressable>
              </View>
            </View>
          </>
        );
      default:
      return <>
        <Pressable
          style={{
            alignItems: 'flex-end',
            justifyContent: 'center',
          }}
          onPress={()=> {
            props?.onClose();
          }}
        >
          <AntIcon size={20} name="close" color={Colors.FoldPixel.GRAY300}/>
        </Pressable>
      </>
    }
  }

  return <>
    <View style={{
      height: 56,
      borderBottomWidth: 1,
      borderBottomColor: Colors.Custom.Gray200,
      flexDirection: 'row'
    }}>
      <View style={{
        marginLeft: 16,
        width: '24.95%',
        justifyContent: 'center',
        borderRightWidth: 1,
        borderRightColor: Colors.Custom.Gray200,
      }}>
        <Text style={{
          fontSize: 14,
          fontWeight: '500',
          color: Colors.Custom.Gray800,
          lineHeight: 16.8
        }}
        >
          {intl.formatMessage({id: 'preference'})}
        </Text>
      </View>
      <View style={{
        width: '73%',
        paddingLeft: 12,
        flexDirection: 'row',
        alignItems: 'center',
      }}>
        <View style={{
          flex: 1,
        }}>
          <Text style={{
            fontSize: 14,
            fontWeight: '500',
            color: Colors.Custom.Gray800,
            lineHeight: 16.8,
          }}>
            {getHeader(props?.selectedPreferenceTab?.code).toUpperCase()}
          </Text>
        </View>
        <View style={{
          marginRight: 16,
        }}>
          {getActionButtons(props?.selectedPreferenceTab?.code)}
        </View>
      </View>
    </View>
  </>
}