import {CaretDownOutlined} from '@ant-design/icons';
import {Select, Skeleton, Spin} from 'antd';
import {View, Text} from 'native-base';
import React, {useEffect, useState} from 'react';
import BaseService from '../../../services/CommonService/BaseService';
import {Colors} from '../../../styles/Colors';
import {IOnCallSchedule} from './interface';
import {useIntl} from 'react-intl';

const OnCallScheduleSearch = (props: {
  onSelect: (value: string) => void;
  value: string;
}) => {
  const intl = useIntl();
  const axios = BaseService.getSharedInstance().axios;
  const [stateData, setStateData] = useState({
    scheduleList: [] as IOnCallSchedule[],
    loading: false as boolean,
    selectedValue: '' as string,
  } as {
    scheduleList: IOnCallSchedule[];
    loading: boolean;
    selectedValue: string;
  });

  const getSchedules = async () => {
    try {
      setStateData((prev) => {
        return {
          ...prev,
          loading: true,
        };
      });
      const res = await axios.get(`/cloud-telephony/api/v1/on-call-schedule`);
      if (res?.data?.length) {
        const onCallScheduleList: IOnCallSchedule[] = res?.data;
        setStateData((prev) => {
          return {
            ...prev,
            scheduleList: onCallScheduleList,
          };
        });
      }
    } catch (error) {
    } finally {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };

  const getScheduleName = () => {
    const schedule = stateData?.scheduleList?.find((schedule) => {
      return stateData?.selectedValue === schedule.id;
    });

    const scheduleName = schedule?.scheduleName || '';
    return scheduleName;
  };

  useEffect(() => {
    if (props.value) {
      setStateData((prev) => {
        return {
          ...prev,
          selectedValue: props.value,
        };
      });
    }
  }, [props.value]);

  useEffect(() => {
    getSchedules();
  }, []);

  return (
    <View marginBottom={1}>
      {stateData.loading ? (
        <Skeleton.Button
          active={true}
          size={'default'}
          shape={'default'}
          block={true}
        />
      ) : (
        <Select
          dropdownMatchSelectWidth={3}
          size="large"
          allowClear
          suffixIcon={
            <CaretDownOutlined
              style={{
                color: Colors.FoldPixel.GRAY300,
              }}
            />
          }
          filterOption={true}
          onClear={() => {
            setStateData((prev) => {
              return {
                ...prev,
                selectedValue: '',
              };
            });
            props.onSelect('');
          }}
          showSearch={true}
          value={getScheduleName()}
          onChange={(_value: string, data: any) => {
            props.onSelect(data.key);
          }}
          placeholder={intl.formatMessage({id: 'searchSchedule'})}
          notFoundContent={
            stateData.loading ? (
              <div style={{textAlign: 'center'}}>
                <Spin size="small" />
              </div>
            ) : (
              <Text color={Colors.FoldPixel.GRAY200}>{intl.formatMessage({id: 'noDataFound'})}</Text>
            )
          }
          style={{height: '36px'}}
          loading={stateData.loading}
        >
          {stateData.scheduleList.map((schedule) => {
            return (
              <Select.Option key={schedule.id} value={schedule.scheduleName}>
                {schedule.scheduleName}
              </Select.Option>
            );
          })}
        </Select>
      )}
    </View>
  );
};

export default React.memo(OnCallScheduleSearch);
