import React from 'react';
import moment from 'moment';
import {getCurrentSubdomain} from '../../../screens/MainScreen/MainScreenHelper';
import {Colors} from '../../../styles';
import {isWeb} from '../../../utils/platformCheckUtils';
import CallAbandonedSvg from '../../common/Svg/CallTrail/CallAbandonedSvg';
import CallForwardedSvg from '../../common/Svg/CallTrail/CallForwardedSvg';
import CallTrailSvg from '../../common/Svg/CallTrail/CallTrailSvg';
import PhoneIncomingSvg from '../../common/Svg/CallTrail/PhoneIncomingSvg';
import RobotSvg from '../../common/Svg/CallTrail/RobotSvg';
import VoicemailSvg from '../../common/Svg/CallTrail/VoicemailSvg';
import VolumeSvg from '../../common/Svg/CallTrail/VolumeSvg';
import DialpadSvg from '../../common/Svg/DialpadSvg';
import {CALL_TRAIL_WIDGET_NAMES} from './CallTrailConstants';
import {ICallTrail, ICallTrailWidgetNames} from './interfaces';
import Feather from 'react-native-vector-icons/Feather';
import { redirectToNewTab } from '../../SideCar/SidecarUtils';

export const getIconByWidgetName = (widgetName: ICallTrailWidgetNames) => {
  let result: JSX.Element = <></>;
  switch (widgetName) {
    case CALL_TRAIL_WIDGET_NAMES.INCOMING_CALL:
      result = <PhoneIncomingSvg height={15} width={15} size={'4'} />;
      break;

    case CALL_TRAIL_WIDGET_NAMES.PATIENT_TYPE:
      result = <PhoneIncomingSvg height={15} width={15} size={'4'} />;
      break;

    case CALL_TRAIL_WIDGET_NAMES.ANSWERED_CALL_USER:
      result = <CallForwardedSvg height={15} width={15} size={'4'} />;
      break;

    case CALL_TRAIL_WIDGET_NAMES.DIAL:
      result = <CallTrailSvg height={15} width={15} size={'4'} />;
      break;

    case CALL_TRAIL_WIDGET_NAMES.MEMBER_INPUT:
      result = (
        <DialpadSvg
          customStrokeColor={Colors.Custom.Gray500}
          size={isWeb() ? '' : '4'}
        />
      );
      break;

    case CALL_TRAIL_WIDGET_NAMES.MENU:
      result = <CallTrailSvg height={15} width={15} size={'4'} />;
      break;

    case CALL_TRAIL_WIDGET_NAMES.PHONE_TREE:
      result = <RobotSvg height={15} width={15} size={'4'} />;
      break;

    case CALL_TRAIL_WIDGET_NAMES.SAY:
      result = <VolumeSvg height={15} width={15} size={'4'} />;
      break;

    case CALL_TRAIL_WIDGET_NAMES.ABANDONED_CALL:
      result = <CallAbandonedSvg height={15} width={15} size={'4'} />;
      break;

    case CALL_TRAIL_WIDGET_NAMES.RECORD:
      result = <VoicemailSvg height={15} width={15} size={'4'} />;
      break;

    case CALL_TRAIL_WIDGET_NAMES.PLAY:
      result = <Feather name="play-circle" size={16} color={'#667085'} />;
      break;

    default:
      result = <></>;
      break;
  }
  return result;
};

export const getTextByWidgetType = (callTrail: ICallTrail) => {
  let result = '';
  const widgetName = callTrail.widgetName;
  switch (widgetName) {
    case CALL_TRAIL_WIDGET_NAMES.INCOMING_CALL:
      result = 'incomingCall';
      break;

    case CALL_TRAIL_WIDGET_NAMES.PATIENT_TYPE:
      result = 'patientType';
      break;

    case CALL_TRAIL_WIDGET_NAMES.ANSWERED_CALL_USER:
      result = 'callAnswered';
      break;

    case CALL_TRAIL_WIDGET_NAMES.DIAL:
      result = 'dialNodeExecuted';
      break;

    case CALL_TRAIL_WIDGET_NAMES.MEMBER_INPUT:
      result = `Pressed ${callTrail.inputDigit}`;
      break;

    case CALL_TRAIL_WIDGET_NAMES.MENU:
      result = 'menuNodeExecuted';
      break;

    case CALL_TRAIL_WIDGET_NAMES.PHONE_TREE:
      result = `${callTrail.phoneTreeName} Tree Executed`;
      break;

    case CALL_TRAIL_WIDGET_NAMES.SAY:
      result = 'sayNodeTriggered';
      break;

    case CALL_TRAIL_WIDGET_NAMES.ABANDONED_CALL:
      result = 'abandonedCall';
      break;

    case CALL_TRAIL_WIDGET_NAMES.RECORD:
      result = 'voiceMail';
      break;

    case CALL_TRAIL_WIDGET_NAMES.PLAY:
      result = 'play';
      break;

    default:
      result = 'Unknown';
      break;
  }
  return result;
};

export const getTimeStringForCallTrail = (data: {
  timestamp1: string;
  timestamp2: string;
}) => {
  const {timestamp1, timestamp2} = data;
  const date1 = moment(timestamp1);
  const date2 = moment(timestamp2);
  const diff = date2.diff(date1, 'seconds');
  const duration = moment.duration(diff, 'seconds');
  const minutes = duration.minutes();
  const remainingSeconds = duration.seconds();
  return `${minutes}:${String(remainingSeconds).padStart(2, '0')} MIN`;
};

export const getPhoneTreeURL = (path: string) => {
  const subdomain = getCurrentSubdomain();
  const url = `https://${subdomain}/#${path}`;
  return url;
};

export const openPhoneTree = (phoneTreeId: string, isSideCarContext?: boolean) => {
  const path = `/admin/call/ivr/update/${phoneTreeId}?flowType=IVR`;
  if(isSideCarContext){
    redirectToNewTab(path);
  } else {
    const url = getPhoneTreeURL(path);
    window.open(url, '_blank');
  }
};
