import React, { useEffect, useState } from 'react';
import { Button, Modal, Upload, notification } from 'antd';
import type { UploadFile, UploadProps } from 'antd';
import './insuranceCard.css'
import LocalStorage from '../utils/LocalStorage';
import {getInsuranceCardImageElation} from '../services/CommonService/AidBoxService';
import UploadSvg from './common/Svg/UploadSvg';
import { Colors } from '../styles';
import { UploadFileStatus } from 'antd/es/upload/interface';
import { Spinner } from 'native-base';
import { getEhrConfig } from '../utils/capabilityUtils';
import { useIntl } from 'react-intl';
import { Resource } from 'fhir/r4';
import { INSURANCE_MEDIA_NUMBER } from '../constants';
import { INSURANCE_NUMBER } from '../constants';
import { ImageSide } from './InsuranceUtils';
interface UploadInsuranceCardProps {
  cardText?: string;
  insuranceId?: string;
  patientId: string;
  patientWithPatientId: string;
  postInsuranceCard?: () => void;
  setBase64Data: (data: string, cardText: string, insuranceNumber: string, isImageDeleted: boolean) => void;
  addPatientView: boolean | undefined;
  base64ImageData?: string;
  insuranceNumber?: string;
  isPrimaryInsuranceDeleted?: boolean;
  isSecondaryInsuranceDeleted?: boolean;
  isTertiaryInsuranceDeleted?: boolean;
  base64FrontData?: string | undefined;
  base64BackData?: string | undefined;
  contactLocationUuid: string | undefined;
}

const UploadInsuranceCard = (props: UploadInsuranceCardProps) => {
  const {contactLocationUuid} = props;
  const ehrConfig = getEhrConfig(contactLocationUuid, '');
  const intl = useIntl();
  const [componentState, setComponentState] = useState({
    previewOpen: false,
    isImageLoading: false,
    previewImage: '',
    previewTitle: '',
    base64ImageData: '',
    isUploadedCardIsImage: false,
    isImageDeleted: false,
  })

  const [primaryFileList, setPrimaryFileList] = useState<UploadFile[]>([]);
  const [secondaryFileList, setSecondaryFileList] = useState<UploadFile[]>([]);
  const [tertiaryFileList, setTertiaryFileList] = useState<UploadFile[]>([]);
  const acceptedImageTypes = ['image/png', 'image/jpeg', 'image/jpg'];
  const handleCancel = () => setComponentState((prev) => ({
    ...prev,
    previewOpen: false
  }));

  useEffect(() => {
    if(props?.isPrimaryInsuranceDeleted){
      setPrimaryFileList([])
    }
    if(props?.isSecondaryInsuranceDeleted){
      setSecondaryFileList([])
    }
    if(props?.isTertiaryInsuranceDeleted){
      setTertiaryFileList([])
    }
  }, [props?.isPrimaryInsuranceDeleted, props?.isSecondaryInsuranceDeleted, props?.isTertiaryInsuranceDeleted ]);

  const getFileList = () => {
    switch (props?.insuranceNumber) {
      case INSURANCE_NUMBER.PRIMARY:
        return primaryFileList
      case INSURANCE_NUMBER.SECONDARY:
        return secondaryFileList;
      case INSURANCE_NUMBER.TERTIARY:
        return tertiaryFileList;
      default:
        return primaryFileList;
    }
  };

  const fileList = getFileList();

  function getBase64(file: any): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }

  const handlePreview = async (file: UploadFile) => {
    if (!componentState.base64ImageData && !file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setComponentState((prev) => ({
      ...prev,
      previewImage: file.url || (file.preview as string)
    }));
    setComponentState((prev) => ({
      ...prev,
      previewOpen: true
    }));
    setComponentState((prev) => ({
      ...prev,
      previewTitle: file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1)
    }));

  };

  const handleChange: UploadProps['onChange'] = async ({ fileList: newFileList }) => {
    switch (props?.insuranceNumber) {
      case INSURANCE_NUMBER.PRIMARY:
        setPrimaryFileList([]);
        break;
      case INSURANCE_NUMBER.SECONDARY:
        setSecondaryFileList([]);
        break;
      case INSURANCE_NUMBER.TERTIARY:
        setTertiaryFileList([]);
        break;
    }
    let isImageDeleted = false;
    if ((props?.base64ImageData || componentState.base64ImageData) && newFileList.length === 0) {
      isImageDeleted = true;
    }
    else {
      isImageDeleted = false;
    }
    if (newFileList?.[0]?.type && !acceptedImageTypes.includes(newFileList?.[0]?.type)) {
      notification.destroy();
      notification.error({
        message: intl.formatMessage({ id: 'Please upload image file' }),
        duration: 3.0,
        placement: 'top'
      });
      return;
    }
    if (newFileList?.[0]?.status === 'error') {
      newFileList[0].status = 'done';
    }
    switch (props?.insuranceNumber) {
      case INSURANCE_NUMBER.PRIMARY:
        setPrimaryFileList(newFileList);
        break;
      case INSURANCE_NUMBER.SECONDARY:
        setSecondaryFileList(newFileList);
        break;
      case INSURANCE_NUMBER.TERTIARY:
        setTertiaryFileList(newFileList);
        break;
    }
    let base64Data;
    if (newFileList.length !== 0) {
      const dataPngUrl = await getBase64(newFileList?.[0]?.originFileObj);
      base64Data = dataPngUrl?.split(',')[1];
    }
    props?.setBase64Data(base64Data || '', props?.cardText || '', props?.insuranceNumber || '', isImageDeleted);
  }

  const fetchImageData = () => {
    setComponentState((prev) => ({
      ...prev,
      isImageLoading: true
    }));

    if (ehrConfig.isElation) {
        let base64ImageData = '';
        if (props?.cardText === ImageSide.frontSide) {
          base64ImageData = props?.base64FrontData || '';
        }
        if (props?.cardText === ImageSide.backSide) {
          base64ImageData = props?.base64BackData || '';
        }  
        setComponentState((prev) => ({
          ...prev,
          base64ImageData: base64ImageData
        }));
        if (base64ImageData) {
          const newFileList = [...fileList, {
            uid: '-1',
            name: 'Insurance Card',
            status: 'done' as UploadFileStatus,
            url: `data:image/png;base64,${base64ImageData}`,
          }];
          switch (props?.insuranceNumber) {
            case INSURANCE_NUMBER.PRIMARY:
              setPrimaryFileList(newFileList);
              break;
            case INSURANCE_NUMBER.SECONDARY:
              setSecondaryFileList(newFileList);
              break;
            case INSURANCE_NUMBER.TERTIARY:
              setTertiaryFileList(newFileList);
              break;
          }
        }
        setComponentState((prev) => ({
          ...prev,
          isImageLoading: false
        }));
    }
    else {
        const base64ImageData = props?.base64ImageData;
        setComponentState((prev) => ({
          ...prev,
          base64ImageData: base64ImageData || ''
        }));
        if (base64ImageData) {
          switch (props?.insuranceNumber) {
            case INSURANCE_NUMBER.PRIMARY:
              setPrimaryFileList([]);
              break;
            case INSURANCE_NUMBER.SECONDARY:
              setSecondaryFileList([]);
              break;
            case INSURANCE_NUMBER.TERTIARY:
              setTertiaryFileList([]);
              break;
          }
          const newFileList = [...fileList,  {
            uid: '-1',
            name: 'Insurance Card',
            status: 'done' as UploadFileStatus,
            url: `data:image/png;base64,${base64ImageData}`,
          }];
          switch (props?.insuranceNumber) {
            case INSURANCE_NUMBER.PRIMARY:
              setPrimaryFileList(newFileList);
              break;
            case INSURANCE_NUMBER.SECONDARY:
              setSecondaryFileList(newFileList);
              break;
            case INSURANCE_NUMBER.TERTIARY:
              setTertiaryFileList(newFileList);
              break;
          }
        }
        setComponentState((prev) => ({
          ...prev,
          isImageLoading: false
        }));
    }
  };
  useEffect(() => {
    !props.addPatientView && fetchImageData();
  }, []);


  const uploadButton = (
    <Button style={{ border: 0, background: 'none' }}>
      <UploadSvg />
      <div style={{ marginTop: 8, color: Colors.Custom.Gray400 }}>{`Upload Insurance Card ${props?.cardText ? props?.cardText : ''}`}</div>
    </Button>
  );


  return (  
    <>
      {componentState.isImageLoading ? <>
        <Spinner />
      </> :
        <>
          <Upload
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
          >
            {fileList?.length !== 0 ? null : uploadButton}
          </Upload>
          <Modal open={componentState.previewOpen} title={componentState.previewTitle} footer={null} onCancel={handleCancel}>
            <img alt="example" style={{ width: '100%' }} src={componentState.previewImage} />
          </Modal>
        </>
      }
    </>
  );
};

export default UploadInsuranceCard;