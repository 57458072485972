import React, {useContext} from 'react';
import {useAppointmentDataContext} from '../../../contexts/AppointmentDataContext';
import {CommonDataContext} from '../../../../../../../../../context/CommonDataContext';
import Stack from '../../../../../../../LayoutComponents/Stack';
import SlotView from '../SlotView/SlotView';
import {ModalActionDatePicker} from '../../../../../../../ModalActionCommonComponent/ModalActionDatePicker';
import {
  getCurrentTimeZone,
  getMomentObj,
  isPastDayOfCalendar,
} from '../../../../../../../../../utils/DateUtils';
import {DISPLAY_DATE_FORMAT} from '../../../../../../../../../constants';
import {isWeb} from '../../../../../../../../../utils/platformCheckUtils';

const SelectSlotByProvider = () => {
  const {
    appointmentBookingState,
    handleAppointmentDateChange,
    renderPrimaryUser,
    renderSecondaryUsers,
    renderNativeCalendar,
  } = useAppointmentDataContext();
  const contextData = useContext(CommonDataContext);
  const userSettings = contextData.userSettings;
  const ehrCapabilities = contextData.ehrCapabilities;
  return (
    <Stack direction="column" space={16} style={{marginTop: 8}}>
      {renderPrimaryUser()}
      {renderSecondaryUsers()}
      {isWeb() ? (
        <ModalActionDatePicker
          isRequired={true}
          className="custom-date-picker"
          label={'appointmentDate'}
          defaultValue={getMomentObj(appointmentBookingState.selectedDate)}
          format={DISPLAY_DATE_FORMAT}
          onChange={handleAppointmentDateChange}
          disabledDate={(current: any) => {
            return (
              current &&
              isPastDayOfCalendar(
                current,
                appointmentBookingState.selectedTimezone?.timezone ||
                  getCurrentTimeZone()
              )
            );
          }}
          value={getMomentObj(appointmentBookingState.selectedDate)}
          customStyle={{
            flex: 1,
          }}
        />
      ) : (
        renderNativeCalendar?.()
      )}
      <SlotView />
    </Stack>
  );
};

export default SelectSlotByProvider;
