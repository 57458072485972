
import { Button, HStack, Text, useToast, View, VStack } from 'native-base';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  BUTTON_TYPE,
  CUSTOM_AUTOMATION_URL,
  ENTITY_EVENTS_TITLE_SUBTITLE,
  ENTITY_EVENTS_TYPE
} from '../../../../constants';
import CommonService from '../../../../services/CommonService/CommonService';
import { Colors } from '../../../../styles';
import { findIsAllowToRunAutomation, getAccountUUID, getCaslAbility } from '../../../../utils/commonUtils';
import { showToast, ToastType } from '../../../../utils/commonViewUtils';
import WorkFlowListByEntityEvents from '../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/WorkFlowListByEntityEvents';
import { capitalizeText } from '../../../common/ContactRelationView/ContactRelationUtils';
import TitleSubtitleView from '../../../common/TitleSubtitleView/TitleSubtitleView';
import { FoldButton } from '../../../CommonComponents/FoldButton/FoldButton';
import FoldPermitCan from '../../../CommonComponents/FoldPermitCan/FoldPermitCan';
import { MAIN_MENU_CODES } from '../../../SideMenuBar/SideBarConst';
import { USER_ACCESS_PERMISSION } from '../../UserAccess/UserAccessPermission';
import InactiveWorkflowWarning from '../../Workflow/Workflow/ExecuteWorkflow/InactiveWorkflowWarning';
import GetContactPhotoAndNameByContactId from '../../Workflow/Workflow/WorkflowTableView/Helper/GetContactPhotoAndNameByContactId';

const AutomationWorkflowEmployee = (props: any) => {
  const {onModalClose, initData, groupList,ruleId} = props;
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const tenantId = props.tenantId || getAccountUUID();
  const toast = useToast();
  const [eventEntityList, setEventEntityList] = useState<any>(null);
  const [isExecuting, setIsExecuting] = useState(false);
  const onSubmit = (): void => {
    setIsExecuting(true)
    const commonService = CommonService.getCommonServiceInstance();
    const workflowService = commonService.workflowService;
    if (!eventEntityList) {
      return;
    }

    const contactIdList = initData?.selectedItems
      ? [
          ...initData?.selectedItems?.map((data: any) => {
            return data?.[props?.type == 'patient' ? 'contactData' : 'contact']
              ?.id || data.id;
          }),
        ]
      : [];
    const segmentIdList = groupList
      ? [
          ...groupList?.map((data: any) => {
            return data?.id;
          }),
        ]
      : [];
    const entityEventMap: any = {};
    (ENTITY_EVENTS_TYPE.WORKFLOW_STATIC || []).forEach((data: any) => {
      if (data?.entity && !entityEventMap[data.entity]) {
        entityEventMap[data.entity] = {};
      }
      if (data?.eventType && !entityEventMap[data.entity][data.eventType]) {
        entityEventMap[data.entity][data.eventType] = data;
      }
    });
    const workflowMasterList = [
      ...eventEntityList?.map((data: any) => {
        return {
          workflowMasterId: data?.workflowMasterId,
          formNodeList: data?.formNodeList,
          isPersonLevelWorkflow:
            entityEventMap[data.entity][data.eventType]
              ?.isPersonLevelWorkflow || false,
        };
      }),
    ];
    if (workflowMasterList.length == 0) {
      showToast(toast, 'Please select workflow from list ', ToastType.error);
      return;
    }
    const data = segmentIdList?.length ? {segmentIdList} : contactIdList?.length ?  {contactIdList} : {segmentIdList: [ruleId]};
    setIsLoading(true);
    workflowService
      .post(`/workflow/event/trigger`, {
        data: {
          ...data,
          isSkipEmptyListExecution:true
        },
        eventType: 'executeStaticWorkflow',
        entity: 'workflow',
        workflowMasterList: workflowMasterList,
        'account-uuid': tenantId,
      })
      .then((resp) => {
        setIsLoading(false);
        setIsExecuting(false)
        if (resp?.data) {
          showToast(toast, 'Automation Execution Initiated', ToastType.success);
          onModalClose();
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setIsExecuting(false)
        showToast(
          toast,
          'Something went wrong. Please Try again later',
          ToastType.error
        );
      });
  };
  return (
    <View>
      <HStack flex={1}>
        <VStack justifySelf={'flex-start'} flex={0.5}>
          <TitleSubtitleView
            isHideCommonButton={true}
            containerStyle={{marginBottom: 0, marginLeft: 0, marginTop: 0}}
            titleLabelId={'automation'}
            subtitleLabelId="automationSubtitle"
          />
        </VStack>
        <HStack
          style={{
            justifyContent: 'flex-end',
            alignItems: 'center',
            flex: 0.5,
            //marginTop: 20,
          }}
        >
          <Button.Group justifyContent={'flex-end'}>
               <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.SECONDARY,
                  onPress: () => {
                    onModalClose(false);
                  },
                  style: {marginRight: 20}
                }}
                customProps={{
                  btnText: intl.formatMessage({
                    id: props.cancelBtnTxt ? props.cancelBtnTxt : "cancel",
                  }),
                  withRightBorder: false,
                }}
              ></FoldButton>
            {findIsAllowToRunAutomation() ? <FoldButton

                nativeProps={{
                  isLoading:isExecuting,
                  isDisabled:isExecuting,
                  variant: BUTTON_TYPE.PRIMARY,
                  onPress: () => {
                    onSubmit();
                  },
                }}
                customProps={{
                  btnText: intl.formatMessage({
                    id: 'Run Automation',
                  }),
                  withRightBorder: false,
                }}
              ></FoldButton> : <></> }
          </Button.Group>
        </HStack>
      </HStack>

      <Text
        size={'smMedium'}
        marginTop={1}
        marginBottom={1}
        color={Colors.Custom.Gray700}
        marginX={0}
      >{''}
        {/* {props?.type == 'patient'
          ? 'Create automation flows'
          : 'Create automation flows'} */}
      </Text>
      <View height={650} overflow={'scroll'}>
      <FoldPermitCan 
        resource={MAIN_MENU_CODES.AUTOMATION}
        action={USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code}
        component={<WorkFlowListByEntityEvents
          tenantId={tenantId}
          addUrl={CUSTOM_AUTOMATION_URL.ADD_PATIENT}
          title={
            ENTITY_EVENTS_TITLE_SUBTITLE.ADD_OR_UPDATE_LEAD_EMPLOYEE_TITLE
          }
          subtitle={
            ENTITY_EVENTS_TITLE_SUBTITLE.ADD_OR_UPDATE_LEAD_EMPLOYEE_SUB_TITLE
          }
          entityEventList={ENTITY_EVENTS_TYPE.WORKFLOW_STATIC}
          onValueChanage={(data: any) => {
            if (data && data.length) {
              const filteredData = data?.filter(
                (data: any) => data.isEnabled
              );
              setEventEntityList(filteredData);
            } else {
              setEventEntityList([]);
            }
          }}
        />}
        />

        <VStack style={{marginTop: 10}}>
          <Text
            style={{marginBottom: 5}}
            size={'mdBold'}
            color={Colors.primary[500]}
          >
            {capitalizeText(props?.type || 'Employee')}
          </Text>
          {groupList?.length ? (
            groupList.map((group: any) => {
              return (
                <Text
                  style={{marginBottom: 5}}
                  size={'smMedium'}
                  color={Colors.primary[500]}
                >
                  {group?.name || ''}
                </Text>
              );
            })
          ) : (
            <></>
          )}
          {initData && initData?.selectedItems?.length
            ? initData?.selectedItems.map((data: any) => {
                return (
                  data?.contact?.name || data?.name ? <Text
                    style={{marginBottom: 5}}
                    size={'smMedium'}
                    color={Colors.primary[500]}
                  >
                    {data?.contact?.name || data?.name}
                  </Text> :
                  <View marginTop={5}><GetContactPhotoAndNameByContactId contactId={data.id}></GetContactPhotoAndNameByContactId></View>
                );
              })
            : null}
        </VStack>
      </View>
      <VStack marginTop={5}>
        <InactiveWorkflowWarning></InactiveWorkflowWarning>
      </VStack>
    </View>
  );
};

export default AutomationWorkflowEmployee;
