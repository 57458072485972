import * as React from 'react';

export interface IUserLocationContext {
  userPracticeLocations?: string[];
  isLocationFilterEnabled?:boolean;
}

export const UserLocationContext = React.createContext<IUserLocationContext>({
  userPracticeLocations: [],
  isLocationFilterEnabled: false
});
