export enum LOADING_STATUS {
  loadingValue,
  loadedValue,
  needToLoad,
}

export enum MetaDataFieldType {
  metaData = 'metaData',
  nodeInfo = 'nodeInfo',
}

export enum TriggerCondition {
  onPatientAddedInPopGroup = 'OnPatientAddedInPopGroup',
}
