import {useLazyQuery} from '@apollo/client';
import {Drawer, Skeleton} from 'antd';
import {useMediaQuery, Text} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../constants/Configs';
import {
  CARE_PROGRAM_CONSENT_TYPE,
  MLOV_CATEGORY,
} from '../../../constants/MlovConst';
import {
  BUTTON_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
  SMALL_WINDOW_1700,
} from '../../../constants/StringConst';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {
  GET_CONSENT_ASSESSMENT_FORM_LOG_DATA,
  GET_CONTACT_CARE_PROGRAM_CONSENT_LOG_DATA,
} from '../../../services/ContactCareProgram/ContactCareProgram';
import {Colors} from '../../../styles/Colors';
import {
  getMlovById,
  getMlovCodeFromId,
  getMlovListFromCategory,
} from '../../../utils/mlovUtils';
import FormResponseView from '../FormResponseView/FormResponseView';
import Stack from '../LayoutComponents/Stack';
import {ModalActionTitle} from '../ModalActionTitle/ModalActionTitle';
import CareProgramConsent from './CareProgramConsent';

export interface ICareProgramConsentViewProps {
  contactCareProgramId: string;
  stepId: string;
  contactId: string;
  programName?: string;
  onClose: (action: boolean) => void;
  locationId?: string | undefined
}

export interface IConsentResponse {
  id: string;
  payerCareProgram: {
    careProgramType: {
      code: string;
      value: string;
    };
  };
  consentLog: {
    id: string;
    hasConsent: boolean;
    resourceId: string;
    resourceTypeCode: string;
    typeId: string;
  };
}

export const WRITTEN_CONSENT = {
  FORM: 'FORM',
  DOCUMENT: 'DOCUMENT',
};

export const CareProgramSubmitConsentView = (
  props: ICareProgramConsentViewProps,
) => {
  const {stepId, contactCareProgramId, onClose, locationId} = props;
  const contextData = useContext(CommonDataContext);
  const screenDimensions = Dimensions.get('window');
  const smallWindow = screenDimensions?.width < SMALL_WINDOW_1700;
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const [consentState, setConsentState] = useState<{
    loading: boolean;
    typeCode: string;
    writtenConsentBy: string;
    contactId: string;
    programName?: string;
    consentForm?: {
      contactId: string;
      formId: string;
      formLogId: string;
      formName: string;
    };
  }>({
    loading: false,
    typeCode: '',
    writtenConsentBy: '',
    contactId: props?.contactId
  });

  const careProgramConsentTypeList =
    getMlovListFromCategory(
      contextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CONTACT_CARE_PROGRAM_CONSENT_TYPE,
    ) || [];

  const [getCareProgramConsent] = useLazyQuery(
    GET_CONTACT_CARE_PROGRAM_CONSENT_LOG_DATA,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    },
  );

  const [getCareProgramConsentFormLogData] = useLazyQuery(
    GET_CONSENT_ASSESSMENT_FORM_LOG_DATA,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    },
  );

  const getConsentLog = async () => {
    try {
      const response = await getCareProgramConsent({
        variables: {
          contactCareProgramId: contactCareProgramId,
        },
      });
      const contactCarePrograms = response?.data?.contactCarePrograms;
      const consentLogData: IConsentResponse = contactCarePrograms?.length
        ? contactCarePrograms?.[0]
        : {} as IConsentResponse;
      const typeId = consentLogData.consentLog?.typeId;
      const consentTypeCode = getMlovCodeFromId(
        careProgramConsentTypeList,
        typeId,
      );

      if (
        consentLogData?.consentLog?.resourceId &&
        consentTypeCode === CARE_PROGRAM_CONSENT_TYPE.WRITTEN &&
        consentLogData?.consentLog?.resourceTypeCode == 'FORM_LOG'
      ) { 
        const formLogResponse = await getCareProgramConsentFormLogData({
          variables: {
            formLogId: consentLogData?.consentLog?.resourceId,
          },
        });
        const consentFormLogData = formLogResponse?.data?.formLog;
        const formData = {
          contactId: consentFormLogData.contactId,
          formId: consentFormLogData?.formResponse?.form?.id,
          formLogId: consentFormLogData?.id,
          formName: consentFormLogData?.formResponse?.form?.name,
        };
        setConsentState(prev => {
          return {
            ...prev,
            programName: consentLogData?.payerCareProgram?.careProgramType?.value,
            typeCode: consentTypeCode,
            loading: false,
            consentForm: formData,
            writtenConsentBy: WRITTEN_CONSENT.FORM,
          };
        });
      } else {
        setConsentState(prev => {
          return {
            ...prev,
            typeCode: consentTypeCode,
            loading: false,
          };
        });
      }
    } catch (err) {
      setConsentState(prev => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };

  useEffect(() => {
    if (!consentState.loading) {
      setConsentState(prev => {
        return {
          ...prev,
          loading: true,
        };
      });
      getConsentLog();
    }
  }, []);
  const isWrittenConsentWithoutForm =
    consentState?.typeCode === CARE_PROGRAM_CONSENT_TYPE.WRITTEN &&
    consentState.typeCode === null;
  return (
    <>
      <Drawer
        destroyOnClose
        placement="right"
        open={true}
        closable
        width={
          isIPadScreen || isIPadMiniScreen ? '60%' : smallWindow ? '50%' : '40%'
        }
        onClose={() => {
          onClose(false);
        }}
        title={
          <ModalActionTitle
            title={'Consent Response'}
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'cancel',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  onClose(false);
                },
              },
            ]}
          />
        }>
        {consentState?.loading && <Skeleton active />}
        {!consentState?.loading && (
          <Stack direction="column" space={24}>
            {(consentState?.typeCode === CARE_PROGRAM_CONSENT_TYPE.VERBAL || isWrittenConsentWithoutForm) && (
              <CareProgramConsent
                  isVisible={true}
                  contactId={props?.contactId}
                  contactCareProgramId={props?.contactCareProgramId}
                  programName={consentState?.programName}
                  onClose={() => {
                    onClose(false);
                  }}
                  isInlineView={true}
                  locationId={locationId}
              />
            )}
            {consentState?.typeCode ==
              CARE_PROGRAM_CONSENT_TYPE.NOT_REQUIRED && (
              <Text>{`Patient consent not required.`}</Text>
            )}

            {consentState?.writtenConsentBy == WRITTEN_CONSENT.FORM &&
              consentState?.typeCode == CARE_PROGRAM_CONSENT_TYPE.WRITTEN && (
                <FormResponseView
                  onClose={() => {
                    onClose(false);
                  }}
                  contactId={consentState.consentForm?.contactId}
                  formId={consentState.consentForm?.formId || ''}
                  formLogId={consentState.consentForm?.formLogId}
                  formName={consentState.consentForm?.formName || ''}
                  showPatientBanner={true}
                  drawerWidth={
                    isIPadScreen || isIPadMiniScreen ? '60%' : smallWindow ? '50%' : '40%'
                  }
                  titleText={'Consent Response'}
                  isInlineView={true}
                />
              )}
          </Stack>
        )}
      </Drawer>
    </>
  );
};
