import {Badge, Divider, HStack, Pressable, Stack, Text, View} from 'native-base';
import {
  IContactCareProgramResponse,
  IContactCareProgramSteps,
  IStepsLog,
} from '../../../services/ContactCareProgram/interface';
import {Colors} from '../../../styles/Colors';
import {CARE_PROGRAM_STEP_TYPE, ENROLLMENT_TOOLTIP_STATUS_MAP, TOOLTIP_STATUS_ANNUAL_VISIT_MAP, TOOLTIP_STATUS_CARE_MAP, TOOLTIP_STATUS_CONSENT_MAP, TOOLTIP_STATUS_TEXT} from './constant';
import Feather from 'react-native-vector-icons/Feather';
import { COMMON_ACTION_CODES } from '../../../constants/ActionConst';
import AWVSvg from '../Svg/CareProgramSteps/AWVSvg';
import ConsentSvg from '../Svg/CareProgramSteps/ConsentSvg';
import { CARE_PROGRAM_STEP_STATUS_CODES } from '../../../constants/MlovConst';
import EcmOutreachSvg from '../Svg/CareProgramSteps/EcmOutreachSvg';
import { getDiffInHours } from '../../../utils/DateUtils';
import TestIdentifiers from '../../../testUtils/TestIdentifiers';
import { testID } from '../../../testUtils/Utils';
import FeatherIcon from 'react-native-vector-icons/Feather';
import { Tooltip } from 'antd';
import {useCareProgramDeclinedInfo} from './customHook/useCareProgramDeclinedInfo';
import AppointmentIconSvg from '../Svg/TimelineSvg/AppointmentIconSvg';
import AssessmentSvg from '../Svg/CareProgramSteps/AssessmentSvg';
import CarePlanStepSvg from '../Svg/CareProgramSteps/CarePlanStepSvg';

export const CareProgramStepWiseView = (props: {
  contactId?: number,
  filterCode: string | undefined,
  contactCareProgram: IContactCareProgramResponse;
  onActionPerformed: (actionCode: string, extraData?: any) => void;
  config?: {
    hideCareProgramName: boolean,
  }
}) => {
  const {contactCareProgram, onActionPerformed, filterCode} = props;
  const {isDeclined, declinedAtStep} = useCareProgramDeclinedInfo(contactCareProgram);
  const getStepLogByContactStepId = (steps: IContactCareProgramSteps): IStepsLog | undefined  => {
    const findStep: IStepsLog | undefined = (contactCareProgram?.stepsLog || []).find(stepLogElem => {
      return stepLogElem.careProgramStepId === steps?.id;
    });
    return findStep;
  }

  const getTimerColorByRemainingTime = (hours: number, stepTypeCode?: string): { backgroundColor: string, fontColor: string } => {
    if (stepTypeCode === CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_14DAY) {
      if (hours <= 24) {
        return {
          fontColor: Colors.Custom.MissedTaskFontColor,
          backgroundColor: Colors.Custom.MissedTaskBackgroundColor,
        };
      } else if (hours <= 36 && hours > 24) {
        return {
          fontColor: Colors.Custom.PendingTaskFontColor,
          backgroundColor: Colors.Custom.PendingTaskBackgroundColor,
        };
      } else {
        return {
          fontColor: Colors.Custom.Gray500,
          backgroundColor: Colors.FoldPixel.GRAY100,
        };
      }
    } else if (stepTypeCode === CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_2DAY) {
      if (hours <= 5) {
        return {
          fontColor: Colors.Custom.MissedTaskFontColor,
          backgroundColor: Colors.Custom.MissedTaskBackgroundColor,
        };
      } else if (hours <= 15 && hours > 5) {
        return {
          fontColor: Colors.Custom.PendingTaskFontColor,
          backgroundColor: Colors.Custom.PendingTaskBackgroundColor,
        };
      } else {
        return {
          fontColor: Colors.Custom.Gray500,
          backgroundColor: Colors.FoldPixel.GRAY100,
        };
      }
    } else {
      return {
        fontColor: Colors.Custom.MissedTaskFontColor,
        backgroundColor: Colors.Custom.MissedTaskBackgroundColor,
      };
    }
  }

  const getTimerElement = (expiresOn: Date, toolTipText: string, stepTypeCode?: string): JSX.Element => {
    let timerText = '';
    const remainingHours = Math.floor(getDiffInHours(new Date(), expiresOn));
    const {icon, background} = getStepColor(CARE_PROGRAM_STEP_STATUS_CODES.EXPIRE);
    if (remainingHours <= 0) {
      return (
        <View style={{backgroundColor: background, padding: 4, borderRadius: 4, overflow: 'hidden'}}>
          <Tooltip title={toolTipText}>
            {stepTypeCode === CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_14DAY ?
              <AppointmentIconSvg customStrokeColor={icon} /> :
              <EcmOutreachSvg customStrokeColor={icon} size={16} />
            }
          </Tooltip>
        </View>
      );
    }

    if (remainingHours > 48) {
      const days: number = Math.floor(remainingHours / 24);
      const dayWiseHours: number = remainingHours % 24;
      timerText = `${days}d ${dayWiseHours} hrs`;
    } else {
      timerText = `${remainingHours} hrs`;
    }
    const timerColor: { backgroundColor: string, fontColor: string } = getTimerColorByRemainingTime(remainingHours, stepTypeCode);
    return (
      <Badge
        backgroundColor={timerColor?.backgroundColor}
        borderRadius={8}
      >
        <Tooltip title={toolTipText}>
          <HStack flex={1} space={1}>
            {stepTypeCode === CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_14DAY && (
              <View marginTop={1}>
                <AppointmentIconSvg customStrokeColor={timerColor.fontColor} />
              </View>
            )}
            {stepTypeCode !== CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_14DAY && (
              <FeatherIcon
                name="clock"
                size={18}
                color={timerColor.fontColor}
                style={{
                  marginRight: 2
                }}
                {...testID(TestIdentifiers.deleteBtn)}
              />
            )}
            <Text color={timerColor.fontColor} fontWeight={'400'} fontSize={14}>
              {timerText}
            </Text>
          </HStack>
        </Tooltip>
      </Badge>
    )
  }

  const getTCMTooltipToConcat = (tooltipStatusText: string,code : string,) => {
    switch (code) {
      case CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_2DAY:
        return tooltipStatusText === TOOLTIP_STATUS_TEXT.IN_PROGRESS ? ' initiated' : (TOOLTIP_STATUS_TEXT.PENDING ? 'not initiated yet' : tooltipStatusText) ;
      case CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_14DAY:
        return tooltipStatusText === TOOLTIP_STATUS_TEXT.IN_PROGRESS ? ' scheduled' : tooltipStatusText;
      default:
        return tooltipStatusText;
    }
  }

  const getStepIcon = (steps: IContactCareProgramSteps) => {
    const stepCode = getStepStatus(steps) || '';
    const {icon, background, tooltipStatusText} = getStepColor(stepCode);
    let element: JSX.Element | undefined = undefined;
    let isTimerStep = false;
    let timer = new Date();
    let iconTooltipText = '';
    const tooltipStatusTextToConcat = tooltipStatusText || '';
    const stepTypeCode = steps.careProgramStepType?.code;
    switch (stepTypeCode) {
      case CARE_PROGRAM_STEP_TYPE.ANNUAL_VISIT:
        element = <AWVSvg customStrokeColor={icon} size={16} />;
        iconTooltipText = `CCM Appointment ${TOOLTIP_STATUS_ANNUAL_VISIT_MAP[tooltipStatusTextToConcat] || tooltipStatusTextToConcat}`;
        break;

      case CARE_PROGRAM_STEP_TYPE.ENROLLMENT:
        element = <AssessmentSvg customStrokeColor={icon} size={16} />;
        iconTooltipText = `${ ENROLLMENT_TOOLTIP_STATUS_MAP[tooltipStatusTextToConcat] || ''} Enrollment`;       
        break;

      case CARE_PROGRAM_STEP_TYPE.ASSESSMENT:
        element = <AssessmentSvg customStrokeColor={icon} size={16} />;
        iconTooltipText = 'Assessment ' + tooltipStatusTextToConcat
        break;

      case CARE_PROGRAM_STEP_TYPE.CONSENT:
        element = <ConsentSvg customStrokeColor={icon} size={16} />;
        iconTooltipText = `Consent ${TOOLTIP_STATUS_CONSENT_MAP[tooltipStatusTextToConcat] || tooltipStatusTextToConcat}`;
        break;

      case CARE_PROGRAM_STEP_TYPE.ECM_OUTREACH:
        element = <EcmOutreachSvg customStrokeColor={icon} size={16} />;
        iconTooltipText = 'ECM outreach ' + getTCMTooltipToConcat(tooltipStatusTextToConcat,CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_2DAY) 
        break;

      case CARE_PROGRAM_STEP_TYPE.CARE_PLAN:
        element = <CarePlanStepSvg customStrokeColor={icon} size={16} />;
        iconTooltipText = `Care plan ${TOOLTIP_STATUS_CARE_MAP[tooltipStatusTextToConcat] || ''}`;       
        break;

      case CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_2DAY:
        iconTooltipText = ' TCM outreach ' + getTCMTooltipToConcat(tooltipStatusTextToConcat, steps?.careProgramStepType?.code);
        if (checkStepStatus(steps, CARE_PROGRAM_STEP_STATUS_CODES.DONE)) {
          element = <EcmOutreachSvg customStrokeColor={icon} size={16} />;
        } else {
          if (checkStepStatus(steps, CARE_PROGRAM_STEP_STATUS_CODES.EXPIRE)) {
            element = <EcmOutreachSvg customStrokeColor={icon} size={16} />;
          } else {
            const findStep: IStepsLog | undefined = getStepLogByContactStepId(steps);
            if (findStep?.expiresOn && stepTypeCode === CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_2DAY) {
              isTimerStep = true;
              timer = findStep?.expiresOn;
              element = getTimerElement(findStep?.expiresOn, iconTooltipText, stepTypeCode);
            }
          }
        }
        break;

      case CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_14DAY:
        iconTooltipText = ' TCM Appointment ' + getTCMTooltipToConcat(tooltipStatusTextToConcat, steps?.careProgramStepType?.code);
        if (checkStepStatus(steps, CARE_PROGRAM_STEP_STATUS_CODES.DONE)) {
          element = <AppointmentIconSvg backgroundColor={background} customStrokeColor={icon} />;
        } else {
          if (checkStepStatus(steps, CARE_PROGRAM_STEP_STATUS_CODES.EXPIRE)) {
            element = <AppointmentIconSvg backgroundColor={background} customStrokeColor={icon} />;
          } else {
            element = <AppointmentIconSvg backgroundColor={background} customStrokeColor={icon} />;
            const findStep: IStepsLog | undefined = getStepLogByContactStepId(steps);
            if (findStep?.expiresOn) {
              isTimerStep = true;
              timer = findStep?.expiresOn;
              return getTimerElement(findStep?.expiresOn, iconTooltipText, stepTypeCode);
            }
          }
        }
        break;
    }
    if (isTimerStep) {
      return (
        <>
          {getTimerElement(timer, iconTooltipText, stepTypeCode)}
        </>
      )
    }
    if (element) {
      return (
        <View style={{backgroundColor: background, padding: 4, borderRadius: 4, overflow: 'hidden'}}>
          <Tooltip title={iconTooltipText}>
            <View>
            {element}
            </View>
          </Tooltip>
        </View>
      );
    }
    return <></>;
  };

  const handleStepSelection = (steps: IContactCareProgramSteps) => {
    const isStepStatusInToDo = checkStepStatus(steps, CARE_PROGRAM_STEP_STATUS_CODES.TO_DO);
    const isStepStatusInFailed = checkStepStatus(steps, CARE_PROGRAM_STEP_STATUS_CODES.FAIL);
    const isStepStatusInDone = checkStepStatus(steps, CARE_PROGRAM_STEP_STATUS_CODES.DONE);
    const isStepStatusInProgress = checkStepStatus(steps, CARE_PROGRAM_STEP_STATUS_CODES.IN_PROGRESS);
    const isStepStatusInReject = checkStepStatus(steps, CARE_PROGRAM_STEP_STATUS_CODES.REJECT);
    const allowToEditAfterComplete: string[] = [
      CARE_PROGRAM_STEP_TYPE.CONSENT,
      CARE_PROGRAM_STEP_TYPE.ASSESSMENT,
      CARE_PROGRAM_STEP_TYPE.ANNUAL_VISIT,
      CARE_PROGRAM_STEP_TYPE.ECM_OUTREACH,
      CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_14DAY,
      CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_2DAY
    ];
    const allowToEditInProgress: string[] = [
      CARE_PROGRAM_STEP_TYPE.CONSENT,
      CARE_PROGRAM_STEP_TYPE.ASSESSMENT,
      // REMOVE
      CARE_PROGRAM_STEP_TYPE.ECM_OUTREACH,
      CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_14DAY,
      CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_2DAY
    ];
    const allowToEditAfterReject: string[] = [
      CARE_PROGRAM_STEP_TYPE.CONSENT,
      CARE_PROGRAM_STEP_TYPE.ECM_OUTREACH,
      CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_14DAY,
      CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_2DAY
    ];
    const allowToEditInProgressStep = isStepStatusInProgress && allowToEditInProgress.includes(steps?.careProgramStepType?.code);
    const allowToEditCompletedStep = isStepStatusInDone && allowToEditAfterComplete.includes(steps?.careProgramStepType?.code);
    const allowToEditRejectedStep = isStepStatusInReject && allowToEditAfterReject.includes(steps?.careProgramStepType?.code);

    const allowToTakeAction = allowToEditInProgressStep || allowToEditCompletedStep || isStepStatusInToDo || isStepStatusInFailed || allowToEditRejectedStep;

    if (allowToTakeAction) {
      const stepCode = steps?.careProgramStepType?.code;
      switch (stepCode) {
        case CARE_PROGRAM_STEP_TYPE.ASSESSMENT:
          if (onActionPerformed && typeof onActionPerformed === 'function') {
            onActionPerformed(
              isStepStatusInDone ? COMMON_ACTION_CODES.SHOW_CARE_PROGRAM_ASSESSMENT  : COMMON_ACTION_CODES.SEND_FORMS,
              {
                stepId: steps.id,
                careProgramId: contactCareProgram?.id,
              }
            );
          }
          break;
        case CARE_PROGRAM_STEP_TYPE.CONSENT:
        case CARE_PROGRAM_STEP_TYPE.ENROLLMENT:
          if (onActionPerformed && typeof onActionPerformed === 'function') {
            onActionPerformed(
              isStepStatusInDone ? COMMON_ACTION_CODES.SHOW_CARE_PROGRAM_CONSENT  : COMMON_ACTION_CODES.CARE_PROGRAM_CONSENT_AND_ENROLL,
              {
                stepId: steps.id,
                careProgramId: contactCareProgram?.id,
                careProgram: contactCareProgram,
              }
            );
          }
          break;
        case CARE_PROGRAM_STEP_TYPE.ECM_OUTREACH:
        case CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_2DAY:
          if (onActionPerformed && typeof onActionPerformed === 'function') {
            onActionPerformed(
              COMMON_ACTION_CODES.ECM_OUTREACH_VIEW,
              {
                stepId: steps.id,
                careProgramId: contactCareProgram?.id,
              }
            );
          }
          break;
        case CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_14DAY:
          if (onActionPerformed && typeof onActionPerformed === 'function') {
            onActionPerformed(
              COMMON_ACTION_CODES.SCHEDULE_APPOINTMENT,
              {
                stepId: steps.id,
                careProgramId: contactCareProgram?.id,
              }
            );
          }
          break;
        case CARE_PROGRAM_STEP_TYPE.ANNUAL_VISIT:
          if (onActionPerformed && typeof onActionPerformed === 'function') {
              onActionPerformed(
                isStepStatusInDone ? COMMON_ACTION_CODES.SHOW_CARE_PROGRAM_AWV_VISIT  : COMMON_ACTION_CODES.SCHEDULE_APPOINTMENT,
                {
                  stepId: steps.id,
                  careProgramId: contactCareProgram?.id,
              });
          }
          break;
        case CARE_PROGRAM_STEP_TYPE.CARE_PLAN:
          window.location.href = `${window.location.origin}/#/members/patient/${props?.contactId}/carePlan`;  
          break;   
      }
    } else if(steps?.careProgramStepType?.code === CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_14DAY && (isStepStatusInProgress || isStepStatusInDone)) {
      if (onActionPerformed && typeof onActionPerformed === 'function') {
        onActionPerformed(
          COMMON_ACTION_CODES.SHOW_CARE_PROGRAM_AWV_VISIT,
          {
            stepId: steps.id,
            careProgramId: contactCareProgram?.id,
          }
        );
      }
    }
  };

  const checkStepStatus = (step: IContactCareProgramSteps, stepCode: string) => {
    const stepId = step?.id;
    return (contactCareProgram?.stepsLog || []).some(stepLog => {
      return (
        stepLog?.careProgramStepId == stepId &&
        stepLog?.careProgramStepStatus?.code == stepCode
      );
    });
  };

  const getStepStatus = (step: IContactCareProgramSteps) => {
    const stepId = step?.id;
    return (contactCareProgram?.stepsLog || []).find(item => item.careProgramStepId === stepId)?.careProgramStepStatus?.code
  };

  const getStepColor = (stepCode: string): {icon: string, background: string, tooltipStatusText?: string} => {
    switch (stepCode) {
      case CARE_PROGRAM_STEP_STATUS_CODES.TO_DO:
        return { icon: Colors.Custom.Gray500, background: Colors.Custom.Gray100, tooltipStatusText: TOOLTIP_STATUS_TEXT.PENDING };

      case CARE_PROGRAM_STEP_STATUS_CODES.SKIP:
        return { icon: Colors.Custom.PendingTaskFontColor, background: Colors.Custom.PendingTaskBackgroundColor };

      case CARE_PROGRAM_STEP_STATUS_CODES.FAIL:
        return { icon: Colors.Custom.MissedTaskFontColor, background: Colors.Custom.MissedTaskBackgroundColor };

      case CARE_PROGRAM_STEP_STATUS_CODES.DONE:
        return { icon: Colors.Custom.CompletedTaskFontColor, background: Colors.Custom.CompletedTaskBackgroundColor,tooltipStatusText: TOOLTIP_STATUS_TEXT.COMPLETED  };

      case CARE_PROGRAM_STEP_STATUS_CODES.EXPIRE:
        return { icon: Colors.Custom.MissedTaskFontColor, background: Colors.Custom.MissedTaskBackgroundColor };

      case CARE_PROGRAM_STEP_STATUS_CODES.IN_PROGRESS:
          return { icon: Colors.Custom.PendingTaskFontColor, background: Colors.Custom.PendingTaskBackgroundColor, tooltipStatusText: TOOLTIP_STATUS_TEXT.IN_PROGRESS };

      default:
        return { icon: Colors.Custom.Gray300, background: Colors.Custom.Gray100 };
    }
  }

  const renderSteps = (steps: IContactCareProgramSteps[]): JSX.Element => {
    return (
      <>
        {(steps || []).map((step, index) => {
          return (
            <>
              <Pressable onPress={() => handleStepSelection(step)}>
                {getStepIcon(step)}
              </Pressable>
              {(index !== steps.length - 1) && (
                <Divider style={{width: 8}}></Divider>
              )}
            </>
          );
        })}
      </>
    );
  };

  const renderCareProgramName = (isDisabled: boolean, prefixIcon?: JSX.Element) => {
    return (
      <View
        padding={1}
        backgroundColor={
          isDisabled ? Colors.Custom.Red100 : Colors.FoldPixel.GRAY100
        }
        borderRadius={4}
      >
        <Text
          wordBreak={'break-word'}
          color={isDisabled ? Colors.FoldPixel.STATUS_ERROR : Colors.Custom.Gray500}
          size={'smLight'}
        >
          {prefixIcon}
          {contactCareProgram?.payerCareProgram?.careProgramType?.value}
        </Text>
      </View>
    );
  }


  return (
    <>
      <Stack
        direction={'row'}
        key={contactCareProgram?.id}
        style={{alignItems: 'center', marginTop: 4}}
      >
        {!props?.config?.hideCareProgramName && (
          <>
            {isDeclined ? (
              <Tooltip title="Patient declined">
                <Pressable
                  onPress={
                    !!declinedAtStep
                      ? () => handleStepSelection(declinedAtStep)
                      : undefined
                  }
                >
                  {renderCareProgramName(isDeclined, <Feather name='slash' size={14} style={{marginRight: 2}} />)}
                </Pressable>
              </Tooltip>
            ) : (
              renderCareProgramName(false)
            )}
          </>
        )}
        {!isDeclined && (
          <Stack
            direction={'row'}
            marginLeft={2}
            style={{alignItems: 'center'}}
          >
            {renderSteps(contactCareProgram?.contactCareProgramSteps)}
          </Stack>
        )}
      </Stack>
    </>
  );
};
