import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Drawer } from 'antd';
import { IAddOrUpdateIframeOrdersProps, IAddOrUpdateIframeOrdersState } from './interfaces';
import { Box, Center, Heading, Icon, Spinner, View, VStack, Text, HStack, Divider } from 'native-base';
import { BUTTON_TYPE, MLOV_CATEGORY, PERSON_TYPES, WINDOW_EVENT_CODES } from '../../../../../../constants';
import { ModalActionTitle } from '../../../../../common/ModalActionTitle/ModalActionTitle';
import { OrderType } from '../OrderConstants';
import { Colors } from '../../../../../../styles';
import { getOrderingURL, populatePrescriptionForPatient, syncOrdersInHGSession } from '../../../../../../services/CommonService/OrderService';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { getOrderCallbackUrl, getStatusDisplay } from '../OrderUtils';
import { OrderIframeResponseCode } from '../../../../../PublicPages/OrderSuccess/OrderSuccessUtils';
import { AddOrUpdateLead } from '../../../../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateLead';
import { CommonDataContext } from '../../../../../../context/CommonDataContext';
import { validatePersonData } from '../../../../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateQueryUtils';
import { EventBus } from '../../../../../../utils/EventBus';
import { SUPPORTED_EVENT_CODE } from '../../../../../../constants/WebSocketConst';
import { getFormDataFromLeadData } from '../../../../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import { updatePatientObject } from '../../../../../RightSideContainer/Contacts/Customer/CustomerView/AddOrUpdateCustomer/AddOrUpdateCustomerUtils';
import { getCapability, getFullEhrCapability, getResourceAbilities, isNewHGFlowEnabled } from '../../../../../../utils/capabilityUtils';
import { CapabilityResource } from '../../../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import { getUserId } from '../../../../../../utils/commonUtils';
import { getContactTypeId, getMlovId, getMlovListFromCategory } from '../../../../../../utils/mlovUtils';
import { getContainedNotes, getQuestionnaireDetails } from '../OrderDetailsDrawer/OrderDetailsUtils';
import {isAccountConfigEnabled} from '../../../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../../../constants/AccountConfigConst';

const AddOrUpdateIframeOrders = (props: IAddOrUpdateIframeOrdersProps) => {
  const defaultState: IAddOrUpdateIframeOrdersState = {
    iframeURL: '',
    loading: false,
    iframeLoading: true,
    showPatientEditModal: false,
    showOrderingModal: false,
    errorMessage: 'Something went wrong to initiate ordering experience.',
    updatedPatientData: undefined,
    sessionStart: undefined,
    sessionEndLoading: false,
  };
  const [addOrUpdateOrderState, setAddOrUpdateOrderState] = useState<IAddOrUpdateIframeOrdersState>(defaultState);
  const [missingFieldsAlert, setMissingFieldAlert] = useState<string>('');
  const commonContextData = useContext(CommonDataContext);
  const ehrCapabilities = getFullEhrCapability(
    '',
    props?.personData?.accountLocationUuid
  );
  const formattedLeadData = getFormDataFromLeadData(
    props.unFormattedContactData,
    commonContextData
  );
  const NEW_HG_FLOW = isNewHGFlowEnabled();
  const patientCapability = getResourceAbilities(
    CapabilityResource.patient,
    '',
    props?.personData?.accountLocationUuid
  );
  const shouldCreateUsingIntegration = patientCapability?.createPatientUsingIntegration || false;
  const isPhoneOrEmailRequired = isAccountConfigEnabled(CONFIG_CODES.IS_PHONE_OR_EMAIL_REQUIRED);
  const userId = getUserId();
  const pronounList = getMlovListFromCategory(
    commonContextData.MLOV,
    MLOV_CATEGORY.PRONOUN
  );
  const customerTypeId = getContactTypeId(
    PERSON_TYPES.CUSTOMER
  )

  const getHeaderTitle = () => {
    switch (props.orderType) {
      case OrderType.LAB: return props.selectedOrder?.id ? 'Edit Order' : 'New Lab Order';
      case OrderType.MED: return 'eRx';
      case OrderType.RAD: return props.selectedOrder?.id ? 'Edit Order' : 'New Imaging Order';
    }
  };

  const resetState = () => {
    setAddOrUpdateOrderState(defaultState);
  }

  const closeDrawer = () => {
    resetState();
    props.onClose?.();
  }

  const onClose = () => {
    setAddOrUpdateOrderState((prev) => ({...prev, sessionEndLoading: true}));
    if (props.orderType === OrderType.MED) {
      populatePrescriptionForPatient(`${formattedLeadData.leadId || formattedLeadData.id || ''}`).then(() => {
        broadcastOrderEvent(SUPPORTED_EVENT_CODE.ORDER_CREATED);
        closeDrawer();
      }).catch(() => {
        broadcastOrderEvent(SUPPORTED_EVENT_CODE.ORDER_CREATED);
        closeDrawer();
      });
    } else if (NEW_HG_FLOW) {
      syncOrdersInHGSession({
        patientId: props.personData?.patientId || props.personData?.patientUuid,
        sessionStart: addOrUpdateOrderState.sessionStart || new Date(),
        sessionEnd: new Date(),
        documentReferenceId: props?.documentReferenceId,
      })
      .then(() => {
        broadcastOrderEvent(SUPPORTED_EVENT_CODE.ORDER_CREATED);
        closeDrawer();
      })
      .catch(() => {
        broadcastOrderEvent(SUPPORTED_EVENT_CODE.ORDER_CREATED);
        closeDrawer();
      })
    } else {
      broadcastOrderEvent(SUPPORTED_EVENT_CODE.ORDER_CREATED);
      closeDrawer();
    }
  }

  const broadcastOrderEvent = (code: string) => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(code, {patientId: props.personData?.patientId || props.personData?.patientUuid});
  };

  const onEventReceived = useCallback((event: MessageEvent) => {
    try {
      if (typeof event.data === 'string') {
        const data = JSON.parse(event.data);
        if (
          data.messageCode === WINDOW_EVENT_CODES.ORDER_UPDATES &&
          data.responseCode == OrderIframeResponseCode.cancelled
        ) {
          onClose();
        }
      }
    } catch (error) {
    }
  }, []);

  const getPatientObjectForUpdate = (validatedPatientData?: any) => {
    return updatePatientObject({
      data: {
        ...formattedLeadData,
        typeId: customerTypeId,
        ...(validatedPatientData?.vitalObservations && {vitalObservations: validatedPatientData?.vitalObservations || []}),
      },
      addTagObjectList: [],
      defaultLocationUuid: formattedLeadData?.locationUuid,
      isSexAtBirthRequired: shouldCreateUsingIntegration,
      sendInvite: false,
      userId: userId,
      pronounList: pronounList
    });
  }

  const onAddOrUpdateFormOpen = async () => {
    setAddOrUpdateOrderState((prev) => ({
      ...prev,
      sessionStart: new Date(),
      loading: true,
    }));
    if (props.orderType === OrderType.MED) {
      const {validationDetails, missingFieldDetails, validatedPatientData} =
        await validatePersonData(
          props?.personData,
          ehrCapabilities,
          props?.personData?.accountLocationUuid
        );
      if (!validationDetails?.validate) {
        if (missingFieldDetails?.message) {
          missingFieldDetails.message += '. These details are required to initiate ordering.';
        }
        const errors = validationDetails.errors || {};
        const validationErrors = Object.keys(errors).map((key) => errors[key]).filter((item) => !!item && typeof item === 'string').join(', ');
        setMissingFieldAlert(missingFieldDetails?.message || validationErrors);
        setAddOrUpdateOrderState((prev) => ({...prev, showPatientEditModal: true, loading: false}));
      } else {
        setAddOrUpdateOrderState((prev) => ({
          ...prev,
          updatedPatientData: getPatientObjectForUpdate(validatedPatientData),
          showOrderingModal: true,
          loading: false,
        }));
      }
    } else {
      setAddOrUpdateOrderState((prev) => ({
        ...prev,
        updatedPatientData: getPatientObjectForUpdate(props?.personData),
        showOrderingModal: true,
        loading: false,
      }));
    }
  }

  const renderOrderDetails = (): JSX.Element | undefined => {
    const selectedOrder = props.selectedOrder;
    if (selectedOrder?.action?.length) {
      return (
        <VStack marginLeft={2} space={2} marginBottom={4} marginTop={-1}>
          {selectedOrder.action.map((action, index) => {
            let details: {key: string, value: string}[] = [];
            if (selectedOrder?.priority) {
              details.push({key: 'Priority', value: getStatusDisplay(selectedOrder.priority)});
            }
            const questionnaireDetails = getQuestionnaireDetails(selectedOrder, [action]);
            details = details.concat(questionnaireDetails);
            const individualOrderNote = getContainedNotes(selectedOrder, [action]);
            if (individualOrderNote) {
              details.push({key: 'Test Notes', value: individualOrderNote});
            }
            const isLastElement = index == ((selectedOrder.action?.length || 0) - 1);
            return (
              <>
                <VStack>
                  <Text fontWeight={600}>{action?.resource?.display}</Text>
                  <Text isTruncated={isLastElement} fontSize="xs" fontWeight={'medium'} color={Colors.Custom.Gray500}>
                    {details.map((data) => {
                      return `${data.key}: ${data.value}`
                    }).join('  •  ')}
                    </Text>
                </VStack>
                {!isLastElement && <Divider />}
              </>
            );
          })}
        </VStack>
      );
    }
  }

  const broadcastPatientUpdateEvent = () => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(WINDOW_EVENT_CODES.PATIENT_UPDATES, {});
  }

  useEffect(() => {
    if (props.isVisible) {
      onAddOrUpdateFormOpen();
    }
  }, [props.isVisible]);

  useEffect(() => {
    if (addOrUpdateOrderState.showOrderingModal) {
      setAddOrUpdateOrderState((prev) => ({
        ...prev,
        loading: true,
      }));
      getOrderingURL(
        props.orderType,
        props.patientContactId,
        props?.documentReferenceId || '',
        getOrderCallbackUrl(),
        NEW_HG_FLOW,
        (response) => {
          setAddOrUpdateOrderState((prev) => ({
            ...prev,
            ...(response?.data?.widgetUrl && {iframeURL: response.data.widgetUrl}),
            loading: false
          }));
        }, (error) => {

          const message = error?.response?.data?.message || 'Something went wrong to initiate ordering experience.';
          setAddOrUpdateOrderState((prev) => ({
            ...prev,
            loading: false,
            iframeLoading: false,
            errorMessage: message
          }));
        },
        addOrUpdateOrderState.updatedPatientData,
        props?.selectedOrder?.id,
      );
    }
  }, [addOrUpdateOrderState.showOrderingModal]);

  useEffect(() => {
    window.addEventListener('message', onEventReceived);
    return () => {
      window.removeEventListener('message', onEventReceived);
    };
  }, []);


  const height = window.innerHeight - 124;

  return (
    <>
      {addOrUpdateOrderState.showPatientEditModal && (
        <AddOrUpdateLead
          onFormCompleteAction={(actionCode: string, additionalData: any) => {
            broadcastPatientUpdateEvent();
            setAddOrUpdateOrderState((prev) => ({
              ...prev,
              updatedPatientData: additionalData?.updateData || getPatientObjectForUpdate(),
              showOrderingModal: true,
              showPatientEditModal: false,
            }));
          }}
          isOrderingWorkflow={true}
          isShowModal={addOrUpdateOrderState.showPatientEditModal}
          handleCloseModal={() => {
            onClose();
          }}
          personType={props?.unFormattedContactData?.contactType?.contactType.code}
          personTypeUuid={props?.unFormattedContactData?.contactType?.contactType?.id}
          // invocationCode={RIGHT_SIDE_CONTAINER_CODE.CONTACT_EDIT_VIEW}
          isEmployeeView={true}
          singleLeadData={props?.unFormattedContactData}
          alert={missingFieldsAlert}
        // showConsentError={true}
        />
      )}
      <Drawer
        headerStyle={{borderWidth: 0, marginBottom: 0}}
        destroyOnClose
        placement="right"
        onClose={onClose}
        visible={props.isVisible}
        closable={false}
        width={'80%'}
        maskClosable={false}
        title={
          <>
            <ModalActionTitle
              title={getHeaderTitle()}
              titleColor={''}
              buttonList={[
                {
                  show: true,
                  id: 1,
                  btnText: 'close',
                  size: 'sm',
                  isLoading: addOrUpdateOrderState.sessionEndLoading,
                  isDisabled: addOrUpdateOrderState.sessionEndLoading,
                  textColor: Colors.Custom.mainSecondaryBrown,
                  variant: BUTTON_TYPE.SECONDARY,
                  isTransBtn: false,
                  onClick: onClose,
                },
              ]}
            />
          </>
        }
      >
        <View height={height} marginX={-2}>
          {renderOrderDetails()}
          {(addOrUpdateOrderState.loading ||
            addOrUpdateOrderState.iframeLoading) && (
              <Center h='100%'>
                <Spinner size={'lg'} />
              </Center>
            )}
          {!addOrUpdateOrderState.loading && addOrUpdateOrderState.iframeURL && (
            <iframe
              src={addOrUpdateOrderState.iframeURL}
              height="100%"
              width="100%"
              style={{border: 'none', visibility: addOrUpdateOrderState.iframeLoading ? 'hidden' : 'visible'}}
              onLoadStart={() => {
                setAddOrUpdateOrderState((prev) => ({
                  ...prev,
                  iframeLoading: true,
                }));
              }}
              onLoad={() => {
                setAddOrUpdateOrderState((prev) => ({
                  ...prev,
                  iframeLoading: false,
                }));
              }}
            />
          )}
          {!addOrUpdateOrderState.loading && !addOrUpdateOrderState.iframeLoading && !addOrUpdateOrderState.iframeURL && (
            <Box
              borderColor={Colors.Custom.BorderColor}
              rounded="lg"
              borderWidth={1}
            >
              <View padding={4}>
                <VStack space={2}>
                  <View alignItems="center" justifyContent="center">
                    <Icon
                      as={AntIcon}
                      name="warning"
                      size="24"
                      color="error.500"
                    />
                  </View>
                  <View alignItems="center" justifyContent="center">
                    <Heading>
                      {addOrUpdateOrderState.errorMessage}
                    </Heading>
                  </View>
                </VStack>
              </View>
            </Box>
          )}
        </View>
      </Drawer>
    </>
  );
}

export default AddOrUpdateIframeOrders;
