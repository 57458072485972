import React from "react";
import { useIntl } from "react-intl";
import { getEncounterDetailById, getPdfFromHTML } from "../../services/CommonService/AidBoxService";
import { ATHENA_VISIT_HISTORY_CSS, VISIT_HISTORY_PDF_MARGIN } from "../PersonOmniView/MiddleContainer/PatientNotes/EncounterNotes/EncounterNotesHelper";

export const usePrintVistHistory = (
  patientId: string,
  onSuccess: (blobUrl: string) => void,
  onError: (error: string) => void,
  locationId?: string,
) => {
    const intl = useIntl();
    const triggerPrint = async (id: string) => {
      try {
        const encounterResponse = await getEncounterDetailById(patientId, id, locationId);
        const resourceEntry = encounterResponse?.data?.entry || [];
        const resource = resourceEntry?.length ? resourceEntry[0].resource : {};
        if (resource?.content && resource?.content?.length) {
          const attachment = resource?.content?.[0]?.attachment;
          const response = await getPdfFromHTML(
            attachment?.data,
            undefined,
            ATHENA_VISIT_HISTORY_CSS,
            VISIT_HISTORY_PDF_MARGIN
          );
          const file: any = {
            blob: response.data,
            blobUrl: URL.createObjectURL(response.data),
          };
          if (file?.blobUrl) {
            onSuccess(file.blobUrl);
          } else {
            onError('Facing issue while printing. Please check and try again.');
          }
        } else {
          onError('Facing issue while printing. Please check and try again.');
        }
      } catch {
        onError(('Facing issue while printing. Please check and try again.'));
      }
    };

  return {triggerPrint};
};