import { ApolloError, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Drawer, Select, Tooltip } from 'antd';
import parse from 'html-react-parser';
import { Liquid } from 'liquidjs';
import {
  Center,
  Divider,
  FormControl,
  HStack,
  Input,
  Spinner,
  Text,
  useMediaQuery,
  useToast,
  View,
  VStack
} from 'native-base';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import {
  BUTTON_TYPE, IPAD_MINI_WIDTH,
  IPAD_WIDTH,
  MLOV_CATEGORY,
  PATIENT_CONSENT_ERROR,
  POPULATION_GROUP_TYPES
} from '../../../constants';
import { COMMON_ACTION_CODES } from '../../../constants/ActionConst';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import {
  FORM_SOURCE,
  FORM_STATUS_CODE,
  TASK_ENTITY_TYPE_CODES
} from '../../../constants/MlovConst';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { ILoginUserData } from '../../../Interfaces';
import { CareJourneyQueries, FormsQueries, TaskQueries } from '../../../services';
import { GetAccountIdBySubdomain } from '../../../services/AccountSubdomain/AccountSubdomainQuery';
import ContactsQueries, { GET_PATIENT_IDS_BY_CONTACT_UUIDS } from '../../../services/Contacts/ContactsQueries';
import EmployeeQueries from '../../../services/Employee/EmployeeQueries';
import InboxQueries from '../../../services/Inbox/InboxQueries';
import {
  IContactInbox,
  IContactInboxesResp,
  IInbox
} from '../../../services/Inbox/interfaces';
import { Colors } from '../../../styles';
import {
  getAccountId,
  getAccountUUID,
  getFormURL,
  getSecondaryValue,
  getUserActionObjectFromActionCode,
  getUserId,
  getUserUUID,
  isContactConsentRequired,
  isJourneyPackagesEnabled,
  isString,
  isValidEmail
} from '../../../utils/commonUtils';
import {
  showPhiWarningToast,
  showToast,
  ToastType
} from '../../../utils/commonViewUtils';
import { getDateToMomentISOString, getFormTaskDates } from '../../../utils/DateUtils';
import {
  getMlovCodeIdObj,
  getMlovIdFromCode,
  getMlovListFromCategory
} from '../../../utils/mlovUtils';
import { useContainerDimensions } from '../../CustomHooks/ContainerDimensionHook';
import {
  getAccountMergeTagData,
  getTemplateCategories,
  getTemplateCategoryList
} from '../../RightSideContainer/ContentManagement/ContentManagementUtils';
import {
  IEmailTemplateData,
  ITemplateCategory
} from '../../RightSideContainer/ContentManagement/EmailTemplates/interfaces';
import { ICreateEmailConversationDrawerState } from '../../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/CreateEmailConversationDrawer/interface';
import ContinueWithExistingConversationPopup from '../../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/CreateSmsConversationDrawer/ContinueWithExistingConversationPopup';
import {
  createSmsInboxConversation,
  sendEmailMessageAPI
} from '../../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/CreateSmsConversationDrawer/SmsConversationServices';
import { ALLOWED_TEMPLATES, CHANNEL_TYPE, TEMPLATES_CONST } from '../../RightSideContainer/TeamInbox/Conversations/ConversationConst';
import { getUserIdListFromMsgContent } from '../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/ConversationMentions/ConversationMentions';
import {
  getGroupMemberEmailList,
  isGroupConversation
} from '../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingUtils';
import { RichText } from '../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/RichText/RichText';
import { SMS_INBOX_ACTION_CODES } from '../../RightSideContainer/TeamInbox/Integrations/IntegrationCreate/SmsInboxCreate/SmsInboxConst';
import { USER_ACCESS_PERMISSION } from '../../RightSideContainer/UserAccess/UserAccessPermission';
import { getGroupDetailById } from '../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { MAIN_MENU_CODES } from '../../SideMenuBar/SideBarConst';
import { AllTemplateSearch } from '../AllTemplateSearch';
import { getDefaultTaskStatusId } from '../CareDashboard/CareDashboardUtils/CareDashboardUtils';
import { CarePlanSearch } from '../CarePlanSearch';
import { IContact } from '../CreateChannelView/interface';
import FormSearch from '../FormSearch/FormSearch';
import { GroupSearch } from '../GroupSearch';
import { ModalActionAntSelect } from '../ModalActionCommonComponent/ModalActionAntSelect';
import { ModalActionSelect } from '../ModalActionCommonComponent/ModalActionSelect';
import { ModalActionTitle } from '../ModalActionTitle/ModalActionTitle';
import PatientSearchAndSelect from '../PatientSearch/PatientSearchAndSelect';
import { handleSendFormToMemberAction } from '../UsageLevel/UsageLevelHandlers';
import AllPatientEmailList from './AllPatientEmailList';
import './EmailDrawerCommon.css';
import { styles } from './EmailPopupViewStyles';
import {
  filterBulkEmailWithConsentCheck,
  getContactIdsFromGroupResponse,
  getEmailFormattedObject,
  getFormattedCarePlans,
  getFormattedFormEmailData, getRecipientDataFromContacts,
  getUpdatedCreateEmailConversationErrorMessages,
  getWithoutConsentPatients,
  handleShareProducts,
  ICarePlan,
  removeDuplicates,
  sendEmail,
  sendFormToGroupAndMembers,
  sendMultipleFormEmail
} from './EmailPopupViewUtils';
import { RecipientMemberData } from './interface';
import Feather from 'react-native-vector-icons/Feather';
import MultipleFormSearch from '../MultipleFormSearch/MultipleFormSearch';
import { IProduct } from '../SearchPackages/interfaces';
import { SearchPackages } from '../SearchPackages';
import ConversationsQueries from '../../../services/Conversations/ConversationsQueries';
import { createCopyLinkPackageApi } from '../../RightSideContainer/Sales/ProductsAndServices/JourneyPackages/PackageServices';
const Option = Select;

interface ICategory {
  name: string;
  code: string;
}

interface IForm {
  id: string;
  name: string;
  taskId?: string;
}

// const categoryList: ICategory[] = [
//   {
//     name: 'General',
//     code: 'GENERAL',
//   },
//   {
//     name: 'Patient Form',
//     code: 'MULTIPLE_PATIENT_FORMS',
//   },
//   {
//     name: 'Single Care Plan Suggestion',
//     code: 'SINGLE_CAREPLAN_SUGGESTION',
//   },
// ];
const COMPULSORY_FORM_FIELDS = {
  TO: 'TO',
  TEMPLATE_CATEGORY: 'TEMPLATE CATEGORY',
  TEMPLATE: 'TEMPLATE',
  PRODUCTS: 'PRODUCTS',
  MEMBER_OR_GROUPS: 'MEMBER_OR_GROUPS',
};
const EmailDrawerCommon = (props: any) => {
  const [visible, setVisible] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState<ICategory>();
  const [formValidationStatus, setFormValidationStatus] = useState<{
    code: string;
    error: string;
  }>();
  const [templatesData, setTemplatesData] = useState<IEmailTemplateData[]>();
  const [selectedTemplate, setSelectedTemplate] =
    useState<IEmailTemplateData>();
  const [richTextValue, setRichTextValue] = useState('');
  const [carePlanDetails, setCarePlanDetails] = useState<ICarePlan[]>();
  const [products, setProducts] = React.useState<IProduct[]>([]);
  const [formLinkData, setFormLinkData] = useState<any>();
  const [selectedCarePlan, setSelectedCarePlan] = useState<ICarePlan>();
  const [contactInfoMessage, setContactInfoMessage] = useState<string>('');
  const userId = getUserId();
  const accountId = getAccountId();
  const smsConversationInboxToast = useToast();
  const commonData = useContext(CommonDataContext);
  const userData = commonData.userData || ({} as ILoginUserData);
  const {selectedConversation} = props;
  const componentRef = useRef();
  const mlovDataObj = commonData.MLOV;
  const groupMemberTypeList = mlovDataObj[MLOV_CATEGORY.GroupMemberType];
  const groupMemberCodeIdObj = getMlovCodeIdObj(groupMemberTypeList);
  const {width, resetDimension} = useContainerDimensions(componentRef);
  const isConsentRequired = isContactConsentRequired();
  const [formEmailState, setFormEmailState] = useState({
    members: [] as any[],
    groups: [] as string[],
    membersData: [] as any[],
  });

  const isJourneyPackagesEnabledForAccount = isJourneyPackagesEnabled();

  const [getConversationByDisplayId] = useLazyQuery(ConversationsQueries.GetConversationById)

  const getCurrentSubdomain = () => {
    if (window.location.host.includes('localhost')) {
      return 'demo.qa.foldhealth.io';
    }
    return window.location.host;
  };

  const isGroupEmail = () => {
    return isGroupConversation(selectedConversation);
  };

  const getContactEmails = () => {
    let contactEmailList = !props?.isRank2
      ? props.contactData?.singleLeadData?.email
        ? props.contactData?.singleLeadData?.email
        : props.contactData?.email
      : getSecondaryValue(props.contactData?.personContact, 'email');
    if (isGroupEmail()) {
      contactEmailList = getGroupMemberEmailList(
        selectedConversation?.groupConversation?.groupMembers || []
      );
    }
    return contactEmailList;
  };

  const contactEmail = getContactEmails();
  const accountMergeTags = getAccountMergeTagData();

  const {data, loading} = useQuery(GetAccountIdBySubdomain, {
    variables: {
      subdomain: getCurrentSubdomain(),
    },
  });

  const supportEmail =
    data?.accountSubdomainMaps?.length > 0
      ? data?.accountSubdomainMaps[0].accountToSubdomain.supportEmail
      : '';

  const mlovData = useContext(CommonDataContext);
  const taskStatusMlov =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_STATUS
    ) || [];
  const defaultStatusId = getDefaultTaskStatusId(taskStatusMlov);
  const taskEntityTypes =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_ENTITY_TYPE
    ) || [];
  const TASK_ENTITY_TYPES = {
    form: getMlovIdFromCode(taskEntityTypes, TASK_ENTITY_TYPE_CODES.FORM),
  };
  const userUUID = getUserUUID();

  const formSourceList =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.FORM_SOURCE
    ) || [];
  const formSourceId = getMlovIdFromCode(formSourceList, FORM_SOURCE.EMAIL);

  const [
    CreateEmailConversationDrawerState,
    setCreateEmailConversationDrawerState,
  ] = useState<ICreateEmailConversationDrawerState>({
    selectedInbox: {} as any,
    isPatientDataLoading: false,
    isLoading: false,
    selectedPatient: {} as IContact,
    patientLists: [] as IContact[],
    messageStr: '',
    subject: '',
    errors: {} as any,
    isConfirmModalOpen: false,
    existingConversation: {} as any,
    inboxList: [] as IInbox[],
    isConsentGiven: true as boolean,
    taskId: '' as string,
    showTemplateMessage: false
  });

  const getDefaultEmail = () => {
    const supportEmail =
      data?.accountSubdomainMaps?.length > 0
        ? data?.accountSubdomainMaps[0].accountToSubdomain.supportEmail
        : '';

    const defaultEmail = {
      id: -1,
      name: 'Default Email',
      channelEmail: {
        email: supportEmail,
      },
    };
    return defaultEmail;
  };

  const getBulkEmails = () => {
    let bulkEmail =
      props.bulkEmail && props.bulkEmail.length
        ? props.bulkEmail
        : props.bulkEmail?.contactData &&
          props?.bulkEmail?.contactData?.length > 0
          ? props.bulkEmail?.contactData
          : [];

    if (isGroupEmail()) {
      bulkEmail = getGroupMemberEmailList(
        selectedConversation?.groupConversation?.groupMembers || []
      );
    }
    const finalBulkEmail = filterBulkEmailWithConsentCheck(
      isConsentRequired,
      bulkEmail,
      props?.bulkContactData
    );
    return finalBulkEmail;
  };

  // email data state
  const [emailData, setEmailData] = useState<any>();
  const bulkEmail = getBulkEmails();
  const [categories, setCategories] = useState<ITemplateCategory[]>([]);

  const formattedEmailData = getEmailFormattedObject(
    emailData,
    props?.contactData?.name,
    bulkEmail
  );

  const contactUuid = props?.contactData?.uuid;
  const accountUuid = getAccountUUID();

  const toast = useToast();
  const intl = useIntl();

  const [searchCareJourneys] = useLazyQuery(
    CareJourneyQueries.GET_CARE_JOURNEYS_BY_TITLE,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  useEffect(() => {
    getTemplateCategories()
      .then((data) => getTemplateCategoryList(data, true))
      .then((list) => {
        let filteredList = [];
        if (props?.showAllTemplates) {
          filteredList = list
          setSelectedCategory({code: 'All', name: 'All'});
        } else {
          filteredList = list.filter((template)=>{
            return ALLOWED_TEMPLATES.includes(template?.code)
          })
          if (!isJourneyPackagesEnabledForAccount) {
            filteredList = filteredList.filter((item) => {
              return item.code !== TEMPLATES_CONST.SHARE_PACKAGES;
            });
          }
        }
        setCategories(filteredList);
      })
      .catch((error) => {});

    searchCareJourneys({
      variables: {
        searchString: `%%`,
      },
    }).then((response) => {
      responseHandler(response)
    })
      .catch((error: any) => {
        errorHandler(error)
      });

    if (props?.templateData?.templateCategoryCode) {
      const category = {
        name: props?.templateData?.templateCategory,
        code: props?.templateData?.templateCategoryCode,
      };
      setSelectedCategory(category);
      // getTemplatesByCategoryCode(
      //   category as ICategory,
      //   props?.templateData?.id
      // );
    }
    const to =
      bulkEmail?.length > 0
        ? bulkEmail
        : Array.isArray(contactEmail)
          ? contactEmail
          : contactEmail
            ? [contactEmail]
            : [];

    setEmailData((prev: any) => ({
      ...prev,
      to: to,
      from: supportEmail,
      subject: props?.templateData?.templateData?.subject,
    }));

    if (props.category) {
      // getTemplatesByCategoryCode(props.category);
      setSelectedCategory(props.category);
    }
    if (props?.content) {
      setRichTextValue(props?.content);
      setEmailData((prev: any) => {
        return {
          ...prev,
          emailContent: props?.content,
        };
      });
    }

    if (props.form) {
      setFormLinkData(props.form);
    }
  }, []);

  useEffect(() => {
    const isFormWithHealthComponent = formLinkData?.isHealthComponentIncluded || emailData?.selectedForms?.some((item: any) => item.isHealthComponentIncluded) || false;
    if (isFormWithHealthComponent) {
      let isLeadSelected = false;
      const multiSelectEnabled = canShowMultiSelectEmail();
      const isFormEmailState = isGroupEnabledOrFormCategorySelected();
      if ((!multiSelectEnabled || !isFormEmailState) && CreateEmailConversationDrawerState.selectedPatient) {
        isLeadSelected = CreateEmailConversationDrawerState.selectedPatient?.contactType?.contactType?.code === 'LEAD';
      }
      if (multiSelectEnabled && isFormEmailState && formEmailState.members.length) {
        isLeadSelected = formEmailState.members.some((item) => item.contactType?.contactType?.code === 'LEAD');
      }
      if (bulkEmail.length) {
        isLeadSelected = props.currentContactType === 'LEAD' || props.currentContactType === '';
      }
      const message = intl.formatMessage({ id: 'healthComponentFormInfoMessageForLead' });
      setContactInfoMessage(isLeadSelected ? message : '');
    } else {
      setContactInfoMessage('');
    }
  }, [CreateEmailConversationDrawerState.selectedPatient, formEmailState.members, formLinkData, emailData?.selectedForms]);

  useEffect(()=> {
    if (props?.contactData?.uuid) {
      checkForConsent(props?.contactData);
    }
  }, [props?.contactData]);

  const isSendButtonDisabled = () => {
    if (props?.isEditMode) {
      return false;
    } else {
      const result =
        !selectedTemplate ||
        (selectedCategory?.code === 'MULTIPLE_PATIENT_FORMS'
          ? !formLinkData
          : undefined) ||
        (selectedCategory?.code === 'SINGLE_CAREPLAN_SUGGESTION'
          ? !selectedCarePlan
          : undefined);
      return result;
    }
  };

  const formStatusList =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.FORM_STATUS
    ) || [];

  const formStatusId = getMlovIdFromCode(
    formStatusList,
    FORM_STATUS_CODE.PENDING
  );

  const [getPatientIds] = useLazyQuery(GET_PATIENT_IDS_BY_CONTACT_UUIDS, {
    fetchPolicy: 'no-cache',
  });

  const [insertFormLogs] = useMutation(FormsQueries.CREATE_MULTIPLE_FORM_LOG, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    onCompleted: () => {
    },
    onError: (error) => {
    },
  });

  const [insertFormLog] = useMutation(FormsQueries.CREATE_FORM_LOG, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    onCompleted: () => {
    },
    onError: (error) => {
    },
  });

  const getFormLogSaveData = (taskId?: string) => {
    return {
      formId: formLinkData.id,
      subjectId: taskId || emailData.formSubjectId,
      sourceId: formSourceId,
      contactId: contactUuid || CreateEmailConversationDrawerState?.selectedPatient?.uuid,
      statusId: formStatusId,
    };
  };

  const getTasksDataForSendForm = (form: IForm, contactId?: string): any[] => {
    const taskData: any[] = [];
    const {startDate, endDate} = getFormTaskDates();
    taskData.push({
      title: form.name,
      assigneeId: contactId,
      contactId: contactId,
      assignedById: userUUID,
      statusId: defaultStatusId,
      referenceData: {
        entity: {
          id: form.id,
          name: form.name,
          metadata: [],
          sourceId: formSourceId,
        },
        entityType: 'FORM',
      },
      entityTypeId: TASK_ENTITY_TYPES.form,
      formId: form.id,
      startDateTime: startDate,
      endDateTime: endDate,
    });
    return taskData;
  };

  const [createTasks] = useMutation(TaskQueries.CREATE_TASKS, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });

  const userActionObject = getUserActionObjectFromActionCode(
    'SEND_AN_EXISTING_FORM_TO_A_MEMBER'
  );
  const level = userActionObject.level || '';

  const [getContactsByIds] = useLazyQuery(
    ContactsQueries.GET_CONTACTS_BY_CONTACTIDS,
    {
      fetchPolicy: 'no-cache',
      onError: (error: any) => {

      },
    }
  );

  const getFormDataForGroupMembers = async (form: any) => {
    const {groupData, memberData} = getFormattedFormEmailData(formEmailState);
    let finalMemberData: RecipientMemberData[] = [...memberData];
    if (groupData.length > 0) {
      const promiseList = groupData.map((item) => {
        return getGroupDetailById(item.groupId)
      });
      const groupResponse = await Promise.all(promiseList);
      const contactIds = getContactIdsFromGroupResponse({groupResponse})
      const {data} = await getContactsByIds({variables: {contactIdList: contactIds}})
      const groupMembersData = getRecipientDataFromContacts({data})
      finalMemberData = [...memberData, ...groupMembersData];
    }
    finalMemberData = removeDuplicates(finalMemberData);

    // Create tasks and store taskId as subjectId in finalMemberData
    const addTaskData: any[] = [];
    finalMemberData.forEach((item) => {
      if (item.contactUuid) {
        const taskData = getTasksDataForSendForm(form, item.contactUuid);
        if (taskData.length) {
          addTaskData.push(taskData[0]);
        }
      }
    });
    if (addTaskData.length) {
      const taskResponse = await createTasks({
        variables: {
          data: addTaskData,
        },
      });
      if (
        taskResponse?.data?.addOrUpdateTasks?.affected_rows &&
        taskResponse?.data?.addOrUpdateTasks?.returning?.length
      ) {
        const responseList: {id: string; assigneeId: string}[] =
          taskResponse.data.addOrUpdateTasks.returning;
        finalMemberData.forEach((item) => {
          const taskData = responseList.find(
            (task: any) => task.assigneeId === item.contactUuid
          );
          if (taskData?.id) {
            item.subjectId = taskData.id;
          }
        });
      }
    }

    // If subjectId is not created (case where member is prospect not patient, in that case we don't create tasks)
    finalMemberData.forEach((item) => {
      item.subjectId = item.subjectId || uuidv4();
    });
    return finalMemberData;
  };

  const getFormLogDataForMembers = (
    memberData: RecipientMemberData[],
    form: any
  ) => {
    const formData: any[] = [];
    memberData.forEach((item) => {
      formData.push({
        formId: form.id,
        subjectId: form.taskId || item.subjectId,
        sourceId: formSourceId,
        contactId: item.contactUuid,
        statusId: formStatusId,
      });
    });
    return formData;
  };

  const validateForm = () => {
    if (
      selectedCategory?.code === TEMPLATES_CONST.SHARE_PACKAGES &&
      products.length === 0
    ) {
      setFormValidationStatus((prev) => {
        return {
          ...prev,
          code: COMPULSORY_FORM_FIELDS.PRODUCTS,
          error: 'Please select a product',
        };
      });
      return false;
    }

    if (props.isGroupSearchEnabled && props.form) {
      if (
        (formEmailState?.groups || []).length === 0 &&
        (formEmailState?.members || []).length === 0
      ) {
        setFormValidationStatus((prev) => {
          return {
            code: COMPULSORY_FORM_FIELDS.MEMBER_OR_GROUPS,
            error: intl.formatMessage({id: 'selectPatientOrGroupAlert'}),
          };
        });
        return false;
      }
      return true;
    }
    if (!emailData?.to && !bulkEmail) {
      setFormValidationStatus({
        code: COMPULSORY_FORM_FIELDS.TO,
        error: intl.formatMessage({id: 'selectPatientAlert'}),
      });
      return false;
    }
    if (!selectedCategory) {
      setFormValidationStatus({
        code: COMPULSORY_FORM_FIELDS.TEMPLATE_CATEGORY,
        error: 'Please select a Template Category',
      });
      return false;
    }
    if (!selectedTemplate) {
      setFormValidationStatus((prev) => {
        return {
          ...prev,
          code: COMPULSORY_FORM_FIELDS.TEMPLATE,
          error: 'Please select a Template'
        }
      });
      return false;
    }
    return true;
  };

  const getFormattedEmailData = async (member: any) => {
    const selectedForms: IForm[] = emailData?.selectedForms || [];
    const formList: {name: string; link: string}[] = [];
    const taskData: any[] = [];
    selectedForms?.forEach((form) => {
      taskData.push(...getTasksDataForSendForm(form, member?.contactUuid));
    });
    const taskResponse = await createTasks({
      variables: {
        data: taskData,
      },
    });
    const data = selectedForms?.map((form) => {
      const task = taskResponse?.data?.addOrUpdateTasks?.returning.find(
        (task: any) => {
          return (
            task?.formId === form?.id &&
            task?.assigneeId === member?.contactUuid
          );
        }
      );
      const subjectId = task?.id || uuidv4();
      const name = form?.name;
      const link = getFormURL(
        form?.id,
        member?.contactUuid,
        accountUuid,
        subjectId,
        formSourceId
      );
      formList.push({name, link});
      return {
        formId: form?.id,
        subjectId: subjectId,
        sourceId: formSourceId,
        contactId: member?.contactUuid,
        statusId: formStatusId,
      };
    });
    await insertFormLogs({
      variables: {
        data,
      },
    });
    return {
      ...formattedEmailData,
      to: [member?.email],
      bodyHtml: (props?.templateData?.templateHtml as string) || null,
      data: {
        ...formattedEmailData.data,
        formList: formList,
      },
    };
  };

  const onSendEmail = async () => {
    if (validateForm()) {
      if (selectedCategory?.code === TEMPLATES_CONST.SHARE_PACKAGES) {
        setLoadingState(true);
        const memberEmails = (formEmailState.members || []).map((item) =>
          item?.email || isValidEmail(item) ? item : ''
        );
        const response = await handleShareProducts({
          productIds: products.map((item) => item.id),
          accountUuid: accountUuid,
          emails: [...(memberEmails || []), ...(formattedEmailData.to || [])],
          fromEmail: emailData.from,
          subject: emailData.subject,
          templateId: selectedTemplate?.id || '',
        });
        setLoadingState(true);
        if (response.data) {
          successHandler({});
        }
        if (response.error) {
          sendEmailErrorHandler(response.error);
        }
        return;
      }

      if (props.form && Object.keys(props.form || {}).length > 0) {
        const form = props.form;
        setLoadingState(true);
        const updatedMemberData = await getFormDataForGroupMembers(props.form);
        await insertFormLogs({
          variables: {
            data: getFormLogDataForMembers(updatedMemberData, form),
          },
        });
        sendFormToGroupAndMembers({
          accountUuid: accountUuid,
          formId: props.form.id,
          memberData: updatedMemberData,
          fromEmail: emailData.from || supportEmail,
          emailData: emailData,
          formSourceId,
        })
          .then(async (response) => {
            setLoadingState(false);
            successHandler(response);
          })
          .catch((error) => {
            setLoadingState(false);
            errorHandler(error);
          });

        return;
      }

      if (
        selectedCategory?.code == 'CAREPLAN_SUGGESTION' &&
        (emailData.careJourneys || []).length == 0
      ) {
        showToast(toast, 'Please select a care journey', ToastType.error);
        return;
      }

      if (
        selectedCategory?.code === 'MULTIPLE_PATIENT_FORMS' &&
        emailData?.selectedForms?.length === 0
      ) {
        showToast(toast, 'Please select a form', ToastType.info);
      } else {
        if(selectedCategory?.code !== 'MULTIPLE_PATIENT_FORMS'){
          sendGeneralEmail()
          return
        }
        setLoadingState(true);
        if (
          !(props?.contactData?.patient?.patientUuid && emailData.selectedForm)
        ) {
          const {memberData} = getFormattedFormEmailData({groups: formEmailState.groups, members: [...formEmailState.membersData, props?.contactData, ...(props?.bulkContactData || [])]});
          const formattedEmailDataPromiseList: Promise<any>[] = [];
          (memberData || []).forEach((item) => {
            if (item?.contactUuid) {
              if (
                emailData?.selectedForms &&
                emailData?.selectedForms?.length > 0
              ) {
                formattedEmailDataPromiseList.push(getFormattedEmailData(item));
              }
            }
          });
          const formattedEmailDataArray: any[] = await Promise.all(formattedEmailDataPromiseList)

          sendMultipleFormEmail(formattedEmailDataArray, {
            successHandler(response) {
              successHandler(response);
              setLoadingState(false);
            },

            errorHandler(error) {
              setLoadingState(false);
              sendEmailErrorHandler(error);
            },
          });

          if (emailData.selectedForm) {
            insertFormLogs({
              variables: {
                data: [getFormLogSaveData()],
              },
            });
            handleSendFormToMemberAction({
              accountUuid: accountUuid,
              userUuid: userUUID,
              level,
            })
              .then((response) => {
              })
              .catch((error) => {
              });
          }
          props.onMailCompleteAction(COMMON_ACTION_CODES.EMAIL_SENT);
          return;
        }
        if (!(props?.contactData?.patient?.patientUuid && emailData.selectedForm && selectedCategory?.code === 'MULTIPLE_PATIENT_FORMS')) {
          setLoadingState(true);
          const selectedForm = emailData.selectedForm;
          const updatedMemberData = await getFormDataForGroupMembers(selectedForm);
          sendFormToGroupAndMembers({
            accountUuid: accountUuid,
            formId: selectedForm?.id,
            memberData: updatedMemberData,
            fromEmail: emailData.from || supportEmail,
            emailData: emailData,
            formSourceId,
          })
            .then((response) => {
              insertFormLogs({
                variables: {
                  data: getFormLogDataForMembers(updatedMemberData, selectedForm),
                },
              });
              setLoadingState(false);
              successHandler(response);
            })
            .catch((error) => {
              setLoadingState(false);
              errorHandler(error);
            });
          return;
        }

        const form = emailData.selectedForm;
        const addTaskData = getTasksDataForSendForm(
          form,
          props.contactData?.uuid
        );

        setLoadingState(true);
        createTasks({
          variables: {data: addTaskData},
          onCompleted: (response) => {
            if (
              response?.addOrUpdateTasks?.affected_rows &&
              response?.addOrUpdateTasks?.returning?.length
            ) {
              response.addOrUpdateTasks.returning.forEach(
                (taskData: {id: string; formId: string}) => {
                  if (form.id === taskData.formId) form.taskId = taskData.id;
                }
              );
            }

            const newLink = getFormURL(
              form.id,
              contactUuid,
              accountUuid,
              form.taskId,
              formSourceId
            );

            const newFormattedEmailData = {
              ...formattedEmailData,
              from: supportEmail,
              data: {
                ...formattedEmailData?.data,
                formList: [
                  {
                    link: newLink,
                    name: form.name,
                  },
                ],
              },
              bodyHtml: (props?.templateData?.templateHtml as string) || null,
            };
            sendEmail(newFormattedEmailData, {
              successHandler(response) {
                successHandler(response);
                setLoadingState(false);
              },
              errorHandler(error) {
                sendEmailErrorHandler(error);
                setLoadingState(false);
              },
            });
            insertFormLogs({
              variables: {
                data: [getFormLogSaveData(form.taskId)],
              },
            });
            props.onMailCompleteAction(COMMON_ACTION_CODES.EMAIL_SENT);
          },
          onError: (error: ApolloError) => {

          },
        });
      }
    }
  };

  const onClose = () => {
    setVisible(false);
    props.onMailCompleteAction(COMMON_ACTION_CODES.RESET);
  };

  const addTemplateToast = (templatesData: IEmailTemplateData[]) => {
    if (templatesData.length === 0) {
      showToast(
        toast,
        'Please Add a template to this category',
        ToastType.info
      );
    }
  };

  const successHandler = (response: any, isActionData?: boolean, actionData?: any) => {
    const message = getSuccessMessage(response);
    showToast(toast, message, ToastType.success);
    setLoadingState(false);
    if (!isActionData){
      onClose()
    }
  };

  const getSuccessMessage = (response: any) => {
    const message = response?.data?.message || 'Email Sent';
    return message;
  };

  const sendEmailErrorHandler = (error: any) => {
    showToast(
      toast,
      'Something went wrong. Please Try again later',
      ToastType.error
    );

    setLoadingState(false);
    onClose();
  };

  const sendGeneralEmail = () => {
    setLoadingState(true);
    const memberEmails = (formEmailState.members || []).map((item) =>
      item?.email || isValidEmail(item) ? item : ''
    );
    const newFormattedEmailData = {
      ...formattedEmailData,
      to: [...(formattedEmailData.to || []), ...(memberEmails || [])],
      bodyHtml: (props?.templateData?.templateHtml as string) || null,
    };
    sendEmail(newFormattedEmailData, {
      successHandler(response) {
        successHandler(response);
        setLoadingState(false);
      },

      errorHandler(error) {
        setLoadingState(false);
        sendEmailErrorHandler(error);
      },
    });
  };

  // const getTemplatesByCategoryCode = (
  //   selectedCategory: ICategory,
  //   templateId?: any
  // ) => {
  //   // const categoryCode = selectedCategory?.code;
  //   // const path = `${ContentTypes.emails.path}?category=${categoryCode}`;
  //   // getTemplates(path)
  //   //   .then((response) => getFormattedEmailTemplateData(response))
  //   //   .then((formattedTemplates) => {
  //   //     addTemplateToast(formattedTemplates);
  //   //     setTemplatesData(formattedTemplates);
  //   //     // if (formattedTemplates.length > 0) {
  //   //     //   const templateToSelect = templateId
  //   //     //     ? formattedTemplates.find(
  //   //     //       (template: any) => template.id == templateId
  //   //     //     )
  //   //     //     : formattedTemplates[0];
  //   //     //   setSelectedTemplate(templateToSelect);
  //   //     //   setEmailData((prev: any) => {
  //   //     //     return {
  //   //     //       ...prev,
  //   //     //       template: templateToSelect?.id,
  //   //     //       templateCategoryCode: templateToSelect?.templateCategoryCode,
  //   //     //     };
  //   //     //   });
  //   //     // }
  //   //   });

  // };

  const responseHandler = (response: any) => {
    const formattedCarePlans = getFormattedCarePlans(response);
    setCarePlanDetails(formattedCarePlans);
  };

  const errorHandler = (error: any) => {

  };

  const getMergeTags = (category: string, categories: ITemplateCategory[]) => {
    const mergeTagsByCategory = categories.find(
      (item) => item.name === category
    )?.mergeTags;
    return {...mergeTagsByCategory, global: accountMergeTags};
  };

  const getHTMLForPackage = async () => {
    const templateData = selectedTemplate;
    const productIds = products.map((item) => item.id);
    const promiseList = productIds.map((item) => {
      return createCopyLinkPackageApi(item);
    });
    const response = await Promise.all(promiseList);
    const engine = new Liquid();
    const mergeTags: Record<string, any> = {};
    mergeTags.packages = response;
    const finalMergeTag = {
      ...getMergeTags(templateData?.templateCategory || '', categories),
      ...mergeTags,
    };
    const tpl = engine.parse(templateData?.templateHtml || '');
    return engine.renderSync(tpl, finalMergeTag);
  };

  const getPreviewHtml = (
    templateData: IEmailTemplateData,
    richText: string,
    carePlan?: ICarePlan,
    shouldReturnData?: boolean,
    subjectId?: string,
    sourceId?: string,
  ): string => {
    const engine = new Liquid();
    const mergeTags: Record<string, any> = {
      general: {
        text: richText,
      },
    };
    mergeTags['patient'] = {
      firstName: props?.contactData?.person?.firstName,
    };
    if (carePlan) {
      mergeTags['careplan'] = {
        name1: carePlan.title,
        description1: carePlan.description,
        price1: carePlan.price,
      };
    }
    if (formLinkData && !emailData?.selectedForms?.length) {
      const formLink = getFormURL(
        formLinkData.id,
        contactUuid || CreateEmailConversationDrawerState?.selectedPatient?.uuid,
        accountUuid,
        subjectId,
        sourceId
      );
      mergeTags['formList'] = [
        {
          name: formLinkData.name,
          link: formLink,
        },
      ];
    }
    if (emailData?.selectedForms && emailData?.selectedForms?.length > 0) {
      mergeTags['formList'] = emailData?.formList || [];
    }
    const finalMergeTag = {
      ...getMergeTags(templateData?.templateCategory, categories),
      ...mergeTags,
    };
    const tpl = engine.parse(templateData?.templateHtml || '');
    const html = engine.renderSync(tpl, finalMergeTag);
    return engine.renderSync(tpl, finalMergeTag);
  };
  useEffect(() => {
    // if (props.category) {
    //   // getTemplatesByCategoryCode(props.category);
    //   setSelectedCategory(props.category);
    // }
    if (props.form) {
      setFormLinkData(props.form);
    }
    if (props?.content) {
      setRichTextValue(props?.content);
      setEmailData((prev: any) => {
        return {
          ...prev,
          emailContent: props?.content,
        };
      });
    }
    getInboxesData();
    // setEmailData({...emailData, to: [props?.contactData?.email]});
    setCreateEmailConversationDrawerState((prev) => {
      return {
        ...prev,
        selectedPatient: props?.contactData,
        selectedInbox: props?.selectedInbox,
        supportEmail: supportEmail,
      };
    });
  }, [loading]);

  const isCategorySelectDisabled =
    props.category && Object.keys(props.category || {}).length !== 0;
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);
  const isSideCarContext = commonData.sidecarContext?.isSidecar;
  const finalWidth = isSideCarContext ? '100%' : isIPadScreen || isIPadMiniScreen ? '60vw' : '700px';

  const selectedEmails = (formEmailState.members || []).map((item) => {
    if (isString(item) && isValidEmail(item)) {
      return item;
    }
    if (item.email && isString(item.email) && isValidEmail(item.email)) {
      return item.email;
    }
    return '';
  });

  const [getInboxesWithChannelTypeFilter] = useLazyQuery(
    InboxQueries.GetInboxesWithChannelTypeFilter,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      notifyOnNetworkStatusChange: true,
      variables: {
        whereCondition: {
          name: {
            _ilike: '%%',
          },
          accountId: {
            _eq: accountId,
          },
          isDeleted: {
            _eq: false,
          },
          channelType: {
            _eq: CHANNEL_TYPE.CHANNEL_EMAIL,
          },
          inboxMembers: {
            userId: {
              _eq: userId,
            },
            isDeleted: {
              _eq: false,
            },
          },
        },
      },
    }
  );

  const getInboxesData = async () => {
    const inboxResp = await getInboxesWithChannelTypeFilter({
      variables: {
        whereCondition: {
          name: {
            _ilike: '%%',
          },
          accountId: {
            _eq: accountId,
          },
          isDeleted: {
            _eq: false,
          },
          channelType: {
            _eq: CHANNEL_TYPE.CHANNEL_EMAIL,
          },
          inboxMembers: {
            userId: {
              _eq: userId,
            },
            isDeleted: {
              _eq: false,
            },
          },
        },
      },
    }).catch((error) => {
      setCreateEmailConversationDrawerState((prev) => {
        return {
          ...prev,
          inboxList: [],
        };
      });
    });
    const defaultEmail = getDefaultEmail();

    if (inboxResp?.data?.inboxes?.length && !bulkEmail?.length) {
      if (inboxResp?.data?.inboxes?.length === 0) {
        setCreateEmailConversationDrawerState((prev) => {
          return {
            ...prev,
            inboxList: [...inboxResp?.data?.inboxes, defaultEmail],
            selectedInbox: defaultEmail,
          };
        });
      } else {
        setCreateEmailConversationDrawerState((prev) => {
          return {
            ...prev,
            inboxList: [...inboxResp?.data?.inboxes, defaultEmail],
            selectedInbox: inboxResp?.data?.inboxes[0],
          };
        });
      }
    } else {
      setCreateEmailConversationDrawerState((prev: any) => {
        return {
          ...prev,
          inboxList: [
            ...CreateEmailConversationDrawerState?.inboxList,
            defaultEmail,
          ],
          selectedInbox: defaultEmail,
        };
      });
    }
  };

  const onContinueWithConversation = async () => {
    setLoadingState(true);
    // await sendMessageAPI({
    //   privateNote: 'false',
    //   textContent: CreateEmailConversationDrawerState?.messageStr || '',
    //   accountId: accountId + '',
    //   conversationId:
    //     CreateEmailConversationDrawerState?.existingConversation
    //       ?.conversations?.[0]?.displayId + '',
    // });
    let templeteDataHtml: string;
    if (selectedCategory?.code === TEMPLATES_CONST.SHARE_PACKAGES) {
      templeteDataHtml = await getHTMLForPackage();
    } else {
      templeteDataHtml = getPreviewHtml(
        (selectedTemplate as IEmailTemplateData) || props.templateData,
        richTextValue,
        selectedCarePlan,
        true,
        CreateEmailConversationDrawerState?.taskId,
        formSourceId
      );
    }

    let userMentionIdStr = '';
    if (groupMemberCodeIdObj && Object.keys(groupMemberCodeIdObj)?.length && templeteDataHtml) {
      const userMentionIds = getUserIdListFromMsgContent(templeteDataHtml, groupMemberCodeIdObj);
      if (userMentionIds?.length) {
        userMentionIdStr = userMentionIds.toString();
      }
    }
    const templateDataJson = templeteDataHtml;
    setLoadingState(true);
    sendEmailMessageAPI({
      private: false,
      content: getContent(templeteDataHtml, templateDataJson),
      conversationDisplayId: CreateEmailConversationDrawerState?.existingConversation?.conversations?.[0]?.displayId,
      inboxId: CreateEmailConversationDrawerState?.existingConversation?.conversations?.[0]?.inboxId,
      subject: emailData?.subject,
      user_mention_ids: userMentionIdStr
    }).then(async (response) => {
      showToast(
        smsConversationInboxToast,
        'Email Sent',
        ToastType.success
      );
      if (props.form && Object.keys(props.form || {}).length > 0) {
        insertFormLog({
          variables: {
            data: getFormLogSaveData(CreateEmailConversationDrawerState?.taskId)
          },
        });
      }
      successHandler(response, true);
      setCreateEmailConversationDrawerState((prev) => {
        return {
          ...prev,
          isConfirmModalOpen: false,
          isLoading: false,
        };
      });
      setVisible(false);
      const conversationDisplayId =
        CreateEmailConversationDrawerState?.existingConversation
          .conversations?.[0]?.displayId;
      const conversationResponse = await getConversationByDisplayId({
        variables: {
          conversationId: conversationDisplayId,
          loginUserIntId: userId,
          loginUserUuid: userUUID,
        },
      });
      props?.onMailCompleteAction(SMS_INBOX_ACTION_CODES.DRAWER_CLOSE, {
        inboxData: {
          id: CreateEmailConversationDrawerState?.selectedInbox?.id,
        },
        conversation: conversationResponse?.data?.conversations?.[0] || {},
      });
    })
      .catch((error) => {
        setCreateEmailConversationDrawerState((prev) => {
          return {
            ...prev,
            isConfirmModalOpen: false,
            isLoading: false,
          };
        });
        showToast(
          smsConversationInboxToast,
          'Something went wrong',
          ToastType.error
        );
      });

  };

  const onModalActionPerformed = (actionCode: string) => {
    switch (actionCode) {
      case SMS_INBOX_ACTION_CODES.MODAL_CLOSE:
        setCreateEmailConversationDrawerState((prev) => {
          return {
            ...prev,
            isConfirmModalOpen: false,
            existingConversation: {} as any,
          };
        });
        break;
      case SMS_INBOX_ACTION_CODES.CONTINUE_WITH_THE_CONVERSATION:
        onContinueWithConversation();
        break;
    }
  };

  const onInboxChange = (inboxId: number) => {
    const curSelectedInbox =
      CreateEmailConversationDrawerState?.inboxList.find((inboxData) => {
        return inboxData.id === inboxId;
      }) || ({} as any);

    setCreateEmailConversationDrawerState((prev) => {
      return {
        ...prev,
        selectedInbox: curSelectedInbox,
      };
    });
  };

  const [getInboxesForContact] = useLazyQuery<IContactInboxesResp>(
    InboxQueries.GetInboxesForContact,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [getInboxesForContactWithSourceId] = useLazyQuery<IContactInboxesResp>(
    InboxQueries.GetInboxesForContactWithSourceId,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const getExistingConversationWithSelectedInbox = async () => {
    const sourceId = CreateEmailConversationDrawerState?.selectedPatient?.email
    const inboxId = CreateEmailConversationDrawerState?.selectedInbox.id;
    const contactExistInboxes = await getInboxesForContactWithSourceId({
      variables: {
        sourceId,
        inboxId,
      },
    });
    if (contactExistInboxes.data?.contactInboxes?.length) {
      return contactExistInboxes.data?.contactInboxes[0];
    }
    return {} as any;
  };

  const getContent = (templeteDataHtml: any, templateDataJson: string) => {
    if (templeteDataHtml.length) {
      return templateDataJson;
    } else {
      return emailData?.emailContent;
    }
  };

  const CreateEmailConversationWithSelectedInbox = async (taskId?: string) => {
    setLoadingState(true)
    let templeteDataHtml: string;
    if (selectedCategory?.code === TEMPLATES_CONST.SHARE_PACKAGES) {
      templeteDataHtml = await getHTMLForPackage();
    } else {
      templeteDataHtml = getPreviewHtml(
        (selectedTemplate as IEmailTemplateData) || props.templateData,
        richTextValue,
        selectedCarePlan,
        true,
        CreateEmailConversationDrawerState?.taskId,
        formSourceId
      );
    }

    const templateDataJson = templeteDataHtml;

    const conversationBodyData :any  = {
      additional_attributes: {
        mail_subject: emailData?.subject,
      },
      inbox_id: CreateEmailConversationDrawerState?.selectedInbox?.id,
      contact_id: CreateEmailConversationDrawerState?.selectedPatient?.id,
      assignee_id: userData.id,
      // message: {
      //   content: getContent(templeteDataHtml, templateDataJson),
      //   content_type: 12,
      // },
      source_id:
        CreateEmailConversationDrawerState?.selectedPatient?.email || '',
    };

    const conversationData = await createSmsInboxConversation(
      {
        bodyParams: conversationBodyData,
      },
      accountId
    ).catch((error: any) => {

      const errMsg =
        error?.response?.data?.error || intl.formatMessage({id: 'apiErrorMsg'});
      showToast(smsConversationInboxToast, errMsg, ToastType.error);

      setLoadingState(false);
    });
    let userMentionIdStr = '';
    if (groupMemberCodeIdObj && Object.keys(groupMemberCodeIdObj)?.length && templeteDataHtml) {
      const userMentionIds = getUserIdListFromMsgContent(templeteDataHtml, groupMemberCodeIdObj);
      if (userMentionIds?.length) {
        userMentionIdStr = userMentionIds.toString();
      }
    }
    if (conversationData?.data && conversationData?.data?.id) {
      sendEmailMessageAPI({
        private: false,
        content: getContent(templeteDataHtml, templateDataJson),
        conversationDisplayId: conversationData?.data?.id,
        inboxId: CreateEmailConversationDrawerState?.selectedInbox?.id,
        subject: emailData?.subject,
        user_mention_ids: userMentionIdStr
      }).then(async (response) => {
        showToast(
          smsConversationInboxToast,
          'Email Sent',
          ToastType.success
        );
        setLoadingState(false);
        setVisible(false);
        if(formLinkData && formLinkData.id){
          insertFormLog({
            variables: {
              data: getFormLogSaveData(taskId),
            },
          });
        }
        successHandler(response,true);
        const conversationDisplayId = conversationData?.data?.id;
        const conversationResponse = await getConversationByDisplayId({
          variables: {
            conversationId: conversationDisplayId,
            loginUserIntId: userId,
            loginUserUuid: userUUID,
          },
        });
        props?.onMailCompleteAction(SMS_INBOX_ACTION_CODES.DRAWER_CLOSE, {
          inboxData: {
            id: CreateEmailConversationDrawerState?.selectedInbox?.id,
          },
          conversation: conversationResponse?.data?.conversations?.[0] || {},
        });
      })
        .catch((error) => {
          setLoadingState(false);
          showToast(
            smsConversationInboxToast,
            'Something went wrong',
            ToastType.error
          );
        });
    }
  };

  const CreateEmailConversation = async () => {
    let taskId = '' as string;
    if (props.form && Object.keys(props.form || {}).length > 0) {
      const taskData = getTasksDataForSendForm(props?.form, CreateEmailConversationDrawerState?.selectedPatient?.uuid);
      if (taskData.length) {
        const addTaskData = [taskData[0]]
        if (addTaskData.length) {
          const taskResponse = await createTasks({
            variables: {
              data: addTaskData,
            },
          });
          if (
            taskResponse?.data?.addOrUpdateTasks?.affected_rows &&
            taskResponse?.data?.addOrUpdateTasks?.returning?.length
          ) {
            taskId = taskResponse?.data?.addOrUpdateTasks?.returning[0]?.id
            setCreateEmailConversationDrawerState((prev)=>{
              return {
                ...prev,
                taskId: taskId
              }
            })
          }
        }
      }
    }
    let templeteDataHtml: string;
    if (selectedCategory?.code === TEMPLATES_CONST.SHARE_PACKAGES) {
      templeteDataHtml = await getHTMLForPackage();
    } else {
      templeteDataHtml = getPreviewHtml(
        (selectedTemplate as IEmailTemplateData) || props.templateData,
        richTextValue,
        selectedCarePlan,
        true,
        taskId,
        formSourceId
      );
    }
    const templateDataJson = JSON.stringify(templeteDataHtml);
    const CreateEmailConversationData = {
      selectedPatient: CreateEmailConversationDrawerState?.selectedPatient,
      messageStr: getContent(templeteDataHtml, templateDataJson),
      subject: emailData?.subject,
    };

    const errorData = getUpdatedCreateEmailConversationErrorMessages(
      CreateEmailConversationData
    );

    if (
      errorData?.isErrorPresent ||
      !CreateEmailConversationDrawerState?.isConsentGiven
    ) {
      setCreateEmailConversationDrawerState((prev) => {
        return {
          ...prev,
          errors: {
            ...prev.errors,
            ...errorData.errors,
          },
        };
      });
    } else {
      setLoadingState(true)
      const existingConversation: IContactInbox =
        await getExistingConversationWithSelectedInbox();
      if (existingConversation?.conversations?.[0]?.id) {
        setCreateEmailConversationDrawerState((prev) => {
          return {
            ...prev,
            isConfirmModalOpen: true,
            isLoading: false,
            existingConversation: existingConversation as any,
          };
        });
      } else {
        setCreateEmailConversationDrawerState((prev) => {
          return {
            ...prev,
            isLoading: false,
            existingConversation: {} as any,
          };
        });
        await CreateEmailConversationWithSelectedInbox(taskId);
      }
    }
  };

  const [getEmployeeConsent] = useLazyQuery(
    EmployeeQueries.getEmployeeConsent,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const checkForConsent = async (contactData: any) => {
    if (!isConsentRequired) {
      setCreateEmailConversationDrawerState((prev) => ({
        ...prev,
        isConsentGiven: true,
        selectedPatient: contactData,
        isLoading: false,
      }));
      return;
    }

    const checkPatientConsent = await getEmployeeConsent({
      variables: {
        contactUuid: contactData?.uuid,
      },
    });
    if (!checkPatientConsent?.data?.contactConsents.length) {
      setCreateEmailConversationDrawerState((prev) => {
        return {
          ...prev,
          isConsentGiven: false,
          selectedPatient: contactData,
        };
      });
    } else {
      setCreateEmailConversationDrawerState((prev) => {
        return {
          ...prev,
          isConsentGiven: true,
          selectedPatient: contactData,
        };
      });
    }
  };

  const handleSend = () => {
    if (CreateEmailConversationDrawerState?.selectedInbox?.id !== -1) {
      CreateEmailConversation();
    } else {
      onSendEmail();
    }
  };


  const isGroupEnabledOrFormCategorySelected = () => {
    return (
      props?.isGroupSearchEnabled
    );
  };

  const isPropsContainForm = () => {
    return props?.form && Object.keys(props.form || {}).length > 0;
  };

  const canShowMultiSelectEmail = () => {
    return !CreateEmailConversationDrawerState?.selectedInbox ||
      CreateEmailConversationDrawerState?.selectedInbox?.id === -1 ||
      bulkEmail?.length;
  }
  const checkIfValidTemplate = (selectedTemplate: any) =>{
    return ALLOWED_TEMPLATES.some(template => template === selectedTemplate.code)
  }

  const setLoadingState = (value: boolean) => {
    setCreateEmailConversationDrawerState((prev)=>{
      return{
        ...prev,
        isLoading: value,
      }
    })
  }

  return (
    <>
      <Drawer
        visible={visible}
        style={{minWidth: isSideCarContext ? undefined : 740}}
        width={finalWidth}
        onClose={onClose}
        closable={false}
        destroyOnClose
        title={
          <ModalActionTitle
            title={props?.isEditMode ? 'previewEmail' : 'email'}
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'cancel',
                size: 'sm',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  onClose();
                },
              },
              {
                isDisabled: !CreateEmailConversationDrawerState?.isConsentGiven
                  ? true
                  : CreateEmailConversationDrawerState?.inboxList
                  ? false
                  : isSendButtonDisabled(),
                show: true,
                id: 2,
                btnText: 'send',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                // isLoading: isLoading,
                onClick: () => {
                  handleSend();
                },
              },
            ]}
          />
        }
      >
        {CreateEmailConversationDrawerState?.isLoading ? (
          <View
            height={'full'}
            width={'full'}
            position="absolute"
            zIndex={10}
            bg={'coolGray.50:alpha.70'}
          >
            <Center
              top={'30%'}
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              <Spinner size={'lg'} />
            </Center>
          </View>
        ) : (
          <VStack mx={0}>
            <View mb={4}>
              {props?.isEditMode ||
              CreateEmailConversationDrawerState?.showTemplateMessage
                ? showPhiWarningToast(
                    intl.formatMessage({
                      id: 'dummyTemplateMessage',
                    })
                  )
                : showPhiWarningToast(
                    intl.formatMessage({
                      id: 'phiWarning',
                    })
                  )}
            </View>
            <View px={1} py={2} backgroundColor={'gray.100'}>
              <View mb={2}>
                <HStack borderColor={'transparent'} flex={1} w={'100%'}>
                  <Text alignSelf={'center'} px={2} color={'gray.500'}>
                    From:
                  </Text>
                  <VStack
                    style={[
                      {
                        width: '100%',
                        flex: 1,
                      },
                    ]}
                    ref={componentRef}
                  >
                    <Select
                      disabled={
                        props?.selectPatientDisable
                          ? true
                          : selectedEmails?.length > 1 ||
                            emailData?.to?.length > 1
                          ? true
                          : false
                      }
                      size="large"
                      placeholder="Select Inbox"
                      onChange={(inboxId) => {
                        onInboxChange(inboxId);
                      }}
                      value={
                        CreateEmailConversationDrawerState?.selectedInbox?.id
                      }
                      className={'email-chat-inbox-select'}
                    >
                      {CreateEmailConversationDrawerState?.inboxList.map(
                        (singleInbox) => {
                          return (
                            <Option
                              key={singleInbox?.id}
                              value={singleInbox?.id}
                            >
                              <HStack>
                                <Text>{singleInbox?.name}</Text>
                                {singleInbox?.channelEmail?.email && (
                                  <Tooltip
                                    title={singleInbox?.channelEmail?.email}
                                  >
                                    <Text
                                      style={[
                                        styles.textStyle,
                                        {maxWidth: width - 180},
                                      ]}
                                    >
                                      ({singleInbox?.channelEmail?.email})
                                    </Text>
                                  </Tooltip>
                                )}
                              </HStack>
                            </Option>
                          );
                        }
                      )}
                    </Select>
                  </VStack>
                </HStack>
              </View>

              <View>
                <HStack borderColor={'transparent'} flex={1} w={'100%'}>
                  <Text
                    px={2}
                    mr={4}
                    color={'gray.500'}
                    justifySelf={'center'}
                    mt={2.5}
                  >
                    To:
                  </Text>
                  {/* <EmailTagView email={contactEmail} emails={bulkEmail} /> */}

                  <VStack flex={1} space={2}>
                    {canShowMultiSelectEmail() ? (
                      <VStack
                        style={{
                          width: '100%',
                          flex: 1,
                          paddingBottom: 8,
                        }}
                        space={props?.isGroupSearchEnabled ? '3' : undefined}
                      >
                        <AllPatientEmailList
                          isGetCompleteObject={isGroupEnabledOrFormCategorySelected()}
                          defaultValue={
                            isGroupEnabledOrFormCategorySelected()
                              ? selectedEmails
                              : emailData?.to || bulkEmail
                          }
                          onSelectValue={(value: any,extraData: any) => {
                            if (isGroupEnabledOrFormCategorySelected()) {
                              setFormEmailState((prev) => {
                                return {
                                  ...prev,
                                  members: value,
                                  membersData: extraData
                                };
                              });
                            } else {
                              setFormEmailState((prev) => {
                                return {
                                  ...prev,
                                  members: value,
                                  membersData: extraData
                                };
                              });
                              setEmailData((prev: any) => {
                                return {...prev, to: value};
                              });
                            }
                            if (
                              COMPULSORY_FORM_FIELDS.TO ===
                              formValidationStatus?.code
                            ) {
                              setFormValidationStatus(undefined);
                            }
                          }}
                          selectedValues={formEmailState.members}
                        />
                        {isConsentRequired &&
                          getWithoutConsentPatients(props?.bulkContactData)?.length ? (
                            <HStack pl={1}>
                              <Text fontSize={12} color={Colors.Custom.Gray500}>
                                {`Please give consent for `}
                                <Text
                                  fontSize={12}
                                  color={Colors.Custom.Gray500}
                                  fontWeight={700}
                                >
                                  {getWithoutConsentPatients(
                                    props?.bulkContactData
                                  )}
                                </Text>
                                {' ' + PATIENT_CONSENT_ERROR}
                              </Text>
                            </HStack>
                          ) : <></>}
                        {props?.isGroupSearchEnabled && (
                          <GroupSearch
                            groupType={POPULATION_GROUP_TYPES.STATIC}
                            onSelectGroups={(value) => {
                              setFormEmailState((prev) => {
                                return {
                                  ...prev,
                                  groups: value,
                                };
                              });
                            }}
                            selectedGroups={formEmailState.groups}
                          />
                        )}
                      </VStack>
                    ) : (
                      <VStack
                        style={{
                          width: '100%',
                          flex: 1,
                          paddingBottom: 8,
                        }}
                        space={isCategorySelectDisabled ? '3' : undefined}
                      >
                        <FormControl
                          isInvalid={
                            CreateEmailConversationDrawerState?.errors?.patient
                          }
                        >
                          <PatientSearchAndSelect
                            value={
                              CreateEmailConversationDrawerState.selectedPatient
                                ?.email
                            }
                            isDisabled={props?.contactData?.id ? true : false}
                            contactData={props?.contactData}
                            showEmail={true}
                            allowClear={false}
                            isProspect={false}
                            placeholder="Search members"
                            searchPatientWithEmail={true}
                            onChange={(participant: any) => {
                              const contactData =
                                participant?.label?.props?.contactData;
                              setCreateEmailConversationDrawerState((prev) => {
                                return {
                                  ...prev,
                                  selectedPatient: contactData,
                                };
                              });
                              checkForConsent(contactData);

                              if (isCategorySelectDisabled) {
                                setFormEmailState((prev) => {
                                  return {
                                    ...prev,
                                    members: [contactData],
                                  };
                                });
                              } else {
                                setEmailData({
                                  ...emailData,
                                  to: [contactData.email],
                                });
                              }
                            }}
                          />
                          <FormControl.ErrorMessage
                            _text={{
                              fontSize: 'xs',
                              color: 'error.500',
                              fontWeight: 500,
                            }}
                          >
                            {CreateEmailConversationDrawerState.errors.patient}
                          </FormControl.ErrorMessage>
                        </FormControl>

                        {!CreateEmailConversationDrawerState?.isConsentGiven && (
                          <HStack pl={1}>
                            <Text
                              fontSize={12}
                              color={Colors.Custom.MissedCallIconColor}
                            >
                              {`Please give consent for "${CreateEmailConversationDrawerState?.selectedPatient?.name}" ${PATIENT_CONSENT_ERROR}`}
                            </Text>
                          </HStack>
                        )}

                        {isCategorySelectDisabled && (
                          <GroupSearch
                            groupType={POPULATION_GROUP_TYPES.STATIC}
                            onSelectGroups={(value) => {
                              setFormEmailState((prev) => {
                                return {
                                  ...prev,
                                  groups: value,
                                };
                              });
                            }}
                            selectedGroups={formEmailState.groups}
                          />
                        )}
                      </VStack>
                    )}
                    {formValidationStatus?.code ===
                    COMPULSORY_FORM_FIELDS.TO ? (
                      <Text style={styles.missingFieldErrorMsgStyle}>
                        {formValidationStatus.error}
                      </Text>
                    ) : null}
                    {formValidationStatus?.code ===
                    COMPULSORY_FORM_FIELDS.MEMBER_OR_GROUPS ? (
                      <Text
                        style={{
                          fontSize: 14,
                          color: Colors.Custom.ErrorColor,
                          fontWeight: '500',
                        }}
                      >
                        {formValidationStatus.error}
                      </Text>
                    ) : null}
                  </VStack>
                </HStack>
                {/* </>
            }
          /> */}
              </View>
            </View>

            <VStack style={{marginTop: 10, marginBottom: 10}}>
              {/* <Divider /> */}
            </VStack>

            <VStack>
              {!props?.isEditMode && (
                <>
                  <HStack style={styles.selectionContainer}>
                    <Text style={styles.selectionTextStyle}>
                      Template Categories{' '}
                    </Text>
                    <View style={styles.selectStyle}>
                      <ModalActionAntSelect
                        className={'select-template-category'}
                        showSearch={true}
                        errors={formValidationStatus?.code === COMPULSORY_FORM_FIELDS.TEMPLATE_CATEGORY}
                        isDisabled={isCategorySelectDisabled}
                        disabled={isPropsContainForm()}
                        selectedValue={selectedCategory?.code}
                        defaultValue={selectedCategory?.code}
                        value={selectedCategory?.name}
                        filterOption={(input: string, option: any) => {
                          return (option?.children || '')
                            ?.toLowerCase?.()
                            ?.includes(input?.toLowerCase());
                        }}
                        onChange={(value: any) => {
                          const category = categories.find(
                            (item) => item.id === value
                          );
                          if (checkIfValidTemplate(category)) {
                            setCreateEmailConversationDrawerState((prev) => {
                              return {
                                ...prev,
                                showTemplateMessage: false,
                              };
                            });
                          } else {
                            setCreateEmailConversationDrawerState((prev) => {
                              return {
                                ...prev,
                                showTemplateMessage: true,
                              };
                            });
                          }
                          setSelectedCategory(category);
                          setSelectedTemplate(undefined);
                          setTemplatesData(undefined);
                          // getTemplatesByCategoryCode(category as ICategory);
                          if (
                            COMPULSORY_FORM_FIELDS.TEMPLATE_CATEGORY ===
                            formValidationStatus?.code
                          ) {
                            setFormValidationStatus(undefined);
                          }
                        }}
                        placeholder={'Select template category'}
                        data={categories}
                        optionProps={{
                          key: 'id',
                          value: 'id',
                          label: 'name',
                        }}
                        customStyle={{
                          flex: 1,
                          height: '53px',
                          width: 'auto',
                        }}
                      />
                      {formValidationStatus?.code ===
                      COMPULSORY_FORM_FIELDS.TEMPLATE_CATEGORY ? (
                        <Text style={styles.missingFieldErrorMsgStyle}>
                          {formValidationStatus.error}
                        </Text>
                      ) : null}
                    </View>
                  </HStack>

                  {/* {templatesData && templatesData.length > 0 && (
                  <>

                    <VStack style={styles.dividerStyle}>
                      <Divider />
                    </VStack>

                    <HStack style={styles.selectionContainer}>
                      <Text style={styles.selectionTextStyle}>
                        Templates List{' '}
                      </Text>
                      <View style={styles.selectStyle}>
                        <ModalActionSelect
                          selectedValue={
                            selectedTemplate?.id || props?.templateData?.id
                          }
                          defaultValue={
                            selectedTemplate?.id || props?.templateData?.id
                          }
                          placeholder="Choose a Template"
                          onValueChange={(value: string) => {
                            const template = templatesData?.find(
                              (item) => item?.id?.toString() === value
                            );
                            setSelectedTemplate(template);
                            setEmailData({
                              ...emailData,
                              template: template?.id,
                            });
                          }}
                          data={templatesData}
                          selectItemProps={{
                            key: 'id',
                            label: 'templateName',
                            value: 'id',
                          }}
                          customStyle={{flex: 1}}
                        />
                      </View>
                    </HStack>
                  </>
                )} */}
                  <VStack style={styles.dividerStyle}>
                    <Divider />
                  </VStack>
                  <HStack style={styles.selectionContainer}>
                    <Text style={styles.selectionTextStyle}>
                      Templates List{' '}
                    </Text>
                    <View style={styles.selectStyle}>
                      {categories.length > 0 ? (
                        <AllTemplateSearch
                          multipleCategoryCodes={(categories || []).map(
                            (item) => item.code
                          )}
                          contentType="email"
                          categoryCode={
                            isPropsContainForm()
                              ? 'PAT_FORM'
                              : (selectedCategory && selectedCategory.code) ||
                                ''
                          }
                          status={COMPULSORY_FORM_FIELDS.TEMPLATE === formValidationStatus?.code? 'error': undefined}
                          onSelect={(selected) => {
                            const subject = selected?.templateData?.subject || ''
                            setSelectedTemplate(selected);
                            setFormValidationStatus((prev) => {
                              if (prev?.code===COMPULSORY_FORM_FIELDS.TEMPLATE) {
                                return;
                              } else {
                                return prev;
                              }
                            })
                            const selectedCategory = categories.find((item) => {
                              return (
                                item.code === selected?.templateCategoryCode
                              );
                            });
                            setSelectedCategory((prev) => {
                              return {
                                ...prev,
                                code: selectedCategory?.code || '',
                                name: selectedCategory?.name || '',
                              };
                            });
                            setEmailData((prev: any) => ({
                              ...prev,
                              template: selected?.id,
                              subject: subject,
                            }));
                            if (selectedCategory?.code === 'All') {
                              setCreateEmailConversationDrawerState((prev) => {
                                return {
                                  ...prev,
                                  showTemplateMessage: true,
                                };
                              });
                            }
                          }}
                        />
                      ) : (
                        <></>
                      )}
                      {COMPULSORY_FORM_FIELDS.TEMPLATE === formValidationStatus?.code &&
                        <Text style={styles.missingFieldErrorMsgStyle}>
                          {formValidationStatus.error}
                        </Text>
                      }
                    </View>
                  </HStack>
                </>
              )}

              {selectedTemplate &&
                selectedTemplate?.templateCategoryCode ===
                  'SINGLE_CAREPLAN_SUGGESTION' && (
                  <>
                    <VStack style={styles.dividerStyle}>
                      <Divider />
                    </VStack>

                    <HStack style={styles.selectionContainer}>
                      <Text style={styles.selectionTextStyle}>
                        Care Journeys:{' '}
                      </Text>
                      <View style={styles.selectStyle}>
                        <ModalActionSelect
                          placeholder="Choose a Care Journey"
                          onValueChange={(id: string) => {
                            const carePlanSelected = carePlanDetails?.find(
                              (item) => item.id === id
                            );
                            setSelectedCarePlan(carePlanSelected);
                            setEmailData({
                              ...emailData,
                              careplan: carePlanSelected?.title,
                              careplanPrice: carePlanSelected?.price,
                              careplanDes: carePlanSelected?.description,
                            });
                          }}
                          data={carePlanDetails}
                          selectItemProps={{
                            key: 'id',
                            label: 'title',
                            value: 'id',
                          }}
                          customStyle={{flex: 1}}
                        />
                      </View>
                    </HStack>
                  </>
                )}

              {selectedTemplate &&
                selectedTemplate?.templateCategoryCode ===
                  'CAREPLAN_SUGGESTION' &&
                templatesData &&
                templatesData?.length > 0 && (
                  <>
                    <VStack style={styles.dividerStyle}>
                      <Divider />
                    </VStack>

                    <HStack style={styles.selectionContainer}>
                      <Text style={styles.selectionTextStyle}>
                        Care Journeys:{' '}
                      </Text>
                      <View style={styles.selectStyle}>
                        <CarePlanSearch
                          onSelect={(result) => {
                            setEmailData((prev: any) => {
                              return {
                                ...prev,
                                careJourneys: result,
                              };
                            });
                          }}
                        />
                      </View>
                    </HStack>
                  </>
                )}
              {selectedTemplate &&
                selectedTemplate?.templateCategoryCode ===
                  'MULTIPLE_PATIENT_FORMS' &&
                !props?.form && (
                  <>
                    <VStack style={styles.dividerStyle}>
                      <Divider />
                    </VStack>

                    <HStack style={styles.selectionContainer}>
                      <Text style={styles.selectionTextStyle}>Forms List</Text>
                      <View style={styles.selectStyle}>
                        <MultipleFormSearch
                          value={emailData?.selectedForms || []}
                          isShowError={false}
                          infoMessage={contactInfoMessage}
                          onChange={(value: {id: string; name: string}[]) => {
                            const formList = (value || []).map((item) => {
                              const uniqueId = uuidv4();
                              const name = item?.name;
                              const link = getFormURL(
                                item.id,
                                contactUuid,
                                accountUuid,
                                uniqueId,
                                formSourceId
                              );
                              return {
                                name,
                                link,
                              };
                            });
                            setEmailData((prev: any) => {
                              return {
                                ...prev,
                                formList,
                                selectedForms: value,
                              };
                            });
                          }}
                        />
                      </View>
                    </HStack>
                  </>
                )}
            </VStack>

            {selectedTemplate &&
            selectedTemplate.templateCategoryCode ===
              TEMPLATES_CONST.SHARE_PACKAGES ? (
              <>
                <VStack style={styles.dividerStyle}>
                  <Divider />
                </VStack>
                <HStack style={styles.selectionContainer}>
                  <Text style={styles.selectionTextStyle}>Packages</Text>
                  <View style={styles.selectStyle}>
                    <SearchPackages
                      onSelectPackages={(products) => {
                        setProducts(products);
                      }}
                    />
                    {formValidationStatus?.code ===
                    COMPULSORY_FORM_FIELDS.PRODUCTS ? (
                      <Text style={styles.missingFieldErrorMsgStyle}>
                        {formValidationStatus.error}
                      </Text>
                    ) : null}
                  </View>
                </HStack>
              </>
            ) : (
              <></>
            )}

            <VStack style={styles.dividerStyle}>
              <Divider />
            </VStack>

            <VStack>
              <FormControl
                isInvalid={
                  CreateEmailConversationDrawerState?.errors?.subject || false
                }
              >
                <Input
                  onChangeText={(value: any) => {
                    setEmailData((prev: any) => {
                      return {
                        ...prev,
                        subject: value,
                      };
                    });
                  }}
                  placeholder="Enter Subject"
                  variant={'unstyled'}
                  borderColor={'transparent'}
                  style={styles.inputStyle}
                  w="100%"
                  px={2}
                  value={
                    emailData?.subject ||
                    props?.templateData?.templateData?.subject
                  }
                  _focus={{
                    borderColor: Colors.Custom.Gray200,
                  }}
                  InputLeftElement={<Text color={'gray.500'}>Subject:</Text>}
                />
                {CreateEmailConversationDrawerState?.errors?.subject && (
                  <FormControl.ErrorMessage
                    _text={{
                      fontSize: 'xs',
                      color: 'error.500',
                      fontWeight: 500,
                    }}
                  >
                    {CreateEmailConversationDrawerState.errors.subject}
                  </FormControl.ErrorMessage>
                )}
              </FormControl>
            </VStack>

            <VStack style={{marginTop: 10, marginBottom: 10}}>
              <Divider />
            </VStack>

            {selectedCategory?.code == 'GENERAL' && (
              <>
                <VStack>
                  <FormControl
                    isInvalid={
                      CreateEmailConversationDrawerState?.errors?.message
                    }
                  >
                    <RichText
                      valueStr={richTextValue}
                      isEnterToSubmit={false}
                      onRichTextAction={() => {
                      }}
                      onChangesValue={(value: string) => {
                        setRichTextValue(value);
                        setEmailData({...emailData, emailContent: value});
                      }}
                    />
                    <FormControl.ErrorMessage
                      _text={{
                        fontSize: 'xs',
                        color: 'error.500',
                        fontWeight: 500,
                      }}
                    >
                      {CreateEmailConversationDrawerState.errors.message}
                    </FormControl.ErrorMessage>
                  </FormControl>
                </VStack>
                <VStack style={styles.dividerStyle}>
                  <Divider />
                </VStack>
              </>
            )}

            <VStack>
              {selectedTemplate ? (
                <View>
                  {parse(
                    getPreviewHtml(
                      selectedTemplate as IEmailTemplateData,
                      richTextValue,
                      selectedCarePlan
                    )
                  )}
                </View>
              ) : (
                props?.templateData && (
                  <View>
                    {parse(
                      getPreviewHtml(
                        props?.templateData as IEmailTemplateData,
                        richTextValue,
                        selectedCarePlan
                      )
                    )}
                  </View>
                )
              )}
            </VStack>
            {selectedTemplate && (
              <VStack style={{marginTop: 10, marginBottom: 10}}>
                <Divider />
              </VStack>
            )}

          </VStack>
        )}
      </Drawer>
      <ContinueWithExistingConversationPopup
        isOpen={CreateEmailConversationDrawerState.isConfirmModalOpen}
        onActionPerformed={onModalActionPerformed}
      />
    </>
  );
};

export default EmailDrawerCommon;
