
import { Skeleton,Spacer,View } from 'native-base';
import { useContext, useEffect,useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Colors } from '../../../../../../styles';
import { TestIdentifiers,testID } from '../../../../../../testUtils';
import { GraphMemberList } from '../../../../../common/AppDashboard/GraphMemberList';
import { BarGraph } from '../../../../../common/Graph/BarGraph';
import { getEmailAllStatusByTagsGroupByMessageId } from '../../AddOrUpdateWorkflow/WorkflowApi';
import { IEmailGraphByTag } from '../Interfaces';
import { isAccountConfigEnabled } from '../../../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../../../constants/AccountConfigConst';
import { UserLocationContext } from '../../../../../../context/UserLocationContext';
import { isLoginUserBusinessOwner } from '../../../../../../utils/commonUtils';



export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
  },
};
export function EmailGraphByTag(props: IEmailGraphByTag) {
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const isBusinessOwner = isLoginUserBusinessOwner();
  const [searchParams] = useSearchParams();
  const userLocationContextData = useContext(UserLocationContext);

  enum LOADING_STATUS {
    loadingValue,
    loadedValue,
    needToLoad,
  }
  const [loadingStatus, setLoadingStatus] = useState(LOADING_STATUS.needToLoad);
  const [data, setData] = useState<any>();
  const capitlizeFirst = (str: string) => {
    // checks for null, undefined and empty string
    if (!str) return;
    return str.match('^[a-z]')
      ? str.charAt(0).toUpperCase() + str.substring(1)
      : str;
  };

  const getDateStr = (dateStr: string) => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const date = new Date(dateStr);
    date.getMonth();
    return date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear();
  };
  const [fetchCount, setFetchCount]= useState(0);
  const constructChartData = (results: any) => {

    const datasets: Array<any> = [];

    results.sort(function (a: any, b: any) {
      const firstDate:any = new Date(b.createdOn);
      const secondDate:any =  new Date(a.createdOn)
      const diff:any =   secondDate  - firstDate;
      return diff;
    });

    const statusCountMap:any = {}
    results.forEach((data:any)=>{
      if(!data?.eventCode){
        return;
      }
      if(!statusCountMap[data.eventCode]){
        statusCountMap[data.eventCode] = 0;
      }
      statusCountMap[data.eventCode] = statusCountMap[data.eventCode] + 1;
    })

    results.forEach((log: any) => {
      const statusCount = statusCountMap[log.eventCode] || '';
      const status = capitlizeFirst(log.eventCode)
      datasets.push({
        status: statusCount ? status + ' (' + statusCount + ')' :status,
        date: getDateStr(log.createdOn),
        eventCode: log.eventCode,
        createdOn: log.createdOn,
        count: 1
      });
    });


    const newData = {
      data: datasets,
      style: {
        height: 200
      },
      label: {
        type: 'inner',
        content: '',
      },
      xField: 'date',
      yField: 'count',
      seriesField: 'status',
    }
    setData(()=>{
      return newData
    });
    setFetchCount(fetchCount+1);
    props.setIsHideEmailGraph(!datasets.length);
    setLoadingStatus(LOADING_STATUS.loadedValue);
  };

  const fetchData = (tag: string) => {
    const fromDate = (searchParams.get('fromDate') ? searchParams.get('fromDate') : undefined);
    const toDate = (searchParams.get('toDate') ? searchParams.get('toDate') : undefined)
    const eventCode = (searchParams.get('emailStatus') ? searchParams.get('emailStatus') : undefined)
    const auditFilterLocationIds = (searchParams.get('auditFilterLocationIds') ? searchParams.get('auditFilterLocationIds') : undefined)
    // const filterLocationIds = auditFilterLocationIds? auditFilterLocationIds.split(',') : userLocationContextData?.userPracticeLocations
    const filterLocationIds = auditFilterLocationIds ? auditFilterLocationIds.split(',') : (userLocationContextData?.userPracticeLocations && userLocationContextData?.userPracticeLocations.length>0 ) ? userLocationContextData.userPracticeLocations : props?.intersectedLocationIdList;
    if((!fromDate || !toDate) && props?.isDateMandatory){
      return
    }
    let dateFilter: any = undefined;
    if (fromDate) {
      dateFilter = {};
      dateFilter.fromDate = fromDate
    }
    if (toDate) {
      dateFilter = dateFilter || {}
      dateFilter.toDate = toDate
    }
    getEmailAllStatusByTagsGroupByMessageId(tag, props?.tagData?.tagType, dateFilter, eventCode || '',false, isMsoEnabled, isBusinessOwner, filterLocationIds).then((results: any) => {
      constructChartData(results);
      setLoadingStatus(LOADING_STATUS.loadedValue);
    })
      .catch((err: any) => {

        setLoadingStatus(LOADING_STATUS.loadedValue);
      });
  };
  if (loadingStatus === LOADING_STATUS.needToLoad) {
    setLoadingStatus(LOADING_STATUS.loadingValue);
    if (props?.tagData?.tag) {
      fetchData(props?.tagData?.tag);
    }
  }

  function toCamelCase(input: string): string {
    const words = input.split(' ');
    const camelWords = words.map((word, index) => {
        if (index === 0) {
            return word.toLowerCase();
        } else {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }
    });
    return camelWords.join('');
}

function getStartOfDay(date: Date): string {
  const startOfDay = new Date(date);
  startOfDay.setHours(0, 0, 0, 0);
  return startOfDay.toISOString();
}

function getEndOfDay(date: Date): string {
  const endOfDay = new Date(date);
  endOfDay.setHours(23, 59, 59, 999);
  return endOfDay.toISOString();;
}
function isIntegerString(input: string): boolean {
  return /^[+-]?\d+$/.test(input);
}
const [contactPopupData, setContactPopupData]   = useState<any>({
  contactIdList:[],
  isShowContactList:false,
  pageSize:10,
  currentPage:1,
  eventCode:''
});



const fetchClickedData = (clickedData:any) => {
    if(!clickedData ){
      return;
    }
    const filterLocationIds = (userLocationContextData?.userPracticeLocations && userLocationContextData?.userPracticeLocations.length > 0) ? userLocationContextData.userPracticeLocations : props?.intersectedLocationIdList;
    const fromDate = getStartOfDay(clickedData?.createdOn)
    const toDate = getEndOfDay(clickedData?.createdOn)
    const eventCode = toCamelCase(clickedData.eventCode)
    if(!fromDate || !toDate){
      return
    }
    let dateFilter: any = undefined;
    if (fromDate) {
      dateFilter = {};
      dateFilter.fromDate = fromDate
    }
    if (toDate) {
      dateFilter = dateFilter || {}
      dateFilter.toDate = toDate
    }
    getEmailAllStatusByTagsGroupByMessageId(props?.tagData?.tag, props?.tagData?.tagType, dateFilter, eventCode || '', true, isMsoEnabled, isBusinessOwner, filterLocationIds).then((results: any) => {
      const contactIdListInResult:any = [];
      (results||[]).forEach((result:any)=>{
        (result.tags || [])?.forEach((tag:any)=>{(tag?.serviceLog?.resourceLevelLog||[]).forEach((resourceLevelLog:any)=>{
            const resourceId = resourceLevelLog?.resourceId;
            if(isIntegerString(resourceId)){
              const contactId = parseInt(resourceId);
              contactIdListInResult.push(contactId)
            }

        })})

    })
      setContactPopupData({...contactPopupData,eventCode,currentPage:1, contactIdList:contactIdListInResult, isShowContactList:true})
      setLoadingStatus(LOADING_STATUS.loadedValue);
    })
      .catch((err: any) => {

        setLoadingStatus(LOADING_STATUS.loadedValue);
      });
  };
  if (loadingStatus === LOADING_STATUS.needToLoad) {
    setLoadingStatus(LOADING_STATUS.loadingValue);
    if (props?.tagData?.tag) {
      fetchData(props?.tagData?.tag);
    }
  }

  function paginateArray(inputArray: number[], pageSize: number, pageNumber: number): number[] {
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    return inputArray.slice(startIndex, endIndex);
}

function camelToPascalWithSpaces(input: string): string {
  const words = input.replace(/([a-z])([A-Z])/g, '$1 $2').split(/[\s_-]+/);
  const pascalCaseWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  return pascalCaseWords.join(' ');
}

  useEffect(() => {
    fetchData(props?.tagData?.tag)
  }, [searchParams.get('fromDate'), searchParams.get('toDate'), searchParams.get('emailStatus'), props?.tagData?.tag, searchParams.get('auditFilterLocationIds')])

  return data ? (
    <View flex={1} width='full'>
      {/* <Text size='smMedium' color={Colors.Custom.Gray500} >Email Record Graph</Text> */}

      <Spacer flex={1}></Spacer>
      <View flex={10} justifyContent={'center'} alignItems={'center'} padding={5} >
        <BarGraph key={fetchCount}  {...data} onClick={(clickData:any)=>{
          fetchClickedData(clickData)
        }} color={(data:any)=>{
          const  colorMap:any = {
            sent: Colors.info[300],
            delivered: Colors.warning[300],
            undelivered: Colors.danger[300],
            opened:Colors.success[300],
            'Unique Opened': Colors.success[400],
            unsubscribed:Colors.danger[400],
            failed: Colors.danger[500],
            clicked:Colors.success[500],
            'Unique Clicked':Colors.success[600],
            queued:Colors.muted[300],
          }
          if(!colorMap[data?.eventCode]){
          }
          return colorMap[data?.eventCode] || Colors.warning[300]
        }} />
      </View>
      { contactPopupData?.isShowContactList ? <GraphMemberList
          title={camelToPascalWithSpaces(contactPopupData?.eventCode || '')}
          page={contactPopupData?.currentPage || 1}
          pageSize={contactPopupData.pageSize}
          isFromNewEmployer={false}
          isFromDashboard={false}
          onClose={()=>{
            setContactPopupData({...contactPopupData, currentPage:1, contactIdList:[], isShowContactList:false})
          }}
          onPaginationChange={(page: number, pageSize: number) => {
            setContactPopupData({...contactPopupData, currentPage: page, pageSize:pageSize})
          }}
          total={contactPopupData?.contactIdList?.length}
          isOpen={contactPopupData?.isShowContactList}
          contactIdList={paginateArray(contactPopupData?.contactIdList, contactPopupData?.pageSize, contactPopupData?.currentPage)}
          onCompleteAction={() => {
            setContactPopupData({...contactPopupData,currentPage:1, contactIdList:[], isShowContactList:false})
          }}
        /> : <></> }
    </View >
  ) : (
    <View {...testID(TestIdentifiers.pageLoading)} width={'full'} height='full'>
      <Skeleton size={'full'}></Skeleton>
    </View>

  );
}
