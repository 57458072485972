import { View, Text, ScrollView } from 'react-native'
import React, { useEffect, useState } from 'react'
import { ICarePlanDetail } from '../interfaces';
import { useLazyQuery } from '@apollo/client';
import { GET_CARE_PLAN_AUDIT } from '../../../../../services/CarePlan/CarePlanQueries';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../constants/Configs';
import useGetBatchedAccountUsers from '../../../../CustomHooks/useGetBatchedAccountUsers';
import { Pressable, Spinner } from 'native-base';
import { getDateStrFromFormat } from '../../../../../utils/DateUtils';
import { DATE_FORMATS, DISPLAY_SLASH_DATE_FORMAT, MONTH_YEAR } from '../../../../../constants';
import Stack from '../../../../common/LayoutComponents/Stack';
import { Colors } from '../../../../../styles';
import CarePlanDiff from '../CarePlanDiff/CarePlanDiff';
import ArrowDownSvg from '../../../../common/Svg/ArrowDownSvg';
import ArrowRightSvg from '../../../../common/Svg/ArrowRightSvg';
import { cloneDeep } from 'lodash';

interface ICarePlanAuditProps {
  carePlanDetails: ICarePlanDetail;
  components: any[];
  locationId: string | undefined;
}

interface ICarePlanAuditItem {
  date: string;
  assignedBy: string;
  oldComponents: any[];
  newComponents: any[];
  auditVisible: boolean;
}

interface ICarePlanAuditGroup {
  groupTitle: string;
  auditList: ICarePlanAuditItem[];
}

interface IActivityAudit {
  id: string;
  data: any[];
  actionCode: string;
  performedById: string;
  source: string;
  performedByTypeCode: string;
  timestamp: string;
}

const CarePlanAudit = (props: ICarePlanAuditProps) => {
  // Constants
  const { carePlanDetails, components, locationId } = props;

  // States
  const [componentState, setComponentState] = useState<{
    loading: boolean;
    components: any[];
    audit: ICarePlanAuditGroup[],
  }>({
    loading: false,
    components: components,
    audit: []
  });

  // APIs
  const {
    loading: accountUserLoading,
    userList: accountUserList,
  } = useGetBatchedAccountUsers();

  const [getCarePlanAudit] = useLazyQuery(GET_CARE_PLAN_AUDIT, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });

  // Lifecycle methods
  useEffect(() => {
    if (!accountUserLoading) {
      fetchCarePlanAudit();
    }
  }, [accountUserLoading])

  // Other methods
  const fetchCarePlanAudit = async () => {
    try {
      setComponentState((prev) => ({...prev, loading: true}));
      const response = await getCarePlanAudit({
        variables: {
          carePlanId: carePlanDetails.id,
        }
      });
      const auditData: IActivityAudit[] = response?.data?.activityLogs || [];
      const groupedData = getComponentGroupedData(auditData);
      setComponentState((prev) => ({...prev, audit: groupedData, loading: false}));
    } catch (error) {

      setComponentState((prev) => ({...prev, loading: false}));
    }
  }

  const getGroupKey = (item: IActivityAudit) => {
    const date = item.timestamp;
    return getDateStrFromFormat(date, MONTH_YEAR)
  }

  const getAuditDate = (item: IActivityAudit) => {
    const date = item.timestamp;
    return getDateStrFromFormat(date, DATE_FORMATS.REPORT_DATE_FORMAT)
  }

  const getAuditUser = (item: IActivityAudit) => {
    const userId = item.performedById;
    return accountUserList.find((item) => item.uuid === userId)?.name || 'User';
  }

  const getComponentGroupedData = (auditData: IActivityAudit[]): ICarePlanAuditGroup[] => {
    const auditGroups: ICarePlanAuditGroup[] = [];
    auditData.forEach((item, index) => {
      const data: ICarePlanAuditItem = {
        date: getAuditDate(item),
        assignedBy: getAuditUser(item),
        oldComponents: cloneDeep(auditData?.[index + 1]?.data || []),
        newComponents: cloneDeep(item.data),
        auditVisible: false,
      }
      const groupKey = getGroupKey(item);
      const auditMonthFound = auditGroups.find((item) => item.groupTitle === groupKey);
      if (auditMonthFound) {
        auditMonthFound.auditList.push(data);
      } else {
        auditGroups.push({
          groupTitle: groupKey,
          auditList: [data]
        })
      }
    });
    return auditGroups;
  }

  const renderAuditCard = (item: ICarePlanAuditItem, monthIndex: number, monthItemIndex: number) => {
    const isCurrent = monthIndex === 0 && monthItemIndex === 0;
    return (
      <Stack direction="column" space={8}>
        <Pressable
          onPress={() => {
            setComponentState((prev) => {
              const isAuditVisible = prev.audit?.[monthIndex]?.auditList?.[monthItemIndex]?.auditVisible;
              prev.audit[monthIndex].auditList[monthItemIndex].auditVisible = !isAuditVisible;
              return {
                ...prev,
                audit: [...prev.audit],
              };
            })
          }}>
          <Stack
            direction='row'
            style={{
              flex: 1,
              paddingHorizontal: 16,
              paddingVertical: 12,
              borderRadius: 12,
              backgroundColor: item.auditVisible ? Colors.Custom.purpleBackgroundContainer : Colors.FoldPixel.GRAY50,
              borderWidth: item.auditVisible ? 0.5 : 0,
              borderColor: Colors.FoldPixel.PRIMARY300,
              alignItems: 'center'
            }}>
            <Stack
              direction='column'
              space={4}
              style={{ flexGrow: 1 }}>
              <Stack direction="row" space={8} style={{ alignItems: 'center' }}>
                <Text style={{ fontSize: 14, color: Colors.FoldPixel.GRAY400 }}>{item.date}</Text>
                {isCurrent && (
                  <Text style={{backgroundColor: Colors.FoldPixel.PRIMARY100, paddingHorizontal: 8, paddingVertical: 4, borderRadius: 4, color: Colors.FoldPixel.PRIMARY300}}>
                    {'Current Care Plan'}
                  </Text>
                )}
              </Stack>
              <Text style={{ fontSize: 12, color: Colors.FoldPixel.GRAY300 }}>{item.assignedBy}</Text>
            </Stack>
            <View style={{width: 16, height: 16}}>
              {item.auditVisible ? (
                <ArrowDownSvg customStrokeColor={Colors.FoldPixel.GRAY400} />
              ) : (
                <ArrowRightSvg customStrokeColor={Colors.FoldPixel.GRAY400} />
              )}
            </View>
          </Stack>
        </Pressable>
        {item.auditVisible && <View
          style={[{
            marginBottom: 24,
          }
          ]}>
          <CarePlanDiff
            oldData={item.oldComponents || []}
            newData={item.newComponents || []}
            components={cloneDeep(componentState.components || [])}
            carePlanDetails={carePlanDetails}
            locationId={locationId}
           />
        </View>}
      </Stack>
    );
  }

  const loading = componentState.loading || accountUserLoading;

  return (
    <View>
      {loading && <Spinner size={"sm"} />}
      {!loading && (
        <ScrollView>
          <Stack direction='column'>
            {componentState.audit.length > 0 && (
              <Stack direction='column' space={24}>
                {componentState.audit.map((item, monthIndex) => {
                  return (
                    <Stack key={`CarePlanMonthAudit_${monthIndex}`} direction='column' space={8}>
                      <Text style={{ fontSize: 12, color: Colors.FoldPixel.GRAY300, textTransform: 'uppercase' }}>
                        {item.groupTitle}
                      </Text>
                      <Stack direction="column" space={8}>
                        {item.auditList.map((auditItem, monthItemIndex) => {
                          return (
                            <View key={`CarePlanAuditCard_${monthIndex}_${monthItemIndex}`}>
                              {renderAuditCard(auditItem, monthIndex, monthItemIndex)}
                            </View>
                          );
                        })}
                      </Stack>
                    </Stack>
                  );
                })}
              </Stack>
            )}
          </Stack>
        </ScrollView>
      )}
    </View>
  )
}

export default CarePlanAudit
