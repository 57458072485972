export const CONTACT_RULE_CATEGORY = {
  VITAL: 'VITAL',
  ACTIVITY: 'ACTIVITY',
  LAB_ANALYTE: 'LAB_ANALYTE',
  FORM: 'FORM',
  CONTACT: 'CONTACT',
  OTHER: 'OTHER',
  SOCIAL: 'SOCIAL',
};

export const CONTACT_RULE_ACTION = {
  SEARCH: 'SEARCH',
  ADD: 'ADD',
  ADD_WITH_TEMPLATE: 'ADD_WITH_TEMPLATE',
  UPDATE: 'UPDATE',
}
