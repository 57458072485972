import {useEffect, useState} from 'react';
import {useLazyQuery} from '@apollo/client';
import {getUserContactPracticeLocations} from '../../../../services/Lead/LeadQueries';

interface ILocationData {
  isLoading: boolean;
  locationId?: string;
  error?: any;
}

export const useContactLocationUuid = (
  contactUuid?: number,
  skipCall?: boolean
) => {
  const [locationData, setLocationData] = useState<ILocationData>({
    isLoading: false,
    locationId: undefined,
    error: undefined,
  });
  const [getLocationId] = useLazyQuery(getUserContactPracticeLocations, {
    fetchPolicy: 'no-cache',
  });

  const fetchContactLocationUuid = async (
    contactUuid: number | string
  ) => {
    try {
      const variables = {
        contactId: contactUuid,
      };
      const apiResponse = await getLocationId({variables: variables});
      const locationId =
        apiResponse?.data?.contacts?.[0]?.contactPracticeLocations?.[0]
          ?.accountLocation?.uuid;
      setLocationData((prev) => {
        return {
          ...prev,
          locationId: locationId,
          isLoading: false,
        };
      });
      return locationId;
    } catch (error) {
      setLocationData((prev) => {
        return {
          ...prev,
          isLoading: false,
          error: error,
        };
      });
      return undefined;
    }
  };
  useEffect(() => {
    if (!skipCall && contactUuid) {
      fetchContactLocationUuid(contactUuid);
    }
  }, [contactUuid]);

  return {
    fetchContactLocationUuid,
    locationData,
  };
};
