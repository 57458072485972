import {isArray, isEmpty} from 'lodash';

export const checkIfArrayAndIsNotEmpty = (arr: Array<any>) => {
  return isArray(arr) && !isEmpty(arr);
};

export const areArraysEqual = (arr1: string[], arr2: string[]): boolean => {

  
  // Check if arrays have the same length
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Sort both arrays
  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  // Compare sorted arrays element by element
  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  return true;
}


export const findArrayIntersection = (array1: string[], array2: string[]): string[] => {
  // Choose the smaller array to create the Set
  const [smallerArr, largerArr] = array1.length < array2.length ? [array1, array2] : [array2, array1];
  
  const set = new Set(smallerArr);
  const intersection: string[] = [];
  
  for (const item of largerArr) {
    if (set.has(item)) {
      intersection.push(item);
      set.delete(item); // Ensure we don't add duplicates
    }
  }
  
  return intersection;
}