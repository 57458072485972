import React from 'react';
import {
  IReccuringEventConfig,
  IRenderDateParams,
  ReccurenceType,
} from '../hooks/useReccuringEvent/interface';
import {
  Box,
  FormControl,
  HStack,
  Icon,
  Radio,
  Text,
  VStack,
  View,
} from 'native-base';
import {useIntl} from 'react-intl';
import {ModalActionAntSelect} from '../../../../../ModalActionCommonComponent/ModalActionAntSelect';
import {
  MONTH_DROPDOWN_LIST,
  RECCURENCE_TYPE_LIST,
} from '../hooks/useReccuringEvent/constants';
import {DisplayText} from '../../../../../DisplayText/DisplayText';
import {Colors} from '../../../../../../../styles';
import {InputNumber, TimePicker,   Switch,
} from 'antd';
import {
  getMinMaxDates,
  getRepeatsEverySuffixText,
} from '../hooks/useReccuringEvent/helpers';
import {
  getCurrentTimeZone,
  getMomentObj,
  isBeforeDate,
  isBeforeOrSameDate,
  isFutureDay,
  isPastDay,
} from '../../../../../../../utils/DateUtils';
import {
  DATE_FORMATS,
  DISPLAY_DATE_FORMAT,
  monthsArray,
} from '../../../../../../../constants';
import {ModalActionDatePicker} from '../../../../../ModalActionCommonComponent/ModalActionDatePicker';
import {Moment} from 'moment';
import {WeekDaySelector} from '../../../../../WeekSelector';
import {IReccuringEventView, ReccuringEventType} from './Interfaces';
import {getReccurringEventViewTitleIds} from './ReccuringEventViewHelper';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { debounce } from 'lodash';
import { AppointmentAction } from '../AppointmentBookingEnums';
import { testID } from '../../../../../../../testUtils';

const ReccuringEventView = (props: IReccuringEventView) => {
  const {config, isEnabled, onToggle, allowedToEdit, allowedToToggle} = props;
  const isEditBlockOccurence = props.editType === AppointmentAction.editBlockOccurence;
  const isEditBlockSeries = props.editType === AppointmentAction.editBlockSeries;
  const isEditOccurence = props.editType === AppointmentAction.editOccurence;
  const isEditSeries = props.editType === AppointmentAction.editSeries;
  const intl = useIntl();
  const onChange = () => {
    if (isEnabled) {
      config.onResetData();
    }
    onToggle(!isEnabled);
  };

  const renderReccurenceType = () => {
    return (
      <ModalActionAntSelect
        extraStyle={{
          flex: 1,
        }}
        disabled={!allowedToEdit}
        label={intl.formatMessage({id: 'recurenceType'})}
        data={RECCURENCE_TYPE_LIST}
        value={config.data.frequency}
        optionProps={{
          key: 'value',
          label: 'label',
          value: 'value',
        }}
        onChange={(selectedValue: any) => {
          config.onChangeData('frequency', selectedValue);
        }}
      />
    );
  };

  const renderRepeatsEvery = () => {
    const isInvalid =
      config.data.repeatEvery === null || config.data.repeatEvery < 1 || config.data.repeatEvery > config.maxRepeatsEveryLimit;
    return (
      <FormControl isInvalid={isInvalid} isDisabled={!allowedToEdit}>
        <FormControl.Label>
          <DisplayText
            textLocalId={'repeatsEvery'}
            extraStyles={{
              color: Colors.Custom.Gray500,
              fontWeight: 500,
              fontSize: 14,
            }}
          />
          <Text color="error.500">*</Text>
        </FormControl.Label>
        <HStack flex={1}>
          <InputNumber
            disabled={!allowedToEdit}
            type={'number'}
            style={{
              padding: 2,
              fontSize: 12,
              borderRadius: 8,
              flex: 1,
              height: '38px',
              marginTop: 5
            }}
            status={isInvalid ? 'error' : undefined}
            value={config.data.repeatEvery}
            precision={0}
            step={1}
            min={1}
            max={100}
            onChange={debounce((text: any) => {
              config.onChangeData('repeatEvery', text);
            },200)}
          />
          <Box style={{ height: '38px'}} mt={1}>
            <Text padding={2}>
              {getRepeatsEverySuffixText(config.data.frequency)}
            </Text>
          </Box>
        </HStack>
        {renderMaxRepeatsEveryMessage()}
      </FormControl>
    );
  };

  const renderRepeatsOnMonthDay = () => {
    const dateLimits = getMinMaxDates(config.data.repeatsOnMonth);
    const isInvalid =
      config.data.repeatsOnMonthDay === null ||
      config.data.repeatsOnMonthDay < 1 ||
      !(
        config.data.repeatsOnMonthDay >= dateLimits.minDate &&
        config.data.repeatsOnMonthDay <= dateLimits.maxDate
      );
    return (
      <FormControl isInvalid={isInvalid} isDisabled={!allowedToEdit}>
        <FormControl.Label>
          <DisplayText
            textLocalId={'Repeat on Day'}
            extraStyles={{
              color: Colors.Custom.Gray500,
              fontWeight: 500,
              fontSize: 14,
            }}
          />
          <Text color="error.500">*</Text>
        </FormControl.Label>
        <HStack flex={1}>
          <InputNumber
            disabled={!allowedToEdit}
            status={isInvalid ? 'error' : undefined}
            type={'number'}
            style={{
              padding: 2,
              fontSize: 12,
              borderRadius: 8,
              flex: 1,
              height: '40px',
            }}
            min={dateLimits.minDate}
            max={dateLimits.maxDate}
            value={config.data.repeatsOnMonthDay}
            precision={0}
            step={1}
            onChange={(text: any) => {
              config.onChangeData('repeatsOnMonthDay', text);
            }}
          />
        </HStack>
      </FormControl>
    );
  };

  const renderMonth = () => {
    return (
      <ModalActionAntSelect
        extraStyle={{
          flex: 1,
        }}
        disabled={!allowedToEdit}
        label=""
        optionProps={{
          key: 'value',
          label: 'label',
          value: 'value',
        }}
        data={MONTH_DROPDOWN_LIST}
        value={config.data.repeatsOnMonth}
        onChange={(selectedValue: any) => {
          config.onChangeData('repeatsOnMonth', selectedValue);
        }}
      />
    );
  };

  const renderRepeatsOn = () => {
    let jsx = <></>;
    switch (config.data.frequency) {
      case ReccurenceType.DAILY:
        jsx = <></>;
        break;
      case ReccurenceType.WEEKLY:
        jsx = (
          <>
            <DisplayText
              textLocalId={'days'}
              extraStyles={{
                color: Colors.Custom.Gray500,
                fontWeight: 500,
                fontSize: 14,
              }}
            />
            <Box mt={4} />
            <WeekDaySelector
              disabled={!allowedToEdit}
              values={config.data?.repeatsOnWeekDays}
              onChange={(ids) => {
                config.onChangeData('repeatsOnWeekDays', ids);
              }}
            />
          </>
        );
        break;
      case ReccurenceType.MONTHLY:
        jsx = renderRepeatsOnMonthDay();
        break;
      case ReccurenceType.YEARLY:
        jsx = (
          <HStack flex={1} alignItems={'flex-end'}>
            <View w="48%" mr={2}>
              {renderRepeatsOnMonthDay()}
            </View>
            <View w="50%">{renderMonth()}</View>
          </HStack>
        );
        break;
    }
    return (
      <View
        width={config.data.frequency !== ReccurenceType.DAILY ? '50%' : null}
        mr={2}
      >
        {jsx}
      </View>
    );
  };

  const renderDate = (config: IRenderDateParams) => {
    const {
      isRequired,
      leftMargin,
      label,
      format,
      onChange,
      disabledDate,
      value,
      customStyle,
      error,
      disabled,
      extraStyle
    } = config;
    return (
      <ModalActionDatePicker
        isRequired={isRequired}
        leftMargin={leftMargin}
        label={label}
        format={format}
        onChange={onChange}
        disabledDate={disabledDate}
        value={value}
        disablePicker={disabled}
        customStyle={customStyle}
        extraStyle={extraStyle}
        errors={error}
        errorsText="Invalid Date"
        {...testID('DatePicker')}
      />
    );
  };

  const renderStartTimeAndEndTime = () => {
    return (
      <FormControl isRequired isDisabled={!allowedToEdit}>
        <DisplayText
          textLocalId={'Select start and end time'}
          extraStyles={{
            color: Colors.Custom.Gray500,
            fontWeight: 500,
            fontSize: 14,
          }}
        />
        <TimePicker.RangePicker
          style={{height: '40px', width: '100%', marginTop: 2}}
          allowClear={false}
          format={DATE_FORMATS.DISPLAY_TIME_FORMAT}
          minuteStep={5}
          value={config.data.startAndEndTime as any}
          {...testID('TimePicker')}
          onChange={(values) => {
            config.onChangeData('startAndEndTime', values as any);
          }}
        />
      </FormControl>
    );
  };

  const renderMaxDateMessage = () => {
    if (!config.maxDate || isEditOccurence || isEditBlockOccurence) {
      return <></>;
    }
    return (
      <Text color={Colors.Custom.Gray500}>
        Maximum end date can be:
        {config.maxDate.format(DISPLAY_DATE_FORMAT)}
      </Text>
    );
  };
  const renderMaxRepeatsEveryMessage = () => {
    if (isEditOccurence || isEditBlockOccurence) {
      return <></>;
    }
    return (
      <Text color={Colors.Custom.Gray500}>
        Maximum can be: {config.maxRepeatsEveryLimit}
      </Text>
    );
  };

  const getBlockTimeStartDateParams = (): IRenderDateParams => {
    const commonParams = {
      isRequired: true,
      leftMargin: undefined,
      label: 'startDate',
      format: DISPLAY_DATE_FORMAT,
      onChange: (date: Moment) => {
        const finalDate = date.startOf('day').toISOString();
        config.onChangeData(config.isUpdate ? 'seriesStartDate' : 'startDate', finalDate);
      },
      disabledDate: (current: any) => {
        return (
          current &&
          isPastDay(current, getCurrentTimeZone()) &&
          isBeforeOrSameDate(current, config.data.endDate)
        );
      },
      value: getMomentObj((config.isUpdate ? config.data.seriesStartDate : config.data.startDate) as string),
      customStyle: { flex: 1 },
      error: false,
      disabled: !allowedToEdit,
    };

    return  commonParams;
  };


  const getMainViewByType = () => {
    switch (props.type) {
      case ReccuringEventType.APPOINTMENT:
        return (
          <>
            <HStack flex={1} alignItems={'flex-start'}>
              <View w={'50%'} mr={2}>
                {renderReccurenceType()}
              </View>
              <View w={'50%'}>{renderRepeatsEvery()}</View>
            </HStack>
            <HStack flex={1} mt={1} alignItems={'flex-start'}>
              {renderRepeatsOn()}
              <View w={'50%'}>
                {renderDate({
                  isRequired: true,
                  leftMargin:
                    config.data.frequency === ReccurenceType.DAILY
                      ? -3
                      : undefined,
                  label: 'endDate',
                  format: DISPLAY_DATE_FORMAT,
                  onChange: (date: Moment) => {
                    const finalDate = date.endOf('day').toISOString();
                    config.onChangeData('endDate', finalDate);
                  },
                  disabledDate: config.disabledEndDates,
                  value: getMomentObj(config.data.endDate),
                  customStyle: {flex: 1},
                  error: false,
                  disabled: !allowedToEdit,
                })}
                {renderMaxDateMessage()}
              </View>
            </HStack>
          </>
        );
      case ReccuringEventType.BLOCKTIME:
        return (
          <>
            <HStack flex={1}>
              <View w="48%" mr={2}>
                {renderDate(getBlockTimeStartDateParams())}
              </View>
              <View w={'50%'}>
                {renderDate({
                  isRequired: true,
                  leftMargin: undefined,
                  label: 'endDate',
                  format: DISPLAY_DATE_FORMAT,
                  onChange: (date: Moment) => {
                    const finalDate = date.endOf('day').toISOString();
                    config.onChangeData('endDate', finalDate);
                  },
                  disabledDate: config.disabledEndDates,
                  value: getMomentObj(config.data.endDate),
                  customStyle: {flex: 1},
                  error: false,
                  disabled: !allowedToEdit,
                })}
                {renderMaxDateMessage()}
              </View>
            </HStack>
            <HStack mt={2} flex={1} alignItems={'flex-start'}>
              <View w={'48%'} mr={2}>
                {renderReccurenceType()}
              </View>
              <View w={'50%'}>{renderRepeatsEvery()}</View>
            </HStack>
            <HStack mt={2}>{renderRepeatsOn()}</HStack>
            {config.isUpdate && isEditBlockOccurence && (
              <HStack mr={3} w={'48%'}>
                {renderDate({
                  isRequired: true,
                  leftMargin: undefined,
                  label: 'Event date',
                  format: DISPLAY_DATE_FORMAT,
                  onChange: (date: Moment) => {
                    const finalDate = date.startOf('day').toISOString();
                    config.onChangeData('startDate', finalDate);
                  },
                  disabledDate: (current: any) => {
                    return (
                      current &&
                      isPastDay(current, getCurrentTimeZone()) &&
                      isBeforeOrSameDate(current, config.data.endDate)
                    );
                  },
                  value: getMomentObj(config.data.startDate),
                  customStyle: {flex: 1},
                  extraStyle: {flex: 1},
                  error: false,
                  disabled: !allowedToEdit,
                })}
              </HStack>
            )}

            <HStack mt={2}>{renderStartTimeAndEndTime()}</HStack>
          </>
        );
    }
  };

  return (
    <VStack>
      <HStack alignItems={'center'}>
        <Text fontWeight={500} fontSize={16}>
          {intl.formatMessage({id: getReccurringEventViewTitleIds(props.type)})}
        </Text>
        <Switch
          className='custom-switch'
          style={{
            backgroundColor: isEnabled
              ? Colors.Custom.mainPrimaryPurple
              : Colors.Custom.Gray400,
              marginLeft: 10,
          }}
          disabled={!allowedToToggle}
          onChange={() => onChange()}
          // onToggle={onChange}
          checked={isEnabled}
          {...testID('RecurringToggle')}
        />
      </HStack>
      {isEnabled && (
        <VStack
          p={4}
          borderWidth={1}
          borderRadius={'md'}
          borderColor={Colors.Custom.Gray300}
          mt={2}
        >
          {getMainViewByType()}
          {allowedToEdit && <Text mt={2} color={Colors.Custom.Gray500}>{config.displaySummaryText}</Text>}
        </VStack>
      )}
    </VStack>
  );
};

export default ReccuringEventView;
