export const getSearchStringForCannedResponseEditor = (
  editorRef: any
) => {
  if (!editorRef.current) {
    return '';
  }
  const inst = editorRef.current;
  const markdown = inst.getMarkdown();
  if (markdown.indexOf('\n') !== -1) {
    return markdown.substring(0, markdown.indexOf('\n'));
  }
  return markdown;
};
