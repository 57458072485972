import { Drawer,Popover } from 'antd';
import { cloneDeep } from 'lodash';
import {
  Box,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Pressable,
  Text,
  VStack,
  View
} from 'native-base';
import React,{ FC,useCallback,useContext,useEffect,useState } from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { useSearchParams } from 'react-router-dom';
import { Handle,NodeProps,Position } from 'reactflow';
import {
  FlowType,
  WorkflowContext
} from '../../../../../context/WorkflowContext';
import { Colors } from '../../../../../styles'

import CircleIconView,{
  ICON_LIB,
  NodeCategory
} from '../../../../common/CircleIconView/CircleIconView';
import { AUTOMATION_ICONS, CustomAutomationIcon } from '../../../../common/CircleIconView/CustomAutomationIcon';
import { getNextNodeDependOnNaturalLanguage } from '../../workflowUtils';
import DynamicAutomationIcon from './DynamicAutomationIcon';
import {
  canShowField,
  getDisplayLabel,
  getDisplayValueComponent,
  setFieldsVisibilityFromPreviousNode
} from './FlowNodeHelper';
import { IUserActionState,IUserInputField } from './FlowNodeInterface';
import { INPUT_FIELD_TYPE,RequiredSourceNodeError } from './NodeInputField';
import NodeLevelExecutionLogFirst from './NodeLevelExecutionLogFirst';
import NodeLevelExecutionLogSecond from './NodeLevelExecutionLogSecond';
import ShowFilterTotalCountInputField from './ShowFilterTotalCountInputField';
import ShowFilterTotalCountOnNode from './ShowFilterTotalCountOnNode';
import SideMenu from './SideMenu';
import SideMenuHeader from './SideMenuHeader';
import WorkflowNodeDisplayText from './WorkflowNodeDisplayText';
import { JOURNEY_PRESCRIBE_NODE } from '../../../Journeys/AddOrUpdateJourney/JourneyTabs';

const CATEGORY = {
  OPERATION: 'OPERATION',
  FILTER: 'FILTER',
  CONDITION: 'CONDITION'
}

function ButtonMenu(props: {
  nodeId: string;
  data: any;
  parentDisplayName: string;
  getOutputNodeList: (
    nodeId: string,
    outputNodeList: Array<string>,
    isNeedParentList?: boolean,
    mergeParentNodeOutputList?: boolean
  ) => Array<string>;
  lambdaState: IUserActionState;
  nodeType: string;
  isViewOnly?: boolean;
  nodeMasterDataMap: { [key: string]: IUserActionState };
  onPress: (node: any, clientX: number, clientY: number, isEdit?: boolean) => void;
  setIsEdit: (isOpen: boolean) => void;
  canDo?: boolean;
  isAddedByFold?: boolean;
  isHoveredOverNode?: boolean;
  setIsHoveredOverNode?: any;
  setIsHoveredOverButtonMenu?: any;
  isHoveredOverButtonMenu: boolean;
  isExecutionLog?: boolean;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isExecutionLog] = useState<any>(
    searchParams.get('isExecutionLog') || false
  );
  const { setIsEdit, canDo, isAddedByFold, isHoveredOverNode, setIsHoveredOverNode, isHoveredOverButtonMenu, setIsHoveredOverButtonMenu } = props;
  const workflowContext = useContext(WorkflowContext);
  const [showModalData, setShowModalData] = useState({
    showModal: false,
    source: '',
    clientX: 0,
    clientY: 0,
  });
  const nodeMasterDataMap = props.nodeMasterDataMap;
  let handleList = [{ key: 'out', displayName: 'out', color: 'green.500' }];
  const nodeType = props.nodeType;
  const awsState = nodeMasterDataMap[nodeType]?.awsState;
  const disableAddStepsAction =
    nodeMasterDataMap[nodeType]?.disableAddStepsAction;

  let isHideOptionText = true;

  if (awsState === 'Option') {
    handleList = [];
    isHideOptionText = false;

    (nodeMasterDataMap[nodeType]?.optionList || []).forEach((option: any) => {
      handleList.push({
        key: option.value,
        displayName: option.displayName,
        color: option.color || '',
      });
    });
  }

  let outputNodeList = props.lambdaState?.outputNodeList || [];

  const mergeParentNodeOutputList =
    workflowContext.flowType !== FlowType.careJourney;

  outputNodeList = props.getOutputNodeList
    ? props.getOutputNodeList(
      props.nodeId,
      outputNodeList,
      false,
      mergeParentNodeOutputList
    )
    : outputNodeList;

  outputNodeList = outputNodeList.filter((id) => {
    return id != props.lambdaState?.type || id == 'AutomationTrigger';
  });

  if (
    workflowContext.flowType !== FlowType.careJourney &&
    !outputNodeList.length
  ) {
    return <></>;
  }

  return (
    <Flex style={{ width: '100%' }}>
      <HStack justifyContent="center" style={{ width: '100%' }} alignItems={'center'}>
        {handleList.map((handle, index) => {
          const key = handle.key;
          const displayName = handle.displayName || key;
          const sourceHandle = key;
          if (index == 0) {
            if (props?.data?.metaData?.naturalLanguageParseNodeList?.length) {
              const naturalLanguageParseNodeList = props?.data?.metaData?.naturalLanguageParseNodeList || [];
              // alert(JSON.stringify(naturalLanguageParseNode))
              props?.data?.setData ? props.data?.setData('naturalLanguageParseNodeList', []) : '';
              const nextNode = getNextNodeDependOnNaturalLanguage(naturalLanguageParseNodeList, sourceHandle, outputNodeList, nodeMasterDataMap);
              if (nextNode) {
                props.onPress(nextNode, 0, 0, false);
              }
            }
          }

          return (
            <VStack key={sourceHandle + index} backgroundColor={'transparent'}>
              <HStack flexWrap={'wrap'} >
                {!isHideOptionText && (
                  <Text numberOfLines={1} color={handle.color}>
                    {displayName}:
                  </Text>
                )}
                {disableAddStepsAction && (
                  <View >
                    <Handle
                      key={sourceHandle}
                      type="source"
                      position={Position.Bottom}
                      isConnectable={false}
                      style={{
                        position: 'absolute',
                        top: '-15px',
                        width: '40px',
                        height: '20px',
                        borderWidth: 0,
                        backgroundColor: 'transparent',
                        borderRadius: '3px',
                      }}
                      id={sourceHandle + index}
                    />
                  </View>
                )}
                {!disableAddStepsAction && (
                  <Pressable
                  marginTop={-1}
                    onPress={(event) => {
                      
                      if (props.isViewOnly) {
                        return;
                      }
                      props.onPress({
                        sourceHandle: sourceHandle,
                        isFocusOnSelectNextNode: true ,
                        metaData: {},
                      }, event?.nativeEvent.locationX, event?.nativeEvent.locationY, false);
                    }}
                    onHoverIn={()=> {setIsHoveredOverButtonMenu(true)}}
                    // onPressIn={() => {
                    //   setIsHoveredOverNode(true)
                    //   setIsHoveredOverButtonMenu(true)

                    // }}
                    onHoverOut={()=>{setIsHoveredOverButtonMenu(false)}}
                    // onPressOut={() => {
                    //   setIsHoveredOverNode(false)
                    //   setIsHoveredOverButtonMenu(false)
                    // }}
                  >

                      <Box justifyContent={'center'} alignItems={'center'}>
                        {(((isHoveredOverNode || isHoveredOverButtonMenu ) ||
                          (workflowContext?.focusOnSelectNextNode?.sourceId === props.nodeId &&
                            workflowContext?.focusOnSelectNextNode?.sourceHandle == sourceHandle)) &&
                            (!isExecutionLog)) &&
                            !props?.isViewOnly && workflowContext.isAllowInterAction  ? (
                          <DynamicAutomationIcon
                            isHoveredOverButtonMenu={isHoveredOverButtonMenu ||( workflowContext?.focusOnSelectNextNode?.sourceId === props.nodeId && workflowContext?.focusOnSelectNextNode?.sourceHandle == sourceHandle)}
                          />
                        ) : null}
                        <VStack key={props.nodeId + sourceHandle + index } width='full' justifyContent={'center'} alignItems={'center'}>
                        <Text color={'transparent'} width={'301'} height={'1px'}>{isExecutionLog ? 'This is execution log' : 'null'}</Text>
                        <Text key={props.nodeId + sourceHandle + index} width={1} height={0} style={{ color: 'transparent' }}>{'K'}</Text>
                        
                        <Handle
                          key={sourceHandle}
                          type="source"
                          position={Position.Bottom}
                          style={{
                            width: 20,
                            height: 20,
                            position: 'absolute',
                            top: '-10px',
                            borderWidth: 0,
                            backgroundColor: 'transparent',
                            borderRadius: '3px',
                          }}
                          id={sourceHandle}
                          
                        />
                        
                        </VStack>
                         
                      </Box>
                    
                  </Pressable>
                )}
              </HStack>
              {/* <NewOutputModel
                parentDisplayName={props.parentDisplayName}
                allOutputNodeList={outputNodeList}
                showModalData={showModalData}
                sourceHandle={sourceHandle}
                setShowModalData={setShowModalData}
                onPress={props.onPress}
                setIsEdit={setIsEdit}
                canDo={canDo}
                isAddedByFold={isAddedByFold}
              ></NewOutputModel> */}
            </VStack>
          );
        })}
      </HStack>
    </Flex>
  );
}

const compareObject = (object1: any, object2: any) => {
  return JSON.stringify(object1) == JSON.stringify(object2);
};

const LambdaState: FC<NodeProps> = ({ data, id }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isExecutionLog] = useState<any>(
    searchParams.get('isExecutionLog') || false
  );
  const workflowContext = useContext(WorkflowContext);
  const nodeMetaData = workflowContext.nodeMetaData;
  const nodeMasterDataMap = workflowContext.nodeMasterDataMap;
  const [nodeType] = useState(data?.nodeType);
  const [rootNodeId] = useState(data?.rootNodeId);
  const lambdaState: IUserActionState = cloneDeep(nodeMasterDataMap[nodeType]);
  const disableEditAndDeleteActions = lambdaState?.disableEditAndDeleteActions;
  const disableEdit = lambdaState?.disableEdit;
  const isNodeExecutionCompletedForCareJourney = data?.metaData?.isNodeExecutionCompletedForCareJourney;
  const [isViewOnly] = useState(!!data?.isViewOnly);
  const [userInputFieldList, setUserInputFieldList] = useState<
    Array<IUserInputField>
  >(
    data?.metaData?.userInputFieldList || lambdaState?.userInputFieldList || []
  );


  const [isEdit, setIsEditState] = useState(data.isEdit || false);
  const [isNewlyAdded, setIsNewlyAdded] = useState(data.isNewlyAdded || false);

  useEffect(()=>{
    setIsNewlyAdded(data.isNewlyAdded)
  }, [data.isNewlyAdded])

  function setIsEdit(isEdit: boolean) {
    setIsEditState(isEdit);
    data.setIsEdit(isEdit);
  }

  useEffect(() => {
    const triggerState = searchParams.get('triggerState');
    const contentType = searchParams.get('contentType');
    const DEFAULT_NODE_TYPE = searchParams.get('DEFAULT_NODE_TYPE');
    const isDefaultStateSet = searchParams.get('isDefaultStateSet');
    const isFromCMS =
      contentType == 'sms' ||
      contentType == 'notification' ||
      contentType == 'email';
    if (
      triggerState &&
      isFromCMS &&
      DEFAULT_NODE_TYPE == nodeType &&
      !isDefaultStateSet
    ) {
      searchParams.set('isDefaultStateSet', 'set');
      setIsEdit(true);
      setSearchParams(searchParams);
    }
  }, [searchParams.get('triggerState')]);

  const displayName = lambdaState?.nodeCardTitle || lambdaState?.displayName || nodeType;
  const nodeStyle = nodeType==='UnityAI'? {
    // @ts-ignore: Unreachable code error
    backgroundImage:
    'linear-gradient(237.73deg, #D2EBEF 0.27%, #F5E4FE 102.44%)',
  } : {}
  const description = lambdaState?.description
  const getOutputNodeList = data?.getOutputNodeList;
  const replaceNodeById = data?.replaceNodeById;
  const previousImmediateNode = data?.getPreviousImmediateNode(id);
  const getPreviousImmediateNode = data?.getPreviousImmediateNode;
  const { inputFields: updatedInputFieldList } =
    setFieldsVisibilityFromPreviousNode(
      userInputFieldList,
      nodeMasterDataMap,
      previousImmediateNode,
      workflowContext.flowType
    );

  const userInputFieldListCopy = updatedInputFieldList.map(
    (userInputField: IUserInputField) => {
      return {
        ...userInputField,
        value:
          userInputField.value == undefined
            ? userInputField.defaultValue
            : userInputField.value,
      };
    }
  );

  const setUserInputFieldListValue = (list: Array<IUserInputField>) => {
    data.setData('userInputFieldList', list);
    setUserInputFieldList(list);
  };

  if (!compareObject(userInputFieldListCopy, userInputFieldList)) {
    setUserInputFieldListValue(userInputFieldListCopy);
  }

  const onCloseEditMode = useCallback(() => {
    setIsEdit(false);
  }, []);

  const onSaveEditMode = (data: any) => {
    const newUserInputFieldList: Array<IUserInputField> =
      data?.userInputFieldMap?.out?.userInputFieldList || [];

    setUserInputFieldListValue(newUserInputFieldList);
    setIsEdit(false);
  };

  const isRequiredSourceNodeError = (): boolean => {
    const requiredNode = getRequiredSourceNodeIfConnected();
    return requiredSourceNodes.length > 0 && !requiredNode;
  };

  const getRequiredSourceNodeIfConnected = () => {
    const requiredConnectedNode = requiredSourceNodes.filter(
      (node) => node.type && node.type === previousImmediateNode?.data?.nodeType
    );
    if (requiredConnectedNode.length > 0) {
      return previousImmediateNode;
    }
  };

  const getRequiredSourceNodes = () => {
    const groups: string[] = [];
    const requiredNodeSourceNames: any[] = [];

    userInputFieldList.forEach((field) => {
      if (field.group && !groups.includes(field.group)) {
        groups.push(field.group);
      }
    });

    Object.keys(nodeMasterDataMap).forEach((key: string) => {
      const entityType = nodeMasterDataMap[key].passData?.entityType || '';
      if (groups.includes(entityType) && nodeMasterDataMap[key].displayName) {
        requiredNodeSourceNames.push(nodeMasterDataMap[key]);
      }
    });

    return requiredNodeSourceNames;
  };

  const onUserInputFieldClick = useCallback(() => {
    setIsEdit(true);
  }, []);

  const handleChangeInUserInputFieldList = useCallback(
    (value: IUserInputField) => {
      // handleChangeInUserInputFieldList
    },
    []
  );

  const requiredSourceNodes = getRequiredSourceNodes();

  const previousImmediateNodeId = previousImmediateNode?.id;

  const mergeParentNodeOutputList =
    workflowContext.flowType !== FlowType.careJourney;

  let parentNodeOutputList: Array<string> = [];

  if (previousImmediateNodeId) {
    const outputNodeData = getOutputNodeList
      ? getOutputNodeList(id, [], true, mergeParentNodeOutputList)
      : { parentNodeOutputList: [] };

    parentNodeOutputList = outputNodeData.parentNodeOutputList || [];
  }
  const [rootNodeType] = useState(data?.rootNodeType);
  const isShowNodeDisplayTemplate = workflowContext?.nodeMasterDataMap[nodeType]?.nodeDisplayTemplate;
  const showCardTitle = !!(lambdaState?.nodeCardTitle || lambdaState?.displayName || !isShowNodeDisplayTemplate);
  const nodeCategoryDisplayText = (['ADD', 'SEND', 'ORDER'].indexOf(lambdaState?.category?.code || '') != -1) ? lambdaState?.category?.display : '';

  const [isHoveredOverNode, setIsHoveredOverNode] = useState(false)
  const [isHoveredOverButtonMenu, setIsHoveredOverButtonMenu] = useState(false)


  const getColorByLambdaState = ({ params: { isBorderColor = false, isIconBackground = false, isBackgroundColor = false } }) => {
    const categoryCode = lambdaState?.category?.code || '';
    const colorMap = {
        isBorderColor: {
            [CATEGORY.FILTER]: Colors.FoldPixel.ACCENT_DARK_PINK,
            [CATEGORY.OPERATION]: Colors.FoldPixel.GRAY200,
            default: Colors.FoldPixel.PRIMARY300
        },
        isIconBackground: {
            [CATEGORY.FILTER]: '#FBD4E1',
            [CATEGORY.OPERATION]: Colors.FoldPixel.GRAY100,
            default: '#8F5AE21A'
        },
        isBackgroundColor: {
            [CATEGORY.FILTER]: Colors.FoldPixel.ACCENT_LIGHT_PINK,
            [CATEGORY.OPERATION]: Colors.FoldPixel.GRAY50,
            default: Colors.FoldPixel.PRIMARY100
        }
    };

    const getColor = (map:any, code:string) => {
      return map[code] || map.default || 'white'
    };

    if (isBorderColor) {return getColor(colorMap.isBorderColor, categoryCode)};
    if (isIconBackground) {return getColor(colorMap.isIconBackground, categoryCode)};
    if (isBackgroundColor) {return getColor(colorMap.isBackgroundColor, categoryCode)};
    return 'white';
};
  const [timeoutId, setTimeoutId] = useState<any>(undefined);
  // const widthHeightRatio = data?.reactFlowInstance ? data?.reactFlowInstance?.getZoom() : 1
  const widthHeightRatio = 1;
  const getPopoverBorder = () => {
    const borderColor = getColorByLambdaState({params:{isBorderColor : true}})
    const borderString = 0.5*widthHeightRatio + 'px solid '+ borderColor;
    return borderString;
  }

  const nodePopoverContent = (
    <VStack space={0} style={{
        // minWidth: 235 * widthHeightRatio,
        // maxWidth: 400 * widthHeightRatio,
        // backgroundColor: 'amber.400'
        // maxWidth: 100,
        // maxHeight: 200
        // height:'100%'
        // height: '50%'
      }}
      flex={1}
      >
      <HStack>
        <Text
          fontSize={12}
          fontStyle={'normal'}
          fontWeight={500}
          // letterSpacing={widthHeightRatio}
          color={getColorByLambdaState({ params: { isBorderColor: true } })}
        >
          {displayName}
        </Text>

      </HStack>
      <HStack
        style={{
          borderRadius: 8
        }}
      >
        {isShowNodeDisplayTemplate &&
          <WorkflowNodeDisplayText
            isShowTemplate={true}
            // ratio={widthHeightRatio}
            previousImmediateNode={previousImmediateNode}
            userInputFieldList={userInputFieldList}
            displayName={displayName}
            nodeType={nodeType}
            // widthHeightRatio={widthHeightRatio}
          />
        }
      </HStack>

      {!isExecutionLog && <Divider color={Colors.FoldPixel.GRAY200} marginY={1}></Divider>}

      <HStack width={'100%'}>
        {nodeType === 'WORKFLOWS' &&
          data &&
          data.metaData &&
          data.metaData.title && (
            <Text fontWeight="400" textAlign="center">
              {data.metaData.title}
            </Text>
          )}

        {isRequiredSourceNodeError() && (
          <RequiredSourceNodeError
            requiredNodes={requiredSourceNodes.map(
              (node) => node.displayName
            )}
          />
        )}
        {/* <HStack marginTop={2} flex={1} width='350'>
            {isShowNodeDisplayTemplate && <WorkflowNodeDisplayText previousImmediateNode={previousImmediateNode} userInputFieldList={userInputFieldList} displayName={displayName} nodeType={nodeType}></WorkflowNodeDisplayText>}
          </HStack> */}
        {!isShowNodeDisplayTemplate && <VStack width={'100%'}>
          {!isRequiredSourceNodeError() &&
            userInputFieldList
              .filter((userInputField) => {
                return (
                  userInputField?.fieldType !== INPUT_FIELD_TYPE.RICH_TEXT_EDITOR &&
                  userInputField?.fieldType !== INPUT_FIELD_TYPE.ASSIGNED_TO_USER_ROLE_OR_TASK_POOL &&
                  userInputField?.fieldType !== INPUT_FIELD_TYPE.READONLY_INFO_FIELD &&
                  canShowField(userInputField)
                );
              })
              .map((userInputField: IUserInputField, index: number) => {
                const displayLabel = getDisplayLabel(userInputField);
                const displayValue = getDisplayValueComponent(
                  userInputField,
                  nodeMetaData,
                  onUserInputFieldClick
                );
                return index < 2 ||
                  (!data.isWorkFlowEditable && displayValue !== '-') ? (
                    <HStack
                      key={`${displayLabel}_${index}`}
                      space={4}
                    >
                      <HStack  flexBasis={'max-content'}>
                        {displayLabel && (
                          <Text fontWeight={400} fontSize={12 * widthHeightRatio} color={Colors.FoldPixel.GRAY200}>
                            {displayLabel}
                          </Text>
                        )}
                      </HStack>
                      <HStack flex={5}>
                        <Text fontWeight={400} fontSize={12 * widthHeightRatio} color={Colors.FoldPixel.GRAY200} wordBreak={'break-all'}>
                          {displayValue}
                        </Text>
                      </HStack>
                    </HStack>
                ) : (
                  <View key={`${displayLabel}_${index}`}></View>
                );
              })}
          {/* {!isRequiredSourceNodeError() &&
            data.isWorkFlowEditable &&
            userInputFieldList.length > 2 && (
              <HStack marginTop={1}>
                <Spacer />
                <Pressable
                  key="more"
                  width="50px"
                  onPress={() => {
                    setIsEdit(true);
                  }}
                >
                  <Text color="primary.500">More...</Text>
                </Pressable>
              </HStack>
            )} */}

        </VStack>}
      </HStack>

      {rootNodeType == 'ForAllPatientWithFilter' ?
        <HStack>
          {lambdaState?.isMemberFilterNode ?
            <HStack alignItems={'center'}>
              <Text fontWeight="400" fontSize={widthHeightRatio * 12} lineHeight={'120%'} fontStyle={'normal'} color={Colors.FoldPixel.GRAY200}>Total Members : </Text>
              <ShowFilterTotalCountInputField
                sourceHandle={data?.getSourceHandle(id)}
                getPreviousImmediateNode={getPreviousImmediateNode}
                prevImmediateNode={previousImmediateNode}
                userInputFieldList={userInputFieldList}
                setUserInputFieldList={(list: any) => {
                  setUserInputFieldListValue(list)
                }}
                widthHeightRatio={widthHeightRatio}
              />
            </HStack> :
            <HStack alignItems={'center'}>
              <Text fontWeight="400" fontSize={widthHeightRatio * 12} fontStyle={'normal'} lineHeight={'120%'} color={Colors.FoldPixel.GRAY200}>Total Members : </Text>
              <ShowFilterTotalCountOnNode
                sourceHandle={data?.getSourceHandle(id)}
                getPreviousImmediateNode={getPreviousImmediateNode}
                prevImmediateNode={previousImmediateNode}
                userInputFieldList={userInputFieldList}
                setUserInputFieldList={(list: any) => {
                  setUserInputFieldListValue(list)
                }}
                widthHeightRatio={widthHeightRatio}
              />
            </HStack>
          }
        </HStack> : <></>}
    </VStack>
  )

  return (
    <div className={  workflowContext?.focusOnSelectNextNode?.isFocusOnSelectNextNode && workflowContext?.focusOnSelectNextNode?.sourceId !== id  ? 'blurDiv': '' } style={{width:'100%', height:'100%'}} >
    {/* <VStack> */}
      <HStack>
        {isExecutionLog ? (
          <HStack
            borderStyle={'solid'}
            roundedLeft="md"
            borderWidth={0.5}
            borderRightWidth={0}
            borderLeftRadius={12}
            borderColor={(
              isNewlyAdded ? getColorByLambdaState({ params: { isBorderColor: true } }) :
              isHoveredOverNode ? getColorByLambdaState({ params: { isBorderColor: true } }) : Colors.FoldPixel.GRAY200
              )}
          >
            <NodeLevelExecutionLogFirst
              rootNodeType={rootNodeType}
              rootNodeId={rootNodeId}
              parentNode={previousImmediateNodeId}
              nodeMetaData={lambdaState}
              uiNodeId={id}
            />
            <NodeLevelExecutionLogSecond
              rootNodeId={rootNodeId}
              parentNode={previousImmediateNodeId}
              nodeMetaData={lambdaState}
              uiNodeId={id}
              locationIds={workflowContext?.locationIntersections || []}
            />
          </HStack>
        ) : (
          <></>
        )}


        <Pressable
          borderRightRadius={12}
          isDisabled={isEdit}
          onPress={() => {
            if (data.isWorkFlowEditable && !disableEditAndDeleteActions && !disableEdit && !isNodeExecutionCompletedForCareJourney) {
              setIsEdit(true);
            }
            setIsHoveredOverNode(false)
          }}
          cursor={'pointer'}
          onHoverIn={() => {
            setIsHoveredOverNode(true)
          }}
          onHoverOut={() => {
            setIsHoveredOverNode(false)
          }}
          paddingBottom={isExecutionLog ? 0 : 5 }
        >
          <Popover
            trigger={['hover']}
            zIndex={100}
            content={!isEdit && workflowContext.isAllowInterAction ? nodePopoverContent : null}
            placement= 'top'
            showArrow={false}
            // open={isHoveredOverNode && !isEdit}
            autoAdjustOverflow={true}
            overlayInnerStyle={{
              borderRadius:12*widthHeightRatio,
              border: getPopoverBorder()

            }}
            overlayStyle={{
              maxWidth: window.innerWidth * 0.3,
              maxHeight: window.innerHeight * 0.4
            }}
          >
            <Box
              height={isExecutionLog ? '100%' : 70}
              style={{ paddingHorizontal: 12, paddingVertical: 12, ...nodeStyle}}
              borderRightRadius={12}
              borderLeftRadius={isExecutionLog ? 'none' : 12}
              borderLeftWidth={isExecutionLog? 0 : 0.5}
              borderColor={(
                isNewlyAdded ? getColorByLambdaState({ params: { isBorderColor: true } }) :
                isHoveredOverNode ? getColorByLambdaState({ params: { isBorderColor: true } }) : Colors.FoldPixel.GRAY200
                )}
              borderWidth='0.5'
              backgroundColor={
                isNewlyAdded ? getColorByLambdaState({ params: { isBackgroundColor: true } }) :
                isHoveredOverNode ? getColorByLambdaState({ params: { isBackgroundColor: true } }) : 'white'
              }
              width={250}
              borderStyle={data?.isAddedByFold ? 'dashed' : 'solid'}
              justifyContent={'center'}
            >
              <Handle
                type="target"
                position={Position.Top}
                id="in"
                style={{
                  width: '100%',
                  height: '20px',
                  backgroundColor: 'transparent',
                  borderColor: 'transparent',
                  borderRadius: '3px',
                }}
              />


              <HStack space={2} alignItems={"center"}>
                <HStack alignItems={'center'}>

                <CircleIconView
                    nodeType={data?.nodeType}
                    isStartNode={data?.isStartNode}
                    isSquareView={true}
                    borderWidth='5px'
                    size={'28px'}
                    iconInfo={ 
                      data?.isStartNode || data?.nodeType == JOURNEY_PRESCRIBE_NODE ? 
                      {
                        'lib': ICON_LIB.CustomSvgIcon,
                        'icon': AUTOMATION_ICONS.ZAP_GRADIENT_ICON
                      } :
                      lambdaState?.iconInfo
                    }
                    nodeCategory={lambdaState?.category?.code || NodeCategory.moment}
                    backgroundColor={
                      data?.isAddedByFold
                        ? Colors.secondary[100]
                        :
                        isNewlyAdded ? getColorByLambdaState({ params: { isIconBackground: true } }) :
                        isHoveredOverNode ? getColorByLambdaState({ params: { isIconBackground: true } }) : getColorByLambdaState({ params: { isBackgroundColor: true } })
                    }
                    borderColor={
                      data?.isAddedByFold
                        ? Colors.secondary[100]
                        : 
                        Colors.FoldPixel.TRANSPARENT
                    }
                    iconColor={
                      data?.isAddedByFold
                        ? Colors.secondary[500]
                        : 
                        getColorByLambdaState({ params: { isBorderColor: true } })
                    }
                  />
                </HStack>

                <HStack flex={1} alignItems={'center'}>
                  <VStack flex={1} space={1}>
                    <Text
                      fontSize={12} 
                      fontWeight={isHoveredOverNode? '500' : '400'} 
                      textAlign='left' 
                      lineHeight={'120%'} 
                      color={isNewlyAdded? Colors.FoldPixel.GRAY300 : isHoveredOverNode ? Colors.FoldPixel.GRAY300 : Colors.FoldPixel.GRAY200 }
                      numberOfLines={1}
                      textOverflow={'ellipsis'}
                    >
                      {displayName} 
                    </Text>
                    
                    {isShowNodeDisplayTemplate &&
                    <Text
                      overflow={'hidden'}
                      style={{ 
                        fontSize: 12, 
                        fontWeight: '400', 
                        lineHeight: 14.4, 
                        color: Colors.FoldPixel.GRAY400
                      }}
                      numberOfLines={2}
                      >
                      <WorkflowNodeDisplayText
                        isHoveredOverNode={isHoveredOverNode}
                        isSimpleView={true}
                        previousImmediateNode={previousImmediateNode}
                        userInputFieldList={userInputFieldList}
                        displayName={displayName}
                        nodeType={nodeType}
                      />
                    </Text>
                    }
                    { !isShowNodeDisplayTemplate &&
                      workflowContext.flowType == FlowType.ivr &&
                    <VStack width={'100%'}>
                      {!isRequiredSourceNodeError() &&
                        userInputFieldList
                          .filter((userInputField) => {
                            return (
                              userInputField?.fieldType !== INPUT_FIELD_TYPE.RICH_TEXT_EDITOR &&
                              userInputField?.fieldType !== INPUT_FIELD_TYPE.ASSIGNED_TO_USER_ROLE_OR_TASK_POOL &&
                              userInputField?.fieldType !== INPUT_FIELD_TYPE.READONLY_INFO_FIELD &&
                              canShowField(userInputField)
                            );
                          })
                          .map((userInputField: IUserInputField, index: number) => {
                            const displayLabel = getDisplayLabel(userInputField);
                            const displayValue = getDisplayValueComponent(
                              userInputField,
                              nodeMetaData,
                              onUserInputFieldClick
                            );
                            return index < 2 ||
                              (!data.isWorkFlowEditable && displayValue !== '-') ? (
                              <HStack
                                key={`${displayLabel}_${index}`}
                                space={4}
                              >
                                <HStack flexBasis={'max-content'}>
                                  {displayLabel && (
                                    <Text fontWeight={400} fontSize={12} color={Colors.FoldPixel.GRAY400}>
                                      {displayLabel}
                                    </Text>
                                  )}
                                </HStack>
                                <HStack flex={5}>
                                  <Text fontWeight={400} fontSize={12} color={Colors.FoldPixel.GRAY400} numberOfLines={1}>
                                    {displayValue}
                                  </Text>
                                </HStack>
                              </HStack>
                            ) : (
                              <View key={`${displayLabel}_${index}`}></View>
                            );
                          })}
                    </VStack>}
                  </VStack>
                </HStack>

                {data?.isAddedByFold ? (
                <HStack
                  style={{
                    backgroundColor: Colors.secondary[100],
                    paddingHorizontal: 10,
                    borderRadius: 5,
                    marginBottom: 15,
                    justifyContent: 'space-between',
                  }}
                >
                  <View
                    style={{
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      flexDirection: 'row',
                    }}
                  >
                    <AntIcon
                      size={15}
                      color={Colors.secondary[500]}
                      name={'infocirlceo'}
                    />
                    <Text
                      size={'smRegular'}
                      color={Colors.secondary[500]}
                      style={{ alignItems: 'center' }}
                    >
                      {`   Added by Fold`}
                    </Text>
                  </View>

                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    {data.isWorkFlowEditable && !disableEditAndDeleteActions && !isNodeExecutionCompletedForCareJourney && (
                      <IconButton
                        icon={
                          <Icon
                            as={AntIcon}
                            color={Colors.secondary[700]}
                            size="xs"
                            name={isViewOnly ? 'eyeo' : 'edit'}
                          />
                        }
                        onPress={() => {
                          setIsEdit(true);
                        }}
                      />
                    )}
                    {!data?.isStartNode &&
                      data.isWorkFlowEditable &&
                      !disableEditAndDeleteActions && !isNodeExecutionCompletedForCareJourney && (
                        <IconButton
                          icon={
                            <Icon
                              as={AntIcon}
                              color={Colors.secondary[700]}
                              size="xs"
                              name="delete"
                            />
                          }
                          onPress={() => {
                            data?.onDelete(id);
                          }}
                        />
                      )}
                  </View>
                </HStack>
              ) : (
                <View justifyContent={'center'}>
                {
                  !data?.isStartNode &&
                  data.isWorkFlowEditable &&
                  !isViewOnly &&
                  !disableEditAndDeleteActions &&
                  !isNodeExecutionCompletedForCareJourney && workflowContext.isAllowInterAction && (
                    <Pressable
                      borderRadius={4}
                      onHoverIn={()=>{setIsHoveredOverNode(true)}}
                      onHoverOut={()=>{setIsHoveredOverNode(true)}}
                      backgroundColor={Colors.FoldPixel.TRANSPARENT}
                      _hover={{ backgroundColor: getColorByLambdaState({ params: { isIconBackground: true } }) }}
                      onPress={(event) => {
                          data?.onDelete(id)
                      }}
                    >
                      <CustomAutomationIcon
                        name={'trashBin'} 
                        size='24px' 
                        iconColor={isHoveredOverNode? Colors.FoldPixel.GRAY300 : 'transparent'}
                      />
                  </Pressable>
                   )}
                </View>
              )}
              </HStack>
            </Box>
          </Popover>
        </Pressable>

        {/* {isExecutionLog ? (
          <VStack
            margin={0}
            borderStyle={'solid'}
            roundedRight="md"
            shadow={1}
            borderColor={'gray.100'}
            borderTopWidth={1}
            borderBottomWidth={1}
            borderRightWidth={1}
          ></VStack>
        ) : (
          <></>
        )} */}
      </HStack>
      <HStack height={'10px'} justifyContent={'center'}>
        <View height={'10px'} style={{ margin: -25 }}>
          <ButtonMenu
            isExecutionLog={isExecutionLog}
            setIsHoveredOverButtonMenu={setIsHoveredOverButtonMenu}
            isHoveredOverButtonMenu={isHoveredOverButtonMenu}
            setIsHoveredOverNode={setIsHoveredOverNode}
            isHoveredOverNode={isHoveredOverNode}
            data={data}
            parentDisplayName={(nodeCategoryDisplayText + ' ' + displayName).trim()}
            isViewOnly={isViewOnly}
            nodeId={id}
            getOutputNodeList={getOutputNodeList}
            lambdaState={lambdaState}
            nodeType={nodeType}
            nodeMasterDataMap={nodeMasterDataMap}

            onPress={(formData, clientX, clientY, isEdit) =>{
              setIsHoveredOverNode(false)
              data.onSelect(
                {
                  ...formData,
                  sourceId: id,
                  isEdit: isEdit,
                  nodeType: nodeType
                },
                clientX,
                clientY
              )
            }}
            setIsEdit={setIsEdit}
            canDo={data.isWorkFlowEditable && !disableEditAndDeleteActions && !isNodeExecutionCompletedForCareJourney}
            isAddedByFold={data?.isAddedByFold}
          />
        </View>
      </HStack>
      <Drawer
        zIndex={1000}
        title={''}
        destroyOnClose
        placement="right"
        onClose={() => {
          onCloseEditMode();
        }}
        open={isEdit}
        closable={false}
        width={'45%'}
      >
        <div style={isViewOnly ? { cursor: 'no-drop' } : {}}>
          <SideMenu
            iconInfo={lambdaState?.iconInfo}
            nodeCategory={lambdaState?.category?.code || NodeCategory.moment}
            rootNodeType={rootNodeType}
            getPreviousImmediateNode={getPreviousImmediateNode}
            isViewOnly={isViewOnly}
            sourceHandle={data?.getSourceHandle(id)}
            sideMenuHeader={
              <SideMenuHeader
                isViewOnly={isViewOnly}
                replaceNodeById={replaceNodeById}
                nodeId={id}
                previousImmediateNodeId={previousImmediateNodeId}
                stateData={lambdaState}
                data={data}
                parentOutputNodeTypeList={parentNodeOutputList}
              ></SideMenuHeader>
            }
            prevImmediateNode={previousImmediateNode}
            nodeType={nodeType}
            userInputFieldMap={{
              out: { userInputFieldList: cloneDeep(userInputFieldList) },
            }}
            requiredSourceNode={{
              isRequiredSourceNodeError: isRequiredSourceNodeError(),
              requiredSourceNodes,
            }}
            onChangeUserInputFieldList={handleChangeInUserInputFieldList}
            isOpen={isEdit}
            title={displayName}
            description={description}
            onClose={onCloseEditMode}
            onSave={onSaveEditMode}
          />
        </div>
      </Drawer>
    {/* </VStack> */}
    </div>

  );


};

export default React.memo(LambdaState);
