import _ from "lodash";
import { CARE_TEAM_MEMBER_TYPE, USER_ROLE_CODES } from "../../../../constants/MlovConst";
import { IAbility, IUser } from "../../../../Interfaces";
import { CareTeamAction } from "./CareTeamConst";
import { IUserRole } from "./interfaces";

export const formatRoleArray = (roles: IUserRole[]) => {
  if (!roles || !roles?.length) {
    return [];
  }
  return [
    ...new Map(
      roles
        .filter((item) => !!item?.['userRole']?.['customRoleCode'])
        .map((item) => [item?.['userRole']?.['customRoleCode'], item])
    ).values(),
  ].map((item) => item.userRole);
};

export const isPhysician = (member: any) => {
  const roles = formatRoleArray(member.userRoles);
  return roles?.some((role: any) => role?.code === USER_ROLE_CODES.PHYSICIAN);
};

export const getActionList = (member: any, additionalData: {
  careTeamAbilities: IAbility,
  primaryCarePhysician: string,
  isElation: boolean
}): CareTeamAction[] => {
  const actions: CareTeamAction[] = [];
  const isPCPMember = additionalData.primaryCarePhysician === member.uuid;
  let canMarkAsPCP = isPhysician(member);
  if (additionalData.isElation && !member.externalUserId) {
    canMarkAsPCP = false;
  }
  if (canMarkAsPCP) actions.push(CareTeamAction.markAsPCP);
  const canDelete =  !isPCPMember || (isPCPMember && !additionalData.careTeamAbilities?.allowedOperations?.disableDeletePCP);
  if (canDelete) actions.push(CareTeamAction.delete);
  return actions;
}

export const ALLOWED_USER_ROLES_CARE_TEAM_ROLES_MAP = {
  [CARE_TEAM_MEMBER_TYPE.CARE_MANAGER]: [
    USER_ROLE_CODES.CARE_PROGRAM_MANAGER,
    USER_ROLE_CODES.CLINICAL_NURSE_SPECIALIST,
    USER_ROLE_CODES.CERTIFIED_NURSE_MIDWIVES,
    USER_ROLE_CODES.NURSE_PRACTITIONER,
    USER_ROLE_CODES.PHYASST,
  ],
  [CARE_TEAM_MEMBER_TYPE.PCP]: [
    USER_ROLE_CODES.PHYSICIAN,
    USER_ROLE_CODES.QUALIFIED_HEALTH_PROFESSIONAL,
  ],
  [CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER]: [USER_ROLE_CODES.PHYSICIAN],
};


export const getUserItemPopoverAction = (params: {
  user: IUser,
  careTeamMemberTypes: any,
  CARE_TEAM_MEMBER_TYPE: any,
  isElation: boolean,
  isCareProgramEnabled: boolean
}) => {
  const {user, careTeamMemberTypes, CARE_TEAM_MEMBER_TYPE, isElation, isCareProgramEnabled} = params;
  const actionList: {
    action: CareTeamAction;
    title: string;
  }[] = [];
  const isPCP =
    user.memberType?.includes(careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.PCP]);
  const isCareManager =
    user.memberType?.includes(careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.CARE_MANAGER]);
  const isBilling =
    user.memberType?.includes(careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER]);
  const roles = formatRoleArray(user?.userRoles as []).map((role) => {
    if (role.customRoleCode) {
      return role.customRoleCode;
    } else {
      return role.userRole.code;
    }
  });

  if (isPCP) {
    actionList.push({
      action: CareTeamAction.unMarkAsPCP,
      title: 'unMarkAsPCP',
    });
  }
  if (isCareManager) {
    actionList.push({
      action: CareTeamAction.unMarkAsCareManager,
      title: 'unMarkAsCareManager',
    });
  }
  if (isBilling) {
    actionList.push({
      action: CareTeamAction.unMarkAsBilling,
      title: 'unMarkAsBillingPractioner',
    });
  }

  
  if (isAllowedToMarkAs(CARE_TEAM_MEMBER_TYPE.PCP, roles) && !isPCP) {
    actionList.push({
      action: CareTeamAction.markAsPCP,
      title: 'markAsPCP',
    });
  }

  if (
    isAllowedToMarkAs(CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER, roles) &&
    !isBilling &&
    isCareProgramEnabled
  ) {
    actionList.push({
      action: CareTeamAction.markAsBilling,
      title: 'markAsBillingPractioner',
    });
  }

  if ( isAllowedToMarkAs(CARE_TEAM_MEMBER_TYPE.CARE_MANAGER, roles) && !isCareManager && isCareProgramEnabled) {
    actionList.push({
      action: CareTeamAction.markAsCareManager,
      title: 'markAsCareManager',
    });
  }

  

  if (!(isElation && isPCP)) {
    actionList.push({
      action: CareTeamAction.delete,
      title: 'delete',
    });
  }

  return actionList;
};

const isAllowedToMarkAs = (type: string, roles: string[]) => {

  const allowedRoles = ALLOWED_USER_ROLES_CARE_TEAM_ROLES_MAP[type];

  const commonRoles = _.intersection(allowedRoles, roles);

  return  commonRoles.length > 0

}