import { IInterventionMlovs } from "../../../../../../../Interfaces";
import { IInterventionType } from "../interfaces";

export const INTERVENTION_IMMEDIATE_CODE = 'immediate';
export const interventionType: IInterventionType[] = [
    {
        code: 'memberTask',
        value: 'Send Task',
        type: 'CareJourneyAddTaskForContact',
        userInputFieldListKeys: [
            'title',
            'description',
            'priority',
            'taskLabel',
        ]
    },
    {
        code: 'form',
        value: 'Send Form',
        type: 'SendForms',
        userInputFieldListKeys: [
            'formList',
            'patientFacingTitle',
        ]
    },
    {
        code: 'educationContent',
        value: 'Send Educational Content',
        type: 'CareJourneyContentV3',
        userInputFieldListKeys: [
            'educationContent',
            'priority',
            'patientFacingTitle',
        ]
    },
    {
        code: 'measureVital',
        value: 'Measure Vital',
        type: 'CareJourneyVital',
        userInputFieldListKeys: [
            'entity',
            'note',
            'priority',
            'patientFacingTitle'
        ]
    },
    {
        code: 'providerTask',
        value: 'Internal Task',
        type: 'CareJourneyAddTask',
        userInputFieldListKeys: [
            'title',
            'description',
            'assignedToUserRoleOrTaskPool',
            'priority',
            'taskLabel'
        ]
    },
]

export const interventiondurationMlovs: IInterventionMlovs[] = [
    {
        id: INTERVENTION_IMMEDIATE_CODE,
        value: "immediate"
    },
    {
        id: 'day',
        value: "day"
    },
    {
        id: 'week',
        value: "week"
    }
]

export enum InterventionActions {
    EDIT = 'EDIT',
    DELETE = 'DELETE',
}

export enum TaskType {
    ProviderTask = 'providerTask',
}

export enum EntityType {
    MemberTask = 'memberTask',
    Form = 'form',
    EducationContent = 'educationContent',
    MeasureVital = 'measureVital',
    ProviderTask = 'providerTask',
    AppointmentLink = 'appointmentLink',
}

export enum InterventionTypeValues {
    CareJourneyAddTaskForContact = 'CareJourneyAddTaskForContact',
    SendForms = 'SendForms',
    CareJourneyContentV3 = 'CareJourneyContentV3',
    CareJourneyVital = 'CareJourneyVital',
    CareJourneyAddTask = 'CareJourneyAddTask',
    SendAppointmentBookingLink = 'SendAppointmentBookingLink',
}

export enum InterventionStatus {
    TO_DO = 'TO_DO',
    IN_PROGRESS = 'IN_PROGRESS',
    FAILED = 'FAILED',
    COMPLETED = 'COMPLETED'
}

export enum TaskStatus {
    Pending = 'Pending',
    Missed = 'Missed',
    Completed = 'Completed',
}

export enum InterventionPriority {
    High='asap',
    Medium='urgent',
    Low='routine'
}

