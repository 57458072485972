import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {
  HStack,
  View,
  VStack,
  Text,
  Pressable,
  Spacer,
  FlatList,
  Spinner,
} from 'native-base';
import { Collapse } from 'antd';
import Feather from 'react-native-vector-icons/Feather';
import {
  IBarrier,
  IBarrierComponentValue,
} from '../interfaces';
import {
  getMomentObj,
} from '../../../../../../../utils/DateUtils';

import {useIntl} from 'react-intl';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';
import {
  FormContext,
  FormViewType,
  IFormCommonData,
  IFormComponentProps,
} from '../../CustomWrapper/CustomWrapper';
import {
  isInvalid,
} from './AddOrUpdateBarriersHelper';
import FormComponentLoader from '../../CustomWrapper/FormComponentLoader/FormComponentLoader';
import {IKeyOperation, IMlov} from '../../../../../../../Interfaces';
import {FHIR_RESOURCE, RESOURCE_BLOCK_MESSAGE_ID} from '../../../../../../../constants/FhirConstant';
import {
  ADD_UPDATE_EVENTS,
  componentKeys,
  FormError,
} from '../../CustomWrapper/CustomComponentHelper';
import FormComponentError from '../../CustomWrapper/FormComponentError/FormComponentError';
import {v4 as uuidv4} from 'uuid';
import {Colors} from '../../../../../../../styles';
import SectionLoadingIndicator from '../../CustomWrapper/SectionLoadingIndicator/SectionLoadingIndicator';
import {
  ICustomDetailColumn,
} from '../../CustomWrapper/CustomDetailTableView/CustomDetailTableView';
import {Drawer} from 'antd';
import {BUTTON_TYPE, HTTP_ERROR_CODE, MLOV_CATEGORY} from '../../../../../../../constants';
import {cloneDeep} from 'lodash';
import {DetailViewCollapse} from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailViewCollapse';
import {NoImmunizationsSvg} from '../../../../../../common/Svg';
import {EventBus} from '../../../../../../../utils/EventBus';
import { InputType } from '../../../../../../../utils/capabilityUtils';
import { BarrierFields, filterAndSkipDuplicates } from '../../CustomComponentUtils';
import { useContainerDimensions } from '../../../../../../CustomHooks/ContainerDimensionHook';
import { ModalActionTitle } from '../../../../../../common/ModalActionTitle/ModalActionTitle';
import { CUSTOM_COMPONENT_STYLE } from '../../constants';
import DetailPreview from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailPreview/DetailPreview';
import { IFormValidationOutput, IHealthComponent } from '../../CustomWrapper/interfaces';
import { getOrganizationCapabilites } from '../../../../../TeamInbox/Conversations/MessagingContactDetails/ContactDetailsTabs/ActivityTimeLine/ActivityAdditionalInfo/CommonComponent/FormResponse/FormUtils';
import AdditionalHealthComponentSearch, { AdditionalHealthComponentSearchType, IHealthComponentItem } from '../../../../../../common/BarrierSearch/AdditionalHealthComponentSearch';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CREATE_BARRIER } from '../../../../../../../services/Components/ComponentQueries';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../../../constants/Configs';
import CustomListViewHealthComponent from '../../CustomListViewHealthComponent/CustomListViewHealthComponent';
import NoBarrierSvg from '../../../../../../common/Svg/CarePlanSvgs/NoBarrierSvg';
import { MlovQueries } from '../../../../../../../services';

interface IAddOrUpdateBarriersState extends IHealthComponent{
  mlovMap: Map<string, IMlov[]>;

}

const AddOrUpdateBarriers = (props: IFormComponentProps) => {
  const { Panel } = Collapse;
  const intl = useIntl();
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const isPatientForm = contextData.formContext === FormContext.patientForm;
  const isPatientNote = contextData.formContext === FormContext.patientNotes;
  const isCompactView = contextData.componentView === FormViewType.compact;
  const isPatientProfile = contextData.formContext === FormContext.patientProfile;
  const isRecentReported = contextData.formContext === FormContext.recentReportedView;
  const isPreviewMode = contextData.isPreviewMode;
  const isAllowToShare = props?.component?.allowToShare &&  props?.component?.allowToShare === false ? false : true;
  const componentRef = useRef();
  const drawerDetailRef = useRef();
  const { width } = useContainerDimensions(componentRef);
  const { width: drawerDetailWidth, resetDimension } = useContainerDimensions(drawerDetailRef);
  const isMobileScreen = isCompactView ? drawerDetailWidth <= 480 : width <= 480;
  const [componentValue, setComponentValue] = useState<IBarrierComponentValue>(
    props.component?.selectedValue ||
    props.component.defaultComponentValue
    || {barriers: []}
  );
  const [componentState, setComponentState] =
    useState<IAddOrUpdateBarriersState>({
      loading: false,
      formError: contextData.formError,
      collapsed: contextData.defaultCollapse,
      showSavingIndicator: false,
      editModalVisible: false,
      editModalCollapse: false,
      showPatientReportedRecords: false,
      selectedPatientReportedRecord: undefined,
      selectedItemToMap: undefined,
      activePanels: [],
      mlovMap: new Map()
    });
  const [importFromChartLoading, setImportFromChartLoading] = useState<boolean>(false);
  const isWidgetView = props.options.componentView === FormViewType.widget;
  const carePlanCategoryList = componentState.mlovMap.get(MLOV_CATEGORY.CARE_PLAN_CATEGORY) || [];

  const getFieldCapabilities = (): IKeyOperation | undefined => {
    return contextData.capabilities?.abilities?.keyAllowedOperations;
  };

  const validateData = (currentData: IBarrierComponentValue): IFormValidationOutput => {
    // If no patient id is not found means it's lead and for lead, the section is not applicable. So validation should always return true
    if (componentState.formError === FormError.noPatientIdFound) {
      return {isValid: true, message: ''};
    }
    const hasMissingData = currentData?.barriers?.some((item) => {
      return isInvalidRecord(item);
    });
    return { isValid: !hasMissingData, message: hasMissingData ? `${props.component.label}: Please fill all the mandatory fields` : '' };
  };

  const isInvalidRecord = (item: IBarrier) => {
    const config = getFieldCapabilities();
    return (
      isInvalid(BarrierFields.title, item, true, config)
    );
  };

  const canAddNew = (): boolean => {
    // GLOBAL CHECK FROM EHR SETTINGS
    return contextData.isBuilderMode || !props.disabled || !isWidgetView;
  };
  const organizationCapability = getOrganizationCapabilites(
    contextData.organizationCapability,
    FHIR_RESOURCE.CLINICAL_DATA
  );

  const [createBarrier] = useMutation(CREATE_BARRIER, {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    });

  const [getAccountMlovList] = useLazyQuery(MlovQueries.GET_ACCOUNT_MLOVS_BY_CATEGORY, {
    fetchPolicy: 'no-cache',
    context: {
      headers: contextData.headers,
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });


  const removeFromLocal = (barrier: IBarrier) => {
    if(barrier.id){
      barrier.isDeleted = true;
      const index = componentValue.barriers.findIndex(
        (item) => item.id === barrier.id
      );
      componentValue.barriers[index] = barrier;
      setComponentValue((prev) => ({
        ...prev,
        barriers: [...componentValue.barriers],
      }));
    }
    else {
      const index = componentValue.barriers.findIndex(
        (item) => item.uniqueId === barrier.uniqueId
      );
      componentValue.barriers.splice(index, 1);
      setComponentValue((prev) => ({
        ...prev,
        barriers: [...componentValue.barriers],
      }));
    }

  }

  const addNewItem = (item: IHealthComponentItem) => {
    const recordedDate = getMomentObj(new Date()).toISOString();
    const uniqueId = uuidv4();

    const data: IBarrier = {
      title: item?.title,
      barrierId: item?.id,
      tenantId: item?.tenantId,
      recordedDate,
      uniqueId: uniqueId,
    };
    componentValue.barriers.splice(0, 0, data);
    onRecordChange(data);
    setComponentValue({
      barriers: [...componentValue.barriers],
    });
  };

  const onRecordChange = (record: IBarrier) => {
    if (!record?.barrierId) {
      addOrUpdateRecord(record);
    }
  };

  const getSearchComponent = (fullWidth?: boolean): JSX.Element => {
    return (
      <Pressable
        width={'100%'}
        paddingRight={fullWidth ? 6 : 0}
        onPress={(event) => event.stopPropagation()}
      >
        <View
          flex={1}
          style={!isMobileScreen ? {width: fullWidth ? '100%' : 350} : {}}
        >
          <AdditionalHealthComponentSearch
            addNewOptionEnabled={true}
            clearOnSelection
            searchType={AdditionalHealthComponentSearchType.Barrier}
            additionalHeaders={contextData.headers}
            placeholder="Search and add new barrier"
            isShowError={false}
            onChange={(value: IHealthComponentItem) => {
              if (value) {
                addNewItem(value);
              }
            }}
            filterBarriers={getFilteredBarries()}
          />
        </View>
      </Pressable>
    );
  };

  useEffect(()=> {
    fetchMetaData();
  }, []);

  const fetchMetaData = async () => {
    try {
      const tenantId = contextData?.tenantId;
      if (!tenantId) {
        return;
      }
      const mlovData = await getAccountMlovList({
        variables: {
          categories: [MLOV_CATEGORY.CARE_PLAN_CATEGORY],
        },
      });
      const carePlanCategoryTypes: IMlov[] = (
        mlovData?.data?.accountMlovsDeprecated || []
      ).filter(
        (mlov: any) =>
          mlov?.category?.category === MLOV_CATEGORY.CARE_PLAN_CATEGORY &&
          (!mlov.tenantId || mlov.tenantId === tenantId)
      );

      setComponentState((prev) => {
        const map = prev.mlovMap;
        map.set(MLOV_CATEGORY.CARE_PLAN_CATEGORY, carePlanCategoryTypes);
        return {
          ...prev,
          mlovMap: map,
        };
      });
    } catch (error) {

      setComponentState((prev) => ({
        ...prev,
        formError: FormError.configurationDataAPIFail,
      }));
    }
  };

  const getHeaderRightView = (): JSX.Element | undefined => {
    if (!isMobileScreen) {
      return (
        <HStack space={2} alignItems="center">
          <Spacer />
          {componentState.showSavingIndicator && <SectionLoadingIndicator />}
        </HStack>
      );
    }
  };


  const getDeleteFunction = (item: IBarrier) => {
    // const isLocalDelete: boolean =
    //   (isPatientForm && !item.id) || contextData.isBuilderMode;

    // if (isLocalDelete) {
    //   removeFromLocal(item?.uniqueId);
    // }
    removeFromLocal(item);
  };

  const getDetailView = (): JSX.Element => {
    if (!getFilteredBarries().length) {
      return (
        <VStack paddingBottom={5} alignItems={'center'}>
          <NoBarrierSvg titleId="noBarrierList" />
          <HStack space={2} alignItems="center">
            <Spacer />
            {componentState.showSavingIndicator && <SectionLoadingIndicator />}
            {canAddNew() && !isWidgetView && getSearchComponent()}
          </HStack>
        </VStack>
      );
    }
    return (
      <VStack flex={1} space={4}>
        <VStack space={2}>
          {canAddNew() && !isWidgetView && !isMobileScreen && (
            <HStack width={'100%'} margin={3} space={2} alignItems="center">
              {getSearchComponent(true)}
            </HStack>
          )}
          <CustomListViewHealthComponent
            dataSource={getFilteredBarries()}
            getDeleteFunction={getDeleteFunction}
            isHideDelete={isWidgetView}
            isHideBorder={isWidgetView}
            isShowSubtitle={isWidgetView}
            carePlanCategoryList={carePlanCategoryList}
          />
        </VStack>
      </VStack>
    );
  };

  const onAddOrUpdateError = (error: any) => {
    const errorMessage =
      error?.response?.status === HTTP_ERROR_CODE.METHOD_NOT_ALLOWED
        ? intl.formatMessage({
            id: RESOURCE_BLOCK_MESSAGE_ID,
          })
        : error?.response?.data?.message;
    setComponentState((prev) => ({
      ...prev,
      showSavingIndicator: false,
      ...(errorMessage && {customError: errorMessage}),
      ...(!errorMessage && {nonBlockingFormError: FormError.addUpdateFail}),
    }));
  }

  const addOrUpdateRecord = (record: IBarrier) => {
    if (!isInvalidRecord(record)) {
      setComponentState((prev) => ({...prev, showSavingIndicator: true}));
      createBarrier({
        variables: {
          title: record?.title,
        },
      })
        ?.then((response) => {
          broadcastUpdateEvent();
          const keepShowingSavingIndicator = false;
          if (response?.data?.addOrUpdateBarriers?.[0].id) {
            updateDataFromAPIToList(response?.data?.addOrUpdateBarriers?.[0], record);
          }
          setComponentState((prev) => ({
            ...prev,
            ...(!keepShowingSavingIndicator && {showSavingIndicator: false}),
            nonBlockingFormError: undefined,
          }));
        })
        ?.catch((error) => {
          onAddOrUpdateError(error);
          // If new record is failed which is not saved before then only remove from local list
          if (!record.barrierId) {
            removeFromLocal(record);
          }
        });
    }
  };

  const updateDataFromAPIToList = (data: any, record: IBarrier) => {
    if (record.barrierId) return;

    setComponentValue((prev) => {
      const list = prev.barriers;
      const index = list.findIndex(
        (value) => value.uniqueId === record.uniqueId
      );
      if (index > -1) {
        list[index].barrierId = data.id;
      }
      return {
        ...prev,
        barriers: list,
      };
    });
  };

  const broadcastUpdateEvent = (viewType?: FormViewType) => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(
      ADD_UPDATE_EVENTS.BARRIER,
      viewType || contextData.componentView
    );
  };

  const fetchImportData = () => {
    if (isPatientNote && !isPreviewMode && contextData.patientId) {
      setImportFromChartLoading(true);
    }
  }

  useEffect(() => {
    const isDataAvailable = !!getFilteredBarries().length;
    contextData?.updateLoadingStatus?.(componentKeys.BARRIERS, componentState.loading, isDataAvailable);
  }, [componentState.loading]);

  useEffect(() => {
    if (componentState.editModalVisible) {
      resetDimension();
    }
  }, [componentState.editModalVisible]);

  useEffect(() => {
    const list = componentValue.barriers;
    const value = cloneDeep({barriers: list, total: list.length});
    if (contextData.isBuilderMode) {
      props.component.defaultComponentValue = value;
    }
    props.onChange(value);
  }, [componentValue]);

  props.validateRef.current = validateData;

  if (isPreviewMode) {
    return (
      <>
        {componentState.loading && (
          <View padding={4}>
            <FormComponentLoader />
          </View>
        )}
        {!componentState.loading && !componentState.formError && (
          <>
            {componentValue.barriers.length > 0 && (
              <div className={` ${isAllowToShare ? '' : 'disallow-to-share'}`}>
              <DetailPreview titleLocalId={props.component.label}>
                <FlatList
                  data={componentValue.barriers}
                  initialNumToRender={(componentValue.barriers || [])?.length}
                  keyExtractor={(item) => JSON.stringify(item)}
                  renderItem={({item}) => (
                    <div className="page-break">
                      <VStack my={0.5}>
                        <Text fontWeight={600}>{item.title}</Text>
                      </VStack>
                    </div>
                  )}
                />
              </DetailPreview>
              </div>
            )}
          </>
        )}
      </>
    );
  }

  const getFilteredBarries = () => {
    return componentValue.barriers.filter(barrier => !barrier.isDeleted);
  }

  return (
    <View ref={componentRef}>
        <div className='page-break'>
        <DetailViewCollapse
          hideArrow
          collapsible='disabled'
          btnList={[
            {
              icon: importFromChartLoading ? (
                <Spinner mr={1.5} />
              ) : (
                <Feather
                  name="download"
                  size={20}
                  color={Colors.Custom.mainPrimaryPurple}
                />
              ),
              onIconBtnClick: fetchImportData,
              label: intl.formatMessage({id: 'importFromChart'}),
              tooltipLabel: intl.formatMessage({id: 'importFromChartOverrideInfo'}),
              isCustomButton: true,
              hide: !(isPatientNote && !isPreviewMode),
              disabled: importFromChartLoading,
            }
          ]}
          textLocalId={props.component.label}
          toggled={!componentState.collapsed}
          headerStyle={{marginTop: 3}}
          extraTextStyles={
            isPatientNote ? {fontWeight: 'bold', fontSize: 16} : {}
          }
          extraPanelStyles={
            isPatientNote
              ? {borderColor: '#D0D5DD', ...CUSTOM_COMPONENT_STYLE}
              : {}
          }
          onToggled={() => {
            setComponentState((prev) => ({
              ...prev,
              collapsed: !prev.collapsed,
            }));
          }}
        >
          <VStack>
            {canAddNew() && !isWidgetView &&
              isMobileScreen &&
              getFilteredBarries().length > 0 && (
                <VStack marginY={4} space={2} flex={1} marginX={2}>
                  {getSearchComponent()}
                  {componentState.showSavingIndicator && (
                    <SectionLoadingIndicator />
                  )}
                </VStack>
              )}
            {componentState.loading && (
              <View padding={4}>
                <FormComponentLoader />
              </View>
            )}
            {!componentState.loading && componentState.customError && (
                 <FormComponentError error={FormError.custom} customError={componentState.customError} />
               )}
            {!componentState.loading && componentState.formError && (
              <FormComponentError error={componentState.formError} />
            )}
            {!componentState.loading && componentState.nonBlockingFormError && (
              <FormComponentError error={componentState.nonBlockingFormError} />
            )}
            {!componentState.loading &&
              !componentState.formError &&
              getDetailView()}
          </VStack>
        </DetailViewCollapse>
        </div>
      <Drawer
        title={
          <>
          <ModalActionTitle
            isHeadNotSticky
            title={'Barriers'}
            buttonList={[
              {
                onClick: () => {
                  setComponentState((prev) => ({
                    ...prev,
                    editModalVisible: false,
                  }));
                },
                show: true,
                id: 1,
                btnText: intl.formatMessage({
                  id: 'close',
                }),
                size: 'sm',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
              },
            ]}
          />
          </>
        }
        destroyOnClose
        placement="right"
        onClose={() => {
          setComponentState((prev) => ({...prev, editModalVisible: false}));
        }}
        open={componentState.editModalVisible}
        closable
        width="50%"
      >
        <DetailViewCollapse
          btnList={[]}
          textLocalId={props.component.label}
          toggled={!componentState.editModalCollapse}
          headerRightView={getHeaderRightView()}
          onToggled={() => {
            setComponentState((prev) => ({
              ...prev,
              editModalCollapse: !prev.editModalCollapse,
            }));
          }}
        >
          <VStack ref={drawerDetailRef}>
            {canAddNew() &&
              isMobileScreen &&
              getFilteredBarries().length > 0 && (
                <VStack marginY={4} space={2} flex={1} marginX={2}>
                  {getSearchComponent()}
                  {componentState.showSavingIndicator && (
                    <SectionLoadingIndicator />
                  )}
                </VStack>
              )}
            {componentState.loading && <FormComponentLoader />}
            {!componentState.loading && componentState.customError && (
              <FormComponentError error={FormError.custom} customError={componentState.customError} />
            )}
            {!componentState.loading && componentState.formError && (
              <FormComponentError error={componentState.formError} />
            )}
            {!componentState.loading && componentState.nonBlockingFormError && (
              <FormComponentError error={componentState.nonBlockingFormError} />
            )}
            {!componentState.loading &&
              !componentState.formError &&
              getDetailView()}
          </VStack>
        </DetailViewCollapse>
      </Drawer>
    </View>
  );
};

export default AddOrUpdateBarriers;
