import { useQuery } from '@apollo/client';
import { Select as AntSelect, Spin } from 'antd';
import { FormControl, HStack, Radio, Select, Text, VStack, View, WarningOutlineIcon } from 'native-base';
import { useContext, useState } from 'react';
import { MLOV_CATEGORY } from '../../../../constants';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../constants/Configs';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import TaskPoolQueries from '../../../../services/TaskPool/TaskPoolQueries';
import { getMlovId, getMlovListFromCategory } from '../../../../utils/mlovUtils';
import { ParticipantType } from '../../../common/CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import UserAutoComplete, { IUserSearch } from '../../../common/CalendarWidget/UserAutoComplete/UserAutoComplete';
import { IUserPool, IUserPoolUsers, TaskPoolAndUserSearch } from '../../../common/Tasks/TaskPool/TaskPoolAndUserSearch';
import { IInputElement } from '../FlowComponent/StateNodes/FlowNodeInterface';

interface IComponentState {
  value: {
    selectedAssignToCode?: string;
    selectedAssigneeRoleId?: string;
    selectedTaskPoolId?: string;
    selectedAssigneeId?: string;
    member?: IUserSearch;
  };
  accountTaskPools?: any[];
}

const assignToTypeCodes = {
  USER_ROLE: 'USER_ROLE',
  TASK_POOL: 'TASK_POOL',
  SELECT_USER: 'SELECT_USER'
}

export function AssignToUserRoleOrTaskPoolField(props: IInputElement, allowToSelectUser?: boolean) {
  const [ componentState, setComponentState ] = useState<IComponentState>({
    value: {
      selectedAssignToCode: props.value?.assignToCode,
      selectedAssigneeRoleId: props.value?.assigneeRoleId,
      selectedTaskPoolId: props.value?.taskPoolId,
      selectedAssigneeId: props.value?.assigneeId,
      member: props?.value?.member,
    },
  });

  const mlovData = useContext(CommonDataContext);
  const userRoles = getMlovListFromCategory(mlovData.MLOV, MLOV_CATEGORY.USER_ROLES) || [];

  const taskPoolTypeId = getMlovId(mlovData.CARE_STUDIO_MLOV, 'UserPoolType', 'task_user_pool');

  const { loading: isGetTaskPoolAPILoading } = useQuery(TaskPoolQueries.GetTaskPool, {
    variables: {
      params: {
        userPoolTypeId: taskPoolTypeId,
      },
    },
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setComponentState((prev) => {
        return {
          ...prev,
          accountTaskPools: data?.getUserPools?.userPools,
        };
      });
    },
    onError: (error) => {
    },
  });

  function isFormValid(selectedAssignToCode?: string, selectedAssigneeRoleId?: string, selectedTaskPoolId?: string, member?: IUserSearch) {
    if (!selectedAssignToCode) return false;

    if (selectedAssignToCode === assignToTypeCodes.USER_ROLE && !selectedAssigneeRoleId) {
      return false;
    }

    if (selectedAssignToCode === assignToTypeCodes.TASK_POOL && !selectedTaskPoolId) {
      return false;
    }

    if (selectedAssignToCode === assignToTypeCodes.SELECT_USER && (!member || !member?.value)) {
      return false;
    }

    return true;
  }

  function onChange(selectedAssignToCode?: string, selectedAssigneeRoleId?: string, selectedTaskPoolId?: string, member?: IUserSearch) {
    if (!isFormValid(selectedAssignToCode, selectedAssigneeRoleId, selectedTaskPoolId, member)) {
      return props.onChange(null, '');
    }

    const value = {
      assignToCode: selectedAssignToCode,
      assigneeRoleId: selectedAssigneeRoleId,
      taskPoolId: selectedTaskPoolId,
      member: member,
      assigneeId: member?.value,
    };

    return props.onChange(value, '');
  }

  return (
    <VStack space={1}>
      <View>
        <FormControl
          isInvalid={props.isShowError && !componentState.value.selectedAssignToCode}
          flex={1}
        >
          <Radio.Group
            name="frequencyType"
            value={componentState.value.selectedAssignToCode}
            onChange={(selectedAssignToCode) => {
              setComponentState((prev) => ({
                ...prev,
                value: {
                  ...prev.value,
                  selectedAssignToCode: selectedAssignToCode,
                  selectedAssigneeRoleId: undefined,
                  selectedTaskPoolId: undefined,
                }
              }));

              onChange(selectedAssignToCode, undefined, undefined);
            }}
          >
            <HStack space={2}>
              <Radio value={assignToTypeCodes.USER_ROLE} marginY={1}>
                <Text marginLeft={2}>Specific Role In Care Journey Team</Text>
              </Radio>

              <Radio value={assignToTypeCodes.TASK_POOL} marginY={1}>
                <Text marginLeft={2}>Task Pool</Text>
              </Radio>

              {allowToSelectUser && (
                <Radio value={assignToTypeCodes.SELECT_USER} marginY={1}>
                  <Text marginLeft={2}>Select User</Text>
                </Radio>
              )}
            </HStack>
          </Radio.Group>
          <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
            Please select assign to option
          </FormControl.ErrorMessage>
        </FormControl>
      </View>

      {
        componentState.value.selectedAssignToCode === assignToTypeCodes.USER_ROLE &&
        <View marginTop={2}>
          <FormControl
            isInvalid={props.isShowError && !componentState.value.selectedAssigneeRoleId}
            flex={1}
          >
              <AntSelect
                style={{ height: '40px', fontSize: '13px' }}
                placeholder="Select user role"
                value={componentState.value.selectedAssigneeRoleId || undefined}
                onChange={(selectedAssigneeRoleId) => {
                  setComponentState((prev) => ({
                    ...prev,
                    value: {
                      ...prev.value,
                      selectedAssigneeRoleId: selectedAssigneeRoleId,
                    },
                  }));
                  onChange(componentState.value.selectedAssignToCode, selectedAssigneeRoleId, componentState.value.selectedTaskPoolId);
                }}
              >
                {userRoles?.map((data: any) => (
                  <AntSelect.Option key={data.id} label={data.value} value={data.id}>
                    {data.value}
                  </AntSelect.Option>
                ))}
              </AntSelect>
          </FormControl>
        </View>
      }

      {
        componentState.value.selectedAssignToCode === assignToTypeCodes.TASK_POOL &&
        <View marginTop={2}>
          {/* <AntSelect
            size="large"
            showSearch={false}
            allowClear
            filterOption={false}
            value={componentState.accountTaskPools?.length && componentState.value.selectedTaskPoolId ? [ componentState.value.selectedTaskPoolId ] : []}
            onChange={(value: any[], data: any) => {
              setComponentState((prev) => ({
                ...prev,
                value: {
                  ...prev.value,
                  selectedTaskPoolId: data?.value || undefined,
                },
              }));
              onChange(componentState.value.selectedAssignToCode, componentState.value.selectedAssigneeRoleId, data?.value || undefined);
            }}
            placeholder="Select task pool"
            loading={isGetTaskPoolAPILoading}
            notFoundContent={isGetTaskPoolAPILoading && <Spin size="small" />}
            style={{height: '40px'}}
            className={props.isShowError && !componentState.value.selectedTaskPoolId ? 'field-error' : ''}
          >
            {componentState.accountTaskPools?.map((item, index) => {
              return (
                <AntSelect.Option key={item.id} value={item.id}>
                  <Text>{item.name}</Text>
                </AntSelect.Option>
              );
            })}
          </AntSelect> */}
          <TaskPoolAndUserSearch
            assignToCode={componentState.value.selectedAssignToCode}
            {...props}
          />
        </View>
      }

      {
        componentState.value.selectedAssignToCode === assignToTypeCodes.SELECT_USER &&
        <View marginTop={2}>
          <UserAutoComplete
            selectedValue={props.value?.member}
            isDisabled={false}
            onChange={(user) => {
              if (user) {
                const member = { key: user?.key, email: user?.data?.email, label: user?.label, value: user?.value, type: ParticipantType.staff };
                onChange(componentState.value.selectedAssignToCode, componentState.value.selectedAssigneeRoleId, componentState.value.selectedTaskPoolId, member);
              } else {
                onChange(componentState.value.selectedAssignToCode, componentState.value.selectedAssigneeRoleId, componentState.value.selectedTaskPoolId, undefined);
              }
            }}
          />

        </View>
      }
    </VStack>
  );
}
