import {useLazyQuery} from "@apollo/client";
import {useEffect,useState} from "react";
import {Text,View} from "react-native";
import ConversationsQueries from "../../../services/Conversations/ConversationsQueries";
import {Colors} from "../../../styles";
import {INVITE_TYPE_VALUE} from "../MeetingView/MeetingViewConst";
import {IContact} from "../MeetingView/interface";
import {useIntl} from "react-intl";
import {Skeleton} from "native-base";
import { getAllowedUserPracticeLocationUuids, getUserUUID } from "../../../utils/commonUtils";
import { CONFIG_CODES } from "../../../constants/AccountConfigConst";
import { isAccountConfigEnabled } from "../../../utils/configUtils";
import { USER_ACCESS_PERMISSION } from "../../RightSideContainer/UserAccess/UserAccessPermission";
import { MAIN_MENU_CODES } from "../../SideMenuBar/SideBarConst";

export const ContactLinkedWithView = (props: {
  linkedWith: string,
  contactData: {
    email?: string,
    phoneNumber?: string,
    contactId: string
  }
})=> {
  const {linkedWith, contactData} = props;
  const userUuid = getUserUUID();
  const [stateData, setStateData] = useState({
    contactList: [] as IContact[],
    loading: false as boolean,
  });
  const [, , , , lastContactName] = stateData?.contactList;
  const intl = useIntl();
  const isCommunicationLocationHandlingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_COMMUNICATION_LOCATION_HANDLING
  );
  const allowedUserPracticeLocationUuids = getAllowedUserPracticeLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.INBOX
  );

  const [getMemberByContact] = useLazyQuery(
    ConversationsQueries.getMemberByContact,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const getMembersDataForEmailAndSms = async  () => {
    setStateData((prev)=>{
      return {
        ...prev,
        loading: true
      }
    })
    let whereObject: any = {
      isDeleted: {_eq: false},
      id: {_neq: contactData?.contactId},
    };
    if (linkedWith === INVITE_TYPE_VALUE.SMS) {
       whereObject = {
        ...whereObject,
        phoneNumber: {_eq: contactData?.phoneNumber},
       };
    } else if (linkedWith === INVITE_TYPE_VALUE.EMAIL) {
      whereObject = {
        ...whereObject,
        email: {_eq: contactData?.email},
       };
    }
    if (isCommunicationLocationHandlingEnabled) {
      whereObject = {
        ...whereObject,
        contactPracticeLocations: {
          practiceLocationUuid: {
            _in: allowedUserPracticeLocationUuids,
          },
        },
      };
    }
    if (contactData?.email || contactData?.phoneNumber) {
      const response = await getMemberByContact({
        variables: {
          where: whereObject
        }
      })
      setStateData((prev)=> {
        return {
          ...prev,
          contactList: response?.data?.contacts || [],
          loading: false
        }
      })
    } else {
      setStateData((prev)=>{
        return {
          ...prev,
          loading: false
        }
      })
    }
  }

  const getContactsName = ()=> {
    let contactsName = '';
    if (stateData?.contactList?.length) {
      stateData?.contactList?.forEach((contact: any, index: number)=> {
        contactsName = contactsName + " " + contact?.name;
        if (index !== stateData?.contactList?.length -1) {
          contactsName = contactsName + ", ";
        }
      })
    }
    return contactsName.trim();
  }

  useEffect(()=>{
    if (contactData?.email || contactData?.phoneNumber) {
      getMembersDataForEmailAndSms();
    }
  },[])

  return (<>
    {stateData?.loading ? <Skeleton.Text lines={1} />:
    <View>
      {stateData?.contactList?.length ? <View>
        <Text style={{fontSize: 14, lineHeight: 21, fontWeight: '400'}}>
          {intl.formatMessage({id: 'linkedWith'})}{" "}
          <Text style={{color: Colors.Custom.mainPrimaryPurple}}>
            {getContactsName()}
          </Text>
          {(stateData?.contactList?.length > 1 && lastContactName?.name) && <>
            <Text>{' and '}</Text>
            <Text style={{color: Colors.Custom.mainPrimaryPurple}}>
              {lastContactName.name}
            </Text>
          </>}
        </Text>
      </View> : <></>}
    </View>}
  </>)
}
