import {Pressable, View, Text} from "react-native"
import {PREFERENCES_TAB_LIST, PREFERENCES_TAB_LIST_CODE} from "./PreserenceConst"
import {COMMON_ACTION_CODES} from "../../../constants/ActionConst"
import {Colors} from "../../../styles"
import {useState} from "react"
import PreferencesProfileSvg from "../Svg/PreferencesProfileSvg"
import PreferencesNotificationSvg from "../Svg/PreferencesNotificationSvg"
import PreferencesEmailSettingsSvg from "../Svg/PreferencesEmailSettingsSvg"
import {IPreferenceSideTabList} from "./PreferencesInterface"
import {isEmployerRole, isMasterAccount} from "../../../utils/commonUtils"
import PreferencesAutoReplySvg from "../Svg/PreferencesAutoReplySvg"

export const PreferenceSideTabList = (props: IPreferenceSideTabList) => {
  const [pressedIndex, setPressedIndex] = useState<number | null>(null);
  const isEmployer = isEmployerRole();
  const isMasterAccountFlag = isMasterAccount();

  const showBackground = (tab: any, index: number) => {
    if (props?.selectedPreferenceTab?.code === tab?.code) {
      return Colors.Custom.Gray50;
    } else if (pressedIndex === index) {
      return Colors.Custom.Gray50;
    }
    return;
  }

  const getPreferenceIcon = (tabCode: string) => {
    switch(tabCode) {
      case PREFERENCES_TAB_LIST_CODE.ACCOUNT_AND_PROFILE:
        return <PreferencesProfileSvg/>
      case PREFERENCES_TAB_LIST_CODE.EMAIL_SETTINGS:
        return <PreferencesEmailSettingsSvg/>
      case PREFERENCES_TAB_LIST_CODE.NOTIFICATION_SETTING:
        return <PreferencesNotificationSvg/>
      case PREFERENCES_TAB_LIST_CODE.AUTO_REPLY:
        return <PreferencesAutoReplySvg/>
      default :
       return <></>;
    }
  }

  const filteredPreferenceTabList = () => {
    const hideNotification = isEmployer || isMasterAccountFlag;
    if (hideNotification) {
      return PREFERENCES_TAB_LIST?.filter((list) => {
        return list?.code !== PREFERENCES_TAB_LIST_CODE.NOTIFICATION_SETTING
      });
    }
    return PREFERENCES_TAB_LIST;
  }

  return <>
    <View>
      <View style={{
        flexDirection: 'column'
      }}>
        {filteredPreferenceTabList()?.map?.((tab, index) => {
          return (
            <div
              onMouseEnter={() => {
                setPressedIndex(index);
              }}
              onMouseLeave={() => {
                setPressedIndex(null);
              }}
              style={{
                backgroundColor: showBackground(tab, index),
                marginTop: 2,
                marginBottom: 2,
                borderRadius: 4,
              }}
            >
              <Pressable
                onPress={() => {
                  props?.onActionPerformed(COMMON_ACTION_CODES.SELECTED_PREFERENCE_TAB_CHANGE, tab);
                }}
                onFocus={() => {
                  setPressedIndex(index)
                }}
              >
                <View style={{
                  paddingHorizontal: 16,
                  paddingVertical: 12,
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                  {getPreferenceIcon(tab?.code)}
                  <Text style={{
                    padding: 4,
                    marginLeft: 4,
                    fontWeight: '400',
                    lineHeight: 16.8,
                    color: Colors.FoldPixel.GRAY300
                  }}>
                    {tab?.title}
                  </Text>
                </View>
              </Pressable>
            </div>
          )
        })}
      </View>
    </View>
  </>
}