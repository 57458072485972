import {HStack, Input, View, Text} from 'native-base';
import {IAddSubTask, ISubTasks} from '../interfaces';
import {Colors} from '../../../../styles/Colors';
import {Button, Checkbox, DatePicker, Modal, Popover} from 'antd';
import CalendarSvg from '../../Svg/SideMenu/CalendarSvg';
import DeleteButtonSvg from '../../Svg/DeleteButtonSvg';
import AddUserSvg from '../../Svg/AddUserSvg';
import {ActivityIndicator, Pressable} from 'react-native';
import {ADD_SUBTASK_CONST} from '../AddTaskUtils';
import {useContext, useEffect, useState} from 'react';
import './CustomDatePicker.css';
import {BUTTON_TYPE, DATE_FORMATS, GROUP_MEMBER_TYPE} from '../../../../constants';
import {
  getCurrentTimeZone,
  getDateStrFromFormat,
  getDateToMomentISOString,
  getMomentObj,
  getMomentObjFromFormat,
  getStartOfDayOfTimezone,
} from '../../../../utils/DateUtils';
import UserAutoComplete from '../../CalendarWidget/UserAutoComplete/UserAutoComplete';
import {IUserSearch} from '../../../PersonOmniView/RightContainer/CareTeamView/interfaces';
import {IUserPatientSearchItem} from '../../CustomComponents/CustomUserPatientSearch/CustomUserPatientSearch.native';
import PatientSearchAndSelect from '../../PatientSearch/PatientSearchAndSelect';
import {IAccountData} from '../../../RightSideContainer/Contacts/TeamMembers/EditAccount/interface';
import CalendarPlanSvg from '../../Svg/SideMenu/CalendarPlanSvg';
import UserSvg from '../../Svg/UserSvg';
import { FoldButton } from '../../../CommonComponents/FoldButton/FoldButton';
import { useIntl } from 'react-intl';
import { IStateForHandlingSubTaskAssigneeChange } from '../../CareDashboard/CareDashboardInterfaces';
import { DisplayCardAvatar } from '../../DisplayCard/DisplayCardAvatar';
import { MAIN_MENU_CODES } from '../../../SideMenuBar/SideBarConst';
import FeatureFlags from '../../../../constants/FeatureFlags.enums';
import { getBooleanFeatureFlag } from '../../../../utils/commonUtils';
import { CommonDataContext } from '../../../../context/CommonDataContext';
const AddSubTasks = (props: IAddSubTask) => {
  const {
    subTaskItem,
    isDisable,
    handleSubTaskChange,
    isEmployer,
    taskPoolData,
    accountDetails,
    value,
    employerId,
    subTaskLoading,
    isEditTask,
    emptyTaskPoolField,
    task
  } = props;
  const intl = useIntl();
  const mlovData = useContext(CommonDataContext);
  const isMultiTenancyEnabled = getBooleanFeatureFlag(mlovData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openSubTaskAssignee, setOpenSubTaskAssignee] = useState(false);
  const [subTaskTitle, setSubTaskTitle] = useState(subTaskItem?.title || '');
  const [loading, setLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  useEffect(()=> {
    if (!props?.subTaskLoading) {
      setLoading(props?.subTaskLoading || false);
    }
  }, [subTaskLoading]);

  useEffect(() => setSubTaskTitle(subTaskItem?.title || ''),[subTaskItem?.title]);
  const [stateForHandlingSubTaskAssigneeChange, setStateForHandlingSubTaskAssigneeChange] = useState<IStateForHandlingSubTaskAssigneeChange>({
    showAlertOfResetTaskPool: false,
  })

  const GetDivider = (props: {height?: number}) => {
    return (
      <View
        style={{
          width: 1.5,
          height: props.height || 16,
          marginHorizontal: 8,
          backgroundColor: Colors.Custom.Gray400,
        }}
      />
    );
  };

  const handleSubTaskAssigneeChange = (selectedUser: IUserPatientSearchItem, closeModal: boolean) => {
    const updateAssignee = {
      ...subTaskItem,
      assignee: selectedUser,
    };
    handleSubTaskChange?.(
      ADD_SUBTASK_CONST.CHANGE_ASSIGNEE,
      updateAssignee
    );
    setOpenSubTaskAssignee(false);
    if (closeModal) {
      setStateForHandlingSubTaskAssigneeChange((prev) => {
        return {
          ...prev,
          showAlertOfResetTaskPool: false,
        }
      })
    }
  }

  const renderSubTaskAssigneeTo = (subTaskItem: ISubTasks) => (
    <>
      <View style={{width: '100%'}}>
        {isEmployer ? (
          <UserAutoComplete
            labelText={'assignee'}
            selectedValue={subTaskItem.assignee as IUserSearch}
            excludeOtherEmployers={true}
            onChange={(selectedUser) => {
              if (subTaskItem?.id) {
                setLoading(true);
              }
              const updateAssignee = {
                ...subTaskItem,
                assignee: selectedUser as IUserPatientSearchItem,
              };
              handleSubTaskChange?.(
                ADD_SUBTASK_CONST.CHANGE_ASSIGNEE,
                updateAssignee
              );
              setOpenSubTaskAssignee(false);
            }}
            usersData={isEmployer ? taskPoolData?.accountUsers : []}
            allowClear={false}
          />
        ) : (
          <PatientSearchAndSelect
            isDisabled={isMultiTenancyEnabled && !value?.selectedAccountLocations}
            accountLocationUuids={value?.selectedAccountLocations?.map((location) => location?.uuid)}
            resourceType={MAIN_MENU_CODES.TASKS}
            isRequired={true}
            showUserPendingTasksCount={true}
            labelColor={Colors.Custom.Gray500}
            showCurrentUserAtTop={taskPoolData?.selectedPool?.id ? false : true}
            isInvalid={props?.isInvalid}
            showError={props?.isInvalid}
            label={'Assign to'}
            isProspect={true}
            disableLeadsSearch
            disablePatientSearch
            showEmail
            showErrorBorder={true}
            placeholder="Search Staff or Member"
            value={subTaskItem.assignee}
            poolSelected={value?.taskPool?.key ? true : false}
            selectedPoolUsers={taskPoolData?.selectedPoolUsers}
            defaultAssigneeInSelectedPool={taskPoolData?.defaultAssignee}
            onChange={(participants: any, data: any) => {
              const contactIdValue =
                participants?.label?.props?.contactData?.uuid ||
                participants?.value;
              const contactIdKey = contactIdValue || participants?.key;
              const selectedUser = {
                key: contactIdKey,
                value: contactIdValue,
                label: participants?.label?.props?.contactData?.name,
                type: data?.type,
                details: participants?.label?.props?.contactData,
              }
              const isSelectedUserInSelectedTaskPool = taskPoolData?.selectedPool?.userPoolUsers?.some((user: any) => user.userId === contactIdKey)
              if (!taskPoolData?.selectedPool || !selectedUser?.key || isSelectedUserInSelectedTaskPool) {
                if (subTaskItem?.id && isEditTask  && task?.assigneeTypeCode !== 'CONTACT') {
                  setLoading(true);
                }
                handleSubTaskAssigneeChange(selectedUser as IUserPatientSearchItem, false)
              }
              else {
                setStateForHandlingSubTaskAssigneeChange((prev) => {
                  return {
                    ...prev,
                    showAlertOfResetTaskPool: true,
                    temporarySelectedUser: selectedUser
                  }
                })
              }
            }}
            employerId={employerId}
            allowClear={false}
          />
        )}
      </View>
    </>
  );

  return (
    <HStack marginTop={2}>
      <View
        key={subTaskItem?.id || subTaskItem?.tempId}
        flex={1}
        flexDirection={'row'}
        alignItems={'center'}
        width={'100%'}
        borderColor={Colors.Custom.Gray200}
        borderRadius={8}
        borderWidth={1}
      >
        {!isDisable && (
          <View marginRight={2} marginLeft={2}>
            <Checkbox
              disabled={isDisable || false}
              defaultChecked={subTaskItem.isChecked}
              checked={subTaskItem.isChecked}
              key={subTaskItem.id}
              className="copy-checkbox"
              onChange={(item) => {
                if (subTaskItem?.id) {
                  setLoading(true);
                }
                handleSubTaskChange?.(
                  ADD_SUBTASK_CONST.MARK_COMPLETE,
                  subTaskItem
                );
              }}
            />
          </View>
        )}
        <Input
          key={subTaskItem?.id || subTaskItem?.tempId}
          flex={1}
          alignContent={'center'}
          isDisabled={isDisable || false}
          multiline
          borderWidth={0}
          placeholderTextColor={Colors.Custom.Gray300}
          color={Colors.FoldPixel.GRAY400}
          bgColor={'white'}
          borderRadius={4}
          borderColor={Colors.Custom.alertsDescriptionColor}
          height={44}
          paddingLeft={isDisable ? 0 : 'none'}
          placeholder={'Enter subtask details'}
          fontWeight={400}
          fontSize={14}
          value={subTaskTitle}
          onChangeText={(value) => {
            setSubTaskTitle(value);
          }}
          onFocus={handleInputFocus}
          onBlur={(event) => {
              if (subTaskTitle?.length && subTaskItem.title !== subTaskTitle) {
                if (subTaskItem?.id || isEditTask && task?.assigneeTypeCode !== 'CONTACT') {
                  setLoading(true);
                }
                const updateTitle = {
                  ...subTaskItem,
                  title: subTaskTitle,
                };
                handleSubTaskChange?.(
                  ADD_SUBTASK_CONST.UPDATE_TITLE,
                  updateTitle
                );
              }
              setIsFocused(false);
          }}
          // InputRightElement={<>
          //   {subTaskItem?.status?.value && subTaskItem?.status?.value === 'Missed' && <View backgroundColor={ Colors.Custom.Gray100} borderRadius={12} px={2} py={0.5}>
          //     <Text color={Colors.Custom.Gray700}>{subTaskItem?.status?.value}</Text>
          //   </View>}
          // </>}
        />
        <HStack
          marginRight={3}
          marginLeft={2}
          justifyContent={'flex-end'}
          alignItems={'center'}
        >
          <Popover
            overlayInnerStyle={{
              bottom: 3,
              borderRadius: 12,
              padding: 0,
              width: 450,
            }}
            content={renderSubTaskAssigneeTo(subTaskItem)}
            trigger="click"
            placement="bottomLeft"
            visible={openSubTaskAssignee}
            onVisibleChange={() => {
              setOpenSubTaskAssignee(false);
            }}
            overlayStyle={{borderRadius: 8}}
          >
            <Pressable
              disabled={isDisable || false}
              onPress={() => {
                setOpenSubTaskAssignee(!openSubTaskAssignee);
              }}
            >
              
              <View
                paddingLeft={
                  subTaskItem?.assignee?.value?.length ? 2 : undefined
                }
                paddingRight={
                  subTaskItem?.assignee?.value?.length ? 2 : undefined
                }
                paddingTop={
                  subTaskItem?.assignee?.value?.length ? 0.5 : undefined
                }
                paddingBottom={
                  subTaskItem?.assignee?.value?.length ? 0.5 : undefined
                }
              >
                <DisplayCardAvatar
                  avatarStyle={{
                    avatarSize: '7',
                    textStyle: {
                      fontSize: 12,
                      fontWeight: '500'
                    }
                  }}
                  isLetterAvatarShow={true}
                  userData={{
                    userType: GROUP_MEMBER_TYPE.USER,
                    userId: subTaskItem?.assignee?.key || -1,
                    userName: subTaskItem.assignee?.label
                  }}
                  hideOnlineIndicator
                />
              </View>
            </Pressable>
          </Popover>

          <Pressable
            style={{marginLeft: 2}}
            disabled={isDisable || false}
            onPress={() => {
              setOpenDatePicker(true);
            }}
          >
            <HStack
              backgroundColor={
                subTaskItem?.endDateTime?.length
                  ? Colors.Custom.Gray100
                  : undefined
              }
              borderColor={subTaskItem?.endDateTime?.length ? Colors.FoldPixel.GRAY200: undefined}
              borderRadius={subTaskItem?.endDateTime?.length ? 4 : undefined}
              paddingLeft={subTaskItem?.endDateTime?.length ? 2 : undefined}
              paddingRight={subTaskItem?.endDateTime?.length ? 2 : undefined}
              paddingTop={subTaskItem?.endDateTime?.length ? 1 : undefined}
              paddingBottom={subTaskItem?.endDateTime?.length ? 1 : undefined}
              borderWidth={.5}
            >
              {subTaskItem?.endDateTime?.length && (
                <Text color={Colors.FoldPixel.GRAY300}>
                  {getDateStrFromFormat(
                    subTaskItem?.endDateTime,
                    DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT
                  )}
                </Text>
              )}
            </HStack>
          </Pressable>
          {!isDisable && !isFocused && (
            <>
              <GetDivider />
              <Pressable
                disabled={loading}
                onPress={() => {
                  setLoading(true);
                  handleSubTaskChange?.(ADD_SUBTASK_CONST.DELETE, subTaskItem);
                }}
              >
                <View pl={1} size={18}>
                  {loading ?
                  <ActivityIndicator color={Colors.Custom.PrimaryColor} />
                  :
                  <DeleteButtonSvg customStrokeColor={Colors.Custom.Gray400} />
                  }
                </View>
              </Pressable>
            </>
          )}
        </HStack>
      </View>
      {openDatePicker && (
        <DatePicker
          disabledDate={(() => {
            const currentDate =
              value?.dueDate ?
                getMomentObjFromFormat(value.dueDate, DATE_FORMATS.DISPLAY_DATE_FORMAT).toDate()
                : new Date();
            const endOfCurrentDate = getStartOfDayOfTimezone(currentDate, getCurrentTimeZone()).endOf('day').valueOf();
            return (date) => {
              return !!(date?.valueOf() && date.valueOf() > endOfCurrentDate);
            }
          })()}
          open={openDatePicker}
          className="my-class"
          format={DATE_FORMATS.DISPLAY_DATE_FORMAT}
          value={
            getMomentObj(subTaskItem?.endDateTime || '')?.isValid()
              ? getMomentObj(subTaskItem.endDateTime || '')
              : undefined
          }
          onOpenChange={() => {
            setOpenDatePicker(false);
          }}
          onChange={(date: any, formatStrings: string) => {
            if (formatStrings && formatStrings.length > 1) {
              if (subTaskItem?.id) {
                setLoading(true);
              }
              setOpenDatePicker(false);
              const updatedItem = {
                ...subTaskItem,
                endDateTime: getMomentObjFromFormat(formatStrings, DATE_FORMATS.DISPLAY_DATE_FORMAT).endOf('day').toISOString(),
              };
              handleSubTaskChange?.(ADD_SUBTASK_CONST.DUE_DATE, updatedItem);
            }
          }}
        />
      )}
      <Modal
        title="Change Task Pool" open={stateForHandlingSubTaskAssigneeChange.showAlertOfResetTaskPool}
        onCancel={() => {
          setStateForHandlingSubTaskAssigneeChange((prev) => {
            return {
              ...prev,
              showAlertOfResetTaskPool: false,
            }
          })
        }}
        footer={null}
        bodyStyle={{ paddingTop: 12 }}
      >
        <View>
          <Text size={'mdMedium'} >{`Subtask assignee chosen is not part of ${taskPoolData?.selectedPool?.label}. Going ahead will reset the task pool. Continue?`}</Text>
          <Button.Group style={{ marginTop: 12 }}>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                style: {margin: 3},
                onPress: () => {
                  setStateForHandlingSubTaskAssigneeChange((prev) => {
                    return {
                      ...prev,
                      showAlertOfResetTaskPool: false,
                    }
                  })
                },
              }}
              customProps={{
                btnText: 'No',
                withRightBorder: false,
              }}
            ></FoldButton>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                style: {margin: 3},
                onPress: () => {
                  if (stateForHandlingSubTaskAssigneeChange.temporarySelectedUser) {
                    if (isEditTask && task?.assigneeTypeCode !== 'CONTACT') {
                      setLoading(true)
                    }
                    const closeModal = true
                    handleSubTaskAssigneeChange(stateForHandlingSubTaskAssigneeChange.temporarySelectedUser, closeModal)
                    emptyTaskPoolField?.()
                  }
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'Yes',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          </Button.Group>
        </View>
      </Modal>
    </HStack>
  );
};

export default AddSubTasks;
