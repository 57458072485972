import {Spinner, useToast, View} from 'native-base';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {Dimensions} from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {useNavigate, useParams} from 'react-router-dom';
import {Colors} from '../../../../styles';
import {showToast, ToastType} from '../../../../utils/commonViewUtils';
import {TableTopBar} from '../../../common/TableTopBar';
import TitleSubtitleView from '../../../common/TitleSubtitleView/TitleSubtitleView';
import {ContentTypes} from '../ContentManagementConsts';
import {
  createTemplate,
  getTemplateById,
  updateTemplate,
} from '../ContentManagementUtils';
import {IPEDynamicZone} from './interfaces';
import CreatePEView from './PatientEducationCreator/CreatePEView';
import {TopCard} from './PatientEducationCreator/TopCard';
import {styles} from './PatientEducationStyle';
import {
  getPatientEducationObject,
  getSingleFormattedPEContent,
  getTitleString,
  isEmptyHtml,
  patientEduValidation,
} from './PatientEducationUtils';
import Feather from 'react-native-vector-icons/Feather';
import {BUTTON_TYPE} from '../../../../constants';
import {COMPONENT_TYPES} from './PatientEducationConsts';
import { CommonDataContext } from '../../../../context/CommonDataContext';

const PatientEducation = (props: {
  id?: string;
  isHideTopTab?: boolean;
  onClose?: any;
  onUpdate?: any;
}) => {
  const topbarRef = useRef<any>(null);
  const creatorRef = useRef<any>(null);
  const componentContainerRef = useRef<any>(null);
  const commonData = useContext(CommonDataContext);
  const isSideCarContext = !!commonData?.sidecarContext?.isSidecar;

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [readTime, setReadTime] = useState<string>('');
  const [dynamicZoneData, setDynamicZoneData] = useState<IPEDynamicZone[]>([]);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState<any>({});

  const dynamicZoneDataRef = useRef<IPEDynamicZone[]>([]);

  const toast = useToast();

  const errorHandler = () => {
    setSubmitting(false);
    showToast(
      toast,
      'Something went wrong! Please check if you added media to media component',
      ToastType.error
    );
  };

  let id = props.id;
  if (!id) {
    const params = useParams();
    id = params.id;
  }

  const navigate = useNavigate();

  const handleAdd = () => {
    const postObject = getPatientEducationObject({
      title: title,
      description: description,
      dynamicZone: dynamicZoneData,
      readTime: readTime,
      id: id ? id : '',
    });

    const {errors, validated} = patientEduValidation({
      title,
      description,
    })
    setErrors(errors);
    if (validated) {
      setSubmitting(true);
      if (id && id.toString() !== '-1') {
        updateTemplate(
          ContentTypes.patientEducationArticles.path,
          id,
          postObject
        )
          .then((response) => {
            setSubmitting(false);
            if (props.onUpdate) {
              props.onUpdate(response);
            } else {
              navigate('/admin/contentManagement/patientEducation');
            }
          })
          .catch((error) => {
            errorHandler();

          });
      } else {
        createTemplate(ContentTypes.patientEducationArticles.path, postObject)
          .then((response) => {
            setSubmitting(false);
            if (props.onUpdate) {
              props.onUpdate(response);
            } else {
              navigate('/admin/contentManagement/patientEducation');
            }
          })
          .catch((error) => {
            errorHandler();

          });
      }
    } else {
    }
  };

  const handleCancel = () => {
    if (props.onClose) {
      props.onClose();
    } else {
      navigate('/admin/contentManagement/patientEducation');
    }
  };

  useEffect(() => {
    if (
      creatorRef.current &&
      topbarRef.current &&
      componentContainerRef.current
    ) {
      const creatorHeight =
        componentContainerRef.current.offsetHeight -
        topbarRef.current.offsetHeight;
      creatorRef.current.style.height = `${creatorHeight}px`;
    }

    if (id) {
      setLoading(true);
      getTemplateById(ContentTypes.patientEducationArticles.path, id)
        .then((data) => {
          const singlePEContent = getSingleFormattedPEContent(data);
          setTitle(singlePEContent.title);
          setDescription(singlePEContent.description);
          setDynamicZoneData(singlePEContent.dynamicZone);
          dynamicZoneDataRef.current = singlePEContent.dynamicZone;
          setReadTime(singlePEContent?.readTime as string);
        })
        .catch((error) => {});
      setLoading(false);
    }

    window.addEventListener('beforeunload', beforeunloadEvent);
    window.addEventListener('keydown', handleDeleteButtonPress);

    return () => {
      window.removeEventListener('beforeunload', beforeunloadEvent);
      window.removeEventListener('keydown', handleDeleteButtonPress);
    };
  }, []);

  const handleDeleteButtonPress = (e: KeyboardEvent) => {
    if (!(e.key === 'Backspace')) {
      return;
    }
    const selectedItem = dynamicZoneDataRef.current.find(
      (item) => item.isSelected
    );
    if (!selectedItem) {
      return;
    }
    if (
      selectedItem.__component === COMPONENT_TYPES.TEXT &&
      isEmptyHtml(selectedItem.text || '')
    ) {
      handleDeleteDynamicZone(selectedItem);
      return;
    }
    if (selectedItem.__component === COMPONENT_TYPES.MEDIA) {
      handleDeleteDynamicZone(selectedItem);
    }
  };

  const handleDeleteDynamicZone = (selectedItem: IPEDynamicZone) => {
    const nonSelected = dynamicZoneDataRef.current.filter(
      (item) => item.draggableId !== selectedItem.draggableId
    );
    setDynamicZoneData(nonSelected);
    dynamicZoneDataRef.current = nonSelected;
  };

  const onHeaderBackClick = () => {
    navigate(-1);
  };

  const {height} = Dimensions.get('window');
  const beforeunloadEvent = (ev: BeforeUnloadEvent) => {
    ev.preventDefault();
    ev.returnValue = '';
  };

  return (
    <>
      <View style={styles.mainContainer}>
        {!props.isHideTopTab && (
          <View backgroundColor={'#fff'}>
            <TitleSubtitleView
              titleLabelId={id ? 'updateArticle' : 'createArticle'}
              showBackButton={true}
              subtitleLabelId={
                id ? 'updateArticleSubtitle' : 'createArticleSubtitle'
              }
              onBackClick={onHeaderBackClick}
            />
          </View>
        )}
        <View
        // pb={2}
        >
          <TableTopBar
            title={getTitleString(title)}
            hideSearchBar
            alignSearchBarFlexEnd={true}
            isDividerNotVisible={true}
            buttonList={[
              {
                btnClick: () => {
                  handleCancel();
                },
                btnText: 'cancel',
                colorScheme: 'muted',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                size: 'sm',
                isTransBtn: false,
                id: 1,
              },
              {
                btnClick: () => {
                  handleAdd();
                },
                btnText: 'save',
                colorScheme: 'primary',
                size: 'sm',
                variant: BUTTON_TYPE.PRIMARY,
                textColor: Colors.Custom.mainPrimaryPurple,
                isTransBtn: false,
                id: 1,
                isLoading: submitting,
                isDisabled: (!title?.length && !dynamicZoneData?.length)
              },
            ]}
          />
        </View>
        <View style={styles.componentContainer}>
          <View
            style={{
              backgroundColor: '#fff',
            }}
          >
            <View
              style={{
                height: height - 100,
                overflow: 'scroll',
                marginHorizontal: isSideCarContext ? 0 : 200,
              }}
            >
              <View>
                <TopCard
                  onChangeDescription={(description: string) =>
                    setDescription(description)
                  }
                  onChangeTitle={(title: string) => setTitle(title)}
                  data={{
                    title: title,
                    description: description,
                    readTime: readTime,
                  }}
                  errors={errors}
                  onChangeReadTime={(readTime) => {
                    setReadTime(readTime as string);
                  }}
                />

                {loading && id ? (
                  <Spinner />
                ) : (
                  <CreatePEView
                    getDynamicData={(dynamicData) => {
                      dynamicZoneDataRef.current = dynamicData;
                      setDynamicZoneData(dynamicData);
                    }}
                    dynamicData={dynamicZoneData}
                  />
                )}
              </View>
            </View>
          </View>
        </View>
      </View>
    </>
  );
};

export default PatientEducation;
