import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {cloneDeep, toInteger} from 'lodash';
import moment from 'moment';
import {
  Box,
  Button,
  Divider,
  HStack,
  Icon,
  Pressable,
  Progress,
  Skeleton,
  Text,
  useToast,
  View,
  VStack,
} from 'native-base';
import {useContext, useEffect, useState, useCallback} from 'react';
import {useIntl} from 'react-intl';
import {
  DATE_FORMATS,
  DIASTOLIC_BLOOD_PRESSURE_LOINC,
  EVENT_NAMES,
  KANBANVIEWTYPE,
  MLOV_CATEGORY,
  PERSON_TYPES,
  SYSTOLIC_BLOOD_PRESSURE_LOINC,
} from '../../../../../constants';
import {
  CARESTUDIO_APOLLO_CONTEXT,
  PATIENT_EDUCATION_URL,
} from '../../../../../constants/Configs';
import {FHIR_RESOURCE} from '../../../../../constants/FhirConstant';
import {
  FORM_SOURCE,
  LABEL_TYPE_CODES,
  ORDERED_TASK_PRIORITY_CODES_DESC,
  TASK_STATUS_CODES,
  USER_ROLE_CODES,
} from '../../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {ILoginUserData, ITaskDetailConfig, IUser} from '../../../../../Interfaces';
import {TaskQueries, UserQueries, VitalQueries} from '../../../../../services';
import {
  fhirAddOrUpdate,
  getResourceContent,
} from '../../../../../services/CommonService/AidBoxService';
import {Colors} from '../../../../../styles';
import {checkIfArrayAndIsNotEmpty} from '../../../../../utils/arrUtils';
import {getEhrConfig, getResourceAbilities, getVitalListFromCapability} from '../../../../../utils/capabilityUtils';
import {
  filterWorkflowUser,
  getAccountUUID,
  getFormURL,
  getUserFullName,
  getUserUUID,
} from '../../../../../utils/commonUtils';
import {
  getCurrentTimeZone,
  getDateStrFromFormat,
  getDateToMomentISOString,
  getDayBackInDate,
  getDayFutureInDate,
  getEndOfDay,
  getFormattedDate,
  getISODateFromDisplayDate,
  getStartOfDay,
} from '../../../../../utils/DateUtils';
import {
  getMlovId,
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../../../utils/mlovUtils';
import {
  getFHIRTemplate,
  getObservationResourceTemplate,
  getVitalConfigFromLoinc,
  Vital,
} from '../../../../../utils/VitalUtils';
import {
  ILabelTask,
  ITask,
  ITasksData,
} from '../../../../common/CareDashboard/CareDashboardInterfaces';
import {ITopBarData} from '../../../../common/CareDashboard/CareDashboardTopBar/CareDashboardTopBar';
import {
  GET_TASK_LABEL_BATCH_COUNT,
  getAllAddedlabelsIds,
  getCompletedTaskStatusId,
  KANBAN_COLUMNS,
  parseTaskDataIntoColumns,
  TASK_COMPLETED_STATUS_CODE,
} from '../../../../common/CareDashboard/CareDashboardUtils/CareDashboardUtils';
import {IKanBanColumnType} from '../../../../common/KanBanView/interfaces';
import NoteSvgIcon from '../../../../common/Svg/NoteSvgIcon';
import { getTaskIdsWithVitalData } from '../../../../common/Tasks/TasksUtils/TasksUtils';
import {
  BottomViewAction,
  canHideCheckBox,
  getBottomView,
} from '../../../../TaskCard/TaskCardHelper';
import TaskDetailCard from '../../../../TaskView/TaskDetailCard';
import {checkIfTaskIsCompleted} from '../../../../TaskView/taskUtils';
import {getFormDataFromLeadData} from '../../../Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import {IObservation} from '../../../Forms/FHFormio/CustomComponents/Vitals/interfaces';
import { IContact } from '../interfaces';
import TasksDrawer from './TasksDrawer';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { ParticipantType } from '../../../../common/CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import { IAllUserPoolSelect } from '../../../../common/CareDashboard/CareDashboardWidget/UserAutoComplete';
import { GetUsersTaskAccess, GetUsersTaskPoolAccess } from '../../../../../services/TaskPool/TaskPoolQueries';
import { GET_USER_FOR_SCHEDULE_ACCESS } from '../../../../../services/User/UserQueries';
import AddOrUpdateTask from '../../../../common/AddTask/AddOrUpdateTask';
import { EventBus } from '../../../../../utils/EventBus';
import { Tooltip, notification } from 'antd';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import { removeDuplicates } from '../../../Contacts/Leads/LeadView/LeadTableView/LeadListView/LeadListHelper';
import { EntityType, TaskPanelType } from '../../../../TaskCard/TaskEnum';
import { TASK_ASSIGNEE_TYPE_CODES } from '../../../../common/CareDashboard/CareDashboardTable/CareDashboardTableHelper';
import { getPanelTitle } from '../../../../TaskCard/TaskHelper';
import FeatherIcon from 'react-native-vector-icons/Feather';
import ArrowRightDiagonalSvg from '../../../../common/Svg/ArrowRightDiagonalSvg';
import { ToastType, showToast } from '../../../../../utils/commonViewUtils';
import { getDocumentRefByReferenceId } from '../../../../../services/CommonService/OrderService';
import { SOURCE_MAP } from '../../../../common/AddTask/AddTaskUtils';
import { DocStatus, NoteEntry } from '../../../../PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import {GET_CARE_PLAN_BY_ID} from '../../../../../services/CarePlan/CarePlanQueries';
import useCarePlanStatus from '../../../../PersonOmniView/MiddleContainer/CarePlan/useCarePlanStatus';
import { TASK_EVENTS, TASK_MODULE_CODE } from '../../../../common/CareDashboard/CareDashboardConstants';


const TaskPanel = (props: {
  contactData: IContact;
  showDetailProfile?: boolean;
  panelType: TaskPanelType
}) => {
  const currentUserUUID = getUserUUID();
  const isFromPatientTaskPanel = props.panelType === TaskPanelType.PATIENT;
  const isFromPatientContextTaskPanel = props.panelType === TaskPanelType.INTERNAL;
  const [isShowAddTaskDrawer, setIsShowAddTaskDrawer] = useState(false);
  const [isShowViewAll, setIsShowViewAll] = useState(false)
  const { contactData } = props;
  const [accountUsers, setAccountUser] = useState<IUser[]>([]);
  const [tasks, setTasks] = useState<Array<ITask>>([]);
  const [isShowTasksDrawer, setIsShowTasksDrawer] = useState(false);
  const [loading, setLoading] = useState(true);
  const [vitalDataLoading, setVitalLoading] = useState(false);
  const [userTaskPoolAccess, setUserTaskPoolAccess] = useState(false);
  const carePlanStatus = useCarePlanStatus();
  const [loadingData, setLoadingData] = useState<{
    loading: boolean;
    taskIdWithAction?: any;
  }>({
    loading: false,
  });
  const [taskDetail, setTaskDetail] = useState<ITaskDetailConfig>({
    show: false,
    showCareJourneyTask: false,
  });
  const DEFAULT_PREV_DAY = 30;
  const DEFAULT_NEXT_DAY = 30;
  const startDate = getStartOfDay()?.toISOString();
  const endDate = getEndOfDay()?.toISOString();
  const [topBarData, setTopBarData] = useState<ITopBarData>({
    start: '',
    end: '',
    addTask: false,
    hideActions: true,
  });
  const [dashboardColumns, setDashboardColumns] =
    useState<IKanBanColumnType[]>(KANBAN_COLUMNS);
  const [userPoolSelect, setAllUserPoolSelect] = useState<IAllUserPoolSelect[]>([]);
  const [accountUser, setAccountUsers] = useState<any[]>([]);
  const [pendingTasksCount, setPendingTasksCount] = useState(0)
  const mlovData = useContext(CommonDataContext);

  const formattedContactData = getFormDataFromLeadData(contactData, mlovData);
  const accountLocationUuid =
    formattedContactData?.accountLocationUuid ||
    props?.contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid ||
    '';

  const userUUID = getUserUUID();
  const loggedInUserId = getUserUUID();
  const accessUserTypeId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'UserPoolType',
    'access_user_task'
  );
  // ehrConfig and visitNote configuration use for get visit note data
  const resourceAbilities = getResourceAbilities(
    FHIR_RESOURCE.DOCUMENT_REFERENCE,
    '',
    props?.contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid
  );
  const foldVisitNoteWithEncountersEnabled = resourceAbilities?.foldVisitNoteEnabled || false;
  const ehrConfig = getEhrConfig(accountLocationUuid, '');

  const userFullName = getUserFullName();


  const accountSettings = mlovData.userSettings;
  const wearableIntegrationEnabled =
    accountSettings['wearable_integration_enabled']?.value === 'True' || false;
  const userData = mlovData.userData || ({} as ILoginUserData);
  const accountId =
    userData?.accounts.length > 0 ? userData?.accounts[0].uuid || '' : '';
  const accountUuid = getAccountUUID();

  const isTimelineView = true;
  const vitals = getVitalListFromCapability('', accountLocationUuid);
  const formSourceList =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.FORM_SOURCE
    ) || [];
  const formSourceId = getMlovIdFromCode(
    formSourceList,
    FORM_SOURCE.CARE_JOURNEY
  );
  const taskStatusMlov =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_STATUS
    ) || [];

  const completedStatusId = getCompletedTaskStatusId(taskStatusMlov);

  const intl = useIntl();
  const toast = useToast();

  const eventBus = EventBus.getEventBusInstance();

  const showErrorPopup = () => {
    showToast(
      toast,
      intl.formatMessage({id: 'apiErrorMsg'}),
      ToastType.error
    );
  };

  const getAssignedByUserDetail = (task: ITask) => {
    return (
      (accountUsers || []).find((user: IUser) => {
        return user.uuid === task?.assignedById;
      }) || ({} as IUser)
    );
  };

  const updateTaskAssignedByData = (data: ITasksData) => {
    (data?.tasks || []).forEach((task) => {
      task.assignedBy = getAssignedByUserDetail(task);
    });
    return data.tasks;
  };

  const setEmptyData = () => {
    setTasks([]);
    const columns = parseTaskDataIntoColumns(
      [],
      intl,
      KANBANVIEWTYPE.PATIENT_KANBAN
    );
    setDashboardColumns(columns);
  };

  const markTaskAsComplete = (task: ITask) => {
    updateTaskStatus({
      variables: {
        params: {
          id: task.id,
          data: {
            statusId: completedStatusId,
            referenceData: task.referenceData,
          },
        },
      },
    });
  };

  const [captureVital] = useMutation(VitalQueries.RECORD_VITAL_VALUE, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const [updateTaskStatus] = useMutation(
    TaskQueries.UPDATE_TASK_STATUS_AND_REFERENCE,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      onCompleted: () => {
        showToast(
          toast,
          intl.formatMessage({id: 'taskCompletedMsg'}),
          ToastType.success
        );
        setTaskDetail({show: false, task: undefined});
        setLoadingData((prev) => ({...prev, loading: false}));
        fetchTasks();
      },
      onError: (error: any) => {

        setLoadingData((prev) => ({...prev, loading: false}));
        showErrorPopup();
      },
    }
  );

  useEffect(() => {
    fetchTasks()
  },[taskDetail.show ])

  useQuery(UserQueries.GET_USERS_FOR_CALENDAR, {
    fetchPolicy: 'no-cache',
    variables: {
      accountId: accountUuid,
    },
    onCompleted: (data: any) => {
      setAccountUser(data?.users);
    },
    onError: (error: any) => {

    },
  });

  const addVitalDataInTasksList = (
    tasks: Array<ITask>,
    vitalData: {resource: IObservation}[]
  ) => {
    setTasks((prevTasks) => {
      prevTasks.forEach((task: ITask) => {
        if (task.contactId) {
          const matchedData = vitalData.filter((observation) => {
            return (
              observation.resource?.id === task.referenceData?.externalId
            );
          });
          if (matchedData.length > 0) {
            if (matchedData[0].resource?.valueQuantity?.value) {
              task.capturedData = {
                value: `${matchedData[0].resource.valueQuantity.value}`,
                valueUnit: matchedData[0].resource.valueQuantity.unit || '',
              };
            } else if (matchedData[0].resource?.component?.length) {
              const systolicData = matchedData[0].resource?.component?.find(
                (item) =>
                  item.code?.coding?.length &&
                  item.code.coding[0].code === SYSTOLIC_BLOOD_PRESSURE_LOINC
              );
              const diastolicData = matchedData[0].resource?.component?.find(
                (item) =>
                  item.code?.coding?.length &&
                  item.code.coding[0].code === DIASTOLIC_BLOOD_PRESSURE_LOINC
              );
              if (
                systolicData?.valueQuantity?.value ||
                diastolicData?.valueQuantity?.value
              ) {
                task.capturedData = {
                  value: `${systolicData?.valueQuantity?.value || '-'}/${
                    diastolicData?.valueQuantity?.value || '-'
                  }`,
                  valueUnit: systolicData?.valueQuantity?.unit || '',
                };
              }
            }
          } else if (task.referenceData?.value) {
            task.capturedData = {
              value: `${task.referenceData.value}`,
              valueUnit: task.referenceData?.unit || '',
            };
          }
        }
      });
      return prevTasks;
    });
  };

  const getRecordedVitalData = () => {
    addVitalDataInTasksList(tasks, []);
  };

  const [getLabelsData] = useLazyQuery(TaskQueries.GET_LABELS_BY_IDS, {
    fetchPolicy: 'no-cache',
  });

  async function fetchLabelsForBatch(batchIds: string[]) {
    const getLabelsRes = await getLabelsData({
      variables: {
        labelIds: batchIds,
        labelTypeId: mlov.id,
      },
    }).catch ((err) => {
    });
    return getLabelsRes?.data?.labels || [] as ILabelTask[];
  }

  const [getSubTaskByIds] = useLazyQuery(TaskQueries.GET_SUB_TASK_BY_ID, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const [getTasks, taskQuery] = useLazyQuery(
    TaskQueries.GET_CARE_DASHBOARD_TASKS,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
      onCompleted: async (data: any) => {
        data = data?.getTasks;
        if (data && data.tasks && data.tasks.length > 0) {
          setPendingTasksCount(data?.aggregate?.total)
          const updatedTaskList = updateTaskAssignedByData(data);
          setTasks(updatedTaskList);

          if (updatedTaskList?.length) {
            const allLabelsIdLists = getAllAddedlabelsIds(updatedTaskList);
            if (allLabelsIdLists?.length) {
              const batchSize = GET_TASK_LABEL_BATCH_COUNT;
              let batches = [] as ILabelTask[];
              for (let i = 0; i < allLabelsIdLists.length; i += batchSize) {
                const batchIds = allLabelsIdLists.slice(i, i + batchSize);
                const getLabelsDataRes = await fetchLabelsForBatch(batchIds);
                batches = [...batches, ...getLabelsDataRes];
              }

              Promise.all(batches)
                .then((results) => {
                  const taskWithLabelsList = updatedTaskList.map((taskItem) => {
                    const arrayOfArrays =
                      taskItem.labels?.map((labelsItem) => {
                        const filterLable = results?.filter((allLabelsitem) => {
                          if (labelsItem.labelId === allLabelsitem?.uuid) {
                            return allLabelsitem;
                          }
                        });
                        if (filterLable?.length) {
                          return {
                            ...filterLable?.[0],
                            id: labelsItem.id,
                            labelId: labelsItem.labelId
                          }
                        } else {
                          return []
                        }
                      }) || ([] as ILabelTask[]);
                    return {
                      ...taskItem,
                      labels: removeDuplicates(
                        arrayOfArrays?.flat(),
                        'labelId'
                      ),
                    };
                  });
                  setTasks(taskWithLabelsList);
                })
                .catch((error) => {
                });
            }
          }

          const columns = parseTaskDataIntoColumns(
            cloneDeep(updatedTaskList),
            intl,
            KANBANVIEWTYPE.PATIENT_KANBAN
          );

          setDashboardColumns(columns);

          const taskIds = getTaskIdsWithVitalData(updatedTaskList);
          if (taskIds.length > 0) {
            getRecordedVitalData();
          }
        } else {
          setEmptyData();
        }
      },
      onError: (error: any) => {

        setEmptyData();
        showErrorPopup();
      },
    }
  );
  const [taskStatusCountDetails, setTaskStatusCountDetails] = useState<any>();

  const [getTasksCount, taskCountQuery] = useLazyQuery(
    TaskQueries.GET_TASKS_COUNT,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
      onCompleted: (data: any) => {
        const taskStatusCountArr = data?.getTaskStatusCount;
        if (checkIfArrayAndIsNotEmpty(taskStatusCountArr)) {
          const taskStatusObj = taskStatusCountArr?.[0];
          setTaskStatusCountDetails(taskStatusObj);
        } else {
          setTaskStatusCountDetails({completedCount: 0, totalCount: 0});
        }
      },
      onError: (error: any) => {
        setTaskStatusCountDetails({});
      },
    }
  );

  const [getAllTasksCount, getAllTasksCountQuery] = useLazyQuery(
    TaskQueries.GET_AGGREGATE_TASK_COUNT,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
      onCompleted: (data: any) => {
        if (data?.getTasks?.aggregate?.total > 0) {
          setIsShowViewAll(true)
        }
      },
      onError: (error: any) => {

      },
    }
  );
  if (
    (vitalDataLoading || taskQuery.loading || taskCountQuery.loading || getAllTasksCountQuery.loading) &&
    !loading
  ) {
    setLoading(true);
  } else if (
    !vitalDataLoading &&
    !taskQuery.loading &&
    !taskCountQuery.loading &&
    !getAllTasksCountQuery.loading &&
    loading
  ) {
    setLoading(false);
  }

  const getQueryObject = () => {
    const query: any = {
      assigneeIds: isFromPatientTaskPanel ? [contactData?.uuid] : [],
      contactIds: isFromPatientContextTaskPanel ? [contactData?.uuid] : [],
      ...(isFromPatientContextTaskPanel && {
        assigneeIdsNotIn: [contactData?.uuid],
      }),
      statusIdsNotIn: [completedStatusId],
      limit: 3,
      orderBy: {
        endDateTime: "asc"
      }
    };
    return query;
  }

  const fetchTasks = () => {
    const queryObject = getQueryObject();
    getTasks({
      variables: queryObject,
    });

    getTasksCount({
      variables: {
        startDate: moment().subtract(30, 'days').toISOString(false),
        endDate: getEndOfDay().toISOString(),
        groupBy: ['assigneeId'],
        assigneeId: contactData?.uuid,
      },
    });
  };

  const refetchAllTasks = () => {
    fetchTasks();
    getAllTasksCount({
      variables: {
        params: {
          timezone: getCurrentTimeZone(),
          assigneeIds: isFromPatientTaskPanel ? [contactData?.uuid] : [],
          contactIds: isFromPatientContextTaskPanel ? [contactData?.uuid] : [],
          ...(isFromPatientContextTaskPanel && {
            assigneeIdsNotIn: [contactData?.uuid],
          }),
        }
      },
    }
    )
  }

  useEffect(() => {
    let mounted = true;
    fetchTasks();
    getAllTasksCount({
      variables: {
        params: {
          timezone: getCurrentTimeZone(),
          assigneeIds: isFromPatientTaskPanel ? [contactData?.uuid] : [],
          contactIds: isFromPatientContextTaskPanel ? [contactData?.uuid] : [],
          ...(isFromPatientContextTaskPanel && {
            assigneeIdsNotIn: [contactData?.uuid],
          }),
        }
      },
    }
    )
    eventBus.addEventListener(EVENT_NAMES.NEW_TASK_ADDED, addTaskEvent);
    eventBus.addEventListener(EVENT_NAMES.REFRESH_TASK, refetchAllTasks);
    return () => {
      eventBus.removeEventListener(addTaskEvent);
      eventBus.removeEventListener(refetchAllTasks);
      const clear = async () => {
        mounted = false;
      };
      clear();
    };
  }, [props?.contactData?.id]);

  const addTaskEvent = (data: {assigneeId: string, memberContextId?: string}) => {
    fetchTasks();
    getTasksCount({
      variables: {
        startDate: moment().subtract(30, 'days').toISOString(false),
        endDate: getEndOfDay().toISOString(),
        groupBy: ['assigneeId'],
        assigneeId: contactData?.uuid,
      },
    });
    getAllTasksCount({
      variables: {
        params: {
          timezone: getCurrentTimeZone(),
          assigneeIds: isFromPatientTaskPanel ? [contactData?.uuid] : [],
          contactIds: isFromPatientContextTaskPanel ? [contactData?.uuid] : [],
          ...(isFromPatientContextTaskPanel && {
            assigneeIdsNotIn: [contactData?.uuid],
          }),
        },
      },
    });
    }

  const recordLabtestData = (task: ITask, data: any) => {
    setLoadingData((prev) => ({...prev, loading: true}));
    const params: any = {
      value: JSON.stringify(data),
      patientId: formattedContactData?.patientUuid || '',
      observationDate: getDateToMomentISOString(new Date()),
      valueUnit: data.unit,
      observationName: task.referenceData?.entity?.name || task.title,
      subjectId: task.id,
    };
    captureVital({
      variables: {
        data: params,
      },
    })
      .then(() => {
        markTaskAsComplete(task);
      })
      .catch((error: any) => {
        setLoadingData((prev) => ({...prev, loading: false}));

        showErrorPopup();
      });
  };

  const recordVitalData = (task: ITask, data: any) => {
    setLoadingData((prev) => ({...prev, loading: true}));
    const vital = getVitalConfigFromLoinc(
      vitals,
      task.referenceData?.entity?.loinc || ''
    );
    const patientId =
      formattedContactData?.patientId || formattedContactData?.patientUuid;
    if (vital) {
      const fhirData = getFHIRTemplate({
        observations: [
          getObservationResourceTemplate(
            vital.loinc as Vital,
            vitals,
            data.vital.value,
            getDateToMomentISOString(new Date())
          ),
        ],
        patientId: patientId || '',
      });

      fhirAddOrUpdate(
        FHIR_RESOURCE.OBSERVATION,
        undefined,
        fhirData,
        {
          patientId: patientId,
          locationId: accountLocationUuid,
        },
        (response) => {
          if (response?.data?.entry?.length) {
            const observationId = response.data?.entry?.[0]?.id || response.data?.entry?.[0]?.resource?.id;
            if (!task.referenceData) task.referenceData = {};
            task.referenceData.externalId = observationId;
            task.referenceData.value = data.vital.value;
            task.referenceData.unit = data.unit;
            markTaskAsComplete(task);
          } else {
            setLoadingData((prev) => ({...prev, loading: false}));
            showErrorPopup();
          }
        },
        () => {
          setLoadingData((prev) => ({...prev, loading: false}));
          showErrorPopup();
        }
      );
    }
  };

  const handleBottomViewActions = (
    task: ITask,
    action: BottomViewAction,
    data: any
  ) => {
    setLoadingData((prev) => ({
      ...prev,
      taskIdWithAction: task.id,
    }));
    switch (action) {
      case BottomViewAction.captureLabTest:
        recordLabtestData(task, data);
        break;
      case BottomViewAction.captureVital:
        recordVitalData(task, data);
        break;

      case BottomViewAction.openForm:
        const url = getFormURL(
          task.referenceData?.entity?.id || '',
          formattedContactData.contactUUID || '',
          accountId,
          task.id,
          task.referenceData?.entity?.sourceId || formSourceId,
          undefined,
          task.referenceData?.eventId
        );
        window.open(url, '_blank');
        break;

      case BottomViewAction.openContent:
        if (task.referenceData?.entity?.slug) {
          const slug = task.referenceData.entity.slug;
          const url = PATIENT_EDUCATION_URL + slug;
          window.open(url, '_blank');
        }
        break;

      case BottomViewAction.markAsComplete:
        setLoadingData({
          taskIdWithAction: task.id,
          loading: true,
        });
        updateTaskStatus({
          variables: {
            params: {
              id: task.id,
              data: {
                statusId: completedStatusId,
              },
            },
          },
        });
        break;

      default:
        break;
    }
  };

  function parseDate(date: any) {
    return new Date(date);
  }

  useEffect(() => {
    eventBus.addEventListener(TASK_EVENTS.OPEN_TASK_DETAIL, onTaskDetailEvent, { moduleCode: TASK_MODULE_CODE });
    return () => {
      eventBus.removeEventListenerByEventName(TASK_EVENTS.OPEN_TASK_DETAIL, onTaskDetailEvent, { moduleCode: TASK_MODULE_CODE });
    }
  }, []);

  function datediff(first: any, second: any) {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }

  const getDaysCompletedInJourney = (selectedJourney: any) =>
    datediff(
      parseDate(selectedJourney?.startDateTime),
      parseDate(new Date())
    );

  const getTotalDaysForJourney = (selectedJourney: any) =>
    datediff(
      parseDate(selectedJourney?.startDateTime),
      parseDate(selectedJourney?.endDateTime)
    );

  const handleVitalsBottomView = (task: ITask) =>
    getBottomView(
      task,
      loadingData.taskIdWithAction === task.id ? loadingData.loading : false,
      vitals,
      handleBottomViewActions,
      isTimelineView,
      true,
      true,
      accountLocationUuid
    );

  const checkIfAllSubTasksAreCompleted = async (task: ITask) => {
    if (!task.subTasks || task.subTasks?.length === 0) {
      return true;
    }
    const response = await getSubTaskByIds({
      variables: {
        ids: task.subTasks?.map((task) => task?.id),
      },
    });
    return response?.data?.getTasks?.tasks?.every(
      (subTask: any) => subTask.statusId === completedStatusId
    );
  }

  const [getLinkNoteWithTask] = useLazyQuery(TaskQueries.GET_TASK_LINK_NOTE, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });
  const getCarePlanLinkedWithTask = getLinkNoteWithTask;
  const [getCarePlanById] = useLazyQuery(GET_CARE_PLAN_BY_ID, {
    fetchPolicy: 'no-cache',
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });


  const getPatientNoteViewByResourceData = async (resourceId: any) => {
    const response = await getDocumentRefByReferenceId(`${resourceId}`,foldVisitNoteWithEncountersEnabled, accountLocationUuid);
    const noteEntry: NoteEntry = { resource: response?.data } as NoteEntry;
    return noteEntry?.resource || {};
  }

  const checkCarePlanStatusIsReviewed = async (editTask: ITask) => {
    const isCarePlanContextTask = editTask?.resourceMap && editTask.resourceMap?.carePlan || false;
    let isInReview = false;
      if (isCarePlanContextTask) {
        const carePlanMappingParam = { resourceId: editTask?.id, sourceTypeCode: SOURCE_MAP.CARE_PLAN };
        const carePlanResourceMapResponse = await getCarePlanLinkedWithTask({ variables: carePlanMappingParam });
        const resourceMappings = carePlanResourceMapResponse?.data?.resourceMappings || [];
        const carePlanId = resourceMappings?.length ? resourceMappings?.[0]?.sourceId : undefined;
        if (carePlanId) {
          const carePlanResponse = await getCarePlanById({ variables: { id: carePlanId } });
          const carePlan = carePlanResponse?.data?.contactCarePlans?.[0];
          isInReview = carePlan?.statusId === carePlanStatus.inReviewCarePlanStatusId;
        }
      }
    return isInReview;
  }

  const isNoteStatusNotSigned = async (editTask: ITask): Promise<boolean> => {
    let noteStatusNotSigned = false;
    const isTaskLinkWithNote = editTask?.resourceMap && editTask.resourceMap?.notes || false;
    if (isTaskLinkWithNote) {
      const taskNoteResourceMapParam = { resourceId: editTask?.id, sourceTypeCode: SOURCE_MAP.NOTES };
      const resourceMapResponse = await getLinkNoteWithTask({ variables: taskNoteResourceMapParam });
      const resourceMappings = resourceMapResponse?.data?.resourceMappings || [];
      const noteId = resourceMappings?.length ? resourceMappings?.[0]?.sourceId : undefined;
      if (noteId) {
        const notesResponse  = await getPatientNoteViewByResourceData(noteId);
        noteStatusNotSigned = notesResponse && notesResponse?.docStatus === DocStatus.PRELIMINARY;
      }
    }
    return noteStatusNotSigned;
  }

  const handleTaskStatusUpdation = async (task: ITask) => {
    const allSubTasksCompleted = await checkIfAllSubTasksAreCompleted(task);
    if (!allSubTasksCompleted) {
      notification.error({
        message: intl.formatMessage({id: 'subTaskPendingMsg'}),
        duration: 3
      })
      setLoadingData({
        taskIdWithAction: undefined,
        loading: false,
      });
      return;
    }
    const isNoteNotSigned = await isNoteStatusNotSigned(task);
    if (isNoteNotSigned) {
      notification.error({
        message: intl.formatMessage({id: 'completeTaskLinkNoteMessage'}),
        duration: 3,
        placement: 'top'
      })
      setLoadingData({
        taskIdWithAction: undefined,
        loading: false,
      });
      return;
    }
    const isCarePlanInReview = await checkCarePlanStatusIsReviewed(task);
    if (isCarePlanInReview) {
      notification.error({
        message: intl.formatMessage({id: 'carePlanInReviewMsg'}),
        duration: 3,
        placement: 'top'
      })
      setLoadingData({
        taskIdWithAction: undefined,
        loading: false,
      });
      return;
    }
    if (allSubTasksCompleted){
      updateTaskStatus({
        variables: {
          params: {
            id: task.id,
            data: {
              statusId: completedStatusId,
            },
          },
        },
      });
    } else {

    }
  }

  const getTodaysTasks = () => {
    const pendingTasksToShowInPanel: Array<ITask> = [];
    if (checkIfArrayAndIsNotEmpty(tasks)) {
      tasks.forEach((task: ITask) => {
        const endDateTime = task?.endDateTime ? task?.endDateTime : '';
        if (
          getFormattedDate(endDateTime, 'MM/DD/YY') ===
          getFormattedDate(moment().toString(), 'MM/DD/YY')
        ) {
          pendingTasksToShowInPanel.push(task);
        }
      });
      return pendingTasksToShowInPanel;
    }
    return pendingTasksToShowInPanel;
  };

  const sortTasksByPriority = (tasks: Array<ITask> = []) => {
    if (checkIfArrayAndIsNotEmpty(tasks)) {
      tasks.sort((a, b) => {
        const currentMlovScore = ORDERED_TASK_PRIORITY_CODES_DESC.indexOf(
          a.priority?.code || ''
        );
        const nextMlovScore = ORDERED_TASK_PRIORITY_CODES_DESC.indexOf(
          b.priority?.code || ''
        );

        return currentMlovScore - nextMlovScore;
      });
    }
    return tasks;
  };

  const getTasksByStartDate = (): {
    taskDetails: {[startDate: string]: Array<ITask>};
    startDates: Array<string>;
  } => {
    const taskDetails: {[startDate: string]: Array<ITask>} = {};
    let startDates: Array<string> = [];
    const dateFormat = 'MM/DD/YYYY';

    if (checkIfArrayAndIsNotEmpty(tasks)) {
      tasks.forEach((task: ITask) => {
        let startDateTime =
          task.startDateTime !== undefined ? task.startDateTime : '';
        startDateTime = getFormattedDate(startDateTime, dateFormat);

        if (taskDetails[startDateTime as keyof typeof taskDetails]) {
          taskDetails[startDateTime as keyof typeof taskDetails].push(task);
        } else {
          const temp: Array<ITask> = [];
          temp.push(task);
          taskDetails[startDateTime as keyof typeof taskDetails] = temp;
          startDates.push(startDateTime);
        }
      });

      startDates.sort((a, b) => Number(new Date(b)) - Number(new Date(a)));

      startDates = startDates.map((date: string | Date) => {
        const formattedDate = getFormattedDate(date, dateFormat);
        sortTasksByPriority(taskDetails[formattedDate]);
        return formattedDate;
      });

      return {taskDetails, startDates};
    }
    return {taskDetails, startDates};
  };

  // const sortAllTasksByStartDate = ()=>{}


  const getTotalPendingTasksInPanelLength = () => {
    if (checkIfArrayAndIsNotEmpty(tasks)) {
      return tasks.length;
    }
    return 0;
  };


  const getCompletedTasksPercentage = () => {
    const completedCount = taskStatusCountDetails?.completedCount || 0;
    const totalCount = taskStatusCountDetails?.totalCount || 1; // So that we don't get NaN after division
    const totalPercentageComplete = (
      (completedCount * 100) /
      totalCount
    ).toFixed(0);
    return totalPercentageComplete;
  };


  const accessUserTaskPoolTypeId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'UserPoolType',
    'task_user_pool'
  );
  const labels = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.LABEL_TYPE
  );
  const filteredResult = labels?.filter((item) => {
    return item.code === LABEL_TYPE_CODES.TASK;
  });
  const mlov = filteredResult[0];

  const [getAccountUsers] = useLazyQuery(GET_USER_FOR_SCHEDULE_ACCESS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      const accountUsers: any[] = [];
      if (data && data?.users && data?.users.length) {
        data.users = filterWorkflowUser(data.users, loggedInUserId);
        (data.users || []).forEach((user: any) => {
          accountUsers.push({
            userName: user.name,
            userId: user.uuid,
            email: user.email || '',
          });
        });
      }
      setAccountUsers(accountUsers);
      getUserTaskPoolAccess();
    },
    onError: (error: any) => {

    },
  });

  const [getAllTaskAccessUserTaskPools] = useLazyQuery(GetUsersTaskAccess, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const [ getUserTaskPoolAccess, getUserTaskPoolAccessAPI ] = useLazyQuery(GetUsersTaskPoolAccess, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    //skip: accountUserQueryLoading,
    variables: {
      // userId: accountUsers.map(
      //   (accountUserItem: any) => accountUserItem.userId
      // ),
      userId: [loggedInUserId],
      userPoolTypeId: accessUserTypeId,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      // fetching all account users amd filtering login user data
      const filterUserPoolAccessData: any = data.userPoolAccesses?.filter(
        (dataItem: any) => dataItem.userId === loggedInUserId
      );

      if (filterUserPoolAccessData && filterUserPoolAccessData.length) {
        // logged In user have the other pool user access
        const userPoolAccessUsersData =
          filterUserPoolAccessData[0]?.user_pool?.userPoolUsers;
        const loggedInUser = accountUser.filter(
          (accountItem: any) => accountItem.userId === loggedInUserId
        );
        if (loggedInUser && loggedInUser.length) {
          userPoolAccessUsersData.unshift(loggedInUser[0]);
        }

        // flag to manage dropdown whether there are multiple users to handle or not
        if (userPoolAccessUsersData && userPoolAccessUsersData.length > 1) {
          setUserTaskPoolAccess(true);
        } else {
          setUserTaskPoolAccess(false);
        }
        //userDataPostProcessing(userPoolAccessUsersData);
        fetchAllTaskPoolAccessUserPool(userPoolAccessUsersData);
      } else {
        // logged In user do not have the other pool user access
        setUserTaskPoolAccess(false);
      }
    },
    onError: (error: any) => {

    },
  });


  const fetchAllTaskPoolAccessUserPool = async (users: any[]) => {
    try {
      const userIds = users.map((userItem: any) => userItem?.userId);
      const taskPoolData = await getAllTaskAccessUserTaskPools({
        variables: {
          userId: userIds,
          userPoolTypeId: accessUserTaskPoolTypeId,
        },
      });
      const newAllUserPool: IAllUserPoolSelect[] = [];
      taskPoolData.data?.userPoolUsers?.map((userPoolItem: any) => {
        const userPoolId = userPoolItem?.userPoolId;
        const userPoolName = userPoolItem?.userPool?.name;
        const userPoolUsers = userPoolItem?.userPool?.userPoolUsers;

        const findIndex = newAllUserPool.findIndex(
          (userPoolItem: IAllUserPoolSelect) =>
            userPoolName === userPoolItem.userPoolName
        );
        if (findIndex < 0) {
          newAllUserPool.push({
            userPoolId,
            userPoolName,
            userPoolUserIds: userPoolUsers.map(
              (userPoolUserItem: any) => userPoolUserItem.userId
            ),
          });
        }
      });
      setAllUserPoolSelect(newAllUserPool)

    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    getAccountUsers({
      variables: {
        accountUUID: accountUuid,
        roleCode: USER_ROLE_CODES.EMPLOYER
      },
    });
  }, [props?.contactData?.id]);


  const handleRefreshClick = () => {
    fetchTasks();
  }



  const getNewTaskAssignee = () => {
    if(isFromPatientContextTaskPanel){
      return {
        value: currentUserUUID,
        label: getUserFullName(),
        key: currentUserUUID,
        type: ParticipantType.staff,
        details: userData,
      }
    } else {
      return {
        value: formattedContactData?.contactUUID,
        label: formattedContactData?.name,
        key: formattedContactData?.contactUUID,
        type: ParticipantType.patient,
        contactData: formattedContactData?.contactData,
        data: formattedContactData?.contactData,
      }
    }
  }

  const getNewTaskMember = () => {
        return {
          value: formattedContactData?.contactUUID,
          label: formattedContactData?.name,
          key: formattedContactData?.contactUUID,
          type: formattedContactData.contactType?.code === PERSON_TYPES.LEAD ? ParticipantType.leads : ParticipantType.patient,
          contactData: formattedContactData?.contactData,
          data: formattedContactData?.contactData,
        };
  }

const renderAdherenceField = () => {
    if(isFromPatientContextTaskPanel){
      return <></>
    }
    return (
      <>
        {taskStatusCountDetails?.totalCount === 0 ? (
          <></>
        ) : (
          <View
            px={4}
            py={3}
            backgroundColor="white"
            borderRadius={8}
            mt={3}
            style={{
              // @ts-expect-error: Let's ignore a compile error like this unreachable code
              boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.06)`,
            }}
            // key={index}
          >
            {loading ? (
              <>
                <Skeleton h="20" rounded={'md'} />
                <Skeleton.Text rounded={'md'} />
              </>
            ) : (
              <VStack>
                <HStack mb={2} justifyContent="space-between">
                  <View width="4/5">
                    <Text fontSize={16} lineHeight={24} fontWeight={700}>
                      Patient's Task Adherence
                    </Text>
                  </View>
                  <HStack space={2} alignItems="center"></HStack>
                </HStack>
                <VStack>
                  <View mb={2}>
                    <Progress
                      _filledTrack={{bg: Colors.Custom.ProgressColor}}
                      bg="gray.200"
                      value={toInteger(getCompletedTasksPercentage())}
                    />
                  </View>
                  <View>
                    <Text
                      fontWeight={400}
                      lineHeight={20}
                      fontSize={14}
                      color={Colors.Custom.IATextColor}
                    >
                      {taskStatusCountDetails?.completedCount}/
                      {taskStatusCountDetails?.totalCount} in Last 30 days
                    </Text>
                  </View>
                </VStack>
              </VStack>
            )}
          </View>
        )}
      </>
    );
  }

  const isCareJourneyOrderTask = (task: ITask | undefined): boolean => {
    if (task?.referenceData?.careJourneyId && task?.referenceData?.entityType) {
      return (
        task?.referenceData?.entityType === EntityType.MED ||
        task?.referenceData?.entityType === EntityType.LAB_TEST ||
        task?.referenceData?.entityType === EntityType.IMMUNIZATION
      );
    }
    return false;
  };
  const onTaskDetailEvent = useCallback((data: { task: ITask, taskPool?: { key: string; value: string; label: string; } }) => {
    const task = data?.task;
    const taskPool = data?.taskPool;
    const isCareJourneyTask = isCareJourneyOrderTask(task);
    setTaskDetail((prev) => {
      return {
        ...prev,
        show: true,
        task: task ? {
          ...task,
          isCompleted: task.status?.code === TASK_STATUS_CODES.COMPLETED
        } : undefined,
        showCareJourneyTask: isCareJourneyTask,
        ...(taskPool?.key && { prefillData: { taskPool } }),
      }
    });
  }, []);

  const resetTaskDetails = () => {
    setTaskDetail((prev) => ({
      ...prev,
      show: false,
      task: undefined,
      showCareJourneyTask: false,
      activeTask: undefined,
    }));
  }
  return (
    <Box p={7}>
      <HStack alignItems={'center'} justifyContent="space-between">
        <HStack alignItems={'center'}>
          <Box mr={2}>
            <NoteSvgIcon />
          </Box>
          <Text fontSize={20} fontWeight={700}>
            {getPanelTitle(props?.panelType)}
          </Text>
        </HStack>
        <HStack alignItems="center" justifyContent="space-between">
          {isShowViewAll ? (
            <>
              <Tooltip title={intl.formatMessage({ id: 'viewAll' })}>
                <Pressable
                  onPress={() => setIsShowTasksDrawer(true)}
                  marginX={2}
                  marginTop={1}
                >
                  <ArrowRightDiagonalSvg color={Colors?.Custom?.Gray500} />
                </Pressable>
              </Tooltip>
              <View
                style={{
                  width: 1,
                  height: 20,
                  backgroundColor: Colors.Custom.Gray200,
                  marginHorizontal: 4,
                }}
              ></View>
            </>
          ) : (
            <></>
          )}
          <Tooltip title={intl.formatMessage({id: 'refresh'})}>
            <Icon
              onPress={() => handleRefreshClick()}
              as={MaterialCommunityIcon}
              name={'reload'}
              size={'5'}
              color={Colors.Custom.Gray500}
              mx={2}
            />
          </Tooltip>
          <View
            style={{
              width: 1,
              height: 20,
              backgroundColor: Colors.Custom.Gray200,
              marginHorizontal: 4,
            }}
          />

          <Pressable
            onPress={() => {
              setIsShowAddTaskDrawer(true);
            }}
          >
            <View
              style={[
                {
                  width: 36,
                  height: 36,
                  borderRadius: 18,
                  justifyContent: 'center',
                  alignItems: 'center',
                },
                {},
              ]}
            >
              <Tooltip title={intl.formatMessage({id: 'addTask'})}>
                <AntIcon
                  name="plus"
                  style={{ color: Colors.Custom.foldIconColor }}
                  size={20}
                />
              </Tooltip>
            </View>
          </Pressable>
        </HStack>
      </HStack>
      {renderAdherenceField()}
      <VStack space={2} marginTop={2}>
        {loading ? (
          <>
            <Skeleton h="20" rounded={'md'} />
            <Skeleton.Text rounded={'md'} />
          </>
        ) : getTotalPendingTasksInPanelLength() === 0 ? (
          <Box borderRadius={8} p={4} bgColor={Colors.Custom.ContainerBGColor}>
            <Text textAlign={'center'} color={Colors.Custom.PlaceholderColor}>
              No Tasks
            </Text>
          </Box>
        ) :
                <VStack>
                  {tasks.map((task: ITask, index: number) => {
                    const shouldShowCareJourneyTitle = task?.patientCareJourney?.title || task?.patientCareJourney?.careJourney?.title;
                    return (
                      <Pressable
                        onPress={() => {
                          setTaskDetail((prev) => ({...prev,
                            show: true,
                            activeTaskId: task.id,
                            activeTask: task
                          }));
                        }}
                      >
                        <View
                          my={1}
                          bgColor={Colors.Custom.ContainerBGColor}
                          borderRadius={8}
                          style={{
                            // @ts-expect-error
                            boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.06)`,
                          }}
                        >
                          <TaskDetailCard
                            task={task}
                            key={task.id}
                            hideDeleteAction={false}
                            getBottomView={handleVitalsBottomView}
                            updateTaskStatus={handleTaskStatusUpdation}
                            isDashboardView
                            arrLength={tasks.length}
                            index={index}
                            onDeleteTaskHandler={() => {
                              refetchAllTasks();
                            }}
                            handleCheckbox={(isChecked) => {
                              if (isChecked) {
                                setLoadingData({
                                  taskIdWithAction: task.id,
                                  loading: true,
                                });
                                handleTaskStatusUpdation(task);
                                const eventBus = EventBus.getEventBusInstance();
                                const code = `${EVENT_NAMES.REFRESH_TASK}_${contactData.uuid}`;
                                eventBus.broadcastEvent(code, {});
                              }
                            }}
                            isCheckboxLoading={
                              loadingData.taskIdWithAction === task.id
                                ? loadingData.loading
                                : false
                            }
                            isHideCheckbox={canHideCheckBox(
                              task,
                              wearableIntegrationEnabled
                            )}
                          />
                        </View>
                      </Pressable>
                    );
                  })}
                </VStack>
            }
        {!loading && pendingTasksCount > 3 ? (
          <Pressable
            onPress={() => setIsShowTasksDrawer(true)}
            style={{
              paddingBottom: 20,
            }}
          >
            <Text
              size={'smSemibold'}
              style={{
                textAlign: 'center',
                color: Colors.primary[500],
                textDecorationLine: 'underline',
              }}
            >
              {`Show more`}
            </Text>
          </Pressable>
        ) : null}
      </VStack>
      {isShowTasksDrawer && (
        <TasksDrawer
          panelType={props.panelType}
          locationId={accountLocationUuid}
          open={isShowTasksDrawer}
          onClose={() => {
            setTopBarData((prev) => ({
              ...prev,
              start: startDate,
              end: endDate,
              addTask: false,
              hideActions: true,
            }));
            setIsShowTasksDrawer(false);
          }}
          contactData={contactData}
          formattedContactData={formattedContactData}
          taskTopBarData={topBarData}
          getTasksByDateRange={(startDate = '', endDate = '') => {
            setTopBarData((prev) => ({
              ...prev,
              start: startDate,
              end: endDate,
            }));
          }}
          showDetailProfile={props?.showDetailProfile}
          handleTaskRefresh={(isNewTaskAdded: boolean) => {
            if (isNewTaskAdded) {
              fetchTasks();
            }
          }}
          restrictPatientOrLeadSelectionTo={getNewTaskMember() as any}
          onNoteRedirect={() => {
            setIsShowTasksDrawer(false);
            setIsShowAddTaskDrawer(false);
          }}
        />
      )}
      {taskDetail.show &&
        <AddOrUpdateTask
          key={taskDetail?.activeTask?.id}
          task={taskDetail.activeTask}
          isVisible={taskDetail.show}
          onCancel={resetTaskDetails}
          onComplete={() => {
            resetTaskDetails();
            fetchTasks();
          }
          }
          fetchAllTypeTask={resetTaskDetails}
          restrictPatientOrLeadSelectionTo={getNewTaskMember() as any}
          extraData={taskDetail?.prefillData?.taskPool ? {
            taskPool: taskDetail.prefillData?.taskPool,
          } : undefined}
          personData={formattedContactData}
          onNoteRedirect={() => {
            setIsShowTasksDrawer(false);
            setIsShowAddTaskDrawer(false);
          }}
        />
      }
      {isShowAddTaskDrawer && (
        <AddOrUpdateTask
          member={getNewTaskMember() as any}
          assignee={getNewTaskAssignee() as any}
          isVisible={isShowAddTaskDrawer}
          onComplete={(data) => {

            setIsShowAddTaskDrawer(false);
            fetchTasks();
          }}
          onCancel={() => {
            setIsShowAddTaskDrawer(false);
            fetchTasks();
          }}
          restrictPatientOrLeadSelectionTo={getNewTaskMember() as any}
          onNoteRedirect={() => {
            setIsShowTasksDrawer(false);
            setIsShowAddTaskDrawer(false);
          }}
        />
      )}
    </Box>
  );
};

export default TaskPanel;
