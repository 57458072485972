import {useLazyQuery} from '@apollo/client';
import {Center, HStack, Pressable, ScrollView, Skeleton, Text, View} from 'native-base';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions, Keyboard, FlatList as RNFlatList, SafeAreaView} from 'react-native';
import {FlatList} from 'react-native-bidirectional-infinite-scroll';
import {GestureHandlerRootView} from 'react-native-gesture-handler';
import Swipeable from 'react-native-gesture-handler/Swipeable';
import {COPY_MESSAGE_CODE, MESSAGE_FLAT_LIST_CONST, MESSAGE_WINDOW_TABS, PERSON_TYPES} from '../../../../../constants';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {CONVERSATION_LOCAL_EVENT_CODES, WEB_SOCKET_EVENT_CODE} from '../../../../../constants/WebSocketConst';
import ConversationsQueries from '../../../../../services/Conversations/ConversationsQueries';
import {Colors} from '../../../../../styles';
import {TestIdentifiers, testID} from '../../../../../testUtils';
import {getDisplayDateFormatted} from '../../../../../utils/DateUtils';
import {EventBus} from '../../../../../utils/EventBus';
import {MessageBus} from '../../../../../utils/MessageBus';
import {getUserUUID, isChannelEmail, showInfoOnMessageHeader} from '../../../../../utils/commonUtils';
import {isAccountConfigEnabled} from '../../../../../utils/configUtils';
import {isWeb} from '../../../../../utils/platformCheckUtils';
import StickyNoteMessageView from '../../../../common/ActionMessageView/StickyNoteMessageView';
import {EmailMessageBox} from '../../../../common/ChatUI/EmailMessageBox';
import {ConversationAttachments} from '../../../../common/ChatUI/MessageBox/ConversationAttachments';
import MessageBox from '../../../../common/ChatUI/MessageBox/MessageBox';
import {IReplyMessageObject} from '../../../../common/ChatUI/MessageBox/interfaces';
import {MessagesFlatList} from '../../../../common/MessagesFlatList';
import {EmptyStateSvg} from '../../../../common/Svg';
import UnreadMsg from '../../../../common/UnreadMsg/UnreadMsg';
import {CHANNEL_TYPE, CHANNEL_TYPE_CODE} from '../ConversationConst';
import {
  IConversationData,
  IMessageBoxData,
  IMessagingWindowCommon,
} from '../interfaces';
import {
  checkIsLoggedInUserConversationMember,
  convertMsgObjectToReplyMsgObject,
  getContactDataAndType,
  getLastNonInternalMessageInList,
  getMembersId,
  getUniqueDisplayMessageList,
  isBroadCastGroup,
  isIncomingOrOutgoingNonActivityMessage,
  isInternalChat,
  isSeen
} from './MessagingUtils';
import {styles} from './MessagingWindowStyles';
import {ICopiedMessageItem} from './interface';
import UnreadMsgV2 from '../../../../common/UnreadMsg/UnreadMsgV2';
import { MessagingEventQueue } from '../MessagingEventQueue/MessagingEventQueue';

const GetMessagingListElem = (props: IMessagingWindowCommon): JSX.Element => {
  const {
    msgData,
    selectedConversation,
    selectedInboxTypeCode,
    onReplyCallback,
    isKeyboardVisible,
    isPrivateTab,
    isReadOnlyView,
    actionMessageCode,
    loadingLoadMore,
    isSeachMsgContainerVisible,
    searchMessage,
    onRedirectToMentionChange,
    redirectToMention
  } = props;
  const eventQueueRef = useRef(new MessagingEventQueue({moduleCode: 'MessagingWindowCommon'}));
  const eventQueue = eventQueueRef?.current;

  const {height} = Dimensions.get('window');
  const userUuid = getUserUUID();
  const conversationUuid = selectedConversation?.uuid;
  const contactInfo = getContactDataAndType(
    selectedConversation || ({} as IConversationData)
  );
  const isLoggedInUserGroupMember = checkIsLoggedInUserConversationMember(selectedConversation, userUuid);
  const messageBus = MessageBus.getMessageBusInstance()
  const isEmailInbox = isChannelEmail(props.selectedConversation?.conversationInbox?.channelType || '');
  const lastNonInternalMessageInDisplayList = getLastNonInternalMessageInList(
    msgData.displayData || []
  );
  const isMentionConversation =
    selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION;

  const showInfoOnHeaderData = showInfoOnMessageHeader();

  const contactData: any = contactInfo.contactData;
  const [stateData, setStateData] = useState({
    lastSeenData: [] as any,
    tab: MESSAGE_WINDOW_TABS.CHAT,
    copiedMsgList: [] as ICopiedMessageItem[],
    copied: false,
    actionMessageLoading: false,
    headerLoading: false,
    footerLoading: false,
  });
  const intl = useIntl();

  const isPatient =
    contactData.contactType?.contactType?.code === PERSON_TYPES.CUSTOMER;
  const isSinglePatientReadReceiptEnable = isAccountConfigEnabled(CONFIG_CODES.SINGLE_PATIENT_READ_RECEIPTS);
  const isCopyOrSelectMessageInDescending = isAccountConfigEnabled(CONFIG_CODES.COPY_SELECT_MESSAGE_IN_DESCENDING);

  const isContact =
    contactData.contactType?.contactType?.id?.length;

  const isViewStickyNote =
    showInfoOnHeaderData?.ADD_STICKY_NOTE &&
    isContact && !isBroadCastGroup(selectedConversation as IConversationData) &&
    !isInternalChat(selectedConversation as any);

  const [GetLastSeenByConversationId] = useLazyQuery(
    ConversationsQueries.GET_LAST_SEEN_BY_CONVERSATION_ID,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );
  const groupMemberId = getMembersId(
    selectedConversation,
    contactData,
    userUuid
  );

  const toShowPhiWarning = () => {
    if (selectedConversation?.conversationInbox?.channelType) {
      const type = selectedConversation?.conversationInbox?.channelType || '';
      if (
        type === CHANNEL_TYPE.CHANNEL_EMAIL &&
        isKeyboardVisible &&
        isPrivateTab !== true
      ) {
        return true;
      } else if (
        type === CHANNEL_TYPE.CHANNEL_TWILIO_SMS &&
        isKeyboardVisible &&
        isPrivateTab !== true
      ) {
        return true;
      } else if (
        type === CHANNEL_TYPE.CHANNEL_WEB_WIDGET &&
        isKeyboardVisible &&
        isPrivateTab !== true
      ) {
        return true;
      }
      return false;
    } else {
      if (contactData?.source) {
        if (
          contactData?.source === 'WEB_WIDGET' &&
          isKeyboardVisible &&
          isPrivateTab !== true
        ) {
          return true;
        }
        return false;
      }
      return false;
    }
  };

  const getLastSeenByUsers = useCallback(() => {
    GetLastSeenByConversationId({
      variables: {
        conversationUuid: conversationUuid,
      },
    }).then((data) => {
      if (data?.data?.conversationLastseens) {
        setStateData((prev)=> {
          return {
            ...prev,
            lastSeenData: data.data.conversationLastseens,
          }
        });
      } else {
        setStateData((prev)=> {
          return {
            ...prev,
            lastSeenData: [],
          }
        });
      }
    });
  }, [conversationUuid]);

  const usersLastSeenUpdated = (data: any) => {
    if (
      conversationUuid === data?.data?.conversationUuid
    ) {
      getLastSeenByUsers();
    }
  };

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(
      WEB_SOCKET_EVENT_CODE.MESSAGE_READ,
      usersLastSeenUpdated
    );
    getLastSeenByUsers();

    return () => {
      eventBus.removeEventListener(usersLastSeenUpdated);
    };
  }, [conversationUuid]);

  const messageList = getUniqueDisplayMessageList(msgData.displayData);
  const row: Array<any> = [];
  let prevOpenedRow: any;
  const flatListRef = useRef<RNFlatList<IMessageBoxData>>(null);

  const scrollToMessage = (msg: IMessageBoxData) => {
    if (isWeb()) {
      onScrollToMessageFail(msg);
      return;
    }
    const index = messageList.findIndex(
      (element) => element.uuid === msg.uuid
    );
    if(index === -1 || index > 15){
      onScrollToMessageFail(msg)
    }
    if (flatListRef?.current && index > 1) {
      try {
        flatListRef.current?.scrollToIndex({
          animated: true,
          index: index,
          viewPosition: 0,
        });
        onScrollToMessageFail(msg, true)
      } catch (error) {

      }
    }
  }

  const onScrollToMessageFail = (
    msg: IMessageBoxData,
    isSetClickedMessageUuid?: boolean
  ) => {
    if (msg?.messageData && props.onScrollToMessageFail) {
      props.onScrollToMessageFail(
        {
          uuid: msg?.messageData?.uuid,
          id: msg?.messageData?.id,
          createdAt: msg?.messageData?.createdAt,
        },
        isSetClickedMessageUuid
      );
    }
  };

  const swipeActions = () => {
    return (
      <View
        style={{
          width: 100,
        }}
      ></View>
    );
  };
  const closeRow = (index: number) => {
    if (prevOpenedRow) prevOpenedRow.close();
    prevOpenedRow = row[index];
  };

  const renderMsgItem = (item: any, index: number) => {
    const tempObject: IReplyMessageObject = convertMsgObjectToReplyMsgObject(item);
    const prevMessage = index != undefined ? messageList[index + 1] : undefined;
    const prevDate = prevMessage ? getDisplayDateFormatted(prevMessage?.dateStr) : undefined;
    const currentDate = getDisplayDateFormatted(item.dateStr);

    const isDisplaySectionMsgHeader = currentDate != prevDate;
    if (
      isEmailInbox &&
      isIncomingOrOutgoingNonActivityMessage(item.messageType)
    ) {
      return (
        <EmailMessageBox
          messageData={item}
          channelData={selectedConversation?.conversationInbox?.channelEmail}
          onActionPerformed={(actionId, actionData) => {
            if (props.onMessageAction) {
              props.onMessageAction(actionId, actionData);
            }
          }}
          isDisplayFullContent={
            isWeb() && lastNonInternalMessageInDisplayList?.id === item.id
          }
          navigation={props.navigation ? props.navigation : null}
          onViewTaskAction={props.onViewTaskAction}
        />
      );
    }
    return (
      <MessageBox
        clickMessageUuid={props.clickMessageUuid}
        clickEventId={props.msgData.clickEventId}
        resetClickedMessageUuid={props.resetClickedMessageUuid}
        onAddCommunicationType={props.onAddCommunicationType}
        isCopyModeStateOnCode={props.isCopyModeStateOnCode || ''}
        currentIndex={index}
        navigation={props.navigation ? props.navigation : null}
        text={item.displayContent}
        dateStr={item.dateStr}
        position={item.position}
        senderType={item.senderType}
        senderContact={item.senderContact}
        senderUser={item.senderUser}
        senderFullName={item.senderFullName}
        messageType={item.messageType}
        attachments={item.msgAttachment}
        private={item.private}
        conversation={selectedConversation}
        isReadOnlyView={isReadOnlyView}
        selectedInboxTypeCode={selectedInboxTypeCode}
        conversationMessage={item.messageData}
        parentMessageUuid={item?.parentMessageUuid}
        parentMessage={item?.parentMessage}
        onParentMsgClick={(parentMsg: IMessageBoxData) => {
          scrollToMessage(parentMsg);
        }}
        uuid={item.uuid}
        id={item.id}
        onReplyCallback={onReplyCallback}
        showModal={props?.showModal}
        onActionPerformed={props?.onActionPerformed}
        replyObject={tempObject}
        item={item}
        messageAction={props?.messageAction}
        onMessageAction={props?.onMessageAction}
        isSeen={
          item.position === 'right'
            ? isSeen(
              stateData.lastSeenData,
              groupMemberId,
              item.date,
              item.displayContent,
              false,
              selectedConversation,
              isSinglePatientReadReceiptEnable,
              item?.private
            )
            : false
        }
        isSeenByUsers={isSeen(
          stateData.lastSeenData,
          groupMemberId,
          item.date,
          item.displayContent,
          true,
          selectedConversation,
          isSinglePatientReadReceiptEnable,
          item?.private
        )}
        handleCopyClick={handleCopyClick}
        taskCount ={item.taskCount}
        onViewTaskAction={(messageUuid) => {
          if (props?.onViewTaskAction) {
            props?.onViewTaskAction(messageUuid);
          }
        }}
        searchMessage={searchMessage}
        // parentMessageUuid={stateData?.parentMessageUuid}
        onRedirectToMentionChange={onRedirectToMentionChange}
        redirectToMention={redirectToMention}
        isDisplaySectionMsgHeader={isDisplaySectionMsgHeader}
        groupMemberDataLoading={props?.groupMemberDataLoading || false}
        userList={props?.userList}
        sipNumberList={props?.sipNumberList}
      />
    );
  };
  const renderMsgList = ({item, index}: any) => {
    return (
      <View pb={3} style={{paddingHorizontal: 15}}>
        {selectedConversation?.unreadMessages?.[0]?.id === item?.id &&
        isLoggedInUserGroupMember &&
        selectedConversation?.unreadMsgCount &&
        selectedConversation?.unreadMsgCount > 0 ? (
          isWeb() ? (
            <UnreadMsgV2 />
          ) : (
            <UnreadMsg unreadMsgCount={selectedConversation?.unreadMsgCount} />
          )
        ) : (
          <></>
        )}
        {props.onReplyCallback &&
          selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET &&
          item.messageType !== 2 ? (
          <GestureHandlerRootView>
          <Swipeable
            ref={(ref) => (row[index] = ref)}
            friction={2}
            leftThreshold={40}
            renderLeftActions={swipeActions}
            onSwipeableWillOpen={() => {
              closeRow(index);
              closeRow(index);
              if (props.onReplyCallback) {
                props.onReplyCallback(convertMsgObjectToReplyMsgObject(item));
              }
            }}
            {...testID('SwipeableMessagesView')}
          >
            {renderMsgItem(item, index)}
          </Swipeable>
          </GestureHandlerRootView>
        ) : (
          renderMsgItem(item, index)
        )}
      </View>
    );
  };

  const getHeight = () => {
    if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS) {
      return 90;
    } else {
      return 120;
    }
  };

  const showConversationAttachmentOption = () => {
    const channelType = selectedConversation?.conversationInbox?.channelType;
    if (channelType === CHANNEL_TYPE.CHANNEL_TWILIO_SMS) {
      return false;
    }
    return props?.showTabs || !isWeb();
  };

  const getTabs = () => {
    return (
      <HStack height={isWeb() ? 10 : 8}>
        <Pressable
          borderBottomWidth={
            stateData?.tab === MESSAGE_WINDOW_TABS.CHAT ? 2 : 0
          }
          borderColor={Colors.primary['500']}
          alignSelf={isWeb() ? 'flex-end' : 'center'}
          alignItems={'center'}
          flex={0.5}
          onPress={() => {
            Keyboard.dismiss();
            setStateData((prev)=> {
              return {
                ...prev,
                tab: MESSAGE_WINDOW_TABS.CHAT
              }
            });
          }}
          {...testID(MESSAGE_WINDOW_TABS.CHAT + 'Tab')}
        >
          <Text fontWeight={500} fontSize={16}>
            {isEmailInbox ? 'Email' : MESSAGE_WINDOW_TABS.CHAT}
          </Text>
        </Pressable>
        <Pressable
          borderBottomWidth={
            stateData?.tab === MESSAGE_WINDOW_TABS.FILES ? 2 : 0
          }
          borderColor={Colors.primary['500']}
          alignItems={'center'}
          alignSelf={isWeb() ? 'flex-end' : 'center'}
          flex={0.5}
          onPress={() => {
            Keyboard.dismiss();
            setStateData((prev)=> {
              return {
                ...prev,
                tab: MESSAGE_WINDOW_TABS.FILES
              }
            });
          }}
          {...testID(MESSAGE_WINDOW_TABS.FILES + 'Tab')}
        >
          <Text fontWeight={500} fontSize={16}>
            {MESSAGE_WINDOW_TABS.FILES}
          </Text>
        </Pressable>
      </HStack>
    );
  };

  const handleCopyClick = (selectedItem: ICopiedMessageItem) => {
    const mutableArray = [...stateData.copiedMsgList];
    let updatedArray = [] as ICopiedMessageItem[]
    if (selectedItem?.isChecked) {
      let selectedMessage = {} as ICopiedMessageItem;
      messageList?.forEach((msg) => {
        if (msg.id === selectedItem.id) {
          selectedMessage = selectedItem;
        } else {
          return;
        }
      });
      if (selectedMessage.id) {
        mutableArray.push(selectedMessage);
        updatedArray = mutableArray
        setStateData((prev) => {
          return {
            ...prev,
            copiedMsgList: [...mutableArray]
          }
        })
      }
    } else {
      const updatedMessageArray = mutableArray.filter(msg => {
        return msg.id != selectedItem.id
      })
      updatedArray = updatedMessageArray
      setStateData((prev) => {
        return {
          ...prev,
          copiedMsgList: [...updatedMessageArray]
        }
      })

    }
    const updatedOrderArray = updatedArray.sort(function (a, b) {
      const firstDate: any = new Date(b.date);
      const secondDate: any = new Date(a.date);
      if (isCopyOrSelectMessageInDescending) {
        const diff: any = firstDate - secondDate;
        return diff;
      } else {
        const diff: any = secondDate - firstDate;
        return diff;
      }
    });
    props.onActionPerformed?.(COMMON_ACTION_CODES.COPY_MESSAGE_LIST, updatedOrderArray)
  };
  useEffect(() => {
    if (props.isCopyModeStateOnCode == COPY_MESSAGE_CODE.COPY_MODE_COPIED || props.isCopyModeStateOnCode == COPY_MESSAGE_CODE.COPY_MODE_OFF) {
      setStateData((prev) => {
        return {
          ...prev,
          copiedMsgList: [],
        };
      });
    }
  }, [props.isCopyModeStateOnCode]);

  useEffect(() => {
    setStateData((prev) => {
      return {
        ...prev,
        headerLoading:
          stateData.headerLoading && msgData?.loadingLoadMore
            ? msgData?.loadingLoadMore
            : false,
        footerLoading:
          stateData.footerLoading && msgData?.loadingLoadMore
            ? msgData?.loadingLoadMore
            : false,
      };
    });
  }, [msgData?.displayData, msgData?.loadingLoadMore]);

  const onMsgReceivedListenerFn = useCallback(
    () => {
      if (
        !flatListRef.current ||
        isMentionConversation ||
        isWeb()
      ) {
        return;
      }
      flatListRef.current.scrollToIndex({animated: true, index: 0});
    },
    [conversationUuid]
  );

  useEffect(() => {
    eventQueue.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.NEW_MESSAGE_CREATED,
      onMsgReceivedListenerFn
    );
    return () => {
      eventQueue.removeEventQueue();
    };
  }, []);

  const loadMoreOlderMessages = async () => {
    if (msgData?.areMessagesRemaining) {
      setStateData((prev) => {
        return {
          ...prev,
          headerLoading: true,
          footerLoading: false,
        };
      });
      props.onFetchMoreIfAvailable();
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          headerLoading: false,
        };
      });
    }
  };

  const loadMoreRecentMessages = async () => {
    if (msgData?.lastConversationMsgUuid !== messageList?.[0]?.uuid && msgData?.lastConversationMsgUuid !== messageList?.[1]?.uuid) {
      setStateData((prev) => {
        return {
          ...prev,
          footerLoading: true,
          headerLoading: false,
        };
      });
      props.onFetchMoreRecentIfAvailable();
    }
  };

  // const onViewableItemsChanged = async (args: {
  //   viewableItems: ViewToken[];
  //   changed: ViewToken[];
  // }) => {
  //   const {viewableItems} = args;
  //   const visibleMessageItems: IMessageBoxData[] = viewableItems.map(
  //     (vi) => vi.item
  //   );
  //   const mentionObjects = getMentionObjects({
  //     conversationId: selectedConversation.id,
  //     messageBoxData: visibleMessageItems,
  //   });
  //   await messageBus.updateMentionStatus({mentionObjects});
  // };

  // const viewabilityConfigCallbackPairs = useRef<ViewabilityConfigCallbackPair[]>([
  //   {
  //     viewabilityConfig: {
  //       itemVisiblePercentThreshold: 100,
  //       // waitForInteraction: true,
  //     },
  //     onViewableItemsChanged(info) {
  //       onViewableItemsChanged(info);
  //     },
  //   },
  // ]);

  const renderLoader = () => {
    return (
      <View {...testID(TestIdentifiers.pageLoading)}>
        <Skeleton.Text lines={3}></Skeleton.Text>
      </View>
    );
  };

  return (
    <View flex={1}>
      {messageList?.length > 0 ? (
        <>
          {!isWeb() && toShowPhiWarning() && (
            <View mt={0.5} p={2} bgColor={'secondary.100'}>
              <Pressable onPress={()=> {
                Keyboard.dismiss();
              }}>
              <Text {...testID(TestIdentifiers.phiWarning)} color={'secondary.800'} fontSize={11}>
                {intl.formatMessage({
                  id: 'phiWarning',
                })}
              </Text>
              </Pressable>
            </View>
          )}
          {!isWeb() && isViewStickyNote ? (
            <StickyNoteMessageView
              contactData={contactData}
              conversationId={conversationUuid}
            />
          ) : (
            <></>
          )}

          <SafeAreaView style={{flex: 1}}>
            {showConversationAttachmentOption() && getTabs()}
            {stateData?.tab === MESSAGE_WINDOW_TABS.CHAT && (
              <GestureHandlerRootView style={{flex: 1}}>
                {stateData?.headerLoading && renderLoader()}
                {isWeb() ? (
                  <FlatList
                    inverted
                    keyboardShouldPersistTaps='handled'
                    initialNumToRender={30}
                    maxToRenderPerBatch={30}
                    disableVirtualization={true}
                    onEndReached={loadMoreOlderMessages}
                    onStartReached={loadMoreRecentMessages}
                    onEndReachedThreshold={1}
                    onStartReachedThreshold={.1}
                    //activityIndicatorColor={'black'}
                    //showDefaultLoadingIndicators={true}
                    style={[
                      styles.scrollViewStyle,
                      {flex: 1, height: '100%'},
                      props.msgWindowHeight
                        ? {maxHeight: props.msgWindowHeight}
                        : {},
                    ]}
                    data={messageList}
                    keyExtractor={(item, index) =>
                      'msgBox_' + item.id + index + item.displayContent
                    }
                    renderItem={renderMsgList}
                    onScrollBeginDrag={()=> {
                      Keyboard.dismiss();
                    }}
                    {...testID('MessageList')}
                  />
                ) : (
                  <MessagesFlatList
                    ref={flatListRef}
                    inverted
                    keyboardShouldPersistTaps='handled'
                    initialNumToRender={MESSAGE_FLAT_LIST_CONST.INITIAL_ITEMS_TO_RENDER}
                    maxToRenderPerBatch={MESSAGE_FLAT_LIST_CONST.MAX_ITEMS_TO_RENDER}
                    disableVirtualization={true}
                    onEndReached={loadMoreOlderMessages}
                    onStartReached={loadMoreRecentMessages}
                    onEndReachedThreshold={MESSAGE_FLAT_LIST_CONST.ON_END_REACHED_THRESHOLD}
                    onStartReachedThreshold={MESSAGE_FLAT_LIST_CONST.ON_START_REACHED_THRESHOLD}
                    style={[
                      styles.scrollViewStyle,
                      {flex: 1, height: '100%'},
                      props.msgWindowHeight
                        ? {maxHeight: props.msgWindowHeight}
                        : {},
                    ]}
                    data={messageList}
                    keyExtractor={(item, index) =>
                      'msgBox_' + item.id + index + item.displayContent
                    }
                    renderItem={renderMsgList}
                    onScrollToIndexFailed={(info) => {
                      if (!flatListRef.current) {
                        return;
                      }
                      const offset = info.averageItemLength * info.index;
                      flatListRef.current?.scrollToOffset({offset: offset});
                      let timeout: NodeJS.Timeout;
                      const wait = new Promise((resolve) => {
                        timeout = setTimeout(resolve, 50);
                      });
                      wait.then(() => {
                        flatListRef.current?.scrollToIndex({
                          index: info.index,
                          animated: true,
                        });
                        clearTimeout(timeout);
                      });
                    }}
                    {...testID('MessageList')}
                  />
                )}
                {stateData?.footerLoading && renderLoader()}
              </GestureHandlerRootView>
            )}
            {stateData?.tab === MESSAGE_WINDOW_TABS.FILES && (
              <ScrollView
                style={[
                  styles.scrollViewStyle,
                  {flex: 1},
                  props.msgWindowHeight
                    ? { maxHeight: props.msgWindowHeight }
                    : undefined,
                ]}>
                <ConversationAttachments
                  conversationId={selectedConversation?.id || -1}
                  conversationDisplayId={selectedConversation?.displayId || -1}
                  navigation={props?.navigation}
                />
              </ScrollView>
            )}
          </SafeAreaView>
        </>
      ) : (
        <View flex={1} width={'full'} zIndex={10}>
          <Center
            alignItems={'center'}
            justifyContent={'center'}
            alignContent={'center'}
            flex={1}
            height={'100%'}
            {...testID('NoMessages')}
          >
            <EmptyStateSvg titleId="noMessages" />
          </Center>
        </View>
      )}
    </View>
  );
};

export default GetMessagingListElem;
