import { Drawer, Spin, Button as AntdButton } from 'antd';
import { camelCase } from 'lodash';
import {
  Button,
  FormControl,
  HStack,
  Icon,
  IconButton,
  Input,
  Pressable,
  Select,
  Spacer,
  Text,
  useToast,
  View,
  VStack
} from 'native-base';
import { useContext, useRef, useState } from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { getId } from '..';
import { BUTTON_TYPE } from '../../../../../constants';
import {
  FlowType,
  WorkflowContext
} from '../../../../../context/WorkflowContext';
import { Colors } from '../../../../../styles';
import { getResourceAbilities } from '../../../../../utils/capabilityUtils';
import { showToast, ToastType } from '../../../../../utils/commonViewUtils';
import { ModalActionTitle } from '../../../../common/ModalActionTitle/ModalActionTitle';
import { tagColorList } from '../../../Contacts/Tags/TagsModal';
import { ColorSelector } from '../../../Contacts/Tags/TagsModal/ColorSelector';
import { CapabilityResource } from '../../../Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import { FormOutputDisplayTemplate } from '../../UserInputFields/FormOutputDisplayTemplate';
import {
  canShowField,
  isInvalidUserInput,
  setFieldsVisibility
} from './FlowNodeHelper';
import {
  IAddNewOption,
  ICondition,
  IConditionEditElement,
  IConditionListEditElement,
  IInputParams,
  ISideMenu,
  IUserInputField,
  IUserInputFieldMap,
  IUserInputFieldMapValue
} from './FlowNodeInterface';
import NodeInputField, {
  INPUT_FIELD_TYPE,
  RequiredSourceNodeError
} from './NodeInputField';
import TitleSubtitleView from '../../../../common/TitleSubtitleView/TitleSubtitleView';
import { DisplayText } from '../../../../common/DisplayText/DisplayText';
import Feather from 'react-native-vector-icons/Feather';
import CircleIconView, { NodeCategory } from '../../../../common/CircleIconView/CircleIconView';
import { LeftOutlined, LoadingOutlined } from '@ant-design/icons';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
// import './SideMenuStyles.css';

const CustomColor = {
  GREY_BORDER : '#E4E7EC',
}

function getConditionEditElement(
  props: IConditionEditElement,
  mainIndex: number
) {
  const getPreviousImmediateNode = props.getPreviousImmediateNode;
  const [conditionData, setConditionData] = useState(props.conditionData);
  const conditionResultInfo = props.conditionResultInfo || {};
  const inputParamList = props.inputParamList || [];
  const conditionKey = Object.keys(conditionData)[0];
  const keyConditionList = conditionData[conditionKey];
  const keyDisplayName = conditionResultInfo[conditionKey].displayName;
  const conditionKeyMap: {[key: string]: IInputParams} = {};
  const isShowError = props.isShowError;
  const conditionParamsSelectList = inputParamList.map(
    (inputParam: IInputParams) => {
      conditionKeyMap[inputParam.key] = inputParam;
      return {
        key: inputParam.key,
        value: inputParam.key,
        label: inputParam.displayName,
      };
    }
  );
  return (
    <View key={conditionKey + mainIndex}>
      <Text
        color={conditionResultInfo[conditionKey].color}
        key={conditionKey + mainIndex + 'displayName'}
        marginTop={4}
      >
        {keyDisplayName}:
      </Text>
      {keyConditionList.map((condition, index) => {
        const conditionParamValue = condition.key;
        const conditionOperatorValue = condition.operator;
        const conditionValue = condition.value;
        const paramInfo = conditionKeyMap[conditionParamValue] || {};
        let displayValue;
        (paramInfo.possibleValueList || []).some((data) => {
          if (data.value === conditionValue) {
            displayValue = data.displayName;
          }
        });
        const userInputFieldC: IUserInputField = {
          key: conditionParamValue,
          type: paramInfo.type,
          displayName: paramInfo.displayName,
          value: conditionValue,
          defaultValue: paramInfo.defaultValue,
          fieldType: paramInfo.fieldType,
          displayValue: displayValue,
          possibleValueList: paramInfo.possibleValueList,
        };
        const [userInputField, setUserInputField] = useState(userInputFieldC);
        return (
          <HStack
            marginLeft="25px"
            marginY="5px"
            space={2}
            key={conditionKey + mainIndex + '_' + index}
            alignItems="center"
          >
            <Text width="50px" alignContent="center" textAlign="center">
              {condition.bitwiseOperator}
            </Text>
            <Select
              borderColor={
                isShowError && !conditionParamValue ? 'red.600' : '#ddd5d5'
              }
              key={conditionParamValue + conditionKey + mainIndex + index}
              fontSize={'14px'}
              maxWidth={
                (conditionKeyMap[conditionParamValue]?.displayName || '')
                  .length > 12
                  ? '150px'
                  : '90px'
              }
              marginLeft={index == 0 ? '1px' : 0}
              selectedValue={conditionParamValue}
              style={{flex: 1, padding: 10, width: '350px'}}
              onValueChange={(newConditionParamValue) => {
                const keyConditionListCopy = keyConditionList.map(
                  (keyConditionData) => {
                    return {...keyConditionData};
                  }
                );
                keyConditionListCopy[index] = {
                  key: newConditionParamValue,
                  operator: '',
                  value: '',
                  bitwiseOperator: condition.bitwiseOperator,
                };
                const newUserInputField = {...userInputField};
                newUserInputField.value = undefined;
                newUserInputField.defaultValue = '';
                props.onChange({[conditionKey]: keyConditionListCopy});
                setConditionData({[conditionKey]: keyConditionListCopy});
                setUserInputField(newUserInputField);
              }}
            >
              {conditionParamsSelectList.map((data) => {
                return (
                  <Select.Item
                    key={data.key + conditionParamValue}
                    label={data.label}
                    value={data.value}
                  />
                );
              })}
            </Select>

            <Select
              borderColor={
                isShowError && !conditionOperatorValue ? 'red.600' : '#ddd5d5'
              }
              key={
                conditionOperatorValue + conditionKey + index + '_' + mainIndex
              }
              fontSize={'14px'}
              maxWidth={'90px'}
              selectedValue={conditionOperatorValue}
              style={{flex: 1, padding: 10, width: '350px'}}
              onValueChange={(itemValue) => {
                const keyConditionListCopy = keyConditionList.map(
                  (keyConditionData) => {
                    return {...keyConditionData};
                  }
                );
                keyConditionListCopy[index] = {
                  key: conditionParamValue,
                  operator: itemValue,
                  value: userInputField.value,
                  bitwiseOperator: condition.bitwiseOperator,
                };
                props.onChange({[conditionKey]: keyConditionListCopy});
                setConditionData({[conditionKey]: keyConditionListCopy});
              }}
            >
              {conditionKeyMap[conditionParamValue]?.operatorList?.map(
                (operator: any) => {
                  return (
                    <Select.Item
                      key={
                        (operator?.value ? operator.value : operator) +
                        conditionParamValue +
                        conditionKey
                      }
                      label={
                        operator?.displayName ? operator.displayName : operator
                      }
                      value={operator?.value ? operator.value : operator}
                    />
                  );
                }
              )}
            </Select>
            {
              <NodeInputField
              rootNodeType={props.rootNodeType}
              sourceHandle={props.sourceHandle}
                nodeType={props.nodeType}
                isShowError={isShowError && (!userInputField.optional || (isInvalidUserInput(userInputField, [])))}
                key={
                  userInputField.value + conditionKey + index + '_' + mainIndex
                }
                userInputFieldList={[]}
                getPreviousImmediateNode={getPreviousImmediateNode}
                isDisplayLabel={false}
                userInputField={userInputField}
                onNewAdd={props.onNewAdd}
                setUserInputField={(newUserInputField: IUserInputField) => {
                  const keyConditionListCopy = keyConditionList.map(
                    (keyConditionData) => {
                      return {...keyConditionData};
                    }
                  );
                  keyConditionListCopy[index] = {
                    key: conditionParamValue,
                    operator: conditionOperatorValue,
                    value: newUserInputField.value,
                    bitwiseOperator: condition.bitwiseOperator,
                  };
                  props.onChange({[conditionKey]: keyConditionListCopy});
                  setConditionData({[conditionKey]: keyConditionListCopy});
                  setUserInputField(newUserInputField);
                }}
              ></NodeInputField>
            }
          </HStack>
        );
      })}
    </View>
  );
}

function getConditionListEditElement(props: IConditionListEditElement) {
  const [conditionList, setConditionList] = useState(props.conditionList || []);
  const [conditionResultInfo] = useState(props.conditionResultInfo || {});
  const [inputParamList] = useState(props.inputParamList || []);
  return (
    <VStack space={4} flex={1} backgroundColor="#ffff" alignItems="left">
      <Text fontWeight="500">Condition List</Text>
      <VStack>
        {conditionList.map((conditionData, index) => {
          const conditionEditData: IConditionEditElement = {
            conditionData: conditionData,
            nodeType:props.nodeType,
            rootNodeType:props.rootNodeType,
            isShowError: props.isShowError,
            getPreviousImmediateNode: props.getPreviousImmediateNode,
            conditionResultInfo,
            inputParamList,
            onChange: (newConditionData: {
              [key: string]: Array<ICondition>;
            }) => {
              setConditionList((conditionList) => {
                const conditionListCopy = conditionList.map((condition) => {
                  return {...condition};
                });
                conditionListCopy[index] = newConditionData;
                props.onChange(conditionListCopy);
                return conditionListCopy;
              });
            },
          };
          return getConditionEditElement(conditionEditData, index);
        })}
      </VStack>
    </VStack>
  );
}

const AddNewOption = (props: IAddNewOption) => {
  const getPreviousImmediateNode = props.getPreviousImmediateNode;
  const toast = useToast();
  const [config] = useState(props.config);
  const workflowContext = useContext(WorkflowContext);
  const nodeMasterDataMap = workflowContext.nodeMasterDataMap;
  const addNewOptionInfo = props.addNewOptionInfo;
  const [defaultNodeTypeOnNewOption] = useState(
    config?.value?.nodeType || addNewOptionInfo?.defaultNodeTypeOnNewOption
  );
  const [optionKey, setOptionKey] = useState(
    config?.isEdit ? props?.config?.value?.displayName : ''
  );
  const [isShowError, setIsShowError] = useState(false);
  const [optionColor, setOptionColor] = useState(
    config?.isEdit ? props?.config?.value?.color : '#000000'
  );
  const defaultUserInputFieldList = defaultNodeTypeOnNewOption
    ? nodeMasterDataMap[defaultNodeTypeOnNewOption].userInputFieldList || []
    : [];
  const [userInputFieldList, setUserInputFieldList] = useState(
    config?.isEdit
      ? props?.config?.value?.userInputFieldList
      : defaultUserInputFieldList
  );
  const patientCapability = getResourceAbilities(
    CapabilityResource.patient,
    '',
    workflowContext?.locationIds?.[0]
  );

  return (
    <VStack>
      <HStack marginTop={4}>
        <Spacer />
        <Button.Group space={2}>
          <Button
            rounded="3xl"
            variant="outline"
            colorScheme="muted"
            onPress={() => {
              props.onClose();
            }}
          >
            Cancel
          </Button>

          { (
            <Button
              rounded="3xl"
              onPress={() => {
                setIsShowError(true);
                const isInvalid = userInputFieldList.some(
                  (userInputField: IUserInputField) => {
                    return (
                      !userInputField.optional &&
                      (userInputField.value === undefined ||
                        userInputField.value === null)
                    );
                  }
                );
                if (optionKey && !isInvalid) {
                  const key = getId(camelCase(optionKey));
                  const value = {
                    id: config.isEdit
                      ? config?.value?.id
                      : getId(defaultNodeTypeOnNewOption || ''),
                    value: config.isEdit ? config.editKey : key,
                    displayName: optionKey,
                    color: optionColor,
                    nodeType: defaultNodeTypeOnNewOption,
                    isAllowDelete: true,
                    userInputFieldList,
                    updatedTime: (new Date()).toISOString()
                  };
                  const response = props.onSave(config.isEdit ? config.editKey : key, value, config.editKey);
                  if (!response.success) {
                    showToast(toast, response.errorMessage, ToastType.error);
                  }
                }
              }}
            >
              {config?.isEdit ? 'Update' : 'Add'}
            </Button>
          ) }
        </Button.Group>
      </HStack>
      <VStack>
        <FormControl isInvalid={isShowError && !optionKey}>
          <View marginBottom={5}>
            <Text fontWeight={300} fontSize={16} flex={3} marginBottom={2}>
              {props.addNewOptionInfo.optionDisplayName + ' Name'}
            </Text>
            <Input
              value={optionKey}
              onChangeText={(text) => setOptionKey(text)}
            />
          </View>
          <View marginBottom={5}>
            <Text fontWeight={300} fontSize={16} flex={3} marginBottom={2}>
              {props.addNewOptionInfo.optionDisplayName + ' Color'}
            </Text>
            <ColorSelector
              color={optionColor}
              title=""
              colorList={tagColorList}
              onBtnPress={(color: string) => setOptionColor(color)}
              onChangeHex={(text) => setOptionColor('#' + text)}
            />
          </View>
          {userInputFieldList.map(
            (userInputField: IUserInputField, index: number) => {
              return (
                <NodeInputField
                  rootNodeType={props.rootNodeType}
                  sourceHandle={props.sourceHandle}
                  nodeType={props.nodeType}
                  getPreviousImmediateNode={getPreviousImmediateNode}
                  userInputFieldList={userInputFieldList}
                  isShowError={isShowError && (!userInputField.optional|| isInvalidUserInput(userInputField, userInputFieldList))}
                  isDisplayLabel={
                    !userInputField?.isHideLabel &&
                    userInputField.fieldType !== INPUT_FIELD_TYPE.REMINDER &&
                    userInputField.fieldType !==
                      INPUT_FIELD_TYPE.VITAL_CONDITIONAL
                  }
                  key={(userInputField?.key||'') + (index || '')}
                  prevImmediateNode={props.prevImmediateNode}
                  userInputField={userInputField}
                  setUserInputField={(newUserInputField: IUserInputField) => {
                    setUserInputFieldList((userInputFieldList: any) => {
                      const userInputFieldListCopy = JSON.parse(
                        JSON.stringify(userInputFieldList)
                      );
                      userInputFieldListCopy[index] = newUserInputField;
                      return userInputFieldListCopy;
                    });
                  }}
                />
              );
            }
          )}
        </FormControl>
      </VStack>
    </VStack>
  );
};

function SideMenu(props: ISideMenu) {
  const [addNewOptionConfig, setAddNewOptionConfig] = useState<{
    editKey?: string;
    isEdit: boolean;
    value?: IUserInputFieldMapValue;
  }>({isEdit: false});
  const workflowContext = useContext(WorkflowContext);
  const mlovData = useContext(CommonDataContext);
  const isSideCarContext = !!mlovData.sidecarContext?.isSidecar;
  const getPreviousImmediateNode = props.getPreviousImmediateNode;
  const [conditionList, setConditionList] = useState(props.conditionList || []);
  const [conditionResultInfo] = useState(props.conditionResultInfo || {});
  const [inputParamList] = useState(props.inputParamList || []);
  const [isShowAddNew, setIsShowAddNew] = useState(false);
  const [isShowError, setIsShowError] = useState(false);
  const [userInputFieldMap, setUserInputFieldMap] =
    useState<IUserInputFieldMap>(
      JSON.parse(JSON.stringify(props.userInputFieldMap))
    );
  const [conditionKeyList] = useState(Object.keys(userInputFieldMap));

  const addNewOptionInfo = props.addNewOptionInfo;
  const isCareJourney = workflowContext.flowType === FlowType.careJourney;

  const {
    isValueChanged: isFieldVisibilityChanged,
    inputFields: updatedUserInputFieldMap,
  } = setFieldsVisibility(userInputFieldMap?.out?.userInputFieldList);

  if (userInputFieldMap?.out)
    userInputFieldMap.out.userInputFieldList = updatedUserInputFieldMap;

  if (isFieldVisibilityChanged) {
    setUserInputFieldMap(userInputFieldMap);
  }

  const isValidForm = () => {
    let isInvalid = Object.keys(userInputFieldMap).some((key: string) => {
      const userInputFieldList = userInputFieldMap[key].userInputFieldList;
      return (userInputFieldList || []).some((userInputField) => {
        return isInvalidUserInput(userInputField, userInputFieldList);
      });
    });
    if (isInvalid) {
      return false;
    }
    isInvalid = conditionList.some((data) => {
      const conditionKey = Object.keys(data)[0];
      return data[conditionKey].some((conditionData) => {
        return (
          !conditionData.key ||
          !conditionData.operator ||
          conditionData.value === undefined || conditionData.value === ''
        );
      });
    });
    return !isInvalid;
  };

  const isDuplicateKey = (value: IUserInputFieldMapValue, userInputFieldMap: IUserInputFieldMap) => {
    return Object.keys(userInputFieldMap).some((key: string) => {
      const info = userInputFieldMap[key];
      const infoDisplayName = camelCase(info.displayName || '');
      const valueDisplayName = camelCase(value.displayName || '');
      if (infoDisplayName === valueDisplayName) {
        return true;
      }
      let valueWhenPressKey = '';
      value?.userInputFieldList?.filter((item: IUserInputField) => {
        if (item?.key === 'whenPressKey') {
          valueWhenPressKey = item?.value;
        }
      });

      const hasDuplicate = info?.userInputFieldList?.some((element: any) => {
        if (element?.key === 'whenPressKey') {
          return valueWhenPressKey.toString() === element?.value?.toString();
        }
      });
      return hasDuplicate;
    });
  };

  const scrollableHeight = window.innerHeight - 150;
  const buttonList = [
    {
      show:
        !props.requiredSourceNode?.isRequiredSourceNodeError && !isShowAddNew,
      id: 1,
      btnText: 'cancel',
      textColor: Colors.Custom.mainSecondaryBrown,
      variant: BUTTON_TYPE.SECONDARY,
      isTransBtn: false,
      onClick: () => {
        props.onClose();
      },
    },
  ];
  if (!props.isViewOnly) {
    buttonList.push({
      show:
        !props.requiredSourceNode?.isRequiredSourceNodeError && !isShowAddNew,
      id: 2,
      btnText: 'save',
      textColor: Colors.Custom.mainPrimaryPurple,
      variant: BUTTON_TYPE.PRIMARY,
      isTransBtn: false,
      onClick: () => {
        setIsShowError(true);
        if (isValidForm()) {
          props.onSave({userInputFieldMap, conditionList});
        }
      },
    });
  }

  const GetCloseAndContinue = () => {
    return (
      <HStack
        justifyContent={'flex-end'}
        space={2}
        marginY={2}
        alignItems={'center'}
      >
        {!props.isViewOnly && (
          <>
            <Pressable
              onPress={() => {
                setIsShowError(true);
                if (isValidForm()) {
                  props.onSave({userInputFieldMap, conditionList});
                }
              }}
              isDisabled={props?.isContinueButtonLoading}
            >
              <View
                style={{
                  backgroundColor: Colors.primary[300],
                  height: '32px',
                  justifyContent: 'center',
                  paddingHorizontal: '8px',
                  borderRadius: 4,
                }}
              >
                {props?.isContinueButtonLoading ? (
                  <Spin
                    size="small"
                    indicator={
                      <LoadingOutlined
                        style={{fontSize: 16, color: Colors.Custom.Gray300}}
                        spin
                      />
                    }
                  />
                ) : (
                  <DisplayText
                    textLocalId={props?.primaryButtonText || 'Save'}
                    size={'smBold'}
                    extraStyles={{
                      color: Colors?.Custom?.ContainerBGColor,
                    }}
                  />
                )}
              </View>
            </Pressable>

            <View
              alignSelf={'center'}
              style={{
                borderWidth: 0.5,
                height: 16,
                borderColor: Colors.FoldPixel.GRAY200,
              }}
            ></View>
          </>
        )}
        {
          <Pressable
            onPress={() => {
              props.onClose();
            }}
          >
            <View justifyContent={'center'} height={'36px'}>
              <Feather name="x" size={24} color={Colors.FoldPixel.GRAY300} />
            </View>
          </Pressable>
        }
      </HStack>
    );
  };

  const getTitle = (): string => {
    return props?.title ? props?.title : 'Then';
  };

  const parentElemRef = useRef<any>(null);

  const disableStyle:any = {
    opacity: 0.5
  }

  const renderSideMenu = (
    <div className="custom-new-workflow-trigger workflow-ant-input-number ">
    <View width={'100%'} ref={parentElemRef} gap={4}>
    {!props?.hideSideMenuTopBar && (
      <HStack
        justifyContent={'space-between'}
        flex={1}
        alignItems={'center'}>
        {/* <View backgroundColor={CustomColor.ACCENT_LIGHT_PINK} borderRadius={8}>
              <CircleIconView
                iconInfo={props.iconInfo}
                nodeCategory={props.nodeCategory || NodeCategory.moment}
                backgroundColor= {CustomColor.TRANSPARENT}
                borderColor= {CustomColor.TRANSPARENT}
                iconColor={CustomColor.ACCENT_DARK_PINK}
                size='32px'
              />
        </View> */}
        <View flex={8}>
        <TitleSubtitleView
          useStrictWidth={true}
          isTitleSubtitleOldView={true}
          containerStyle={{ marginHorizontal: 0, marginTop: 0 }}
          titleLabelId = {props.title ? props.title : props.isViewOnly ? 'Details' : 'Edit'}
          subtitleLabelId=""
          isHideCommonButton={true}
          titleStyle={{
            fontSize: 24,
            lineHeight: 28.8,
            fontWeight:400,
          }}
        />
        </View>
        <View flex={2}>
          <GetCloseAndContinue/>
        </View>
      </HStack>
      )}
        {!!props.description &&
          <Text
            color={Colors?.Custom?.Gray400}
            fontWeight={400}
            fontSize={'14'}
            width={parentElemRef?.current?.offsetWidth || '80%'}
          >
            {props.description}
          </Text>
        }
        { props.isViewOnly ? <Text
            color={Colors?.Custom?.Gray600}
            fontWeight={400}
            fontSize={'14'}
            width={parentElemRef?.current?.offsetWidth || '80%'}
          >
            {'This screen is view-only. No changes or edits can be made here.'}
          </Text> : <></>}
      <View mx={0} overflow={'scroll'} height={scrollableHeight} style={ props.isViewOnly ? disableStyle : {}}>
        <VStack space={6}>
          {!isCareJourney && props.sideMenuHeader}
          {props.rootNodeType == 'ForAllPatientWithFilter' ?
          Object.keys(userInputFieldMap).slice(0,1).map(
            (userInputFieldMapKey: string) => {
              const userInputFieldList =
                userInputFieldMap[userInputFieldMapKey].userInputFieldList;

              return userInputFieldList.length && userInputFieldList?.some((fieldList) => fieldList?.fieldType === INPUT_FIELD_TYPE.SHOW_FILTER_TOTAL_COUNT) ? (
                <View>
                  {!props.requiredSourceNode?.isRequiredSourceNodeError &&
                  conditionKeyList.length ? (
                    <VStack
                      borderWidth={1}
                      borderColor={CustomColor.GREY_BORDER}
                      borderRadius={8}
                      backgroundColor={ Colors.FoldPixel.GRAY50}
                      flex={1}
                      alignItems="left"
                      pointerEvents={userInputFieldMap[userInputFieldMapKey].isAllowDelete ? 'auto' : 'auto'}
                      >
                      {userInputFieldList.map(
                        (userInputField, index: number) => {
                          if(userInputField.fieldType ===
                            INPUT_FIELD_TYPE.SHOW_FILTER_TOTAL_COUNT
                          ){
                          return (
                            <NodeInputField
                              rootNodeType={props.rootNodeType}
                              sourceHandle={props.sourceHandle}
                              getPreviousImmediateNode={
                                getPreviousImmediateNode
                              }
                              nodeType={props.nodeType}
                              isViewOnly={!!props.isViewOnly}
                              onNewAdd={props.onNewAdd}
                              userInputFieldList={userInputFieldList}
                              isShowError={
                                isShowError && (!userInputField.optional|| isInvalidUserInput(userInputField))
                              }
                              isDisplayLabel={
                                !userInputField?.isHideLabel &&
                                userInputField.fieldType !==
                                  INPUT_FIELD_TYPE.REMINDER &&
                                userInputField.fieldType !==
                                  INPUT_FIELD_TYPE.VITAL_CONDITIONAL
                              }
                              key={userInputFieldMapKey + (userInputFieldMap[userInputFieldMapKey]?.updatedTime)}
                              prevImmediateNode={props.prevImmediateNode}
                              userInputField={userInputField}
                              setUserInputField={(
                                newUserInputField: IUserInputField
                              ) => {
                                // setUserInputFieldMap(userInputFieldMapCopy);
                                setUserInputFieldMap(
                                  (userInputFieldMap: IUserInputFieldMap) => {
                                    const userInputFieldList =
                                      userInputFieldMap[userInputFieldMapKey]?.userInputFieldList || [];
                                    const userInputFieldListCopy = JSON.parse(
                                      JSON.stringify(userInputFieldList)
                                    );
                                    userInputFieldListCopy[index] =
                                      newUserInputField;

                                    const newUserInputFieldList = [
                                      ...userInputFieldListCopy,
                                    ];

                                    const userInputFieldMapCopy = {
                                      ...userInputFieldMap,
                                    };
                                    userInputFieldMapCopy[
                                      userInputFieldMapKey
                                    ].userInputFieldList = newUserInputFieldList;

                                    props.onChangeUserInputFieldList
                                      ? props.onChangeUserInputFieldList(
                                          newUserInputFieldList,
                                          index,
                                          userInputFieldMapKey
                                        )
                                      : '';

                                    return userInputFieldMapCopy;
                                  }
                                );
                              }}
                            />
                          )};
                        }
                      )}
                    </VStack>
                  ) : (
                    <></>
                  )}
                </View>
              ) : (
                <></>
              );
            }
          ) : <></> }


          {Object.keys(userInputFieldMap).map(
            (userInputFieldMapKey: string) => {
              const userInputFieldList =
                userInputFieldMap[userInputFieldMapKey].userInputFieldList;

              return userInputFieldList.length ? (
                <View marginBottom={10}>
                  <HStack>
                    <Text
                      marginRight={5}
                      fontWeight={300}
                      fontSize={16}
                      color={userInputFieldMap[userInputFieldMapKey].color}
                    >
                      {userInputFieldMap[userInputFieldMapKey].displayName}
                    </Text>
                    {userInputFieldMap[userInputFieldMapKey].isAllowDelete &&
                      !props.isViewOnly && (
                        <HStack>
                          <IconButton
                            icon={<Icon as={AntIcon} size="xs" name="delete" />}
                            onPress={() => {
                              const userInputFieldMapCopy = {
                                ...userInputFieldMap,
                              };
                              delete userInputFieldMapCopy[
                                userInputFieldMapKey
                              ];
                              setUserInputFieldMap(userInputFieldMapCopy);
                            }}
                          />
                          <IconButton
                            icon={<Icon as={AntIcon} size="xs" name="edit" />}
                            onPress={() => {
                              setAddNewOptionConfig({
                                editKey: userInputFieldMapKey,
                                isEdit: true,
                                value: userInputFieldMap[userInputFieldMapKey],
                              });
                              setIsShowAddNew(true);
                            }}
                          />
                        </HStack>
                      )}
                    {props.requiredSourceNode?.isRequiredSourceNodeError && (
                      <RequiredSourceNodeError
                        requiredNodes={props.requiredSourceNode?.requiredSourceNodes.map(
                          (node) => node.displayName
                        )}
                      />
                    )}
                  </HStack>
                  {!props.requiredSourceNode?.isRequiredSourceNodeError &&
                  conditionKeyList.length ? (
                    <VStack
                      borderWidth={1}
                      borderColor={CustomColor.GREY_BORDER}
                      borderRadius={8}
                      backgroundColor={Colors.FoldPixel.GRAY50}
                      flex={1}
                      alignItems="left"
                      pointerEvents={userInputFieldMap[userInputFieldMapKey].isAllowDelete ? 'auto' : 'auto'}
                      >
                      {userInputFieldList.map(
                        (userInputField, index: number) => {
                          const canShow = canShowField(userInputField, getPreviousImmediateNode, props?.prevImmediateNode);
                          if (!canShow) {
                            return <></>;
                          }
                          if (
                            userInputField.fieldType ===
                            INPUT_FIELD_TYPE.FORM_OUTPUT_DISPLAY_TEMPLATE
                          ) {
                            return (
                              <FormOutputDisplayTemplate
                                userInputField={userInputField}
                                userInputFieldList={userInputFieldList}
                              />
                            );
                          }
                          if(
                            userInputField.fieldType ===
                            INPUT_FIELD_TYPE.SHOW_FILTER_TOTAL_COUNT
                          ){
                            return <></>
                          }
                          return (
                            <NodeInputField
                              rootNodeType={props.rootNodeType}
                              sourceHandle={props.sourceHandle}
                              getPreviousImmediateNode={
                                getPreviousImmediateNode
                              }
                              nodeType={props.nodeType}
                              isViewOnly={!!props.isViewOnly}
                              onNewAdd={props.onNewAdd}
                              userInputFieldList={userInputFieldList}
                              isShowError={
                                isShowError && (!userInputField.optional|| isInvalidUserInput(userInputField, userInputFieldList))
                              }
                              isDisplayLabel={
                                !userInputField?.isHideLabel &&
                                userInputField.fieldType !==
                                  INPUT_FIELD_TYPE.REMINDER &&
                                userInputField.fieldType !==
                                  INPUT_FIELD_TYPE.VITAL_CONDITIONAL
                              }
                              key={userInputFieldMapKey + (userInputFieldMap[userInputFieldMapKey]?.updatedTime)}
                              prevImmediateNode={props.prevImmediateNode}
                              userInputField={userInputField}
                              setUserInputField={(
                                newUserInputField: IUserInputField
                              ) => {
                                // setUserInputFieldMap(userInputFieldMapCopy);
                                setUserInputFieldMap(
                                  (userInputFieldMap: IUserInputFieldMap) => {
                                    const userInputFieldList =
                                      userInputFieldMap[userInputFieldMapKey]?.userInputFieldList || [];
                                    const userInputFieldListCopy = JSON.parse(
                                      JSON.stringify(userInputFieldList)
                                    );
                                    userInputFieldListCopy[index] =
                                      newUserInputField;

                                    const newUserInputFieldList = [
                                      ...userInputFieldListCopy,
                                    ];

                                    const userInputFieldMapCopy = {
                                      ...userInputFieldMap,
                                    };
                                    userInputFieldMapCopy[
                                      userInputFieldMapKey
                                    ].userInputFieldList = newUserInputFieldList;

                                    props.onChangeUserInputFieldList
                                      ? props.onChangeUserInputFieldList(
                                          newUserInputFieldList,
                                          index,
                                          userInputFieldMapKey
                                        )
                                      : '';

                                    return userInputFieldMapCopy;
                                  }
                                );
                              }}
                            />
                          );
                        }
                      )}
                    </VStack>
                  ) : (
                    <></>
                  )}
                </View>
              ) : (
                <></>
              );
            }
          )}
          <View>
            {inputParamList.length &&
            conditionResultInfo &&
            conditionList?.length ? (
              getConditionListEditElement({
                rootNodeType:props.rootNodeType,
                sourceHandle:props.sourceHandle,
                nodeType:props.nodeType,
                getPreviousImmediateNode: getPreviousImmediateNode,
                inputParamList: inputParamList,
                conditionResultInfo: conditionResultInfo,
                conditionList: conditionList,
                isShowError: isShowError,
                onChange: (
                  conditionList: Array<{[key: string]: Array<ICondition>}>
                ) => {
                  setConditionList(conditionList);
                },
              })
            ) : (
              <></>
            )}
          </View>
        </VStack>

        {isShowAddNew && addNewOptionInfo && (
          <Drawer
          destroyOnClose
          placement="right"
          onClose={() => {
            setIsShowAddNew(false);
          }}
          visible={isShowAddNew}
          closable={false}
          width={isSideCarContext ? '100%' : '45%'}
        >
          <AddNewOption
            rootNodeType={props.rootNodeType}
            sourceHandle={props.sourceHandle}
            nodeType={props.nodeType}
            getPreviousImmediateNode={getPreviousImmediateNode}
            config={addNewOptionConfig}
            prevImmediateNode={props.prevImmediateNode}
            addNewOptionInfo={addNewOptionInfo}
            onSave={(
              key: string,
              value: IUserInputFieldMapValue,
              editKey: string
            ) => {
              const userInputFieldMapCopy = {...userInputFieldMap};
              if (addNewOptionConfig.isEdit) {
                  delete userInputFieldMapCopy[editKey];
                  if (!isDuplicateKey(value, userInputFieldMapCopy)) {
                    userInputFieldMapCopy[key] = value;
                    setUserInputFieldMap({});
                    setTimeout(() => {
                      setUserInputFieldMap(
                        JSON.parse(JSON.stringify(userInputFieldMapCopy))
                      );
                      setIsShowAddNew(false);
                      setAddNewOptionConfig({isEdit: false});
                      return {success: true};
                    }, 10);
                  } else {
                    return {
                      errorMessage:
                        addNewOptionInfo.optionDisplayName + ' Already Present',
                      success: false,
                    };
                  }
              } else if (!isDuplicateKey(value, userInputFieldMap)) {
                userInputFieldMapCopy[key] = value;
                setUserInputFieldMap(JSON.parse(JSON.stringify(userInputFieldMapCopy)));
                setIsShowAddNew(false);
                setAddNewOptionConfig({isEdit: false});
                return {success: true};
              } else {
                return {
                  errorMessage:
                    addNewOptionInfo.optionDisplayName + ' Already Present',
                  success: false,
                };
              }
            }}
            onClose={() => {
              setIsShowAddNew(false);
            }}
          ></AddNewOption>
          </Drawer>
        )}

      </View>
      {!props.requiredSourceNode?.isRequiredSourceNodeError &&
          !isShowAddNew &&
          !props.isViewOnly && (
            <HStack marginBottom={4}>
              <Spacer />
              <Button.Group space={2}>
                {addNewOptionInfo && addNewOptionInfo.isAllowAddNewOption ? (
                  <Button
                    rounded="3xl"
                    variant="outline"
                    colorScheme="muted"
                    onPress={() => {
                      setAddNewOptionConfig({isEdit: false, value: undefined});
                      setIsShowAddNew(true);
                    }}
                  >
                    {'Add New ' +
                      (addNewOptionInfo.optionDisplayName
                        ? addNewOptionInfo.optionDisplayName + ' '
                        : '')}
                  </Button>
                ) : (
                  <></>
                )}
              </Button.Group>
            </HStack>
          )}
    </View>
    </div>
  );

  const backBtn = () => (
    {
      show: true,
      id: 1,
      btnText: 'cancel',
      textColor: Colors.Custom.mainSecondaryBrown,
      variant: BUTTON_TYPE.SECONDARY,
      isTransBtn: false,
      onClick: () => {
        props.onClose();
      },
    }
  );


  return props?.encloseSideMenuInDrawer ? (
    <Drawer
      headerStyle={{borderWidth: 0, marginBottom: 0}}
      destroyOnClose
      placement="right"
      width={props.width || '100%'}
      open={true}
      title={
        <ModalActionTitle
          title={'Send Content'}
          titleColor={''}
          leftBackButton={isSideCarContext ? <AntdButton onClick={() => props.onClose()} type="text" icon={<LeftOutlined />}/> : undefined}
          buttonList={[
            {...backBtn(), show: !isSideCarContext},
            {
              show: !props.isViewOnly,
              id: 3,
              btnText: props?.primaryButtonText || 'save',
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.PRIMARY,
              isLoading: props?.isContinueButtonLoading,
              isDisabled: props?.isContinueButtonLoading,
              isTransBtn: false,
              onClick: () => {
                setIsShowError(true);
                if (isValidForm()) {
                  props.onSave({userInputFieldMap, conditionList});
                }
              },
            },
          ]}
        />
      }
    >
      {renderSideMenu}
    </Drawer>
  ) : (
    renderSideMenu
  );
}

export default SideMenu;
